export function guid() {
    function s4() {
      return Math.floor((1 + Math.random()) * 0x10000)
        .toString(16)
        .substring(1);
    }
    return s4() + s4() + '-' + s4() + '-' + s4() + '-' +
      s4() + '-' + s4() + s4() + s4();
}

export function validateEmail(email){
  if(!email){
    return false;
  }
  
  const regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return regex.test(email);
}

export function getInitials(firstName, lastName) {
  let initial = '';

  if (firstName) {
    initial = firstName.charAt(0);
  }

  if (lastName) {
    initial = initial + lastName.charAt(0);
  }

  return initial.toUpperCase();
}

export const padNumber = (number = 0, pad) => {
  const length = pad - String(number).length + 1;
  let padding = '';
  if (length > 0) {
    padding = Array(pad - String(number).length + 1).join('0');
  }
  return padding + number;
}

export const getBaseUrl = (url) => {
  let paths = url.split('/');
  let basePaths = paths.slice(0, 3);
  
  return basePaths.join('/');
}

export function* chunks(arr, n) {
  for (let i = 0; i < arr.length; i += n) {
    yield arr.slice(i, i + n);
  }
}

export function stripHtml(htmlStr) {
  let element = document.createElement("DIV");
  element.innerHTML = htmlStr;
  if(element.textContent === 'undefined' || element.innerText  === 'undefined') {
    return "";
  }
  return element.textContent || element.innerText || "";
};