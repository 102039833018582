import { 
  ADDING_ASSETS_REQUEST, ADDING_ASSETS_SUCCEEDED, ADDING_ASSETS_FAILED,
  UPLOADING_FILE_REQUEST, UPLOADING_FILE_PROGRESS,
  CREATING_ASSET_REQUEST, CREATING_ASSET_SUCCEEDED, CREATING_ASSET_FAILED, 
  ADD_ASSET_URL, ADD_ASSET_URL_SUCCEDDED, ADD_ASSET_URL_FAILED,

  UPDATING_ASSET, UPDATING_ASSET_SUCCEEDED, UPDATING_ASSET_FAILED,
  UPLOAD_NEW_ASSET_VERSION_REQUEST, UPLOAD_NEW_ASSET_VERSION_SUCCEDDED, UPLOAD_NEW_ASSET_VERSION_FAILED, 
  UPLOADING_NEW_ASSET_VERSION_STARTED, UPLOADING_NEW_ASSET_VERSION_PROGRESS, UPLOAD_NEW_ASSET_VERSION_CANCEL,
  PROCESS_ASSET, PROCESS_ASSET_SUCCESS, PROCESS_ASSET_FAILED,

  UPDATE_FILTERS_IN_CONTENT,

  BULK_UPDATING_ASSETS_REQUEST,  BULK_UPDATING_ASSETS_SUCCEEDED, BULK_UPDATING_ASSETS_FAILED,

  SELECT_ASSET_FOR_PAGE,

  FETCHING_ASSETS, FETCHING_ASSETS_SUCCEEDED, FETCHING_ASSETS_FAILED,
  FETCHING_ASSET, FETCHING_ASSET_SUCCEEDED, FETCHING_ASSET_FAILED,
  FETCHING_ASSET_PREVIEW, FETCHING_ASSET_PREVIEW_SUCCEEDED, FETCHING_ASSET_PREVIEW_FAILED,
  FETCHING_ASSET_PREVIEW_TEXT, FETCHING_ASSET_PREVIEW_TEXT_SUCCEDDED, FETCHING_ASSET_PREVIEW_TEXT_FAILED,
  FETCHING_HTML_PREVIEW_TEMPLATE, FETCHING_HTML_PREVIEW_TEMPLATE_SUCCEEDED, FETCHING_HTML_PREVIEW_TEMPLATE_FAILED,

  RECORDING_ASSET_SESSION_EVENT_REQUEST, RECORDING_ASSET_SESSION_EVENT_SUCCEDDED, RECORDING_ASSET_SESSION_EVENT_FAILED,
  RECORDING_ASSET_ANALYTICS_EVENT_REQUEST, RECORDING_ASSET_ANALYTICS_EVENT_SUCCEDDED, RECORDING_ASSET_ANALYTICS_EVENT_FAILED,
  CAPTURED_ASSET_VIDEO_NEW_RANGE, UPDATED_ASSET_VIDEO_RANGE, REMOVED_ASSET_VIDEO_RANGE,
  SYNCING_ASSET_VIEW_RANGE_REQUEST, SYNCING_ASSET_VIEW_RANGE_SUCCEDDED, SYNCING_ASSET_VIEW_RANGE_FAILED,

  SEARCH_ASSETS, SEARCHING_ASSETS_SUCCEEDED, SEARCHING_ASSETS_FAILED, 
  INVALIDATE_ASSET_SEARCH,
  
  DELETING_ASSETS_REQUEST, DELETING_ASSETS_SUCCEEDED, DELETING_ASSETS_FAILED,

  SELECT_ASSET, DESELECT_ASSET, SELECT_ALL_ASSETS, DESELECT_ALL_ASSETS, INVALIDATE_ASSET_SELECTION,

  FETCHING_CONTENT_SUGGESTIONS, FETCHING_CONTENT_SUGGESTIONS_SUCCEEDED, FETCHING_CONTENT_SUGGESTIONS_FAILED,

  FETCHING_CONTENT_ANALYTICS, FETCHING_CONTENT_ANALYTICS_SUCCEEDED, FETCHING_CONTENT_ANALYTICS_FAILED
} from 'constants/actionTypes';

import axios from 'axios';
import { createAction } from 'redux-actions';
import buildUrl from 'build-url';
import { call, put, takeLatest, takeEvery } from 'redux-saga/effects';

const apiUrl = `${process.env.REACT_APP_API_URL}/api/2.0`;
const ASSETS_BASE_URL = `${apiUrl}/assets`;
const CONTENT_BASE_URL = `${apiUrl}/content`;
const FILE_UPLOAD_BASE_URL = `${apiUrl}/file_upload`;
const ANALYTICS_URL_BASE_URL = `${apiUrl}/analytics`;
const SEARCH_URL = `${apiUrl}/content/search`;

/*
* Add Asset from Paperflite
*/
export const addAssets = createAction(ADDING_ASSETS_REQUEST, (assets) => {
  return assets;
})

const addAssetsSucceeded = createAction(ADDING_ASSETS_SUCCEEDED, (assets, response) => {
  return {
    assets: assets,
    response: response
  }
})

const addAssetsFailed = createAction(ADDING_ASSETS_FAILED, (assets, error) => {
  return {
    assets: assets,
    error: error
  }
})

function* addAssetsSaga(action) {
  var assets = action.payload;

  var assetIds = assets.map(item => {
    return item.id;
  })

  const data = assetIds;

  const addAssetsAPI = () => {
    return axios.put(CONTENT_BASE_URL,
      data
    ).then(response => response.data);
  }

  try {
    const response = yield call(addAssetsAPI);
    if (response) {
      yield put(addAssetsSucceeded(assets, response));
    }
  } catch (error) {
    console.log(error);
    yield put(addAssetsFailed(assets, error));
  }
}

/*
* Create Asset from presigned s3 file
*/
export const createAsset = createAction(CREATING_ASSET_REQUEST, (url, file) => {
  return {
    file: file,
    url: url
  }
})

const creatingAssetSucceeded = createAction(CREATING_ASSET_SUCCEEDED, (url, file, json) => {
  return {
    asset: json,
    url: url,
    file: file
  }
})

const creatingAssetFailed = createAction(CREATING_ASSET_FAILED, (url, file, error) => {
  return {
    error: error,
    url: url,
    file: file
  }
});

function* createAssetSaga(action){
    const creatingAssetAPI = () => {
        var data = {
          signedUrl: action.payload.url
        }
        return axios.post(
          FILE_UPLOAD_BASE_URL,
          data
        ).then((response) => {
          return response.data;
        })
        .catch(err => {
          throw err;
        });
    }

    try {
      const response = yield call(creatingAssetAPI);
      if(response){
          yield put(creatingAssetSucceeded(action.payload.url, action.payload.file, response));
          yield put(addAssets([response]));
      }
    } catch (error) {
        yield put(creatingAssetFailed(action.payload.url, action.payload.file, error));
    }
  }
  
  
export const uploadingFile = createAction(UPLOADING_FILE_REQUEST, (file) => {
  return {
    file: file
  }
})

/*
* Track file upload to s3
*/
export const uploadingFileProgress = createAction(UPLOADING_FILE_PROGRESS, (file, progress) => {
  return {
    progress: progress,
    file: file
  }
});

/*
* Create Asset from url
*/
export const createAssetFromUrl = createAction(ADD_ASSET_URL, (url) => {
  return {
    url: url,
    type: 'url'
  }
})

const creatingAssetFromUrlSuccedded = createAction(ADD_ASSET_URL_SUCCEDDED, (url, json) => {
  return {
    asset: json,
    url: url
  }
})

const creatingAssetFromUrlFailed = createAction(ADD_ASSET_URL_FAILED, (url, error) => {
  return {
    error: error,
    url: url
  }
})

function* createAssetFromUrlSaga(action){
  const createAssetFromUrlAPI = () => {

    let url = buildUrl(ASSETS_BASE_URL, {
      path: `/url`
    });

    var data = {
      url: action.payload.url,
      type: action.payload.type
    }

    return axios.post(
      url,
      data,
      {params: data}
    ).then((response) => {
      return response.data;
    })
    .catch(err => {
      throw err;
    });
  }

  try {
    const response = yield call(createAssetFromUrlAPI);
    if(response){
      yield put(creatingAssetFromUrlSuccedded(action.payload.url, response));
      yield put(addAssets([response]));
    }
  } catch (error) {
      yield put(creatingAssetFromUrlFailed(action.payload.url, error));
  }
}

/*
* Update Asset
*/
export const updateAsset = createAction(UPDATING_ASSET, (contextId, request, callback) => {
  return {
    contextId: contextId,
    request: request,
    callback: callback
  }
})

const updateAssetSucceeded = createAction(UPDATING_ASSET_SUCCEEDED, (contextId, asset) => {
  return {
    contextId: contextId,
    asset: asset
  }
})

const updateAssetFailed = createAction(UPDATING_ASSET_FAILED, (contextId, error) => {
  return {
    contextId: contextId,
    error: error
  }
})

function* updateAssetSaga(action) {
  var { contextId, request, callback } = action.payload;

  let url = buildUrl(CONTENT_BASE_URL, {
    path: request.id
  });

  const updateAssetAPI = () => {
    return axios.put(url, request).then(response => response.data);
  }

  try {
    const response = yield call(updateAssetAPI);
    if (response) {
      yield put(updateAssetSucceeded(contextId, response));
      callback(response);
    }
  } catch (error) {
    yield put(updateAssetFailed(contextId, error));
  }
}

/*
* Upload New Asset Version
*/
export const uploadNewVersion = createAction(UPLOAD_NEW_ASSET_VERSION_REQUEST, (contextId, id, signedUrl, file) => {
  return {
    contextId: contextId,
    id: id,
    signedUrl: signedUrl,
    file: file
  }
})

const uploadNewVersionSucceeded = createAction(UPLOAD_NEW_ASSET_VERSION_SUCCEDDED, (contextId, asset) => {
  return {
    contextId: contextId,
    asset: asset
  }
})

const uploadNewVersionFailed = createAction(UPLOAD_NEW_ASSET_VERSION_FAILED, (contextId, error) => {
  return {
    contextId: contextId,
    error: error
  }
})

function* uploadNewVersionSaga(action) {
  var { contextId, id, signedUrl } = action.payload;

  var data = {
    signedUrl: signedUrl
  }

  let url = buildUrl(ASSETS_BASE_URL, {
    path: `/${id}/version`
  });

  const uploadNewVersionAPI = () => {
    return axios.post(url, data).then(response => response.data);
  }

  try {
    const response = yield call(uploadNewVersionAPI);
    if (response) {
      yield put(uploadNewVersionSucceeded(contextId, response));
    }
  } catch (error) {
    yield put(uploadNewVersionFailed(contextId, error));
  }
}

export const uploadingNewVersionStarted = createAction(UPLOADING_NEW_ASSET_VERSION_STARTED, (contextId, file) => {
  return {
    contextId: contextId, 
    file: file
  }
})

export const uploadingNewVersionProgress = createAction(UPLOADING_NEW_ASSET_VERSION_PROGRESS, (contextId, progress) => {
  return {
    contextId: contextId,
    progress: progress
  };
})

export const cancelNewVersionUpload = createAction(UPLOAD_NEW_ASSET_VERSION_CANCEL, (contextId, file) => {
  return {
    contextId: contextId,
    file: file
  };
})


export const processAsset = createAction(PROCESS_ASSET, (contextId, id) => {
  return {
    contextId: contextId,
    assetId: id
  }
})

export const processAssetSuccess = createAction(PROCESS_ASSET_SUCCESS, (contextId, response) => {
  return {
    contextId: contextId,
    response: response
  };
})

export const processAssetFailed = createAction(PROCESS_ASSET_FAILED, (contextId, error) => {
  return {
    contextId: contextId,
    error: error
  }
})

// processed Asset
function* processAssetSaga(action) {
  var { contextId, assetId } = action.payload;

  let url = buildUrl(CONTENT_BASE_URL, {
    path: `/${assetId}/process`
  });
  
  const processAssetAPI = () => {
    return axios.put(url).then(response => response.data);
  }

  try {
    const response = yield call(processAssetAPI);
    if (response) {
      yield put(processAssetSuccess(contextId, response));
    }
  } catch (error) {
    yield put(processAssetFailed(contextId, error));
  }
}

/*
* Bulk update assets
*/
export const bulkUpdateAssets = createAction(BULK_UPDATING_ASSETS_REQUEST, (request, callback) => {
  return {
    request: request,
    callback: callback
  }
})

const bulkUpdateAssetsSucceeded = createAction(BULK_UPDATING_ASSETS_SUCCEEDED)

const bulkUpdateAssetsFailed = createAction(BULK_UPDATING_ASSETS_FAILED, (error) => {
  return {
    error: error
  }
})

function* bulkUpdateAssetSaga(action) {
  var { request, callback } = action.payload;

  let url = buildUrl(CONTENT_BASE_URL, {
    path: `bulk`
  });

  const bulkUpdateAssetAPI = () => {
    return axios.put(url, request).then(response => response.data);
  }

  try {
    const response = yield call(bulkUpdateAssetAPI);
    yield put(bulkUpdateAssetsSucceeded());
    callback();
  } catch (error) {
    yield put(bulkUpdateAssetsFailed(error));
  }
}


/*
* Fetch all assets by cluster
*/
export const fetchAssets = createAction(FETCHING_ASSETS, (request, page, limit) => {
  return {
    request: request,
    page: page,
    limit: limit
  }
})

const fetchAssetsSucceeded = createAction(FETCHING_ASSETS_SUCCEEDED, (request, page, limit, response) => {
  return {
    request: request,
    page: page,
    limit: limit,
    response: response
  }
})

const fetchAssetsFailed = createAction(FETCHING_ASSETS_FAILED, (request, page, limit, error) => {
  return {
    request: request,
    page: page,
    limit: limit,
    error: error
  }
})

function* fetchAssetsSaga(action) {
  const { request, page, limit } = action.payload;

  var params = {
    page: page,
    limit: limit || 20
  }

  let url = buildUrl(CONTENT_BASE_URL, {
    queryParams: params
  });

  const fetchAssetsAPI = () => {
    return axios.post(url, request).then(response => response.data);
  }

  try {
    const response = yield call(fetchAssetsAPI);
    yield put(fetchAssetsSucceeded(request, page, limit, response));
  } catch (error) {
    yield put(fetchAssetsFailed(request, page, limit, error));
  }
}

/*
* Fetch asset by id
*/
export const fetchAsset = createAction(FETCHING_ASSET, (id, contextId,  callback) => {
  return {
    id: id,
    contextId: contextId,
    callback: callback
  };
});

export const fetchAssetSucceeded = createAction(FETCHING_ASSET_SUCCEEDED, (contextId, asset) => {
  return {
    contextId: contextId,
    asset: asset
  };
})

export const fetchAssetFailed = createAction(FETCHING_ASSET_FAILED, (contextId, error) => {
  return {
    contextId: contextId,
    error: error
  };
})

function* fetchAssetSaga(action) {
  var { id, contextId,  callback} = action.payload;

  let url = buildUrl(CONTENT_BASE_URL, {
    path: id
  });

  const fetchAssetAPI = () => {
    return axios.get(url).then(response => response.data);
  }

  try {
    const response = yield call(fetchAssetAPI);
    yield put(fetchAssetSucceeded(contextId, response));
    if(callback) {
      callback(response);
    }
  } catch (error) {
    yield put(fetchAssetFailed(contextId, error));
  }
}

/*
* Select Asset for page
*/
export const selectAssetForPage = createAction(SELECT_ASSET_FOR_PAGE, (contextId, asset) => {
  return {
    contextId: contextId,
    asset: asset
  };
})

/*
* Fetch asset preview
*/
export const fetchPreviewAsset = createAction(FETCHING_ASSET_PREVIEW, (id, contextId, callback) => {
  return {
    id: id,
    contextId: contextId,
    callback: callback
  };
})

const fetchPreviewAssetSucceeded = createAction(FETCHING_ASSET_PREVIEW_SUCCEEDED, (id, contextId,  result) => {
  return {
    id: id, 
    contextId: contextId,
    result: result
  }
})

const fetchPreviewAssetFailed = createAction(FETCHING_ASSET_PREVIEW_FAILED, (id, contextId, error) => {
  return {
    id: id,
    contextId: contextId,
    error: error
  }
})

function* fetchPreviewAssetSaga(action) {
  const { id, contextId, callback} = action.payload;

  let url = buildUrl(CONTENT_BASE_URL, {
    path: `${id}/preview`
  });

  const fetchPreviewAssetAPI = () => {
    return axios.get(url).then(response => response.data);
  }

  try {
    const response = yield call(fetchPreviewAssetAPI);
    yield put(fetchPreviewAssetSucceeded(id, contextId, response));
    
    if(callback) {
      callback(response);
    }
  } catch (error) {
    yield put(fetchPreviewAssetFailed(id, contextId, error));
  }
}

/*
* Fetch asset preview text
*/
export const fetchPreviewText = createAction(FETCHING_ASSET_PREVIEW_TEXT, (id, contextId) => {
  return {
    id: id,
    contextId: contextId
  };
});

const fetchPreviewTextSucceeded = createAction(FETCHING_ASSET_PREVIEW_TEXT_SUCCEDDED, (id, contextId, result) => {
  return  {
    id: id,
    contextId: contextId,
    result: result
  }
})

const fetchPreviewTextFailed = createAction(FETCHING_ASSET_PREVIEW_TEXT_FAILED, (id, contextId, error) => {
  return {
    id: id,
    contextId: contextId,
    error: error
  }
})

function* fetchPreviewTextSaga(action) {
  var {id, contextId} = action.payload;

  let url = buildUrl(CONTENT_BASE_URL, {
    path: `${id}/preview/text`
  });

  const fetchPreviewTextAPI = () => {
    return axios.get(url).then(response => response.data);
  }

  try {
    const response = yield call(fetchPreviewTextAPI);
    yield put(fetchPreviewTextSucceeded(id, contextId, response));
  } catch (error) {
    yield put(fetchPreviewTextFailed(id, contextId, error));
  }
}

/*
* Fetch the asset preview html template
*/
export const fetchHtmlPreview = createAction(FETCHING_HTML_PREVIEW_TEMPLATE, (contextId, url) => {
  return {
    contextId: contextId,
    url: url
  };
})

const fetchHtmlPreviewSucceeded = createAction(FETCHING_HTML_PREVIEW_TEMPLATE_SUCCEEDED, (contextId, preview) => {
  return {
    contextId: contextId,
    preview: preview
  };
})

const fetchHtmlPreviewFailed = createAction(FETCHING_HTML_PREVIEW_TEMPLATE_FAILED, (contextId, error) => {
  return {
    contextId: contextId,
    error: error
  };
})

function* fetchHtmlPreviewSaga(action) {
  var {contextId, url} = action.payload;

  const fetchHtmlPreviewAPI = () => {
    return axios.get(url).then(response => response.data);
  }

  try {
    const response = yield call(fetchHtmlPreviewAPI);
    yield put(fetchHtmlPreviewSucceeded(contextId, response));
  } catch (error) {
    yield put(fetchHtmlPreviewFailed(contextId, error));
  }
}

/**
* Start asset view session
*/
export const recordSessionAnalyticsEvent = createAction(RECORDING_ASSET_SESSION_EVENT_REQUEST, (contextId, event) => {
  return {
    contextId: contextId,
    event: event
  }
})

const recordSessionAnalyticsEventSucceeded = createAction(RECORDING_ASSET_SESSION_EVENT_SUCCEDDED, (contextId, response) => {
  return {
    contextId: contextId,
    response: response
  };
})

const recordSessionAnalyticsEventFailed = createAction(RECORDING_ASSET_SESSION_EVENT_FAILED, (contextId, error) => {
  return {
    contextId: contextId,
    error: error
  };
})

function* recordSessionAnalyticsEventSaga(action) {
  var {contextId, event} = action.payload;

  const recordSessionAnalyticsEventAPI = () => {
    return axios.post(ANALYTICS_URL_BASE_URL, event).then(response => response.data);
  }

  try {
    const response = yield call(recordSessionAnalyticsEventAPI);
    yield put(recordSessionAnalyticsEventSucceeded(contextId, response));
  } catch (error) {
    yield put(recordSessionAnalyticsEventFailed(contextId, error));
  }
}

/**
* Create an analytics event
*/
export const recordAnalyticsEvent = createAction(RECORDING_ASSET_ANALYTICS_EVENT_REQUEST, event => {
  return event;
})

const recordAnalyticsEventSucceeded = createAction(RECORDING_ASSET_ANALYTICS_EVENT_SUCCEDDED, response => {
  return response;
})

const recordAnalyticsEventFailed = createAction(RECORDING_ASSET_ANALYTICS_EVENT_FAILED, error => {
  return error;
})

function* recordAnalyticsEventSaga(action) {
  var event = action.payload;

  const recordAnalyticsEventAPI = () => {
    return axios.post(ANALYTICS_URL_BASE_URL, event).then(response => response.data);
  }

  try {
    const response = yield call(recordAnalyticsEventAPI);
    yield put(recordAnalyticsEventSucceeded(response));
  } catch (error) {
    yield put(recordAnalyticsEventFailed(error));
  }
}

/**
* -----------------------------------------------------
*   Video Analytics
*   - Capture viewing new range in video, returns an
*     internal id
* -----------------------------------------------------
*/
export const captureNewVideoRange = createAction(CAPTURED_ASSET_VIDEO_NEW_RANGE, (contextId, startRange) => {
  var id = '_' + Math.random().toString(36).substr(2, 9);

  return {
    contextId: contextId,
    id: id,
    external_id: null,
    start: startRange,
    end: startRange,
    synced: false,
    syncing: false,
    updatedDate: new Date().getTime(),
    syncedDate: null
  }
});

/**
* -----------------------------------------------------
*   Video Analytics
*   - update range in video
* -----------------------------------------------------
*/
export const updateVideoRange = createAction(UPDATED_ASSET_VIDEO_RANGE, (contextId, id, endRange) => {
  return {
    contextId: contextId,
    id: id,
    end: endRange,
    updatedDate: new Date().getTime()
  }
})

/**
* -----------------------------------------------------
*   Video Analytics
*   - remove video range after sync
* -----------------------------------------------------
*/
export const removeVideoRange = createAction(REMOVED_ASSET_VIDEO_RANGE, (contextId, id) => {
  return {
    contextId: contextId,
    id: id
  }
})

/**
* -----------------------------------------------------
*   Video Analytics
*   - Sync range with the server
* -----------------------------------------------------
*/
export const syncAssetVideoRange = createAction(SYNCING_ASSET_VIEW_RANGE_REQUEST, (contextId, id, event) => {
  return {
    contextId: contextId,
    id: id,
    event: event
  }
})

const syncAssetVideoRangeSucceeded = createAction(SYNCING_ASSET_VIEW_RANGE_SUCCEDDED, (contextId, id, range) => {
  return {
    contextId: contextId,
    id: id, 
    range: range
  }
})

const syncAssetVideoRangeFailed = createAction(SYNCING_ASSET_VIEW_RANGE_FAILED, (contextId, id, error) => {
  return {
    contextId: contextId,
    id: id,
    error: error
  }
})

function* syncAssetVideoRangeSaga(action) {
  var { id, contextId, event} = action.payload;

  const syncAssetVideoRangeAPI = () => {
    return axios.post(ANALYTICS_URL_BASE_URL, event).then(response => response.data);
  }

  try {
    const response = yield call(syncAssetVideoRangeAPI);
    yield put(syncAssetVideoRangeSucceeded(contextId, id, response));
  } catch (error) {
    yield put(syncAssetVideoRangeFailed(contextId, id, error));
  }
}

/**
 * Updating filters on change
 * 
*/
export const updateFiltersInContent = createAction(UPDATE_FILTERS_IN_CONTENT, (filters) => {
  return {
    filters: filters
  }
});


/**
* Search Assets
*/
export const searchAssets = createAction(SEARCH_ASSETS, (query, page) => {
  return {
    query: query,
    page: page
  }
});

const searchAssetsSucceeded = createAction(SEARCHING_ASSETS_SUCCEEDED, (query, response) => {
  return {
    query: query,
    response: response
  }
});

const searchAssetsFailed = createAction(SEARCHING_ASSETS_FAILED, (query, error) => {
  return {
    query: query,
    error: error
  }
});

export const invalidateAssetSearch = createAction(INVALIDATE_ASSET_SEARCH);

function* searchAssetsSaga(action) {
  const {query, page=0} = action.payload;

  var params = {
    page: page
  }

  let url = buildUrl(SEARCH_URL, {
    queryParams: params
  });

  let request = {
    query: query
  }

  const searchAssetsAPI = () => {
    return axios.post(url, request).then(response => response.data);
  }

  try {
    const response = yield call(searchAssetsAPI);
    yield put(searchAssetsSucceeded(query, response));
  } catch (error
    ) {
    yield put(searchAssetsFailed(query, error));
  }
}


/**
* Delete Assets
*/
export const deleteAssets = createAction(DELETING_ASSETS_REQUEST, (request, callback) => {
  return {
    request: request,
    callback: callback
  };
})

const deleteAssetsSucceeded = createAction(DELETING_ASSETS_SUCCEEDED, (request) => {
  return request;
})

const deleteAssetsFailed = createAction(DELETING_ASSETS_FAILED, (error) => {
  return error;
})

function* deleteAssetsSaga(action) {
  var { request, callback } = action.payload;
  
  const deleteAssetsAPI = () => {
    return axios.delete(CONTENT_BASE_URL, {
      data: request
    }).then(response => response.data);
  }

  try {
    const response = yield call(deleteAssetsAPI);
    yield put(deleteAssetsSucceeded(request));
    if(callback){
      callback();
    }
  } catch (error) {
    console.log(error);
    yield put(deleteAssetsFailed(error));
  }
}

export const selectAsset = createAction(SELECT_ASSET, (assets) => {
  return assets;
})

export const deselectAsset = createAction(DESELECT_ASSET, (asset) => {
  return asset;
})

export const selectAllAssets = createAction(SELECT_ALL_ASSETS);
export const deselectAllAssets = createAction(DESELECT_ALL_ASSETS);
export const invalidateAssetSelection = createAction(INVALIDATE_ASSET_SELECTION);

/**
* Fetch content suggestions
*/
export const fetchContentSuggestions = createAction(FETCHING_CONTENT_SUGGESTIONS, (query) => {
  return query;
});

const fetchContentSuggestionsSucceeded = createAction(FETCHING_CONTENT_SUGGESTIONS_SUCCEEDED, (query, response) => {
  return { query, response };
});

const fetchContentSuggestionsFailed = createAction(FETCHING_CONTENT_SUGGESTIONS_FAILED, (query, error) => {
  return {query, error};
});

function* fetchContentSuggestionsSaga(action) {
  const query = action.payload;

  var params = {
    query: query
  }


  let url = buildUrl(`${CONTENT_BASE_URL}/search/suggestions`, {
    queryParams: params
  });

  const fetchContentSuggestionsAPI = () => {
    return axios.get(url).then(response => response.data);
  }

  try {
    const response = yield call(fetchContentSuggestionsAPI);
    yield put(fetchContentSuggestionsSucceeded(query, response));
  } catch (error
  ) {
    yield put(fetchContentSuggestionsFailed(query, error));
  }
}

/**
* Fetch content analytics
*/
export const fetchContentAnalytics = createAction(FETCHING_CONTENT_ANALYTICS, (assets) => {
  return assets;
})

const fetchContentAnalyticsSucceeded = createAction(FETCHING_CONTENT_ANALYTICS_SUCCEEDED, (assets, response) => {
  return { assets, response };
})

const fetchContentAnalyticsFailed = createAction(FETCHING_CONTENT_ANALYTICS_FAILED, (assets, error) => {
  return { assets, error };
})

function* fetchContentAnalyticsSaga(action) {
  const assets = action.payload;

  var params = {
    assets: assets
  }

  let url = buildUrl(`${CONTENT_BASE_URL}/analytics`, {
    queryParams: params
  });

  const fetchContentAnalyticsAPI = () => {
    return axios.get(url).then(response => response.data);
  }

  try {
    const response = yield call(fetchContentAnalyticsAPI);
    yield put(fetchContentAnalyticsSucceeded(assets, response));
  } catch (error
  ) {
    yield put(fetchContentAnalyticsFailed(assets, error));
  }
}

export default function* contentSaga() {
  yield takeEvery(ADDING_ASSETS_REQUEST, addAssetsSaga);
  yield takeEvery(CREATING_ASSET_REQUEST, createAssetSaga);
  yield takeEvery(ADD_ASSET_URL, createAssetFromUrlSaga);
  yield takeLatest(FETCHING_ASSETS, fetchAssetsSaga);
  yield takeLatest(FETCHING_ASSET, fetchAssetSaga);
  yield takeLatest(FETCHING_ASSET_PREVIEW, fetchPreviewAssetSaga);
  yield takeLatest(FETCHING_ASSET_PREVIEW_TEXT, fetchPreviewTextSaga);
  yield takeLatest(FETCHING_HTML_PREVIEW_TEMPLATE, fetchHtmlPreviewSaga);
  yield takeEvery(RECORDING_ASSET_SESSION_EVENT_REQUEST, recordSessionAnalyticsEventSaga);
  yield takeEvery(RECORDING_ASSET_ANALYTICS_EVENT_REQUEST, recordAnalyticsEventSaga);
  yield takeEvery(SYNCING_ASSET_VIEW_RANGE_REQUEST, syncAssetVideoRangeSaga);
  yield takeLatest(SEARCH_ASSETS, searchAssetsSaga);
  yield takeEvery(UPDATING_ASSET, updateAssetSaga);
  yield takeEvery(BULK_UPDATING_ASSETS_REQUEST, bulkUpdateAssetSaga);
  yield takeEvery(UPLOAD_NEW_ASSET_VERSION_REQUEST, uploadNewVersionSaga);
  yield takeEvery(PROCESS_ASSET, processAssetSaga);
  yield takeEvery(DELETING_ASSETS_REQUEST, deleteAssetsSaga);  
  yield takeLatest(FETCHING_CONTENT_SUGGESTIONS, fetchContentSuggestionsSaga);
  yield takeEvery(FETCHING_CONTENT_ANALYTICS, fetchContentAnalyticsSaga);
};
