import React, { Fragment, useState, useEffect, useMemo } from 'react';
import {useDispatch, useSelector} from 'react-redux';

import { fetchPages } from 'actions/pages';
import _ from 'lodash';
import { guid } from 'services/util';

import cx from 'classnames';

import {
  Icon,
  Segment,
  Input,
  Label,
  Grid,
  Dropdown,
  Search,
  Header,
} from 'semantic-ui-react';

import Truncate from 'react-truncate';

import styles from './filters.module.scss';
import { style } from 'd3-selection';

const properties = [{
  type: 'page',
  name: 'Page',
  data_type: 'page',
  config: {

  }
}]

const conditions = [{
  type: 'page',
  options:[{
    key: 'is',
    text: 'Is'
  },{
    key: 'is_not',
    text: 'Is Not'
  }]
}]

function getPropertyByKey(properties, value){
  var property = properties.find(property => {
    return _.isEqual(property.value, value);
  })

  return property;
}

function getConditionsForProperty(property){
  if(!(property && property.data_type)){
    return [];
  }

  const filter = conditions.find(item => {
    return item.type === property.data_type;
  });

  if(!filter){
    return [];
  }

  return filter.options || [];
}

function getDefaultConditionForProperty(property){
  const conditions = getConditionsForProperty(property);
  if(conditions.length == 0){
    return '';
  }

  return conditions[0].key;
}

function processfiltersForRequest(filters) {
  var updatedFilters = (filters || []).map(filter => {
    var property = filter.property;
    var operator = filter.operator;
    var value = filter.value;

    if(filter.property.type === 'asset'){
      value = (filter.value || []).map(item => item.id);
    }

    return {
      property: property,
      operator: operator,
      value: value  
    }
  });

  return updatedFilters;
}

const PropertyContext = React.createContext([]);

export function Filters({value, cluster, createDisabled, editable, className, onChange = () => {}}){
  const dispatch = useDispatch();


  var filters = (value || []).map(filter => {
    if(!filter._id){
      return {
        ...filter,
        _id: guid()
      }
    }

    return filter;
  });

  /**
   * Properties
  */
  const propertiesOptions = useMemo(() => {
    const basicProperties = properties.map(item => {
      return {
        ...item,
        key: item.type,
        value: {
          type: item.type
        }
      }
    })
    return basicProperties;
  }, []);


  /**
   * Custom functions
  */
  const insertFilter = (index = -1) => {
    const filter = {
      _id: guid(),
      property: propertiesOptions[0].value,
      operator: getDefaultConditionForProperty(propertiesOptions[0])
    }

    var updated = [...filters];
    if(index < 0){
      updated = [...filters, filter];
    }else{
      updated.splice(index + 1, 0, filter);
    }
    onChange(updated);
  }

  const deleteFilter = (index) => {
    var updated = [...filters];
    updated.splice(index, 1);
    onChange(updated)
  }

  const onFilterChange = (filter) => {
    var updated = filters.map((item, index) => {
      if(item._id === filter._id){
        return filter;
      }

      return item;
    });

    onChange(updated);
  }

  return (
    <Segment basic className={cx(styles.filters, className)}>
      <Grid>
        <PropertyContext.Provider value={propertiesOptions}>
          {filters.map((filter, index) => {
            return (
              <Filter 
                key={index}
                index={index}
                value={filter} 
                editable={editable}
                onChange={onFilterChange}
                onInsert={insertFilter} 
                onDelete={deleteFilter} 
              />
            )
          })}
        </PropertyContext.Provider>
      </Grid>
    </Segment>
  );
}

export function Filter({value, index, editable, onChange, onInsert, onDelete}){
  const properties = React.useContext(PropertyContext);
  const property = getPropertyByKey(properties, value.property);

  const handlePropertyTypeChange = (item) =>{
    var filter = {
      _id: value._id,
      property: item,
      operator: getDefaultConditionForProperty(property),
      value: null
    };

    onChange(filter);
  }

  const handleConditionChange = (operator) => {
    var filter = {
      ...value,
      operator: operator 
    };

    onChange(filter);
  }

  const handleValueChange = (changedValue) => {
    var filter = {
      ...value,
      value: changedValue,
      input: ''
    };

    onChange(filter);
  }

  const handleInputChange = (input) => {
    var filter = {
      ...value, 
      input: input
    };

    onChange(filter);
  }

  return (
    <Grid.Row columns="equal" className={styles.filter}>
      <Grid.Column className={styles.compact} >
        <PropertyTypeFilter value={value.property} onChange={handlePropertyTypeChange} editable={editable} />
      </Grid.Column>
      <Grid.Column className={styles.compact}>
        <ConditionFilter value={value} onChange={handleConditionChange} editable={editable} />
      </Grid.Column>
      <Grid.Column className={styles.values}>
        <ValueComponent property={value.property} 
          value={value.value} 
          input={value.input} 
          editable={editable}
          onChange={handleValueChange} 
          onInputChange={handleInputChange} 
        />
      </Grid.Column>
    </Grid.Row>
  );
}
Filters.Filter = Filter;

function PropertyTypeFilter({value, editable, onChange}){
  const properties = React.useContext(PropertyContext);
  const property = getPropertyByKey(properties, value);
  const selectedValue = _.get(property, 'key');

  const options = useMemo(() => {
    return properties.map(p => {
      return {
        key: p.key,
        text: p.name,
        value: p.key
      }
    })
  }, [properties]);

  const handleChange = (e, data) => {
    const selectedOption = properties.find(p => {
      return p.key === data.value;
    })

    onChange(selectedOption.value);
  }

  return (
    <Dropdown
      className={styles.dropdown}
      placeholder='Select a property...'
      search
      selection
      disabled={!editable}
      options={options}
      value={selectedValue}
      onChange={handleChange}
    >
    </Dropdown>  
  );
}

function ConditionFilter({value, editable, onChange}){
  const properties = React.useContext(PropertyContext);
  const property = getPropertyByKey(properties, value.property);

  var conditionOptions = getConditionsForProperty(property).map(option => {
    return {key: option.key, value: option.key, text: option.text};
  });

  const handleConditionChange = (e, data) => {
    const value = data.value;
    onChange(value);
  }

  return (
    <Dropdown
      className={cx(styles.dropdown, styles.condition)}
      selection
      compact
      disabled={!editable}
      value={value.operator}
      options={conditionOptions}
      onChange={handleConditionChange}
    />
  );
}

/**
 * Value Input
 * 
*/

function ValueComponent({property, value, editable, onChange, input, onInputChange}){
  const properties = React.useContext(PropertyContext);
  const propertyConfig = getPropertyByKey(properties, property);

  var type = propertyConfig ? propertyConfig.data_type : null;
  if(type === 'page') {
    return (
      <PageValue property={propertyConfig}
        value={value}
        editable={editable}
        onChange={onChange}
        input={input}
        onInputChange={onInputChange} />
    )
  }

  return (
    <InputValue property={propertyConfig} 
      value={value} 
      editable={editable}
      onChange={onChange} 
      input={input} 
      onInputChange={onInputChange} />
  );
}

function PageValue({ property, value, editable, onChange, input, onInputChange}) {
  const values = value || [];

  const dispatch = useDispatch();
  const searching = useSelector(state => state.pages.loading);
  const pages = useSelector(state => state.pages.items);

  const handleSearchChange = (e, { value }) => {
    dispatch(fetchPages(value, 0, 20, "name", "ASC"));
    onInputChange(value);
  }

  const handlePageSelect = (e, { result }) => {
    var updated = values;
    if (!updated) {
      updated = [];
    }

    if (result) {
      updated = [...updated, result];
      _.uniqBy(updated, 'id');
      onChange(updated);
    }
  }

  const deleteValue = (index) => {
    var updated = [...values];
    updated.splice(index, 1);
    onChange(updated);
  }

  return (
    <>
      {values.map((item, index) => {
        return (
          <Label key={item.id} image className={cx(styles.value)}>
            <Truncate width={200}>{item.name}</Truncate>
            {editable &&
              <Icon name='delete' onClick={deleteValue.bind(this, index)} />
            }
          </Label>
        );
      })}

      {editable &&
        <Search size='mini' className={styles.search}
          loading={searching}
          icon={null}
          placeholder='Type something'
          onSearchChange={_.debounce(handleSearchChange, 500, {
            leading: true,
          })}
          onResultSelect={handlePageSelect}
          results={pages}
          resultRenderer={(result) => {
            return (
              <div className={styles.content}>
                <Header className={styles.pageName} as='h6'><Truncate lines={2}>{result.name}</Truncate></Header>
              </div>
            )
          }}
          value={input}
        />
      }
    </>
  );
}

function InputValue({ property, value, editable, onChange, input, onInputChange}){
  const values = value || [];

  const handleKeyPressed = (e) => {
    if (e.key === "Enter") {
      handleChanges();
    }
  }

  const handleChanges = () => {
    var updated = value;
    if(!updated){
      updated = [];
    }

    if(input && input.trim()){
      updated = [...updated, input.trim()];
      _.uniq(updated);
      onChange(updated);
    }
  }

  const deleteValue = (index) => {
    var updated = [...values];
    updated.splice(index, 1);
    onChange(updated);
  }

  return (
    <Fragment>
      {values.map((item, index) => {
        return (
          <Label key={`${item}-${index}`} image className={cx(styles.value)}>
            {item}
            {editable &&
              <Icon name='delete' onClick={deleteValue.bind(this, index)}/>
            }
          </Label>
        );
      })}

      {editable &&
        <Input className={styles.input} size='mini'
          value={input} 
          placeholder='Type something'
          onChange={(e) => {
            onInputChange(e.target.value);
          }}
          onBlur={handleChanges}
          onKeyPress={handleKeyPressed}
        />
      }
    </Fragment>
  );
}

