export default class FileService {
  static formatSize(bytes) {
    if (bytes >= 1000000000) {
      return (bytes / 1000000000).toFixed(2) + " GB";
    }
    if (bytes >= 1000000) {
      return (bytes / 1000000).toFixed(2) + " MB";
    }
    return (bytes / 1000).toFixed(2) + " KB";
  } 

  static formatDuration(timeSpent){
    let timeSpentValue = `${FileService.padNumber(timeSpent, 1)} Seconds`;

    if (timeSpent >= 60) {
      var minutes = Math.round(timeSpent / 60);
      timeSpentValue = `${FileService.padNumber(minutes, 1)} Minute${
        minutes == 1 ? "" : "s"
      }`;
    }

    return timeSpentValue;
  }

  static padNumber(number = 0, pad) {
    number = Math.ceil(number);

    const length = pad - String(number).length + 1;
    let padding = "";
    if (length > 0) {
      padding = Array(pad - String(number).length + 1).join("0");
    }
    return padding + number;
  }

  static fileType(metadata) {
    switch (metadata.content_type) {
      case "application/pdf":
        return "PDF";
      case "video/mp4":
        return "VIDEO";
      case "image/gif":
      case "image/tiff":
      case "image/png":
      case "image/bmp":
      case "image/jpeg":
      case "image/svg+xml":
        return "IMAGE";
      case "application/vnd.openxmlformats-officedocument.presentationml.presentation":
      case "application/vnd.openxmlformats-officedocument.presentationml.template":
      case "application/vnd.openxmlformats-officedocument.presentationml.slideshow":
      case "application/vnd.ms-powerpoint.addin.macroEnabled.12":
      case "application/vnd.ms-powerpoint.presentation.macroEnabled.12":
      case "application/vnd.ms-powerpoint.template.macroEnabled.12":
      case "application/vnd.ms-powerpoint.slideshow.macroEnabled.12":
        return "PPTX";
      case "application/vnd.ms-powerpoint":
        return "PPT";
      case "application/msword":
        return "DOC";
      case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
      case "application/vnd.openxmlformats-officedocument.wordprocessingml.template":
      case "application/vnd.ms-word.template.macroEnabled.12":
        return "DOCX";
      case "application/vnd.ms-excel":
        return "XLS"
      case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
      case "application/vnd.openxmlformats-officedocument.spreadsheetml.template":
      case "application/vnd.ms-excel.sheet.macroEnabled.12":
      case "application/vnd.ms-excel.template.macroEnabled.12":
      case "application/vnd.ms-excel.addin.macroEnabled.12":
      case "application/vnd.ms-excel.sheet.binary.macroEnabled.12":
        return "XLSX"
      case "application/url":
        if (metadata.external_video_provider) {
          if (metadata.external_video_provider == "youtube") {
            return "YOUTUBE";
          } else if (metadata.external_video_provider == "vimeo") {
            return "VIMEO";
          } else if (metadata.external_video_provider == "wistia") {
            return "WISTIA";
          }
          else if (metadata.external_video_provider == "vidyard") {
            return "VIDYARD";
          }
        }
        return "LINK";
      case 'audio/mp3':
      case 'audio/mpeg':
      case 'audio/aiff':
      case 'audio/flac':
      case 'audio/x-aiff':
        return 'AUDIO';  
      default:
        return "";
    }
  }

  static hasPageAnalytics(metadata) {
    switch (metadata.content_type) {
      case "application/pdf":
      case "application/vnd.openxmlformats-officedocument.presentationml.presentation":
      case "application/vnd.openxmlformats-officedocument.presentationml.template":
      case "application/vnd.openxmlformats-officedocument.presentationml.slideshow":
      case "application/vnd.ms-powerpoint.addin.macroEnabled.12":
      case "application/vnd.ms-powerpoint.presentation.macroEnabled.12":
      case "application/vnd.ms-powerpoint.template.macroEnabled.12":
      case "application/vnd.ms-powerpoint.slideshow.macroEnabled.12":
      case "application/vnd.ms-powerpoint":
      case "application/msword":
      case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
      case "application/vnd.openxmlformats-officedocument.wordprocessingml.template":
      case "application/vnd.ms-word.template.macroEnabled.12":
        return true;

      default:
        return false;
    }
  }

  static hasVideoAnalytics(metadata) {
    switch (metadata.content_type) {
      case "video/mp4":
        return true;

      case "application/url":
        if (metadata.external_video_provider) {
          if (
            metadata.external_video_provider == "youtube" ||
            metadata.external_video_provider == "vimeo" ||
            metadata.external_video_provider == "wistia" ||
            metadata.external_video_provider == "vidyard"
          ) {
            return true;
          }
        }

        return false;

      default:
        return false;
    }
  }

  static hasAudioAnalytics(metadata) {
    switch(metadata.content_type){
      case 'audio/mp3':
        return true;

      case 'audio/aiff':
        return true;

      case 'audio/flac':
        return true;  
      
      default:
        return false;
    }
  }

  static hasAnalytics(metadata) {
    return (
      FileService.hasVideoAnalytics(metadata) ||
      FileService.hasPageAnalytics(metadata) || 
      FileService.hasAudioAnalytics(metadata)
    );
  }
}
