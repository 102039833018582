import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Segment, Table, Menu, Button, Icon, Label, Header } from 'semantic-ui-react';

import SidePane from 'components/SidePane';

import _ from 'lodash';
import dateformat from 'dateformat';
import styles from './urlManagement.module.scss';
import UpdateRedirect from 'components/UrlManagement/updateRedirect/index';
import UpdateAlias from 'components/UrlManagement/updateAlias/index';

import cx from 'classnames';

export default function UrlManagementView() {
  const dispatch = useDispatch();
  const aliases = useSelector(state => _.get(state, 'urlManagement.items', []));

  const [alias, setAlias] = useState("");
  const [showUpdateRedirect, setShowUpdateRedirect] = useState(false);
  const [showUpdateAlias, setShowUpdateAlias] = useState(false);


  const editRedirect = (value) => {
    if (value.target) {
      setShowUpdateRedirect(true);
    } else setShowUpdateAlias(true);
    setAlias(value);
  }

  const handleCancel = () => {
    setShowUpdateAlias(false);
    setShowUpdateRedirect(false);
  }

  return (
    <Segment className={styles.urlManagementView}>
      <h1 className={styles.header}>Url Management</h1>
      <SidePane
        right
        open={showUpdateRedirect || showUpdateAlias}
        width='40vw'
        onClose={() => {
          setShowUpdateRedirect(false);
          setShowUpdateAlias(false);
          setAlias("");
        }}
        closeOnDocumentClick={true}>
        {alias.target &&
          <UpdateAlias alias={alias} onClose={handleCancel} />
        }
        {alias.target == null &&
          <UpdateRedirect alias={alias} onClose={handleCancel} />
        }
      </SidePane>
      <Menu>
        <Menu.Menu position='right'>
          <Menu.Item>
            <Button className={styles.createRedirectButton} onClick={() => { setShowUpdateRedirect(true) }}>
              <Icon name='add' /> Create Redirect
            </Button>
          </Menu.Item>
        </Menu.Menu>
      </Menu>
      <Table>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell width={5}>Alias Name</Table.HeaderCell>
            <Table.HeaderCell width={5}>URL</Table.HeaderCell>
            <Table.HeaderCell>Modified on</Table.HeaderCell>
            <Table.HeaderCell>Created by</Table.HeaderCell>
            <Table.HeaderCell width={2}> </Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body className={styles.aliasTable}>
          {aliases.map(alias => {
            return <AliasTableRow alias={alias} />
          })}
        </Table.Body>
      </Table>
    </Segment>
  )
}

const AliasTableRow = React.memo(({ alias }) => {
  const dispatch = useDispatch();

  var aliasType = "Web Page";
  if (alias.entityType == "asset") {
    aliasType = "Asset"
  } else if (alias.entityType == "redirect") {
    aliasType = "Redirect"
  }

  const handleDelete = (value) => {

  }

  const editRedirect = (value) => {

  }

  const name = _.get(alias, 'target.name') || _.get(alias, 'name');


  return (
    <Table.Row className={styles.alias}>
      <Table.Cell width={5} className={styles.aliasName}>
        {alias.entityType === 'page' && <WebPageAlias alias={alias} />}
        {alias.entityType === 'asset' && <AssetAlias alias={alias} />}
      </Table.Cell>
      <Table.Cell width={5} className={styles.aliasURL}>
        {alias.alias}
      </Table.Cell>
      <Table.Cell className={styles.aliasDetails}>
        {dateformat(alias.modifiedDate, 'dd mmm yyyy')}
      </Table.Cell>
      <Table.Cell className={styles.aliasDetails}>
        {alias.createdBy && alias.createdBy.firstName + " " + alias.createdBy.lastName}
      </Table.Cell>
      <Table.Cell width={2}>
        <Icon className={styles.editIcon} name='edit outline' onClick={editRedirect.bind(this, alias)} />
        <Icon className={cx(styles.deleteIcon, alias.canonical && styles.disabled)} name='trash alternate outline' onClick={handleDelete.bind(this, alias.id)} />
      </Table.Cell>
    </Table.Row>
  );
});

const WebPageAlias = React.memo(({ alias }) => {
  const name = _.get(alias, 'target.name');
  const publishedDate = _.get(alias, 'target.publishedDate');

  return (
    <Header className={styles.page} as='h5'>
      <Header.Content>
        {name}
        <Header.Subheader>
          <Label size='tiny'>Web Page</Label> published on {dateformat(publishedDate, 'dd mmm yyyy')}
        </Header.Subheader>
      </Header.Content>
    </Header>
  );
});

const AssetAlias = React.memo(({ alias }) => {
  const name = _.get(alias, 'target.name');
  const publishedDate = _.get(alias, 'target.publishedDate');

  return (
    <Header className={styles.page} as='h5'>
      <Header.Content>
        {name}
        <Header.Subheader>
          <Label size='tiny'>Web Page</Label> published on {dateformat(publishedDate, 'dd mmm yyyy')}
        </Header.Subheader>
      </Header.Content>
    </Header>
  );
})