import React, { useState } from 'react';
import {useDispatch,  useSelector } from 'react-redux';
import { 
  Item, 
  Icon, 
  Input, 
  Button,
  Segment,
  Header,
  Form,
  Label
 } from 'semantic-ui-react';


import { createAssetFromUrl } from 'actions/content';

import {isUrl, fixHTTPUrl} from 'services/asset.service';

import styles from './addAsset.module.scss';

export function AddUrl(props){
  const dispatch = useDispatch();
  const selectedAssets = useSelector(state => state.selection.assets);

  const [url, setUrl] = useState('');
  const [error, setError] = useState(null);

  const handleAddAssetFromUrl = () => {
    console.log(url);
    var fixedUrl = fixHTTPUrl(url);
    console.log(fixedUrl);

    let currentAsset = selectedAssets.find((item) => {
      return fixedUrl === item.asset.url;
    })

    if (currentAsset && currentAsset.error) {
      setError(currentAsset.error.errors[0].message);
      return;
    }

    if (!isUrl(fixedUrl)) {
      setError('Please provide a valid url');
      return;
    } else {
      setError(null);
    }

    dispatch(createAssetFromUrl(fixedUrl));
  }

  return (
    <Segment basic className={styles.addUrl}>
      <Header as='h2'>
        <i aria-hidden="true" className="icon-url icon"></i>  
        <Header.Content as='h2'>
          Add Url
          <Header.Subheader className={styles.subHeading}>
            Type any URL
          </Header.Subheader>
        </Header.Content>
      </Header>

      <Form>
        <Form.Field>
          <Input
            fluid
            placeholder='eg. https://www.paperflite.com'
            error={error !== null}
            value={url}
            onChange={(e) => {
              setUrl(e.target.value);
              setError(null);
            }}
          />
          {error &&
            <Label basic color='red' pointing>
              {error}
            </Label>
           }
        </Form.Field>

        <Button className={styles.addUrlButton} onClick={handleAddAssetFromUrl}>
          <Icon name='add' />
          ADD URL
        </Button>
      </Form>
      
    </Segment>
  );
}

export default AddUrl;