import React, { useState, useEffect} from 'react';

import { Image } from 'semantic-ui-react'
import _ from 'lodash';

import styles from '../../analytics/Analytics.module.scss';

import CarouselView from './carousel/CarouselView';

export default function PageAnalyticsCarouselView(props){

  const [ activePage, setActivePage ] = useState(null)
  const [ activeIndex, setActiveIndex ] = useState(null)
  const [ pages, setPages ] = useState(_.get(props, 'preview.pages', []));
  const [ analytics, setAnalytics]  = useState(null)
  const [ timeSpent, setTimeSpent ] = useState(0)

  useEffect(() => {
    setPages(_.get(props, 'preview.pages', []));
  }, [props.preview]);
  
  var previewItems = props.preview && props.preview.pages || [];

  const handleSlideChange = (next) => {
    if(next === -1 || next >= pages.length) {
      return;
    }

    let page = pages[next];
    console.log(page);
    let analyticsIndex = page.index + 1;
    let analytics = (props.analytics.dataPoints || []).find(item => {
      return item.index === analyticsIndex;
    })

    if(!analytics) {
      analytics = {
        index: analyticsIndex,
        totalTimeSpent: 0
      }
    }

    setAnalytics(analytics)

    if(analytics){
      let minutes = padNumber(Math.floor(analytics.totalTimeSpent / 60), 2);
      let seconds = padNumber(analytics.totalTimeSpent % 60, 2);

      setTimeSpent(`${minutes}:${seconds}`)
    }
  }

  const padNumber = (number = 0, pad) => {
    number = Math.ceil(number);
  
    const length = pad - String(number).length + 1;
    let padding = '';
    if (length > 0) {
      padding = Array(pad - String(number).length + 1).join('0');
    }
    return padding + number;
  }

  return (
    <div className={styles.carouselContainer}>
      <CarouselView id={props.id} itemClass={styles.assetCarousel} slidesToShow={4} onSlideChange={handleSlideChange}>
        {pages && pages.map(
          item =>
            <div key={item.background.thumbnail} className={styles.pageIcon}>
              <Image src={item.background.thumbnail} />
            </div>
        )}
      </CarouselView>

      {analytics &&
        <div className={styles.analytics}>
          <p>{`#${analytics.index} | Time spent ${timeSpent}`}</p>
        </div>
      }
    </div>
  )

}
