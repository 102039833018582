import React, { useState, useEffect } from 'react';
import Lottie from 'react-lottie';

export default function AfterEffect({url, width, height, play, loop, ...rest}){
  const [loading,  setLoading] = useState(true);
  const [stop, setStop] = useState(false);
  const [data,  setData] = useState(null);

  const [, updateComponent] = React.useState();
  const forceUpdateComponent = React.useCallback(() => updateComponent({}), []);
  
  useEffect(() => {
    forceUpdateComponent();
  }, [play, loop])

  useEffect(() => {
    setLoading(true);
    setData(null);

    if(url){
      fetch(url)
      .then(response => response.json())
      .then(data => {
        setData(data);
        setLoading(false);
      });
    }
  }, [url]);

  const options = {
    loop: loop,
    autoplay: play == "autoPlay" ? true : false,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice"
    },
    ...rest,
    animationData: data
  }

  console.log("after effect options " + JSON.stringify(options));

  return (
    <div
      onMouseEnter={() => play == "onHover" ? setStop(false) : ""}
      onMouseLeave={() => play == "onHover" ? setStop(true) : ""}>
    <Lottie
      options={options}
      height={parseInt(height)}
      width={parseInt(width)}
      style={{ margin: "0px" }}
      isStopped={stop}
    />
    </div>
  );
}