import { 
  AUTHENTICATE_HUBSPOT_REQUEST, AUTHENTICATE_HUBSPOT_SUCCEDDED, AUTHENTICATE_HUBSPOT_FAILED,
  FETCH_HUBSPOT_FORMS, FETCHING_HUBSPOT_FORMS_SUCCEEDED, FETCHING_HUBSPOT_FORMS_FAILED,
  FETCH_HUBSPOT_FORM, FETCHING_HUBSPOT_FORM_SUCCEEDED, FETCHING_HUBSPOT_FORM_FAILED,
  UPDATE_HUBSPOT_REQUEST, UPDATING_HUBSPOT_SUCCEEDED, UPDATING_HUBSPOT_FAILED
} from 'constants/actionTypes';

import axios from 'axios';
import { createAction } from 'redux-actions';
import buildUrl from 'build-url';
import { call, put, takeEvery } from 'redux-saga/effects';


const apiUrl = `${process.env.REACT_APP_API_URL}/api/2.0`;
const HUBSPOT_BASE_URL = `${apiUrl}/integrations/hubspot`;
const HUBSPOT_AUTHENTICATION_URL = `${HUBSPOT_BASE_URL}/authenticate`;

/*
* Authenticate Hubspot
*/
export const authenticateHubspot = createAction(AUTHENTICATE_HUBSPOT_REQUEST, (request, callback) => {
  return { request, callback };
})

const authenticateHubspotSucceeded = createAction(AUTHENTICATE_HUBSPOT_SUCCEDDED, (response) => {
  return response;
})

const authenticateHubspotFailed = createAction(AUTHENTICATE_HUBSPOT_FAILED, (error) => {
  return error;
})

function* authenticateHubspotSaga(action) {
  const { request, callback } = action.payload;

  let url = buildUrl(HUBSPOT_AUTHENTICATION_URL, {
    headers: {'Content-Type':'application/x-www-form-urlencoded'},
    queryParams: request });

  const authenticateHubspotAPI = () => {
    return axios.post(url).then(response => response.data);
  }

  try {
    const response = yield call(authenticateHubspotAPI);
    if (response) {
      yield put(authenticateHubspotSucceeded(response));
      if(callback) {
        callback(response);
      }
    }
  } catch (error) {
    yield put(authenticateHubspotFailed(error));
  }
}


/*
* Fetching hubspot forms
*/
export const fetchHubspotForms = createAction(FETCH_HUBSPOT_FORMS, (page, limit) => {
  return {
    page: page,
    limit: limit
  }
});

const fetchHubspotFormsSucceeded = createAction(FETCHING_HUBSPOT_FORMS_SUCCEEDED, response => {
  return response;
});

const fetchHubspotFormsFailed = createAction(FETCHING_HUBSPOT_FORMS_FAILED, error => {
  return error;
})

function* fetchHubspotFormsSaga(action) {

  const { page, limit } = action.payload;

  let url = buildUrl(`${HUBSPOT_BASE_URL}/forms`, {
    queryParams: {
      page: page,
      limit: limit
    }
  });

  const fetchHubspotFormsAPI = () => {
    return axios.get(url).then(response => response.data);
  }

  try {
    const response = yield call(fetchHubspotFormsAPI);
    if (response) {
      yield put(fetchHubspotFormsSucceeded(response));
    }
  } catch (error) {
    yield put(fetchHubspotFormsFailed(error));
  }
}

/*
* Fetching hubspot form
*/
export const fetchHubspotForm = createAction(FETCH_HUBSPOT_FORM, (id, context) => {
  return {
    id: id,
    context: context
  };
})

const fetchHubspotFormSucceeded = createAction(FETCHING_HUBSPOT_FORM_SUCCEEDED, (id, response, context) => {
  return {
    id: id,
    response: response,
    context: context
  }
})

const fetchHubspotFormFailed = createAction(FETCHING_HUBSPOT_FORM_FAILED, (id, error, context) => {
  return {
    id: id,
    error: error,
    context: context
  }
})

function* fetchHubspotFormSaga(action) {
  const { id, context } = action.payload;

  const fetchHubspotFormAPI = () => {
    return axios.get(`${HUBSPOT_BASE_URL}/forms/${id}`).then(response => response.data);
  }

  try {
    const response = yield call(fetchHubspotFormAPI);
    if (response) {
      yield put(fetchHubspotFormSucceeded(id, response, context));
    }
  } catch (error) {
    yield put(fetchHubspotFormFailed(id, error, context));
  }
}

/*
* Update Hubspot
*/
export const updateHubspot = createAction(UPDATE_HUBSPOT_REQUEST, (request, callback) => {
  return {
    request: request,
    callback: callback
  };
})

export const updatingHubspotSucceeded = createAction(UPDATING_HUBSPOT_SUCCEEDED, (response) => {
  return response;
})

export const updatingHubspotFailed = createAction(UPDATING_HUBSPOT_FAILED, (error) => {
  return error;
})

function* updateHubspotSaga(action) {
  const { request, callback } = action.payload;

  const updateHubspotAPI = () => {
    return axios.put(HUBSPOT_BASE_URL, request).then(response => response.data);
  }

  try {
    const response = yield call(updateHubspotAPI);
    if(response) {
      yield put(updatingHubspotSucceeded(response));
    } 
    if (callback) {
      callback(response);
    }
  } catch (error) {
    yield put(updatingHubspotFailed(error));
  }
}

export default function* hubspotSaga() {
  yield takeEvery(AUTHENTICATE_HUBSPOT_REQUEST, authenticateHubspotSaga);
  yield takeEvery(FETCH_HUBSPOT_FORMS, fetchHubspotFormsSaga);
  yield takeEvery(FETCH_HUBSPOT_FORM, fetchHubspotFormSaga);
  yield takeEvery(UPDATE_HUBSPOT_REQUEST, updateHubspotSaga);
}