import {
  FETCHING_COLLECTIONS_REQUEST, FETCHING_COLLECTIONS_SUCCEEDED, FETCHING_COLLECTIONS_FAILED,
  FETCHING_COLLECTION_REQUEST, FETCHING_COLLECTION_SUCCEEDED, FETCHING_COLLECTION_FAILED,
  FETCHING_COLLECTION_SECTION_REQUEST, FETCHING_COLLECTION_SECTION_SUCCEEDED, FETCHING_COLLECTION_SECTION_FAILED
}
  from 'constants/actionTypes.jsx';

const initialState = {
  loading: false,
  invalidated: true,
  items: [],
  collection: {}
}

const collectionState = {
  item: null,
  loading: false
}

function collectionsReducer(state = initialState, action) {
  switch (action.type) {
    case FETCHING_COLLECTIONS_REQUEST: 
      return Object.assign({}, state, {
        ...state,
        loading: true,
        invalidated: true
      });

    case FETCHING_COLLECTIONS_SUCCEEDED:
      return Object.assign({}, state, {
        ...state,
        loading: false,
        invalidated: false,
        items: action.payload
      })

    case FETCHING_COLLECTIONS_FAILED: 
      return Object.assign({}, state, {
        ...state,
        loading: false,
        invalidated: false,
        items: []
      })

    case FETCHING_COLLECTION_REQUEST:
      var id = action.payload.id;
      var collection = state.collection[id] || collectionState;

      return Object.assign({}, state, {
        ...state,
        collection: {
          ...state.collection,
          [id]: {
            ...collection,
            loading: true
          }
        }
      });

    case FETCHING_COLLECTION_SUCCEEDED:
      var id = action.payload.id;
      var collection = state.collection[id] || collectionState;

      if (action.payload.collection.sections) {
        var sortedSection = action.payload.collection.sections
          .map(section => {
            return {
              ...section,
              fetched: false
            }
          });

        action.payload.collection.sections = sortedSection;
      }

      return Object.assign({}, state, {
        ...state,
        collection: {
          ...state.collection,
          [id]: {
            ...collection,
            loading: false,
            item: action.payload.collection
          }
        }
      });

    case FETCHING_COLLECTION_FAILED:
      var id = action.payload.id;
      var collection = state.collection[id] || collectionState;

      return Object.assign({}, state, {
        ...state,
        collection: {
          ...state.collection,
          [id]: {
            ...collection,
            loading: false
          }
        }
      });

    case FETCHING_COLLECTION_SECTION_REQUEST:
      var collectionId = action.payload.collectionId;
      var sectionId = action.payload.sectionId;

      var currentCollection = state.collection[collectionId] || collectionState;

      var sections = (currentCollection.item.sections || []).map(section => {
        if (section.id === sectionId) {
          return {
            ...section,
            loading: true,
            fetched: false
          }
        } else {
          return section;
        }
      })

      return Object.assign({}, state, {
        ...state,
        collection: {
          ...state.collection,
          [collectionId]: {
            ...currentCollection,
            item: {
              ...currentCollection.item,
              sections: sections
            }
          }
        }
      });

    case FETCHING_COLLECTION_SECTION_SUCCEEDED:
      var collectionId = action.payload.collectionId;
      var sectionId = action.payload.sectionId;

      var currentCollection = state.collection[collectionId] || collectionState;

      var sections = currentCollection.item.sections.map(section => {
        if (section.id === sectionId) {
          return {
            ...section,
            ...action.payload.section,
            loading: false,
            fetched: true
          }
        } else {
          return section;
        }
      });

      return Object.assign({}, state, {
        ...state,
        collection: {
          ...state.collection,
          [collectionId]: {
            ...currentCollection,
            item: {
              ...currentCollection.item,
              sections: sections
            }
          }
        }
      });

    case FETCHING_COLLECTION_SECTION_FAILED:
      var collectionId = action.payload.collectionId;
      var sectionId = action.payload.sectionId;

      var currentCollection = state.collection[collectionId] || collectionState;

      sections = currentCollection.item.sections.map(section => {
        if (section.id === sectionId) {
          return {
            ...section,
            loading: false,
            fetched: false
          }
        } else {
          return section;
        }
      })

      return Object.assign({}, state, {
        ...state,
        collection: {
          ...state.collection,
          [collectionId]: {
            ...currentCollection,
            item: {
              ...currentCollection.item,
              sections: sections
            }
          }
        }
      });

    default:
      return state;
  }
}

export { collectionsReducer };