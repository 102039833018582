/*
  Anonymous Actions
*/
export const AUTHENTICATING_USER_REQUEST = 'AUTHENTICATING_USER_REQUEST';
export const AUTHENTICATING_USER_SUCCEDDED = 'AUTHENTICATING_USER_SUCCEDDED';
export const AUTHENTICATING_USER_FAILED = 'AUTHENTICATING_USER_FAILED';

export const FETCHING_USER_PROFILE = 'FETCHING_USER_PROFILE';
export const FETCHING_USER_PROFILE_SUCCEEDED = 'FETCHING_USER_PROFILE_SUCCEEDED';
export const FETCHING_USER_PROFILE_FAILED = 'FETCHING_USER_PROFILE_FAILED';

export const UPDATING_USER_PROFILE = 'UPDATING_USER_PROFILE';
export const UPDATING_USER_PROFILE_SUCCEEDED = 'UPDATING_USER_PROFILE_SUCCEEDED';
export const UPDATING_USER_PROFILE_FAILED = 'UPDATING_USER_PROFILE_FAILED';

export const FORGOT_PASSWORD_REQUEST = 'FORGOT_PASSWORD_REQUEST';
export const FORGOT_PASSWORD_SUCCEEDED = 'FORGOT_PASSWORD_SUCCEEDED';
export const FORGOT_PASSWORD_FAILED = 'FORGOT_PASSWORD_FAILED';

export const FETCHING_SSO_URL_REQUEST = 'FETCHING_SSO_URL_REQUEST';
export const FETCHING_SSO_URL_SUCCEEDED = 'FETCHING_SSO_URL_SUCCEEDED';
export const FETCHING_SSO_URL_FAILED = 'FETCHING_SSO_URL_FAILED';

export const SSO_AUTHENTICATION_FAILED = 'SSO_AUTHENTICATION_FAILED';
export const LOGOUT_USER_REQUEST = 'LOGOUT_USER_REQUEST';
export const LOGOUT_USER = 'LOGOUT_USER';

/*
  Resource center actions
*/
export const FETCH_STORYBOARDS_REQUEST = 'FETCH_STORYBOARDS_REQUEST';
export const FETCHING_STORYBOARDS_SUCCEEDED = 'FETCHING_STORYBOARDS_SUCCEEDED';
export const FETCHING_STORYBOARDS_FAILED = 'FETCHING_STORYBOARDS_FAILED';

export const FETCH_STORYBOARD_REQUEST = 'FETCH_STORYBOARD_REQUEST';
export const FETCHING_STORYBOARD_SUCCEEDED = 'FETCHING_STORYBOARD_SUCCEEDED';
export const FETCHING_STORYBOARD_FAILED = 'FETCHING_STORYBOARD_FAILED';

export const SWITCH_STORYBOARD_REQUEST = 'SWITCH_STORYBOARD_REQUEST';
export const SWITCH_STORYBOARD = 'SWITCH_STORYBOARD';

/*
  Assets Actions
*/
export const FETCHING_HUB_STREAMS_REQUEST = 'FETCHING_HUB_STREAMS_REQUEST';
export const FETCHING_HUB_STREAMS_SUCCEEDED = 'FETCHING_HUB_STREAMS_SUCCEEDED';
export const FETCHING_HUB_STREAMS_FAILED = 'FETCHING_HUB_STREAMS_FAILED';

export const FETCHING_STREAM_REQUEST = 'FETCHING_STREAM_REQUEST';
export const FETCHING_STREAM_SUCCEEDED = 'FETCHING_STREAM_SUCCEEDED';
export const FETCHING_STREAM_FAILED = 'FETCHING_STREAM_FAILED';

export const FETCHING_GROUP_REQUEST = 'FETCHING_GROUP_REQUEST';
export const FETCHING_GROUP_SUCCEEDED = 'FETCHING_GROUP_SUCCEEDED';
export const FETCHING_GROUP_FAILED = 'FETCHING_GROUP_FAILED';

export const FETCHING_COLLECTIONS_REQUEST = 'FETCHING_COLLECTIONS_REQUEST';
export const FETCHING_COLLECTIONS_SUCCEEDED = 'FETCHING_COLLECTIONS_SUCCEEDED';
export const FETCHING_COLLECTIONS_FAILED = 'FETCHING_COLLECTIONS_FAILED';

export const FETCHING_COLLECTION_REQUEST = 'FETCHING_COLLECTION_REQUEST';
export const FETCHING_COLLECTION_SUCCEEDED = 'FETCHING_COLLECTION_SUCCEEDED';
export const FETCHING_COLLECTION_FAILED = 'FETCHING_COLLECTION_FAILED';

export const FETCHING_COLLECTION_SECTION_REQUEST = 'FETCHING_COLLECTION_SECTION_REQUEST';
export const FETCHING_COLLECTION_SECTION_SUCCEEDED = 'FETCHING_COLLECTION_SECTION_SUCCEEDED';
export const FETCHING_COLLECTION_SECTION_FAILED = 'FETCHING_COLLECTION_SECTION_FAILED';

export const ADDING_ASSETS_REQUEST = 'ADDING_ASSETS_REQUEST';
export const ADDING_ASSETS_SUCCEEDED = 'ADDING_ASSETS_SUCCEEDED';
export const ADDING_ASSETS_FAILED = 'ADDING_ASSETS_FAILED';

export const ADD_ASSETS_TO_RESOURCE_CENTER_REQUEST = 'ADD_ASSETS_TO_RESOURCE_CENTER_REQUEST';
export const ADD_ASSETS_TO_RESOURCE_CENTER_SUCCEEDED = 'ADD_ASSETS_TO_RESOURCE_CENTER_SUCCEEDED';
export const ADD_ASSETS_TO_RESOURCE_CENTER_FAILED = 'ADD_ASSETS_TO_RESOURCE_CENTER_FAILED';

export const UPLOADING_FILE_REQUEST = 'UPLOADING_FILE_REQUEST';
export const UPLOADING_FILE_SUCCEDDED = 'UPLOADING_FILE_SUCCEDDED';
export const UPLOADING_FILE_FAILED = 'UPLOADING_FILE_FAILED';
export const UPLOADING_FILE_PROGRESS = 'UPLOADING_FILE_PROGRESS';

export const CREATING_ASSET_REQUEST = 'CREATING_ASSET_REQUEST';
export const CREATING_ASSET_SUCCEEDED = 'CREATING_ASSET_SUCCEEDED';
export const CREATING_ASSET_FAILED = 'CREATING_ASSET_FAILED';

export const ADD_ASSET_URL = 'ADD_ASSET_URL';
export const ADD_ASSET_URL_SUCCEDDED = 'ADD_ASSET_URL_SUCCEDDED';
export const ADD_ASSET_URL_FAILED = 'ADD_ASSET_URL_FAILED';

export const REMOVE_ASSET_SELECTION = 'REMOVE_ASSET_SELECTION';
export const INVALIDATE_ASSET_SELECTION = 'INVALIDATE_ASSET_SELECTION';

export const REMOVE_ASSETS_FROM_RESOURCE_CENTER_REQUEST = 'REMOVE_ASSETS_FROM_RESOURCE_CENTER_REQUEST';
export const REMOVE_ASSETS_FROM_RESOURCE_CENTER_SUCCEEDED = 'REMOVE_ASSETS_FROM_RESOURCE_CENTER_SUCCEEDED';
export const REMOVE_ASSETS_FROM_RESOURCE_CENTER_FAILED = 'REMOVE_ASSETS_FROM_RESOURCE_CENTER_FAILED';

export const FETCHING_ASSETS = 'FETCHING_ASSETS';
export const FETCHING_ASSETS_SUCCEEDED = 'FETCHING_ASSETS_SUCCEEDED';
export const FETCHING_ASSETS_FAILED = 'FETCHING_ASSETS_FAILED';

export const FETCH_WIDGET_ASSET_REQUEST = 'FETCH_WIDGET_ASSET_REQUEST';
export const FETCHING_WIDGET_ASSET_SUCCEEDED = 'FETCHING_WIDGET_ASSET_SUCCEEDED';
export const FETCHING_WIDGET_ASSET_FAILED = 'FETCHING_WIDGET_ASSET_FAILED';

export const FETCHING_ASSET = 'FETCHING_ASSET';
export const FETCHING_ASSET_SUCCEEDED = 'FETCHING_ASSET_SUCCEEDED';
export const FETCHING_ASSET_FAILED = 'FETCHING_ASSET_FAILED';

export const FETCHING_ASSET_PREVIEW = 'FETCHING_ASSET_PREVIEW';
export const FETCHING_ASSET_PREVIEW_SUCCEEDED = 'FETCHING_ASSET_PREVIEW_SUCCEEDED';
export const FETCHING_ASSET_PREVIEW_FAILED = 'FETCHING_ASSET_PREVIEW_FAILED';

export const FETCHING_ASSET_PREVIEW_TEXT = 'FETCHING_ASSET_PREVIEW_TEXT';
export const FETCHING_ASSET_PREVIEW_TEXT_SUCCEDDED = 'FETCHING_ASSET_PREVIEW_TEXT_SUCCEDDED';
export const FETCHING_ASSET_PREVIEW_TEXT_FAILED = 'FETCHING_ASSET_PREVIEW_TEXT_FAILED';

export const FETCHING_HTML_PREVIEW_TEMPLATE = 'FETCHING_HTML_PREVIEW_TEMPLATE';
export const FETCHING_HTML_PREVIEW_TEMPLATE_SUCCEEDED = 'FETCHING_HTML_PREVIEW_TEMPLATE_SUCCEEDED';
export const FETCHING_HTML_PREVIEW_TEMPLATE_FAILED = 'FETCHING_HTML_PREVIEW_TEMPLATE_FAILED';

export const UPDATING_ASSET = "UPDATING_ASSET";
export const UPDATING_ASSET_SUCCEEDED = "UPDATING_ASSET_SUCCEDDED";
export const UPDATING_ASSET_FAILED = "UPDATING_ASSET_FAILED";

export const BULK_UPDATING_ASSETS_REQUEST = "BULK_UPDATING_ASSETS_REQUEST";
export const BULK_UPDATING_ASSETS_SUCCEEDED = "BULK_UPDATING_ASSETS_SUCCEEDED";
export const BULK_UPDATING_ASSETS_FAILED = "BULK_UPDATING_ASSETS_FAILED";

export const UPLOAD_NEW_ASSET_VERSION_REQUEST = "UPLOAD_NEW_ASSET_VERSION_REQUEST";
export const UPLOAD_NEW_ASSET_VERSION_SUCCEDDED = "UPLOAD_NEW_ASSET_VERSION_SUCCEDDED";
export const UPLOAD_NEW_ASSET_VERSION_FAILED = "UPLOAD_NEW_ASSET_VERSION_FAILED";
export const UPLOADING_NEW_ASSET_VERSION_STARTED = "UPLOADING_NEW_ASSET_VERSION_STARTED";
export const UPLOADING_NEW_ASSET_VERSION_PROGRESS = "UPLOADING_NEW_ASSET_VERSION_PROGRESS";
export const UPLOAD_NEW_ASSET_VERSION_CANCEL = "UPLOAD_NEW_ASSET_VERSION_CANCEL";

export const PROCESS_ASSET = "PROCESS_ASSET";
export const PROCESS_ASSET_SUCCESS = "PROCESS_ASSET_SUCCESS";
export const PROCESS_ASSET_FAILED = "PROCESS_ASSET_FAILED";

export const FETCHING_ASSET_ANALYTICS_REQUEST = 'FETCHING_ASSET_ANALYTICS_REQUEST';
export const FETCHING_ASSET_ANALYTICS_SUCCEDDED = 'FETCHING_ASSET_ANALYTICS_SUCCEDDED';
export const FETCHING_ASSET_ANALYTICS_FAILED = 'FETCHING_ASSET_ANALYTICS_FAILED';

export const DELETING_ASSETS_REQUEST = 'DELETING_ASSETS_REQUEST';
export const DELETING_ASSETS_SUCCEEDED = 'DELETING_ASSETS_SUCCEEDED';
export const DELETING_ASSETS_FAILED = 'DELETING_ASSETS_FAILED';

export const SELECT_ASSET_FOR_PAGE = 'SELECT_ASSET_FOR_PAGE'

export const SELECT_ASSET = 'SELECT_ASSET';
export const DESELECT_ASSET = 'DESELECT_ASSET';
export const SELECT_ALL_ASSETS = 'SELECT_ALL_ASSETS';
export const DESELECT_ALL_ASSETS = 'DESELECT_ALL_ASSETS';

export const FETCHING_CONTENT_SUGGESTIONS = 'FETCHING_CONTENT_SUGGESTIONS';
export const FETCHING_CONTENT_SUGGESTIONS_SUCCEEDED = 'FETCHING_CONTENT_SUGGESTIONS_SUCCEEDED';
export const FETCHING_CONTENT_SUGGESTIONS_FAILED = 'FETCHING_CONTENT_SUGGESTIONS_FAILED';

export const FETCHING_CONTENT_ANALYTICS = 'FETCHING_CONTENT_ANALYTICS';
export const FETCHING_CONTENT_ANALYTICS_SUCCEEDED = 'FETCHING_CONTENT_ANALYTICS_SUCCEEDED';
export const FETCHING_CONTENT_ANALYTICS_FAILED = 'FETCHING_CONTENT_ANALYTICS_FAILED';

//Analytics events
export const RECORDING_ASSET_SESSION_EVENT_REQUEST = 'RECORDING_ASSET_SESSION_EVENT_REQUEST';
export const RECORDING_ASSET_SESSION_EVENT_SUCCEDDED = 'RECORDING_ASSET_SESSION_EVENT_SUCCEDDED';
export const RECORDING_ASSET_SESSION_EVENT_FAILED = 'RECORDING_ASSET_SESSION_EVENT_FAILED';

export const RECORDING_ASSET_ANALYTICS_EVENT_REQUEST = 'RECORDING_ASSET_ANALYTICS_EVENT_REQUEST';
export const RECORDING_ASSET_ANALYTICS_EVENT_SUCCEDDED = 'RECORDING_ASSET_ANALYTICS_EVENT_SUCCEDDED';
export const RECORDING_ASSET_ANALYTICS_EVENT_FAILED = 'RECORDING_ASSET_ANALYTICS_EVENT_FAILED';

// Video Analytics
export const CAPTURED_ASSET_VIDEO_NEW_RANGE = 'CAPTURED_ASSET_VIDEO_NEW_RANGE';
export const UPDATED_ASSET_VIDEO_RANGE = 'UPDATED_ASSET_VIDEO_RANGE';
export const REMOVED_ASSET_VIDEO_RANGE = 'REMOVED_ASSET_VIDEO_RANGE';
export const SYNCING_ASSET_VIEW_RANGE_REQUEST = 'SYNCING_ASSET_VIEW_RANGE_REQUEST';
export const SYNCING_ASSET_VIEW_RANGE_SUCCEDDED = 'SYNCING_ASSET_VIEW_RANGE_SUCCEDDED';
export const SYNCING_ASSET_VIEW_RANGE_FAILED = 'SYNCING_ASSET_VIEW_RANGE_FAILED';

export const SEARCH_ASSETS = 'SEARCH_ASSETS';
export const SEARCHING_ASSETS_SUCCEEDED = 'SEARCHING_ASSETS_SUCCEEDED';
export const SEARCHING_ASSETS_FAILED = 'SEARCHING_ASSETS_FAILED';
export const INVALIDATE_ASSET_SEARCH = 'INVALIDATE_ASSET_SEARCH';

/*
  Pages related Actions
*/
export const FETCH_PAGES_REQUEST = 'FETCH_PAGES_REQUEST';
export const FETCHING_PAGES_SUCCEEDED = 'FETCHING_PAGES_SUCCEEDED';
export const FETCHING_PAGES_FAILED = 'FETCHING_PAGES_FAILED';

export const FETCH_RECENTLY_UPDATED_PAGES_REQUEST = 'FETCH_RECENTLY_UPDATED_PAGES_REQUEST';
export const FETCHING_RECENTLY_UPDATED_PAGES_SUCCEEDED = 'FETCHING_RECENTLY_UPDATED_PAGES_SUCCEEDED';
export const FETCHING_RECENTLY_UPDATED_PAGES_FAILED = 'FETCHING_RECENTLY_UPDATED_PAGES_FAILED';

export const FETCH_PAGE_REQUEST = 'FETCH_PAGE_REQUEST';
export const FETCHING_PAGE_SUCCEEDED = 'FETCHING_PAGE_SUCCEEDED';
export const FETCHING_PAGE_FAILED = 'FETCHING_PAGE_FAILED';

export const CREATE_PAGE_REQUEST = 'CREATE_PAGE_REQUEST';
export const CREATING_PAGE_SUCCEEDED = 'CREATING_PAGE_SUCCEEDED';
export const CREATING_PAGE_FAILED = 'CREATING_PAGE_FAILED';

export const UPDATE_PAGE_REQUEST = 'UPDATE_PAGE_REQUEST';
export const UPDATING_PAGE_SUCCEEDED = 'UPDATING_PAGE_SUCCEEDED';
export const UPDATING_PAGE_FAILED = 'UPDATING_PAGE_FAILED';

export const PUBLISH_PAGE_REQUEST = 'PUBLISH_PAGE_REQUEST';
export const PUBLISHING_PAGE_SUCCEEDED = 'PUBLISHING_PAGE_SUCCEEDED';
export const PUBLISHING_PAGE_FAILED = 'PUBLISHING_PAGE_FAILED';

export const RESTORE_PAGE_REQUEST = 'RESTORE_PAGE_REQUEST';
export const RESTORE_PAGE_SUCCEEDED = 'RESTORE_PAGE_SUCCEEDED';
export const RESTORE_PAGE_FAILED = 'RESTORE_PAGE_FAILED';

export const DELETE_PAGE_REQUEST = 'DELETE_PAGE_REQUEST';
export const DELETE_PAGE_SUCCEEDED = 'DELETE_PAGE_SUCCEEDED';
export const DELETE_PAGE_FAILED = 'DELETE_PAGE_FAILED';

export const INVALIDATE_PAGE = 'INVALIDATE_PAGE';

export const FETCH_PAGE_ANALYTICS_REQUEST = 'FETCH_PAGE_ANALYTICS_REQUEST';
export const FETCHING_PAGE_ANALYTICS_SUCCEEDED = 'FETCHING_PAGE_ANALYTICS_SUCCEEDED';
export const FETCHING_PAGE_ANALYTICS_FAILED = 'FETCHING_PAGE_ANALYTICS_FAILED';

export const FETCH_PAGE_ALIASES = 'FETCH_PAGE_ALIASES';
export const FETCH_PAGE_ALIASES_SUCCEEDED = 'FETCH_PAGE_ALIASES_SUCCEEDED';
export const FETCH_PAGE_ALIASES_FAILED = 'FETCH_PAGE_ALIASES_FAILED';

/**
 * Widget related actions
*/
export const CREATE_EDITOR_CONTEXT = 'CREATE_EDITOR_CONTEXT';
export const UPDATE_EDITOR_CONTEXT = 'UPDATE_EDITOR_CONTEXT';
export const INSERT_WIDGETS_AT_INDEX = 'INSERT_WIDGETS_AT_INDEX';
export const MOVE_WIDGET_TO_INDEX = 'MOVE_WIDGET_TO_INDEX';
export const RESIZING_WIDGET = 'RESIZING_WIDGET';
export const INVALIDATE_RESIZING_WIDGET = 'INVALIDATE_RESIZING_WIDGET';
export const REARRANGE_WIDGETS = 'REARRANGE_WIDGETS';
export const CREATE_WIDGETS_FROM_PALETTE = 'CREATE_WIDGETS_FROM_PALETTE';

export const REMOVE_WIDGET_FROM_PARENT = 'REMOVE_WIDGET_FROM_PARENT';

export const SHOW_WIDGET_PLACEHOLDER = 'SHOW_WIDGET_PLACEHOLDER';
export const INVALIDATE_WIDGET_PLACEHOLDER = 'INVALIDATE_WIDGET_PLACEHOLDER';
export const WIDGET_DROP_REQUEST = 'WIDGET_DROP_REQUEST';
export const PALETTE_DROP_REQUEST = 'PALETTE_DROP_REQUEST';

export const DELETE_WIDGET = 'DELETE_WIDGET';
export const SELECT_WIDGET = 'SELECT_WIDGET';
export const DESELECT_WIDGET = 'DESELECT_WIDGET';
export const UPDATE_WIDGET_NAME = 'UPDATE_WIDGET_NAME';
export const UPDATE_WIDGET_PROPERTIES = 'UPDATE_WIDGET_PROPERTIES';
export const UPDATE_WIDGET_CONTENT = 'UPDATE_WIDGET_CONTENT';

export const CREATE_WIDGET_REQUEST = 'CREATE_WIDGET_REQUEST';
export const CREATING_WIDGET_SUCCEEDED = 'CREATING_WIDGET_SUCCEEDED';
export const CREATING_WIDGET_FAILED = 'CREATING_WIDGET_FAILED';

export const UPDATE_WIDGET_REQUEST = 'UPDATE_WIDGET_REQUEST';
export const UPDATING_WIDGET_SUCCEEDED = 'UPDATING_WIDGET_SUCCEEDED';
export const UPDATING_WIDGET_FAILED = 'UPDATING_WIDGET_FAILED';

export const CLONE_WIDGET_REQUEST = 'CLONE_WIDGET_REQUEST';
export const CLONING_WIDGET_SUCCEEDED = 'CLONING_WIDGET_SUCCEEDED';
export const CLONING_WIDGET_FAILED = 'CLONING_WIDGET_FAILED';

export const FETCH_WIDGET_CONTENT_REQUEST = 'FETCH_WIDGET_CONTENT_REQUEST';
export const FETCHING_WIDGET_CONTENT_SUCCEEDED = 'FETCHING_WIDGET_CONTENT_SUCCEEDED';
export const FETCHING_WIDGET_CONTENT_FAILED = 'FETCHING_WIDGET_CONTENT_FAILED';

export const UPDATE_WIDGET_CONTENT_REQUEST = 'UPDATE_WIDGET_CONTENT_REQUEST';
export const UPDATING_WIDGET_CONTENT_SUCCEEDED = 'UPDATING_WIDGET_CONTENT_SUCCEEDED';
export const UPDATING_WIDGET_CONTENT_FAILED = 'UPDATING_WIDGET_CONTENT_FAILED';

export const FETCH_WIDGET_ASSETS_REQUEST = 'FETCH_WIDGET_ASSETS_REQUEST';
export const FETCHING_WIDGET_ASSETS_SUCCEEDED = 'FETCHING_WIDGET_ASSETS_SUCCEEDED';
export const FETCHING_WIDGET_ASSETS_FAILED = 'FETCHING_WIDGET_ASSETS_FAILED';

export const UPDATE_FILTERS_IN_WIDGET_ASSETS = 'UPDATE_FILTERS_IN_WIDGET_ASSETS';

export const UPDATE_SEARCH_QUERY_IN_WIDGET_ASSETS = 'UPDATE_SEARCH_QUERY_IN_WIDGET_ASSETS';

export const FETCH_PALETTE_GROUPS_REQUEST = 'FETCH_PALETTE_GROUPS_REQUEST';
export const FETCHING_PALETTE_GROUPS_SUCCEEDED = 'FETCHING_PALETTE_GROUPS_SUCCEEDED';
export const FETCHING_PALETTE_GROUPS_FAILED = 'FETCHING_PALETTE_GROUPS_FAILED';

export const CLONE_PAGE_REQUEST = 'CLONE_PAGE_REQUEST';
export const CLONING_PAGE_SUCCEEDED = 'CLONING_PAGE_SUCCEEDED';
export const CLONING_PAGE_FAILED = 'CLONING_PAGE_FAILED';

export const UPDATE_PAGE_PROPERTIES = 'UPDATE_PAGE_PROPERTIES';
export const UPDATE_TRACKING_PIXEL_PROPERTIES = 'UPDATE_TRACKING_PIXEL_PROPERTIES';


export const MERGE_WIDGET_EDITORS = 'MERGE_WIDGET_EDITORS';

export const UPDATE_ASSETS_FOR_WIDGET = 'UPDATE_ASSETS_FOR_WIDGET';
export const UPDATE_ASSETS_FOR_WIDGET_SUCCEEDED = 'UPDATE_ASSETS_FOR_WIDGET_SUCCEEDED';
export const UPDATE_ASSETS_FOR_WIDGET_FAILED = 'UPDATE_ASSETS_FOR_WIDGET_FAILED';

/**
 * Asset Template related actions
*/
export const CREATE_ASSET_TEMPLATE_REQUEST = 'CREATE_ASSET_TEMPLATE_REQUEST';
export const CREATING_ASSET_TEMPLATE_SUCCEEDED = 'CREATING_ASSET_TEMPLATE_SUCCEEDED';
export const CREATING_ASSET_TEMPLATE_FAILED = 'CREATING_ASSET_TEMPLATE_FAILED';

export const UPDATE_ASSET_TEMPLATE_REQUEST = 'UPDATE_ASSET_TEMPLATE_REQUEST';
export const UPDATING_ASSET_TEMPLATE_SUCCEEDED = 'UPDATING_ASSET_TEMPLATE_SUCCEEDED';
export const UPDATING_ASSET_TEMPLATE_FAILED = 'UPDATING_ASSET_TEMPLATE_FAILED';

export const FETCH_ASSET_TEMPLATES_REQUEST = 'FETCH_ASSET_TEMPLATES_REQUEST';
export const FETCHING_ASSET_TEMPLATES_SUCCEEDED = 'FETCHING_ASSET_TEMPLATES_SUCCEEDED';
export const FETCHING_ASSET_TEMPLATES_FAILED = 'FETCHING_ASSET_TEMPLATES_FAILED';

export const FETCH_ASSET_TEMPLATE_REQUEST = 'FETCH_ASSET_TEMPLATE_REQUEST';
export const FETCHING_ASSET_TEMPLATE_SUCCEEDED = 'FETCHING_ASSET_TEMPLATE_SUCCEEDED';
export const FETCHING_ASSET_TEMPLATE_FAILED = 'FETCHING_ASSET_TEMPLATE_FAILED';

export const DELETE_ASSET_TEMPLATE_REQUEST = 'DELETE_ASSET_TEMPLATE_REQUEST';
export const DELETING_ASSET_TEMPLATE_SUCCEEDED = 'DELETING_ASSET_TEMPLATE_SUCCEEDED';
export const DELETING_ASSET_TEMPLATE_FAILED = 'DELETING_ASSET_TEMPLATE_FAILED';

export const INVALIDATE_ASSET_TEMPLATES = 'INVALIDATE_ASSET_TEMPLATES';

/**
 * Page Template related actions
*/
export const FETCH_PAGE_TEMPLATES_REQUEST = 'FETCH_PAGE_TEMPLATES_REQUEST';
export const FETCHING_PAGE_TEMPLATES_SUCCEEDED = 'FETCHING_PAGE_TEMPLATES_SUCCEEDED';
export const FETCHING_PAGE_TEMPLATES_FAILED = 'FETCHING_PAGE_TEMPLATES_FAILED';

export const FETCH_PAGE_TEMPLATE_REQUEST = 'FETCH_PAGE_TEMPLATE_REQUEST';
export const FETCHING_PAGE_TEMPLATE_SUCCEEDED = 'FETCHING_PAGE_TEMPLATE_SUCCEEDED';
export const FETCHING_PAGE_TEMPLATE_FAILED = 'FETCHING_PAGE_TEMPLATE_FAILED';

export const UPDATE_PAGE_TEMPLATE_REQUEST = 'UPDATE_PAGE_TEMPLATE_REQUEST';
export const UPDATING_PAGE_TEMPLATE_SUCCEEDED = 'UPDATING_PAGE_TEMPLATE_SUCCEEDED';
export const UPDATING_PAGE_TEMPLATE_FAILED = 'UPDATING_PAGE_TEMPLATE_FAILED';

export const PUBLISH_PAGE_TEMPLATE_REQUEST = 'PUBLISH_PAGE_TEMPLATE_REQUEST';
export const PUBLISHING_PAGE_TEMPLATE_SUCCEEDED = 'PUBLISHING_PAGE_TEMPLATE_SUCCEEDED';
export const PUBLISHING_PAGE_TEMPLATE_FAILED = 'PUBLISHING_PAGE_TEMPLATE_FAILED';

export const UPDATING_PAGE_TEMPLATE_PROPERTIES = 'UPDATING_PAGE_TEMPLATE_PROPERTIES'
/*
  Clusters related Actions
*/
export const CREATING_CLUSTER = 'CREATING_CLUSTER';
export const CREATING_CLUSTER_SUCCEEDED = 'CREATING_CLUSTER_SUCCEEDED';
export const CREATING_CLUSTER_FAILED = 'CREATING_CLUSTER_FAILED';

export const UPDATING_CLUSTER = 'UPDATING_CLUSTER';
export const UPDATING_CLUSTER_SUCCEEDED = 'UPDATING_CLUSTER_SUCCEEDED';
export const UPDATING_CLUSTER_FAILED = 'UPDATING_CLUSTER_FAILED';

export const FETCHING_ALL_CLUSTERS = 'FETCHING_ALL_CLUSTERS';
export const FETCHING_ALL_CLUSTERS_SUCCEEDED = 'FETCHING_ALL_CLUSTERS_SUCCEEDED';
export const FETCHING_ALL_CLUSTERS_FAILED = 'FETCHING_ALL_CLUSTERS_FAILED';

export const FETCH_CLUSTER_REQUEST = 'FETCH_CLUSTER_REQUEST';
export const FETCHING_CLUSTER_SUCCEEDED = 'FETCHING_CLUSTER_SUCCEEDED';
export const FETCHING_CLUSTER_FAILED = 'FETCHING_CLUSTER_FAILED';

export const INVALDATE_CLUSTER_REQUEST = 'INVALDATE_CLUSTER_REQUEST';
export const UPDATE_CLUSTER = 'UPDATE_CLUSTER';

export const UPDATE_FILTERS_IN_CONTENT = 'UPDATE_FILTERS_IN_CONTENT';

export const DELETE_CLUSTER_REQUEST = 'DELETE_CLUSTER_REQUEST';
export const DELETE_CLUSTER_SUCCEEDED = 'DELETE_CLUSTER_SUCCEEDED';
export const DELETE_CLUSTER_FAILED = 'DELETE_CLUSTER_FAILED';

/*
  Custom fields related Actions
*/
export const FETCH_CUSTOM_FIELDS = 'FETCH_CUSTOM_FIELDS';
export const FETCH_CUSTOM_FIELDS_SUCCEDDED = 'FETCH_CUSTOM_FIELDS_SUCCEDDED';
export const FETCH_CUSTOM_FIELDS_FAILED = 'FETCH_CUSTOM_FIELDS_FAILED';
export const INVALIDATE_CUSTOM_FIELDS = 'INVALIDATE_CUSTOM_FIELDS';

export const UPDATING_CUSTOM_FIELD_REQUEST = "UPDATING_CUSTOM_FIELD_REQUEST";
export const UPDATING_CUSTOM_FIELD_SUCCEEDED = "UPDATING_CUSTOM_FIELD_SUCCEEDED";
export const UPDATING_CUSTOM_FIELD_FAILED = "UPDATING_CUSTOM_FIELD_FAILED";

export const FETCHING_CUSTOM_FIELD_REQUEST = "FETCHING_CUSTOM_FIELD_REQUEST";
export const FETCHING_CUSTOM_FIELD_SUCCEEDED = "FETCHING_CUSTOM_FIELD_SUCCEEDED";
export const FETCHING_CUSTOM_FIELD_FAILED = "FETCHING_CUSTOM_FIELD_FAILED";

export const DELETING_CUSTOM_FIELD_REQUEST = "DELETING_CUSTOM_FIELD_REQUEST";
export const DELETING_CUSTOM_FIELD_SUCCEEDED = "DELETING_CUSTOM_FIELD_SUCCEEDED";
export const DELETING_CUSTOM_FIELD_FAILED = "DELETING_CUSTOM_FIELD_FAILED";


/*
  Journey Related Actions
*/
export const CREATE_JOURNEY_REQUEST = 'CREATE_JOURNEY_REQUEST';
export const CREATING_JOURNEY_SUCCEEDED = 'CREATING_JOURNEY_SUCCEEDED';
export const CREATING_JOURNEY_FAILED = 'CREATING_JOURNEY_FAILED';

export const FETCH_JOURNEYS_REQUEST = 'FETCH_JOURNEYS_REQUEST';
export const FETCHING_JOURNEYS_SUCCEEDED = 'FETCHING_JOURNEYS_SUCCEEDED';
export const FETCHING_JOURNEYS_FAILED = 'FETCHING_JOURNEYS_FAILED';

export const FETCH_JOURNEY_REQUEST = 'FETCH_JOURNEY_REQUEST';
export const FETCHING_JOURNEY_SUCCEEDED = 'FETCHING_JOURNEY_SUCCEEDED';
export const FETCHING_JOURNEY_FAILED = 'FETCHING_JOURNEY_FAILED';

export const UPDATE_JOURNEY_REQUEST = 'UPDATE_JOURNEY_REQUEST';
export const UPDATING_JOURNEY_SUCCEEDED = 'UPDATING_JOURNEY_SUCCEEDED';
export const UPDATING_JOURNEY_FAILED = 'UPDATING_JOURNEY_FAILED';

export const FILTER_SELECTION = 'FILTER_SELECTION';
export const INVALIDATE_FILTER_SELECTION = 'INVALIDATE_FILTER_SELECTION';

export const CREATE_JOURNEY_STEP = 'CREATE_JOURNEY_STEP';
export const CREATE_JOURNEY_STEP_SUCCEEDED = 'CREATE_JOURNEY_STEP_SUCCEEDED';
export const CREATE_JOURNEY_STEP_FAILED = 'CREATE_JOURNEY_STEP_FAILED';

export const UPDATE_JOURNEY_STEP = 'UPDATE_JOURNEY_STEP';
export const UPDATE_JOURNEY_STEP_SUCCEEDED = 'UPDATE_JOURNEY_STEP_SUCCEEDED';
export const UPDATE_JOURNEY_STEP_FAILED = 'UPDATE_JOURNEY_STEP';

export const FETCH_JOURNEY_STEP = 'FETCH_JOURNEY_STEP';
export const FETCH_JOURNEY_STEP_SUCCEEDED = 'FETCH_JOURNEY_STEP_SUCCEEDED';
export const FETCH_JOURNEY_STEP_FAILED = 'FETCH_JOURNEY_STEP_FAILED';

export const REARRANDE_STEP_IN_JOURNEY = 'REARRANDE_STEP_IN_JOURNEY';
export const DELETE_STEP_FROM_JOURNEY = 'DELETE_STEP_FROM_JOURNEY';

export const DELETE_JOURNEY_REQUEST = 'DELETE_JOURNEY_REQUEST';
export const DELETE_JOURNEY_SUCCEEDED = 'DELETE_JOURNEY_SUCCEEDED';
export const DELETE_JOURNEY_FAILED = 'DELETE_JOURNEY_FAILED';

/*
  Reports Actions
*/
export const FETCHING_ASSETS_FOR_SESSION = 'FETCHING_ASSETS_FOR_SESSION';
export const FETCHING_ASSETS_FOR_SESSION_SUCCEEDED = 'FETCHING_ASSETS_FOR_SESSION_SUCCEEDED';
export const FETCHING_ASSETS_FOR_SESSION_FAILED = 'FETCHING_ASSETS_FOR_SESSION_FAILED';

export const FETCHING_SESSION_ASSET_ANALYITCS = 'FETCHING_SESSION_ASSET_ANALYITCS';
export const FETCHING_SESSION_ASSET_ANALYITCS_SUCCEEDED = 'FETCHING_SESSION_ASSET_ANALYITCS_SUCCEEDED';
export const FETCHING_SESSION_ASSET_ANALYITCS_FAILED = 'FETCHING_SESSION_ASSET_ANALYITCS_FAILED';

export const UPDATE_FILTER_FOR_REPORTS = 'UPDATE_FILTER_FOR_REPORTS';

/*
  Visitor Reports Actions
*/

export const FETCH_TOP_VISITORS = 'FETCH_TOP_VISITORS';
export const FETCHING_TOP_VISITORS_SUCCEEDED = 'FETCHING_TOP_VISITORS_SUCCEEDED';
export const FETCHING_TOP_VISITORS_FAILED = 'FETCHING_TOP_VISITORS_FAILED';

export const FETCHING_VISITOR_SESSIONS = 'FETCHING_VISITOR_SESSIONS';
export const FETCHING_VISITOR_SESSIONS_SUCCEEDED = 'FETCHING_VISITOR_SESSIONS_SUCCEEDED';
export const FETCHING_VISITOR_SESSIONS_FAILED = 'FETCHING_VISITOR_SESSIONS_FAILED';

export const FETCH_VISITORS_BY_BROWSERS = 'FETCH_VISITORS_BY_BROWSERS';
export const FETCHING_VISITORS_BY_BROWSERS_SUCCEEDED = 'FETCHING_VISITORS_BY_BROWSERS_SUCCEEDED';
export const FETCHING_VISITORS_BY_BROWSERS_FAILED = 'FETCHING_VISITORS_BY_BROWSERS_FAILED';

export const FETCH_VISITORS_BY_DEVICES = 'FETCH_VISITORS_BY_DEVICES';
export const FETCHING_VISITORS_BY_DEVICES_SUCCEEDED = 'FETCHING_VISITORS_BY_DEVICES_SUCCEEDED';
export const FETCHING_VISITORS_BY_DEVICES_FAILED = 'FETCHING_VISITORS_BY_DEVICES_FAILED';

export const FETCH_VISITORS_BY_COUNTRIES = 'FETCH_VISITORS_BY_COUNTRIES';
export const FETCHING_VISITORS_BY_COUNTRIES_SUCCEEDED = 'FETCHING_VISITORS_BY_COUNTRIES_SUCCEEDED';
export const FETCHING_VISITORS_BY_COUNTRIES_FAILED = 'FETCHING_VISITORS_BY_COUNTRIES_FAILED';

export const FETCH_VISITORS_BY_REFERERS = 'FETCH_VISITORS_BY_REFERERS';
export const FETCHING_VISITORS_BY_REFERERS_SUCCEEDED = 'FETCHING_VISITORS_BY_REFERERS_SUCCEEDED';
export const FETCHING_VISITORS_BY_REFERERS_FAILED = 'FETCHING_VISITORS_BY_REFERERS_FAILED';

export const FETCH_VISITORS_BY_UTM_CAMPAIGN = 'FETCH_VISITORS_BY_UTM_CAMPAIGN';
export const FETCHING_VISITORS_BY_UTM_CAMPAIGN_SUCCEEDED = 'FETCHING_VISITORS_BY_UTM_CAMPAIGN_SUCCEEDED';
export const FETCHING_VISITORS_BY_UTM_CAMPAIGN_FAILED = 'FETCHING_VISITORS_BY_UTM_CAMPAIGN_FAILED';

export const FETCH_VISITORS_BY_UTM_SOURCE = 'FETCH_VISITORS_BY_UTM_SOURCE';
export const FETCHING_VISITORS_BY_UTM_SOURCE_SUCCEEDED = 'FETCHING_VISITORS_BY_UTM_SOURCE_SUCCEEDED';
export const FETCHING_VISITORS_BY_UTM_SOURCE_FAILED = 'FETCHING_VISITORS_BY_UTM_SOURCE_FAILED';

export const FETCH_VISITORS_BY_UTM_MEDIUM = 'FETCH_VISITORS_BY_UTM_MEDIUM';
export const FETCHING_VISITORS_BY_UTM_MEDIUM_SUCCEEDED = 'FETCHING_VISITORS_BY_UTM_MEDIUM_SUCCEEDED';
export const FETCHING_VISITORS_BY_UTM_MEDIUM_FAILED = 'FETCHING_VISITORS_BY_UTM_MEDIUM_FAILED';

export const FETCH_VISITORS_BY_PAGES = 'FETCH_VISITORS_BY_PAGES';
export const FETCHING_VISITORS_BY_PAGES_SUCCEEDED = 'FETCHING_VISITORS_BY_PAGES_SUCCEEDED';
export const FETCHING_VISITORS_BY_PAGES_FAILED = 'FETCHING_VISITORS_BY_PAGES_FAILED';

export const FETCH_SESSIONS_TIME_SERIES = 'FETCH_SESSIONS_TIME_SERIES';
export const FETCHING_SESSIONS_TIME_SERIES_SUCCEEDED = 'FETCHING_SESSIONS_TIME_SERIES_SUCCEEDED';
export const FETCHING_SESSIONS_TIME_SERIES_FAILED = 'FETCHING_SESSIONS_TIME_SERIES_FAILED';

export const FETCH_ACTIVE_SESSIONS = 'FETCH_ACTIVE_SESSIONS';
export const FETCHING_ACTIVE_SESSIONS_SUCCEEDED = 'FETCHING_ACTIVE_SESSIONS_SUCCEEDED';
export const FETCHING_ACTIVE_SESSIONS_FAILED = 'FETCHING_ACTIVE_SESSIONS_FAILED';

export const FETCHING_VISITOR_ANALYTICS_DAILY = 'FETCHING_VISITOR_ANALYTICS_DAILY';
export const FETCHING_VISITOR_ANALYTICS_DAILY_SUCCEEDED = 'FETCHING_VISITOR_ANALYTICS_DAILY_SUCCEEDED';
export const FETCHING_VISITOR_ANALYTICS_DAILY_FAILED = 'FETCHING_VISITOR_ANALYTICS_DAILY_FAILED';

export const FETCHING_VISITOR_SESSIONS_DAILY = 'FETCHING_VISITOR_SESSIONS_DAILY';
export const FETCHING_VISITOR_SESSIONS_DAILY_SUCCEEDED = 'FETCHING_VISITOR_SESSIONS_DAILY_SUCCEEDED';
export const FETCHING_VISITOR_SESSIONS_DAILY_FAILED = 'FETCHING_VISITOR_SESSIONS_DAILY_FAILED';

export const FETCHING_VISITOR_ASSETS = 'FETCHING_VISITOR_ASSETS';
export const FETCHING_VISITOR_ASSETS_SUCCEEDED = 'FETCHING_VISITOR_ASSETS_SUCCEEDED';
export const FETCHING_VISITOR_ASSETS_FAILED = 'FETCHING_VISITOR_ASSETS_FAILED';

export const FETCHING_VISITOR = 'FETCHING_VISITOR';
export const FETCHING_VISITOR_SUCCEEDED = 'FETCHING_VISITOR_SUCCEEDED';
export const FETCHING_VISITOR_FAILED = 'FETCHING_VISITOR_FAILED';

export const FETCHING_SESSIONS_FOR_VISITOR = 'FETCHING_SESSIONS_FOR_VISITOR';
export const FETCHING_SESSIONS_FOR_VISITOR_SUCCEEDED = 'FETCHING_SESSIONS_FOR_VISITOR_SUCCEEDED';
export const FETCHING_SESSIONS_FOR_VISITOR_FAILED = 'FETCHING_SESSIONS_FOR_VISITOR_FAILED';

export const SELECT_VISITOR_SESSION = 'SELECT_VISITOR_SESSION';

export const FETCHING_VISITOR_ANALYTICS = 'FETCHING_VISITOR_ANALYTICS';
export const FETCHING_VISITOR_ANALYTICS_SUCCEEDED = 'FETCHING_VISITOR_ANALYTICS_SUCCEEDED';
export const FETCHING_VISITOR_ANALYTICS_FAILED = 'FETCHING_VISITOR_ANALYTICS_FAILED';

export const DOWNLOAD_VISITOR_REPORTS = 'DOWNLOAD_VISITOR_REPORTS';
export const DOWNLOAD_VISITOR_REPORTS_SUCCEEDED = 'DOWNLOAD_VISITOR_REPORTS_SUCCEEDED';
export const DOWNLOAD_VISITOR_REPORTS_FAILED = 'DOWNLOAD_VISITOR_REPORTS_FAILED';

/*
  Lead report actions
*/
export const FETCHING_LEAD_SESSIONS = 'FETCHING_LEAD_SESSIONS';
export const FETCHING_LEAD_SESSIONS_SUCCEEDED = 'FETCHING_LEAD_SESSIONS_SUCCEEDED';
export const FETCHING_LEAD_SESSIONS_FAILED = 'FETCHING_LEAD_SESSIONS';

export const FETCHING_LEAD = 'FETCHING_LEAD';
export const FETCHING_LEAD_SUCCEEDED = 'FETCHING_LEAD_SUCCEEDED';
export const FETCHING_LEAD_FAILED = 'FETCHING_LEAD_FAILED';

export const FETCHING_SESSIONS_FOR_LEAD = 'FETCHING_SESSIONS_FOR_LEAD';
export const FETCHING_SESSIONS_FOR_LEAD_SUCCEEDED = 'FETCHING_SESSIONS_FOR_LEAD_SUCCEEDED';
export const FETCHING_SESSIONS_FOR_LEAD_FAILED = 'FETCHING_SESSIONS_FOR_LEAD_FAILED';

export const SELECT_LEAD_SESSION = 'SELECT_LEAD_SESSION';

export const FETCHING_LEAD_ANALYTICS = 'FETCHING_LEAD_ANALYTICS';
export const FETCHING_LEAD_ANALYTICS_SUCCEEDED = 'FETCHING_LEAD_ANALYTICS_SUCCEEDED';
export const FETCHING_LEAD_ANALYTICS_FAILED = 'FETCHING_LEAD_ANALYTICS_FAILED';


export const DOWNLOAD_LEAD_REPORTS = 'DOWNLOAD_LEAD_REPORTS';
export const DOWNLOADING_LEAD_REPORTS_SUCCEEDED = 'DOWNLOADING_LEAD_REPORTS_SUCCEEDED';
export const DOWNLOADING_LEAD_REPORTS_FAILED = 'DOWNLOADING_LEAD_REPORTS_FAILED';


/*
  Content report actions
*/
export const FETCHING_CONTENT_ANALYTICS_DAILY = "FETCHING_CONTENT_ANALYTICS_DAILY";
export const FETCHING_CONTENT_ANALYTICS_DAILY_SUCCEEDED = "FETCHING_CONTENT_ANALYTICS_DAILY_SUCCEEDED";
export const FETCHING_CONTENT_ANALYTICS_DAILY_FAILED = "FETCHING_CONTENT_ANALYTICS_DAILY_FAILED";

export const FETCHING_CONTENT_REPORTS = "FETCHING_CONTENT_REPORTS";
export const FETCHING_CONTENT_REPORTS_SUCCEEDED = "FETCHING_CONTENT_REPORTS_SUCCEEDED";
export const FETCHING_CONTENT_REPORTS_FAILED = "FETCHING_CONTENT_REPORTS_FAILED";

export const DOWNLOAD_CONTENT_REPORTS = 'DOWNLOAD_CONTENT_REPORTS';
export const DOWNLOADING_CONTENT_REPORTS_SUCCEEDED = 'DOWNLOADING_CONTENT_REPORTS_SUCCEEDED';
export const DOWNLOADING_CONTENT_REPORTS_FAILED = 'DOWNLOADING_CONTENT_REPORTS_FAILED';

export const CREATING_CUSTOM_FIELD_REQUEST = "CREATING_CUSTOM_FIELD_REQUEST";
export const CREATING_CUSTOM_FIELD_SUCCEEDED = "CREATING_CUSTOM_FIELD_SUCCEEDED";
export const CREATING_CUSTOM_FIELD_FAILED = "CREATING_CUSTOM_FIELD_FAILED";

export const FETCH_ASSET_SESSIONS_TIME_SERIES = "FETCH_ASSET_SESSIONS_TIME_SERIES";
export const FETCHING_ASSET_SESSIONS_TIME_SERIES_SUCCEEDED = "FETCHING_ASSET_SESSIONS_TIME_SERIES_SUCCEEDED";
export const FETCHING_ASSET_SESSIONS_TIME_SERIES_FAILED = "FETCHING_ASSET_SESSIONS_TIME_SERIES_FAILED";

export const FETCH_ASSETS_FOR_REPORT = "FETCH_ASSETS_FOR_REPORT";
export const FETCHING_ASSETS_FOR_REPORT_SUCCEEDED = "FETCHING_ASSETS_FOR_REPORT_SUCCEEDED";
export const FETCHING_ASSETS_FOR_REPORT_FAILED = "FETCHING_ASSETS_FOR_REPORTS_FAILED";

export const FETCH_CONTENT_ANALYTICS_FOR_REPORTS = "FETCH_CONTENT_ANALYTICS";
export const FETCHING_CONTENT_ANALYTICS_FOR_REPORTS_SUCCEEDED = "FETCHING_CONTENT_ANALYTICS_SUCCEEDED";
export const FETCHING_CONTENT_ANALYTICS_FOR_REPORTS_FAILED = "FETCHING_CONTENT_ANALYTICS_FAILED";



/*
  Gating related Actions
*/
export const FETCH_ALL_GATING_REQUEST = 'FETCH_ALL_GATING_REQUEST';
export const FETCH_ALL_GATING_SUCCEEDED = 'FETCH_ALL_GATING_SUCCEEDED';
export const FETCH_ALL_GATING_FAILED = 'FETCH_ALL_GATING_FAILED';

export const FETCH_GATING_REQUEST = 'FETCH_GATING_REQUEST';
export const FETCH_GATING_SUCCEEDED = 'FETCH_GATING_SUCCEEDED';
export const FETCH_GATING_FAILED = 'FETCH_GATING_FAILED';

export const CREATE_GATING_REQUEST = 'CREATE_GATING_REQUEST';
export const CREATE_GATING_SUCCEEDED = 'CREATE_GATING_SUCCEEDED';
export const CREATE_GATING_FAILED = 'CREATE_GATING_FAILED';

export const UPDATE_GATING_REQUEST = 'UPDATE_GATING_REQUEST';
export const UPDATE_GATING_SUCCEEDED = 'UPDATE_GATING_SUCCEEDED';
export const UPDATE_GATING_FAILED = 'UPDATE_GATING_FAILED';

export const DELETE_GATING_REQUEST = 'DELETE_GATING_REQUEST';
export const DELETE_GATING_SUCCEEDED = 'DELETE_GATING_SUCCEEDED';
export const DELETE_GATING_FAILED = 'DELETE_GATING_FAILED';

/*
  Websocket message
*/
export const WS_ASSET_PROCESSED = "WS_ASSET_PROCESSED";

/*
  Integrartions Actions
*/
export const FETCHING_INTEGRATIONS_REQUEST = "FETCHING_INTEGRATIONS_REQUEST";
export const FETCHING_INTEGRATIONS_SUCCEDDED = "FETCHING_INTEGRATIONS_SUCCEDDED";
export const FETCHING_INTEGRATIONS_FAILED = "FETCHING_INTEGRATIONS_FAILED";
export const INVALIDATE_INTEGRATIONS = "INVALIDATE_INTEGRATIONS";

export const ACTIVATING_INTEGRATION_SUCCEDDED = "ACTIVATING_INTEGRATION_SUCCEDDED";

export const DEACTIVATING_INTEGRATION_REQUEST = "DEACTIVATING_INTEGRATION_REQUEST";
export const DEACTIVATING_INTEGRATION_SUCCEDDED = "DEACTIVATING_INTEGRATION_SUCCEDDED";
export const DEACTIVATING_INTEGRATION_FAILED = "DEACTIVATING_INTEGRATION_FAILED";

export const INTEGRATION_AUTH_EXPIRED = "INTEGRATION_AUTH_EXPIRED";

/*
  Freshmarketer Integrartions Actions
*/
export const AUTHENTICATE_FRESHMARKETER_REQUEST = 'AUTHENTICATE_FRESHMARKETER_REQUEST';
export const AUTHENTICATE_FRESHMARKETER_SUCCEEDED = 'AUTHENTICATE_FRESHMARKETER_SUCCEEDED';
export const AUTHENTICATE_FRESHMARKETER_FAILED = 'AUTHENTICATE_FRESHMARKETER_FAILED';

export const FETCHING_FRESHMARKETER_LISTS = 'FETCHING_FRESHMARKETER_LISTS';
export const FETCHING_FRESHMARKETER_LISTS_SUCCEEDED = 'FETCHING_FRESHMARKETER_LISTS_SUCCEEDED';
export const FETCHING_FRESHMARKETER_LISTS_FAILED = 'FETCHING_FRESHMARKETER_LISTS_FAILED';

//hubspot Integration Actions
export const AUTHENTICATE_HUBSPOT_REQUEST = "AUTHENTICATE_HUBSPOT_REQUEST";
export const AUTHENTICATE_HUBSPOT_SUCCEDDED = "AUTHENTICATE_HUBSPOT_SUCCEDDED";
export const AUTHENTICATE_HUBSPOT_FAILED = "AUTHENTICATE_HUBSPOT_FAILED";

export const UPDATE_HUBSPOT_REQUEST = "UPDATE_HUBSPOT_REQUEST";
export const UPDATING_HUBSPOT_SUCCEEDED = "UPDATING_HUBSPOT_SUCCEEDED";
export const UPDATING_HUBSPOT_FAILED = "UPDATING_HUBSPOT_FAILED";

export const FETCH_HUBSPOT_FORMS = 'FETCH_HUBSPOT_FORMS';
export const FETCHING_HUBSPOT_FORMS_SUCCEEDED = 'FETCHING_HUBSPOT_FORMS_SUCCEEDED';
export const FETCHING_HUBSPOT_FORMS_FAILED = 'FETCHING_HUBSPOT_FORMS_FAILED';

export const FETCH_HUBSPOT_FORM = 'FETCH_HUBSPOT_FORM';
export const FETCHING_HUBSPOT_FORM_SUCCEEDED = 'FETCHING_HUBSPOT_FORM_SUCCEEDED';
export const FETCHING_HUBSPOT_FORM_FAILED = 'FETCHING_HUBSPOT_FORM_FAILED';

//Zoho Integration Actions
export const AUTHENTICATE_ZOHO_REQUEST = "AUTHENTICATE_ZOHO_REQUEST";
export const AUTHENTICATE_ZOHO_SUCCEDDED = "AUTHENTICATE_ZOHO_SUCCEDDED";
export const AUTHENTICATE_ZOHO_FAILED = "AUTHENTICATE_ZOHO_FAILED";

export const UPDATING_ZOHO_REQUEST = "UPDATING_ZOHO_REQUEST";
export const UPDATING_ZOHO_SUCCEDDED = "UPDATING_ZOHO_SUCCEDDED";
export const UPDATING_ZOHO_FAILED = "UPDATING_ZOHO_FAILED";

/*
  Pardot Integrartions Actions
*/
export const AUTHENTICATE_PARDOT_REQUEST = 'AUTHENTICATE_PARDOT_REQUEST';
export const AUTHTENTICATING_PARDOT_SUCCEEDED = 'AUTHTENTICATING_PARDOT_SUCCEEDED';
export const AUTHTENTICATING_PARDOT_FAILED = 'AUTHTENTICATING_PARDOT_FAILED';

export const UPDATE_PARDOT_REQUEST = 'UPDATE_PARDOT_REQUEST';
export const UPDATING_PARDOT_SUCCEEDED = 'UPDATING_PARDOT_SUCCEEDED';
export const UPDATING_PARDOT_FAILED = 'UPDATING_PARDOT_FAILED';

export const FETCH_PARDOT_FORMS = 'FETCH_PARDOT_FORMS';
export const FETCHING_PARDOT_FORMS_SUCCEEDED = 'FETCHING_PARDOT_FORMS_SUCCEEDED';
export const FETCHING_PARDOT_FORMS_FAILED = 'FETCHING_PARDOT_FORMS_FAILED';

export const FETCH_PARDOT_FORM = 'FETCH_PARDOT_FORM';
export const FETCHING_PARDOT_FORM_SUCCEEDED = 'FETCHING_PARDOT_FORM_SUCCEEDED';
export const FETCHING_PARDOT_FORM_FAILED = 'FETCHING_PARDOT_FORM_FAILED';

export const DEACTIVATE_PARDOT_REQUEST = 'DEACTIVATE_PARDOT_REQUEST';
export const DEACTIVATING_PARDOT_SUCCEEDED = 'DEACTIVATING_PARDOT_SUCCEEDED';
export const DEACTIVATING_PARDOT_FAILED = 'DEACTIVATING_PARDOT_FAILED';

/*
  Marketo Integrartions Actions
*/
export const AUTHENTICATE_MARKETO_REQUEST = 'AUTHENTICATE_MARKETO_REQUEST';
export const AUTHENTICATE_MARKETO_SUCCEEDED = 'AUTHENTICATE_MARKETO_SUCCEEDED';
export const AUTHENTICATE_MARKETO_FAILED = 'AUTHENTICATE_MARKETO_FAILED';

export const FETCH_MARKETO_FORMS = 'FETCH_MARKETO_FORMS';
export const FETCHING_MARKETO_FORMS_SUCCEEDED = 'FETCHING_MARKETO_FORMS_SUCCEEDED';
export const FETCHING_MARKETO_FORMS_FAILED = 'FETCHING_MARKETO_FORMS_FAILED';

export const SEARCH_MARKETO_FORM = 'SEARCH_MARKETO_FORM';
export const SEARCH_MARKETO_FORM_SUCCEEDED = 'SEARCH_MARKETO_FORM_SUCCEEDED';
export const SEARCH_MARKETO_FORM_FAILED = 'SEARCH_MARKETO_FORM_FAILED';

/*
Six Sense Integration
*/
export const ACTIVATING_SIX_SENSE_REQUEST = "ACTIVATING_SIX_SENSE_REQUEST";
export const ACTIVATING_SIX_SENSE_SUCCEDDED = "ACTIVATING_SIX_SENSE_SUCCEDDED";
export const ACTIVATING_SIX_SENSE_FAILED = "ACTIVATING_SIX_SENSE_FAILED";

export const UPDATING_SIX_SENSE_REQUEST = "UPDATING_SIX_SENSE_REQUEST";
export const UPDATING_SIX_SENSE_SUCCEDDED = "UPDATING_SIX_SENSE_SUCCEDDED";
export const UPDATING_SIX_SENSE_FAILED = "UPDATING_SIX_SENSE_FAILED";

/*
  Content Selection 
*/
export const CREATE_CONTENT_EDITOR = 'CREATE_CONTENT_EDITOR';
export const UPDATE_CONTENT_EDITOR = 'UPDATE_CONTENT_EDITOR';
export const INVALIDATE_CONTENT_EDITOR = 'INVALIDATE_CONTENT_EDITOR';
export const ADD_ASSET_TO_CONTENT_EDITOR = 'ADD_ASSET_TO_CONTENT_EDITOR';
export const UPDATE_ASSET_IN_CONTENT_EDITOR = 'UPDATE_ASSET_IN_CONTENT_EDITOR';
export const DELETE_ASSET_FROM_CONTENT_EDITOR = 'DELETE_ASSET_FROM_CONTENT_EDITOR';
export const REARRANGE_ASSET_IN_CONTENT_EDITOR = 'REARRANGE_ASSET_IN_CONTENT_EDITOR';

export const SAVING_CONTENT_IN_CONTENT_EDITOR = 'SAVING_CONTENT_IN_CONTENT_EDITOR';

export const FETCH_ASSETS_FOR_FILTERS = 'FETCH_ASSETS_FOR_FILTERS';
export const FETCH_ASSETS_FOR_FILTERS_SUCCEEDED = 'FETCH_ASSETS_FOR_FILTERS_SUCCEEDED';
export const FETCH_ASSETS_FOR_FILTERS_FAILED = 'FETCH_ASSETS_FOR_FILTERS_FAILED';


/*
 * Live Actions related actions
*/
export const FETCH_LIVE_ELEMENTS_REQUEST = 'FETCH_LIVE_ELEMENTS_REQUEST';
export const FETCHING_LIVE_ELEMENTS_SUCCEEDED = 'FETCHING_LIVE_ELEMENTS_SUCCEEDED';
export const FETCHING_LIVE_ELEMENTS_FAILED = 'FETCHING_LIVE_ELEMENTS_FAILED';

export const FETCH_LIVE_ELEMENT_REQUEST = 'FETCH_LIVE_ELEMENT_REQUEST';
export const FETCHING_LIVE_ELEMENT_SUCCEEDED = 'FETCHING_LIVE_ELEMENT_SUCCEEDED';
export const FETCHING_LIVE_ELEMENT_FAILED = 'FETCHING_LIVE_ELEMENT_FAILED';

export const CREATE_LIVE_ELEMENT_REQUEST = 'CREATE_LIVE_ELEMENT_REQUEST';
export const CREATING_LIVE_ELEMENT_SUCCEEDED = 'CREATING_LIVE_ELEMENT_SUCCEEDED';
export const CREATING_LIVE_ELEMENT_FAILED = 'CREATING_LIVE_ELEMENT_FAILED';

export const CLONE_LIVE_ELEMENT_REQUEST = 'CLONE_LIVE_ELEMENT_REQUEST';
export const CLONING_LIVE_ELEMENT_SUCCEEDED = 'CLONING_LIVE_ELEMENT_SUCCEEDED';
export const CLONING_LIVE_ELEMENT_FAILED = 'CLONING_LIVE_ELEMENT_FAILED';

export const UPDATE_LIVE_ELEMENT_REQUEST = 'UPDATE_LIVE_ELEMENT_REQUEST';
export const UPDATING_LIVE_ELEMENT_SUCCEEDED = 'UPDATING_LIVE_ELEMENT_SUCCEEDED';
export const UPDATING_LIVE_ELEMENT_FAILED = 'UPDATING_LIVE_ELEMENT_FAILED';

export const UPDATE_LIVE_ELEMENT_NAME = 'UPDATE_LIVE_ELEMENT_NAME';

export const PUBLISH_LIVE_ELEMENT_REQUEST = 'PUBLISH_LIVE_ELEMENT_REQUEST';
export const PUBLISHING_LIVE_ELEMENT_SUCCEEDED = 'PUBLISHING_LIVE_ELEMENT_SUCCEEDED';
export const PUBLISHING_LIVE_ELEMENT_FAILED = 'PUBLISHING_LIVE_ELEMENT_FAILED';

export const DELETE_LIVE_ELEMENT_REQUEST = 'DELETE_LIVE_ELEMENT_REQUEST';
export const DELETE_LIVE_ELEMENT_SUCCEEDED = 'DELETE_LIVE_ELEMENT_SUCCEEDED';
export const DELETE_LIVE_ELEMENT_FAILED = 'DELETE_LIVE_ELEMENT_FAILED';

/**
 * Asset viewer Actions related actions
*/
export const FETCH_ASSET_VIEWERS_REQUEST = 'FETCH_ASSET_VIEWERS_REQUEST';
export const FETCHING_ASSET_VIEWERS_SUCCEEDED = 'FETCHING_ASSET_VIEWERS_SUCCEEDED';
export const FETCHING_ASSET_VIEWERS_FAILED = 'FETCHING_ASSET_VIEWERS_FAILED';

export const FETCH_ASSET_VIEWER_REQUEST = 'FETCH_ASSET_VIEWER_REQUEST';
export const FETCHING_ASSET_VIEWER_SUCCEEDED = 'FETCHING_ASSET_VIEWER_SUCCEEDED';
export const FETCHING_ASSET_VIEWER_FAILED = 'FETCHING_ASSET_VIEWER_FAILED';

export const CREATE_ASSET_VIEWER_REQUEST = 'FETCHING_ASSET_VIEWERS_REQUEST';
export const CREATING_ASSET_VIEWER_SUCCEEDED = 'CREATING_ASSET_VIEWER_SUCCEEDED';
export const CREATING_ASSET_VIEWER_FAILED = 'CREATING_ASSET_VIEWER_FAILED';

export const CLONE_ASSET_VIEWER_REQUEST = 'CLONE_ASSET_VIEWER_REQUEST';
export const CLONING_ASSET_VIEWER_SUCCEEDED = 'CLONING_ASSET_VIEWER_SUCCEEDED';
export const CLONING_ASSET_VIEWER_FAILED = 'CLONING_ASSET_VIEWER_FAILED';

export const UPDATE_ASSET_VIEWER_REQUEST = 'UPDATE_ASSET_VIEWER_REQUEST';
export const UPDATING_ASSET_VIEWER_SUCCEEDED = 'UPDATING_ASSET_VIEWER_SUCCEEDED';
export const UPDATING_ASSET_VIEWER_FAILED = 'UPDATING_ASSET_VIEWER_FAILED';

export const PUBLISH_ASSET_VIEWER_REQUEST = 'PUBLISH_ASSET_VIEWER_REQUEST';
export const PUBLISHING_ASSET_VIEWER_SUCCEEDED = 'PUBLISHING_ASSET_VIEWER_SUCCEEDED';
export const PUBLISHING_ASSET_VIEWER_FAILED = 'PUBLISHING_ASSET_VIEWER_FAILED';

export const RESTORE_ASSET_VIEWER_REQUEST = 'RESTORE_ASSET_VIEWER_REQUEST';
export const RESTORING_ASSET_VIEWER_SUCCEEDED = 'RESTORING_ASSET_VIEWER_SUCCEEDED';
export const RESTORING_ASSET_VIEWER__FAILED = 'RESTORING_ASSET_VIEWER_FAILED';

export const DELETE_ASSET_VIEWER_REQUEST = 'DELETE_ASSET_VIEWER_REQUEST';
export const DELETING_ASSET_VIEWER_SUCCEEDED = 'DELETING_ASSET_VIEWER_SUCCEEDED';
export const DELETING_ASSET_VIEWER_FAILED = 'DELETING_ASSET_VIEWER_FAILED';


/**
 * URL Management
*/
export const CREATE_ALIAS_REQUEST = 'CREATE_ALIAS_REQUEST';
export const CREATING_ALIAS_SUCCEEDED = 'CREATING_ALIAS_SUCCEEDED';
export const CREATING_ALIAS_FAILED = 'CREATING_ALIAS_FAILED';

export const UPDATE_ALIAS_REQUEST = 'UPDATE_ALIAS_REQUEST';
export const UPDATING_ALIAS_SUCCEEDED = 'UPDATING_ALIAS_SUCCEEDED';
export const UPDATING_ALIAS_FAILED = 'UPDATING_ALIAS_FAILED';

export const FETCH_ALIASES_REQUEST = 'FETCH_ALIASES_REQUEST';
export const FETCHING_ALIASES_SUCCEEDED = 'FETCHING_ALIASES_SUCCEEDED';
export const FETCHING_ALIASES_FAILED = 'FETCHING_ALIASES_FAILED';

export const DELETE_ALIAS_REQUEST = 'DELETE_ALIAS_REQUEST';
export const DELETING_ALIAS_SUCCEEDED = 'DELETING_ALIAS_SUCCEEDED';
export const DELETING_ALIAS_FAILED = 'DELETING_ALIAS_FAILED';

export const INVALIDATE_ALIASES = 'INVALIDATE_ALIASES';

/**
 * Form related actions
*/
export const FETCH_FORMS_REQUEST = 'FETCH_FORMS_REQUEST';
export const FETCHING_FORMS_SUCCEEDED = 'FETCHING_FORMS_SUCCEEDED';
export const FETCHING_FORMS_FAILED = 'FETCHING_FORMS_FAILED';

export const FETCH_FORM_REQUEST = 'FETCH_FORM_REQUEST';
export const FETCHING_FORM_SUCCEEDED = 'FETCHING_FORM_SUCCEEDED';
export const FETCHING_FORM_FAILED = 'FETCHING_FORM_FAILED';

export const INVALIDATE_FORM_SELECTION = 'INVALIDATE_FORM_SELECTION';

export const CREATE_FORM_REQUEST = 'CREATE_FORM_REQUEST';
export const CREATING_FORM_SUCCEEDED = 'CREATING_FORM_SUCCEEDED';
export const CREATING_FORM_FAILED = 'CREATING_FORM_FAILED';

export const CLONE_FORM_REQUEST = 'CLONE_FORM_REQUEST';
export const CLONING_FORM_SUCCEEDED = 'CLONING_FORM_SUCCEEDED';
export const CLONING_FORM_FAILED = 'CLONING_FORM_FAILED';

export const UPDATE_FORM_REQUEST = 'UPDATE_FORM_REQUEST';
export const UPDATING_FORM_SUCCEEDED = 'UPDATING_FORM_SUCCEEDED';
export const UPDATING_FORM_FAILED = 'UPDATING_FORM_FAILED';

export const PUBLISH_FORM_REQUEST = 'PUBLISH_FORM_REQUEST';
export const PUBLISHING_FORM_SUCCEEDED = 'PUBLISHING_FORM_SUCCEEDED';
export const PUBLISHING_FORM_FAILED = 'PUBLISHING_FORM_FAILED';

export const DELETE_FORM_REQUEST = 'DELETE_FORM_REQUEST';
export const DELETING_FORM_SUCCEEDED = 'DELETING_FORM_SUCCEEDED';
export const DELETING_FORM_FAILED = 'DELETING_FORM_FAILED';

export const RESTORE_FORM_REQUEST = 'RESTORE_FORM_REQUEST';
export const RESTORING_FORM_SUCCEEDED = 'RESTORE_FORM_REQUEST_SUCCEEDED';
export const RESTORING_FORM_FAILED = 'RESTORING_FORM_FAILED'

export const UPDATE_ASSET_IN_PLACEHOLDER = 'UPDATE_ASSET_IN_PLACEHOLDER';

export const ADD_PLACEHOLDER_ASSETS_FOR_WIDGET = 'ADD_PLACEHOLDER_ASSETS_FOR_WIDGET';
export const ADD_PLACEHOLDER_ASSETS_FOR_WIDGET_SUCCEEDED = 'ADD_PLACEHOLDER_ASSETS_FOR_WIDGET_SUCCEEDED';
export const ADD_PLACEHOLDER_ASSETS_FOR_WIDGET_FAILED = 'ADD_PLACEHOLDER_ASSETS_FOR_WIDGET_FAILED';

//USER MANAGEMENT
export const INVITE_USER_REQUEST = "INVITE_USER_REQUEST";
export const INVITE_USER_SUCCEEDED = "INVITE_USER_SUCCEEDED";
export const INVITE_USER_FAILED = "INVITE_USER_FAILED";
export const INVALIDATE_ERROR_MESSAGE = "INVALIDATE_ERROR_MESSAGE";

export const FETCHING_USER_INVITATION = "FETCHING_USER_INVITATION";
export const FETCHING_USER_INVITATION_SUCCEDDED = "FETCHING_USER_INVITATION_SUCCEDDED";
export const FETCHING_USER_INVITATION_FAILED = "FETCHING_USER_INVITATION_FAILED";

export const USER_REGISTRATION_REQUEST = "USER_REGISTRATION_REQUEST";
export const USER_REGISTRATION_SUCCEDDED = "USER_REGISTRATION_SUCCEDDED";
export const USER_REGISTRATION_FAILED = "USER_REGISTRATION_FAILED";

export const FETCHING_USERS_REQUEST = "FETCHING_USERS_REQUEST";
export const FETCHING_USERS_SUCCEEDED = "FETCHING_USERS_SUCCEEDED";
export const FETCHING_USERS_FAILED = "FETCHING_USERS_FAILED";

export const UPDATE_USER_ACCESS = "UPDATE_USER_ACCESS";
export const UPDATE_USER_ACCESS_SUCCEEDED = "UPDATE_USER_ACCESS_SUCCEEDED";
export const UPDATE_USER_ACCESS_FAILED = "UPDATE_USER_ACCESS_FAILED";

export const FETCHING_INVITATIONS_REQUEST = "FETCHING_INVITATIONS_REQUEST";
export const FETCHING_INVITATIONS_SUCCEEDED = "FETCHING_INVITATIONS_SUCCEEDED";
export const FETCHING_INVITATIONS_FAILED = "FETCHING_INVITATIONS_FAILED";

export const RESEND_USER_INVITATION = "RESEND_USER_INVITATION";
export const RESEND_USER_INVITATION_SUCCEEDED = "RESEND_USER_INVITATION_SUCCEEDED";
export const RESEND_USER_INVITATION_FAILED = "RESEND_USER_INVITATION_FAILED";

export const DELETE_USER_INVITATION = "DELETE_USER_INVITATION";
export const DELETE_USER_INVITATION_SUCCEEDED = "DELETE_USER_INVITATION_SUCCEEDED";
export const DELETE_USER_INVITATION_FAILED = "DELETE_USER_INVITATION_FAILED";

export const CHANGING_PASSWORD = "CHANGING_PASSWORD";
export const CHANGING_PASSWORD_SUCCEEDED = "CHANGING_PASSWORD_SUCCEEDED";
export const CHANGING_PASSWORD_FAILED = "CHANGING_PASSWORD_FAILED";

//Section Alias

export const UPDATE_ALIAS_FOR_SECTION = "UPDATE_ALIAS_FOR_SECTION";
export const UPDATE_ALIAS_FOR_SECTION_SUCCEEDED = "UPDATE_ALIAS_FOR_SECTION_SUCCEEDED";
export const UPDATE_ALIAS_FOR_SECTION_FAILED = "UPDATE_ALIAS_FOR_SECTION_FAILED";