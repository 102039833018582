import {
  FETCH_PAGES_REQUEST, FETCHING_PAGES_SUCCEEDED, FETCHING_PAGES_FAILED,
  
  FETCH_RECENTLY_UPDATED_PAGES_REQUEST, FETCHING_RECENTLY_UPDATED_PAGES_SUCCEEDED, FETCHING_RECENTLY_UPDATED_PAGES_FAILED,

  FETCH_PAGE_REQUEST, FETCHING_PAGE_SUCCEEDED, FETCHING_PAGE_FAILED,

  FETCH_PAGE_ANALYTICS_REQUEST, FETCHING_PAGE_ANALYTICS_SUCCEEDED, FETCHING_PAGE_ANALYTICS_FAILED,

  CREATE_PAGE_REQUEST, CREATING_PAGE_SUCCEEDED, CREATING_PAGE_FAILED,

  CLONE_PAGE_REQUEST, CLONING_PAGE_SUCCEEDED, CLONING_PAGE_FAILED,

  DELETE_PAGE_REQUEST, DELETE_PAGE_SUCCEEDED, DELETE_PAGE_FAILED,

  SWITCH_STORYBOARD
} from 'constants/actionTypes.jsx';
import { act } from 'react-dom/test-utils';

const initialState = {
  loading: false,
  items: [],
  pagination: {
    currentPage: -1,
    totalPages: 1,
    recordsPerPage: 20,
    totalRecordsCount: 0
  },
  recentlyUpdated: {
    loading: false,
    items: []
  },
  create: {
    creating: false,
    item: null,
    error: null
  },
  clone: {
    cloning: false,
    item: null,
    error: null
  },
  templates: {
    loading: false,
    items: [],
    error: null
  },
  analytics: {

  }
}

const initialAnalyticsState = {
  loading: false,
  items: []
}

function pagesReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_PAGES_REQUEST:
      const { page } = action.payload;

      var items = state.items;

      if (page === 0) {
        return {
          ...state,
          loading: true,
          items: [],
          pagination: {
            currentPage: -1,
            totalPages: 1,
            recordsPerPage: 20,
            totalRecordsCount: 0
          }
        }
      }

      return {
        ...state,
        loading: true
      }

    case FETCHING_PAGES_SUCCEEDED:
      var items = state.items;
      var { currentPage, totalPages, recordsPerPage, totalRecordsCount, records = [] } = action.payload;

      if (currentPage !== 0) {
        items = [...state.items, ...records];
      } else {
        items = records
      }

      items = items.reduce((x, y) => {
        var index = x.findIndex((e) => {
          return e.id === y.id;
        });

        if (index < 0) {
          return [...x, y];
        } else {
          x[index] = y;
          return x;
        }
      }, []);

      return {
        ...state,
        loading: false,
        items: items,
        pagination: {
          ...state.pagination,
          currentPage,
          totalPages,
          recordsPerPage,
          totalRecordsCount
        }
      }

    case FETCH_RECENTLY_UPDATED_PAGES_REQUEST:
      return {
        ...state,
        recentlyUpdated: {
          ...state.recentlyUpdated,
          loading: true
        }
      }

    case FETCHING_RECENTLY_UPDATED_PAGES_SUCCEEDED:
      return {
        ...state,
        recentlyUpdated: {
          ...state.recentlyUpdated,
          loading: false,
          items: action.payload.records || []
        }
      }

    case FETCHING_RECENTLY_UPDATED_PAGES_FAILED:
      return {
        ...state,
        recentlyUpdated: {
          ...state.recentlyUpdated,
          loading: false,
          error: action.payload.error
        }
      }


    case CREATE_PAGE_REQUEST:
      return {
        ...state,
        create: {
          creating: true,
          item: action.payload.request
        }
      }

    case CREATING_PAGE_SUCCEEDED:
      var items = [action.payload, ...state.items];

      return {
        ...state,
        items: items,
        create: {
          creating: false,
          item: action.payload,
          error: null
        }
      }

    case CREATING_PAGE_FAILED:
      return {
        ...state,
        create: {
          creating: false,
          item: null,
          error: action.payload
        }
      }

    case CLONE_PAGE_REQUEST:
      var { id } = action.payload;

      return {
        ...state,
        clone: {
          page: id,
          cloning: true
        }
      }

    case CLONING_PAGE_SUCCEEDED:
      items = [action.payload, ...state.items];

      return {
        ...state,
        items: items,
        clone: {
          cloning: false,
          item: action.payload,
          error: null
        }
      }

    case CLONING_PAGE_FAILED:
      return {
        ...state,
        clone: {
          ...state.clone,
          cloning: false,
          item: null,
          error: action.payload
        }
      }

    case DELETE_PAGE_REQUEST:
      return state;

    case DELETE_PAGE_SUCCEEDED:
      var { id } = action.payload;

      var items = state.items.filter(item => {
        return item.id !== id;
      })

      return {
        ...state,
        items: items
      }

    case DELETE_PAGE_FAILED:
      return state;

    case SWITCH_STORYBOARD:
      return initialState;

    case FETCH_PAGE_ANALYTICS_REQUEST:
      var { id } = action.payload;
      var analyticsState = state.analytics[id] || initialAnalyticsState;

      return {
        ...state,
        analytics:{
          ...state.analytics,
          [id]: {
            ...analyticsState,
            loading: true
          }
        }
      }
    case FETCHING_PAGE_ANALYTICS_SUCCEEDED:
      var { id, response } = action.payload;
      var analyticsState = state.analytics[id] || initialAnalyticsState;

      return {
        ...state,
        analytics:{
          ...state.analytics,
          [id]: {
            ...analyticsState,
            loading: false,
            items: response
          }
        }
      }
    case FETCHING_PAGE_ANALYTICS_FAILED:
      var { id, error } = action.payload;
      var analyticsState = state.analytics[id] || initialAnalyticsState;

      return {
        ...state,
        analytics:{
          ...state.analytics,
          [id]: {
            ...analyticsState,
            loading: false,
            error: error
          }
        }
      }
    default:
      return state
  }
}

export { pagesReducer };