import {
  AUTHENTICATE_HUBSPOT_REQUEST, AUTHENTICATE_HUBSPOT_SUCCEDDED, AUTHENTICATE_HUBSPOT_FAILED,
  FETCH_HUBSPOT_FORMS, FETCHING_HUBSPOT_FORMS_SUCCEEDED, FETCHING_HUBSPOT_FORMS_FAILED,
  UPDATE_HUBSPOT_REQUEST, UPDATING_HUBSPOT_SUCCEEDED, UPDATING_HUBSPOT_FAILED
} from 'constants/actionTypes.jsx';

const initialState = {
  authentication: {
    authenticating: false,
    error: null
  },
  deactivation: {
    deactivating: false,
    error: null
  },
  forms: {
    loading: false,
    items: [],
    page: 0,
    limit: 100,
    loadMore: false
  },
  changes: {
    saving: false,
    error: null
  },
};

function hubspotReducer(state = initialState, action) {
  switch (action.type) {
    case AUTHENTICATE_HUBSPOT_REQUEST:
      return Object.assign({}, state, {
        ...state,
        authentication: {
          authenticating: true,
          error: null
        }
      })

    case AUTHENTICATE_HUBSPOT_SUCCEDDED:
      return Object.assign({}, state, {
        ...state,
        authentication: {
          authenticating: false,
          error: null
        }
      })
    case AUTHENTICATE_HUBSPOT_FAILED:
      return Object.assign({}, state, {
        ...state,
        authentication: {
          authenticating: false,
          error: action.payload
        }
      })
    
    case FETCH_HUBSPOT_FORMS:
      return {
        ...state,
        forms: {
          ...state.forms,
          loading: true
        }
      }

    case FETCHING_HUBSPOT_FORMS_SUCCEEDED:

      var items = state.forms.items;

      if (state.forms.page !== 0) {
        items = [...items, ...action.payload];
      } else {
        items = action.payload
      }

      items = items.reduce((x, y) => {
        var index = x.findIndex((e) => {
          return e.guid === y.guid;
        });

        if (index < 0) {
          return [...x, y];
        } else {
          x[index] = y;
          return x;
        }
      }, []);

      return {
        ...state,
        forms: {
          ...state.forms,
          loading: false,
          items: items,
          page: state.forms.page + state.forms.limit,
          loadMore: action.payload.length > 0
        }
      }

    case FETCHING_HUBSPOT_FORMS_FAILED:
      return {
        ...state,
        forms: {
          ...state.forms,
          loading: false,
          error: action.payload
        }
      }

    case UPDATE_HUBSPOT_REQUEST:
      return {
        ...state,
        changes: {
          saving: true,
          error: null
        }
      }

    case UPDATING_HUBSPOT_SUCCEEDED:
      return {
        ...state,
        changes: {
          ...state.changes,
          saving: false,
          error: null
        }
      }

    case UPDATING_HUBSPOT_FAILED: 
      return {
        ...state,
        changes: {
          ...state.changes,
          saving: false,
          error: action.payload
        }
      }

    default:
      return state;
  }
}

export { hubspotReducer };