import {
  CREATE_CONTENT_EDITOR, UPDATE_CONTENT_EDITOR, INVALIDATE_CONTENT_EDITOR, ADD_ASSET_TO_CONTENT_EDITOR,
  UPDATE_ASSET_IN_CONTENT_EDITOR,
  FETCH_ASSETS_FOR_FILTERS, FETCH_ASSETS_FOR_FILTERS_SUCCEEDED, FETCH_ASSETS_FOR_FILTERS_FAILED,
  DELETE_ASSET_FROM_CONTENT_EDITOR, REARRANGE_ASSET_IN_CONTENT_EDITOR,
  SAVING_CONTENT_IN_CONTENT_EDITOR, UPDATE_ASSET_IN_PLACEHOLDER,

  FETCH_WIDGET_ASSET_REQUEST, FETCHING_WIDGET_ASSET_SUCCEEDED, FETCHING_WIDGET_ASSET_FAILED

} from 'constants/actionTypes';

import _ from 'lodash';
import { selectAsset } from 'actions/content';

const initialState = {
  editors: {}
}

const initialContentState = {
  type: null,
  list: null,
  filters: [],
  assets: [],
  loading: false,
  resetting: false,
  pagination: {
    current: -1,
    totalPages: 1,
    totalRecords: 0
  },
  selectedAsset: null
}

export function contentSelectionReducer(state = initialState, action) {
  switch (action.type) {
    case CREATE_CONTENT_EDITOR:
      var { editor, content } = action.payload;

      var editorContext = _.get(state, `editors.${editor}`, initialContentState);

      var assets = (content.assets || []).map(asset => {
        return {
          ...asset,
          isIconSet: false,
          isNameSet: false,
          isDescriptionSet: false,
          icon: _.get(asset.icon, 'full', null)
        }
      });

      return {
        ...state,
        editors: {
          ...state.editors,
          [editor]: {
            ...editorContext,
            ...content,
            assets: assets
          }
        }
      }

    case UPDATE_CONTENT_EDITOR:
      var { editor, content } = action.payload;

      var editorContext = _.get(state, `editors.${editor}`, initialContentState);

      if (!editorContext) {
        return state;
      }

      return {
        ...state,
        editors: {
          ...state.editors,
          [editor]: {
            ...editorContext,
            ...content
          }
        }
      }

    case INVALIDATE_CONTENT_EDITOR:
      var editor = action.payload;

      var editors = _.omit(state.editors, [editor]);
      return {
        ...state,
        editors: {
          ...editors
        }
      }

    case ADD_ASSET_TO_CONTENT_EDITOR:
      var { editor, asset } = action.payload;

      var editorContext = _.get(state, `editors.${editor}`, initialContentState);

      if (!editorContext) {
        return state;
      }

      var type = editorContext.type;

      if (type !== 'asset') {
        return state;
      }

      var assets = editorContext.assets || [];

      var index = assets.findIndex(item => item.id === asset.id);

      if (index > -1) {
        return state;
      }

      asset = {
        ...asset,
        isIconSet: false,
        isNameSet: false,
        isDescriptionSet: false,
        icon: _.get(asset.icon, 'full', null),
        override: false,
      }
      assets.push(asset);

      return {
        ...state,
        editors: {
          ...state.editors,
          [editor]: {
            ...editorContext,
            assets: assets
          }
        }
      }

    case UPDATE_ASSET_IN_PLACEHOLDER:
      var { editor, asset } = action.payload;
      var editorContext = _.get(state, `editors.${editor}`, initialContentState);

      if (!editorContext) {
        return state;
      }

      var assets = editorContext.assets || [];

      var index = _.findIndex(assets, { id: asset.id });
      assets.splice(index, 1, asset);

      return {
        ...state,
        editors: {
          ...state.editors,
          [editor]: {
            ...editorContext,
            assets: assets,
            selectedAsset: asset
          }
        }
      }

    case UPDATE_ASSET_IN_CONTENT_EDITOR:
      var { editor, asset } = action.payload;

      var editorContext = _.get(state, `editors.${editor}`, initialContentState);

      if (!editorContext) {
        return state;
      }

      var assets = (editorContext.assets || []).map(item => {
        if (item.id === asset.id) {
          return asset;
        }

        return item;
      });

      return {
        ...state,
        editors: {
          ...state.editors,
          [editor]: {
            ...editorContext,
            assets: assets,
            selectedAsset: asset
          }
        }
      }

    case DELETE_ASSET_FROM_CONTENT_EDITOR:
      var { editor, asset } = action.payload;

      var editorContext = _.get(state, `editors.${editor}`, initialContentState);

      if (!editorContext) {
        return state;
      }

      var currentIndex = (editorContext.assets || []).findIndex(item => {
        return item.id !== asset.id;
      });

      var assets = (editorContext.assets || []).filter(item => {
        return item.id !== asset.id;
      });

      var selectedAsset = editorContext.selectedAsset;

      if (selectedAsset && selectedAsset.id === asset.id) {
        if (currentIndex > assets.length - 1) {
          currentIndex = 0;
        }

        var selectedAsset = assets[currentIndex];
      }

      return {
        ...state,
        editors: {
          ...state.editors,
          [editor]: {
            ...editorContext,
            assets: assets,
            selectedAsset: selectedAsset
          }
        }
      }

    case REARRANGE_ASSET_IN_CONTENT_EDITOR:
      var { editor, asset, from, to } = action.payload;

      var editorContext = _.get(state, `editors.${editor}`, initialContentState);

      if (!editorContext) {
        return state;
      }

      var assets = (editorContext.assets || []);
      assets = [...assets];

      var index = assets.findIndex(item => {
        return item.id === asset
      });

      console.log(index);

      var sourceAsset = assets[index];

      assets.splice(index, 1);
      assets.splice(to, 0, sourceAsset);

      return {
        ...state,
        editors: {
          ...state.editors,
          [editor]: {
            ...editorContext,
            assets: assets
          }
        }
      }

    case FETCH_ASSETS_FOR_FILTERS:
      var { editor, page } = action.payload;

      var editorContext = _.get(state, `editors.${editor}`, initialContentState);

      if (!editorContext) {
        return state;
      }

      return {
        ...state,
        editors: {
          ...state.editors,
          [editor]: {
            ...editorContext,
            loading: true,
            pagination: {
              ...editorContext.pagination,
              current: page,
            }
          }
        }
      }

    case FETCH_ASSETS_FOR_FILTERS_SUCCEEDED:
      var { editor, response, page } = action.payload;

      var editorContext = _.get(state, `editors.${editor}`, initialContentState);

      if (!editorContext) {
        return state;
      }

      var records = response.records.map(asset => {
        return {
          ...asset,
          isIconSet: false,
          isNameSet: false,
          isDescriptionSet: false,
          icon: _.get(asset.icon, 'full', null)
        }
      });

      var items = editorContext.assets || [];

      if (response.currentPage !== 0) {
        items = [...items, ...records];
      } else {
        items = records
      }

      items = items.reduce((x, y) => {
        var index = x.findIndex((e) => {
          return e.id === y.id;
        });

        if (index < 0) {
          return [...x, y];
        } else {
          x[index] = y;
          return x;
        }
      }, []);

      return {
        ...state,
        editors: {
          ...state.editors,
          [editor]: {
            ...editorContext,
            assets: items,
            loading: false,
            pagination: {
              ...editorContext.pagination,
              current: response.currentPage,
              totalPages: response.totalPages,
              totalRecords: response.totalRecordsCount
            }
          }
        }
      }

    case FETCH_ASSETS_FOR_FILTERS_FAILED:
      var { editor, error } = action.payload;

      return {
        ...state,
        editors: {
          ...state.editors,
          [editor]: {
            ...editorContext,
            loading: false,
            error: error
          }
        }
      }

    case SAVING_CONTENT_IN_CONTENT_EDITOR:
      var { editor } = action.payload;

      var editorContext = _.get(state, `editors.${editor}`, initialContentState);

      return {
        ...state,
        editors: {
          ...state.editors,
          [editor]: {
            ...editorContext,
            saving: true
          }
        }
      };

    case FETCH_WIDGET_ASSET_REQUEST:
      var { contextId, id } = action.payload;

      var editorContext = _.get(state, `editors.${contextId}`, initialContentState);

      if (!editorContext) {
        return state;
      }
      return Object.assign({}, state, {
        ...state,
        editors: {
          ...state.editors,
          [contextId]: {
            ...editorContext,
            resetting: true,
          }
        }
      })

    case FETCHING_WIDGET_ASSET_SUCCEEDED:
      var { contextId, asset } = action.payload;

      var editorContext = _.get(state, `editors.${contextId}`, initialContentState);

      if (!editorContext) {
        return state;
      }

      var assets = (editorContext.assets || []).map(item => {
        if (item.id === asset.id) {
          return {
            ...asset,
            override: false,
            icon: _.get(asset, 'icon.full', null)
          }
        }

        return item;
      });

      var selectedAsset = _.find(assets, ['id', asset.id]);

      return Object.assign({}, state, {
        ...state,
        editors: {
          ...state.editors,
          [contextId]: {
            ...editorContext,
            resetting: false,
            assets: assets,
            selectedAsset: selectedAsset
          }
        }
      })

    case FETCHING_WIDGET_ASSET_FAILED:
      var { contextId, error } = action.payload;
      return Object.assign({}, state, {
        ...state,
        editors: {
          ...state.editors,
          [contextId]: {
            ...editorContext,
            resetting: false,
            error: error
          }
        }
      })

    default:
      return state;
  }
}