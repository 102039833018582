import {
  SELECT_ASSET_FOR_PAGE,
  FETCHING_ASSET, FETCHING_ASSET_SUCCEEDED, FETCHING_ASSET_FAILED,
  FETCHING_ASSET_PREVIEW, FETCHING_ASSET_PREVIEW_SUCCEEDED, FETCHING_ASSET_PREVIEW_FAILED,
  FETCHING_ASSET_PREVIEW_TEXT, FETCHING_ASSET_PREVIEW_TEXT_SUCCEDDED, FETCHING_ASSET_PREVIEW_TEXT_FAILED,
  FETCHING_HTML_PREVIEW_TEMPLATE, FETCHING_HTML_PREVIEW_TEMPLATE_SUCCEEDED, FETCHING_HTML_PREVIEW_TEMPLATE_FAILED,

  RECORDING_ASSET_SESSION_EVENT_REQUEST, RECORDING_ASSET_SESSION_EVENT_SUCCEDDED, RECORDING_ASSET_SESSION_EVENT_FAILED,

  CAPTURED_ASSET_VIDEO_NEW_RANGE, UPDATED_ASSET_VIDEO_RANGE, REMOVED_ASSET_VIDEO_RANGE,
  SYNCING_ASSET_VIEW_RANGE_REQUEST, SYNCING_ASSET_VIEW_RANGE_SUCCEDDED, SYNCING_ASSET_VIEW_RANGE_FAILED,

  UPDATING_ASSET, UPDATING_ASSET_SUCCEEDED, UPDATING_ASSET_FAILED,
  UPLOAD_NEW_ASSET_VERSION_SUCCEDDED, UPLOAD_NEW_ASSET_VERSION_FAILED,
  UPLOADING_NEW_ASSET_VERSION_STARTED, UPLOADING_NEW_ASSET_VERSION_PROGRESS, UPLOAD_NEW_ASSET_VERSION_CANCEL,
  PROCESS_ASSET, PROCESS_ASSET_SUCCESS, PROCESS_ASSET_FAILED,

  SWITCH_STORYBOARD,

  CREATING_ALIAS_SUCCEEDED, UPDATING_ALIAS_SUCCEEDED, WS_ASSET_PROCESSED
} from 'constants/actionTypes';

import _ from 'lodash';

const initialSessionState = {
  id: null,
  creating: false,
  updating: false,
  page: {
    index: 0,
    creating: false,
    updating: false
  },
  ranges: []
};

const initialAssetState = {
  loading: false,
  processing: false,
  fetch: false,
  updating: false,
  invalidated: true,
  item: null,
  preview: {
    loading: false,
    item: null
  },
  session: {
    ...initialSessionState
  },
  upload: {
    uploading: false,
    progress: 0,
    file: null,
    source: null
  }
}

const initialState = {}

export function assetReducer(state = initialState, action) {
  switch (action.type) {
    case SELECT_ASSET_FOR_PAGE:
      var id = action.payload.contextId;
      var asset = action.payload.asset;

      var assetState = state[id] || initialAssetState;

      if (!id) {
        return state;
      }

      return {
        ...state,
        [id]: {
          ...assetState,
          item: asset
        }
      }

    case FETCHING_ASSET:
      id = action.payload.contextId;
      assetState = state[id] || initialAssetState;

      if (!id) {
        return state;
      }

      return {
        ...state,
        [id]: {
          ...assetState,
          loading: true,
          invalidated: true,
          preview: {
            loading: false,
            item: null
          }
        }
      }

    case FETCHING_ASSET_SUCCEEDED:
      id = action.payload.contextId;
      assetState = state[id] || initialAssetState;

      if (!id) {
        return state;
      }

      asset = action.payload.asset;

      return {
        ...state,
        [id]: {
          ...assetState,
          loading: false,
          item: asset,
          invalidated: false,
          preview: {
            loading: false,
            item: null
          }
        }
      }

    case FETCHING_ASSET_FAILED:
      id = action.payload.contextId;
      assetState = state[id] || initialAssetState;

      if (!id) {
        return state;
      }

      return {
        ...state,
        [id]: {
          ...assetState,
          loading: false,
          invalidated: true,
          error: action.payload.error
        }
      }

    case FETCHING_ASSET_PREVIEW:
      id = action.payload.contextId;
      assetState = state[id] || initialAssetState;

      if (!id) {
        return state;
      }

      if (!id) {
        return state;
      }

      return {
        ...state,
        [id]: {
          ...assetState,
          preview: {
            ...assetState.preview,
            loading: true
          }
        }
      }

    case FETCHING_ASSET_PREVIEW_SUCCEEDED:
      id = action.payload.contextId;
      assetState = state[id] || initialAssetState;

      if (!id) {
        return state;
      }

      if (!id) {
        return state;
      }

      var result = action.payload.result;

      return {
        ...state,
        [id]: {
          ...assetState,
          preview: {
            ...assetState.preview,
            loading: false,
            item: result
          }
        }
      }

    case FETCHING_ASSET_PREVIEW_FAILED:
      id = action.payload.contextId;
      assetState = state[id] || initialAssetState;

      if (!id) {
        return state;
      }

      var error = action.payload.error;

      return {
        ...state,
        [id]: {
          ...assetState,
          preview: {
            ...assetState.preview,
            error: error
          }
        }
      }

    case FETCHING_ASSET_PREVIEW_TEXT:
      return state;

    case FETCHING_ASSET_PREVIEW_TEXT_SUCCEDDED:
      id = action.payload.contextId;
      assetState = state[id] || initialAssetState;

      if (!id) {
        return state;
      }

      var pages = state.preview.item.pages.map((page, index) => {
        var response = action.payload.result.pages[index];
        return {
          ...page,
          width: response.width,
          height: response.height,
          texts: response.texts
        }
      });

      return {
        ...state,
        [id]: {
          ...assetState,
          preview: {
            ...assetState.preview,
            item: {
              ...assetState.preview.item,
              pages: pages,
              fonts: action.payload.fonts
            }
          }
        }
      }

    case FETCHING_ASSET_PREVIEW_TEXT_FAILED:
      id = action.payload.contextId;
      assetState = state[id] || initialAssetState;

      if (!id) {
        return state;
      }

      return {
        ...state,
        [id]: {
          ...assetState,
          preview: {
            ...assetState.preview,
            item: {
              ...assetState.preview.item,
              error: action.payload.error
            }
          }
        }
      }

    case FETCHING_HTML_PREVIEW_TEMPLATE:
      id = action.payload.contextId;
      assetState = state[id] || initialAssetState;

      if (!id) {
        return state;
      }

      return {
        ...state,
        [id]: {
          ...assetState,
          preview: {
            ...assetState.preview,
            loading: true
          }
        }
      }

    case FETCHING_HTML_PREVIEW_TEMPLATE_SUCCEEDED:
      id = action.payload.contextId;
      assetState = state[id] || initialAssetState;

      if (!id) {
        return state;
      }

      return {
        ...state,
        [id]: {
          ...assetState,
          preview: {
            ...assetState.preview,
            htmlPreview: action.payload.preview,
            loading: false
          }
        }
      }

    case FETCHING_HTML_PREVIEW_TEMPLATE_FAILED:
      id = action.payload.contextId;
      assetState = state[id] || initialAssetState;

      if (!id) {
        return state;
      }

      return {
        ...state,
        [id]: {
          ...assetState,
          preview: {
            ...assetState.preview,
            error: action.payload
          }
        }
      }

    case RECORDING_ASSET_SESSION_EVENT_REQUEST:
      id = action.payload.contextId;
      assetState = state[id] || initialAssetState;

      if (!id) {
        return state;
      }

      return {
        ...state,
        [id]: {
          ...assetState,
          session: {
            ...initialSessionState
          }
        }
      }

    case RECORDING_ASSET_SESSION_EVENT_SUCCEDDED:
      id = action.payload.contextId;
      assetState = state[id] || initialAssetState;

      if (!id) {
        return state;
      }

      return {
        ...state,
        [id]: {
          ...assetState,
          session: {
            ...assetState.session,
            id: action.payload.response.id,
            creating: false
          }
        }
      }

    case RECORDING_ASSET_SESSION_EVENT_FAILED:
      id = action.payload.contextId;
      assetState = state[id] || initialAssetState;

      if (!id) {
        return state;
      }

      return {
        ...state,
        [id]: {
          ...assetState,
          session: {
            ...assetState.session,
            id: null,
            creating: false,
            error: action.payload.error
          }
        }
      }

    case CAPTURED_ASSET_VIDEO_NEW_RANGE:
      id = action.payload.contextId;
      assetState = state[id] || initialAssetState;

      if (!id) {
        return state;
      }

      return {
        ...state,
        [id]: {
          ...assetState,
          session: {
            ...assetState.session,
            ranges: [
              ...assetState.session.ranges,

            ]
          }
        }
      }

    case UPDATED_ASSET_VIDEO_RANGE:
      id = action.payload.contextId;
      assetState = state[id] || initialAssetState;

      if (!id) {
        return state;
      }

      var ranges = [...assetState.session.ranges].map((range) => {
        if (range.id === action.payload.id) {
          return {
            ...range,
            end: Math.max(range.start, range.end, action.payload.end),
            updatedDate: action.payload.updatedDate,
            synced: false
          }
        }

        return range;
      });

      return {
        ...state,
        [id]: {
          ...assetState,
          session: {
            ...assetState.session,
            ranges: ranges
          }
        }
      }

    case REMOVED_ASSET_VIDEO_RANGE:
      id = action.payload.contextId;
      assetState = state[id] || initialAssetState;

      if (!id) {
        return state;
      }

      ranges = [...assetState.session.ranges].filter((range) => {
        return range.id !== action.payload.id;
      });

      return {
        ...state,
        [id]: {
          ...assetState,
          session: {
            ...assetState.session,
            ranges: ranges
          }
        }
      }

    case SYNCING_ASSET_VIEW_RANGE_REQUEST:
      id = action.payload.contextId;
      assetState = state[id] || initialAssetState;

      if (!id) {
        return state;
      }

      ranges = [...assetState.session.ranges].map((range) => {
        if (range.id === action.payload.id) {
          return {
            ...range,
            syncing: true
          }
        }

        return range;
      });

      return {
        ...state,
        [id]: {
          ...assetState,
          session: {
            ...assetState.session,
            ranges: ranges
          }
        }
      }

    case SYNCING_ASSET_VIEW_RANGE_SUCCEDDED:
      id = action.payload.contextId;
      assetState = state[id] || initialAssetState;

      if (!id) {
        return state;
      }

      ranges = [...assetState.session.ranges].map((range) => {
        if (range.id === action.payload.id) {
          return {
            ...range,
            syncing: false,
            synced: true,
            external_id: action.payload.range.rangeId,
            syncedDate: new Date()
          }
        }

        return range;
      });

      return {
        ...state,
        [id]: {
          ...assetState,
          session: {
            ...assetState.session,
            ranges: ranges
          }
        }
      }

    case SYNCING_ASSET_VIEW_RANGE_FAILED:
      id = action.payload.contextId;
      assetState = state[id] || initialAssetState;

      if (!id) {
        return state;
      }

      ranges = [...assetState.session.ranges].map((range) => {
        if (range.id === action.payload.id) {
          return {
            ...range,
            syncing: false
          }
        }

        return range;
      });

      return {
        ...state,
        [id]: {
          ...assetState,
          session: {
            ...assetState.session,
            ranges: ranges
          }
        }
      }

    case UPDATING_ASSET:
      id = action.payload.contextId;
      assetState = state[id] || initialAssetState;

      if (!id) {
        return state;
      }

      return {
        ...state,
        [id]: {
          ...assetState,
          updating: true
        }
      }

    case UPDATING_ASSET_SUCCEEDED:
      id = action.payload.contextId;
      assetState = state[id] || initialAssetState;

      if (!id) {
        return state;
      }

      return {
        ...state,
        [id]: {
          ...assetState,
          updating: false,
          item: action.payload.asset
        }
      }

    case UPDATING_ASSET_FAILED:
      id = action.payload.contextId;
      assetState = state[id] || initialAssetState;

      if (!id) {
        return state;
      }

      return {
        ...state,
        [id]: {
          ...assetState,
          updating: false,
          errors: action.payload.error
        }
      }

    case UPLOAD_NEW_ASSET_VERSION_SUCCEDDED:
      id = action.payload.contextId;
      assetState = state[id] || initialAssetState;

      if (!id) {
        return state;
      }

      return {
        ...state,
        [id]: {
          ...assetState,
          item: action.payload.asset,
          upload: {
            uploading: false,
            progress: 0,
            file: null
          }
        }
      }

    case UPLOAD_NEW_ASSET_VERSION_FAILED:
      id = action.payload.contextId;
      assetState = state[id] || initialAssetState;

      if (!id) {
        return state;
      }

      return {
        ...state,
        [id]: {
          ...assetState,
          upload: {
            uploading: false,
            progress: 0,
            file: null,
            error: action.payload.error
          }
        }
      }

    case UPLOADING_NEW_ASSET_VERSION_STARTED:
      id = action.payload.contextId;
      assetState = state[id] || initialAssetState;

      if (!id) {
        return state;
      }

      return {
        ...state,
        [id]: {
          ...assetState,
          upload: {
            uploading: true,
            progress: 0,
            file: action.payload.file
          }
        }
      }

    case UPLOADING_NEW_ASSET_VERSION_PROGRESS:
      id = action.payload.contextId;
      assetState = state[id] || initialAssetState;

      if (!id) {
        return state;
      }

      return {
        ...state,
        [id]: {
          ...assetState,
          upload: {
            ...assetState.upload,
            progress: action.payload.progress,
          }
        }
      }

    case UPLOAD_NEW_ASSET_VERSION_CANCEL:
      id = action.payload.contextId;
      assetState = state[id] || initialAssetState;

      if (!id) {
        return state;
      }

      return {
        ...state,
        [id]: {
          ...assetState,
          upload: {
            uploading: false,
            progress: 0,
            file: null,
            source: null
          }
        }
      }

    case PROCESS_ASSET: {
      const { contextId } = action.payload;

      const assetState = state[contextId] || initialAssetState;

      if (!contextId) {
        return state;
      }

      return {
        ...state,
        [contextId]: {
          ...assetState,
          fetch: false,
          processing: true,
        }
      }
    }
      
    case PROCESS_ASSET_SUCCESS: {
      const { contextId, response } = action.payload;

      const assetState = state[contextId] || initialAssetState;

      if (!contextId) {
        return state;
      }

      return {
        ...state,
        [contextId]: {
          ...assetState,
          processing: false,
          item: response,
        }      
      }
    }

    case WS_ASSET_PROCESSED: {
      const asset = action.payload;

      const contextIds = Object.entries(state).find(([key, value]) => {
        return _.get(value, 'item.id', null) === asset.id;
      })

      var contextId = contextIds && contextIds[0];

      var assetState = null;
      if(contextId) {
        assetState = state[contextId];
      }

      if(!assetState) {
        return state;
      }

      return {
        ...state,
        [contextId]: {
          ...assetState,
          processing: false,
          fetch: true,
          item: asset,
        }
      }
    }

    case PROCESS_ASSET_FAILED: {
      const { contextId, error } = action.payload;

      const assetState = state[contextId] || initialAssetState;

      if (!contextId) {
        return state;
      }

      return {
        ...state,
        [contextId]: {
          ...assetState,
          processing: false,
          error: error
        }
      }
    }
      
    case CREATING_ALIAS_SUCCEEDED:
    case UPDATING_ALIAS_SUCCEEDED:
      const { response } = action.payload;

      if (!response.canonical) {
        return state;
      }
      
      if (response.type != 'asset') {
        return state;
      }

      const target = _.get(response, 'target.id');
      
      const updated = {...state};
      Object.keys(state).forEach(context => {
        const asset = updated[context];
        const assetId = _.get(asset, 'item.id');
        if(assetId === target){
          updated[context] = {
            ...asset,
            alias: response.url
          }
        }
      });

      return updated;

    case SWITCH_STORYBOARD:
      return initialState;

    default:
      return state;
  }
}