import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateProperties, updateWidget, updateWidgetContent } from 'actions/widgetEditor'

import _ from 'lodash';
import cx from 'classnames';

import {
  Form,
  Label,
  Icon,
  Input,
  Button
} from 'semantic-ui-react';

import styles from './password.module.scss';

import { WidgetRegistry } from 'components/Widgets/registry';
import { QuickSettings, QuickSettingsProperty } from 'components/QuickSettings';

import { ModalWidget } from 'components/Widgets/modal';
import { MODAL } from 'constants/ItemTypes';

export const PasswordWidget = (props) => {
  var widgets = props.widgets || [];
  const properties = props.properties;

  const modalWidget = useSelector(state => {
    var modal = null;
    var editorContext = state.widgetsEditor.editors[props.editor];

    if (!editorContext) {
      return null;
    }

    var items = (props.widgets || []).map(id => {
      return editorContext.widgetsById[id];
    })

    modal = items.find(item => {
      const type = _.get(item, 'type');
      return type === MODAL;
    })

    if(modal !== null) {
      modal = {
        ...modal,
        properties: {
          ...properties,
          size: properties.size || 'tiny'
        }
      }
    }

    return modal;
  });

  if(!modalWidget) {
    return null;
  }

  return (
    <div
      className={cx(styles.passwordWidget, props.className)}
      id={`widget_${props.id}`}
      onMouseOver={props.onMouseOver}
      onMouseOut={props.onMouseOut}
      onClick={props.onClick}
    >
      <ModalWidget widgets={widgets} editor={props.editor} editable={props.editable} {...modalWidget} />
      {props.children}
    </div>
  )
}

function PasswordQuickSettings(props) {
  const dispatch = useDispatch();

  const [selected, setSelected] = useState('password');

  const menuItems = [
    { id: 'password', title: 'Password', icon: 'icon-custom' },
    { id: 'basic', title: 'Basic', icon: 'icon-custom' },
    { id: 'spacing', title: 'Spacing', icon: 'icon-spacing' },
    { id: 'background', title: 'Background', icon: 'icon-background' },
    { id: 'border', title: 'Border', icon: 'icon-border' },
    { id: 'advanced', title: 'Advanced', icon: 'icon-custom' }
  ]

  const handleMenuClick = (item) => {
    setSelected(item);
  }

  var widget = useSelector(state => {
    const editorContext = state.widgetsEditor.editors[props.editor];

    if (editorContext) {
      return editorContext.widgetsById[props.id];
    }

    return null;
  });
  if (!widget) {
    return false;
  }

  var properties = widget.properties;

  const handleChange = (changeRequest) => {
    dispatch(updateProperties({
      id: widget.id,
      change: changeRequest,
      context: props.editor
    }));
  }

  const handleBackgroundImageChange = (media, type) => {
    var changeRequest = { ...widget };
    _.set(changeRequest, 'properties.background.upload', {
      type: type,
      data: media
    });

    changeRequest = WidgetRegistry.processRequest([changeRequest])[0];
    dispatch(updateWidget(props.id, changeRequest, props.editor));
  }

  const handleCssChange = (css) => {
    var changeRequest = { ...widget };
    _.set(changeRequest, 'properties.css', css);

    changeRequest = WidgetRegistry.processRequest([changeRequest])[0];
    dispatch(updateWidget(props.id, changeRequest, props.editor));
  }

  return (
    <QuickSettings menuItems={menuItems} selected={selected} onMenuClick={handleMenuClick} >
      {selected === 'password' && 
        <PasswordSettings passwordSet={widget.passwordSet} id={widget.id} editor={props.editor} />
      }

      {selected === 'basic' && 
        <QuickSettingsProperty.Dropdown title='Size'
          tooltip='Size of the popup'
          placeholder='Size of the popup'
          selection
          options={[
            { key: 'mini', value: 'mini', text: 'Mini' },
            { key: 'tiny', value: 'tiny', text: 'Tiny' },
            { key: 'small', value: 'small', text: 'Small' },
            { key: 'large', value: 'large', text: 'Large' },
            { key: 'fullscreen', value: 'fullscreen', text: 'Full Screen' },
          ]}
          value={properties.size}
          onChange={(e, data) => {
            handleChange({
              size: data.value
            })
          }}
        />
      }

      {selected === 'spacing' &&
        <>
          <QuickSettingsProperty.Spacing
            title='Margin'
            value={properties.margin}
            onChange={(margin) => {
              handleChange({
                margin: margin
              });
            }}
          />
          <QuickSettingsProperty.Spacing
            title='Padding'
            value={properties.padding}
            onChange={(padding) => {
              handleChange({
                padding: padding
              });
            }}
          />
        </>
      }

      {selected === "background" &&
        <QuickSettingsProperty.Background expanded
          value={properties.background}
          onChange={(background) => {
            handleChange({
              background: background
            });
          }}
          onImageChange={(image, type) => {
            handleBackgroundImageChange(image, type);
          }}
        />
      }

      {selected === 'border' &&
        <QuickSettingsProperty.Border expanded value={properties.border} onChange={(border) => {
          handleChange({
            border: border
          });
        }}
        />
      }

      {selected === 'advanced' &&
        <QuickSettingsProperty.CSS title='Stylesheets' value={properties.css} onChange={handleCssChange} />
      }
    </QuickSettings>
  )
}

function PasswordSettings({ id, editor }) {
  const dispatch = useDispatch();

  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState(null);

  var editorContext = useSelector(state => state.widgetsEditor.editors[editor]);
  var widget = editorContext.widgetsById[id];
  var updatingContent = _.get(widget, 'updatingContent', false);

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  }

  const handleChange = (e) => {
    setPassword(e.target.value)
  }

  const handleUpdatePassword = () => {
    if(password === null || password.trim().length === 0) {
      setError("Please Enter password");
      return;
    }

    dispatch(updateWidgetContent(id, {
      content: password
    }, editor
    ));
  }

  const renderButtonText = () => {
    if(updatingContent) {
      return 'Updating...'
    }

    if(widget.passwordSet) {
      return 'Change Password';
    }

    return 'Create Password';
  }

  return (
    <div className={styles.passwordLabel}>
      <Label>Set Password for popup</Label>
      <Input
        // action={{ icon: <Icon name='eye' onClick={toggleShowPassword} />}}
        placeholder={widget.passwordSet ? "*********" : "Enter Password"}
        type={showPassword ? 'text' : 'password'} 
        onChange={handleChange}
        value={password} 
        autoComplete='off'
      />
      <span className={cx(styles.error, {[styles.showError]: error})}>{error}</span>

      <div className={styles.changePasswordButton}>
        <Button onClick={handleUpdatePassword}>
          {renderButtonText()}
        </Button>
      </div>
    </div>
  )
}

PasswordWidget.QuickSettings = PasswordQuickSettings;