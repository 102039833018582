import axios from 'axios';
import AuthService from './auth.service';
import { UNKNOWN_ERROR } from '../constants/errors.jsx';
import { refreshToken } from '../actions/users';

const apiUrl = process.env.REACT_APP_API_URL;
const OAUTH_URL = `${apiUrl}/oauth/token`;

export default class AuthInterceptor {
  constructor(history) {
    this.history = history;
    this.initialise();
    this.isAlreadyFetchingAccessToken = false;
    this.subscribers = [];
  }

  initialise() {
    axios.interceptors.request.use(function (config) {
      if(AuthService.isAuthenticated()){
        if(config.url.startsWith(apiUrl) && !config.url.startsWith(OAUTH_URL)){
          config.headers.Authorization = 'Bearer ' + AuthService.getAccessToken();
       }
      }
      return config;
    });
  
    //Intercept response and prepare the error object
    axios.interceptors.response.use((response) => {
      return response
    }, (error) => {
  
      const { config, response: { status } } = error;
      const originalRequest = config;
  
      if (status === 401) {
        if(config.url.endsWith('oauth/token') || config.url.endsWith('users/logout')){
          return Promise.reject(error);
        }
  
  
        if (!this.isAlreadyFetchingAccessToken) {
          this.isAlreadyFetchingAccessToken = true;
          var refreshTokenValue = AuthService.getRefreshToken();
  
          refreshToken(refreshTokenValue).then((token) => {
            this.isAlreadyFetchingAccessToken = false
  
            if(token && token.data && token.data.access_token){
              AuthService.saveToken(token.data.access_token, token.data.refresh_token);
              this.onAccessTokenFetched(token.data.access_token);
            }
          }).catch(err => {
            this.isAlreadyFetchingAccessToken = false;
            this.subscribers = [];
            
            window.location = '/login';
          })
        }
  
        const retryOriginalRequest = new Promise((resolve) => {
          this.addSubscriber(access_token => {
            originalRequest.headers.Authorization = 'Bearer ' + access_token
            resolve(axios(originalRequest))
          })
        })
  
        return retryOriginalRequest
      }
  
  
      console.log("Unknown error :" + error);
      
      let errorMessage = '';
  
      if(error.response && error.response.data) {
        return Promise.reject(error.response.data);
      } else {    
        return Promise.reject({
          code: UNKNOWN_ERROR,
          message: "Oops, Unable to complete the request"
        });
      }
    })
  }

  onAccessTokenFetched(access_token) {
    this.subscribers = this.subscribers.filter(callback => callback(access_token))
  }
  
  addSubscriber(callback) {
    this.subscribers.push(callback)
  }

  static updateAppId(id) {
    if (id) {
      if(window.authInterceptor) {
        axios.interceptors.request.eject(window.authInterceptor);
      }
      
      window.authInterceptor = axios.interceptors.request.use(function (config) {
        if (AuthService.isAuthenticated()) {
          if (config.url.startsWith(apiUrl)) {
            config.headers['PF-APPLICATION-ID'] = id;
          }
        }

        return config;
      });
    }
  }
}