import {
  CREATE_EDITOR_CONTEXT, UPDATE_EDITOR_CONTEXT,

  CREATING_WIDGET_SUCCEEDED, CREATING_WIDGET_FAILED,
  CLONE_WIDGET_REQUEST, CLONING_WIDGET_SUCCEEDED, CLONING_WIDGET_FAILED,

  UPDATE_WIDGET_REQUEST, UPDATING_WIDGET_SUCCEEDED, UPDATING_WIDGET_FAILED,
  UPDATE_WIDGET_CONTENT_REQUEST, UPDATING_WIDGET_CONTENT_SUCCEEDED, UPDATING_WIDGET_CONTENT_FAILED,
  FETCH_WIDGET_CONTENT_REQUEST, FETCHING_WIDGET_CONTENT_SUCCEEDED, FETCHING_WIDGET_CONTENT_FAILED,
  FETCH_WIDGET_ASSETS_REQUEST, FETCHING_WIDGET_ASSETS_SUCCEEDED, FETCHING_WIDGET_ASSETS_FAILED,
  UPDATE_FILTERS_IN_WIDGET_ASSETS, UPDATE_SEARCH_QUERY_IN_WIDGET_ASSETS, 

  INSERT_WIDGETS_AT_INDEX, MOVE_WIDGET_TO_INDEX, REMOVE_WIDGET_FROM_PARENT, SHOW_WIDGET_PLACEHOLDER, INVALIDATE_WIDGET_PLACEHOLDER,
  DELETE_WIDGET, SELECT_WIDGET, DESELECT_WIDGET,
  UPDATE_WIDGET_NAME, UPDATE_WIDGET_PROPERTIES,

  RESIZING_WIDGET, INVALIDATE_RESIZING_WIDGET,

  FETCH_PALETTE_GROUPS_REQUEST, FETCHING_PALETTE_GROUPS_SUCCEEDED, FETCHING_PALETTE_GROUPS_FAILED,

  MERGE_WIDGET_EDITORS,  ADD_PLACEHOLDER_ASSETS_FOR_WIDGET, ADD_PLACEHOLDER_ASSETS_FOR_WIDGET_SUCCEEDED, ADD_PLACEHOLDER_ASSETS_FOR_WIDGET_FAILED,
  FETCH_PARDOT_FORM, FETCHING_PARDOT_FORM_SUCCEEDED, FETCHING_PARDOT_FORM_FAILED,

  UPDATE_ALIAS_FOR_SECTION, UPDATE_ALIAS_FOR_SECTION_SUCCEEDED, UPDATE_ALIAS_FOR_SECTION_FAILED
} from 'constants/actionTypes.jsx';

import _ from 'lodash';

import { normalise, moveWidget, deleteWidget } from 'services/widget.helper';
import { removeWidgetFromParent } from 'services/widget.helper';

const initialState = {
  editors: {},
  widget: {
    content: {
      loading: false,
      saving: false,
      item: null,
    }
  },
  palette: {
    loading: false,
    groups: []
  }
}

const initialAssetState = {
  loading: false,
  items: [],
  error: null,
  pagination: {
    currentPage: 0,
    totalPages: 0,
    recordsPerPage: 8,
    totalRecordsCount: 0
  }
}

const editorContextState = {
  selection: null,
  updating: false,
  placeholder: null,
  resizing: null,
  widgetsById: {}
}

export function widgetsEditorReducer(state = initialState, action) {
  switch (action.type) {
    case CREATE_EDITOR_CONTEXT:
      var { context, widgets } = action.payload;

      var editorContext = _.get(state, `editors.${context}`, editorContextState);

      if (!editorContext) {
        return state;
      }
      var normalised = normalise(widgets, {});

      return {
        ...state,
        editors: {
          ...state.editors,
          [context]: {
            ...editorContextState,
            ...normalised,
            widgetAssetsById: {}
          }
        }
      }

    case UPDATE_EDITOR_CONTEXT:
      var { context, widgets } = action.payload;

      var editorContext = _.get(state, `editors.${context}`, editorContextState);

      if (!editorContext) {
        return state;
      }

      var normalised = normalise(widgets, {});

      return {
        ...state,
        editors: {
          ...state.editors,
          [context]: {
            ...editorContext,
            ...normalised,
          }
        }
      }

    case CREATING_WIDGET_SUCCEEDED:
    case CLONING_WIDGET_SUCCEEDED:

      console.log("Create widget");
      var context = action.payload.context;
      var widgets = action.payload.widgets;

      var editor = state.editors[context];

      if (!editor) {
        return state;
      }

      var widgetsById = editor.widgetsById;

      if (!widgetsById) {
        return state;
      }

      console.log(`Before ${Object.keys(widgetsById).length}`);
      normalised = normalise(widgets, widgetsById);
      widgetsById = { ...normalised.widgetsById };
      console.log(`AFter ${Object.keys(widgetsById).length}`);

      return {
        ...state,
        editors: {
          ...state.editors,
          [context]: {
            ...state.editors[context],
            widgetsById: widgetsById
          }
        }
      }

    case CREATING_WIDGET_FAILED:
      var temp = action.payload;
      return state;

    case INSERT_WIDGETS_AT_INDEX:
      var widgets = action.payload.widgets;
      var index = action.payload.index;
      var parent = action.payload.parent;
      var context = action.payload.context;

      var editor = state.editors[context];

      if (!editor) {
        return state;
      }

      var widgetsById = editor.widgetsById;

      if (!widgetsById) {
        return state;
      }

      normalised = normalise(widgets, widgetsById);
      widgetsById = { ...normalised.widgetsById };

      var parentWidgets = editor.widgets || [];

      if (parent != null) {
        var parentWidget = widgetsById[parent];
        parentWidget = {
          ...parentWidget,
          widgets: [...(parentWidget.widgets || []), ...normalised.widgets]
        }

        widgetsById[parent] = parentWidget;
      } else {
        parentWidgets = [...parentWidgets, ...normalised.widgets];
      }

      return {
        ...state,
        editors: {
          ...state.editors,
          [context]: {
            ...state.editors[context],
            widgets: parentWidgets,
            widgetsById: widgetsById
          }
        }
      }

    case SHOW_WIDGET_PLACEHOLDER:
      var context = action.payload.context;
      var parent = action.payload.parent;
      var index = action.payload.index;
      var data = action.payload.data;

      return {
        ...state,
        editors: {
          ...state.editors,
          [context]: {
            ...state.editors[context],
            placeholder: {
              parent: parent,
              index: index,
              data: data
            }
          }
        }
      }

    case INVALIDATE_WIDGET_PLACEHOLDER:
      var context = action.payload.context;
      return {
        ...state,
        editors: {
          ...state.editors,
          [context]: {
            ...state.editors[context],
            placeholder: null
          }
        }
      }

    case MOVE_WIDGET_TO_INDEX:

      var widget = action.payload.widget;
      var toIndex = action.payload.index;
      var parent = action.payload.parent;
      var editorContext = action.payload.context;
      var insertBefore = action.payload.insertBefore;

      var editor = state.editors[editorContext];

      if (!editor) {
        return state;
      }

      var widgetsById = editor.widgetsById;

      if (!widgetsById) {
        return state;
      }

      var context = {
        widgets: [..._.get(editor, 'widgets', [])],
        widgetsById: { ...editor.widgetsById }
      };

      var widgetItem = widgetsById[widget];
      context = moveWidget(context, widgetItem, parent, toIndex, insertBefore);

      return {
        ...state,
        editors: {
          ...state.editors,
          [editorContext]: {
            ...state.editors[editorContext],
            widgets: context.widgets,
            widgetsById: context.widgetsById
          }
        }
      }

    case REMOVE_WIDGET_FROM_PARENT:
      var editorContext = action.payload.context;
      var widget = action.payload.widget;
      var parent = action.payload.parent;

      var editor = state.editors[editorContext];

      if (!editor) {
        return state;
      }

      var widgetsById = editor.widgetsById;

      if (!widgetsById) {
        return state;
      }

      var context = {
        widgets: [..._.get(editor, 'widgets', [])],
        widgetsById: { ...editor.widgetsById }
      };

      var widgetItem = widgetsById[widget];

      context = removeWidgetFromParent(context, parent, widgetItem);

      return {
        ...state,
        editors: {
          ...state.editors,
          [editorContext]: {
            ...state.editors[editorContext],
            widgets: context.widgets,
            widgetsById: context.widgetsById
          }
        }
      }

    case RESIZING_WIDGET:
      var context = action.payload.context;
      var widget = action.payload.widget;
      var dimension = action.payload.dimension;

      return {
        ...state,
        editors: {
          ...state.editors,
          [context]: {
            ...state.editors[context],
            resizing: {
              id: widget,
              dimension: dimension
            }
          }
        }
      }

    case INVALIDATE_RESIZING_WIDGET:
      var context = action.payload.context;

      return {
        ...state,
        editors: {
          ...state.editors,
          [context]: {
            ...state.editors[context],
            resizing: null
          }
        }
      }

    case DELETE_WIDGET:
      var id = action.payload.id;
      var editorContext = action.payload.context;

      var editor = state.editors[editorContext];

      if (!editor) {
        return state;
      }

      var widgetsById = editor.widgetsById;

      if (!widgetsById) {
        return state;
      }

      widgetsById = deleteWidget(id, widgetsById);

      var widgets = (editor.widgets || []).filter(w => w != id);

      return {
        ...state,
        editors: {
          ...state.editors,
          [editorContext]: {
            ...state.editors[editorContext],
            widgets: widgets,
            widgetsById: widgetsById
          }
        }
      }

    case SELECT_WIDGET:
      var { context, widget } = action.payload;

      if (!context) {
        return state;
      }

      var editorContext = _.get(state, `editors.${context}`, editorContextState);

      return {
        ...state,
        editors: {
          ...state.editors,
          [context]: {
            ...editorContext,
            selection: widget
          }
        }
      }

    case DESELECT_WIDGET:
      var { context } = action.payload;

      if (!context) {
        return state;
      }

      var editorContext = _.get(state, `editors.${context}`, editorContextState);

      return {
        ...state,
        editors: {
          ...state.editors,
          [context]: {
            ...editorContext,
            selection: null
          }
        }
      }

    case UPDATE_WIDGET_NAME:
      var { id, name, context } = action.payload;

      var editorContext = _.get(state, `editors.${context}`, editorContextState);

      if (!editorContext) {
        return state;
      }

      var widgetsById = editorContext.widgetsById;
      var widget = widgetsById[id];
      if (!widget) {
        return state;
      }

      var updatedWidgetsById = {};
      Object.keys(widgetsById).forEach(key => {
        var item = widgetsById[key];
        if (item.id === id) {
          item = {
            ...item,
            name: name
          };
        }

        updatedWidgetsById[key] = item;
      })

      return {
        ...state,
        editors: {
          ...state.editors,
          [context]: {
            ...editorContext,
            widgetsById: updatedWidgetsById
          }
        }
      }
      
    case UPDATE_WIDGET_PROPERTIES:
      var { id, change, context } = action.payload;

      var editorContext = _.get(state, `editors.${context}`, editorContextState);

      if (!editorContext) {
        return state;
      }

      var widgetsById = editorContext.widgetsById;
      var widget = widgetsById[id];
      if (!widget) {
        return state;
      }

      var updatedWidgetsById = {};
      Object.keys(widgetsById).forEach(key => {
        var item = widgetsById[key];
        if (item.id === id) {
          item = {
            ...item,
            properties: {
              ...item.properties,
              ...change
            }
          };
        }

        updatedWidgetsById[key] = item;
      })

      return {
        ...state,
        editors: {
          ...state.editors,
          [context]: {
            ...editorContext,
            widgetsById: updatedWidgetsById
          }
        }
      }

    case FETCH_WIDGET_CONTENT_REQUEST:
      var { id, context } = action.payload;
      var editorContext = _.get(state, `editors.${context}`, editorContextState);

      if (!editorContext) {
        return state;
      }

      var widgetsById = editorContext.widgetsById;
      var widget = widgetsById[id];
      if (!widget) {
        return state;
      }

      var updatedWidgetsById = {};
      Object.keys(widgetsById).forEach(key => {
        var item = widgetsById[key];
        if (item.id === id) {
          item = {
            ...item,
            content: {
              ...item.content,
              fetching: true,
              fetched: false
            }
          };
        }

        updatedWidgetsById[key] = item;
      })

      return {
        ...state,
        editors: {
          ...state.editors,
          [context]: {
            ...editorContext,
            widgetsById: updatedWidgetsById
          }
        }
      }

    case FETCHING_WIDGET_CONTENT_SUCCEEDED:
      var { id, response, context } = action.payload;
      var editorContext = _.get(state, `editors.${context}`, editorContextState);

      if (!editorContext) {
        return state;
      }

      var widgetsById = editorContext.widgetsById;
      var widget = widgetsById[id];
      if (!widget) {
        return state;
      }

      var updatedWidgetsById = {};
      Object.keys(widgetsById).forEach(key => {
        var item = widgetsById[key];
        if (item.id === id) {
          item = {
            ...item,
            content: {
              ...item.content,
              fetching: false,
              fetched: true,
              ...response
            }
          };
        }

        updatedWidgetsById[key] = item;
      })

      return {
        ...state,
        editors: {
          ...state.editors,
          [context]: {
            ...editorContext,
            widgetsById: updatedWidgetsById
          }
        }
      }

    case FETCHING_WIDGET_CONTENT_FAILED:
      var { id, error, context } = action.payload;
      var editorContext = _.get(state, `editors.${context}`, editorContextState);

      if (!editorContext) {
        return state;
      }

      var widgetsById = editorContext.widgetsById;
      var widget = widgetsById[id];
      if (!widget) {
        return state;
      }

      var updatedWidgetsById = {};
      Object.keys(widgetsById).forEach(key => {
        var item = widgetsById[key];
        if (item.id === id) {
          item = {
            ...item,
            content: {
              ...item.content,
              fetching: false,
              fetched: false,
              ...error
            }
          };
        }

        updatedWidgetsById[key] = item;
      })

      return {
        ...state,
        editors: {
          ...state.editors,
          [context]: {
            ...editorContext,
            widgetsById: updatedWidgetsById
          }
        }
      }

    case FETCH_WIDGET_ASSETS_REQUEST:
      var { id, context, page } = action.payload;

      var editorContext = _.get(state, `editors.${context}`, editorContextState);

      if (!editorContext) {
        return state;
      }

      var widgetsById = editorContext.widgetsById;
      var widget = widgetsById[id];
      if (!widget) {
        return state;
      }

      var assetState = editorContext.widgetAssetsById[id] || initialAssetState;
      assetState = {
        ...assetState,
        loading: true
      }

      if(page === 0) {
        assetState = {
          ...assetState,
          items: []
        }
      }

      return {
        ...state,
        editors: {
          ...state.editors,
          [context]: {
            ...editorContext,
            widgetAssetsById: {
              ...editorContext.widgetAssetsById,
              [id]: assetState
            }
          }
        }
      }

    case FETCHING_WIDGET_ASSETS_SUCCEEDED:
      var { id, response, context } = action.payload;

      var editorContext = _.get(state, `editors.${context}`, editorContextState);

      if (!editorContext) {
        return state;
      }

      var widgetsById = editorContext.widgetsById;
      var widget = widgetsById[id];
      if (!widget) {
        return state;
      }

      var assetState = editorContext.widgetAssetsById[id] || initialAssetState;

      var { records, ...pagination } = response;

      var items = [];
      if (pagination.currentPage == 0) {
        items = records;
      } else {
        items = _.unionBy(assetState.items, records, 'id');
      }

      assetState = {
        ...assetState,
        loading: false,
        items: items,
        pagination: pagination
      }

      return {
        ...state,
        editors: {
          ...state.editors,
          [context]: {
            ...editorContext,
            widgetAssetsById: {
              ...editorContext.widgetAssetsById,
              [id]: assetState
            }
          }
        }
      }

    case FETCHING_WIDGET_ASSETS_FAILED:
      var { id, error, context } = action.payload;

      var editorContext = _.get(state, `editors.${context}`, editorContextState);

      if (!editorContext) {
        return state;
      }

      var widgetsById = editorContext.widgetsById;
      var widget = widgetsById[id];
      if (!widget) {
        return state;
      }

      var assetState = editorContext.widgetAssetsById[id] || initialAssetState;
      assetState = {
        ...assetState,
        loading: false,
        error: action.payload.error
      }

      return {
        ...state,
        editors: {
          ...state.editors,
          [context]: {
            ...editorContext,
            widgetAssetsById: {
              ...editorContext.widgetAssetsById,
              [id]: assetState
            }
          }
        }
      }

    case UPDATE_SEARCH_QUERY_IN_WIDGET_ASSETS:
      var { id, editor, searchQuery } = action.payload;

      var editorContext = _.get(state, `editors.${editor}`, editorContextState);

      if (!editorContext) {
        return state;
      }

      var widgetsById = editorContext.widgetsById;

      var widget = widgetsById[id];

      if (!widget) {
        return state;
      }

      return {
        ...state,
        editors: {
          ...state.editors,
          [editor]: {
            ...editorContext,
            widgetsById: {
              ...widgetsById,
              [id]: {
                ...widget,
                searchQuery: searchQuery
              }
            }
          }
        }
      }

    case UPDATE_FILTERS_IN_WIDGET_ASSETS:
      var { id, editor, filters } = action.payload;

      var editorContext = _.get(state, `editors.${editor}`, editorContextState);

      if (!editorContext) {
        return state;
      }

      var widgetsById = editorContext.widgetsById;

      var widget = widgetsById[id];

      if (!widget) {
        return state;
      }

      var updatedFilters = _.get(widget, 'selectedFilters') || [];

      updatedFilters = updatedFilters.filter(item => {
        return item.property.fieldId != filters.property.fieldId
      });

      if (filters.value) {
        updatedFilters = [...updatedFilters, filters]
      }

      return {
        ...state,
        editors: {
          ...state.editors,
          [editor]: {
            ...editorContext,
            widgetsById: {
              ...widgetsById,
              [id]: {
                ...widget,
                selectedFilters: updatedFilters
              }
            }
          }
        }
      }


    case ADD_PLACEHOLDER_ASSETS_FOR_WIDGET:
      var { editor, widget, request, callback} = action.payload;

      var editorContext = _.get(state, `editors.${editor}`, editorContextState);

      if (!editorContext) {
        return state;
      }

      var widgetsById = editorContext.widgetsById;
      var widgetObj = widgetsById[widget];
      if (!widgetObj) {
        return state;
      }

      var assetState = editorContext.widgetAssetsById[widget] || initialAssetState;
      assetState = {
        ...assetState,
        loading: true
      }

      return {
        ...state,
        editors: {
          ...state.editors,
          [editor]: {
            ...editorContext,
            widgetAssetsById: {
              ...editorContext.widgetAssetsById,
              [widget]: assetState
            }
          }
        }
      }

    case ADD_PLACEHOLDER_ASSETS_FOR_WIDGET_SUCCEEDED:
      var { editor, widget, response} = action.payload;

      var editorContext = _.get(state, `editors.${editor}`, editorContextState);

      if (!editorContext) {
        return state;
      }

      var widgetsById = editorContext.widgetsById;
      var widgetObj = widgetsById[widget];
      if (!widgetObj) {
        return state;
      }

      var assetState = editorContext.widgetAssetsById[widget] || initialAssetState;


      var records = response && response.assets.map(asset => {
        return {
          ...asset,
          // isIconSet: asset.isIconSet,
          // isNameSet: asset.isNameSet,
          // isSummarySet: asset.isSummarySet,
          // isMetadataSet: asset.isMetadataSet,
          icon:  asset.isIconSet ? asset.icon:null
        }
      });

      assetState = {
        ...assetState,
        loading: false,
        items: response && response.assets,
      }

      return {
        ...state,
        editors: {
          ...state.editors,
          [editor]: {
            ...editorContext,
            widgetAssetsById: {
              ...editorContext.widgetAssetsById,
              [widget]: assetState
            },
          }
        }
      }

      case ADD_PLACEHOLDER_ASSETS_FOR_WIDGET_FAILED:
        var { editor, widget, error } = action.payload;

        var editorContext = _.get(state, `editors.${editor}`, editorContextState);
  
        if (!editorContext) {
          return state;
        }
  
        var widgetsById = editorContext.widgetsById;
        var widgetObj = widgetsById[widget];
        if (!widgetObj) {
          return state;
        }
  
        var assetState = editorContext.widgetAssetsById[widget] || initialAssetState;
        assetState = {
          ...assetState,
          loading: false,
          error: action.payload.error
        }
  
        return {
          ...state,
          editors: {
            ...state.editors,
            [editor]: {
              ...editorContext,
              widgetAssetsById: {
                ...editorContext.widgetAssetsById,
                [widget]: assetState
              }
            }
          }
        }

    case UPDATE_WIDGET_CONTENT_REQUEST:
      var { id, context } = action.payload;

      var editorContext = _.get(state, `editors.${context}`, editorContextState);

      if (!editorContext) {
        return state;
      }

      var widgetsById = editorContext.widgetsById;
      var widget = widgetsById[id];
      if (!widget) {
        return state;
      }

      var updatedWidgetsById = {};
      Object.keys(widgetsById).forEach(key => {
        var item = widgetsById[key];
        if (item.id === id) {
          item = {
            ...item,
            updatingContent: true
          };
        }

        updatedWidgetsById[key] = item;
      })

      return {
        ...state,
        editors: {
          ...state.editors,
          [context]: {
            ...editorContext,
            widgetsById: updatedWidgetsById
          }
        }
      }

    case UPDATING_WIDGET_CONTENT_SUCCEEDED:
      var { id, content, context } = action.payload;
      console.log('reducer ' + content);

      var editorContext = _.get(state, `editors.${context}`, editorContextState);

      if (!editorContext) {
        return state;
      }

      var widgetsById = editorContext.widgetsById;
      var widget = widgetsById[id];
      if (!widget) {
        return state;
      }

      var updatedWidgetsById = {};
      Object.keys(widgetsById).forEach(key => {
        var item = widgetsById[key];
        if (item.id === id) {
          item = {
            ...item,
            updatingContent: false,
            content: content
          };
        }

        updatedWidgetsById[key] = item;
      })

      return {
        ...state,
        editors: {
          ...state.editors,
          [context]: {
            ...editorContext,
            widgetsById: updatedWidgetsById
          }
        }
      }

    case UPDATING_WIDGET_CONTENT_FAILED:
      var { id, error, context } = action.payload;

      var editorContext = _.get(state, `editors.${context}`, editorContextState);

      if (!editorContext) {
        return state;
      }

      var widgetsById = editorContext.widgetsById;
      var widget = widgetsById[id];
      if (!widget) {
        return state;
      }

      var updatedWidgetsById = {};
      Object.keys(widgetsById).forEach(key => {
        var item = widgetsById[key];
        if (item.id === id) {
          item = {
            ...item,
            updatingContent: false,
            error: error
          };
        }

        updatedWidgetsById[key] = item;
      })

      return {
        ...state,
        editors: {
          ...state.editors,
          [context]: {
            ...editorContext,
            widgetsById: updatedWidgetsById
          }
        }
      }

    case FETCH_PALETTE_GROUPS_REQUEST:
      return {
        ...state,
        palette: {
          ...state.palette,
          loading: true
        }
      }

    case FETCHING_PALETTE_GROUPS_SUCCEEDED:
      var additionalGroups = [
        {
          id: "live_elements",
          name: "Live Elements"
        },
        {
          id: "form_elements",
          name: "Forms"
        }
      ]
      var updatedGroups = [...action.payload.groups, ...additionalGroups];
      
      return {
        ...state,
        palette: {
          ...state.palette,
          loading: false,
          groups: updatedGroups
        }
      }

    case FETCHING_PALETTE_GROUPS_FAILED:
      return {
        ...state,
        palette: {
          ...state.palette,
          loading: false,
          error: action.payload
        }
      }

    case UPDATE_WIDGET_REQUEST:
      var { id, request, context } = action.payload;

      var editorContext = _.get(state, `editors.${context}`, editorContextState);
      if (!editorContext) {
        return state;
      }

      var widgetsById = editorContext.widgetsById;
      var widget = widgetsById[id];
      if (!widget) {
        return state;
      }

      var updatedWidgetsById = {};
      Object.keys(widgetsById).forEach(key => {
        var item = widgetsById[key];
        if (item.id === id) {
          item = {
            ...item,
            updating: true
          };
        }

        updatedWidgetsById[key] = item;
      })

      return {
        ...state,
        editors: {
          ...state.editors,
          [context]: {
            ...editorContext,
            widgetsById: updatedWidgetsById
          }
        }
      }

    case UPDATING_WIDGET_SUCCEEDED:
      var { response, context } = action.payload;
      var id = response.id;

      var editorContext = _.get(state, `editors.${context}`, editorContextState);
      if (!editorContext) {
        return state;
      }

      var widgetsById = editorContext.widgetsById;
      var widget = widgetsById[id];
      if (!widget) {
        return state;
      }

      var normalised = normalise([response], widgetsById);

      return {
        ...state,
        editors: {
          ...state.editors,
          [context]: {
            ...editorContext,
            widgetsById: normalised.widgetsById
          }
        }
      }

    case UPDATING_WIDGET_FAILED:
      var { id, error, context } = action.payload;

      var editorContext = _.get(state, `editors.${context}`, editorContextState);
      if (!editorContext) {
        return state;
      }

      var widgetsById = editorContext.widgetsById;
      var widget = widgetsById[id];
      if (!widget) {
        return state;
      }

      var updatedWidgetsById = {};
      Object.keys(widgetsById).forEach(key => {
        var item = widgetsById[key];
        if (item.id === id) {
          item = {
            ...item,
            updating: false,
            error: error
          };
        }

        updatedWidgetsById[key] = item;
      })

      return {
        ...state,
        editors: {
          ...state.editors,
          [context]: {
            ...editorContext,
            widgetsById: updatedWidgetsById
          }
        }
      }

    case MERGE_WIDGET_EDITORS:
      const { source, destination } = action.payload;

      var sourceContext = _.get(state, `editors.${source}`, editorContextState);
      var destiationContext = _.get(state, `editors.${destination}`, editorContextState);

      var sourceWidgetsById = sourceContext.widgetsById;
      var destinationWidgetsById = destiationContext.widgetsById;

      var widgetsById = { ...destinationWidgetsById, ...sourceWidgetsById };

      return {
        ...state,
        editors: {
          ...state.editors,
          [destination]: {
            ...destiationContext,
            widgetsById: widgetsById
          }
        }
      }

    case FETCH_PARDOT_FORM:
      var { id, context } = action.payload;
      var editorContext = _.get(state, `editors.${context}`, editorContextState);

      if (!editorContext) {
        return state;
      }

      var widgetsById = editorContext.widgetsById;
      var widget = widgetsById[id];
      if (!widget) {
        return state;
      }

      var updatedWidgetsById = {};
      Object.keys(widgetsById).forEach(key => {
        var item = widgetsById[key];
        if (item.id === id) {
          item = {
            ...item,
            content: {
              ...item.content,
              fetching: true,
              fetched: false
            }
          };
        }

        updatedWidgetsById[key] = item;
      })

      return {
        ...state,
        editors: {
          ...state.editors,
          [context]: {
            ...editorContext,
            widgetsById: updatedWidgetsById
          }
        }
      }

    case FETCHING_PARDOT_FORM_SUCCEEDED:
      var { id, response, context } = action.payload;
      var editorContext = _.get(state, `editors.${context}`, editorContextState);

      if (!editorContext) {
        return state;
      }

      var widgetsById = editorContext.widgetsById;
      var widget = widgetsById[id];
      if (!widget) {
        return state;
      }

      var updatedWidgetsById = {};
      Object.keys(widgetsById).forEach(key => {
        var item = widgetsById[key];
        if (item.id === id) {
          item = {
            ...item,
            content: {
              ...item.content,
              fetching: false,
              fetched: true,
              ...response
            }
          };
        }

        updatedWidgetsById[key] = item;
      })

      return {
        ...state,
        editors: {
          ...state.editors,
          [context]: {
            ...editorContext,
            widgetsById: updatedWidgetsById
          }
        }
      }

    case FETCHING_PARDOT_FORM_FAILED:
      var { id, error, context } = action.payload;
      var editorContext = _.get(state, `editors.${context}`, editorContextState);

      if (!editorContext) {
        return state;
      }

      var widgetsById = editorContext.widgetsById;
      var widget = widgetsById[id];
      if (!widget) {
        return state;
      }

      var updatedWidgetsById = {};
      Object.keys(widgetsById).forEach(key => {
        var item = widgetsById[key];
        if (item.id === id) {
          item = {
            ...item,
            content: {
              ...item.content,
              fetching: false,
              fetched: false,
              ...error
            }
          };
        }

        updatedWidgetsById[key] = item;
      })

      return {
        ...state,
        editors: {
          ...state.editors,
          [context]: {
            ...editorContext,
            widgetsById: updatedWidgetsById
          }
        }
      }
    
    case UPDATE_ALIAS_FOR_SECTION:
      var { widget, request, context } = action.payload;
      var editorContext = _.get(state, `editors.${context}`, editorContextState);
      if (!editorContext) {
        return state;
      }

      var widgetsById = editorContext.widgetsById;
      var selectedWidget = widgetsById[widget];
      if (!selectedWidget) {
        return state;
      }

      var updatedWidgetsById = {};
      Object.keys(widgetsById).forEach(key => {
        var item = widgetsById[key];
        if (item.id === widget) {
          item = {
            ...item,
            updating: true
          };
        }

        updatedWidgetsById[key] = item;
      })

      return {
        ...state,
        editors: {
          ...state.editors,
          [context]: {
            ...editorContext,
            widgetsById: updatedWidgetsById
          }
        }
      }
    
    case UPDATE_ALIAS_FOR_SECTION_SUCCEEDED:
      var { widget, context, response } = action.payload;
      var editorContext = _.get(state, `editors.${context}`, editorContextState);
      if (!editorContext) {
        return state;
      }

      var widgetsById = editorContext.widgetsById;
      var selectedWidget = widgetsById[widget];
      if (!selectedWidget) {
        return state;
      }

      var updatedWidgetsById = {};
      Object.keys(widgetsById).forEach(key => {
        var item = widgetsById[key];
        if (item.id === widget) {
          item = {
            ...item,
            updating: false,
            alias: response.alias,
            error: ''
          };
        }

        updatedWidgetsById[key] = item;
      })

      return {
        ...state,
        editors: {
          ...state.editors,
          [context]: {
            ...editorContext,
            widgetsById: updatedWidgetsById
          }
        }
      }

    case UPDATE_ALIAS_FOR_SECTION_FAILED:
      var { widget, context, error } = action.payload;
      var editorContext = _.get(state, `editors.${context}`, editorContextState);
      if (!editorContext) {
        return state;
      }

      var widgetsById = editorContext.widgetsById;
      var selectedWidget = widgetsById[widget];
      if (!selectedWidget) {
        return state;
      }

      var updatedWidgetsById = {};
      Object.keys(widgetsById).forEach(key => {
        var item = widgetsById[key];
        if (item.id === widget) {
          item = {
            ...item,
            updating: false,
            error: error
          };
        }

        updatedWidgetsById[key] = item;
      })

      return {
        ...state,
        editors: {
          ...state.editors,
          [context]: {
            ...editorContext,
            widgetsById: updatedWidgetsById
          }
        }
      }

    default:
      return state;
  }
}