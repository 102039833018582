import React from 'react';

import _ from 'lodash';

import { Row } from './layouts/row';
import { Column } from './layouts/column';
import { HeroBanner } from './texts/heroBanner';
import { Html } from './texts';
import { Text } from './texts/text';
import { Button } from './button';
import { Image } from './image';
import { AfterEffects } from './afterEffects';
import { AssetsGrid } from './assets';
import { Carousel, CarouselItem } from './carousel';

import { PasswordWidget } from './custom/password';
import AssetsQuickSettings from './assets/quickSettings';

import MenuProperties from './menu/properties';
import MenuQuickSettings from './menu/quickSettings';
import MenuView from './menu';

import StickyMenuProperties from './stickyMenu/properties';
import StickyMenuQuickSettings from './stickyMenu/quickSettings';
import StickyMenuView from './stickyMenu';

import { Form } from './form';
import { PardotForm } from './form/pardotForm.jsx';
import { HubspotForm } from './form/hubspotForm.jsx';
import { MarketoForm } from './form/marketoForm.jsx';
import { FormButton } from './form/button';
import { FormInput } from './form/input';
import { ExternalForm } from './form/externalForm.jsx';

import { CTACard } from './cta/card';

import { Section } from './section';

import { InteractiveContent } from './interactive';

import { ModalWidget } from './modal';

import { LiveElement } from './liveElements';
import { FormElement } from './formElements';

import { AssetViewer } from './assetViewer'; 
import { Container } from './container';

import { Seek } from './seek';

import { wrapWithRow } from 'services/widget.helper';
import { wrapWithColumn } from 'services/widget.helper';

import { 
  ROW, COLUMN, 
  TEXT, HERO_BANNER, IMAGE,
  BUTTON, CTA_CARD,
  ASSETS,
  MENU_WIDGET, HTML_WIDGET,
  CAROUSEL, CAROUSEL_ITEM, ASSET_VIEWER, CONTAINER_WIDGET, SECTION_WIDGET, LIVE_ELEMENT,
  FORM, FORM_INPUT, FORM_BUTTON, MODAL, PASSWORD_PROTECTION, STICKY_MENU_WIDGET, SEEK
} from 'constants/ItemTypes';

export class WidgetRegistry{
  static register(type, component){
    WidgetRegistry.widgets[type] = {
      wrapWithRow: (widget) => {
        if(!widget){
          return widget;
        }
    
        return wrapWithRow(widget, 16);
      },
      wrapWithColumn: (widget) => {
        if(!widget){
          return widget;
        }
        
        return wrapWithColumn(widget, 16);
      },
      ...component
    };    
  }

  static render(type, props, editable){
    var viewType = editable ? 'editor' : 'view';
    var config = WidgetRegistry.widgets[type];
    if(!config){
      console.error(`Widget config for type ${type} not found. Check if the widget type is registered`);
      return false;

    }
    var Component = config[viewType];
    if(!Component){
      return false;
    }
    const key = props.id;
    return React.createElement(Component, {...props, key, editable});
  }

  static properties(type, props, editable){
    var Component = WidgetRegistry.widgets[type].properties;

    if(!Component){
      return false;
    }
    const key = props.id;
    return React.createElement(Component, {...props, key, editable});
  }

  static quickSettings(type, props) {
    var Component = WidgetRegistry.widgets[type].quickSettings;

    if(!Component) {
      return false;
    }

    const key = props.id;
    return React.createElement(Component, {...props, key});
  }

  static quickActions(type, props) {
    var actions = WidgetRegistry.widgets[type].quickActions;

    if(!actions || actions.length == 0) {
      return false;
    }

    return actions.map((action, index) => {
      return React.createElement(action, {...props, index});
    });
  }

  static processMenuRequest = (menus) => {
    var menusRequest = menus.map(menu => {
      var children = (menu.children || []).map(child => {
        if (child.type === "PAGE") {
          return {
            ...child,
            icon: child.icon && !child.icon.type ? child.icon : null,
            value: _.get(child, 'value.id', null)
          }
        }

        return {
          ...child,
          icon: child.icon && !child.icon.type ? child.icon : null,
        };
      })

      if (menu.type === 'PAGE') {
        return {
          ...menu,
          value: _.get(menu, 'value.id', null),
          icon: menu.icon && !menu.icon.type ? menu.icon : null,
          children: children
        }
      }

      if (menu.type === 'ANCHOR') {
        return {
          ...menu,
          target:'_self',
        }
      }

      return {
        ...menu,
        icon: menu.icon && !menu.icon.type ? menu.icon : null,
        children: children
      }
    })

    return menusRequest;
  }

  static processMenuContentRequest(content) {
    var request = {
        leftMenus: this.processMenuRequest(content.leftMenus),
        rightMenus: this.processMenuRequest(content.rightMenus),
    }

    return request;
  }

  static processRequest(widgets){
    if(!widgets){
      return widgets;
    }

    return widgets.map(widget => {
      return this._processRequest(widget);
    });
  }

  static _processRequest(widget){
    if(widget == null){
      return null;
    }

    var updatedWidget = _.cloneDeep(widget);
    var properties = updatedWidget.properties || {};
    
    if(properties.background){
      var backgroundImage = _.get(properties, 'background.media.id');
      _.set(properties, 'background.media', backgroundImage);
    }

    var content = widget.content;
    if (widget.type === 'assets.default') {
      content = null;
    }

    if (widget.type === 'texts.menu' ) {
      content = this.processMenuContentRequest(widget.content);
    }

    return {...updatedWidget,
      properties: properties,
      content: content,
      widgets: this.processRequest(updatedWidget.widgets)
    };
  }
}

WidgetRegistry.widgets = {};

//Initial widgets
WidgetRegistry.register(ROW, {
  editor: Row,
  view: Row,
  properties: Row.Property,
  quickSettings: Row.QuickSettings
});

WidgetRegistry.register(COLUMN, {
  editor: Column,
  view: Column,
  properties: Column.Property,
  quickSettings: Column.QuickSettings
});

WidgetRegistry.register(HERO_BANNER, {
  editor: HeroBanner,
  view: HeroBanner,
  properties: HeroBanner.Property,
  quickSettings: HeroBanner.QuickSettings,
  quickActions: [HeroBanner.QuickAction]
});

WidgetRegistry.register(TEXT, {
  editor: Text,
  view: Text,
  quickSettings: Text.QuickSettings
});

WidgetRegistry.register(BUTTON, {
  editor: Button,
  view: Button,
  properties: Button.Property,
  quickSettings: Button.QuickSettings
});

WidgetRegistry.register(CTA_CARD, {
  editor: CTACard,
  view: CTACard,
  quickSettings: CTACard.QuickSettings,
  wrapWithRow: (widget) => {
    if(!widget){
      return widget;
    }

    return wrapWithRow(widget, 4);
  },
  wrapWithColumn: (widget) => {
    if(!widget){
      return widget;
    }
    
    return wrapWithColumn(widget, 4);
  }
});

WidgetRegistry.register(IMAGE, {
  editor: Image,
  view: Image,
  properties: Image.Property,
  quickSettings: Image.QuickSettings
});

WidgetRegistry.register('widgets.afterEffects', {
  editor: AfterEffects,
  view: AfterEffects,
  properties: AfterEffects.Property,
  quickSettings: AfterEffects.QuickSettings
});

WidgetRegistry.register(FORM, {
  editor: Form,
  view: Form,
  properties: Form.Property,
  quickSettings: Form.QuickSettings
});

WidgetRegistry.register('widgets.form.pardot', {
  editor: PardotForm,
  view: PardotForm,
  properties: PardotForm.Property,
  quickSettings: PardotForm.QuickSettings
});

WidgetRegistry.register('widgets.form.hubspot', {
  editor: HubspotForm,
  view: HubspotForm,
  properties: HubspotForm.Property,
  quickSettings: HubspotForm.QuickSettings
});

WidgetRegistry.register('widgets.form.marketo', {
  editor: MarketoForm,
  view: MarketoForm,
  properties: MarketoForm.Property,
  quickSettings: MarketoForm.QuickSettings
});

WidgetRegistry.register(FORM_INPUT, {
  editor: FormInput,
  view: FormInput,
  properties: FormInput.Property,
  quickSettings: FormInput.QuickSettings
});

WidgetRegistry.register(FORM_BUTTON, {
  editor: FormButton,
  view: FormButton,
  properties: FormButton.Property,
  quickSettings: FormButton.QuickSettings
});

WidgetRegistry.register('widgets.form.external', {
  editor: ExternalForm,
  view: ExternalForm,
  properties: ExternalForm.Property,
  quickSettings: ExternalForm.QuickSettings
});

WidgetRegistry.register(MODAL, {
  editor: ModalWidget,
  view: ModalWidget,
  properties: ModalWidget.Property,
  quickSettings: ModalWidget.QuickSettings
});

WidgetRegistry.register(ASSETS, {
  editor: AssetsGrid,
  view: AssetsGrid,
  quickSettings: AssetsQuickSettings,
  quickActions: [AssetsGrid.QuickAction]
});


WidgetRegistry.register(HTML_WIDGET, {
  editor: Html,
  view: Html,
  properties: Html.Property,
  quickSettings: Html.QuickSettings
});

WidgetRegistry.register(MENU_WIDGET, {
  editor: MenuView,
  view: MenuView,
  properties: MenuProperties,
  quickSettings: MenuQuickSettings
})

WidgetRegistry.register(STICKY_MENU_WIDGET, {
  editor: StickyMenuView,
  view: StickyMenuView,
  properties: StickyMenuProperties,
  quickSettings: StickyMenuQuickSettings
})


WidgetRegistry.register(SECTION_WIDGET, {
  editor: Section,
  view: Section,  
  quickSettings: Section.QuickSettings,
  quickActions: [Section.QuickAction]
})

WidgetRegistry.register(PASSWORD_PROTECTION, {
  editor: PasswordWidget,
  view: PasswordWidget,
  quickSettings: PasswordWidget.QuickSettings
})

WidgetRegistry.register(LIVE_ELEMENT, {
  editor: LiveElement,
  view: LiveElement,
  quickSettings: LiveElement.QuickSettings
})

WidgetRegistry.register('widgets.form_element', {
  editor: FormElement,
  view: FormElement,
  quickSettings: FormElement.QuickSettings
})

WidgetRegistry.register(CAROUSEL, {
  editor: Carousel,
  view: Carousel,
  quickSettings: Carousel.QuickSettings,
  wrapWithRow: (widget) => {
    return widget;
  }
})

WidgetRegistry.register(CAROUSEL_ITEM, {
  editor: CarouselItem,
  view: CarouselItem,
  quickSettings: CarouselItem.QuickSettings
})

//Interactive content
WidgetRegistry.register('widgets.interactive', {
  editor: InteractiveContent,
  view: InteractiveContent,
  quickSettings: InteractiveContent.QuickSettings,
  quickActions: [InteractiveContent.QuickAction],
  wrapWithRow: (widget) => {
    return widget;
  }
});

WidgetRegistry.register('widgets.interactive.item', {
  editor: InteractiveContent.Item,
  view: InteractiveContent.Item
});

WidgetRegistry.register('widgets.interactive.item.cover', {
  editor: InteractiveContent.Cover,
  view: InteractiveContent.Cover,
  quickSettings: InteractiveContent.Cover.QuickSettings,
});

WidgetRegistry.register('widgets.interactive.item.content', {
  editor: InteractiveContent.Content,
  view: InteractiveContent.Content,
  quickSettings: InteractiveContent.Content.QuickSettings
});

WidgetRegistry.register(CONTAINER_WIDGET, {
  editor: Container, 
  view: Container,
  quickSettings: Container.QuickSettings
})

WidgetRegistry.register(ASSET_VIEWER, {
  editor: AssetViewer,
  view: AssetViewer,
  quickSettings: AssetViewer.QuickSettings
})

WidgetRegistry.register(SEEK, {
  editor: Seek,
  view: Seek,
  quickSettings: Seek.QuickSettings
})

export function regiserWidget(id, component) {
  WidgetRegistry.register(id, component);
}