import React, { useState, useEffect, createRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import _ from 'lodash';
import cx from 'classnames';
import Truncate from 'react-truncate';

import {
  Input,
  Visibility,
  List,
  Segment,
  Icon,
  Header,
  Image,
  Placeholder
} from 'semantic-ui-react';

import styles from './addContent.module.scss';

import { AddContentContext } from './index';

import { fetchAssets } from 'actions/content';
import { addAssetToContentEditor } from 'actions/contentSelection';

import FileService from 'services/file.service.jsx';

export default function CleverstoryAssets(props) {
  const dispatch = useDispatch();

  const inputRef = createRef()

  const [query, setQuery] = useState('');

  const assetState = useSelector(state => state.content);
  const assets = _.get(assetState, 'items', [])
  const currentPage = _.get(assetState, 'pagination.current');
  const totalPages = _.get(assetState, 'pagination.totalPages');
  const loading = _.get(assetState, 'loading');
  const { editor } = React.useContext(AddContentContext)

  useEffect(() => {
    let filters = [];
    if (query) {
      filters = [{
        property: { type: "search" },
        operator: 'is',
        value: query
      }];
    }

    dispatch(fetchAssets({ filters: filters }, 0, 20));
  }, [query]);

  const loadMoreAssets = () => {
    let filters = [];
    if (query) {
      filters = [{
        property: { type: "search" },
        operator: 'is',
        value: query
      }];
    }

    if (loading || currentPage === totalPages) {
      return null;
    }

    dispatch(fetchAssets({ filters: filters }, currentPage + 1, 20));
  }

  const handleQueryChange = (e) => {
    setQuery(e.target.value);
  }

  const handleAddAsset = (asset) => {
    dispatch(addAssetToContentEditor(editor, asset))
  }

  var placeholder = loading && currentPage === 0;

  return (
    <Segment basic className={styles.cleverstoryAssets}>
      <Header as={'h3'}>Select Assets</Header>
      <Input iconPosition='left'
        placeholder='Search content here'
        ref={inputRef}>
        <Icon name='search' />
        <input value={query} onChange={handleQueryChange} />
      </Input>
      <Visibility className={styles.searchAssetsVisibility} 
        onBottomVisible={loadMoreAssets} continuous>
        <List className={styles.searchResults}>
          {placeholder && _.times(5).map((index) => {
            return <PlaceholderAssetRow key={index} />
          })}

          {!placeholder && assets.map(item => {
            return <AssetRow key={item.id} asset={item} onClick={handleAddAsset} />
          })}

          {(!placeholder && loading) &&
            <PlaceholderAssetRow key={'loading'} />
          }
        </List>
      </Visibility>
    </Segment>
  )
}

function AssetRow({ asset, onClick}) {
  const handleClick = () => {
    onClick(asset);
  }

  var icon = _.get(asset, 'icon.thumbnail', '/images/default_asset.jpg');

  return (
    <Header as='h4' className={styles.asset} onClick={handleClick}>
      <Image src={icon} />
      <Header.Content className={styles.name}>
        <Truncate lines={1}>{asset.name}</Truncate>
        <Header.Subheader>
          {`${FileService.fileType(asset.metadata)} | ${FileService.formatSize(asset.metadata.content_length)}`}
        </Header.Subheader>
      </Header.Content>
    </Header>
  )
}

function PlaceholderAssetRow(props) {
  return (
    <Placeholder className={styles.assetRowPlaceholder}>
      <Placeholder.Header image>
        <Placeholder.Line />
        <Placeholder.Line />
      </Placeholder.Header>
    </Placeholder>
  )
}