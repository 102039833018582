import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { createWidget, moveWidgetToIndex, updateWidgetName, deleteWidget, rearrangeWidgets, updateProperties, updateAliasForSection } from 'actions/widgetEditor';

import _ from 'lodash';

import { Modal, Segment, Grid, Label, Menu, Button, Icon, Form, Input, TextArea } from 'semantic-ui-react';
import Tooltip from 'components/Tooltip';
import { WidgetContext } from 'pages/pages/editor/editor.jsx';

import { Sortable } from '@shopify/draggable';

import styles from './interactive.module.scss';
import cx from 'classnames';

const interactiveSectionWidgetData = {
  "name": "Section",
  "type": "widgets.interactive.item",
  "properties": {},
  "capabilities": {
    "draggable": false,
    "droppable": false,
    "editable": true,
    "deletable": false
  },
  "widgets": [
    {
      "name": "Cover",
      "type": "widgets.interactive.item.cover",
      "properties": {},
      "capabilities": {
        "draggable": false,
        "droppable": true,
        "editable": true,
        "deletable": false
      },
      "widgets": []
    },
    {
      "name": "Content",
      "type": "widgets.interactive.item.content",
      "properties": {},
      "capabilities": {
        "draggable": false,
        "droppable": true,
        "editable": true,
        "deletable": false
      },
      "widgets": []
    }
  ]
};

export function ManageSectionModal({ trigger, editor, widget, ...rest }) {
  const dispatch = useDispatch();
  const { entityReference } = React.useContext(WidgetContext);
  const listReference = React.useRef(null);

  const [open, setOpen] = useState(false);
  const [dragging, setDragging] = useState(false);
  const [activeSection, setActiveSection] = useState(null);

  const sections = useSelector(state => {
    const editorState = _.get(state, `widgetsEditor.editors.${editor}`);
    if (!editorState) {
      return [];
    }

    return (widget.widgets || []).map(item => {
      return editorState.widgetsById[item];
    }).filter(item => item != null);
  });

  useEffect(() => {
    if (!open && !listReference.current) {
      return;
    }

    const sortable = new Sortable(listReference.current, {
      draggable: `.${styles.section}`,
      handle: `.${styles.handle}`,
      mirror: {
        appendTo: 'body',
        constrainDimensions: true,
      },
    });

    sortable.on('sortable:start', () => {

    });

    sortable.on('sortable:sort', (e) => {

    });

    sortable.on('sortable:stop', (e) => {
      const oldIndex = e.data.oldIndex;
      const newIndex = e.data.newIndex;

      if (oldIndex === newIndex) {
        return;
      }

      const item = sections[oldIndex];
      dispatch(rearrangeWidgets(editor, entityReference, item, widget.id, newIndex, oldIndex > newIndex));
    });

  }, [open]);

  const handleCreate = () => {
    const request = {
      widgets: [interactiveSectionWidgetData],
      reference: entityReference
    };

    dispatch(createWidget(editor, request, (response) => {
      dispatch(moveWidgetToIndex(editor, response[0].id, widget.id, sections.length, false));
    }));
  }

  const handleDelete = (section) => {
    dispatch(deleteWidget(section.id, editor));
  }

  const handleSelect = (section) => {
    setActiveSection(section);
  }

  return (
    <Modal trigger={trigger} size='large'
      className={styles.manageSections}
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
    >
      <Modal.Content>
        <Grid>
          <Grid.Row>
            <Grid.Column width='five' className={styles.left} stretched>
              <Menu secondary>
                <Menu.Item header as="h2">Sections <Label>{sections.length}</Label></Menu.Item>
                <Menu.Menu className={styles.sectionHeader} position='right'>
                  <Tooltip inverted content={'Create new Section'} position="top right">
                    <Menu.Item icon='add' onClick={handleCreate} disabled={false} />
                  </Tooltip>
                </Menu.Menu>
              </Menu>
              <div className={styles.scroll}>
                <div className={styles.sections} ref={listReference}>
                  {sections.map(section => {
                    return (
                      <SectionItem
                        key={section.id}
                        section={section}
                        editor={editor}
                        onDelete={handleDelete}
                        dragging={dragging}
                        onSelect={handleSelect}
                      />
                    )
                  })}
                </div>
              </div>
            </Grid.Column>
            <Grid.Column width='eleven'>
              {activeSection &&
                <SectionItemDetails
                  section={activeSection}
                  editor={editor}
                />
              }
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={() => { setOpen(false); }}>
          <Icon name='remove' /> Close
        </Button>
      </Modal.Actions>
    </Modal>
  );
}

function SectionItem({ section, editor, dragging, onDelete, onSelect }) {
  const dispatch = useDispatch();

  const handleChange = (e) => {
    dispatch(updateWidgetName(section.id, e.target.value, editor));
  }

  return (
    <div className={styles.section}>
      <Handle />
      <Label onClick={() => onSelect(section)} className={styles.name} disabled={dragging}>{section.name}</Label>
      <Button icon onClick={() => onDelete(section)}>
        <Icon name='trash alternate outline' />
      </Button>
    </div>
  );
}

function SectionItemDetails({ section, editor }) {

  const pageAlias = useSelector(state => {
    return _.get(state, `pageEditor.item.alias`);
  });

  const error = useSelector(state => {
    const editorState = _.get(state, `widgetsEditor.editors.${editor}`);
    if (!editorState) {
      return [];
    }

    return _.get(editorState.widgetsById[section.id], 'error.errors[0].message');
  });

  const dispatch = useDispatch();

  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [alias, setAlias] = useState('');

  useEffect(() => {
    if (section) {
      setName(section.name);
      setDescription(section.properties.sectionDescription != null ? section.properties.sectionDescription : '');
      setAlias(section.alias.split(pageAlias).pop());
    }
  }, [section]);

  const handleNameChange = (e, { value }) => {
    setName(value || null);
    dispatch(updateWidgetName(section.id, value, editor));
  }

  const handleDescriptionChange = (e, { value }) => {
    setDescription(value || null);
    dispatch(updateProperties({
      id: section.id,
      change: {
        sectionDescription: value
      },
      context: editor
    }));
  }

  return (
    <Segment className={styles.itemForm}>
      <Form className={styles.form}>
        <Form.Group inline className={styles.input}>
          <label>Name</label>
          <Form.Field
            id='form-input-control-name'
            control={Input}
            value={name}
            placeholder='Provide a valid name'
            onChange={handleNameChange}
          />
        </Form.Group>
        <Form.Group inline className={cx(styles.input, styles.description)}>
          <label>Description</label>
          <Form.Field
            id='form-input-control-description'
            control={TextArea}
            maxLength = "200"
            value={description}
            placeholder='Provide a valid description'
            onChange={handleDescriptionChange}
          />
        </Form.Group>
        <div className={styles.error}>{error ? error : ''}</div>
      </Form>
    </Segment>
  )
}

const Handle = React.forwardRef((props, ref) => {
  return (
    <div className={styles.handle} ref={ref}>
      <div className={styles.line}>
        {_.times(2).map((index) => {
          return <span key={index} />
        })}
      </div>
      <div className={styles.line}>
        {_.times(2).map((index) => {
          return <span key={index} />
        })}
      </div>
      <div className={styles.line}>
        {_.times(2).map((index) => {
          return <span key={index} />
        })}
      </div>
    </div>
  );
});