import {
  FETCHING_VISITOR_SESSIONS, FETCHING_VISITOR_SESSIONS_SUCCEEDED, FETCHING_VISITOR_SESSIONS_FAILED,
  FETCH_TOP_VISITORS, FETCHING_TOP_VISITORS_SUCCEEDED, FETCHING_TOP_VISITORS_FAILED,
  FETCH_VISITORS_BY_BROWSERS, FETCHING_VISITORS_BY_BROWSERS_SUCCEEDED, FETCHING_VISITORS_BY_BROWSERS_FAILED,
  FETCH_VISITORS_BY_DEVICES, FETCHING_VISITORS_BY_DEVICES_SUCCEEDED, FETCHING_VISITORS_BY_DEVICES_FAILED,
  FETCH_VISITORS_BY_COUNTRIES, FETCHING_VISITORS_BY_COUNTRIES_SUCCEEDED, FETCHING_VISITORS_BY_COUNTRIES_FAILED,

  FETCH_VISITORS_BY_REFERERS, FETCHING_VISITORS_BY_REFERERS_SUCCEEDED, FETCHING_VISITORS_BY_REFERERS_FAILED,
  FETCH_VISITORS_BY_UTM_CAMPAIGN, FETCHING_VISITORS_BY_UTM_CAMPAIGN_SUCCEEDED, FETCHING_VISITORS_BY_UTM_CAMPAIGN_FAILED,
  FETCH_VISITORS_BY_UTM_SOURCE, FETCHING_VISITORS_BY_UTM_SOURCE_SUCCEEDED, FETCHING_VISITORS_BY_UTM_SOURCE_FAILED,
  FETCH_VISITORS_BY_UTM_MEDIUM, FETCHING_VISITORS_BY_UTM_MEDIUM_SUCCEEDED, FETCHING_VISITORS_BY_UTM_MEDIUM_FAILED,
  FETCH_VISITORS_BY_PAGES, FETCHING_VISITORS_BY_PAGES_SUCCEEDED, FETCHING_VISITORS_BY_PAGES_FAILED,

  FETCH_SESSIONS_TIME_SERIES, FETCHING_SESSIONS_TIME_SERIES_SUCCEEDED, FETCHING_SESSIONS_TIME_SERIES_FAILED,

  FETCH_ACTIVE_SESSIONS, FETCHING_ACTIVE_SESSIONS_SUCCEEDED, FETCHING_ACTIVE_SESSIONS_FAILED,

  FETCHING_VISITOR_ANALYTICS_DAILY, FETCHING_VISITOR_ANALYTICS_DAILY_SUCCEEDED, FETCHING_VISITOR_ANALYTICS_DAILY_FAILED,
  FETCHING_VISITOR_SESSIONS_DAILY, FETCHING_VISITOR_SESSIONS_DAILY_SUCCEEDED, FETCHING_VISITOR_SESSIONS_DAILY_FAILED,

  FETCHING_VISITOR_ASSETS, FETCHING_VISITOR_ASSETS_SUCCEEDED, FETCHING_VISITOR_ASSETS_FAILED,
  FETCHING_VISITOR_ANALYTICS, FETCHING_VISITOR_ANALYTICS_SUCCEEDED, FETCHING_VISITOR_ANALYTICS_FAILED,
  FETCHING_VISITOR, FETCHING_VISITOR_SUCCEEDED, FETCHING_VISITOR_FAILED,
  FETCHING_SESSIONS_FOR_VISITOR, FETCHING_SESSIONS_FOR_VISITOR_SUCCEEDED, FETCHING_SESSIONS_FOR_VISITOR_FAILED,
  SELECT_VISITOR_SESSION,
  DOWNLOAD_VISITOR_REPORTS, DOWNLOAD_VISITOR_REPORTS_SUCCEEDED, DOWNLOAD_VISITOR_REPORTS_FAILED

} from 'constants/actionTypes.jsx';

import { createAction } from 'redux-actions';
import axios from 'axios';
import { call, put, takeLatest } from 'redux-saga/effects';
import buildUrl from 'build-url';

const apiUrl = process.env.REACT_APP_API_URL;
const API_BASE_URL = `${apiUrl}/api/2.1`;
const VISITOR_REPORTS_URL = `${API_BASE_URL}/resource_center/reports/visitors`;

/**
* Fetch Top visitors 
*/
export const fetchTopVisitors = createAction(FETCH_TOP_VISITORS, (page, request) => {
  return {
    page: page,
    request: request
  }
});

export const fetchingTopVisitorsSucceeded = createAction(FETCHING_TOP_VISITORS_SUCCEEDED, response => {
  return response;
});

export const fetchingTopVisitorsFailed = createAction(FETCHING_TOP_VISITORS_FAILED, error => {
  return error;
});

function* fetchTopVisitorsSaga(action) {
  const params = {
    page: action.payload.page,
    limit: 5
  }

  let url = buildUrl(`${VISITOR_REPORTS_URL}/top_visitors`, {
    queryParams: params
  });

  const fetchingTopVisitorsApi = () => {
    return axios.post(url, action.payload.request).then(response => response.data);
  }

  try {
    const response = yield call(fetchingTopVisitorsApi);
    yield put(fetchingTopVisitorsSucceeded(response));
  } catch (error) {
    yield put(fetchingTopVisitorsFailed(error));
  }
}


/**
* Fetch visitor sessions
*/
export const fetchVisitorSessions = createAction(FETCHING_VISITOR_SESSIONS, (page, request) => {
  return {
    page: page,
    request: request
  }
});

export const fetchingVisitorSessionsSucceeded = createAction(FETCHING_VISITOR_SESSIONS_SUCCEEDED, response => {
  return response;
});

export const fetchingVisitorSessionsFailed = createAction(FETCHING_VISITOR_SESSIONS_FAILED, error => {
  return error;
});

function* fetchVisitorSessionsSaga(action) {
  const params = {
    page: action.payload.page,
    limit: 5
  }

  let url = buildUrl(`${VISITOR_REPORTS_URL}`, {
    queryParams: params
  });

  const fetchingVisitorAnalyticsDaily = () => {
    return axios.post(url, action.payload.request).then(response => response.data);
  }

  try {
    const response = yield call(fetchingVisitorAnalyticsDaily);
    yield put(fetchingVisitorSessionsSucceeded(response));
  } catch (error) {
    yield put(fetchingVisitorSessionsFailed(error));
  }
}

/**
* Fetch visitor sessions by browsers
*/
export const fetchAggregatedBrowsersMetrics = createAction(FETCH_VISITORS_BY_BROWSERS, (page, request) => {
  return {
    page: page,
    request: request
  }
});

export const fetchingAggregatedBrowsersMetricsSuccedded = createAction(FETCHING_VISITORS_BY_BROWSERS_SUCCEEDED, response => {
  return response;
});

export const fetchingAggregatedBrowsersMetricsFailed = createAction(FETCHING_VISITORS_BY_BROWSERS_FAILED, error => {
  return error;
});

function* fetchAggregatedBrowsersMetricsSaga(action) {
  const params = {
    page: action.payload.page,
    limit: 5
  }

  let url = buildUrl(`${VISITOR_REPORTS_URL}/browsers`, {
    queryParams: params
  });


  const fetchAggregatedBrowsersMetricsAPI = () => {
    return axios.post(url, action.payload.request).then(response => response.data);
  }

  try {
    const response = yield call(fetchAggregatedBrowsersMetricsAPI);
    yield put(fetchingAggregatedBrowsersMetricsSuccedded(response));
  } catch (error) {
    yield put(fetchingAggregatedBrowsersMetricsFailed(error));
  }
}

/**
* Fetch visitor sessions by devices
*/
export const fetchAggregatedDevicesMetrics = createAction(FETCH_VISITORS_BY_DEVICES, (page, request) => {
  return {
    page: page,
    request: request
  }
});

export const fetchingAggregatedDevicesMetricsSuccedded = createAction(FETCHING_VISITORS_BY_DEVICES_SUCCEEDED, response => {
  return response;
});

export const fetchingAggregatedDevicesMetricsFailed = createAction(FETCHING_VISITORS_BY_DEVICES_FAILED, error => {
  return error;
});

function* fetchAggregatedDevicesMetricsSaga(action) {
  const params = {
    page: action.payload.page,
    limit: 5
  }

  let url = buildUrl(`${VISITOR_REPORTS_URL}/devices`, {
    queryParams: params
  });


  const fetchAggregatedDevicesMetricsAPI = () => {
    return axios.post(url,
      action.payload.request
    ).then(response => response.data);
  }

  try {
    const response = yield call(fetchAggregatedDevicesMetricsAPI);
    yield put(fetchingAggregatedDevicesMetricsSuccedded(response));
  } catch (error) {
    yield put(fetchingAggregatedDevicesMetricsFailed(error));
  }
}

/**
* Fetch visitor sessions by countries
*/
export const fetchAggregatedCountriesMetrics = createAction(FETCH_VISITORS_BY_COUNTRIES, (page, request) => {
  return {
    page: page,
    request: request
  }
});

export const fetchingAggregatedCountriesMetricsSuccedded = createAction(FETCHING_VISITORS_BY_COUNTRIES_SUCCEEDED, response => {
  return response;
});

export const fetchingAggregatedCountriesMetricsFailed = createAction(FETCHING_VISITORS_BY_COUNTRIES_FAILED, error => {
  return error;
});

function* fetchAggregatedCountriesMetricsSaga(action) {
  const params = {
    page: action.payload.page,
    limit: 5
  }

  let url = buildUrl(`${VISITOR_REPORTS_URL}/countries`, {
    queryParams: params
  });

  const fetchAggregatedCountriesMetricsAPI = () => {
    return axios.post(url,
      action.payload.request
    ).then(response => response.data);
  }

  try {
    const response = yield call(fetchAggregatedCountriesMetricsAPI);
    yield put(fetchingAggregatedCountriesMetricsSuccedded(response));
  } catch (error) {
    yield put(fetchingAggregatedCountriesMetricsFailed(error));
  }
}

/**
* Fetch visitor sessions by referer
*/
export const fetchAggregatedRefererMetrics = createAction(FETCH_VISITORS_BY_REFERERS, (page, request) => {
  return {
    page: page,
    request: request
  }
});

const fetchAggregatedRefererMetricsSucceeded = createAction(FETCHING_VISITORS_BY_REFERERS_SUCCEEDED, (response) => {
  return response;
})

const fetchAggregatedRefererMetricsFailed = createAction(FETCHING_VISITORS_BY_REFERERS_FAILED, (error) => {
  return error;
})

function* fetchAggregatedRefererMetricsSaga(action) {
  const params = {
    page: action.payload.page,
    limit: 5
  }

  let url = buildUrl(`${VISITOR_REPORTS_URL}/referers`, {
    queryParams: params
  });

  const fetchAggregatedRefererMetricsAPI = () => {
    return axios.post(url,
      action.payload.request
    ).then(response => response.data);
  }

  try {
    const response = yield call(fetchAggregatedRefererMetricsAPI);
    yield put(fetchAggregatedRefererMetricsSucceeded(response));
  } catch (error) {
    yield put(fetchAggregatedRefererMetricsFailed(error));
  }
}

/**
* Fetch visitor sessions by UTM Campaign
*/
export const fetchAggregatedUtmCampaignMetrics = createAction(FETCH_VISITORS_BY_UTM_CAMPAIGN, (page, request) => {
  return {
    page: page,
    request: request
  }
});

const fetchAggregatedUtmCampaignMetricsSucceeded = createAction(FETCHING_VISITORS_BY_UTM_CAMPAIGN_SUCCEEDED, response => {
  return response;
})

const fetchAggregatedUtmCampaignMetricsFailed = createAction(FETCHING_VISITORS_BY_UTM_CAMPAIGN_FAILED, error => {
  return error;
})

function* fetchAggregatedUtmCampaignMetricsSaga(action) {
  const params = {
    page: action.payload.page,
    limit: 5
  }

  let url = buildUrl(`${VISITOR_REPORTS_URL}/utm_campaigns`, {
    queryParams: params
  });

  const fetchAggregatedUtmCampaignMetricsAPI = () => {
    return axios.post(url,
      action.payload.request
    ).then(response => response.data);
  }

  try {
    const response = yield call(fetchAggregatedUtmCampaignMetricsAPI);
    yield put(fetchAggregatedUtmCampaignMetricsSucceeded(response));
  } catch (error) {
    yield put(fetchAggregatedUtmCampaignMetricsFailed(error));
  }
}

/**
* Fetch visitor sessions by UTM Source
*/
export const fetchAggregatedUtmSourceMetrics = createAction(FETCH_VISITORS_BY_UTM_SOURCE, (page, request) => {
  return {
    page: page,
    request: request
  }
});

const fetchAggregatedUtmSourceMetricsSucceeded = createAction(FETCHING_VISITORS_BY_UTM_SOURCE_SUCCEEDED, response => {
  return response;
})

const fetchAggregatedUtmSourceMetricsFailed = createAction(FETCHING_VISITORS_BY_UTM_SOURCE_FAILED, error => {
  return error;
})

function* fetchAggregatedUtmSourceMetricsSaga(action) {
  const params = {
    page: action.payload.page,
    limit: 5
  }

  let url = buildUrl(`${VISITOR_REPORTS_URL}/utm_sources`, {
    queryParams: params
  });

  const fetchAggregatedUtmSourceMetricsAPI = () => {
    return axios.post(url,
      action.payload.request
    ).then(response => response.data);
  }

  try {
    const response = yield call(fetchAggregatedUtmSourceMetricsAPI);
    yield put(fetchAggregatedUtmSourceMetricsSucceeded(response));
  } catch (error) {
    yield put(fetchAggregatedUtmSourceMetricsFailed(error));
  }
}

/**
* Fetch visitor sessions by UTM Medium
*/
export const fetchAggregatedUtmMediumMetrics = createAction(FETCH_VISITORS_BY_UTM_MEDIUM, (page, request) => {
  return {
    page: page,
    request: request
  }
});

const fetchAggregatedUtmMediumMetricsSucceeded = createAction(FETCHING_VISITORS_BY_UTM_MEDIUM_SUCCEEDED, response => {
  return response;
})

const fetchAggregatedUtmMediumMetricsFailed = createAction(FETCHING_VISITORS_BY_UTM_MEDIUM_FAILED, error => {
  return error;
})

function* fetchAggregatedUtmMediumMetricsSaga(action) {
  const params = {
    page: action.payload.page,
    limit: 5
  }

  let url = buildUrl(`${VISITOR_REPORTS_URL}/utm_mediums`, {
    queryParams: params
  });

  const fetchAggregatedUtmMediumMetricsAPI = () => {
    return axios.post(url,
      action.payload.request
    ).then(response => response.data);
  }

  try {
    const response = yield call(fetchAggregatedUtmMediumMetricsAPI);
    yield put(fetchAggregatedUtmMediumMetricsSucceeded(response));
  } catch (error) {
    yield put(fetchAggregatedUtmMediumMetricsFailed(error));
  }
}


/**
* Fetch visitor sessions by page
*/
export const fetchAggregatedPagesMetrics = createAction(FETCH_VISITORS_BY_PAGES, (page, request) => {
  return {
    page: page,
    request: request
  }
});

const fetchAggregatedPagesMetricsSucceeded = createAction(FETCHING_VISITORS_BY_PAGES_SUCCEEDED, response => {
  return response;
})

const fetchAggregatedPagesMetricsFailed = createAction(FETCHING_VISITORS_BY_PAGES_FAILED, error => {
  return error;
})

function* fetchAggregatedPagesMetricsSaga(action) {
  const params = {
    page: action.payload.page,
    limit: 5
  }

  let url = buildUrl(`${VISITOR_REPORTS_URL}/pages`, {
    queryParams: params
  });

  const fetchAggregatedPagesMetricsAPI = () => {
    return axios.post(url,
      action.payload.request
    ).then(response => response.data);
  }

  try {
    const response = yield call(fetchAggregatedPagesMetricsAPI);
    yield put(fetchAggregatedPagesMetricsSucceeded(response));
  } catch (error) {
    yield put(fetchAggregatedPagesMetricsFailed(error));
  }
}


/**
* Fetch visitor sessions time series
*/
export const fetchSessionsTimeSeries = createAction(FETCH_SESSIONS_TIME_SERIES, (page, request) => {
  return {
    page: page,
    request: request
  }
});

export const fetchingSessionsTimeSeriesSuccedded = createAction(FETCHING_SESSIONS_TIME_SERIES_SUCCEEDED, response => {
  return response;
});

export const fetchingSessionsTimeSeriesFailed = createAction(FETCHING_SESSIONS_TIME_SERIES_FAILED, error => {
  return error;
});

function* fetchSessionsTimeSeriesSaga(action) {

  const fetchSessionsTimeSeriesAPI = () => {
    return axios.post(`${VISITOR_REPORTS_URL}/sessions/series`,
      action.payload.request
    ).then(response => response.data);
  }

  try {
    const response = yield call(fetchSessionsTimeSeriesAPI);
    yield put(fetchingSessionsTimeSeriesSuccedded(response));
  } catch (error) {
    yield put(fetchingSessionsTimeSeriesFailed(error));
  }
}

/**
* Fetch active visitor sessions
*/
export const fetchActiveSessions = createAction(FETCH_ACTIVE_SESSIONS, (request) => {
  return {
    request: request
  }
});

const fetchActiveSessionsSucceeded = createAction(FETCHING_ACTIVE_SESSIONS_SUCCEEDED, (response) => {
  return response;
})

const fetchActiveSessionsFailed = createAction(FETCHING_ACTIVE_SESSIONS_FAILED, (error) => {
  return error;
})

function* fetchActiveSessionsSaga(action) {

  const fetchActiveSessionsAPI = () => {
    return axios.post(`${VISITOR_REPORTS_URL}/sessions/active`, action.payload.request)
      .then(response => response.data);
  }

  try {
    const response = yield call(fetchActiveSessionsAPI);
    yield put(fetchActiveSessionsSucceeded(response));
  } catch (error) {
    yield put(fetchActiveSessionsFailed(error));
  }
}

/**
* Fetch visitor analytics daily
*/
export const fetchVisitorAnalyticsDaily = createAction(FETCHING_VISITOR_ANALYTICS_DAILY, (fromDate, toDate) => {
  return { fromDate, toDate }
});

export const fetchingVisitorAnalyticsDailySuccedded = createAction(FETCHING_VISITOR_ANALYTICS_DAILY_SUCCEEDED, response => {
  return response;
});

export const fetchingVisitorAnalyticsDailyFailed = createAction(FETCHING_VISITOR_ANALYTICS_DAILY_FAILED, error => {
  return error;
});

function* fetchVisitorAnalyticsDailySaga(action) {
  const data = {
    from: action.payload.fromDate,
    to: action.payload.toDate
  }

  const fetchingVisitorAnalyticsDaily = () => {
    return axios.get(`${VISITOR_REPORTS_URL}/daily`,
      { params: data }
    ).then(response => response.data);
  }

  try {
    const response = yield call(fetchingVisitorAnalyticsDaily);
    yield put(fetchingVisitorAnalyticsDailySuccedded(response));
  } catch (error) {
    yield put(fetchingVisitorAnalyticsDailyFailed(error));
  }
}

/**
* Fetch visitor sessions daily
*/
export const fetchVisitorSessionsDaily = createAction(FETCHING_VISITOR_SESSIONS_DAILY, (fromDate, toDate) => {
  return { fromDate, toDate }
});

export const fetchVisitorSessionsDailySuccedded = createAction(FETCHING_VISITOR_SESSIONS_DAILY_SUCCEEDED, response => {
  return response;
});

export const fetchVisitorSessionsDailyFailed = createAction(FETCHING_VISITOR_SESSIONS_DAILY_FAILED, error => {
  return error;
});

function* fetchVisitorSessionsDailySaga(action) {
  const data = {
    from: action.payload.fromDate,
    to: action.payload.toDate
  }

  const fetchVisitorSessionsDailyAPI = () => {
    return axios.get(`${VISITOR_REPORTS_URL}/daily`,
      { params: data }
    ).then(response => response.data);
  }

  try {
    const response = yield call(fetchVisitorSessionsDailyAPI);
    yield put(fetchVisitorSessionsDailySuccedded(response));
  } catch (error) {
    yield put(fetchVisitorSessionsDailyFailed(error));
  }
}

/**
* Fetch visitor assets viewed
*/

export const fetchAssetsViewedByVisitors = createAction(FETCHING_VISITOR_ASSETS, (page) => {
  return { page }
});

export const fetchingVisitorAssetsSucceeded = createAction(FETCHING_VISITOR_ASSETS_SUCCEEDED, response => {
  return response;
});

export const fetchingVisitorAssetsFailed = createAction(FETCHING_VISITOR_ASSETS_FAILED, error => {
  return error;
});

function* fetchVisitorAssetsSaga(action) {
  const data = {
    page: action.payload.page,
    limit: 5
  }

  const fetchingVisitorAssets = () => {
    return axios.get(`${VISITOR_REPORTS_URL}/assets-viewed`,
      { params: data }
    ).then(response => response.data);
  }

  try {
    const response = yield call(fetchingVisitorAssets);
    yield put(fetchingVisitorAssetsSucceeded(response));
  } catch (error) {
    yield put(fetchingVisitorAssetsFailed(error));
  }
}

/**
* Fetch visitor for id
*/
export const fetchVisitor = createAction(FETCHING_VISITOR, (id) => {
  return { id }
});

export const fetchingVisitorSucceeded = createAction(FETCHING_VISITOR_SUCCEEDED, response => {
  return response;
});

export const fetchingVisitorFailed = createAction(FETCHING_VISITOR_FAILED, error => {
  return error;
});

function* fetchVisitorSaga(action) {
  const id = action.payload.id;

  const fetchingVisitor = () => {
    return axios.get(`${VISITOR_REPORTS_URL}/${id}`,
    ).then(response => response.data);
  }

  try {
    const response = yield call(fetchingVisitor);
    yield put(fetchingVisitorSucceeded(response));
  } catch (error) {
    yield put(fetchingVisitorFailed(error));
  }
}

export const fetchVisitorAnalytics = createAction(FETCHING_VISITOR_ANALYTICS, (request) => {
  return { request };
});

export const fetchingVisitorAnalyticsSucceeded = createAction(FETCHING_VISITOR_ANALYTICS_SUCCEEDED, response => {
  return response;
});

export const fetchingVisitorAnalyticsFailed = createAction(FETCHING_VISITOR_ANALYTICS_FAILED, error => {
  return error;
});

function* fetchingVisitorAnalyticsSaga(action) {
  const { request } = action.payload;


  const fetchingVisitorAnalytics = () => {
    return axios.post(`${VISITOR_REPORTS_URL}/analytics`,
      request
    ).then(response => response.data);
  }

  try {
    const response = yield call(fetchingVisitorAnalytics);
    yield put(fetchingVisitorAnalyticsSucceeded(response));
  } catch (error) {
    yield put(fetchingVisitorAnalyticsFailed(error));
  }
}

/**
* Fetch sesssions for visitor
*/

export const fetchSessionsForVisitor = createAction(FETCHING_SESSIONS_FOR_VISITOR, (id, request, page) => {
  return { id, request, page }
});

export const fetchingSessionsForVisitorSucceeded = createAction(FETCHING_SESSIONS_FOR_VISITOR_SUCCEEDED, response => {
  return response;
});

export const fetchingSessionsForVisitorFailed = createAction(FETCHING_SESSIONS_FOR_VISITOR_FAILED, error => {
  return error;
});

function* fetchSessionsForVisitorSaga(action) {

  const { id, request, page } = action.payload;
  const data = {
    page: page,
    limit: 20
  }

  let url = buildUrl(`${VISITOR_REPORTS_URL}/${id}/sessions`, {
    queryParams: data
  });

  const fetchingVisitorSessions = () => {
    return axios.post(url, request).then(response => response.data);
  }

  try {
    const response = yield call(fetchingVisitorSessions);
    yield put(fetchingSessionsForVisitorSucceeded(response));
  } catch (error) {
    yield put(fetchingSessionsForVisitorFailed(error));
  }
}

/**
* Download Visitor reports
*/
export const downloadVisitorReports = createAction(DOWNLOAD_VISITOR_REPORTS, (type, visitorReducer, request, callback) => {
  return {
    type: type,
    visitorReducer: visitorReducer,
    request: request,
    callback: callback
  }
})

export const downloadVisitorReportsSucceeded = createAction(DOWNLOAD_VISITOR_REPORTS_SUCCEEDED, (type, visitorReducer, response) => {
  return {
    type: type,
    visitorReducer: visitorReducer,
    response: response
  }
})

export const downloadVisitorReportsFailed = createAction(DOWNLOAD_VISITOR_REPORTS_FAILED, (type, visitorReducer, error) => {
  return {
    type: type,
    visitorReducer: visitorReducer,
    error: error
  }
})

function* downloadVisitorReportsSaga(action) {
  const { type, visitorReducer, request, callback } = action.payload;

  const data = {
    startDate: request.startDate,
    endDate: request.endDate,
    filters: request.filters
  }
  let url = buildUrl(VISITOR_REPORTS_URL, {
    path: `/download/${type}`
  });


  const downloadVisitorReportsAPI = () => {
    return axios({
      method: 'POST',
      url: url,
      data: data
    }).then(response => response.data);
  }

  try {
    const response = yield call(downloadVisitorReportsAPI);
    yield put(downloadVisitorReportsSucceeded(type, visitorReducer, response));
    if(callback) {
      callback();
    }
  } catch (error) {
    yield put(downloadVisitorReportsFailed(type, visitorReducer, error));
  }
}


export const selectVisitorSession = createAction(SELECT_VISITOR_SESSION);


export default function* visitorsSaga() {
  yield takeLatest(FETCHING_VISITOR_ANALYTICS_DAILY, fetchVisitorAnalyticsDailySaga);
  yield takeLatest(FETCHING_VISITOR_SESSIONS_DAILY, fetchVisitorSessionsDailySaga);
  yield takeLatest(FETCHING_VISITOR_SESSIONS, fetchVisitorSessionsSaga);
  yield takeLatest(FETCHING_VISITOR_ASSETS, fetchVisitorAssetsSaga);
  yield takeLatest(FETCHING_VISITOR_ANALYTICS, fetchingVisitorAnalyticsSaga)
  yield takeLatest(FETCHING_VISITOR, fetchVisitorSaga);
  yield takeLatest(FETCHING_SESSIONS_FOR_VISITOR, fetchSessionsForVisitorSaga);

  yield takeLatest(FETCH_TOP_VISITORS, fetchTopVisitorsSaga);
  yield takeLatest(FETCH_VISITORS_BY_BROWSERS, fetchAggregatedBrowsersMetricsSaga);
  yield takeLatest(FETCH_VISITORS_BY_DEVICES, fetchAggregatedDevicesMetricsSaga);
  yield takeLatest(FETCH_VISITORS_BY_COUNTRIES, fetchAggregatedCountriesMetricsSaga);
  yield takeLatest(FETCH_SESSIONS_TIME_SERIES, fetchSessionsTimeSeriesSaga);

  yield takeLatest(FETCH_VISITORS_BY_REFERERS, fetchAggregatedRefererMetricsSaga);
  yield takeLatest(FETCH_VISITORS_BY_UTM_CAMPAIGN, fetchAggregatedUtmCampaignMetricsSaga);
  yield takeLatest(FETCH_VISITORS_BY_UTM_SOURCE, fetchAggregatedUtmSourceMetricsSaga);
  yield takeLatest(FETCH_VISITORS_BY_UTM_MEDIUM, fetchAggregatedUtmMediumMetricsSaga);
  yield takeLatest(FETCH_VISITORS_BY_PAGES, fetchAggregatedPagesMetricsSaga);

  yield takeLatest(FETCH_ACTIVE_SESSIONS, fetchActiveSessionsSaga);
  yield takeLatest(DOWNLOAD_VISITOR_REPORTS, downloadVisitorReportsSaga);
};