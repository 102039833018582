import React, { useState, useEffect } from 'react';
import {useDispatch, useSelector} from 'react-redux';
import { fetchAllClusters, fetchCluster, invalidateCluster } from 'actions/clusters';

import { 
  fetchAssets, selectAsset, deselectAsset, selectAllAssets, deselectAllAssets,
  deleteAssets, updateFiltersInContent, fetchContentSuggestions, fetchContentAnalytics
} from 'actions/content';

import _ from 'lodash';
import FileService from 'services/file.service.jsx';
import { normalise } from 'services/clusters.service';
import dateformat from 'dateformat';
import cx from 'classnames';
import Truncate from 'react-truncate';
import { guid, padNumber, getBaseUrl } from 'services/util';

import {
  Link,
  Route,
  Switch,
  Redirect,
  useParams, 
  useHistory,
  useRouteMatch
} from "react-router-dom";

import {
  Button,
  Checkbox,
  Dimmer,
  Header,
  Image,
  Menu,
  Segment,
  Icon,
  Table,
  Visibility,
  Statistic,
  Placeholder,
  Search
} from 'semantic-ui-react';

import SidePane from 'components/SidePane';
import { Clusters } from 'components/Clusters';
import { Filters }  from 'components/Clusters/filters';
import AddAssetContainer from 'components/Asset/AddAsset';
import BulkEditAssets from 'pages/asset/bulkEdit';
import InlineConfirmationDialog from 'components/InlineConfirmationDialog';
import ReadMoreTextView from 'components/ReadMoreTextView';

import styles from './content.module.scss';

export default function ContentPage(props){
  let { path, url } = useRouteMatch();
  let baseUrl = getBaseUrl(url);

  return (
    <div className={styles.content}>
      <Switch>
        <Route path={`${path}`} exact>
          <Redirect to={`${baseUrl}/content/all`} />
        </Route>
        <Route path={`${path}/:cluster`} exact>
          <ClustersBlock />
          <FilteredContent />
        </Route>
      </Switch>
    </div>
  );
}

function ClusterHeader({count}){
  const dispatch = useDispatch();

  const filters = useSelector(state => _.get(state, 'content.filters', []));

  const handleChange = (filter) => {
    var updatedFilters = [...filters, filter];

    dispatch(updateFiltersInContent(updatedFilters));
  }

  return (
    <>
      <Menu secondary icon className={styles.contentMenu}>
        <Menu.Item header>
          <Header as='h2' className={styles.heading}>
            Content
          </Header>
        </Menu.Item>
        <Menu.Item className={cx(styles.searchItem, "searchMenuItem")}>
          <Clusters.Search onChange={handleChange} />
        </Menu.Item>
        <Menu.Item header className={styles.rightItem}>
          <Header as='h2' className={styles.heading}>
            Content
          </Header>
        </Menu.Item>
      </Menu>
    </>
  );
}

function ClustersBlock(props){
  const dispatch = useDispatch();
  const history = useHistory();
  const { cluster } = useParams();
  let { url } = useRouteMatch();
  let baseUrl = getBaseUrl(url);

  const [filter, setFilter] = useState(null);

  useEffect(() => {
    dispatch(fetchAllClusters());
  }, []);

  const handleClusterClick = (cluster) => {
    const id = cluster ? cluster.id : 'all';
    history.push(`${baseUrl}/content/${id}`);
  }

  return (
    <Segment basic className={styles.clusters}>
      <ClusterHeader />
      <Clusters active={cluster} onClusterClick={handleClusterClick}/>
    </Segment>
  );
}

function FilteredContent(props){
  const dispatch = useDispatch();
  const {cluster} = useParams();

  const [sort, setSort] = useState({ sortBy: 'modifiedDate', orderBy: 'DESC' });

  const clusterItem = useSelector(state => _.get(state, 'clusters.cluster.item', null));
  const clusterLoading = useSelector(state => _.get(state, 'clusters.cluster.loading', false));

  const filters = useSelector(state => _.get(state, 'content.filters', []));
  const assetState = useSelector(state => state.content);
  const currentPage = _.get(assetState, 'pagination.current');
  const totalPages = _.get(assetState, 'pagination.totalPages');
  const loading = _.get(assetState, 'loading');

  useEffect(() => {
    if(cluster === 'all'){
      dispatch(invalidateCluster());
      dispatch(updateFiltersInContent([]));
    }else{
      dispatch(fetchCluster(cluster, (item) => {
        const clusterFilters = _.get(item, 'filters', []);
        dispatch(updateFiltersInContent(clusterFilters));
      }));
    }
    
  }, [cluster])

  const loadMoreAssets = () => {
    if (loading || currentPage === totalPages) {
      return null;
    }

    dispatch(fetchAssets({
      ...sort,
      filters: normalise(filters)
    }, currentPage + 1, 20));
  };

  const handleFiltersChange = (filters) => {
    dispatch(updateFiltersInContent(filters));
  }

  return (
    <>
      <Dimmer.Dimmable as={Segment} basic className={styles.filters} dimmed={clusterLoading}>
        <Filters 
          cluster={clusterItem}
          value={filters} 
          editable={true}
          onChange={handleFiltersChange}
        />
        <Dimmer inverted active={clusterLoading}>
          <Header as='h5'>
            Loading cluster...
          </Header>
        </Dimmer>
      </Dimmer.Dimmable>
      <Visibility onBottomVisible={loadMoreAssets} continuous>
        <AssetTable placeholder={loading && currentPage === 0} sort={sort} onSortChange={setSort}/>
      </Visibility>
    </>
  );
}


function AssetTable(props){
  const dispatch = useDispatch();
  const cluster = useSelector(state => _.get(state, 'clusters.cluster.item'));
  const assets = useSelector(state => _.get(state, 'content.items', []));
  const totalRecords = useSelector(state => _.get(state, 'content.pagination.totalRecords', 0))
  const deleting = useSelector(state => _.get(state, 'content.deleting', false));
  const loading = useSelector(state => _.get(state, 'content.loading', false));

  const filters = useSelector(state => _.get(state, 'content.filters', []));

  const selectedAssets = useSelector(state => _.get(state, 'content.selection.assets', []));
  const isAllAssetsSelected = useSelector(state => _.get(state, 'content.selection.selectAll', false));

  const [bulkEdit, showBulkEdit] = useState(false);
  const [showAddAsset, setShowAddAsset] = useState(false);

  const handleAddAssetStateChanged = () => {
    setShowAddAsset(!showAddAsset);
  }

  useEffect(() => {
    dispatch(deselectAllAssets());
    dispatch(fetchAssets({ 
      ...props.sort, 
      filters: normalise(filters)
    }, 0, 20));
  }, [filters, props.sort]);

  useEffect(() => {
    if(!_.isEmpty(assets)) {
      let assetIds = assets.filter(item => {
        return item.loadAnalytics;
      }).map(item => {
        return item.id;
      })

      if (!_.isEmpty(assetIds)) {
        dispatch(fetchContentAnalytics(assetIds));
      }
    }
  }, [assets])

  const toggleSelectAll = () => {
    if (isAllAssetsSelected) {
      dispatch(deselectAllAssets());
    } else {
      dispatch(selectAllAssets());
    }
  }

  const handleEditAssets = ()  => {
    showBulkEdit(true);
  }

  const handleDeleteAssets = ()  => {
    var assetFilters = filters;
    if(!isAllAssetsSelected){
      assetFilters = [{
        property: {
          'type': 'asset'
        },
        operator: 'is',
        value: selectedAssets
      }]
    }

    assetFilters = normalise(assetFilters);
    dispatch(deleteAssets({filters: assetFilters}, () => {
      dispatch(deselectAllAssets());
      dispatch(fetchAssets({
        ...props.sort, 
        filters: filters
      }, 0, 20));
    }));
  }

  const handleSelectionChange = (asset, selected) => {
    if(selected) {
      dispatch(selectAsset([asset]));
    } else {
      if(isAllAssetsSelected){
        dispatch(selectAsset(assets));
      }

      dispatch(deselectAsset(asset));
    } 
  }

  const handleBulkEdit = (request) => {
    dispatch(deselectAllAssets());
    dispatch(fetchAssets({
      ...props.sort,
      filters: filters
    }, 0, 20));
    showBulkEdit(false);
  }

  const handleSortClicked = (sortBy) => {
    let orderBy = 'ASC';

    if(sortBy === props.sort.sortBy) {
      orderBy = props.sort.orderBy === 'ASC' ? 'DESC' : 'ASC';
    } 

    if(sortBy !== props.sort.sortBy) {
      if(sortBy === 'name') {
        orderBy = 'ASC';
      } else {
        orderBy = 'DESC';
      }
    }

    props.onSortChange({ sortBy: sortBy, orderBy: orderBy});
  }

  const getSorted = (sortBy) => {
    var sorted = sortBy === props.sort.sortBy ? props.sort.orderBy : null;
    
    if(!sorted) {
      return null;
    }

    return sorted === 'ASC' ? 'ascending' : 'descending';
  }

  return (
    <>
      <Menu secondary icon className={styles.assetMenu}>
        <Menu.Item header>
          <Header as='h4' className={styles.heading}>
            {`${cluster && cluster.name ? cluster.name : 'All Content'} `}<sup>{`(${totalRecords})`}</sup>
          </Header>
        </Menu.Item>

        {(isAllAssetsSelected || selectedAssets.length > 0) &&
          <>
            <Menu.Item header as='h5' className={styles.selection}>{`Selected ${isAllAssetsSelected ? "all" : selectedAssets.length + " of"} ${totalRecords} assets`}</Menu.Item>
            <Menu.Item className={styles.action}>
              <Button size='mini' onClick={handleEditAssets}>
                <Icon name={'edit'} />{' '}EDIT
              </Button>
            </Menu.Item>
            <Menu.Item header as='h5' className={styles.seperator}>
              or
            </Menu.Item>
            <Menu.Item className={styles.action}>
              <InlineConfirmationDialog
                  message='Are you sure you would like to delete selected assets?'
                  onConfirm={handleDeleteAssets}
                >
                  <Button size='mini' disabled={deleting}>
                    <Icon loading={deleting} name={deleting ? 'spinner' : 'delete'} />{deleting ? ' DELETING...' : 'DELETE'}
                  </Button>
                </InlineConfirmationDialog>
            </Menu.Item>
          </>
        }

        <Menu.Menu position='right'>
          <Menu.Item>
            <Button className={styles.addContentButton} onClick={() => { setShowAddAsset(true) }}>
              <Icon name='add' /> Add Content
            </Button>
          </Menu.Item>
        </Menu.Menu>
      </Menu>
      <Table sortable selectable={!loading} className={styles.assetTable}>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell collapsing 
              className={
                cx(styles.checkbox, {
                   [styles.selected]: isAllAssetsSelected || selectedAssets.length > 0 
                })
              } 
              textAlign="center"
              onClick={toggleSelectAll}
            >
              <Checkbox onChange={toggleSelectAll}
                checked={isAllAssetsSelected}
                indeterminate={!isAllAssetsSelected && selectedAssets.length > 0} />
            </Table.HeaderCell>
            <Table.HeaderCell width={6} sorted={getSorted('name')} onClick={handleSortClicked.bind(this, 'name')}>Name</Table.HeaderCell>
            <Table.HeaderCell textAlign="center">Details</Table.HeaderCell>
            <Table.HeaderCell textAlign="center" sorted={getSorted('createdDate')} onClick={handleSortClicked.bind(this, 'createdDate')}>Created on</Table.HeaderCell>
            <Table.HeaderCell textAlign="center" sorted={getSorted('modifiedDate')} onClick={handleSortClicked.bind(this, 'modifiedDate')}>Last Updated</Table.HeaderCell>
            <Table.HeaderCell textAlign="center">Analytics</Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {props.placeholder && _.times(10).map((index) => {
              return <PlaceholderAssetRow key={index} />
          })}

          {!props.placeholder && assets.map(asset => {
            var selected = selectedAssets.some(item => {
              return item === asset.id;
            })

            return (
            <AssetRow
              key={asset.id}
              asset={asset}
              selected={isAllAssetsSelected || selected}
              showCheckBox={isAllAssetsSelected || selectedAssets.length > 0} 
              onSelectionChange={handleSelectionChange}
            />
            )
          })}

          {(!props.placeholder && loading) && 
            <PlaceholderAssetRow key={'loading'} />
          }
        </Table.Body>
      </Table>

      <SidePane right 
        header='Edit Assets'
        close
        background="#ffffff"
        open={bulkEdit} 
        onClose={() => {
          showBulkEdit(false);
        }}
        closeOnDocumentClick={true}>
          <BulkEditAssets filters={() => {
            var assetFilters = filters;
            if(!isAllAssetsSelected){
              assetFilters = [{
                property: {
                  'type': 'asset'
                },
                operator: 'is',
                value: selectedAssets
              }]
            }

            return normalise(assetFilters);
          }}
          onUpdate={handleBulkEdit}
        />
      </SidePane>

      <AddAssetContainer expanded={showAddAsset} onVisibilityChange={handleAddAssetStateChanged} />
    </>
  );
}

function AssetRow(props){
  let { url, path } = useRouteMatch();
  let baseUrl = getBaseUrl(url);

  var icon = _.get(props.asset, 'icon.thumbnail', '/images/default_asset.jpg');
  
  const toggleSelect = () => {
    props.onSelectionChange(props.asset, !props.selected);
  }

  const timeSpentValue = (timeSpent) => {
    let timeSpentValue = `${padNumber(Math.round(timeSpent), 1)}s`;

    if (timeSpent >= 60) {
      timeSpentValue = `${padNumber(Math.round(timeSpent / 60), 1)}m`;
    }

    return timeSpentValue;
  }

  return (
    <Table.Row className={cx(styles.asset, { [styles.assetSelected]: props.selected })} onClick={toggleSelect}>
      <Table.Cell collapsing className={cx(styles.checkbox, { [styles.selected]: props.showCheckBox })} textAlign="center">
        <Checkbox checked={props.selected} onChange={toggleSelect}/>
      </Table.Cell>
      <Table.Cell width={6}>
        <Header as='h4' className={styles.assetDetail}>
          <Image src={icon} />
          <Header.Content className={styles.assetName}>
            <Link to={`${baseUrl}/assets/${props.asset.id}`}><Truncate lines={1}>{props.asset.name}</Truncate></Link>
            <Header.Subheader>
              {(props.asset.tags || []).map((tag, index) => <Tag key={index} name={tag} />)}
            </Header.Subheader>
          </Header.Content>
        </Header>
      </Table.Cell>
      <Table.Cell textAlign="center">{`${FileService.fileType(props.asset.metadata)} ${props.asset.metadata.content_type !== 'application/url' ? `| ${FileService.formatSize(props.asset.metadata.content_length)}` : ``}`}</Table.Cell>
      <Table.Cell textAlign="center">{dateformat(props.asset.createdDate, 'dd mmm yyyy')}</Table.Cell>
      <Table.Cell textAlign="center">{dateformat(props.asset.modifiedDate, 'dd mmm yyyy')}</Table.Cell>
      <Table.Cell textAlign="center">
        <Statistic.Group className={styles.assetAnalytics}>
          <Statistic.Value>
            <i aria-hidden="true" className="icon-view icon"></i>{_.get(props.asset, 'analytics.views', 0)}
          </Statistic.Value>
          <Statistic.Value>
            <i aria-hidden="true" className="icon-download icon"></i>{_.get(props.asset, 'analytics.downloads', 0)}
          </Statistic.Value>
          <Statistic.Value>
            <i aria-hidden="true" className="icon-timespent icon"></i>{timeSpentValue(_.get(props.asset, 'analytics.avgTimeSpent', 0))}
          </Statistic.Value>
        </Statistic.Group>
      </Table.Cell>
    </Table.Row>
  );
}

function Tags({tags, ...rest}) {
  return (
    <ReadMoreTextView lines={3}>
      {(tags || []).map((tag, index) => <Tag key={index} name={tag} />)}
    </ReadMoreTextView>
  )
}

function Tag({name, ...rest}){
  return (
    <span className={styles.tag}>#{name}</span>
  );
}

function PlaceholderAssetRow(props){
  return (
    <Table.Row className={cx(styles.asset, styles.placeholder)}>
      <Table.Cell collapsing className={cx(styles.checkbox)} textAlign="center">
      </Table.Cell>
      <Table.Cell width={6}>
        <Placeholder>
        <Placeholder.Header image>
          <Placeholder.Line />
          <Placeholder.Line />
        </Placeholder.Header>
        </Placeholder>
      </Table.Cell>
      <Table.Cell textAlign="center">
        <Placeholder className={styles.column}>
          <Placeholder.Line/>
        </Placeholder>
      </Table.Cell>
      <Table.Cell textAlign="center">
        <Placeholder className={styles.column}>
          <Placeholder.Line/>
        </Placeholder>
      </Table.Cell>
      <Table.Cell textAlign="center">
        <Placeholder className={styles.column}>
          <Placeholder.Line/>
        </Placeholder>
      </Table.Cell>
      <Table.Cell textAlign="center">
        <Placeholder className={styles.column}>
          <Placeholder.Line/>
        </Placeholder>
      </Table.Cell>
    </Table.Row>
  );
}