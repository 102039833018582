import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { updateProperties, updateWidget } from 'actions/widgetEditor';

import _ from 'lodash';
import cx from 'classnames';

import { HTML5Backend } from 'react-dnd-html5-backend'
import { DndProvider, useDrag, useDrop } from "react-dnd";

import {
  Modal,
  Button,
  Icon
} from 'semantic-ui-react'

import { SearchInputBar } from './searchInput';
import { Widget, getStyles, getDimensionStyles } from '../widget.jsx';
import { QuickSettings, QuickSettingsProperty } from 'components/QuickSettings';
import { WidgetRegistry } from '../registry.jsx';

import styles from './seek.module.scss';

import { useWidgetDrop } from "services/dnd.service";

export const Seek = React.memo((props) => {
  const ref = useRef(null);

  var widgetStyles = {
    ...getStyles(props.properties),
    ...getColorStyles(props.properties)
  };

  const dropRef = useWidgetDrop(props, ref, {

  });
  
  return (
    <div className={cx(styles.seek, props.className)}
      id={`widget_${props.id}`}
      ref={props.dragRef(dropRef(ref))}
      onMouseOver={props.onMouseOver}
      onMouseOut={props.onMouseOut}
      onClick={props.onClick}
      style={widgetStyles}
    >
      <SearchInputBar disabled />
      {props.children}
    </div>
  )
});

const SeekQuickSettings = (props) => {
  const dispatch = useDispatch();

  const [selected, setSelected] = useState('suggestions');
  const menuItems = [
    { id: 'suggestions', title: 'Suggestions', icon: 'icon-custom' },
    { id: 'spacing', title: 'Spacing', icon: 'icon-spacing' },
    { id: 'color', title: 'Color', icon: 'icon-background' },
    { id: 'border', title: 'Border', icon: 'icon-border' },
    { id: 'advanced', title: 'Advanced', icon: 'icon-custom' }
  ]

  var editorContext = useSelector(state => state.widgetsEditor.editors[props.editor]);
  var widget = editorContext.widgetsById[props.id];
  var properties = widget.properties;

  const handleMenuClick = (item) => {
    setSelected(item);
  }

  const handleChange = (changeRequest) => {
    dispatch(updateProperties({
      id: widget.id,
      change: changeRequest,
      context: props.editor
    }));
  }

  const handleCssChange = (css) => {
    var changeRequest = { ...widget };
    _.set(changeRequest, 'properties.css', css);

    changeRequest = WidgetRegistry.processRequest([changeRequest])[0];
    dispatch(updateWidget(props.id, changeRequest, props.editor));
  }

  return (
    <QuickSettings menuItems={menuItems} selected={selected} onMenuClick={handleMenuClick} >
      {selected === 'suggestions' && 
        <Suggestions suggestions={properties.suggestions || []} 
          widget={widget} onChange={handleChange} editor={props.editor} />
      }
      {selected === 'spacing' &&
        <>
          <QuickSettingsProperty.Spacing
            title='Margin'
            value={properties.margin}
            onChange={(margin) => {
              handleChange({
                margin: margin
              });
            }}
          />
          <QuickSettingsProperty.Spacing
            title='Padding'
            value={properties.padding}
            onChange={(padding) => {
              handleChange({
                padding: padding
              });
            }}
          />
        </>
      }

      {selected === 'color' &&
        <>
          <QuickSettingsProperty.Color
            title='Background Color'
            value={_.get(properties, 'backgroundColor')}
            onChange={(value) => {
              handleChange({
                backgroundColor: value
              })
            }}
          />
          <QuickSettingsProperty.Color
            title='Text Color'
            value={_.get(properties, 'textColor')}
            onChange={(value) => {
              handleChange({
                textColor: value
              })
            }}
          />
        </>
      }

      {selected === 'border' &&
        <QuickSettingsProperty.Border expanded value={properties.border} onChange={(border) => {
          handleChange({
            border: border
          });
        }}
        />
      }

      {selected === 'advanced' &&
        <QuickSettingsProperty.CSS title='Stylesheets' value={properties.css} onChange={handleCssChange} />
      }
    </QuickSettings>
  )
}

Seek.QuickSettings = SeekQuickSettings;

function Suggestions(props) {
  return (
    <>
      <QuickSettingsProperty title='Suggestions'>
        <SuggesionsModal trigger={
          <Button>Edit</Button>
        } widget={props.widget} 
        suggestions={props.suggestions} 
        onChange={props.onChange}
        editor={props.editor} />
      </QuickSettingsProperty>
    </>
  )
}

function SuggesionsModal({ trigger, widget, editor, suggestions, onChange, ...rest }) {

  const [open, setOpen] = useState(false);
  const [items, setItems] = useState(suggestions);

  useEffect(() => {
    setItems(suggestions);
  }, [])

  const handleChange = (value, index) => {
    var updatedItems = items.map((item, i) => {
      if (index === i) {
        return value;
      }

      return item;
    })

    setItems(updatedItems);
  }

  const handleAddClicked = () => {
    if (items.length >= 6) {
      return;
    }

    setItems([...items, '']);
  }

  const handleDeleteClicked = (index) => {
    var deletedItems = [...items];
    deletedItems.splice(index, 1)
    setItems(deletedItems);
  }

  const handleCancel = () => {
    setOpen(false);
  }

  const handleSave = () => {
    onChange({
      suggestions: items
    })
    handleCancel()
  }

  return (
    <Modal trigger={trigger}
      className={styles.suggestionsModal}
      open={open}

      onOpen={() => {
        setOpen(true);
      }}
    >
      <Modal.Content>
        <div className={styles.suggestionsContainer}>
          <div className={styles.header}>
            <h1 className={styles.title}>Suggestions</h1>
            <span className={styles.addButton}  onClick={handleAddClicked}>+ Add</span>
          </div>
          <DndProvider backend={HTML5Backend}>
            <div className={styles.suggestions}>
              {
                items.map((item, index) => {
                  return (
                    <div key={index} className={styles.suggestion}>
                      <input value={item} 
                        placeholder='Please enter suggestion'
                        onChange={(e) => { handleChange(e.target.value, index)}}/>
                      <Button
                        className={styles.deleteButton}
                        icon="trash"
                        floated='right'
                        size='mini'
                        compact
                        onClick={handleDeleteClicked.bind(this, index)}
                      />
                    </div>
                  )
                })
              }
            </div>
          </DndProvider>
        </div>
      </Modal.Content>
      <Modal.Actions className={styles.buttonAction}>
        <Button onClick={handleCancel}>
          <Icon name='remove' /> Cancel
        </Button>
        <Button primary onClick={handleSave}>
          <Icon name={'checkmark'} />
          Save Changes
        </Button>
      </Modal.Actions>
    </Modal>
  )
}

const getColorStyles = (properties) => {

  var backgroundColor = _.get(properties, 'backgroundColor', null);
  var textColor = _.get(properties, 'textColor', null);

  return {
    backgroundColor: colorStyle(backgroundColor),
    color: colorStyle(textColor)
  }
}

const colorStyle = (color) => {
  return `rgba(${_.get(color, 'r') || 0}, ${_.get(color, 'g') || 0}, ${_.get(color, 'b') || 0}, ${_.get(color, 'a') || 1})`;
}