import React, { PropTypes } from 'react';
import cx from 'classnames';
import styles from './../EnhancedDocumentViewer.module.scss';

import TextView from './TextView.jsx';

class TextLayer extends React.PureComponent {
  constructor(props) {
    super(props);
  }

  render() {
    var scale = this.props.dimensions.width / this.props.page.width;
    var layerStyle = {
      transform: `scale(${scale}, 1)`
    }

    return (
      <div className={styles.textLayer}>
        {this._renderTexts()}
      </div>
    );
  }

  _renderTexts(){
    if(!this.props.page.texts){
      return false;
    }
    
    var scale = this.props.dimensions.width / this.props.page.width;

    return this.props.page.texts.map((item, index) => {
      var font = null;

      if(item.f < (this.props.fonts || []).length){
        font = this.props.fonts[item.f];
      }

      return (<TextView key={index} item={item} scale={scale} font={font} />);
    })
  }
}

export default TextLayer;
