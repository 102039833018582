import React, { useState, useEffect } from 'react';
import { Fade, Flip, Bounce, Slide, Roll } from 'react-awesome-reveal';

export default function Animation({ type, direction, duration = 1, delay = 0, children, ...rest }) {

  var animationComponent = children;
  switch (type) {
    case 'fade':
      animationComponent = (
        <Fade direction={direction}
          duration={duration * 1000}
          delay={delay * 1000}
        >
          {children}
        </Fade>
      );

      break;

    case 'flip':
      animationComponent = (
        <Flip direction={direction}
          duration={duration * 1000}
          delay={delay * 1000}
        >
          {children}
        </Flip>
      );

      break;

    case 'bounce':
      animationComponent = (
        <Bounce direction={direction}
          duration={duration * 1000}
          delay={delay * 1000}
        >
          {children}
        </Bounce>
      );

      break;

    case 'slide':
      animationComponent = (
        <Slide direction={direction}
          duration={duration * 1000}
          delay={delay * 1000}
        >
          {children}
        </Slide>
      );

      break;

    case 'roll':
      animationComponent = (
        <Roll 
          duration={duration * 1000}
          delay={delay * 1000}
        >
          {children}
        </Roll>
      );

      break;
  }

  return animationComponent;
}