import React from 'react'
import { connect } from 'react-redux'

import cx from 'classnames';
import _ from 'lodash';
import styles from './audioViewer.module.scss';
import WaveSurfer from 'wavesurfer.js';
import AuthService from 'services/auth.service';
import Truncate from 'react-truncate';

import {
  Icon,
  Header
} from 'semantic-ui-react';

import { useEffect } from 'react';

class AudioView extends React.Component {
  constructor(props){
    super(props);

    this.waveformId = "waveform_"+ new Date().getTime();

    this._handlePlay = this._handlePlay.bind(this);
    this._handleSkipBackward = this._handleSkipBackward.bind(this);
    this._handleSkipForward = this._handleSkipForward.bind(this);
    this._handleReplay = this._handleReplay.bind(this);
    this._handleToggleMute = this._handleToggleMute.bind(this);

    var audioProperties = this._extractAudioProperties(this.props);
    

    this.state = {
      ...audioProperties,
      duration: 0,
      currentTime: 0,
      audioPlaying: false,
      onMute: false,
      waveformLoaded: false,
      loop: false
    }

    this.startRange = 0;
    this.timeSpent = 0;
    this.currentRange = null;
    this.isPlaying = false;
  }

  componentDidMount() {
    this.loadPlayer()
  }

  componentWillUnmount(){
    if(this.wavesurfer){
      this.wavesurfer.destroy();
      this.wavesurfer = null;
    }

    if (this.timer) {
      clearInterval(this.timer);
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps){
    if(this.props.asset.id !== nextProps.asset.id ||
      this.props.asset.metadata.modified_date !== nextProps.asset.metadata.modified_date){
      
      var audioProperties = this._extractAudioProperties(nextProps);

      this.setState({
        ...audioProperties
      }, ()=>{
        this.updatePlayerSource(nextProps); 
      });
    }
  }

  loadPlayer(){
    if(this.timer){
      clearInterval(this.timer);
    }

    this.wavesurfer = WaveSurfer.create({
      barWidth: 1,
      container: '#' + this.waveformId,
      height: 100,
      progressColor: this.props.inline ? '#5DBEC1' : '#8ED4FF',
      responsive: true,
      waveColor: this.props.inline ? '#D4D4D4' : '#ffffff',
      cursorWidth: 0,
      normalize: true,
      hideScrollbar: true,
      backend: 'MediaElement',
      forceDecode: true,
      mediaType: 'audio'
    });

    this.wavesurfer.load(this.state.src);

    this.wavesurfer.on('ready', ()=> {
      
      this.setState({
        duration: this.formatTime(this.wavesurfer.getDuration())
      });

      this.startRange = 0;
      this.endRange = 0;
      this.timeSpent = 0;
    });

    this.wavesurfer.on('waveform-ready', () => {
      console.log("Waveform loaded");

      this.setState({
        waveformLoaded: true
      });
    })

    this.wavesurfer.on('audioprocess', () => {
      var endRange = this.wavesurfer.getCurrentTime();

      if(endRange - this.state.currentTime < 0.5){
        return;
      }

      this.setState({
        currentTime: this.wavesurfer.getCurrentTime()
      });

      if(endRange - this.state.currentTime > 1.5){
        return;
      }
    });

    this.wavesurfer.on('play', () => {
      this.setState({
        audioPlaying: this.wavesurfer.isPlaying()
      });
    });

    this.wavesurfer.on('seek', () => {
      this.currentRange =null;
      this.setState({
        currentTime: this.wavesurfer.getCurrentTime(),
      }, () => this.wavesurfer.play());     
    });

    this.wavesurfer.on('finish', () => {
      if(this.currentRange){
        this.currentRange = null;
      }

      if(this.state.loop){
        this.wavesurfer.play(0);
        this.setState({
          currentTime: 0
        });
      }

      this.setState({
        audioPlaying: this.wavesurfer.isPlaying(),
        currentTime: 0
      });
    });

    this.wavesurfer.on('error', (e) =>{
      console.log(e);
      var error = this.wavesurfer.error();

      this.setState({
        error: error
      })
    });

    this.timer = setInterval(() =>{
      this.syncRanges();
    }, 5000);
  }

  updatePlayerSource(props){
    if(this.timer){
      clearInterval(this.timer);
    }

    this.syncRanges();

    this.currentRange = null;
    this.startRange = 0;
    this.endRange = 0;
    this.timeSpent = 0;
    this.isPlaying = false;
    
    var audioProperties = this._extractAudioProperties(props);
    this.wavesurfer.src(audioProperties);

    this.timer = setInterval(() =>{
      this.syncRanges();
    }, 5000);
  }

  syncRanges(){
    this.props.ranges.forEach((range) => {

      var shouldRemove = false;
      var isCurrent = range.id == this.currentRange; 

      var duration = range.end - range.start;
      var shouldSync = duration >= 2 && (!(range.synced || range.syncing));
      shouldRemove = !shouldSync && !isCurrent;

      if(shouldSync && this.props.session){
        console.log('Sync with server' + range.id);
        var request = {
          type: 103,
          session: this.props.session,
          rangeId: range.external_id,
          start: range.start,
          end: range.end,
          eventDate: range.updatedDate
        }

        this.props.syncAssetVideoRange(range.id, request); 
      }

      if(shouldRemove){
        console.log('Remove from store' + range.id);
        this.props.removeVideoRange(range.id);
      }

    })
  }

  formatTime(time) {
    // Hours, minutes and seconds
    var hrs = ~~(time / 3600);
    var mins = ~~((time % 3600) / 60);
    var secs = ~~time % 60;

    // Output like "1:01" or "4:03:59" or "123:03:59"
    var ret = "";

    if (hrs > 0) {
        ret += "" + hrs + ":" + (mins < 10 ? "0" : "");
    }

    ret += "" + mins + ":" + (secs < 10 ? "0" : "");
    ret += "" + secs;
    return ret;
  };

  _extractAudioProperties(props){
    var albumCover = '/images/audioViewer/albumcover.jpg';
    if(props.asset.icon && props.asset.icon.full){
      albumCover = props.asset.icon.full;
    }

    var audioDetail = {
      src: AuthService.getURL(`api/2.0/assets/${props.asset.id}/download`,{
        modifiedDate: props.asset.metadata.modified_date,
        preview: true
      }),
      type: props.asset.metadata.content_type,
      albumCover: albumCover
    }

    return audioDetail;
  }

  _handlePlay() {
    this.wavesurfer.playPause();
    this.setState({
      audioPlaying: this.wavesurfer.isPlaying()
    });
  }

  _handleSkipBackward() {
    this.wavesurfer.skipBackward(10);
  }

  _handleSkipForward() {
    this.wavesurfer.skipForward(10);
  }

  _handleReplay() {
    this.setState({
      loop: !this.state.loop
    });
  }

  _handleToggleMute() {
    this.setState({
      onMute: !this.state.onMute
    }, () =>
        this.wavesurfer.setVolume(this.state.onMute ? 0 : 1)  
    );
  };

  render() {
    let style = {
      'backgroundImage': `url("${this.state.albumCover}")`
    };

    if(this.props.inline) {
      return this.renderInlineViewer();
    }

    return (
      <div className={styles.container}>
        <div className={styles.overlay} style={style}><div className={styles.inner}></div></div>
        <div className={styles.wrapper}>
          <div className={styles.audioPlayer}>
            <div className={cx(styles.albumCover)}>
              <div className={cx(styles.albumWrapper, { [styles.playing]: this.state.audioPlaying })}>
                <div className={styles.vinyl}></div>
                <div className={styles.inner}>
                  <img src={this.state.albumCover} alt="albumCover" />
                  <div className={styles.ring}></div>
                  <div className={styles.hole}></div>
                </div>
              </div>
              <div className={cx(styles.albumShadow)}></div>
            </div>
            <div className={styles.actions}>
              <div className={styles.action} onClick={this._handleReplay}>
                <Icon name="refresh" className={cx({ [styles.active]: this.state.loop })} />
              </div>
              <div className={styles.action} onClick={this._handleSkipBackward}>
                <Icon name="backward" />
              </div>
              <div className={cx(styles.action, styles.play)} onClick={this._handlePlay}>
                <Icon name={this.state.audioPlaying ? 'pause' : 'play'} className={styles.active} />
              </div>
              <div className={styles.action} onClick={this._handleSkipForward}>
                <Icon name="forward" />
              </div>
              <div className={styles.action} onClick={this._handleToggleMute}>
                <Icon name={this.state.onMute ? 'volume off' : 'volume up'} className={styles.active} />
              </div>
            </div>
          </div>

          <div className={styles.title}><Truncate lines={2}>{this.props.asset.name}</Truncate></div>

          <div className={cx(styles.visualiser, { [styles.loaded]: this.state.waveformLoaded })}>
            <div className={cx(styles.currentTime, styles.timeRange)}>
              {this.formatTime(this.state.currentTime)}
            </div>
            <div className={styles.waveForm} id={this.waveformId}></div>
            <div className={cx(styles.totalDuration, styles.timeRange)}>
              {this.state.duration != '0' ? this.state.duration : '0:00'}
            </div>
          </div>

        </div>
      </div>
    );
  }

  renderInlineViewer() {
    return (
      <div className={styles.inlineViewer} onClick={this._handlePlay}>
        <div className={styles.waveForm} id={this.waveformId}></div>
        <div className={styles.inlineAction}>
          <div className={cx(styles.inlinePlay)}>
            <Icon name={this.state.audioPlaying ? 'pause' : 'play'} className={styles.active} />
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  var assetState = state.asset[ownProps.contextId];
  return {
    session: assetState ? assetState.session.id : null,
    ranges: assetState ? assetState.session.ranges : []
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    
  }
}

const AudioViewer = connect(
  mapStateToProps,
  mapDispatchToProps
)(AudioView)

export default AudioViewer;