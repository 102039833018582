import React, { Fragment, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import cx from 'classnames';
import _ from 'lodash';
import FullStory, { FullStoryAPI } from 'react-fullstory';

import {
  Button,
  Icon,
  Menu,
  Sidebar,
  Popup,
  Image
} from 'semantic-ui-react';

import {
  Link, useRouteMatch, useLocation
} from "react-router-dom";

import StoryboardSwitcher from '../Storyboard/switcher';
import Tooltip from '../Tooltip';
import styles from './applicationMenu.module.scss'
import DefaultUserProfileImage from 'images/default_user_profile_background.png';

import CreatePageModal from 'pages/pages/create';
import { fetchUserProfile, logoutUser } from 'actions/users';

const FULLSTORY_ORGKEY = process.env.REACT_APP_FULLSTORY_ORGKEY;

export default function ApplicationMenuView(props) {
  const dispatch = useDispatch();
  let { url } = useRouteMatch();

  const profile = useSelector(state => _.get(state, 'users.me', null));
  const cleverstory = useSelector(state => _.get(state, 'users.me.permissions.cleverstory'));

  useEffect(() => {
    dispatch(fetchUserProfile());
  }, [])

  useEffect(() => {
    if (!FULLSTORY_ORGKEY || !profile) {
      return;
    }

    FullStoryAPI('identify', profile.email, {
      displayName: `${profile.firstName || ''} ${profile.lastName || ''} `,
      email: profile.email,
      company_str: ''
    });

  }, [profile]);

  const _toggleMenu = () => {
    if (props.onVisibilityChange) {
      props.onVisibilityChange(!props.expanded);
    }
  }

  const _handleAddAssetClicked = () => {
    props.onAddAssetClicked();
  }

  return (
    <Sidebar
      as={Menu}
      animation='push'
      icon
      vertical
      visible={true}
      className={cx(styles.sidebar, { [styles.open]: props.expanded })}
    >
      <Menu.Menu className={styles.toggle}>
        <MenuItem
          icon="icon-menu"
          title={props.expanded ? "Collapse" : "Expand Menu"}
          expanded={props.expanded}
          onClick={_toggleMenu}
        />
      </Menu.Menu>

      <Menu.Menu>
        <LogoMenuItem expanded={props.expanded} />
      </Menu.Menu>

      <Menu.Menu className={styles.primaryMenu}>
        <MenuItem icon="cleverstory-content"
          title="Content"
          value="content"
          to={`${url}/content`}
          expanded={props.expanded}
        />
        <MenuItem icon="cleverstory-page"
          title="Pages"
          value="pages"
          to={`${url}/pages`}
          expanded={props.expanded}
        />

        <MenuItem icon="cleverstory-journey"
          title="Journeys"
          value="journeys"
          to={`${url}/journeys`}
          expanded={props.expanded}
        />

        <MenuItem icon="cleverstory-reports"
          title="Reports"
          value="reports"
          to={`${url}/reports/visitors`}
          expanded={props.expanded}
        />
      </Menu.Menu>

      <Menu.Menu className={styles.secondary}>
        <MenuItem icon="icon-settings"
          title="Settings"
          value="settings"
          to={`${url}/settings/profile`}
          expanded={props.expanded}
        />
        <SwitcherMenuItem expanded={props.expanded} />
        <ProfileMenuItem expanded={props.expanded} profile={profile} />
      </Menu.Menu>

      <FullStoryView />
    </Sidebar>
  );
}

function MenuItem(props) {
  const location = useLocation();
  const paths = location.pathname.split('/');

  return (
    <Menu.Item as={props.to ? Link : 'div'}
      className={cx(styles.menu, props.className, {
        [styles.selected]: props.value === paths[3],
        [styles.expanded]: props.expanded
      })}
      onClick={props.onClick} to={props.to}>
      
      <Tooltip content={props.title}
        className={styles.tooltip}
        basic
        position='right center'
        disabled={props.expanded}
        hoverable
        inverted
        size='tiny'
        offset='0px, 2px'
        popperModifiers={{
          preventOverflow: {
            boundariesElement: "offsetParent"
          }
        }}>
        <i className={cx('icon', props.icon)} />
      </Tooltip>
      <span className={styles.label}>{props.title}</span>
    </Menu.Item>

  )
}

function SwitcherMenuItem(props) {

  return (
    <StoryboardSwitcher trigger={
      <Menu.Item className={cx(styles.menu, styles.switcher, {
        [styles.expanded]: props.expanded
      })} onClick={props.onClick}>
        <i className={cx('icon', 'icon-widget')} />
        <span className={styles.label}>{props.expanded ? "Storyboards" : "Switch Storyboard"}</span>
      </Menu.Item>
    } />

  );
}

function ProfileMenuItem(props) {
  const profileIcon = _.get(props.profile, 'icon.full', '');

  return (

    <Popup
      className={styles.profileMenuPopup}
      position='right center'
      hoverable
      popperModifiers={{
        preventOverflow: {
          boundariesElement: "window"
        }
      }}
      flowing
      content={
        <ProfileMenu profile={props.profile} />
      }
      trigger={
        <Menu.Item className={cx(styles.menu, styles.profileMenuItem, {
          [styles.expanded]: props.expanded
        })} onClick={props.onClick}>
          <Image src={profileIcon} />
          <span className={styles.label}>{_.get(props, 'profile.firstName')}</span>
        </Menu.Item>
      }
    >
    </Popup>
  );
}

function LogoMenuItem(props) {
  return (
    <Menu.Item as='div' className={cx(styles.menu, styles.logoMenu, {
      [styles.expanded]: props.expanded
    })}>
      <Tooltip content="Cleverstory"
        className={styles.tooltip}
        basic
        position='right center'
        disabled={props.expanded}
        hoverable
        inverted
        size='tiny'
        popperModifiers={{
          preventOverflow: {
            boundariesElement: "offsetParent"
          }
        }}>
        <Image src='/images/logo.svg' />
      </Tooltip>
      <span className={styles.label}>Cleverstory</span>
    </Menu.Item>
  )
}

function ProfileMenu(props) {
  let { url } = useRouteMatch();

  return (
    <Menu vertical fluid className={styles.profileMenu}>
      <Menu.Item><Avatar profile={props.profile} /></Menu.Item>

      <Menu.Menu>
        <Menu.Item link href="https://support.cleverstory.io/" target='_blank'>Support Center</Menu.Item>
        <Menu.Item link href="https://app.paperflite.com/terms" target='_blank'>Terms &amp; Conditions</Menu.Item>
        <Menu.Item link href="https://app.paperflite.com/privacy" target='_blank'>Privacy Policy</Menu.Item>
      </Menu.Menu>
      <Menu.Item icon as={Link} to={'/logout'}>
        <Icon name="power off" /> Logout
      </Menu.Item>
    </Menu>
  );
}

function Avatar(props) {
  return (
    <div className={styles.avatar}>
      <Image src={props.profile ? props.profile.icon.full : DefaultUserProfileImage} avatar size="mini" />
      <span>{props.profile ? `${props.profile.firstName} ${props.profile.lastName}` : null}</span>
    </div>
  );
}

function FullStoryView() {
  if (!FULLSTORY_ORGKEY) {
    return false;
  }

  return (
    <FullStory key="fullstory" org={ FULLSTORY_ORGKEY } />
  )
}