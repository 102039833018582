import { CREATE_ALIAS_REQUEST } from 'constants/actionTypes';
import { 
  FETCH_PAGE_REQUEST, FETCHING_PAGE_SUCCEEDED, FETCHING_PAGE_FAILED,
  UPDATE_PAGE_REQUEST, UPDATING_PAGE_SUCCEEDED, UPDATING_PAGE_FAILED,
  PUBLISH_PAGE_REQUEST, PUBLISHING_PAGE_SUCCEEDED, PUBLISHING_PAGE_FAILED,
  RESTORE_PAGE_REQUEST, RESTORE_PAGE_SUCCEEDED, RESTORE_PAGE_FAILED,
  DELETE_PAGE_REQUEST, DELETE_PAGE_SUCCEEDED, DELETE_PAGE_FAILED,

  UPDATE_PAGE_PROPERTIES, UPDATE_TRACKING_PIXEL_PROPERTIES,

  INVALIDATE_PAGE,

  CREATING_ALIAS_SUCCEEDED, UPDATING_ALIAS_SUCCEEDED
} from 'constants/actionTypes.jsx';

import _ from 'lodash';

const initialState = {
  loading: false,
  updating: false,
  publishing: false,
  restoring: false,
  deleting: false,
  item: null,
  error: null,
  selection: null,
  editors: {},
  widget: {
    content: {
      loading: false,
      item: null,
      updating: false
    }
  },
  palette: {
    loading: false,
    groups: []
  }
}

function pageEditorReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_PAGE_REQUEST:
      return {...state,
        loading: true,
        error: null
      }

    case FETCHING_PAGE_SUCCEEDED:
      return {...state,
        loading: false,
        item: action.payload,
        error: null
      }

    case FETCHING_PAGE_FAILED:
      return {...state,
        loading: false,
        error: action.payload
      }

    case INVALIDATE_PAGE: 
      return initialState;

    case UPDATE_PAGE_REQUEST:
      return {...state,
        updating: true,
        error: null
      }  

    case UPDATING_PAGE_SUCCEEDED:
      return {...state,
        updating: false,
        item: action.payload,
        error: null
      }    

    case UPDATING_PAGE_FAILED:
      return {...state,
        updating: false,
        error: action.payload
      }

    case UPDATE_PAGE_PROPERTIES:
      var { change } = action.payload;

      return {
        ...state,
        item: {
          ...state.item,
          draft: {
            ...state.item.draft,
            properties: {
              ...state.item.draft.properties,
              ...change
            }
          }
        }
      }

    case UPDATE_TRACKING_PIXEL_PROPERTIES:

      return {
        ...state,
        item: {
          ...state.item,
          draft: {
            ...state.item.draft,
            trackingPixels: action.payload
          }
        }
      }

    case PUBLISH_PAGE_REQUEST:
      return {...state,
        publishing: true,
        error: null
      }  

    case PUBLISHING_PAGE_SUCCEEDED:
      return {...state,
        publishing: false,
        item: action.payload,
        error: null
      }    

    case PUBLISHING_PAGE_FAILED:
      return {...state,
        publishing: false,
        error: action.payload
      } 
      
    case RESTORE_PAGE_REQUEST:
      return {
        ...state,
        restoring: true,
        error: null
      }

    case RESTORE_PAGE_SUCCEEDED:
      return {
        ...state,
        restoring: false,
        item: action.payload,
        error: null
      }

    case RESTORE_PAGE_FAILED:
      return {
        ...state,
        restoring: false,
        error: action.payload
      } 
    
    case DELETE_PAGE_REQUEST:
      return {...state,
        deleting: true,
        error: null
      }  

    case DELETE_PAGE_SUCCEEDED:
      return {...state,
        deleting: false,
        item: null,
        error: null
      }    

    case DELETE_PAGE_FAILED:
      return {...state,
        deleting: false,
        error: action.payload
      }
    
    case CREATING_ALIAS_SUCCEEDED:
    case UPDATING_ALIAS_SUCCEEDED:
      const { response } = action.payload;

      if(!response.canonical){
        return state;
      }
      
      const target = _.get(response, 'target.id');
      const page = _.get(state, 'item.id');
      if(response.type != 'page' || target != page){
        return state;
      }

      return {
        ...state,
        item: {
          ...state.item,
          alias: response.url
        }
      };
    
    default:
      return state;
  }
}

export { pageEditorReducer };