import React, { useState, Fragment, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateProperties, updateWidget } from 'actions/widgetEditor';

import _ from 'lodash';
import cx from 'classnames';

import { Widget, getSpacingStyles } from '../widget.jsx';
import { denormalise } from 'services/widget.helper';
import { WidgetRegistry } from '../registry.jsx';
import { QuickSettings, QuickSettingsProperty } from 'components/QuickSettings';
import { Banner } from 'components/Banner';

import { useWidgetDrop } from 'services/dnd.service';
import ImageTools from 'services/imagetools.service';
import { BUTTON } from 'constants/ItemTypes';

import styles from './cta.module.scss';
import { getBorderStyles } from '../widget.jsx';

export const CTACard = (props) => {
  const dispatch = useDispatch();
  const ref = useRef(null);
  const dropRef = useWidgetDrop(props, ref, {
    acceptTargets: (type) => {
      return false;
    }
  });

  const properties = props.properties;
  const marginStyle = getSpacingStyles('margin', properties.margin) || {};
  const paddingStyle = getSpacingStyles('padding', properties.padding) || {};
  
  const borderStyle = getBorderStyles(properties.border) || {};

  const widgetStyles = {
    ...marginStyle,
    ...borderStyle
  }

  const textWidget = useSelector(state => {
    var items = null;
    var editorContext = state.widgetsEditor.editors[props.editor];

    if (!editorContext) {
      return null;
    }

    items = (props.widgets || []).map(id => {
      return editorContext.widgetsById[id];
    }).filter(item => {
      const type = _.get(item, 'type');
      return type != BUTTON;
    }).map(item => {
      return item.id;
    });

    return items;
  });

  const buttonWidget = useSelector(state => {
    var items = null;
    var editorContext = state.widgetsEditor.editors[props.editor];

    if (!editorContext) {
      return null;
    }

    items = (props.widgets || []).map(id => {
      return editorContext.widgetsById[id];
    }).filter(item => {
      const type = _.get(item, 'type');
      return type === BUTTON;
    }).map(item => {
      return item.id;
    });

    return items;
  });

  return (
    <div className={cx(props.className, styles.ctaCard)}
      id={`widget_${props.id}`}
      onMouseOver={props.onMouseOver}
      onMouseOut={props.onMouseOut}
      onClick={props.onClick}
      style={widgetStyles}
    >
      <Banner properties={properties} />
      <div className={styles.inner} style={paddingStyle}>
        <div className={styles.text}>
          <Widget.Children widgets={textWidget} parent={props.id} editor={props.editor} editable={props.editable} />
        </div>
        <div className={styles.button}>
          <Widget.Children widgets={buttonWidget} parent={props.id} editor={props.editor} editable={props.editable} />
        </div>
      </div>
      {props.children}
    </div>
  );
}

function CTACardQuickSettings(props) {
  const dispatch = useDispatch();

  const [selected, setSelected] = useState('spacing');

  const menuItems = [
    { id: 'spacing', title: 'Spacing', icon: 'icon-spacing' },
    { id: 'background', title: 'Background', icon: 'icon-background' },
    { id: 'border', title: 'Border', icon: 'icon-border' },
    { id: 'advanced', title: 'Advanced', icon: 'icon-custom' }
  ]

  const handleMenuClick = (item) => {
    setSelected(item);
  }

  var widget = useSelector(state => {
    const editorContext = state.widgetsEditor.editors[props.editor];

    if (editorContext) {
      return editorContext.widgetsById[props.id];
    }

    return null;
  });

  var widgetsById = useSelector(state => {
    const editorContext = state.widgetsEditor.editors[props.editor];

    if (editorContext) {
      return editorContext.widgetsById;
    }

    return {};
  });

  if (!widget) {
    return false;
  }

  var properties = widget.properties;
  var updating = widget.updating;
  var updatingBackground = updating && _.get(properties, 'background.upload') != null;

  const handleChange = (changeRequest) => {
    dispatch(updateProperties({
      id: widget.id,
      change: changeRequest,
      context: props.editor
    }));
  }

  const handleBackgroundImageChange = (media, type) => {
    console.log("Upload image " + type);

    var changeRequest = { ...widget };
    _.set(changeRequest, 'properties.background.upload', {
      type: type,
      data: media
    });

    var widgets = denormalise(changeRequest.widgets, widgetsById);
    changeRequest = _.set(changeRequest, 'widgets', widgets);
    changeRequest = WidgetRegistry.processRequest([changeRequest])[0];
    dispatch(updateWidget(props.id, changeRequest, props.editor));
  }

  const handleCssChange = (css) => {
    var changeRequest = { ...widget };
    _.set(changeRequest, 'properties.css', css);

    var widgets = denormalise(changeRequest.widgets, widgetsById);
    changeRequest = _.set(changeRequest, 'widgets', widgets);
    changeRequest = WidgetRegistry.processRequest([changeRequest])[0];
    dispatch(updateWidget(props.id, changeRequest, props.editor));
  }

  return (
    <QuickSettings menuItems={menuItems} selected={selected} onMenuClick={handleMenuClick} >
      {selected === 'spacing' &&
        <>
          <QuickSettingsProperty.Spacing
            title='Margin'
            value={properties.margin}
            onChange={(margin) => {
              handleChange({
                margin: margin
              });
            }}
          />
          <QuickSettingsProperty.Spacing
            title='Padding'
            value={properties.padding}
            onChange={(padding) => {
              handleChange({
                padding: padding
              });
            }}
          />
        </>
      }

      {selected === 'background' &&
        <QuickSettingsProperty.Background
          expanded
          overlayEnabled
          updating={updatingBackground}
          value={properties.background}
          onChange={(background) => {
            handleChange({
              background: background
            });
          }}
          onImageChange={(image, type) => {
            handleBackgroundImageChange(image, type);
          }}
        />
      }

      {selected === 'border' &&
        <QuickSettingsProperty.Border expanded value={properties.border} onChange={(border) => {
          handleChange({
            border: border
          });
        }}
        />
      }

      {selected === 'advanced' &&
        <QuickSettingsProperty.CSS title='Stylesheets' value={properties.css} onChange={handleCssChange} />
      }
    </QuickSettings>
  )
}

CTACard.QuickSettings = CTACardQuickSettings;

CTACard.QuickAction = function QuickAction(props) {
  const dispatch = useDispatch();
  const uploadIconRef = React.createRef();

  var widget = useSelector(state => {
    const editorContext = state.widgetsEditor.editors[props.editor];

    if (editorContext) {
      return editorContext.widgetsById[props.id];
    }

    return null;
  });

  var widgetsById = useSelector(state => {
    const editorContext = state.widgetsEditor.editors[props.editor];

    if (editorContext) {
      return editorContext.widgetsById;
    }

    return {};
  });

  var properties = widget.properties;
  var updating = widget.updating;
  var updatingBackground = updating && _.get(properties, 'background.upload') != null;


  const handleFileChange = (e) => {
    const file = e.target.files[0];

    if (typeof file === 'string' || file.type.match('image.*')) {
      ImageTools.resize(file, {
        width: 1600, // maximum width
        height: 1024 // maximum height
      }, (blob, didItResize) => {
        loadImageFile(blob).then(url => {
          onImageChange(url, 'image');
        })
      });
    } else if (file.type.match('video.*')) {
      loadImageFile(file).then(url => {
        onImageChange(url, 'video');
      })
    }
  }

  const onImageChange = (media, type) => {
    console.log("Upload image " + type);

    var changeRequest = { ...widget };
    _.set(changeRequest, 'properties.background.upload', {
      type: type,
      data: media
    });

    var widgets = denormalise(changeRequest.widgets, widgetsById);
    changeRequest = _.set(changeRequest, 'widgets', widgets);
    changeRequest = WidgetRegistry.processRequest([changeRequest])[0];
    dispatch(updateWidget(props.id, changeRequest, props.editor));
  }

  const loadImageFile = (imageFile) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.onload = e => resolve(e.target.result)
      reader.onerror = reject
      reader.readAsDataURL(imageFile)
    });
  }

  return (
    <Widget.Action className={styles.quickAction}
      disabled={updatingBackground}
      onClick={() => {
        uploadIconRef.current.click();
      }}
    >
      {updatingBackground ? 'Uploading...' : 'Change Background'}
      <input
        ref={uploadIconRef}
        type="file"
        accept="image/* video/*"
        onChange={handleFileChange}
      />
    </Widget.Action>
  );
}