export default class AsyncLocalStorage {
  static setItem(key, value) {
    localStorage.setItem(key, value);
  }

  static getItem(key){
    return window.localStorage.getItem(key);
  }

  static removeItem(key) {
      return window.localStorage.removeItem(key);
  }
}