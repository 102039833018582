import {
  FETCHING_CONTENT_ANALYTICS_DAILY, FETCHING_CONTENT_ANALYTICS_DAILY_SUCCEEDED, FETCHING_CONTENT_ANALYTICS_DAILY_FAILED,
  FETCHING_CONTENT_REPORTS, FETCHING_CONTENT_REPORTS_SUCCEEDED, FETCHING_CONTENT_REPORTS_FAILED,
  DOWNLOAD_CONTENT_REPORTS, DOWNLOADING_CONTENT_REPORTS_SUCCEEDED, DOWNLOADING_CONTENT_REPORTS_FAILED,
  FETCH_ASSET_SESSIONS_TIME_SERIES, FETCHING_ASSET_SESSIONS_TIME_SERIES_SUCCEEDED, FETCHING_ASSET_SESSIONS_TIME_SERIES_FAILED,
  FETCH_ASSETS_FOR_REPORT, FETCHING_ASSETS_FOR_REPORT_SUCCEEDED, FETCHING_ASSETS_FOR_REPORT_FAILED,
  FETCH_CONTENT_ANALYTICS_FOR_REPORTS, FETCHING_CONTENT_ANALYTICS_FOR_REPORTS_SUCCEEDED, FETCHING_CONTENT_ANALYTICS_FOR_REPORTS_FAILED
} from 'constants/actionTypes';

const initialState = {
  dailyReports: {
    loading: false,
    invalidated: true,
    items: [],
    error: null
  },
  assets: {
    loading: false,
    invalidated: true,
    items: {},
    error: null,
    pagination: {
      current: -1,
      totalPages: 1,
      totalRecords: 0
    }
  },
  asset_session_series: {
    loading: false,
    items: [],
    error: null
  },
  assets: {
    loading: false,
    invalidated: true,
    items: [],
    error: null,
    pagination: {
      current: -1,
      totalPages: 1,
      totalRecords: 0
    }
  },
  cumulativeAnalytics: {
    loading: false,
    item: {}
  }
}

export function contentReportSReducer(state = initialState, action) {
  switch (action.type) {
    case FETCHING_CONTENT_ANALYTICS_DAILY:
      return {
        ...state,
        dailyReports: {
          ...state.dailyReports,
          loading: true
        }
      }

    case FETCHING_CONTENT_ANALYTICS_DAILY_SUCCEEDED:
      return {
        ...state,
        dailyReports: {
          ...state.dailyReports,
          loading: false,
          items: action.payload
        }
      }

    case FETCHING_CONTENT_ANALYTICS_DAILY_FAILED:
      return {
        ...state,
        dailyReports: {
          ...state.dailyReports,
          loading: false,
          error: action.payload
        }
      }

    case FETCHING_CONTENT_REPORTS:
      return {
        ...state,
        assets: {
          ...state.assets,
          loading: true
        }
      }

    case FETCHING_CONTENT_REPORTS_SUCCEEDED:

      var response = action.payload;

      var records = response.records;
      var index = action.payload.currentPage + 1;

      var items = state.assets.items;

      if (response.currentPage !== 0) {
        items = [...state.assets.items, ...records];
      } else {
        items = records
      }

      items = items.reduce((x, y) => {
        var index = x.findIndex((e) => {
          return e.id === y.id;
        });

        if (index < 0) {
          return [...x, y];
        } else {
          x[index] = y;
          return x;
        }
      }, []);

      return {
        ...state,
        assets: {
          ...state.assets,
          loading: false,
          invalidated: false,
          items: items,
          pagination: {
            ...state.assets.pagination,
            current: action.payload.currentPage,
            totalPages: action.payload.totalPages,
            totalRecords: action.payload.totalRecordsCount
          }
        }
      }

    case FETCHING_CONTENT_REPORTS_FAILED:
      return {
        ...state,
        assets: {
          ...state.assets,
          loading: false,
          error: action.payload.error
        }
      }

    case DOWNLOAD_CONTENT_REPORTS:
      return {
        ...state,
        assets: {
          ...state.assets,
          downloading: true,
          downloaded: false
        }
      }

    case DOWNLOADING_CONTENT_REPORTS_SUCCEEDED:
      return {
        ...state,
        assets: {
          ...state.assets,
          downloading: false,
          downloaded: true
        }
      }

    case DOWNLOADING_CONTENT_REPORTS_FAILED:
      return {
        ...state,
        assets: {
          ...state.assets,
          downloading: false,
          downloaded: false,
          error: action.payload
        }
      }

    case FETCH_ASSET_SESSIONS_TIME_SERIES:
      return {
        ...state,
        asset_session_series: {
          ...state.asset_session_series,
          loading: true
        }
      }

    case FETCHING_ASSET_SESSIONS_TIME_SERIES_SUCCEEDED:
      var records = action.payload;

      return {
        ...state,
        asset_session_series: {
          ...state.asset_session_series,
          loading: false,
          items: records,
          error: null
        }
      }

    case FETCHING_ASSET_SESSIONS_TIME_SERIES_FAILED: 
      return {
        ...state,
        asset_session_series: {
          ...state.asset_session_series,
          loading: false,
          error: action.payload.error
        }
      }
      
    case FETCH_ASSETS_FOR_REPORT:
      return {
        ...state,
        assets: {
          ...state.assets,
          loading: true
        }
      }
      
    case FETCHING_ASSETS_FOR_REPORT_SUCCEEDED:
      var records = action.payload.records;

      return {
        ...state,
        assets: {
          ...state.assets,
          loading: false,
          invalidated: false,
          items: records,
          pagination: {
            ...state.assets.pagination,
            current: action.payload.currentPage,
            totalPages: action.payload.totalPages,
            totalRecords: action.payload.totalRecordsCount
          }
        }
      }

    case FETCHING_ASSETS_FOR_REPORT_FAILED:
      return {
        ...state,
        assets: {
          ...state.assets,
          loading: false,
          error: action.payload.error
        }
      }

    case FETCH_CONTENT_ANALYTICS_FOR_REPORTS:
      return {
        ...state,
        cumulativeAnalytics: {
          ...state.cumulativeAnalytics,
          loading: true
        }
      }

    case FETCHING_CONTENT_ANALYTICS_FOR_REPORTS_SUCCEEDED: 
      return {
        ...state,
        cumulativeAnalytics: {
          ...state.cumulativeAnalytics,
          loading: false,
          item: action.payload
        }
      }

    case FETCHING_CONTENT_ANALYTICS_FOR_REPORTS_FAILED:
      return {
        ...state,
        cumulativeAnalytics: {
          ...state.cumulativeAnalytics,
          loading: false,
          item: {},
          error: action.payload.error
        }
      }

    default: 
      return state;
  }
}