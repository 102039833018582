import React, { useState, useEffect, useRef, createRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { fetchAssetsForWidget, updateProperties, updateWidgetContent, updateFiltersInWidgetAssets, updateSearchQueryInWidgetAssets } from 'actions/widgetEditor';
import { selectAssetForPage } from 'actions/content';

import cx from 'classnames';
import _ from 'lodash';
import { guid } from 'services/util';

import {
  Header,
  Grid,
  Segment,
  Loader,
  Visibility,
  Icon,
  Dropdown,
  Button,
  Input
} from 'semantic-ui-react';

import { ContentModal } from './content';
import { Carousel } from 'react-responsive-carousel';
import { getStyles } from '../widget.jsx';
import AssetTemplateView from 'components/Templates/assets';
import AssetViewer from 'components/AssetViewer/AssetViewer';

import { useWidgetDrop } from "services/dnd.service";
import { WidgetContext } from 'pages/pages/editor/editor.jsx';

import "react-responsive-carousel/lib/styles/carousel.min.css";
import styles from "./assets.module.scss";

import AsyncLocalStorage from 'services/localstorage.async.service';
import { Widget } from '../widget';
import { edit } from 'ace-builds';

export function AssetsGrid(props) {
  const ref = useRef(null);
  const dropRef = useWidgetDrop(props, ref, {

  });

  const { entityReference } = React.useContext(WidgetContext);
  const editable = props.editable;
  const properties = props.properties || {};

  const dispatch = useDispatch();

  var template = useSelector(state => {
    return state.templates.items.find(item => {
      return item.id === properties.template;
    })
  });

  var assetsState = useSelector(state => {
    var defaultState = {
      items: []
    };

    var assetsState = defaultState;
    const editorContext = state.widgetsEditor.editors[props.editor];
    if (editorContext) {
      assetsState = editorContext.widgetAssetsById[props.id];
    }

    return assetsState || defaultState;
  });

  var assetWidget = useSelector(state => {
    const editorContext = state.widgetsEditor.editors[props.editor];

    if (editorContext) {
      return editorContext.widgetsById[props.id];
    }

    return null;
  });

  var assetContent = assetWidget.content;
  var type = assetContent.type;


  const handleTitleChange = (e) => {
    const title = e.target.value;

    dispatch(updateProperties({
      id: props.id,
      change: {
        title: title
      },
      context: props.editor
    }));
  }

  const widgetStyles = getStyles(properties);

  return (
    <div className={cx(props.className, styles.assets, 'cs-assets')}
      id={`widget_${props.id}`}
      ref={props.dragRef(dropRef(ref))}
      onMouseOver={props.onMouseOver}
      onMouseOut={props.onMouseOut}
      onClick={props.onClick}
      style={widgetStyles}
    >
      <LayoutView id={props.id} properties={properties} loading={assetsState.loading} editor={props.editor} assets={assetsState.items} >
        {assetsState.items.filter(asset => properties.layout.type != 'inline').map(asset => {
          return <AssetTemplateView key={asset.id} template={template} asset={asset} type={type} />
        })}
      </LayoutView>

      {!assetsState.loading && assetsState.items.length == 0 &&
        <EmptyView widget={props} editor={props.editor} entityReference={entityReference} />
      }

      {props.children}
    </div>
  );
}

function LayoutView({ id, loading, properties, assets, children, editor }) {
  if (!properties.layout) {
    return false;
  }

  var type = properties.layout.type;
  switch (type) {
    case 'carousel':
      return (
        <CarouselView id={id}
          loading={loading}
          editor={editor}
          properties={properties}
        >
          {children}
        </CarouselView>
      )
    case 'metro':
      return (
        <MetroView
          id={id}
          loading={loading}
          editor={editor}
          properties={properties}
        >
          {children}
        </MetroView>
      )
    case 'inline':
      return (
        <InlineAssetView
          id={id}
          loading={loading}
          assets={assets}
          editor={editor}
          properties={properties} />
      )
    case 'grid':
    default:
      return (
        <GridView
          id={id}
          loading={loading}
          editor={editor}
          properties={properties}
        >
          {children}
        </GridView>
      )
  }
}

function InlineAssetView(props) {
  const dispatch = useDispatch();

  const assets = props.assets;
  const asset = assets[0];

  var contextId = AsyncLocalStorage.getItem(props.id);
  if (!contextId) {
    contextId = guid();
    AsyncLocalStorage.setItem(props.id, contextId);
  }

  useEffect(() => {
    dispatch(fetchAssetsForWidget(props.id, 0, props.editor));
  }, []);

  useEffect(() => {
    if (asset) {
      dispatch(selectAssetForPage(contextId, asset))
    }
  }, [asset]);

  if (!contextId) {
    return null;
  }

  if (!asset) {
    return false;
  }

  return (
    <AssetViewer asset={asset} contextId={contextId} inline />
  );
}


function GridView({ id, loading, properties, children, ...rest }) {
  const empty = React.Children.count(children) === 0;
  return (
    <>
      {properties.search && <SearchBar id={id} editor={rest.editor} />}
      <FilterMenu id={id} editor={rest.editor} filters={properties.filters} />

      <InfiniteScroll id={id} editor={rest.editor} pagination={properties.pagination} disabled={!properties.infiniteScroll}>
        <Grid className={styles.grid} columns={properties.layout.columns} stackable padded='vertically'>
          <Grid.Row stretched>
            {React.Children.map(children, child => {
              return (
                <Grid.Column>{React.cloneElement(child)}</Grid.Column>
              );
            })}

            {loading &&
              <Grid.Column width={16}>
                <LoadingCard initial={empty} />
              </Grid.Column>
            }

            {!properties.infiniteScroll &&
              <Grid.Column width={16}>
                <PaginationButton id={id} editor={rest.editor} properties={properties} />
              </Grid.Column>
            }
          </Grid.Row>
        </Grid>
      </InfiniteScroll>
    </>
  );
}

function MetroView({ id, loading, properties, children, ...rest }) {
  const empty = React.Children.count(children) === 0;
  var columnWidth = Math.floor(16 / properties.layout.columns);
  return (
    <>
      {properties.search && <SearchBar id={id} editor={rest.editor}/>}
      <FilterMenu id={id} editor={rest.editor} filters={properties.filters} />
      <InfiniteScroll id={id} editor={rest.editor} pagination={properties.pagination} disabled={!properties.infiniteScroll}>
        <Grid className={styles.grid} columns={properties.layout.columns} stackable padded='vertically'>
          <Grid.Row stretched>
            {React.Children.map(children, (child, index) => {
              if (index == 0) {
                return (
                  <Grid.Column width={2 * columnWidth}>{React.cloneElement(child)}</Grid.Column>
                );
              }

              return (
                <Grid.Column width={columnWidth}>{React.cloneElement(child)}</Grid.Column>
              );
            })}
            {loading &&
              <Grid.Column width={16}>
                <LoadingCard initial={empty} />
              </Grid.Column>
            }

            {!properties.infiniteScroll &&
              <Grid.Column width={16}>
                <PaginationButton id={id} editor={rest.editor} properties={properties} />
              </Grid.Column>
            }

          </Grid.Row>
        </Grid>
      </InfiniteScroll>
    </>
  );
}

function CarouselView({ id, loading, properties, children, ...rest }) {
  const empty = React.Children.count(children) === 0;

  const [state, setState] = useState({
    selected: false,
    prevHovered: false,
    nextHovered: false
  });

  var activeForegroundColor = _.get(properties, 'layout.arrowStyle.active.foregroundColor') || { r: 255, g: 255, b: 255, a: 1 };
  var activeBackgroundColor = _.get(properties, 'layout.arrowStyle.active.backgroundColor') || { r: 224, g: 225, b: 226, a: 1 };
  var hoverForegroundColor = _.get(properties, 'layout.arrowStyle.hover.foregroundColor') || { r: 255, g: 255, b: 255, a: 1 };
  var hoverBackgroundColor = _.get(properties, 'layout.arrowStyle.hover.backgroundColor') || { r: 202, g: 203, b: 205, a: 1 };
  var autoPlayDuration = parseInt(_.get(properties, 'layout.autoPlayDuration', 3000) || 3000);
  if(autoPlayDuration < 3000 || autoPlayDuration > 9000) {
    autoPlayDuration = 3000;
  }

  const handleChange = (index) => {
    setState((state) => {
      return {
        ...state,
        selected: index
      };
    });
  }

  const colorStyle = (color) => {
    return `rgba(${_.get(color, 'r') || 0}, ${_.get(color, 'g') || 0}, ${_.get(color, 'b') || 0}, ${_.get(color, 'a') || 1})`;
  }

  const arrowColor = {
    backgroundColor: colorStyle(activeBackgroundColor),
    color: colorStyle(activeForegroundColor)
  }

  const hoverArrowColor = {
    backgroundColor: colorStyle(hoverBackgroundColor),
    color: colorStyle(hoverForegroundColor)
  }

  return (
    <ErrorHandler>
      <InfiniteScroll id={id} editor={rest.editor} pagination={false}>
        {loading &&
          <LoadingCard initial={empty} />
        }

        {!empty &&
          <Carousel
            className={styles.carousel}
            centerMode
            centerSlidePercentage={properties.layout.cardWidth || 80}
            showThumbs={false}
            infiniteLoop={properties.layout.infinite}
            showArrows={properties.layout.arrows}
            showStatus={false}
            showIndicators={false}
            selectedItem={state.selected}
            autoPlay = {_.get(properties, 'layout.autoPlay', false)}
            interval = {autoPlayDuration}
            renderArrowNext={(onClick, hasNext) => {
              return (
                <Button className={cx(styles.arrow, styles.next, {
                  [styles.show]: hasNext
                })}
                  icon circular size='big'
                  disabled={!hasNext}
                  onClick={onClick}
                  onMouseEnter={() => {
                    setState((state) => {
                      return {
                        ...state,
                        prevHovered: true
                      };
                    });
                  }}
                  onMouseLeave={() => {
                    setState((state) => {
                      return {
                        ...state,
                        prevHovered: false
                      };
                    });
                  }}
                  style={!state.prevHovered ? arrowColor : hoverArrowColor}
                >
                  <Icon name="chevron right" />
                </Button>
              );
            }}
            renderArrowPrev={(onClick, hasPrev) => {
              return (
                <Button className={cx(styles.arrow, styles.previous, {
                  [styles.show]: hasPrev
                })}
                  icon circular size='big'
                  disabled={!hasPrev}
                  onClick={onClick}
                  style={!state.nextHovered ? arrowColor : hoverArrowColor}
                  onMouseEnter={() => {
                    setState((state) => {
                      return {
                        ...state,
                        nextHovered: true
                      };
                    });
                  }}
                  onMouseLeave={() => {
                    setState((state) => {
                      return {
                        ...state,
                        nextHovered: false
                      };
                    });
                  }}
                >
                  <Icon name="chevron left" />
                </Button>
              );
            }}
          >
            {React.Children.map(children, (child, index) => {
              return <>{React.cloneElement(child)}</>
            })}
          </Carousel>
        }
      </InfiniteScroll>
    </ErrorHandler>

  );
}

class ErrorHandler extends React.Component {
  componentDidCatch(error) {
    console.log(error);
  }

  render() {
    return this.props.children;
  }
}

function InfiniteScroll({ id, children, editor, pagination, disabled }) {
  const [height, setHeight] = useState(0);
  const [initialised, setInitialised] = useState(false);
  const [visibile, setVisible] = useState(false);

  const dispatch = useDispatch();

  var assetsState = useSelector(state => {
    var defaultState = {
      items: []
    };

    var assetsState = defaultState;
    const editorContext = state.widgetsEditor.editors[editor];
    if (editorContext) {
      assetsState = editorContext.widgetAssetsById[id];
    }

    return assetsState || defaultState;
  });

  const filters = useSelector(state => _.get(state, `widgetsEditor.editors[${editor}].widgetsById[${id}].selectedFilters`));

  useEffect(() => {
    if (editor && visibile && assetsState.items.length == 0 && !initialised) {
      dispatch(fetchAssetsForWidget(id, 0, editor));
      setInitialised(true);
    }
  }, [visibile, pagination]);

  useEffect(() => {
    if (filters) {
      dispatch(fetchAssetsForWidget(id, 0, editor));
    }
  }, [filters]);

  const handleVisibility = (e, { calculations }) => {
    setHeight(calculations.height + 100);

    if (calculations.onScreen) {
      setVisible(true);
    }

    if (calculations.bottomPassed && !assetsState.loading && initialised && assetsState.pagination) {
      const nextPage = assetsState.pagination.currentPage + 1;
      const totalPage = assetsState.pagination.totalPages;
      const paginate = pagination && nextPage > 0 && nextPage <= totalPage;
      if (paginate && !disabled) {
        dispatch(fetchAssetsForWidget(id, nextPage, editor));
      }
    }
  }

  return (
    <>
      <Visibility
        className={styles.infiniteLoader}
        updateOn='repaint'
        fireOnMount
        once
        offset={height}
        onUpdate={handleVisibility}>
        {children}
      </Visibility>
    </>
  );
}

function LoadingCard({ initial }) {
  return (
    <div className={cx(styles.loader, {
      [styles.initial]: initial
    })}>
      <Loader active content='Loading assets...' />
    </div>
  );
}

function EmptyView(props) {
  return (
    <Segment basic>
      <Header as='h3' icon textAlign='center'>
        <Icon name='settings' size='small' />
        No Assets
        <Header.Subheader>
          You can edit the condition in properties under content or {' '}
          <ContentModal trigger={
            <a href='#'>Click here</a>
          } widget={props.widget} editor={props.editor} entityReference={props.entityReference} />
        </Header.Subheader>
      </Header>
    </Segment>
  );
}

AssetsGrid.Layout = LayoutView;

AssetsGrid.QuickAction = function QuickAction(props) {
  const { entityReference } = React.useContext(WidgetContext);

  const fetching = useSelector(state => {
    return _.get(state, `widgetsEditor.editors[${props.editor}].widgetsById[${props.widget.id}].content.fetching`);
  });

  return (
    <ContentModal entityReference={entityReference} trigger={
      <Widget.Action className={cx(styles.quickAction, { [styles.loading]: fetching })}>
        {fetching ? 'loading...' : 'Edit Content'}
      </Widget.Action>
    } widget={props.widget} editor={props.editor} />
  );
}

export function useAddContent(id, editor, onClose) {
  const dispatch = useDispatch();

  const contentSelection = useSelector(state => {

    const editorContext = state.contentSelection.editors[editor];

    return editorContext;
  });

  if (!editor || !contentSelection) {
    return null;
  }

  let type = contentSelection.type;

  let updatedFilters = [];
  let assetsRequest = [];

  if (type === 'FILTER') {
    updatedFilters = (contentSelection.filters || []).map(filter => {
      var property = filter.property;
      var operator = filter.operator;
      var value = filter.value;

      if (filter.property.type === 'asset') {
        value = (filter.value || []).map(item => item.id);
      }

      return {
        property: property,
        operator: operator,
        value: value
      }
    });
  }

  if (type === 'STATIC') {
    assetsRequest = (contentSelection.assets || []).map(asset => {
      return {
        id: asset.id,
        name: asset.isNameSet ? asset.name : null,
        summary: asset.isSummarySet ? asset.summary : null,
        icon: asset.isIconSet ? asset.icon : null
      }
    })
  }

  dispatch(updateWidgetContent(
    id,
    {
      content: {
        contentType: type,
        filters: updatedFilters,
        list: contentSelection.list ? contentSelection.list.id : null,
        assets: assetsRequest
      }
    },
    (response) => {
      onClose()
      dispatch(fetchAssetsForWidget(id, 0, editor));
    }
  ));
}

function FilterMenu({ id, editor, filters }) {
  const dispatch = useDispatch();

  const fields = useSelector(state => {
    var fields = _.get(filters, 'fields') || [];
    const customFields = state.customFields.items || [];

    return fields.filter(item => {
      return customFields.find(field => field.id === item.id);
    });
  });

  if (filters) {
    filters.fields = fields
  }

  const handleChange = (field, value) => {
    var updatedFilters = {
      property: {
        type: "custom_field",
        fieldId: field.id
      },
      operator: "is",
      value: null
    }

    if (value) {
      updatedFilters = {
        ...updatedFilters,
        value: [value.id]
      }
    }

    dispatch(updateFiltersInWidgetAssets(id, editor, updatedFilters));
  }

  return (
    <div className={styles.filters}>
      {fields.map(field => {
        return (
          <FilterMenuItem
            key={field.id}
            field={field}
            properties={filters}
            onChange={handleChange}
          />
        )
      })}
    </div>
  );
}

function SearchBar({ id, editor }) {
  const dispatch = useDispatch();

  const [input, setInput] = useState('');

  const handleOnChange = (e) => {
    setInput(e.target.value);
    dispatch(updateSearchQueryInWidgetAssets(id, editor, e.target.value));
  }
  const handleSearch = () => {
    dispatch(fetchAssetsForWidget(id, 0, editor));
  }
  const handleKeyPressed = (e) => {
    if (e.key === "Enter") {
      dispatch(fetchAssetsForWidget(id, 0, editor));
    }
  }

  return (
    <div>
      <Input fluid iconPosition='left' placeholder='Type here to Search' className={styles.search} action onKeyPress={handleKeyPressed}>
        <Icon name='search'/>
        <input type='text' className={styles.searchInput} onChange={handleOnChange}/>
        <Button content='Search' type='submit' onClick={handleSearch}/>
      </Input>
    </div>
  )
}
function FilterMenuItem({ id, editor, field, properties, onChange }) {
  const [selected, setSelected] = useState(null);

  const customFields = useSelector(state => _.get(state, 'customFields.items', []));
  const customField = customFields.find(item => item.id === field.id);

  const handleChange = (e, data) => {
    setSelected(data.value);
    onChange(field, data.value);
  }

  var options = (field.options || []).map(option => {
    return {
      key: option.id,
      value: option,
      content: option.value
    }
  });

  const sortedOptions = options.sort((a, b) => {
    const aIndex = customField.options.findIndex(entry => entry.id === a.key);
    const bIndex = customField.options.findIndex(entry => entry.id === b.key);
    return aIndex - bIndex;
  });

  options = [{
    key: 'all',
    value: null,
    content: 'All'
  }, ...sortedOptions];

  const [hover, setHover] = useState(false);

  const regularProperties = _.get(properties, 'regular', {});
  const regularStyle = getStyles(regularProperties);

  const activeProperties = _.get(properties, 'clicked', {});
  const activeStyle = getStyles(activeProperties);

  const hoverProperties = _.get(properties, 'hover', {});
  const hoverStyle = getStyles(hoverProperties);

  const handleMouseEnter = () => {
    setHover(true);
  }

  const handleMouseLeave = () => {
    setHover(false);
  }

  const renderMenuStyle = (active) => {
    var style = regularStyle;
    if (active && !hover) {
      style = {
        ...style,
        ...activeStyle,
      };
    }

    if (active && hover) {
      style = {
        ...style,
        ...activeStyle,
        ...hoverStyle
      }
    }

    if (hover) {
      style = {
        ...style,
        ...hoverStyle
      };
    }

    return style;
  }

  return (
    <Dropdown
      style={renderMenuStyle(true)}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      additionLabel={field.id}
      options={options}
      className={cx(styles.filterItem, {
        [styles.singlefilterItem]: properties.fields.length == 1
      })}
      trigger={
        <div className={cx(styles.filterMenuItem, {
          [styles.selected]: selected != null,
          [styles.singlefilterMenuItem]: properties.fields.length == 1
        })}>
          <div className={styles.label}>{field.name}</div>
          <div className={styles.hidden}>{field.name}</div>
          <div className={styles.value}>{_.get(selected, 'value')}</div>
        </div>
      }
      simple
      item
      closeOnChange
      onChange={handleChange}
    />
  );
}

function PaginationButton({ id, editor, properties }) {
  const dispatch = useDispatch();

  var assetsState = useSelector(state => {
    var defaultState = {
      items: []
    };

    var assetsState = defaultState;
    const editorContext = state.widgetsEditor.editors[editor];
    if (editorContext) {
      assetsState = editorContext.widgetAssetsById[id];
    }

    return assetsState || defaultState;
  });

  if (!assetsState.pagination) {
    return false;
  }

  const nextPage = assetsState.pagination.currentPage + 1;
  const totalPage = assetsState.pagination.totalPages;
  const paginate = nextPage > 0 && nextPage < totalPage;

  const handleLoadMore = () => {
    if (paginate) {
      dispatch(fetchAssetsForWidget(id, nextPage, editor));
    }
  }

  if (!paginate) {
    return false;
  }

  return (
    <Button className={styles.loadMore}
      disabled={assetsState.loading}
      onClick={handleLoadMore}>
      {assetsState.loading ? 'Loading content...' : 'Load more content'}
    </Button>
  );
}