import React, {useState, Fragment} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {updateProperties, updateWidget} from 'actions/widgetEditor'

import _ from 'lodash';
import cx from 'classnames';

import {
  Button as SemanticButton,
  Modal,
  Icon,
  Grid
} from 'semantic-ui-react';

import { Editor } from '@tinymce/tinymce-react';
import { Widget, getStyles } from '../widget.jsx';
import { WidgetRegistry } from '../registry.jsx';
import { Property } from 'components/Properties';
import { QuickSettings, QuickSettingsProperty } from 'components/QuickSettings';
import styles from './modal.module.scss';

export const ModalWidget = (props) => {
  const dispatch = useDispatch();
  var widgets = props.widgets || [];
  const properties = props.properties;


  const widgetStyles = getStyles(properties);
  const size = _.get(properties, 'size', 'small');

  return (
    <div 
      className={cx('ui', {[size]: true}, 'modal', styles.modal, props.className)}
      id={`widget_${props.id}`}
      onMouseOver={props.onMouseOver}
      onMouseOut={props.onMouseOut}
      onClick={props.onClick}
    >
      {properties.close && 
        <i aria-hidden="true" className="close icon"></i>
      }
      
      <div className={cx('content', 'scrolling')} style={widgetStyles}>
        <Widget.Children widgets={props.widgets} editor={props.editor} editable={props.editable} />
      </div>
      {props.children}
    </div>
  );
}

function ModalQuickSettings(props) {
  const dispatch = useDispatch();

  const [selected, setSelected] = useState('action');

  const menuItems = [
    { id: 'basic', title: 'Basic', icon: 'icon-custom' },
    { id: 'spacing', title: 'Spacing', icon: 'icon-spacing' },
    { id: 'background', title: 'Background', icon: 'icon-background' },
    { id: 'border', title: 'Border', icon: 'icon-border' },
    { id: 'advanced', title: 'Advanced', icon: 'icon-custom' }
  ]

  const handleMenuClick = (item) => {
    setSelected(item);
  }

  var widget = useSelector(state => {
    const editorContext = state.widgetsEditor.editors[props.editor];

    if (editorContext) {
      return editorContext.widgetsById[props.id];
    }

    return null;
  });
  if (!widget) {
    return false;
  }

  var properties = widget.properties;

  const handleChange = (changeRequest) => {
    dispatch(updateProperties({
      id: widget.id,
      change: changeRequest,
      context: props.editor
    }));
  }

  const handleBackgroundImageChange = (media, type) => {
    var changeRequest = { ...widget };
    _.set(changeRequest, 'properties.background.upload', {
      type: type,
      data: media
    });

    changeRequest = WidgetRegistry.processRequest([changeRequest])[0];
    dispatch(updateWidget(props.id, changeRequest, props.editor));
  }

  const handleCssChange = (css) => {
    var changeRequest = { ...widget };
    _.set(changeRequest, 'properties.css', css);

    changeRequest = WidgetRegistry.processRequest([changeRequest])[0];
    dispatch(updateWidget(props.id, changeRequest, props.editor));
  }

  return (
    <QuickSettings menuItems={menuItems} selected={selected} onMenuClick={handleMenuClick} >
      {selected === 'basic' && 
        <>
          <QuickSettingsProperty.Dropdown title='Size'
            tooltip='Size of the popup'
            placeholder='Size of the popup'
            selection
            options={[
              { key: 'mini', value: 'mini', text: 'Mini' },
              { key: 'tiny', value: 'tiny', text: 'Tiny' },
              { key: 'small', value: 'small', text: 'Small' },
              { key: 'large', value: 'large', text: 'Large' },
              { key: 'fullscreen', value: 'fullscreen', text: 'Full Screen' },
            ]}
            value={properties.size}
            onChange={(e, data) => {
              handleChange({
                size: data.value
              })
            }}
          />
          <QuickSettingsProperty.Switch
            title={'Close Button'}
            value={properties.close}
            onChange={() => {
              handleChange({
                close: !properties.close
              })
            }}
          />
        </>
      }

      {selected === 'spacing' &&
        <>
          <QuickSettingsProperty.Spacing
            title='Margin'
            value={properties.margin}
            onChange={(margin) => {
              handleChange({
                margin: margin
              });
            }}
          />
          <QuickSettingsProperty.Spacing
            title='Padding'
            value={properties.padding}
            onChange={(padding) => {
              handleChange({
                padding: padding
              });
            }}
          />
        </>
      }

      {selected === "background" &&
        <QuickSettingsProperty.Background expanded
          value={properties.background}
          onChange={(background) => {
            handleChange({
              background: background
            });
          }}
          onImageChange={(image, type) => {
            handleBackgroundImageChange(image, type);
          }}
        />
      }

      {selected === 'border' &&
        <Property.Border expanded value={properties.border} onChange={(border) => {
          handleChange({
            border: border
          });
        }}
        />
      }

      {selected === 'advanced' && 
        <Property.CSS title='Stylesheets' value={properties.css} onChange={handleCssChange} />
      }
    </QuickSettings>
  )
}

Modal.QuickSettings = QuickSettingsProperty;

function ModalProperty(props){
  const dispatch = useDispatch();

  var widget = useSelector(state => {
    const editorContext = state.widgetsEditor.editors[props.editor];

    if (editorContext) {
      return editorContext.widgetsById[props.id];
    }

    return null;
  });
  if(!widget){
    return false;
  }

  var properties = widget.properties;

  const handleChange = (changeRequest) => {
    dispatch(updateProperties({
      id: widget.id,
      change: changeRequest,
      context: props.editor
    }));
  }

  const handleBackgroundImageChange = (media, type) => {
    var changeRequest = {...widget};
    _.set(changeRequest, 'properties.background.upload', {
      type: type,
      data: media
    });

    changeRequest  = WidgetRegistry.processRequest([changeRequest])[0];
    dispatch(updateWidget(props.id, changeRequest, props.editor));
  }

  const handleCssChange = (css) => {
    var changeRequest = {...widget};
    _.set(changeRequest, 'properties.css', css);

    changeRequest  = WidgetRegistry.processRequest([changeRequest])[0];
    dispatch(updateWidget(props.id, changeRequest, props.editor));
  }
  
  return (
    <Fragment>
      <Property.Section title='Basic' expanded>
        <Property.Dropdown title='Size' 
          tooltip='Size of the popup'
          placeholder='Size of the popup'
          selection 
          options={[
            { key: 'mini', value: 'mini', text: 'Mini' },
            { key: 'tiny', value: 'tiny', text: 'Tiny' },
            { key: 'small', value: 'small', text: 'Small' },
            { key: 'large', value: 'large', text: 'Large' },
            { key: 'fullscreen', value: 'fullscreen', text: 'Full Screen' },
          ]}
          value={properties.size}
          onChange={(e, data) => {
            handleChange({
              size: data.value
            })
          }}
        />
        <Property.Switch 
          title={'Close Button'}
          value={properties.close}
          onChange = {() => {
            handleChange({
              close: !properties.close
            })
          }}
        />
      </Property.Section>
      <Property.Section title='Spacing'>
        <Property.Spacing 
          title='Margin' 
          value={properties.margin}
          onChange={(margin) => {
            handleChange({
              margin: margin
            });
          }}
        />
        <Property.Spacing 
          title='Padding' 
          value={properties.padding}
          onChange={(padding) => {
            handleChange({
              padding: padding
            });
          }}
        />
      </Property.Section>
      
      <Property.Background expanded 
        value={properties.background} 
        onChange={(background) => {
          handleChange({
            background: background
          });
        }} 
        onImageChange={(image, type) => {
          handleBackgroundImageChange(image, type);
        }} 
      />

      <Property.Border expanded value={properties.border} onChange={(border) => {
          handleChange({
            border: border
          });
        }} 
      />

      <Property.Section title='Advanced' expanded>
        <Property.CSS title='Stylesheets' value={properties.css} onChange={handleCssChange} />
      </Property.Section>
    </Fragment>
  );
}

ModalWidget.Property = ModalProperty;