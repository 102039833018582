import React from 'react';

import {
  Grid,
  Header,
  Segment,
  Container,
  Image
} from 'semantic-ui-react';

import dateformat from 'dateformat';

import styles from './anonymous.module.scss';

export default function AnonymousPageView(props) { 

  return (
    <Container fluid className={styles.signin}>
      <Grid columns={2} className={styles.grid}>
        <Grid.Column width={10} stretched>
          <Segment basic className={styles.left}>
            <Image src="/images/logo_landscape.svg" size="small" centered />
            <Header as="h2" textAlign="left" className={styles.titleText}>
              The canvas for your masterpiece is calling you and you MUST go at once.
              </Header>
            <Image className={styles.defaultBg} src="/images/default_bg.svg" size="small" centered />
            <Header as="h2" textAlign="left" className={styles.bottomText}>
              Drag it, Drop it, Snip it or Rip it. <br /> With Cleverstory's endless possibilites, give your content the story it deserves.
              </Header>
          </Segment>
        </Grid.Column>
        <Grid.Column width={6} stretched>
          <div className={styles.formContainer}>
            {props.children}
          </div>
          <div className={styles.footer}>
            &copy; {dateformat(new Date(), 'yyyy')} PAPERFLITE
          </div>
        </Grid.Column>
      </Grid>
    </Container>
  )
}