import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from "react-redux";
import _ from 'lodash';

import { useDrop } from 'react-dnd';
import { PALETTE_ITEM, WIDGET_ITEM } from 'constants/ItemTypes.jsx';

import {showPlaceholderAtIndex, rearrangeWidgets, invalidateWidgetPlaceholder} from 'actions/widgetEditor';
import { WidgetContext } from 'pages/pages/editor/editor.jsx';
import { getClosestColumnForWidget } from './widget.helper';
import { DEFAULT_ACCEPT_TARGETS } from 'constants/ItemTypes';

export function calculateDropRequest(dropRequest, horizontal) {
  if(horizontal){
    return _calculateHorizontalDropRequest(dropRequest);
  }else{
    return _calculateVerticalDropRequest(dropRequest);
  }
}

export function calculateDropAnchor(dropRequest) {
  var verticalAnchor = _calculateVerticalAnchor(dropRequest);
  var horizontalAnchor = _calculateHorizontalAnchor(dropRequest);
  
  var anchor = {
    ...verticalAnchor,
    ...horizontalAnchor
  }

  return anchor;
}

function _calculateHorizontalAnchor(dropRequest){
  const clientRect = dropRequest.clientRect;
  const offset = dropRequest.offset;

  const width = clientRect.right - clientRect.left;
  var tolerance = Math.min(width * 0.5, 50);
  tolerance = Math.max(tolerance, 10);

  // Get pixels to the left
  const hoverClientX = offset.x - clientRect.left;

  var left = false;
  var right = false;

  if(width - hoverClientX <= tolerance){
    right = true;
  }else if(hoverClientX <= tolerance){
    left = true;
  } 

  return {
    left, right
  }
}

function _calculateVerticalAnchor(dropRequest){
  const clientRect = dropRequest.clientRect;
  const offset = dropRequest.offset;

  const height = clientRect.bottom - clientRect.top;
  var tolerance = Math.min(height * 0.5, 100);
  //tolerance = Math.max(tolerance, 10);
  
  const hoverClientY = offset.y - clientRect.top;

  var top = false;
  var bottom = false;

  if(height - hoverClientY <= tolerance){
    bottom = true;
  }else if(hoverClientY <= tolerance){
    top = true;
  } 

  return {
    top, bottom
  }
}


function _calculateVerticalDropRequest(dropRequest){
  const clientRect = dropRequest.clientRect;
  const offset = dropRequest.offset;
  const hoverIndex = dropRequest.hoverIndex;
  const item = {...dropRequest.item};
  var request = {...item.request};

  const hoverMiddleY = (clientRect.bottom - clientRect.top) / 2
  
  // Get pixels to the top
  const hoverClientY = offset.y - clientRect.top;
  
  // Only perform the move when the mouse has crossed half of the items height
  // When dragging downwards, only move when the cursor is below 50%
  // When dragging upwards, only move when the cursor is above 50%
  // Dragging downwards
  const direction = hoverClientY < hoverMiddleY ? 'up' : 'down';
  request.index = hoverIndex;
  request.direction = direction;

  if (request.index < hoverIndex && hoverClientY < hoverMiddleY) {
    return item.request;
  }
  
  // Dragging upwards
  if (request.index > hoverIndex && hoverClientY > hoverMiddleY) {
    return item.request;
  }

  return request;
}

function _calculateHorizontalDropRequest(dropRequest){
  const clientRect = dropRequest.clientRect;
  const offset = dropRequest.offset;
  const hoverIndex = dropRequest.hoverIndex;
  const item = {...dropRequest.item};
  var request = {...item.request};

  // Get vertical middle
  const hoverMiddleX = (clientRect.right - clientRect.left) / 2

  // Get pixels to the left
  const hoverClientX = offset.x - clientRect.left
  // Only perform the move when the mouse has crossed half of the items height
  // When dragging downwards, only move when the cursor is below 50%
  // When dragging upwards, only move when the cursor is above 50%
  // Dragging downwards
  const direction = hoverClientX < hoverMiddleX ? 'left' : 'right';
  request.index = hoverIndex;
  request.direction = direction;

  return request;
}

export function useWidgetDrop(props, ref, dropConfig, dependencies){
  const dispatch = useDispatch();
  
  const editorContext = useSelector(state => {
    return state.widgetsEditor.editors[props.editor];
  });

  const { entityReference, onPaletteItemDrop } = React.useContext(WidgetContext);

  const defaultDropConfig = {
    acceptTargets: (type) => {
      return _.includes(DEFAULT_ACCEPT_TARGETS, type);
    },
    drop: (request) => {
      var dropTarget = props.parent;
      var dropIndex = request.index;
      var anchor  = request.anchor || {};
      const clientRect = request.clientRect;

      var direction = "down";
      var identifedTarget = false;
      
      if(anchor.left || anchor.right){
        const columnWidget = getClosestColumnForWidget(editorContext, props.parent);
        if(columnWidget){
          const rowWidget = editorContext.widgetsById[columnWidget.parent];
          const droppable = _.get(rowWidget, 'capabilities.droppable', true);

          if(droppable){
            const columnIndex = _.indexOf(rowWidget.widgets, columnWidget.id);

            if(columnIndex >= 0){
              dropTarget = rowWidget.id;
              dropIndex = columnIndex;
              direction = anchor.right ? 'right' : 'left';

              identifedTarget = true;
            }
          }
        }
      }
      
      if(!identifedTarget && (anchor.top || anchor.bottom)){
        const parentWidget = editorContext.widgetsById[props.parent];
        identifedTarget = _.get(parentWidget, 'capabilities.droppable', true);

        direction = anchor.bottom ? 'down' : 'up';
      }

      if(!identifedTarget){
        return null;
      }
      
      return{
        target: dropTarget,
        index: dropIndex,
        direction: direction,
        clientRect: clientRect
      }
    }
  }

  dropConfig = {
    ...defaultDropConfig,
    ...dropConfig
  }

  const [{ isOver }, drop] = useDrop(() => ({
    accept: [PALETTE_ITEM, WIDGET_ITEM, 'resize_anchor'],
    canDrop(item, monitor){

      var itemType = monitor.getItemType();
      if(itemType === WIDGET_ITEM){
        var widgetType = _.get(item, 'content.type');
        if(dropConfig.acceptTargets){
          return dropConfig.acceptTargets(widgetType);
        }
        return false;
      }else if(itemType === PALETTE_ITEM){
        var widgetType = _.get(item, 'content[0].type');
        if(dropConfig.acceptTargets){
          return dropConfig.acceptTargets(widgetType);
        }

        return false;
      }

      return false;
    },
    hover: _.throttle((item, monitor) => {
      if (!ref.current) {
        return;
      }

      if(!monitor.canDrop()){
        return;
      }

      const isOver = monitor.isOver({ shallow: true });
      if(!isOver){
        //console.log(`Ignoring drag hover for widget ${props.id} -  ${props.type} - ${_.get(props, 'content')}`);
        return;
      }else{
        console.log(`Currently drag hover for widget ${props.id} -  ${props.type} - ${_.get(props, 'content')}`);
      }

      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      // Determine mouse position
      const clientOffset = monitor.getClientOffset();

      var dropRequest = {
        clientRect: hoverBoundingRect,
        offset: clientOffset,
        hoverIndex: props.index,
        item: item
      };

      const anchor = calculateDropAnchor(dropRequest);
    
      var request = {
        ...item.request
      };

      request.index = props.index;
      request.anchor = anchor;
      request.parent = props.parent;
      request.clientRect = hoverBoundingRect; 
      
      if(_.isEqual(request, item.request)){
        return;
      }

      const processedRequest = dropConfig.drop(request);
      if(!processedRequest){
        console.log("Ignoring drop request");
        dispatch(invalidateWidgetPlaceholder(props.editor));
        return;
      }

      const {target, index, direction, clientRect} = processedRequest;
      const insertBefore = direction == 'up' || direction == 'left';

      dispatch(showPlaceholderAtIndex(props.editor, target, index, {
        insertBefore: insertBefore,
        direction: direction,
        rect: clientRect
      }));
      
      item.request = request;
    }, 100),
		collect: monitor => ({
			isOver: !!monitor.isOver(),
		}),
  }), [props.editor, props.parent, props.index, dependencies]);

  return drop;
}