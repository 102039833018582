import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { fetchForms, createForm, cloneForm } from 'actions/forms';

import _ from 'lodash';
import cx from 'classnames';
import dateformat from 'dateformat';

import {
  Route,
  useRouteMatch,
  Switch,
  useHistory
} from "react-router-dom";

import {
  Button,
  Icon,
  Image,
  Placeholder,
} from 'semantic-ui-react';

import Tooltip from 'components/Tooltip';

import styles from './form.module.scss';

import {
  Visibility,
  Grid
} from 'semantic-ui-react';

export default function FormPage() {
  let { path, url } = useRouteMatch();
  const history = useHistory();

  const createdForm = useSelector(state => _.get(state, 'forms.create.item.id'));
  const clonedForm = useSelector(state => _.get(state, 'forms.clone.item.id'));

  useEffect(() => {
    if (createdForm) {
      history.push(`${url}/${createdForm}/edit`);
    }
  }, [createdForm])

  useEffect(() => {
    if (clonedForm) {
      history.push(`${url}/${clonedForm}/edit`);
    }
  }, [clonedForm])

  return (
    <Switch>
      <Grid columns="equal" stretched className={styles.formContent}>
        <Grid.Row>
          <Grid.Column>
            <Switch>
              <Route path={`${path}`} exact>
                <div className={styles.details}>
                  <AllForms />
                </div>
              </Route>
            </Switch>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Switch>
  );
}

function AllForms(props) {
  const dispatch = useDispatch();

  const forms = useSelector(state => _.get(state, 'forms.items', []));
  const pagination = useSelector(state => _.get(state, "forms.pagination", {}));
  const currentPage = _.get(pagination, "currentPage", 0);
  const totalPages = _.get(pagination, "totalPages", 0);
  const loading = useSelector(state => _.get(state, "forms.loading", false));
  const placeholder = loading && currentPage < 0;
  const integrations = useSelector(state => _.get(state, 'integrations.activated.items', []));

  const pardotIntegration = integrations.filter(item => {
    return item != null;
  }).find(item => {
    return item.type === 'pardot';
  })

  const hubspotIntegration = integrations.filter(item => {
    return item != null;
  }).find(item => {
    return item.type === 'hubspot';
  })

  const marketoIntegration = integrations.filter(item => {
    return item != null;
  }).find(item => {
    return item.type === 'marketo';
  })


  console.log(hubspotIntegration);

  useEffect(() => {
    dispatch(fetchForms(0, 20, 'name', 'ASC', false));
  }, [])

  const loadMoreForms = () => {
    if (loading || currentPage === totalPages - 1) {
      return null;
    }

    dispatch(fetchForms(currentPage + 1, 20, "name", "ASC", false));
  }

  return (
    <div className={cx(styles.forms, styles.all)}>
      <h1 className={styles.header}>Forms</h1>

      <Visibility className={styles.inner} onBottomVisible={loadMoreForms} continuous fireOnMount={false}>
        <BlankForm />

        {pardotIntegration &&
          <PardotForm />}

        {hubspotIntegration &&
          <HubspotForm />
        }

        {marketoIntegration &&
          <MarketoForm />
        }

        <ExternalForm />

        {placeholder && _.times(5, (index) => {
          return <FormPlaceholderCard key={index} className={styles.element} />
        })}

        {!placeholder && forms.map(form => {
          return <FormCard key={form.id} className={styles.element} value={form} />
        })}
      </Visibility>
    </div>
  )
}

export function BlankForm(props) {
  const dispatch = useDispatch();

  const creating = useSelector(state => _.get(state, 'forms.create.creating'));
  const formType = useSelector(state => _.get(state, 'forms.create.item.type', null));
  const creatingCurrent = useSelector(state => _.get(state, 'forms.create.item.template')) == null && creating && formType === 'cleverstory';

  const handleCreateClicked = () => {
    var request = {
      name: "Untitled Form",
      widgets: [formWidget],
      reference: {

      },
      type: 'cleverstory'
    }

    dispatch(createForm(request));
  }

  return (
    <>
    <div className={cx(styles.template, styles.blank, props.className, {
      [styles.creating]: creatingCurrent
    })}>
      <div className={styles.image} onClick={handleCreateClicked}>
        <div className="image" />
        <div className={styles.add}>{creatingCurrent ? 'Creating...' : '+'}</div>
      </div>
      <div className={styles.details}>
        <div className={styles.left}>
          <div className={styles.name}>Blank</div>
          <div className={styles.category}>Start from scratch</div>
        </div>
      </div>
    </div>
    </>
  );
}

export function PardotForm(props) {
  const dispatch = useDispatch();

  const creating = useSelector(state => _.get(state, 'forms.create.creating'));
  const formType = useSelector(state => _.get(state, 'forms.create.item.type', null));
  const creatingCurrent = useSelector(state => _.get(state, 'forms.create.item.template')) == null && creating && formType === 'pardot';

  const handleCreateClicked = () => {
    var request = {
      name: "Untitled Pardot Form",
      widgets: pardotFormWidgets,
      reference: {

      },
      type: 'pardot'
    }

    dispatch(createForm(request));
  }

  return (
    <>
    <div className={cx(styles.template, styles.blank, props.className, {
      [styles.creating]: creatingCurrent
    })}>
      <div className={styles.image} onClick={handleCreateClicked}>
        <div className="image" />
        <div className={styles.add}>{creatingCurrent ? 'Creating...' : '+'}</div>
      </div>
      <div className={styles.details}>
        <div className={styles.left}>
          <div className={styles.name}>Pardot Form</div>
          <div className={styles.category}>Create Pardot Form</div>
        </div>
      </div>
    </div>
    </>
  );
}

export function HubspotForm(props) {
  const dispatch = useDispatch();

  const creating = useSelector(state => _.get(state, 'forms.create.creating'));
  const creatingHubspotForm = useSelector(state => _.get(state, 'forms.create.item.hubspotForm', false));
  const creatingCurrent = useSelector(state => _.get(state, 'forms.create.item.template')) == null && creating && creatingHubspotForm;

  const handleCreateClicked = () => {
    var request = {
      name: "Untitled Hubspot Form",
      widgets: hubspotFormWidgets,
      reference: {

      },
      hubspotForm: true
    }

    dispatch(createForm(request));
  }

  return (
    <>
    <div className={cx(styles.template, styles.blank, props.className, {
      [styles.creating]: creatingCurrent
    })}>
      <div className={styles.image} onClick={handleCreateClicked}>
        <div className="image" />
        <div className={styles.add}>{creatingCurrent ? 'Creating...' : '+'}</div>
      </div>
      <div className={styles.details}>
        <div className={styles.left}>
          <div className={styles.name}>Hubspot Form</div>
          <div className={styles.category}>Create Hubspot Form</div>
        </div>
      </div>
    </div>
    </>
  );
}

export function MarketoForm(props) {
  const dispatch = useDispatch();

  const creating = useSelector(state => _.get(state, 'forms.create.creating'));
  const creatingMarketoForm = useSelector(state => _.get(state, 'forms.create.item.marketoForm', false));
  const creatingCurrent = useSelector(state => _.get(state, 'forms.create.item.template')) == null && creating && creatingMarketoForm;

  const handleCreateClicked = () => {
    var request = {
      name: "Untitled Marketo Form",
      widgets: marketoFormWidgets,
      reference: {

      },
      marketoForm: true
    }

    dispatch(createForm(request));
  }

  return (
    <>
    <div className={cx(styles.template, styles.blank, props.className, {
      [styles.creating]: creatingCurrent
    })}>
      <div className={styles.image} onClick={handleCreateClicked}>
        <div className="image" />
        <div className={styles.add}>{creatingCurrent ? 'Creating...' : '+'}</div>
      </div>
      <div className={styles.details}>
        <div className={styles.left}>
          <div className={styles.name}>Marketo Form</div>
          <div className={styles.category}>Create Marketo Form</div>
        </div>
      </div>
    </div>
    </>
  );
}


export function ExternalForm(props) {
  const dispatch = useDispatch();

  const creating = useSelector(state => _.get(state, 'forms.create.creating'));
  const formType = useSelector(state => _.get(state, 'forms.create.item.type', null));
  const creatingCurrent = useSelector(state => _.get(state, 'forms.create.item.template')) == null && creating && formType === 'external';

  const handleCreateClicked = () => {
    var request = {
      name: "Untitled External Form",
      widgets: externalFormWidgets,
      reference: {

      },
      type: 'external'
    }

    dispatch(createForm(request));
  }

  return (
    <>
    <div className={cx(styles.template, styles.blank, props.className, {
      [styles.creating]: creatingCurrent
    })}>
      <div className={styles.image} onClick={handleCreateClicked}>
        <div className="image" />
        <div className={styles.add}>{creatingCurrent ? 'Creating...' : '+'}</div>
      </div>
      <div className={styles.details}>
        <div className={styles.left}>
          <div className={styles.name}>External Form</div>
          <div className={styles.category}>Create External Form</div>
        </div>
      </div>
    </div>
    </>
  );
}

export function FormCard(props) {
  const dispatch = useDispatch();
  const history = useHistory();

  let { url } = useRouteMatch();
  const form = props.value || {};

  const cloning = useSelector(state => _.get(state, 'forms.clone.cloning'));
  const cloningCurrent = useSelector(state => _.get(state, 'forms.clone.element')) === form.id && cloning;

  const _handleEditForm = () => {
    history.push(`${url}/${form.id}/edit`);
  }

  const _handleCloneForm = () => {
    if (cloning) {
      return;
    }

    dispatch(cloneForm(form.id, {
      name: `Clone of ${form.name}`
    }));
  }

  const author = () => {
    const checkDefault = (value, defaultValue) => value == null ? defaultValue : value;

    var firstName = checkDefault(_.get(form, 'createdBy.firstName'), '');
    var lastName = checkDefault(_.get(form, 'createdBy.lastName'), '');

    return `${firstName} ${lastName}`;
  }

  return (
    <div className={cx(styles.page, props.className, {
      [styles.cloning]: cloningCurrent
    })}>
      <div className={styles.image}>
        <div className={styles.analytics}>
          <div className={styles.date}>
            <strong>Published:</strong>
            <span>{dateformat(form.modifiedDate, 'dd mmm yyyy')}</span>
          </div>
          <div className={styles.date}>
            <strong>Last Update:</strong>
            <span>{dateformat(form.modifiedDate, 'dd mmm yyyy')}</span>
          </div>
        </div>

        <Button.Group className={styles.actions}>
          <Tooltip content='Edit Element' inverted size='mini' position='top center'>
            <Button icon onClick={_handleEditForm}>
              <Icon name='pencil' size='large' />
            </Button>
          </Tooltip>
          <Tooltip content='Clone Element' inverted size='mini' position='top center'>
            <Button icon onClick={_handleCloneForm}>
              <Icon name='clone outline' size='large' />
            </Button>
          </Tooltip>
        </Button.Group>
        <Image src='/images/pages/default_page.jpg' wrapped ui={false} fuild="true" />
        {cloningCurrent &&
          <div className={styles.progress}>Cloning...</div>
        }
      </div>
      <div className={styles.details}>
        <div className={styles.left}>
          <div className={styles.name}>{form.name}</div>
          <div className={styles.author}>By {author()}</div>
        </div>
      </div>
    </div>
  );
}

export function FormPlaceholderCard(props) {
  return (
    <div className={cx(styles.page, styles.placeholder, props.className)}>
      <div className={styles.image}>
        <Placeholder fluid>
          <Placeholder.Image />
        </Placeholder>
      </div>
      <div className={styles.details}>
        <div className={styles.left}>
          <Placeholder>
            <Placeholder.Paragraph>
              <Placeholder.Line length='medium' />
              <Placeholder.Line length='short' />
              <Placeholder.Line length='very long' />
            </Placeholder.Paragraph>
          </Placeholder>
        </div>
      </div>
    </div>
  );
}

const formWidget = {
  type: 'layout.row',
  name: 'Row',
  widgets: [{
    type: 'layout.column',
    name: 'Column',
    widgets: [{
      type: 'widgets.form',
      name: 'Contact Form',
      capabilities: {
        "draggable": false,
        "droppable": true,
        "editable": true,
        "resizable": false,
        "clonable": false
      },
      widgets: [{
        type: 'widgets.form.step',
        name: 'Default',
        widgets: [{
          type: 'layout.row',
          name: 'Row',
          capabilities: {
            "draggable": false,
            "droppable": true,
            "editable": false,
            "resizable": false,
            "clonable": false
          },
          widgets: [{
            type: 'layout.column',
            name: 'Column',
            capabilities: {
              "draggable": false,
              "droppable": true,
              "editable": false,
              "resizable": false,
              "clonable": false
            },
            properties: {
              padding: {
                top: '0px',
                right: '0px',
                bottom: '15px',
                left: '0px'
              }
            },
            widgets: [{
              type: 'widgets.text',
              name: 'Text',
              content: '<h1 style="margin: 0px;">Connect with us</h1><p>Please enter a valid business email and we&rsquo;ll get back to you shortly.</p>',
              properties: {

              },
            }]
          }]
        }, {
          type: 'widgets.form.input',
          name: 'Email',
          properties: {
            id: 'email',
            label: 'Email',
            placeholder: 'Enter a valid email'
          },
          capabilities: {
            "clonable": false
          },
        }, {
          type: 'widgets.form.button',
          name: 'Submit Button',
          content: 'Submit'
        }]
      }, {
        type: 'widgets.form.step',
        name: 'Success',
        properties: {
          success: true
        },
        widgets: [{
          type: 'layout.row',
          name: 'Row',
          capabilities: {
            "draggable": false,
            "droppable": true,
            "editable": false,
            "resizable": false,
            "clonable": false
          },
          widgets: [{
            type: 'layout.column',
            name: 'Column',
            capabilities: {
              "draggable": false,
              "droppable": true,
              "editable": false,
              "resizable": false,
              "clonable": false
            },
            properties: {
              padding: {
                top: '15px',
                right: '30px',
                bottom: '15px',
                left: '30px'
              }
            },
            widgets: [{
              type: 'widgets.text',
              name: 'Text',
              content: '<h1>Thank you</h1><p>Our team will reachout to you shorly.</p>'
            }]
          }]
        }]
      }]
    }]
  }]
}

const pardotFormWidgets = [{
  type: 'widgets.form.pardot',
  name: 'Pardot Form',
  capabilities: {
    "draggable": false,
    "droppable": false,
    "editable": true,
    "resizable": false,
    "deletable": false,
    "clonable": false
  },
  widgets: [
    {
      type: 'widgets.form.step',
      name: 'Form',
      capabilities: {
        "draggable": false,
        "droppable": false,
        "editable": false,
        "resizable": false,
        "deletable": false,
        "clonable": false
      }
    },
    {
      type: 'widgets.form.step',
      name: 'Success',
      properties: {
        success: true
      },
      widgets: [{
        type: 'layout.row',
        name: 'Row',
        capabilities: {
          "draggable": false,
          "droppable": true,
          "editable": false,
          "resizable": false,
          "deletable": false,
          "clonable": false
        },
        widgets: [{
          type: 'layout.column',
          name: 'Column',
          capabilities: {
            "draggable": false,
            "droppable": true,
            "editable": false,
            "resizable": false,
            "deletable": false,
            "clonable": false
          },
          properties: {
            padding: {
              top: '15px',
              right: '30px',
              bottom: '15px',
              left: '30px'
            }
          },
          widgets: [{
            type: 'widgets.text',
            name: 'Text',
            content: '<h1>Thank you</h1><p>Our team will reachout to you shorly.</p>'
          }]
        }]
      }]
    }]
}]

const hubspotFormWidgets = [{
  type: 'widgets.form.hubspot',
  name: 'Hubspot Form',
  capabilities: {
    "draggable": false,
    "droppable": false,
    "editable": true,
    "resizable": false,
    "deletable": false,
    "clonable": false
  },
  widgets: [
    {
      type: 'widgets.form.step',
      name: 'Form',
      capabilities: {
        "draggable": false,
        "droppable": false,
        "editable": false,
        "resizable": false,
        "deletable": false,
        "clonable": false
      }
    },
    {
      type: 'widgets.form.step',
      name: 'Success',
      properties: {
        success: true
      },
      widgets: [{
        type: 'layout.row',
        name: 'Row',
        capabilities: {
          "draggable": false,
          "droppable": true,
          "editable": false,
          "resizable": false,
          "deletable": false,
          "clonable": false
        },
        widgets: [{
          type: 'layout.column',
          name: 'Column',
          capabilities: {
            "draggable": false,
            "droppable": true,
            "editable": false,
            "resizable": false,
            "deletable": false,
            "clonable": false
          },
          properties: {
            padding: {
              top: '15px',
              right: '30px',
              bottom: '15px',
              left: '30px'
            }
          },
          widgets: [{
            type: 'widgets.text',
            name: 'Text',
            content: '<h1>Thank you</h1><p>Our team will reachout to you shorly.</p>'
          }]
        }]
      }]
    }]
}]

const marketoFormWidgets = [{
  type: 'widgets.form.marketo',
  name: 'Marketo Form',
  capabilities: {
    "draggable": false,
    "droppable": false,
    "editable": true,
    "resizable": false,
    "deletable": false,
    "clonable": false
  },
  widgets: [
    {
      type: 'widgets.form.step',
      name: 'Form',
      capabilities: {
        "draggable": false,
        "droppable": false,
        "editable": false,
        "resizable": false,
        "deletable": false,
        "clonable": false
      }
    },
    {
      type: 'widgets.form.step',
      name: 'Success',
      properties: {
        success: true
      },
      widgets: [{
        type: 'layout.row',
        name: 'Row',
        capabilities: {
          "draggable": false,
          "droppable": true,
          "editable": false,
          "resizable": false,
          "deletable": false,
          "clonable": false
        },
        widgets: [{
          type: 'layout.column',
          name: 'Column',
          capabilities: {
            "draggable": false,
            "droppable": true,
            "editable": false,
            "resizable": false,
            "deletable": false,
            "clonable": false
          },
          properties: {
            padding: {
              top: '15px',
              right: '30px',
              bottom: '15px',
              left: '30px'
            }
          },
          widgets: [{
            type: 'widgets.text',
            name: 'Text',
            content: '<h1>Thank you</h1><p>Our team will reachout to you shorly.</p>'
          }]
        }]
      }]
    }]
}]


const externalFormWidgets = [{
  type: 'widgets.form.external',
  name: 'External Form',
  capabilities: {
    "draggable": false,
    "droppable": false,
    "editable": true,
    "resizable": false,
    "deletable": false,
    "clonable": false
  },
  widgets: [
    {
      type: 'widgets.form.step',
      name: 'Form',
      capabilities: {
        "draggable": false,
        "droppable": false,
        "editable": false,
        "resizable": false,
        "deletable": false,
        "clonable": false
      }
    },
    {
      type: 'widgets.form.step',
      name: 'Success',
      properties: {
        success: true
      },
      widgets: [{
        type: 'layout.row',
        name: 'Row',
        capabilities: {
          "draggable": false,
          "droppable": true,
          "editable": false,
          "resizable": false,
          "deletable": false,
          "clonable": false
        },
        widgets: [{
          type: 'layout.column',
          name: 'Column',
          capabilities: {
            "draggable": false,
            "droppable": true,
            "editable": false,
            "resizable": false,
            "deletable": false,
            "clonable": false
          },
          properties: {
            padding: {
              top: '15px',
              right: '30px',
              bottom: '15px',
              left: '30px'
            }
          },
          widgets: [{
            type: 'widgets.text',
            name: 'Text',
            content: '<h1>Thank you</h1><p>Our team will reachout to you shorly.</p>'
          }]
        }]
      }]
    }]
}]