import { combineReducers } from "redux";
import undoable from 'redux-undo';

import { usersReducer } from './usersReducer';
import { userManagementReducer } from './userManagementReducer'
import { storyboardReducer } from './storyboardReducer';
import { selectionReducer } from "./selectionReducer";
import { pagesReducer } from "./pagesReducer";
import { pageEditorReducer } from "./pageEditorReducer";
import { widgetsEditorReducer } from './widgetsEditorReducer';
import { templatesReducer } from "./templatesReducer";
import { paperfliteReducer } from './paperflite/paperfliteReducer';
import { clustersReducer } from './clustersReducer';
import { customFieldsReducer } from './customFieldsReducer';
import { contentReducer } from './contentReducer';
import { assetReducer } from './assetReducer';
import { journeyReducer } from './journeyReducer';
import { reportReducer } from './reports/reportReducer';
import { gatingsReducer } from './gatingsReducer';
import { integrationsReducer } from './integrations/integrationsReducer';
import { contentSelectionReducer } from './contentSelectionReducer';
import { aliasReducer } from './aliasReducer';

import { liveElementsReducer } from './liveElementsReducer';
import { assetViewerReducer } from './assetViewerReducer';
import { formReducer } from './formReducer';

import { LOGOUT_USER } from 'constants/actionTypes';

const appReducer = combineReducers({
  users: usersReducer,
  userManagement: userManagementReducer,
  storyboards: storyboardReducer,
  selection: selectionReducer,
  pages: pagesReducer,
  pageEditor: pageEditorReducer,
  widgetsEditor: widgetsEditorReducer,
  templates: templatesReducer,
  paperflite: paperfliteReducer,
  clusters: clustersReducer,
  customFields: customFieldsReducer,
  content: contentReducer,
  asset: assetReducer,
  journeys: journeyReducer,
  reports: reportReducer,
  gatings: gatingsReducer,
  integrations: integrationsReducer,
  contentSelection: contentSelectionReducer,
  liveElements: liveElementsReducer,
  assetViewers: assetViewerReducer,
  aliases: aliasReducer,
  forms: formReducer
})

const rootReducer = (state, action) => {
  if (action.type === LOGOUT_USER) {
    state = undefined
  }

  return appReducer(state, action)
}

export default rootReducer;