import React, { PropTypes, Fragment, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  Grid,
  Button,
  Icon,
  Segment,
  Image,
  Header,
  Menu
} from 'semantic-ui-react';

import {
  useRouteMatch,
} from "react-router-dom";

import _ from 'lodash';

import styles from './hubspot.module.scss';

import InlineConfirmationDialog from 'components/InlineConfirmationDialog';

import { getBaseUrl } from 'services/util';

import buildUrl from 'build-url';

import { authenticateHubspot } from 'actions/integrations/hubspot';

import { HubspotCofiguration } from './hubspotConfiguration.jsx'

import { deactivateIntegration, activatingIntegrationSuccedded } from 'actions/integrations/integrations';

export default function Hubspot() {
  const dispatch = useDispatch();
  let { path, url } = useRouteMatch();
  let baseUrl = getBaseUrl(url);
  const history = useHistory();

  const integrations = useSelector(state => _.get(state, 'integrations.activated.items', []));
  const deactivating = useSelector(state => _.get(state, 'integrations.activated.deactivating', false));
  const authenticating = useSelector(state => _.get(state, "integrations.hubspot.authentication.authenticating", false))
  const apiUrl = `${process.env.REACT_APP_API_URL}`;

  const integration = integrations.filter(item => {
    return item != null;
  }).find(item => {
    return item.type == 'hubspot';
  })

  const handleBackClick = () => {
    history.push(`${baseUrl}/settings/integrations`);
  }

  const handleDeleteClick = () => {
    dispatch(deactivateIntegration('hubspot', (res) => {
      handleBackClick();
    }))
  }

  function handleAuthenticate() {
    //Calculate Position  
    var dualScreenLeft = window.screenLeft != undefined ? window.screenLeft : window.screen.left;
    var dualScreenTop = window.screenTop != undefined ? window.screenTop : window.screen.top;

    var width = window.innerWidth ? window.innerWidth : document.documentElement.clientWidth ? document.documentElement.clientWidth : window.screen.width;
    var height = window.innerHeight ? window.innerHeight : document.documentElement.clientHeight ? document.documentElement.clientHeight : window.screen.height;

    var w = 0.8 * width;
    var h = 0.8 * height;

    var left = ((width / 2) - (w / 2)) + dualScreenLeft;
    var top = ((height / 2) - (h / 2)) + dualScreenTop;

    const url = buildUrl(apiUrl, {
      path: `/api/2.0/integrations/hubspot/authorize?product=cleverstory`
    })

    var newWindow = window.open(url, `Authorize hubspot Account`, 'scrollbars=yes, width=' + w + ', height=' + h + ', top=' + top + ', left=' + left);

    // Puts focus on the newWindow
    if (newWindow.focus) {
      newWindow.focus();
    }

    //Attaching message event listner
    var eventMethod = window.addEventListener ? "addEventListener" : "attachEvent";
    var eventer = window[eventMethod];
    var messageEvent = eventMethod == "attachEvent" ? "onmessage" : "message";

    // Listen to message from child window
    eventer(messageEvent, handleAuthenticateEvent, false);
    return false;
  }

  function handleAuthenticateEvent(e) {

    //Removing message event listner
    var detachEventMethod = window.removeEventListener ? 'removeEventListener' : 'detachEvent';
    var deEventer = window[detachEventMethod];
    var messageEvent = detachEventMethod == "detachEvent" ? "onmessage" : "message";

    if (e.data.type == 'HUBSPOT_AUTHORIZATION_SUCCESS') {
      var code = e.data.payload.code;
      var state = e.data.payload.state;
      var error = e.data.payload.error;

      if (!error) {
        let request = {
          code: code
        }

        dispatch(authenticateHubspot(request, (integration) => {
          dispatch(activatingIntegrationSuccedded(integration));
        }));
      }

      deEventer(messageEvent, handleAuthenticateEvent, false);
    }
  }
  var isAuthenticated = false;

  if (integration && integration.state.code === "ok" && !integration.error) {
    isAuthenticated = true;
  }

  return (
    <Grid columns="equal" className={styles.hubspot}>
      <Grid.Row>
        <Grid.Column width={5} className={styles.detailsView}>
          <Menu secondary>
            <Button className={styles.backButton} onClick={handleBackClick}>
              <Icon name={'angle left'} />BACK
            </Button>
          </Menu>

          <div className={styles.details}>
            <Image src={`/images/integrations/hubspot.svg`} />
            <Header as='h2'>Hubspot</Header>
            <p className={styles.description}>
              Integrate hubspot CRM to sync events across contacts
            </p>
            {integration && integration.state.code === 'ok' &&
              <InlineConfirmationDialog onConfirm={handleDeleteClick} message={'Are you sure to deactivate freshmarketer?'}>
                <Button>{deactivating ? 'DEACTIVATING...' : 'DEACTIVATE'}</Button>
              </InlineConfirmationDialog>
            }
          </div>
        </Grid.Column>
        <Grid.Column className={styles.formView}>
          <Segment basic className={styles.formSegment}>
            <div className={styles.authorize}>
              {!isAuthenticated &&
                <>
                  <h1>Log in to your Hubspot account</h1>
                  <p>Please provide a user account with global access</p>
                  <button className={styles.authButton} onClick={handleAuthenticate}>
                    {authenticating &&
                      'Authenticating...'
                    }

                    {!authenticating &&
                      'Authenticate'
                    }
                  </button>
                </>
              }
              {isAuthenticated &&
                <HubspotCofiguration />}
            </div>
          </Segment>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  )
}