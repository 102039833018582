import React, {useState} from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { createAsset, uploadingFile, uploadingFileProgress, updateFiltersInContent } from 'actions/content.jsx';
import { invalidateAssetSelection } from 'actions/selection';

import _ from 'lodash';
import { guid } from 'services/util';
import { createFilterForAssets } from 'services/clusters.service';
import cx from 'classnames';

import {
  Sidebar,
  Container,
  Grid,
  Item,
  Header,
  Button,
  Menu,
  Step,
  Icon,
  Image,
  Card,
  Progress,
  Segment
} from 'semantic-ui-react';

import Truncate from 'react-truncate';
import Dropzone from 'react-dropzone';

import PaperfliteAssets from './paperflite';
import AddUrl from './url';
import SidePane from 'components/SidePane';

import FileService from 'services/file.service.jsx';
import FileUploadService from 'services/file.upload.service';
import S3Upload from 'react-s3-uploader/s3upload';
import AuthService from 'services/auth.service';

import styles from './addAsset.module.scss';
const API_BASE_URL = `${process.env.REACT_APP_API_URL}/api/2.0`;

export default function AddAssetPopup(props) {
  const dispatch = useDispatch();
  const [source, setSource] = useState('device');

  const assets = useSelector(state => state.selection.assets);

  const handleSourceChange = (source) => {
    setSource(source);
  }

  if(!props.expanded) {
    return false;
  }

  const handleClose = () => {
    if(assets && assets.length > 0){
      const ids = assets.filter(item => item.type === 'asset').map(asset => asset.asset);
      const filters = createFilterForAssets(ids);
      dispatch(updateFiltersInContent(filters))
    }
    dispatch(invalidateAssetSelection());
    props.onVisibilityChange();

    setSource('device');
  }

  return (
    <SidePane open={props.expanded} width='100vw' closeOnDocumentClick={true}>
      <div className={styles.addAsset}>
        <Grid width='equal'>
          <Grid.Column width={4} className={styles.options}>
            <AssetCreationOptions source={source} onChange={handleSourceChange} onClose={handleClose} />
          </Grid.Column>
          <Grid.Column width={6} className={styles.creationContainer}>
            {source === 'device' &&
              <FileDragDrop />
            }

            {source === 'paperflite' &&
              <PaperfliteAssets />
            }

            {source === 'url' &&
              <AddUrl />
            }
          
          </Grid.Column>
          <Grid.Column width={6} className={styles.assets}>
            <AssetsPane onClose={handleClose}/>
          </Grid.Column>
        </Grid>
      </div>
    </SidePane>
  )
}

function AssetCreationOptions({ source, onChange, onClose}){
  const handleClick = (e, data) => {
    var id = data.id;
    onChange(id);
  }

  return (
    <>
      <Button className={styles.backButton} onClick={onClose}>
        <Icon name='angle left' /><label>Back</label>
      </Button>
      <Header as='h2'>Add Content</Header>
      <Step.Group fluid vertical>
        <Step id='device' link active={source === 'device'} onClick={handleClick}>
          <i aria-hidden="true" className="icon-browse icon"></i>
          <Step.Content>
            <Step.Title>Drag and Drop</Step.Title>
            <Step.Description>Files from anywhere or browse</Step.Description>
          </Step.Content>
        </Step>
        <Step id='paperflite' link active={source === 'paperflite'} onClick={handleClick}>
          <i aria-hidden="true" className="icon-paperflite-upload icon"></i>
          <Step.Content>
            <Step.Title>Paperflite</Step.Title>
            <Step.Description>Choose your assets from Paperflite</Step.Description>
          </Step.Content>
        </Step>
        <Step id='url' link active={source === 'url'} onClick={handleClick}>
          <i aria-hidden="true" className="icon-url icon"></i>
          <Step.Content>
            <Step.Title>Add URL</Step.Title>
            <Step.Description>Add a web URL link</Step.Description>
          </Step.Content>
        </Step>
      </Step.Group>
    </>  
  );
}

function FileDragDrop(props){
  const dispatch = useDispatch();

  const handleFileDrop = (files) => {
    for (var i = 0; i < files.length; i++) {
      var file = files[i];
      file.id = guid();

      var s3Uploader = new S3Upload({
        signingUrl: "/file_upload/initiate",
        signingUrlMethod: "POST",
        signingUrlHeaders: {
          Authorization: `Bearer ${AuthService.getAccessToken()}`
        },
        server: API_BASE_URL,
        scrubFilename: (filename) => {
          var name = filename.replace(/[^\w\d_\-.\s]+/ig, '')
          return name;
        },
        contentDisposition: 'auto',
        uploadRequestHeaders: {},
        onFinishS3Put: handleFileUploadFinish,
        onProgress: handleFileUploadProgress,
        onError: handleFileUploadError,
        files: [file]
      });

      FileUploadService.trackUpload(s3Uploader);

      dispatch(uploadingFile(file));

    }
  }

    const handleFileUploadFinish = (info, file) =>{
      if (info) {
        dispatch(createAsset(info.signedUrl, file));
      }
    }

    const handleFileUploadProgress = (progress, textState, file) =>{
      dispatch(uploadingFileProgress(file, progress));
    }

    const handleFileUploadError = () =>{
      
    }

  return (
    <Dropzone onDrop={handleFileDrop}>
      {({ getRootProps, getInputProps, isDragActive }) => (
        <div {...getRootProps({
          className: cx(styles.dropzone, {[styles.active]: isDragActive})
        })}>
          <Segment placeholder>
            <input {...getInputProps()} />
            <Image src='/images/assets/browse_files.svg' />
            <Button className={styles.browseButton} primary>
              <Icon name='add' /> Browse files
            </Button>
          </Segment>
        </div>
      )}
    </Dropzone>
  );
}

function AssetsPane(props){
  const assets = useSelector(state => state.selection.assets);
  
  return (
    <>
      <Header as='h2' attached='top'>
        {`(${assets.length}) Asset${assets.length === 1 ? '' : 's'}`}
      </Header>
      <Segment basic attached className={styles.inner}>
        <Grid columns={2} stretched>
          {assets.map((item) => {
            if(item.type === 'file'){
              return <File key={item.file.id} content={item}/>
            }else if(item.type === 'url'){
              return <URL key={item.url} content={item}/>
            }

            return <Asset key={item.asset.id} content={item}/>
          })}
        </Grid> 
      </Segment>
      <Segment basic attached>
        <Button floated='right' onClick={props.onClose}>ADD &amp; CLOSE</Button>
      </Segment>
    </>
  );
}

function Asset({content}){
  var asset = content.asset;
  const icon = _.get(asset, 'icon.thumbnail', '/images/default_asset.jpg');
  console.log(asset);
  return (
    <Grid.Column>
      <Card fluid className={styles.asset}>
        <Card.Content>
          <Image
            floated='left'
            size='mini'
            src={icon}
          />
          <Card.Header>
            <Truncate lines={1} className={styles.assetName}>{asset.name}</Truncate>
            <Card.Meta>
              {`${FileService.formatSize(asset.metadata.content_length)} | ${FileService.fileType(asset.metadata)}`}

              {(asset.status === 'NEEDS_PROCESSING' || asset.status === 'PROCESSING') &&
                <div className={styles.status}>OPTIMIZING</div>
              }
            </Card.Meta>
          </Card.Header>
        </Card.Content>
      </Card>
    </Grid.Column>
  );
}

function File({content}){
  const {progress} = content; 
  return (
    <Grid.Column>
      <Card fluid className={styles.asset}>
        <Card.Content>
          <Image
            floated='left'
            size='mini'
            src='/images/default_asset.jpg'
          />
          <Card.Header>
            <Truncate lines={1}>{content.file.name}</Truncate>
            <Card.Meta>
              {`${FileService.formatSize(content.file.size)} | Uploading...`}
              {progress === 100 &&
                <div className={styles.status}>PROCESSING</div>
              }
            </Card.Meta>
          </Card.Header>
        </Card.Content>
        <Progress percent={progress} attached='bottom' />
      </Card>
    </Grid.Column>
  );
}

function URL({content}){
  const {url} = content; 
  return (
    <Grid.Column>
      <Card fluid className={styles.asset}>
        <Card.Content>
          <Image
            floated='left'
            size='mini'
            src='/images/default_asset.jpg'
          />
          <Card.Header>
            <Truncate lines={1}>{content.url}</Truncate>
            <Card.Meta>
              LINK
            </Card.Meta>
          </Card.Header>
        </Card.Content>
      </Card>
    </Grid.Column>
  );
}