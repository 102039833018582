import {
  FETCHING_USERS_REQUEST, FETCHING_USERS_SUCCEEDED, FETCHING_USERS_FAILED,
  INVITE_USER_REQUEST, INVITE_USER_SUCCEEDED, INVITE_USER_FAILED,
  UPDATE_USER_ACCESS, UPDATE_USER_ACCESS_SUCCEEDED, UPDATE_USER_ACCESS_FAILED,
  FETCHING_INVITATIONS_REQUEST, FETCHING_INVITATIONS_SUCCEEDED, FETCHING_INVITATIONS_FAILED,
  RESEND_USER_INVITATION, RESEND_USER_INVITATION_SUCCEEDED, RESEND_USER_INVITATION_FAILED,
  DELETE_USER_INVITATION, DELETE_USER_INVITATION_SUCCEEDED, DELETE_USER_INVITATION_FAILED
} from 'constants/actionTypes.jsx';

import { call, put, takeLatest } from 'redux-saga/effects';
import axios from 'axios';
import { createAction } from 'redux-actions';

import buildUrl from 'build-url'; 

const apiUrl = `${process.env.REACT_APP_API_URL}/api/2.0`;
const USER_BASE_URL = `${apiUrl}/users`;
const INVITATION_URL = `${apiUrl}/invitations`;

/**
* Invite User
*/
export const inviteUser = createAction(INVITE_USER_REQUEST, (user, callback) => {
  return {
    user: user,
    callback: callback
  };
})

const inviteUserSucceeded = createAction(INVITE_USER_SUCCEEDED, (response) => {
  return response;
})

const inviteUserFailed = createAction(INVITE_USER_FAILED, (error) => {
  return error;
})

function* inviteUserSaga(action) {
  var { user, callback } = action.payload;

  const inviteUserAPI = () => {
    return axios.post(INVITATION_URL, user).then(response => response.data);
  }

  try {
    const response = yield call(inviteUserAPI);
    yield put(inviteUserSucceeded(response));
    if (callback) {
      callback(response);
    }
  } catch (error) {
    yield put(inviteUserFailed(error));
  }
}

/**
* Update User
*/
export const updateUser = createAction(UPDATE_USER_ACCESS, (user) => {
  return {
    user: user
  };
})

const updateUserSucceeded = createAction(UPDATE_USER_ACCESS_SUCCEEDED, (response) => {
  return response;
})

const updateUserFailed = createAction(UPDATE_USER_ACCESS_FAILED, (error) => {
  return error;
})

function* updateUserSaga(action) {
  var { user } = action.payload;

  let url = buildUrl(USER_BASE_URL, {
    path: `${user.id}/permissions`
  });

  const updateUserAPI = () => {
    return axios.put(url, user).then(response => response.data);
  }

  try {
    const response = yield call(updateUserAPI);
    yield put(updateUserSucceeded(response));
  } catch (error) {
    yield put(updateUserFailed(error));
  }
}

/**
* Fetch all Users
*/
export const fetchAllUsers = createAction(FETCHING_USERS_REQUEST, (page, query) => {
  return {
    page: page,
    query: query
  }
});
const fetchAllUsersSucceeded = createAction(FETCHING_USERS_SUCCEEDED, (response) => {
  return response;
});
const fetchAllUsersFailed = createAction(FETCHING_USERS_FAILED, (error) => {
  return error;
});

const fetchAllUsersAPI = (params) => {
  return axios({
    method: 'GET',
    url: `${USER_BASE_URL}/all`,
    params: params
  }).then(response => response.data);
}

function* fetchAllUsersSaga(action) {
  const { page, query } = action.payload;

  var params = {
    page: page,
    query: query
  }

  try {
    const response = yield call(fetchAllUsersAPI, params);
    yield put(fetchAllUsersSucceeded(response));
  } catch (error) {
    yield put(fetchAllUsersFailed(error));
  }
}

/**
* Fetch all Invitations
*/
export const fetchAllInvitations = createAction(FETCHING_INVITATIONS_REQUEST, (page, query) => {
  return {
    page: page,
    query: query
  }
});
const fetchAllInvitationsSucceeded = createAction(FETCHING_INVITATIONS_SUCCEEDED, (response) => {
  return response;
});
const fetchAllInvitationsFailed = createAction(FETCHING_INVITATIONS_FAILED, (error) => {
  return error;
});

const fetchAllInvitationsAPI = (params) => {
  return axios({
    method: 'GET',
    url: `${INVITATION_URL}/all`,
    params: params
  }).then(response => response.data);
}

function* fetchAllInvitationsSaga(action) {
  const { page, query } = action.payload;

  var params = {
    page: page,
    query: query
  }

  try {
    const response = yield call(fetchAllInvitationsAPI, params);
    yield put(fetchAllInvitationsSucceeded(response));
  } catch (error) {
    yield put(fetchAllInvitationsFailed(error));
  }
}

/**
* Resend Invitations
*/
export const resendInvitation = createAction(RESEND_USER_INVITATION, (id) => {
  return id;
})

const resendInvitationSucceeded = createAction(RESEND_USER_INVITATION_SUCCEEDED, (id) => {
  return id;
})

const resendInvitationFailed = createAction(RESEND_USER_INVITATION_FAILED, (id, error) => {
  return {
    id: id,
    error: error
  };
})

function* resendInvitationSaga(action) {
  var id = action.payload;

  let url = buildUrl(INVITATION_URL, {
    path: `${id}/resend-invitation`
  });

  const resendInvitationAPI = () => {

    return axios.post(url, id).then(response => response.data);
  }

  try {
    const response = yield call(resendInvitationAPI);
    yield put(resendInvitationSucceeded(id));
  } catch (error) {
    yield put(resendInvitationFailed(id, error));
  }
}

/**
* Delete Invitation
*/
export const deleteInvitation = createAction(DELETE_USER_INVITATION, (id) => {
  return {
    id: id
  };
})

const deleteInvitationSucceeded = createAction(DELETE_USER_INVITATION_SUCCEEDED, (id) => {
  return id;
})

const deleteInvitationFailed = createAction(DELETE_USER_INVITATION_FAILED, (error) => {
  return error;
})

function* deleteInvitationSaga(action) {
  var { id } = action.payload;

  let url = buildUrl(INVITATION_URL, {
    path: `${id}`
  });

  const deleteInvitationAPI = () => {
    return axios.delete(url).then(response => response.data);
  }

  try {
    const response = yield call(deleteInvitationAPI);
    yield put(deleteInvitationSucceeded(id));
  } catch (error) {
    yield put(deleteInvitationFailed(error));
  }
}

export default function* userInvitationSaga() {
  yield takeLatest(INVITE_USER_REQUEST, inviteUserSaga);
  yield takeLatest(FETCHING_USERS_REQUEST, fetchAllUsersSaga);
  yield takeLatest(FETCHING_INVITATIONS_REQUEST, fetchAllInvitationsSaga);
  yield takeLatest(RESEND_USER_INVITATION, resendInvitationSaga);
  yield takeLatest(DELETE_USER_INVITATION, deleteInvitationSaga);
  yield takeLatest(UPDATE_USER_ACCESS, updateUserSaga);
};