import React, {useState, useEffect, useRef} from 'react';
import { useSelector, useDispatch } from 'react-redux';

import cx from 'classnames';
import _ from 'lodash';

import { Widget, getStyles } from '../widget.jsx';
import { useWidgetDrop } from 'services/dnd.service.jsx';

import {
  Menu, Dropdown, Image
} from 'semantic-ui-react';

import styles from './menu.module.scss';
import { Link } from 'react-router-dom';

export default function MenuView(props) {
  const ref = useRef(null);
  const dropRef = useWidgetDrop(props, ref, {
    
  });

  const properties = props.properties || {};
  
  var menuContent = props.content;
  
  const [menus, setMenus] = useState([])

  useEffect(() => {
    setMenus(menuContent && menuContent.menus ? menuContent.menus : []);
  }, [menuContent]);

  useEffect(()=> {
    var menusColumn = document.querySelector(`#widget_${props.parent}`);
  
    var closestParentRow = menusColumn.closest(".ui.container");
    if(closestParentRow) {
      closestParentRow.style.cssText = "flex-grow: 0 !important; z-index: 99; position: -webkit-sticky; position: sticky; top: 0px;";
    }
  },[]);

  const widgetStyles = getStyles(properties);

  return (
    <div 
      ref={props.dragRef(dropRef(ref))}
      className={cx(styles.menuWidget, props.className)}
      onMouseOver={props.onMouseOver}
      onMouseOut={props.onMouseOut}
      onClick={props.onClick}
    >
      <div className={cx(styles.menuContainer, 'cs-menu')} id={`widget_${props.id}`} style={widgetStyles}>
        <Menu className={styles.menu} 
          inverted={properties.inverted} 
          borderless={properties.borderless} 
          size='large'
          secondary
        >
          <MenuSection menus={menus} />
        </Menu>
      </div>
      {props.children}
    </div>
  )
}

function MenuSection(props) {
  const [activeItem, setActiveItem] = useState(null);
  
  const handleScroll = () => {
    var current_pos = document.documentElement.scrollTop;
    var anchoredWidgets = document.querySelectorAll(`[data-anchor]`)
    anchoredWidgets.forEach((anchoredWidget)=>{
      var top = anchoredWidget.offsetTop;
      var bottom = top + anchoredWidget.offsetHeight;

      if (current_pos >= top && current_pos <= bottom) {
        if(anchoredWidget.dataset.anchor) {
          setActiveItem(anchoredWidget.dataset.anchor);
        }
      }
    });
  }

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    
    return () => {
      window.removeEventListener("scroll", handleScroll);
    }
  }, []);

  return (
    <>
      {(props.menus || []).map(item => {
        return <MenuItem menu={item} activeItem={activeItem} setActiveItem={setActiveItem}/> 
      })}
    </>
  )
}


function MenuItem({ menu, activeItem, setActiveItem}) {
  var type = menu.type;
  var url = _.get(menu, 'value', '#');
  var target = _.get(menu, 'target', '_self');

  if (type === 'PAGE') {
    url = _.get(menu, 'value.alias', '#');
  }

  if(type === 'ANCHOR') {
    url="javascript:void(0)";
  }

  const handleItemClick = (e,data) => {
    setActiveItem(menu.value)
    if (type === 'ANCHOR') {
      var menuValue = document.querySelector(`[data-anchor="${menu.value}"]`)
      if(menuValue) {
        menuValue.scrollIntoView();
      }
    }
  }

  return (
    <>
      {type === 'PAGE' &&
        <Menu.Item as={Link} to={`${url}`} target={target} onClick={handleItemClick} active={activeItem===menu.value} className={cx({"sticky_menu": true})}>
          <>
            {menu.icon && <img src={menu.icon.thumbnail} />}
            {menu.label || ''}
          </>
        </Menu.Item>
      }

      {type !== 'PAGE' &&
        <Menu.Item as='a' href={`${url}`} target={target} onClick={handleItemClick} active={activeItem===menu.value} className={cx({"sticky_menu": true})}>
          <>
            {menu.icon && <img src={menu.icon.thumbnail} />}
            {menu.label || ''}
          </>
        </Menu.Item>
      }
    </>
  )
}