import React, {useState, Fragment, useRef} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {updateProperties, updateWidget} from 'actions/widgetEditor';

import _ from 'lodash';
import cx from 'classnames';

import {
  Input,
  Form,
  Icon,
  Grid
} from 'semantic-ui-react';

import { Editor } from '@tinymce/tinymce-react';
import { Widget, getStyles } from '../../widget.jsx';
import { WidgetRegistry } from '../../registry.jsx';
import { Property } from 'components/Properties';
import { QuickSettings, QuickSettingsProperty } from 'components/QuickSettings';

import { useWidgetDrop } from 'services/dnd.service';
import { DEFAULT_ACCEPT_TARGETS } from 'constants/ItemTypes.jsx';

import styles from '../form.module.scss';

export const FormInput = (props) => {
  const dispatch = useDispatch();
  var widgets = props.widgets || [];
  const properties = props.properties;
  
  const ref = useRef(null);
  const dropRef = useWidgetDrop(props, ref, {
    
  });

  const widgetStyles = getStyles(properties);
  var width = _.get(properties, 'width', 16);

  if(width <= 0 || width > 16){
    width = 16;
  }

  return (
    <Form.Field
      as={FowardableInput}
      forwardedRef={props.dragRef(dropRef(ref))}
      type='text'
      className={cx(styles.input, props.className)} 
      width={width}
      id={`widget_${props.id}`}
      onMouseOver={props.onMouseOver}
      onMouseOut={props.onMouseOut}
      onClick={props.onClick}
    >
      <label>{properties.label}</label>
      <Input type='text'
        style={widgetStyles} 
        readOnly={props.editable} 
        placeholder={properties.placeholder}
      />

      <Widget.Children widgets={props.widgets} parent={props.id} editor={props.editor} editable={props.editable} />
      {props.children}
    </Form.Field>
  );
}

function FowardableInput({ forwardedRef, ...props }) {
  return <div ref={forwardedRef} {...props} />
};

function InputQuickSettings(props) {
  const dispatch = useDispatch();

  const [selected, setSelected] = useState('basic');

  const menuItems = [
    { id: 'basic', title: 'Basic', icon: 'icon-custom' },
    { id: 'spacing', title: 'Spacing', icon: 'icon-spacing' },
    { id: 'border', title: 'Border', icon: 'icon-border' },
    { id: 'advanced', title: 'Advanced', icon: 'icon-custom' }
  ]

  const handleMenuClick = (item) => {
    setSelected(item);
  }

  var widget = useSelector(state => {
    const editorContext = state.widgetsEditor.editors[props.editor];

    if (editorContext) {
      return editorContext.widgetsById[props.id];
    }

    return null;
  });
  if (!widget) {
    return false;
  }

  var properties = widget.properties;

  const handleChange = (changeRequest) => {
    dispatch(updateProperties({
      id: widget.id,
      change: changeRequest,
      context: props.editor
    }));
  }

  const handleCssChange = (css) => {
    var changeRequest = { ...widget };
    _.set(changeRequest, 'properties.css', css);

    changeRequest = WidgetRegistry.processRequest([changeRequest])[0];
    dispatch(updateWidget(props.id, changeRequest, props.editor));
  }

  return (
    <QuickSettings menuItems={menuItems} selected={selected} onMenuClick={handleMenuClick} >
      {selected === 'basic' &&
        <>
          <QuickSettingsProperty.Input
            title={'Label'}
            value={properties.label}
            onChange={(value) => {
              handleChange({
                label: value
              });
            }}
          />
          <QuickSettingsProperty.Input
            title={'Placeholder'}
            value={properties.placeholder}
            onChange={(value) => {
              handleChange({
                placeholder: value
              });
            }}
          />
          <QuickSettingsProperty.Switch
            title={'Required'}
            value={properties.mandatory}
            onChange={() => {
              handleChange({
                mandatory: !properties.mandatory
              })
            }}
          />
          <QuickSettingsProperty.Switch
            title={'Work Email'}
            value={properties.workEmail}
            onChange={() => {
              handleChange({
                workEmail: !properties.workEmail
              })
            }}
          />

          <QuickSettingsProperty.Dropdown title='Width'
            tooltip='Size of the field'
            placeholder='Size'
            selection
            options={[
              { key: '4', value: 4, text: '25%' },
              { key: '8', value: 8, text: '50%' },
              { key: '12', value: 12, text: '75%' },
              { key: '16', value: 16, text: '100%' },
            ]}
            value={properties.width}
            onChange={(e, data) => {
              handleChange({
                width: data.value
              });
            }}
          />
        </>
      }

      {selected === 'spacing' &&
        <>
          <QuickSettingsProperty.Spacing
            title='Margin'
            value={properties.margin}
            onChange={(margin) => {
              handleChange({
                margin: margin
              });
            }}
          />
          <QuickSettingsProperty.Spacing
            title='Padding'
            value={properties.padding}
            onChange={(padding) => {
              handleChange({
                padding: padding
              });
            }}
          />
        </>
      }

      {selected === 'border' &&
        <QuickSettingsProperty.Border expanded value={properties.border} onChange={(border) => {
          handleChange({
            border: border
          });
        }}
        />
      }

      {selected === 'advanced' &&
        <QuickSettingsProperty.CSS title='Stylesheets' value={properties.css} onChange={handleCssChange} />
      }
    </QuickSettings>
  )
}

FormInput.QuickSettings = InputQuickSettings;

function InputProperty(props){
  const dispatch = useDispatch();

  var widget = useSelector(state => {
    const editorContext = state.widgetsEditor.editors[props.editor];

    if (editorContext) {
      return editorContext.widgetsById[props.id];
    }

    return null;
  });
  if(!widget){
    return false;
  }

  var properties = widget.properties;

  const handleChange = (changeRequest) => {
    dispatch(updateProperties({
      id: widget.id,
      change: changeRequest,
      context: props.editor
    }));
  }

  const handleCssChange = (css) => {
    var changeRequest = {...widget};
    _.set(changeRequest, 'properties.css', css);

    changeRequest  = WidgetRegistry.processRequest([changeRequest])[0];
    dispatch(updateWidget(props.id, changeRequest, props.editor));
  }
  
  return (
    <Fragment>
      <Property.Section title='Basic' expanded>
        <Property.Input 
          title={'Label'}
          value={properties.label}
          onChange={(value) => {
            handleChange({
              label: value
            });
          }}
        />
        <Property.Input 
          title={'Placeholder'}
          value={properties.placeholder}
          onChange={(value) => {
            handleChange({
              placeholder: value
            });
          }}
        />
        <Property.Switch 
          title={'Required'}
          value={properties.mandatory}
          onChange = {() => {
            handleChange({
              mandatory: !properties.mandatory
            })
          }}
        />
        <Property.Switch 
          title={'Work Email'}
          value={properties.workEmail}
          onChange = {() => {
            handleChange({
              workEmail: !properties.workEmail
            })
          }}
        />

        <Property.Dropdown title='Width' 
          tooltip='Size of the field'
          placeholder='Size'
          selection 
          options={[
            { key: '4', value: 4, text: '25%' },
            { key: '8', value: 8, text: '50%' },
            { key: '12', value: 12, text: '75%' },
            { key: '16', value: 16, text: '100%' },
          ]}
          value={properties.width}
          onChange={(e, data) => {
            handleChange({
              width: data.value
            });
          }}
        />
      </Property.Section>
      <Property.Section title='Spacing'>
        <Property.Spacing 
          title='Margin' 
          value={properties.margin}
          onChange={(margin) => {
            handleChange({
              margin: margin
            });
          }}
        />
        <Property.Spacing 
          title='Padding' 
          value={properties.padding}
          onChange={(padding) => {
            handleChange({
              padding: padding
            });
          }}
        />
      </Property.Section>
      
      <Property.Border expanded value={properties.border} onChange={(border) => {
          handleChange({
            border: border
          });
        }} 
      />

      <Property.Section title='Advanced' expanded>
        <Property.CSS title='Stylesheets' value={properties.css} onChange={handleCssChange} />
      </Property.Section>
    </Fragment>
  );
}

FormInput.Property = InputProperty;