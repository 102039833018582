import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import _ from 'lodash';
import cx from 'classnames';

import styles from './addContent.module.scss';
import { updateContentEditor, rearrangeAssetInContentEditor, deleteAssetFromContentEditor, updateAssetInPlaceholder } from 'actions/contentSelection';
import Truncate from 'react-truncate';
import { AddContentContext, SubmitForm } from 'components/Asset/AddContent';
import { assetTypesOptions } from 'constants/filters.jsx'

import SidePane from 'components/SidePane';
import { guid } from 'services/util';

import {
  Icon,
  Form,
  Input,
  Segment,
  Label,
  Header,
  Image,
  TextArea,
  Button,
  Dropdown,
} from 'semantic-ui-react';

import { useDrag, DndProvider, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import Tooltip from 'components/Tooltip';
import ImageTools from 'services/imagetools.service';


export default function PlaceholderContent(props) {
  var closeButtonStyle = { "left": "calc(30vw - 20px)" }

  var content = useSelector(state => {
    return state.contentSelection.editors[props.editor];
  });

  const [type, setType] = useState(content ? content.type : null);

  useEffect(() => {
    if (content && content.type) {
      setType(content.type);
    }
  }, [content && content.type])

  if (!props.editor) {
    return null;
  }

  if (!content) {
    return null;
  }

  if (content && content.assets.length > 0) {
    closeButtonStyle = { "left": "calc(90vw - 20px)" }
  }

  return (
    <SidePane open={props.expanded} width={'90vw'} onClose={props.onClose}>
      <Button basic className={styles.closeButton} style={closeButtonStyle} onClick={props.onClose}>
        <i aria-hidden="true" className={cx('icon', 'icon-close_1')}>
          <span className="path1"></span><span className="path2"></span><span className="path3"></span>
        </i>
      </Button>
      <AddContentContext.Provider value={{
        editor: props.editor,
        onSave: props.onSave,
        onClose: () => { },
        editable: props.editable,
        sortable: props.sortable,
        deletable: props.deletable
      }}>
        <div className={cx(styles.addContentContainer, styles.placeholderAssetContainer, {
          [styles.showAddContent]: true,
          [styles.saving]: content.saving
        })}>

          {content.saving &&
            <div className={styles.savingMessage}>
              Saving content...
            </div>
          }

          <div className={styles.addContent}>
            <div className={styles.assetDetails}> 
              <div className={styles.assetList}>
                <PlaceholderAssetList con tentEditor={props.editor} />
              </div>
              <div className={cx(styles.editAsset)}>
                {content && content.assets.length > 0 &&
                  <PlaceholderAssetEditView contentEditor={props.editor} />
                }
              </div>
            </div>
          </div>

        </div>
      </AddContentContext.Provider>
    </SidePane >
  )
}

function PlaceholderAssetList() {
  const dispatch = useDispatch();

  const { editor, editable, sortable, deletable } = React.useContext(AddContentContext);
  const content = useSelector(state => state.contentSelection.editors[editor]);
  const assets = content.assets || [];
  const type = content.type;
  var selectedAsset = _.get(content, 'selectedAsset', null);

  if (selectedAsset == null) {
    selectedAsset = assets[0]
  }
  const timestamp = (value) => {
    var timestamp = Date.parse(value)
    return timestamp
  }

  const handleCreate = () => {
    var defaultAsset = {
      id: guid(),
      name: "untitled",
      metadata: {
        extension: "type"
      },
      icon: null,
      summary: "",
      updatedDate: timestamp(new Date())
    }

    var request = {
      ...content,
      type: "placeholder_asset",
      assets: [...content.assets, defaultAsset],
      selectedAsset: defaultAsset
    }

    dispatch(updateContentEditor(editor, request));
  }

  const handleEditAsset = (asset) => {
    var request = {
      ...content,
      selectedAsset: asset
    }

    dispatch(updateContentEditor(editor, request));
  }

  const handleDeleteAsset = (asset) => {
    dispatch(deleteAssetFromContentEditor(editor, asset));
  }

  const handleRearrangeAsset = (asset, from, to) => {
    dispatch(rearrangeAssetInContentEditor(editor, asset.id, from, to));
  }

  return (
    <DndProvider backend={HTML5Backend}>
      <Segment basic className={styles.assets}>
        <Segment basic className={styles.assetPlaceholerHeader}>
          <Header as='h3' className={styles.placeholderHeader}>
            <Header.Content>Add Placeholder Asset</Header.Content>
          </Header>

          <Button onClick={handleCreate} className={styles.addPlaceholder}>
            ADD
            <Icon name='add' />
          </Button>
        </Segment>

        <Segment basic className={styles.list}>
          {assets.map((asset, index) => {
            return <PlaceholderAsset
              key={asset.id}
              type={type}
              placeholderAsset={asset}
              placeholderAssets={assets}
              onEdit={handleEditAsset}
              index={index}
              onDelete={handleDeleteAsset}
              onRearrange={handleRearrangeAsset}
              selected={asset.id === _.get(selectedAsset, 'id', null)}
            />
          })}
        </Segment>
      </Segment>
    </DndProvider>
  )
}

function PlaceholderAsset({ placeholderAsset, placeholderAssets, selectedAsset, type, index, selected, onEdit, onDelete, onRearrange, ...rest }) {
  const ref = React.useRef(null);
  const handleRef = React.useRef(null);

  var icon = _.get(placeholderAsset, 'icon') || '/images/default_asset.jpg';
  var assetName = _.get(placeholderAsset, 'name', 'untitled')
  var assetType = _.get(placeholderAsset, 'metadata.extension', 'type');

  const [{ isDragging }, drag, preview] = useDrag(() => ({
    type: 'placeholder_asset',
    item: { type: 'placeholder_asset', placeholderAsset, index },
    canDrag: () => {
      return true
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  }));

  const [, drop] = useDrop(() => ({
    accept: "placeholder_asset",
    hover(item, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;

      if (dragIndex === hoverIndex) {
        return;
      }

      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      const clientOffset = monitor.getClientOffset();
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;

      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      // Dragging upwards

      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }

      onRearrange(item.placeholderAsset, dragIndex, hoverIndex);

      item.index = hoverIndex;
    },
  }));

  const handleEditClick = () => {

    onEdit(placeholderAsset);
  }

  const handleDeleteClick = () => {
    onDelete(placeholderAsset);
  }

  drag(handleRef);

  return (
    <div className={cx(styles.asset, {
      [styles.active]: selected,
      [styles.dragging]: isDragging
    })} ref={drop(preview(ref))}>
      <Handle ref={handleRef} />

      <Header as='h4' onClick={handleEditClick}>
        <Image src={icon} />
        <Header.Content className={styles.name}>
          <Truncate lines={2}>{assetName}</Truncate>
          <Header.Subheader>
            {assetType}
          </Header.Subheader>
        </Header.Content>
      </Header>
      <div className={styles.actions}>
        <Button basic className={styles.edit} onClick={handleEditClick}>
          <i aria-hidden="true" className="edit icon"></i>
        </Button>

        <Button basic className={styles.delete} onClick={handleDeleteClick}>
          <i aria-hidden="true" className="trash alternate outline icon"></i>
        </Button>
      </div>
    </div>
  )
}

function PlaceholderAssetEditView(props) {
  const dispatch = useDispatch();
  const uploadIconRef = React.createRef();

  const { editor } = React.useContext(AddContentContext);
  const content = useSelector(state => state.contentSelection.editors[editor]);
  var selectedAsset = _.get(content, 'selectedAsset', null);

  if (selectedAsset == null) {
    selectedAsset = content.assets[0]
  }
  var assetName = _.get(selectedAsset, 'name', 'untitled');

  const handleUploadIcon = (e) => {
    ImageTools.resize(e.target.files[0], {
      width: 1024, // maximum width
      height: 1024 // maximum height
    }, (blob, didItResize) => {
      var reader = new FileReader();
      reader.onload = (e) => {
        var icon = e.target.result;

        var request = {
          ...selectedAsset,
          icon: icon,
          isIconSet: true,
          isEdited: true
        }

        dispatch(updateAssetInPlaceholder(editor, request));
      };

      reader.readAsDataURL(blob);
    });
  }

  const handleNameChange = (value) => {
    var request = {
      ...selectedAsset,
      name: value,
      isNameSet: true,
      isEdited: true
    }

    dispatch(updateAssetInPlaceholder(editor, request));
  }

  const handleSummaryChange = (value) => {
    var request = {
      ...selectedAsset,
      summary: value,
      isSummarySet: true,
      isEdited: true
    }

    dispatch(updateAssetInPlaceholder(editor, request));
  }


  const handleEntityType = (value) => {
    var metadata = {
      content_type: "PDF",
      extension: value
    }

    var request = {
      ...selectedAsset,
      metadata: metadata,
      isMetadataSet: true,
      isEdited: true
    }

    dispatch(updateAssetInPlaceholder(editor, request));
  }

  const handleTagsChange = (value) => {
    var request = {
      ...selectedAsset,
      tags: value,
      isEdited: true
    }

    dispatch(updateAssetInPlaceholder(editor, request));
  }

  return (
    <Segment basic className={styles.editAsset}>
      <Header as='h3' className={styles.header}>
        Customise how your asset will appear to your visitors
      </Header>
      <div className={styles.editAssetContainer}>
        <div className={cx(styles.uploadImageContainer, {
          [styles.empty]: selectedAsset && typeof selectedAsset.icon == null
        })}
          onClick={() => uploadIconRef.current.click()}
        >
          {selectedAsset.icon &&
            <Image
              className={styles.icon}
              src={selectedAsset.icon} />
          }
          <div className={cx(styles.uploadImage)}>
            <i aria-hidden="true" className="icon-add icon"></i>
            <span>Upload Image</span>
          </div>
          <input
            ref={uploadIconRef}
            type="file"
            accept="image/x-png, image/gif, image/jpeg"
            hidden
            onChange={handleUploadIcon} />
        </div>

        <div className={styles.assetInfo}>
          <InputField title='NAME'
            value={assetName}
            placeholder='Provide asset name'
            onChange={handleNameChange}
          />

          <TextAreaField title='DESCRIPTION'
            value={selectedAsset.summary}
            placeholder='Provide asset description'
            onChange={handleSummaryChange}
          />

          <TagsField tags={selectedAsset.tags} onChange={handleTagsChange} />

          <DropDownField
            title='TYPE'
            value={selectedAsset.metadata && selectedAsset.metadata.extension}
            placeholder='Provide Asset Type'
            onChange={handleEntityType}
          />
        </div>
      </div>
      <SubmitForm showArrows={false} className={styles.actionMenu} />

    </Segment>
  )
}

function InputField({ title, placeholder, value, onChange, error, ...rest }) {

  const handleChange = (e) => {
    onChange(e.target.value);
  }

  return (
    <div className={styles.field}>
      <Label size='small' active>{title}</Label>
      <Input error={error} placeholder={placeholder}
        value={value}
        onChange={handleChange}
        disabled={rest.disabled} />
    </div>
  )
}

function TextAreaField({ title, error, placeholder, value, onChange, ...rest }) {

  const handleChange = (e) => {
    onChange(e.target.value);
  }

  return (
    <div className={styles.field}>
      <Label size='small' active>{title}</Label>
      <Form>
        <TextArea placeholder={placeholder}
          rows={4}
          onChange={handleChange}
          value={value} />
      </Form>
    </div>
  )
}

function DropDownField({ title, placeholder, value, onChange }) {
  const handleChange = (e, { value }) => {
    onChange(value);
  }
  return (
    <div className={styles.field}>
      <Label size='small' active>{title}</Label>
      <Dropdown
        placeholder={placeholder}
        search
        value={value}
        fluid
        selection
        options={assetTypesOptions}
        onChange={handleChange}
      />
    </div>
  )
}

const Handle = React.forwardRef((props, ref) => {
  return (
    <div className={styles.handle} ref={ref}>
      <div className={styles.line}>
        {_.times(2).map((index) => {
          return <span key={index} />
        })}
      </div>
      <div className={styles.line}>
        {_.times(2).map((index) => {
          return <span key={index} />
        })}
      </div>
      <div className={styles.line}>
        {_.times(2).map((index) => {
          return <span key={index} />
        })}
      </div>
    </div>
  );
});

function TagsField({ tags, onChange, ...rest }) {
  const [options, setOptions] = useState((tags || []).map(item => {
    return {
      key: item,
      text: item,
      value: item
    }
  }) || '');

  const handleAddition = (e, { value }) => {
    setOptions([{ text: value, value }, ...options])
  }

  const handleChange = (e, { value }) => {
    onChange(value);
  }

  return (
    <div className={styles.tagField}>
      <Label size='small' active>TAGS</Label>
      <Dropdown
        className={styles.tags}
        search
        selection
        fluid
        multiple
        allowAdditions
        placeholder='Add Tags'
        options={options}
        value={tags}
        onAddItem={handleAddition}
        onChange={handleChange}
        noResultsMessage={null}
      />
    </div>
  )
}