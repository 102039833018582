import React, { useState } from 'react';
import _ from 'lodash';
import {useSelector} from 'react-redux';

import { useHistory } from 'react-router-dom';

import {
  Card,
  Image,
  Loader,
  List,
  Popup
} from 'semantic-ui-react';

import styles from './storyboard.module.scss';

export default function StoryboardSwitcherView(props) {
  const history = useHistory();

  const loading = useSelector(state => _.get(state, 'storyboards.loading'));
  const storyboards = useSelector(state => _.get(state, 'storyboards.items'));

  const handleStoryboardClicked = (storyboard) => {
    history.push(`/app/${storyboard.id}/pages`);
  }

  return (
    <Popup 
      className={styles.StoryboardSwitcher}
      position='right center' 
      hoverable 
      popperModifiers={{
        preventOverflow: {
          boundariesElement: "window"
        }
      }}
      offset={props.offset}
      flowing
      content={
        <StoryboardPopup 
          loading={loading}
          storyboards={storyboards}
          onStoryboardClick={handleStoryboardClicked}
        />
      }
      trigger={props.trigger}
    >
      
    </Popup>
  );
}

function StoryboardPopup({loading, storyboards = [], onStoryboardClick}){
  const items = [];
  const showLoading = loading && storyboards.length == 0;

  return (
    <Card className={styles.popup}>
      <Card.Content>
        <Card.Header>My Storyboards</Card.Header>
      </Card.Content>
      <Card.Content className={styles.storyboards}>
        <Loader active={showLoading} content='Loading storyboards...' />
        {!showLoading &&
          <List divided relaxed>
            {storyboards.map(storyboard => {
              return <Storyboard content={storyboard} onClick={onStoryboardClick}/>
            })}
          </List>  
        }
        
      </Card.Content>
      {/* <Card.Content extra>
        <a>
          <Icon name='add' />
          New Storyboard
        </a>
      </Card.Content> */}
    </Card>
  );
}

function Storyboard({content, onClick}){
  const handleClick = () => {
    if(onClick){
      onClick(content);
    }
  }

  return (
    <List.Item className={styles.storyboard} onClick={handleClick}>
      <Image avatar src={_.get(content, 'icon.thumbnail', null)} verticalAlign='middle'/>
      <List.Content verticalAlign='middle'>
        <List.Header>{content.name}</List.Header>
        <List.Description>{content.baseUrl}</List.Description>
      </List.Content>
    </List.Item>
  );
}