import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { updateProperties, updateWidget } from 'actions/widgetEditor';

import { QuickSettings, QuickSettingsProperty } from 'components/QuickSettings';
import { Property } from 'components/Properties';
import { WidgetRegistry } from '../registry.jsx';
import TabsModal from './tabs';
import { Widget, getStyles } from '../widget.jsx';
import { useWidgetDrop } from "services/dnd.service";

import _ from 'lodash';
import cx from 'classnames';
import Measure from 'react-measure';

import {
  Menu,
  Tab,
  Button,
  Header
} from 'semantic-ui-react';

import styles from './section.module.scss';

export const Section = (props) => {
  
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [menuDimensions, setMenuDimensions] = useState({});

  const ref = React.useRef(null);
  const dropRef = useWidgetDrop(props, ref, {});

  var widgets = props.widgets || [];
  const properties = props.properties;

  var selectedItem = widgets[selectedIndex];
  var selectetItemWidget = useSelector(state => {
    const editorContext = state.widgetsEditor.editors[props.editor];

    if (editorContext) {
      return editorContext.widgetsById[selectedItem];
    }

    return null;
  });

  var selectedContainer = selectetItemWidget.widgets;

  const handleTabChange = (index) => {
    setSelectedIndex(index);
  }

  const widgetStyles = getStyles(properties);

  return (
    <div className={cx(styles.section, props.className)}
      id={`widget_${props.id}`}
      ref={props.dragRef(dropRef(ref))}
      onMouseOver={props.onMouseOver}
      onMouseOut={props.onMouseOut}
      onClick={props.onClick}>
      <Measure
        bounds
        onResize={contentRect => {
          setMenuDimensions(contentRect.bounds);
          console.log(contentRect.bounds);
        }}
      >
        {({ measureRef }) => (
          <div ref={measureRef}>
            <div className={cx('ui', 'secondary', 'menu', styles.sectionMenu)}
              style={widgetStyles}>
              {widgets.map((item, index) => {
                return <MenuItem 
                  key={index} 
                  index={index} 
                  selectedIndex={selectedIndex}
                  id={item} 
                  editor={props.editor} 
                  onTabChange={handleTabChange} 
                  properties={properties} />
              })}
            </div>
          </div>
        )}
      </Measure>
      
      <Widget.Children widgets={selectedContainer} editor={props.editor} editable={props.editable} />
      {props.children}
    </div>
  )
}

function MenuItem({ index, selectedIndex, id, editor, onTabChange, properties, ...rest }) {

  const [hover, setHover] = useState(false);

  const regularProperties = _.get(properties, 'regular', {});
  const regularStyle = getStyles(regularProperties);

  const activeProperties = _.get(properties, 'clicked', {});
  const activeStyle = getStyles(activeProperties);

  const hoverProperties = _.get(properties, 'hover', {});
  const hoverStyle = getStyles(hoverProperties);

  var widget = useSelector(state => {
    const editorContext = state.widgetsEditor.editors[editor];

    if (editorContext) {
      return editorContext.widgetsById[id];
    }

    return null;
  });

  const handleMouseEnter = () => {
    setHover(true);
  }

  const handleMouseLeave = () => {
    setHover(false);
  }

  const renderMenuStyle = (active) => {
    var style = regularStyle;
    if(active && !hover) {
      style = {
        ...style,
        ...activeStyle,
      };
    }

    if(active && hover) {
      style = {
        ...style,
        ...activeStyle,
        ...hoverStyle
      }
    }

    if(hover) {
      style = {
        ...style,
        ...hoverStyle
      };
    }

    return style;
  }

  return (
    // eslint-disable-next-line jsx-a11y/anchor-is-valid
    <a href="#" 
      style={renderMenuStyle(selectedIndex === index)} 
      className={cx('item', { ['active']: selectedIndex === index })} 
      onClick={onTabChange.bind(this, index)}
      onMouseEnter={handleMouseEnter} 
      onMouseLeave={handleMouseLeave}>
      {widget.name || ''}
    </a>
  )
}

function SectionQuickSettings(props) {
  const dispatch = useDispatch();

  const [selected, setSelected] = useState('tabs');

  const menuItems = [
    { id: 'tabs', title: 'Tabs', icon: 'icon-content'},
    { id: 'tabs_style', title: 'Tab Style', icon: 'icon-content'},
    { id: 'spacing', title: 'Spacing', icon: 'icon-spacing' },
    { id: 'border', title: 'Border', icon: 'icon-border' },
    { id: 'advanced', title: 'Advanced', icon: 'icon-custom' }
  ]

  const handleMenuClick = (item) => {
    setSelected(item);
  }

  var editorContext = useSelector(state => state.widgetsEditor.editors[props.editor]);

  var widget = editorContext.widgetsById[props.id];
  if (!widget) {
    return false;
  }

  var properties = widget.properties;
  const handleChange = (changeRequest) => {
    dispatch(updateProperties({
      id: widget.id,
      change: changeRequest,
      context: props.editor
    }));
  }

  const handleCssChange = (css) => {
    var changeRequest = { ...widget };
    _.set(changeRequest, 'properties.css', css);
    changeRequest = WidgetRegistry.processRequest([changeRequest])[0];
    dispatch(updateWidget(props.id, changeRequest, props.editor));
  }

  return (
    <QuickSettings menuItems={menuItems} selected={selected} onMenuClick={handleMenuClick}
      segmentClassName={selected === 'tabs_style' ? styles.tabStyleSettings : null}>
      {selected === 'tabs' && 
        <EditTabs widget={widget} editor={props.editor} />
      }

      {selected === 'tabs_style' && 
        <MenuSelectionSettings widget={widget} editor={props.editor} properties={properties}/>
      }

      {selected === 'spacing' &&
        <>
          <QuickSettingsProperty.Spacing
            title='Margin'
            value={properties.margin}
            onChange={(margin) => {
              handleChange({
                margin: margin
              });
            }}
          />
          <QuickSettingsProperty.Spacing
            title='Padding'
            value={properties.padding}
            onChange={(padding) => {
              handleChange({
                padding: padding
              });
            }}
          />
        </>
      }

      {selected === 'border' &&
        <QuickSettingsProperty.Border expanded value={properties.border} onChange={(border) => {
          handleChange({
            border: border
          });
        }}
        />
      }

      {selected === 'advanced' &&
        <QuickSettingsProperty.CSS title='Stylesheets' value={properties.css} onChange={handleCssChange} />
      }
    </QuickSettings>
  )
}

Section.QuickSettings = SectionQuickSettings;

function EditTabs(props) {
  return (
    <>
      <QuickSettingsProperty title='Menu'>
        <TabsModal trigger={
          <Button>Edit Tabs</Button>
        } widget={props.widget} editor={props.editor} />
      </QuickSettingsProperty>
    </>
  )
}

function MenuSelectionSettings(props) {

  const panes = [
    {
      menuItem: 'Regular',
      render: () => {
        var regular = _.get(props.properties, 'regular', {}) || {};
        return <MenuSelectionTabPane type='regular' 
          item={regular} 
          widget={props.widget} 
          editor={props.editor}/>
      }
    },
    {
      menuItem: 'Active',
      render: () => {
        var active = _.get(props.properties, 'clicked', {}) || {};
        return <MenuSelectionTabPane type="clicked" 
          item={active}
          widget={props.widget}
          editor={props.editor} />
      }
    },
    {
      menuItem: 'Hover',
      render: () => {
        var hover = _.get(props.properties, 'hover', {}) || {};
        return <MenuSelectionTabPane type="hover"
          item={hover}
          widget={props.widget}
          editor={props.editor} />
      }
    }
  ]

  return (
    <Tab menu={{ secondary: true, pointing: true }} className={styles.styleTab} panes={panes} />
  )
}

function MenuSelectionTabPane({ type, item, widget, editor }) {
  const dispatch = useDispatch();

  const handleChange = (changeRequest) => {
    dispatch(updateProperties({
      id: widget.id,
      change: {
        [type]: {
          ...widget[type],
          ...item,
          ...changeRequest,
        }
      },
      context: editor
    }));
  }

  return (
    <Tab.Pane>
      <Property.Section title='Color' expanded className={styles.background}>
        <Property.Color
          title="Foreground"
          value={item.color}
          onChange={(value) => {
            handleChange({
              color: value
            })
          }}
        />

        <Property.Color
          title="Background"
          value={_.get(item, 'background.color', null)}
          onChange={(value) => {
            handleChange({
              background: {
                color: value
              }
            })
          }}
        />
      </Property.Section>

      <Property.Border expanded value={item.border} onChange={(border) => {
        handleChange({
          border: border
        });
      }}
      />
    </Tab.Pane>
  )
}

function SectionQuickAction(props) {
  return (
    <TabsModal trigger={
      <Widget.Action className={styles.quickAction}>
        Edit Tabs
      </Widget.Action>
    } widget={props.widget} editor={props.editor} />
  )
}

Section.QuickAction = SectionQuickAction;