import React, { useState, useEffect } from 'react';

import cx from 'classnames';
import _, { update } from 'lodash';
import dateformat from "dateformat";
import numeral from 'numeral';
import moment from 'moment';
import Truncate from 'react-truncate';

import SemanticDatepicker from 'react-semantic-ui-datepickers';
import 'react-semantic-ui-datepickers/dist/react-semantic-ui-datepickers.css';

import {
  Header,
  Grid,
  Image,
  Button,
  Label,
  Icon,
  Progress,
  Popup as SemanticPopup,
  Segment,
  List,
  Table
} from 'semantic-ui-react';

import styles from '../reports.module.scss';

import { getInitials, padNumber } from 'services/util';
import { DATE_RANGES, FOURTEEN_DAYS, CUSTOM } from 'constants/report.jsx';

import DefaultAssetIcon from 'images/default_asset.jpg';
import { Link, useRouteMatch } from "react-router-dom";
import { getBaseUrl } from 'services/util';
import FileService from 'services/file.service.jsx';
import { getReportDatesForOption } from 'services/report.service';

export function VisitorRow({ session, visitor, onVisitorClick, onSessionClick }) {
  var firstName = visitor.firstName || '';
  var lastName = visitor.lastName || '';

  const assetIcon = session.lastViewedAsset && session.lastViewedAsset.icon ? session.lastViewedAsset.icon.thumbnail : DefaultAssetIcon;

  let timeSpent = session.duration;
  let minutes = padNumber(Math.floor(timeSpent / 60), 2);
  let seconds = padNumber(Math.floor(timeSpent % 60), 2);

  const handleVisitorClick = () => {
    onVisitorClick(visitor)
  }

  const handleSessionClick = () => {
    onSessionClick(session)
  }

  const getGeography = (session) => {
    if (!_.get(session, 'country.city')) {
      return 'UNKNOWN';
    } else {
      return `${_.get(session, 'country.city') || 'UNKNOWN'}, ${_.get(session, 'country.region_name')}, ${_.get(session, 'country.country_code')}`
    }
  }

  return (
    <Grid.Row columns='equal' className={styles.visitorRow}>
      <Grid.Column onClick={handleVisitorClick} className={styles.visitorColumn}>
        <Header as={'h4'}>
          <Avatar firstName={firstName} lastName={lastName} />
          <Header.Content className={styles.name}>
            {`${firstName} ${lastName}`}
            <Header.Subheader className={styles.email}>{visitor.email}</Header.Subheader>
            <Header.Subheader className={styles.info}>{dateformat(session.createdDate, "dd mmmm yyyy h:MM TT")}</Header.Subheader>
          </Header.Content>
        </Header>
      </Grid.Column>
      <Grid.Column onClick={handleSessionClick} className={styles.visitorColumn}>
        <Header as={'h5'}>
          <Image src={assetIcon} />
          <Header.Content className={styles.name}>
            {`${session.assetsCount || 0} Assets - ${minutes}:${seconds}`}
            <Header.Subheader>
              <Truncate lines={1} className={styles.info}>{session.lastViewedAsset ? session.lastViewedAsset.name : ""}</Truncate>
            </Header.Subheader>
          </Header.Content>
        </Header>
      </Grid.Column>
      <Grid.Column>
        <Header as={'h5'} textAlign='right'>
          <Header.Content className={styles.name}>
            {getGeography(session)}
            <Header.Subheader className={styles.info}>{_.get(session, 'os') || 'NA'} - {_.get(session, 'browser') || 'NA'}</Header.Subheader>
          </Header.Content>
        </Header>
      </Grid.Column>
    </Grid.Row>
  );
}

export function TopVisitorRow({ visitor, onVisitorClick }) {
  var firstName = visitor.firstName || '';
  var lastName = visitor.lastName || '';

  const handleVisitorClick = () => {
    onVisitorClick(visitor)
  }

  let timeSpent = _.get(visitor, 'avgTimeSpent', 0);
  let minutes = padNumber(Math.floor(timeSpent / 60), 2);
  let seconds = padNumber(Math.floor(timeSpent % 60), 2);
  var avgTimeSpent = minutes ? `${minutes}:${seconds}` : seconds;

  return (
    <Grid.Row columns='equal' className={styles.visitorRow}>
      <Grid.Column onClick={handleVisitorClick} className={styles.visitorColumn}>
        <Header as={'h4'}>
          <Avatar firstName={firstName} lastName={lastName} />
          <Header.Content className={styles.name}>
            {`${firstName} ${lastName}`}
            <Header.Subheader className={styles.info}>{`${_.get(visitor, 'sessions', 0)} Sessions | ${avgTimeSpent}  Avg time`}</Header.Subheader>
          </Header.Content>
        </Header>
      </Grid.Column>
    </Grid.Row>
  );
}

export function Avatar({ firstName, lastName }) {
  var initials = getInitials(firstName, lastName);

  return (
    <div className={styles.avatar}>{initials}</div>
  );
}

export function Pagination({ pagination, onClick }) {
  const { current, totalPages, totalRecords } = pagination;

  const start = (current * 5) + 1;
  const end = Math.min(start + 4, totalRecords);
  const show = current >= 0 && totalPages > 1;

  const handlePrevious = () => {
    var page = current - 1;
    if (page >= 0) {
      onClick(page);
    }
  }

  const handleNext = () => {
    var page = current + 1;
    if (page < totalPages) {
      onClick(page);
    }
  }

  if (!show) {
    return false;
  }

  return (
    <div className={styles.pagination}>
      {current > 0 &&
        <Button basic>
          <Icon name="angle left" size='large' onClick={handlePrevious} />
        </Button>
      }

      <span className={styles.pageNumber}>{start} - {end} of {pagination.totalRecords}</span>

      {current < totalPages - 1 &&
        <Button basic>
          <Icon name="angle right" size='large' onClick={handleNext} />
        </Button>
      }
    </div>
  );
}

export function CategoryReport({ keyLabel, valueLabel, metrics, onClick }) {
  if (!metrics) {
    return false;
  }

  const { loading, items = [], pagination } = metrics;

  return (
    <div className={styles.categoryReport}>
      <div className={cx(styles.row, styles.header)}>
        <div className={styles.label}>{keyLabel}</div>
        <div className={styles.value}>{valueLabel}</div>
      </div>

      <div className={styles.rows}>
        {!loading && items.length > 0 && (items || []).map((metric, index) => {
          return (
            <div key={metric.name + "_" + index} className={cx(styles.row)}>
              <div className={styles.details}>
                <div className={styles.label}>{metric.name}</div>
                <div className={styles.value}>
                  <span>{metric.sessions}</span>
                  <span className={styles.percent}>{numeral(metric.sessionPercentage).format('0.00%')}</span>
                </div>
              </div>
              <Progress percent={metric.sessionPercentage * 100} size='tiny'></Progress>
            </div>
          );
        })}
      </div>

      {!loading && items.length == 0 &&
        <EmptyMessage title="Insufficient data to generate report." />}

      {pagination &&
        <Label attached='bottom'>
          <Pagination pagination={pagination} onClick={onClick} />
        </Label>
      }
    </div>
  );
}

export function PageReport({ keyLabel, valueLabel, metrics, onClick }) {
  if (!metrics) {
    return false;
  }

  const { loading, items = [], pagination } = metrics;

  return (
    <div className={styles.categoryReport}>
      <div className={cx(styles.row, styles.header)}>
        <div className={styles.label}>{keyLabel}</div>
        <div className={styles.value}>{valueLabel}</div>
      </div>

      <div className={styles.rows}>
        {!loading && items.length > 0 && (items || []).map((metric, index) => {
          return (
            <div key={metric.name + "_" + index} className={cx(styles.row)}>
              <div className={styles.details}>
                <div className={styles.label}>
                  {metric.name}
                  <div className={styles.meta}>{metric.alias}</div>
                </div>
                <div className={styles.value}>
                  <span>{metric.sessions}</span>
                  <span className={styles.percent}>{numeral(metric.sessionPercentage).format('0.00%')}</span>
                </div>
              </div>
              <Progress percent={metric.sessionPercentage * 100} size='tiny'></Progress>
            </div>
          );
        })}
      </div>

      {!loading && items.length == 0 &&
        <EmptyMessage title="Insufficient data to generate report." />}

      {pagination &&
        <Label attached='bottom'>
          <Pagination pagination={pagination} onClick={onClick} />
        </Label>
      }
    </div>
  );
}

export function ContentRow({ item }) {

  let { url, path } = useRouteMatch();
  let baseUrl = getBaseUrl(url);

  var icon = _.get(item.asset, 'icon.thumbnail', '/images/default_asset.jpg');

  const timeSpentValue = (timeSpent) => {
    let timeSpentValue = `${padNumber(Math.round(timeSpent), 1)}s`;

    if (timeSpent >= 60) {
      timeSpentValue = `${padNumber(Math.round(timeSpent / 60), 1)}m`;
    }

    return timeSpentValue;
  }

  return (
    <Table.Row className={cx(styles.asset)}>
      <Table.Cell width={6}>
        <Header as='h4' className={styles.assetDetail}>
          <Image src={icon} />
          <Header.Content className={styles.assetName}>
            <Link to={`${baseUrl}/assets/${item.asset.id}`}><Truncate lines={1}>{item.asset.name}</Truncate></Link>
            <Header.Subheader>
              {`${FileService.fileType(item.asset.metadata)} ${item.asset.metadata.content_type !== 'application/url' ? `| ${FileService.formatSize(item.asset.metadata.content_length)}` : ``}`}
            </Header.Subheader>
          </Header.Content>
        </Header>
      </Table.Cell>
      <Table.Cell textAlign="center">{item.totalViews}</Table.Cell>
      <Table.Cell textAlign="center">{item.downloads}</Table.Cell>
      <Table.Cell textAlign="center">{timeSpentValue(item.totalTimeSpent)}</Table.Cell>
      <Table.Cell textAlign="center">{dateformat(item.asset.modifiedDate, 'dd mmm yyyy')}</Table.Cell>
      <Table.Cell textAlign="center">{dateformat(item.asset.createdDate, 'dd mmm yyyy')}</Table.Cell>
    </Table.Row>
  );
}

export function DatePicker(props) {
  const [open, setOpen] = useState(false);

  const [reportDate, setReportDate] = useState({
    type: FOURTEEN_DAYS,
    startDate: _.get(props.value, 'startDate') || moment(),
    endDate: _.get(props.value, 'endDate') || moment()
  });

  console.log(reportDate);

  useEffect(() => {
    console.log(props.value);


    if (!props.value) {
      return;
    }

    console.log(_.get(props.value, 'startDate'));
    console.log(_.get(props.value, 'endDate'));

    setReportDate((state) => {
      return ({
        ...state,
        type: _.get(props.value, 'type') || FOURTEEN_DAYS,
        startDate: _.get(props.value, 'startDate') || moment(),
        endDate: _.get(props.value, 'endDate') || moment()
      })
    });
  }, [props.value]);

  const handleOpen = () => {
    setOpen(true);
  }

  const handleClose = () => {
    setOpen(false);
  }

  const handleDateRangeClick = (range) => {
    props.onChange({
      type: range.label,
      ...getReportDatesForOption(range.label)
    });

    handleClose();
  }

  const onStartDateChange = (event, data) => {
    if (data.value) {
      const updated = moment(data.value);
      setReportDate((state) => {
        return ({
          ...state,
          startDate: updated,
          endDate: moment.max(updated, reportDate.endDate)
        })
      });
    }

  };

  const onEndDateChange = (event, data) => {
    if (data.value) {
      const updated = moment(data.value);
      console.log(updated);
      setReportDate((state) => {
        return ({
          ...state,
          startDate: moment.min(updated, reportDate.startDate),
          endDate: updated
        })
      });
    }
  };

  const onSubmit = () => {

    props.onChange({
      type: CUSTOM,
      startDate: reportDate.startDate,
      endDate: reportDate.endDate
    });

    handleClose();
  }

  const renderLabel = () => {
    const type = _.get(props, 'value.type') || FOURTEEN_DAYS;

    if (type === CUSTOM) {
      const startDate = _.get(props.value, 'startDate') || moment();
      const endDate = _.get(props.value, 'endDate') || moment();

      return `${dateformat(startDate, 'dd mmm yyyy')} - ${dateformat(endDate, 'dd mmm yyyy')}`;
    }

    const option = DATE_RANGES.find(item => item.label === type);
    return option.value;
  }

  return (
    <SemanticPopup
      trigger={
        <Button className={styles.datepicker}>
          {renderLabel()}
          <Icon name='dropdown' />
        </Button>
      }
      position='bottom left'
      pinned
      on='click'
      basic
      className={styles.datePickerPopup}
      open={open}
      onClose={handleClose}
      onOpen={handleOpen}
      style={{
        width: '200px',
        padding: 0
      }}>
      <Segment className={styles.datePickerSegment}>
        <List className={styles.dateSelectionList}>
          {DATE_RANGES.map(range => {
            return (
              <List.Item className={cx(styles.range, {
                [styles.selectedRange]: range.label === reportDate.type
              })
              } onClick={handleDateRangeClick.bind(this, range)}>
                <List.Icon name={'checkmark'} />
                <List.Content>{range.value}</List.Content>
              </List.Item>
            )
          })}
        </List>

        <div className={styles.customDates}>
          <div className={styles.customDate}>
            <span>Start Date:</span>
            <SemanticDatepicker onChange={onStartDateChange}
              pointing='right'
              clearable={false}
              format='DD MMM YYYY'
              maxDate={moment().toDate()}
              value={reportDate.startDate.toDate()}
            />
          </div>

          <div className={styles.customDate}>
            <span>End  Date:</span>
            <SemanticDatepicker onChange={onEndDateChange}
              pointing='right'
              clearable={false}
              format='DD MMM YYYY'
              maxDate={moment().toDate()}
              value={reportDate.endDate.toDate()} />
          </div>
        </div>

        <div className={styles.submitButton}>
          <Button size='mini' onClick={onSubmit}>Apply</Button>
        </div>
      </Segment>
    </SemanticPopup>
  )
}

export function EmptyMessage(props) {
  return (
    <div className={styles.emptyMessage}>
      <h5>{props.title}</h5>
    </div>
  )
}