import React, { Fragment, useState, useEffect, useRef, forwardRef, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { resizeWidget, updateProperties } from 'actions/widgetEditor';

import _ from 'lodash';
import cx from 'classnames';
import Draggable from 'react-draggable';

import styles from './widget.module.scss';
import { isResizable, getResizableWidget, getClosestColumnForWidget } from 'services/widget.helper';
import { invalidateWidgetResize } from 'actions/widgetEditor';


export function Resizable({handles, onResizing, onResize}) {

  return (
    (handles || []).map(handle => {
      return <Anchor handle={handle} onResizing={onResizing} onResize={onResize} />
    })
  );
}

function Anchor({handle, onResizing, onResize}){
  var axis = 'both';
  if(_.includes(['w', 'e'], handle)){
    axis = 'x';
  }else if(_.includes(['n', 's'], handle)){
    axis = 'y';
  }

  const handleResizing = _.throttle((e, ui) => {
    if (onResizing) {
      onResizing({
        x: axis === 'y' ? 0 : ui.deltaX,
        y: axis === 'x' ? 0 : ui.deltaY
      });
    }
  }, 100);

  const handleStop = (e, ui) => {
    if (onResize) {
      onResize();
    }
  }

  return (
    <>
      <div className={cx(styles.anchor, styles[handle])}></div>
      <Draggable handle={`.${styles.handle}`} axis={axis} onDrag={handleResizing} onStop={handleStop}>
        <div className={cx(styles.handle, styles[handle])}></div>
      </Draggable>
    </>
  )
}

export function ColumnResizer({item, editor}) {
  const dispatch = useDispatch();

  const editorContext = useSelector(state => {
    return state.widgetsEditor.editors[editor];
  });

  const resizable = isResizable(editorContext, item);

  const handleResizing = useCallback((delta) => {
    const widget = getResizableWidget(editorContext, item);

    if (!widget) {
      return;
    }

    const resizable = _.get(item, 'capabilities.resizable', true);
    if (!resizable) {
      return;
    }

    const selector = `#widget_${widget.id}`;
    const element = document.querySelector(selector);
    if (!element) {
      return;
    }

    const rowWidth = element.parentElement.offsetWidth;
    const minWidth = rowWidth / 16;
    var width = element.offsetWidth + delta.x;

    width = Math.max(width, minWidth);
    width = Math.min(width, rowWidth);

    dispatch(resizeWidget(editor, widget.id, {
      width: width
    }));

  }, []);

  const handleResizeFinished = useCallback(() => {
    const widget = getClosestColumnForWidget(editorContext, item.id);

    if (!widget) {
      return;
    }

    const resizable = _.get(item, 'capabilities.resizable', true);
    if (!resizable) {
      return;
    }

    const selector = `#widget_${widget.id}`;
    const element = document.querySelector(selector);
    if (!element) {
      return;
    }

    const width = element.offsetWidth
    const rowWidth = element.parentElement.offsetWidth;
    const widthPerColumn = rowWidth / 16;

    var columns = Math.round(width / widthPerColumn);
    columns = Math.min(columns, 16);
    columns = Math.max(columns, 1);

    dispatch(updateProperties({
      id: widget.id,
      change: {
        width: columns
      },
      context: editor
    }));

    dispatch(invalidateWidgetResize(editor));
  });

  return (
    <Resizable handles={['e']} onResizing={handleResizing}  onResize={handleResizeFinished} />
  );
}