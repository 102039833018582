import {
  CREATING_CLUSTER, CREATING_CLUSTER_SUCCEEDED, CREATING_CLUSTER_FAILED,
  UPDATING_CLUSTER, UPDATING_CLUSTER_SUCCEEDED, UPDATING_CLUSTER_FAILED,
  FETCH_CLUSTER_REQUEST, FETCHING_CLUSTER_SUCCEEDED, FETCHING_CLUSTER_FAILED,
  FETCHING_ALL_CLUSTERS, FETCHING_ALL_CLUSTERS_SUCCEEDED, FETCHING_ALL_CLUSTERS_FAILED,
  INVALDATE_CLUSTER_REQUEST, UPDATE_CLUSTER,
  DELETE_CLUSTER_REQUEST, DELETE_CLUSTER_SUCCEEDED, DELETE_CLUSTER_FAILED
} from 'constants/actionTypes';

import { call, put, takeLatest, takeEvery } from 'redux-saga/effects';
import axios from 'axios';
import { createAction } from 'redux-actions';
import buildUrl from 'build-url';

const apiUrl = `${process.env.REACT_APP_API_URL}/api/2.0`;

const CLUSTERS_BASE_URL = `${apiUrl}/assets/lists`;

/**
* Create cluster
*/
export const createCluster = createAction(CREATING_CLUSTER, (request) => {
  return request;
});

const createClusterSucceeded = createAction(CREATING_CLUSTER_SUCCEEDED, (response) => {
  return response;
});

const createClusterFailed = createAction(CREATING_CLUSTER_FAILED, (error) => {
  return error;
});

function* createClusterSaga(action) {
  var request = action.payload;

  const createClusterAPI = () => {
    return axios.post(CLUSTERS_BASE_URL, request).then(response => response.data);
  }

  try {
    const response = yield call(createClusterAPI);
    yield put(createClusterSucceeded(response));
  } catch (error) {
    yield put(createClusterFailed(error));
  }
}

/**
* Update cluster
*/
export const updateCluster = createAction(UPDATING_CLUSTER, (id, request) => {
  return {
    id: id,
    request: request
  }
});

const updateClusterSuccedded = createAction(UPDATING_CLUSTER_SUCCEEDED, (id, response) => {
  return {
    id: id,
    response: response
  }
});

const updateClusterFailed = createAction(UPDATING_CLUSTER_FAILED, (id, error) => {
  return {
    id: id,
    error: error
  }
});

function* updateClusterSaga(action) {
  var { id, request } = action.payload;

  let url = buildUrl(CLUSTERS_BASE_URL, {
    path: `/${id}`
  });

  const updateClusterAPI = () => {
    return axios.put(url, request).then(response => response.data);
  }

  try {
    const response = yield call(updateClusterAPI);
    yield put(updateClusterSuccedded(id, response));
  } catch (error) {
    yield put(updateClusterFailed(id, error));
  }
}

/**
* Fetch all clusters
*/
export const fetchAllClusters = createAction(FETCHING_ALL_CLUSTERS);
const fetchAllClustersSucceeded = createAction(FETCHING_ALL_CLUSTERS_SUCCEEDED, (response) => {
  return response;
});
const fetchAllClustersFailed = createAction(FETCHING_ALL_CLUSTERS_FAILED, (error) => {
  return error;
});

function* fetchAllClustersSaga() {

  const fetchAllClustersAPI = () => {
    return axios.get(CLUSTERS_BASE_URL).then(response => response.data);
  }

  try {
    const response = yield call(fetchAllClustersAPI);
    yield put(fetchAllClustersSucceeded(response));
  } catch (error) {
    yield put(fetchAllClustersFailed(error));
  }
}

/**
* Fetch a cluster
*/
export const fetchCluster = createAction(FETCH_CLUSTER_REQUEST, (id, callback) => {
  return {
    id: id,
    callback: callback
  }
});

const fetchingClusterSuccedded = createAction(FETCHING_CLUSTER_SUCCEEDED, (id, response) => {
  return {
    id: id,
    response: response
  }
});

const fetchingClusterFailed = createAction(FETCHING_CLUSTER_FAILED, (id, error) => {
  return {
    id: id,
    error: error
  }
});

function* fetchClusterSaga(action) {
  var { id, callback } = action.payload;

  let url = buildUrl(CLUSTERS_BASE_URL, {
    path: `/${id}`
  });

  const fetchClusterAPI = () => {
    return axios.get(url).then(response => response.data);
  }

  try {
    const response = yield call(fetchClusterAPI);
    yield put(fetchingClusterSuccedded(id, response));
    if(callback){
      callback(response);
    }
  } catch (error) {
    yield put(fetchingClusterFailed(id, error));
  }
}

export const updateClusterChanges = createAction(UPDATE_CLUSTER, (cluster) => {
  return cluster;
})

export const invalidateCluster = createAction(INVALDATE_CLUSTER_REQUEST);

/**
* Delete a cluster
*/
export const deleteCluster = createAction(DELETE_CLUSTER_REQUEST, (id, callback) => {
  return {
    id: id,
    callback: callback
  };
})

const deleteClusterSucceeded = createAction(DELETE_CLUSTER_SUCCEEDED, (id, response) => {
  return {
    id, response
  };
})

const deleteClusterFailed = createAction(DELETE_CLUSTER_FAILED, (id, error) => {
  return {
    id, error
  };
})

function* deleteClusterSaga(action) {
  const { id, callback } = action.payload;

  let url = buildUrl(CLUSTERS_BASE_URL, {
    path: `/${id}`
  });

  const deleteClusterAPI = () => {
    return axios.delete(url).then(response => response.data);
  }

  try {
    const response = yield call(deleteClusterAPI);
    yield put(deleteClusterSucceeded(id, response));
    if (callback) {
      callback(response);
    }
  } catch (error) {
    yield put(deleteClusterFailed(id, error));
  }
}

export default function* clusterSaga() {
  yield takeLatest(CREATING_CLUSTER, createClusterSaga);
  yield takeLatest(UPDATING_CLUSTER, updateClusterSaga);
  yield takeLatest(FETCHING_ALL_CLUSTERS, fetchAllClustersSaga);
  yield takeEvery(FETCH_CLUSTER_REQUEST, fetchClusterSaga);
  yield takeEvery(DELETE_CLUSTER_REQUEST, deleteClusterSaga);
};