import React, { useState, useEffect, createRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import _ from 'lodash';
import cx from 'classnames';

import {
  Segment,
  Image,
  Header,
  Label,
  Button,
  Input,
  Form,
  TextArea,
  Dropdown
} from 'semantic-ui-react';

import styles from './addContent.module.scss';

import { AddContentContext, SubmitForm } from 'components/Asset/AddContent';
import { fetchAliases, invalidateAliases } from 'actions/aliases';

import { updateAssetInContentEditor } from 'actions/contentSelection';
import ImageTools from 'services/imagetools.service';

import { fetchWidgetAsset } from 'actions/widgetEditor'

export default function EditAsset(props) {
  const dispatch = useDispatch();

  const { editor } = React.useContext(AddContentContext);
  const content = useSelector(state => state.contentSelection.editors[editor]);
  const assets = _.get(content, 'assets', []);
  var selectedAsset = _.get(content, 'selectedAsset', null);
  var isOverride = _.get(content, 'selectedAsset.override', false);
  var resetting = _.get(content, 'resetting', false);
  var aliases = useSelector(state => _.get(state, 'aliases.items'));

  var canonicalUrl = aliases.find(alias=> {
    if(alias.canonical) {
      return alias;
    }
  });

  const aliasesOptions = (aliases || []).map(item => {
    return {
      key: item.id,
      value: item.id,
      text: item.url
    }
  });

  const [alias, setAlias] = useState(_.get(selectedAsset, 'alias.id') || canonicalUrl && canonicalUrl.id);

  useEffect(()=> {
    if(selectedAsset == null) {
      return;
    }
    if(aliases.length == 0) {
      return;
    }
    
    const alias = (aliases || []).find(item => item.url === selectedAsset.alias);

    if(!alias) {
      setAlias(canonicalUrl.id)
      return;
    }

    setAlias(alias.id)
  }, [aliases])

  useEffect(()=>{
    if(selectedAsset == null) {
      return;
    }

    dispatch(fetchAliases({
      type: selectedAsset.entityType,
      target: selectedAsset.id
      }, 0, 20 ));
  }, [selectedAsset]);

  const showArrows = assets.length > 0;

  const [errors, setErrors] = useState({});

  const uploadIconRef = React.createRef();

  const handleNameChange = (value) => {
    var request = {
      ...selectedAsset,
      name: value,
      updated: true
    }

    dispatch(updateAssetInContentEditor(editor, request));
  }

  const handleSummaryChange = (value) => {
    var request = {
      ...selectedAsset,
      summary: value,
      updated: true
    }

    dispatch(updateAssetInContentEditor(editor, request));
  }

  const handleAliasChange = (value) => {
    if(!selectedAsset.override) {
      return;
    }

    const alias = (aliases || []).find(item => item.id === value);
 
    var request = {
      ...selectedAsset,
      alias: alias.url,
      updated: true
    }

    dispatch(updateAssetInContentEditor(editor, request));
  }

  const handleUploadIcon = (e) => {
    ImageTools.resize(e.target.files[0], {
      width: 1024, // maximum width
      height: 1024 // maximum height
    }, (blob, didItResize) => {
      var reader = new FileReader();
      reader.onload = (e) => {
        var icon = e.target.result;

        var request = {
          ...selectedAsset,
          icon: icon,
          isIconSet: true,
          updated: true
        }

        dispatch(updateAssetInContentEditor(editor, request));
      };

      reader.readAsDataURL(blob);
    });
  }

  if (!selectedAsset) {
    return null;
  }

  const handleOverrideButton = () => {
    var request = {
      ...selectedAsset,
      override: !isOverride
    }

    dispatch(updateAssetInContentEditor(editor, request));

    if (isOverride) {
      dispatch(fetchWidgetAsset(selectedAsset.id, editor, (res) => {
        var request = {
          ...selectedAsset,
          override: !isOverride,
          alias: canonicalUrl.url,
          updated: true
        }
    
        dispatch(updateAssetInContentEditor(editor, request));
      }))
    }
  }

  const buttonText = () => {
    if (resetting) {
      return "loading...";
    }
    if (isOverride) {
      return "Set to Default"
    }
    return "Override"
  }

  return (
    <Segment basic className={styles.editAsset}>
      <Header as='h3' className={styles.header}>
        Customise how your asset will appear to your visitors
      </Header>
      <div className={styles.overrideButton}>
        <Button
          content={buttonText()}
          onClick={handleOverrideButton}
        />
      </div>

      <div className={styles.editAssetContainer}>
        <div className={cx(styles.uploadImageContainer, {
          [styles.empty]: selectedAsset.icon == null
        })}
          onClick={() => {
            if (!isOverride) {
              return;
            }
            uploadIconRef.current.click()
          }}
        >
          {selectedAsset.icon &&
            <Image
              className={styles.icon}
              src={selectedAsset.icon} />
          }

          {isOverride &&
            <div className={cx(styles.uploadImage)}>
              <i aria-hidden="true" className="icon-add icon"></i>
              <span>Upload Image</span>
            </div>
          }
          <input
            ref={uploadIconRef}
            type="file"
            hidden
            onChange={handleUploadIcon} />
        </div>
        <div className={styles.assetInfo}>
          <InputField title='NAME'
            className={styles.assetName}
            value={selectedAsset.name}
            placeholder='Provide asset name'
            error={errors.name}
            onChange={handleNameChange}
            disabled={!isOverride}
          />

          <TextAreaField title='DESCRIPTION'
            value={selectedAsset.summary ? selectedAsset.summary : ""}
            placeholder='Please describe the asset'
            onChange={handleSummaryChange}
            disabled={!isOverride}
          />

          <Form.Field>
            <div className={styles.dropdownTitle}>Select Alias</div>
            <Dropdown
              className={styles.dropdown}
              selection
              disabled={!isOverride}
              options={aliasesOptions}
              value={alias}
              placeholder='choose custom alias for asset'
              onChange={(e, data) => {
                handleAliasChange(data.value);
              }}
            />
          </Form.Field>
        </div>
      </div>

      <SubmitForm showArrows={false} className={styles.actionMenu} />

    </Segment>
  )
}

function InputField({ title, placeholder, value, onChange, error, ...rest }) {

  const handleChange = (e) => {
    onChange(e.target.value);
  }

  return (
    <div className={styles.field}>
      <Label size='small' active>{title}</Label>
      <Input error={error} placeholder={placeholder}
        value={value}
        onChange={handleChange}
        disabled={rest.disabled} />
    </div>
  )
}

function TextAreaField({ title, placeholder, value, onChange, ...rest }) {

  const handleChange = (e) => {
    onChange(e.target.value);
  }

  return (
    <div className={styles.field}>
      <Label size='small' active>{title}</Label>
      <Form>
        <TextArea placeholder={placeholder}
          rows={4}
          onChange={handleChange}
          value={value}
          disabled={rest.disabled} />
      </Form>
    </div>
  )
}