import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateProperties, updateWidgetContent, updatingWidgetContentSucceeded } from 'actions/widgetEditor';
import { fetchMarketoForms, searchMarketoForm } from 'actions/integrations/marketo';

import _ from 'lodash';
import cx from 'classnames';

import {
  Form as SemanticForm,
  Dropdown,
  Icon,
  Input,
  Label,
  Button,
  Modal,
  Table,
  Checkbox,
  Dimmer,
  Loader,
  Segment,
  Header
} from 'semantic-ui-react';

import { guid, validateEmail } from 'services/util';
import Truncate from 'react-truncate';
import Tooltip from 'components/Tooltip';
import { Widget, getStyles, getDimensionStyles } from '../widget.jsx';
import { Property } from 'components/Properties';
import dateformat from 'dateformat';
import moment from 'moment';
import { QuickSettings, QuickSettingsProperty } from 'components/QuickSettings';

import { WidgetContext } from 'pages/pages/editor/editor.jsx';

import styles from './form.module.scss';

export const MarketoForm = (props) => {
  const dispatch = useDispatch();
  const [step, setStep] = useState(null);

  var widgets = props.widgets || [];
  var content = props.content;
  const properties = props.properties;
  var marketoFormStep = widgets[0];

  const { entityReference } = React.useContext(WidgetContext);

  const dimension = _.get(properties, 'dimension') || {};
  var dimensionStyles = calculateModalDimension(dimension);

  useEffect(() => {
    setStep(widgets[0]);
  }, [])

  return (
    <>
    {entityReference.type === 'page' &&
      <>
      <SemanticForm className={styles.externalForm}>
        <StepWidget step={step}
          id={props.id}
          marketoFormStep={marketoFormStep}
          editor={props.editor}
          content={content}
          properties={props.properties}
          editable={props.editable}
        />
      </SemanticForm>
      {props.children}

      <div className={styles.actions}>
        {props.selected &&
          <Steps
            editor={props.editor}
            widgets={widgets}
            value={step}
            marketoFormStep={marketoFormStep}
            onChange={(value) => {
              setStep(value);
            }}
          />
        }

        {props.selected &&
          <MarketoFormModal
            trigger={
              <div className={styles.steps}>
                <Button className={styles.pardotFormButton}>Select Form</Button>
              </div>
            } widget={props.id} editor={props.editor} properties={properties} />
        }
      </div>
      </>
    }

    {entityReference.type !== 'page' &&
      <div className={cx('ui', 'medium', 'modal', styles.modal, props.className)}
        id={`widget_${props.id}`}
        onMouseOver={props.onMouseOver}
        onMouseOut={props.onMouseOut}
        onClick={props.onClick}
        style={dimensionStyles}
      >
        <SemanticForm className={styles.externalForm}>
          <StepWidget step={step}
            id={props.id}
            marketoFormStep={marketoFormStep}
            editor={props.editor}
            content={content}
            properties={props.properties}
            editable={props.editable}
          />
        </SemanticForm>
        {props.children}

        <div className={styles.actions}>
          {props.selected &&
            <Steps
              editor={props.editor}
              widgets={widgets}
              value={step}
              marketoFormStep={marketoFormStep}
              onChange={(value) => {
                setStep(value);
              }}
            />
          }

          {props.selected &&
            <MarketoFormModal
              trigger={
                <div className={styles.steps}>
                  <Button className={styles.pardotFormButton}>Select Form</Button>
                </div>
              } widget={props.id} editor={props.editor} properties={properties} />
          }
        </div>
      </div>
    }
    </>
  );
}

function MarketoFormModal(props) {
  const dispatch = useDispatch();

  const [open, setOpen] = useState(false);
  const [selectedForm, setSelectedForm] = useState(props.properties.form);

  const forms = useSelector(state => _.get(state, "integrations.marketo.forms.items", []));
  const page = useSelector(state => _.get(state, "integrations.marketo.forms.page", 0));
  const limit = useSelector(state => _.get(state, "integrations.marketo.forms.limit", 20));
  const loadMore = useSelector(state => _.get(state, "integrations.marketo.forms.loadMore", false));
  const loading = useSelector(state => _.get(state, "integrations.marketo.forms.loading", false));
  const searching = useSelector(state => _.get(state, "integrations.marketo.forms.searching", false));
  const message = useSelector(state => _.get(state, "integrations.marketo.forms.message", ""));

  const integrations = useSelector(state => _.get(state, 'integrations.activated.items', []));

  const marketoIntegration = integrations.filter(item => {
    return item != null;
  }).find(item => {
    return item.type === 'marketo';
  })

  const munchkinId = useSelector(state => _.get(marketoIntegration,"configuration.munchkinId",null));

  const getMarketoDateFormat = (date) => {
    var momentDate = moment(`${date}`, 'YYYY-MM-DDTHH:mm:ss+-HH:mm:ss');
    var parsedDate = momentDate.toDate();
    var parsedMonth = parsedDate.toLocaleString('en-us', { month: 'short' });
    var marketoFormDate = parsedDate.getDate() + " " + parsedMonth + " " + parsedDate.getFullYear();
    return marketoFormDate;
  }

  useEffect(() => {
    if (page === 0 && !loading) {
      dispatch(fetchMarketoForms(page, limit));
    }
  }, [props.widget])

  const handleFormSelection = (form) => {

    if (form.id === selectedForm) {
      return;
    }

    setSelectedForm(form.id);
  }

  const handleAddForm = () => {
    var currentForm = forms.find(item => {
      return item.id === selectedForm;
    })

    dispatch(updatingWidgetContentSucceeded(props.widget, currentForm, props.editor));

    var domain = null;
    if(currentForm) {
      const {hostname} = new URL(currentForm.url);
      domain = `https://${hostname}`
    }
    dispatch(updateProperties({
      id: props.widget,
      change: {
        form: selectedForm,
        munchkinId : munchkinId,
        domain: domain
      },
      context: props.editor
    }));

    setOpen(false);
    dispatch(fetchMarketoForms(0, 20))
  }

  const handleClose = () => {
    dispatch(fetchMarketoForms(0, 20))
    setSelectedForm(props.properties.form);
    setOpen(false)
  }

  const handleLoadMore = () => {
    if (loading) {
      return;
    }

    dispatch(fetchMarketoForms(page, limit));
  }

  const handleSearch = (searchQuery) => {
    if(searchQuery === null || searchQuery.match(/^ *$/) !== null) {
      dispatch(fetchMarketoForms(0, 20));
    } else {
      dispatch(searchMarketoForm(searchQuery));
    }
  }

  return (
    <Modal size='large'
      trigger={props.trigger}
      className={styles.formSelectionModal}
      open={open}
      onOpen={() => {
        setOpen(true);
      }}>
      <Modal.Header>
        <div className={styles.formHeader}>
          <div className={styles.name}>Select Marketo Form</div>
          <div className={styles.search}>
            <Input
              icon='search'
              iconPosition='left'
              onChange={(e) => {
                handleSearch(e.target.value)
              }}
              placeholder='Search Form here' />
          </div>
        </div>
      </Modal.Header>
      <Modal.Content>
        {searching && <div className={styles.emptyMessage}>Seaching...</div>}
        {message && !searching && <div className={styles.emptyMessage}>No forms found for the given search criteria.</div>}
        {!message && !searching &&
          <Table sortable className={styles.pardotFormTable}>
            <Table.Row>
              <Table.Header>
                <Table.HeaderCell collapsing></Table.HeaderCell>
                <Table.HeaderCell width={8}>Name</Table.HeaderCell>
                <Table.HeaderCell width={5} textAlign="center">Created on</Table.HeaderCell>
                <Table.HeaderCell width={5} textAlign="center">Last Updated</Table.HeaderCell>
              </Table.Header>
              <Table.Body>
                {forms.map(form => {
                  var selected = form.id === parseInt(selectedForm);
                  return (
                    <Table.Row>
                      <Table.Cell collapsing className={cx(styles.checkbox, { [styles.selected]: props.showCheckBox })} textAlign="center">
                        <Checkbox checked={selected} onChange={handleFormSelection.bind(this, form)} />
                      </Table.Cell>
                      <Table.Cell>{form.name}</Table.Cell>
                      <Table.Cell textAlign="center">{getMarketoDateFormat(form.createdAt)}</Table.Cell>
                      <Table.Cell textAlign="center">{getMarketoDateFormat(form.updatedAt)}</Table.Cell>
                    </Table.Row>
                  )
                })}
              </Table.Body>
            </Table.Row>
          </Table>
        }
        {loadMore && !searching &&
          <div className={styles.loadMoreButton}>
            <Button disabled={loading} onClick={handleLoadMore}>{!loading ? 'Load More' : 'Loading...'}</Button>
          </div>
        }
      </Modal.Content>
      <Modal.Actions>
        <Button className={styles.closeButton} onClick={handleClose}>
          <Icon /> CLOSE
        </Button>
        <Button className={styles.addFormButton} onClick={handleAddForm}>
          <Icon /> ADD
        </Button>
      </Modal.Actions>
    </Modal>
  )
}

function Steps({ widgets, value, onChange, editor }) {
  const { scale } = React.useContext(WidgetContext);

  const [style, setStyle] = useState({});

  useEffect(() => {
    if (scale < 1) {
      var updatedScale = 1 / scale;
      setStyle(
        {
          transform: `scale(${updatedScale})`,
          transformOrigin: `bottom right`,
          marginLeft: '40px'
        }
      )
    } else {
      setStyle({})
    }
  }, [scale])

  widgets = widgets || [];
  widgets = useSelector(state => {
    var items = [];

    var editorContext = state.widgetsEditor.editors[editor];

    if (editorContext) {
      items = widgets.map(widget => {
        return editorContext.widgetsById[widget];
      }).filter(widget => {
        return widget.type === 'widgets.form.step';
      }).map(widget => {
        return { key: widget.id, value: widget.id, text: widget.name }
      });
    }

    return items;
  });

  return (
    <div className={styles.steps} style={style}>
      State {' '}
      <Dropdown
        inline
        options={widgets}
        value={value}
        onChange={(e, data) => {
          onChange(data.value);
        }}
      />
    </div>
  );
}

function StepWidget({ step, id, marketoFormStep, editor, content, properties, ...rest }) {
  const widget = useSelector(state => {
    const editorContext = state.widgetsEditor.editors[editor];

    if (editorContext) {
      return editorContext.widgetsById[step];
    }

    return null;
  });

  if (!widget) {
    return false;
  }

  if (step === marketoFormStep) {
    return <MarketoStepWidget
      id={id}
      widget={widget}
      content={content}
      editor={editor}
      properties={properties} />
  }

  return (
    <div>
      <Widget.Children widgets={widget.widgets} editor={editor} editable={rest.editable} />
    </div>
  );
}

function Form(props) {
  const { baseUrl, munchkinId, formId } = props;

  useMarketo(props);
  return <form id={`mktoForm_${formId}`} class="marketo_form" />;
}

function appendScript(baseUrl, setScriptLoaded) {
  if (window.MktoForms2) return setScriptLoaded(true);

  const script = document.createElement("script");
  script.src = `${baseUrl}/js/forms2/js/forms2.min.js`;
  script.onload = () => (window.MktoForms2 ? setScriptLoaded(true) : null);
  document.body.appendChild(script);
}

function useMarketo({ baseUrl, munchkinId, formId, callback }) {
  const [scriptLoaded, setScriptLoaded] = useState(false);

  useEffect(() => {
    if (scriptLoaded) {
      window.MktoForms2.loadForm(baseUrl, munchkinId, formId, callback);
      return;
    }
    appendScript(baseUrl, setScriptLoaded);
  }, [scriptLoaded, baseUrl, munchkinId, formId, callback]);

}


function MarketoStepWidget({ widget, id, content, editor, properties }) {
  var fetching = _.get(content, 'fetching', false);
  var formId = _.get(properties, 'form', null);
  var munchkinId = _.get(properties, 'munchkinId', null);
  var domain = _.get(properties, 'domain', null);

  const dimension = _.get(properties, 'dimension') || {};
  var dimensionStyles = calculateModalDimension(dimension);


  if (dimensionStyles.height) {
    dimensionStyles = {
      ...dimensionStyles,
      height: `calc(${dimensionStyles.height} + 60px)`
    }
  }

  return (
    <>
    {fetching &&
      <div className={styles.loader}>
        <Dimmer active inverted>
          <Loader active>Loading Marketo form...</Loader>
        </Dimmer>
      </div>
    }

    {!fetching &&
      <Form formId={formId} baseUrl={domain} munchkinId={munchkinId} />
    }

    {!fetching && !formId &&
      <EmptyView id={id} editor={editor} properties={properties} />
    }
    </>
  );
}

function EmptyView(props) {
  return (
    <Segment basic>
      <Header as='h3' icon textAlign='center'>
        <Icon name='settings' size='small' />
        No Form Selected
        <Header.Subheader>
          Please select a Marketo form.
          <MarketoFormModal
            trigger={
              <a href='#'>Click here</a>
            } widget={props.id} editor={props.editor} properties={props.properties} />
        </Header.Subheader>
      </Header>
    </Segment>
  );
}

function FormQuickSettings(props) {
  const dispatch = useDispatch();

  const integrations = useSelector(state => _.get(state, 'integrations.activated.items', []));
  const [selected, setSelected] = useState('action');

  const menuItems = [
    { id: 'action', title: 'Action', icon: 'icon-layout' },
    { id: 'dimension', title: 'Dimension', icon: 'icon-spacing' }
  ]

  const handleMenuClick = (item) => {
    setSelected(item);
  }

  var widget = useSelector(state => {
    const editorContext = state.widgetsEditor.editors[props.editor];

    if (editorContext) {
      return editorContext.widgetsById[props.id];
    }

    return null;
  });
  if (!widget) {
    return false;
  }

  var properties = widget.properties;
  var integrationProperties = _.get(widget.properties, 'integrations') || [];

  const handleChange = (changeRequest) => {
    dispatch(updateProperties({
      id: widget.id,
      change: changeRequest,
      context: props.editor
    }));
  }

  return (
    <QuickSettings menuItems={menuItems} selected={selected} onMenuClick={handleMenuClick} >
      {selected === 'action' &&
        <>
        <EmailProperty
          value={properties.emails}
          onChange={(value) => {
            handleChange({
              emails: value
            })
          }}
        />
        </>
      }

      {selected === 'dimension' &&
        <>
        <QuickSettingsProperty.Input
          title='Width'
          value={_.get(properties, 'dimension.width')}
          onChange={(value) => {
            const dimension = _.get(properties, 'dimension') || {};

            var changes = {
              width: value
            };

            handleChange({
              dimension: {
                ...dimension,
                ...changes
              }
            });
          }}
        />

        <QuickSettingsProperty.Input
          title='Height'
          value={_.get(properties, 'dimension.height')}
          onChange={(value) => {
            const dimension = _.get(properties, 'dimension') || {};

            var changes = {
              height: value
            };

            handleChange({
              dimension: {
                ...dimension,
                ...changes
              }
            });

          }}
        />
        </>}
    </QuickSettings>
  )
}
MarketoForm.QuickSettings = FormQuickSettings;

function FormProperty(props) {
  const dispatch = useDispatch();

  const integrations = useSelector(state => _.get(state, 'integrations.activated.items', []));

  var widget = useSelector(state => {
    const editorContext = state.widgetsEditor.editors[props.editor];

    if (editorContext) {
      return editorContext.widgetsById[props.id];
    }

    return null;
  });
  if (!widget) {
    return false;
  }

  var properties = widget.properties;
  var integrationProperties = _.get(widget.properties, 'integrations') || [];

  const handleChange = (changeRequest) => {
    dispatch(updateProperties({
      id: widget.id,
      change: changeRequest,
      context: props.editor
    }));
  }

  return (
    <>
    <Property.Section title='Action' expanded>
      <EmailProperty
        value={properties.emails}
        onChange={(value) => {
          handleChange({
            emails: value
          })
        }}
      />

    </Property.Section>

    </>
  );
}
MarketoForm.Property = FormProperty;

function EmailProperty({ value, onChange }) {
  const [input, setInput] = useState('');
  const emails = value || [];
  const handleKeyPressed = (e) => {
    if (e.key === "Enter") {
      handleChanges();
    }
  }
  const handleChanges = () => {
    var updated = emails;
    if (!updated) {
      updated = [];
    }
    if (input && input.trim() && validateEmail(input.trim())) {
      updated = [...updated, input.trim()];
      updated = _.uniq(updated);
      onChange(updated);
    }
    setInput('');
  }
  const deleteValue = (index) => {
    var updated = [...emails];
    updated.splice(index, 1);
    onChange(updated);
  }
  return (
    <Property title={'Notify'} tooltip={'Notify users'}>
      {emails.map((item, index) => {
        return (
          <Tooltip content={item} inverted>
            <Label key={`${item}-${index}`} image className={cx(styles.email)}>
              <div className={styles.value}><Truncate lines={1}>{item}</Truncate></div>
              <Icon name='delete' onClick={deleteValue.bind(this, index)} />
            </Label>
          </Tooltip>
        );
      })}
      <Input className={styles.emailInput} size='mini'
        placeholder='Email address'
        value={input}
        onChange={(e) => {
          setInput(e.target.value);
        }}
        onBlur={handleChanges}
        onKeyPress={handleKeyPressed}
      />
    </Property>
  );
}

function calculateModalDimension(dimension) {
  if (!dimension) {
    return null;
  }

  var width = dimension.width;
  var height = dimension.height;

  if (width) {
    var viewportWidth = window.innerWidth - 100;
    width = Math.min(width, viewportWidth);
  }

  if (height) {
    var viewportHeight = window.innerHeight - 80;
    height = Math.min(height, viewportHeight);
  }

  dimension = {
    ...dimension,
    width: width,
    height: height
  }

  var dimensionStyles = getDimensionStyles(dimension);

  if (dimensionStyles.height) {
    dimensionStyles = {
      ...dimensionStyles,
      height: `calc(${dimensionStyles.height} - 60px)`
    }
  }

  return dimensionStyles;
}