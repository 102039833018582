import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Switch, Route, NavLink, useRouteMatch } from "react-router-dom";
import { Segment, Menu, Header, Input, Visibility, Image, List, Loader, Button, Table, Message, Transition } from 'semantic-ui-react';

import { inviteUser, fetchAllUsers, updateUser, fetchAllInvitations, resendInvitation, deleteInvitation } from 'actions/userManagement';
import { SemanticToastContainer, toast } from 'react-semantic-toasts';
import { getBaseUrl } from 'services/util';

import dateformat from 'dateformat';
import _ from 'lodash';
import cx from 'classnames';

import styles from './UserManagement.module.scss';

export default function UserManagementView() {
  const dispatch = useDispatch();
  let { path, url } = useRouteMatch();
  let baseUrl = getBaseUrl(url);

  const [email, setEmail] = useState(null);
  const [error, setError] = useState(null);
  const [visible, setVisible] = useState(false);
  const [invitedEmail, setInvitedEmail] = useState("");

  const users = useSelector(state => _.get(state, 'userManagement.users.items', []));
  const invitations = useSelector(state => _.get(state, 'userManagement.invitations.items', []));

  const usersCount = useSelector(state => _.get(state, "userManagement.users.pagination.totalRecordsCount", 0));
  const invitationsCount = useSelector(state => _.get(state, "userManagement.invitations.pagination.totalRecordsCount", 0));

  const inviteError = useSelector(state => _.get(state, 'userManagement.inviteError', null));
  const inviting = useSelector(state => _.get(state, 'userManagement.inviting', false));

  useEffect(() => {
    dispatch(fetchAllUsers(0, ''));
    dispatch(fetchAllInvitations(0, ''))
  }, [])

  useEffect(() => {
    if (inviteError && inviteError !== {}) {
      console.log(inviteError.errors[0].message);
      setError(inviteError.errors[0].message);
    }
  }, [inviteError])

  useEffect(() => {

    if (invitations.length > 0 && invitations[invitations.length - 1].email === email) {
      // console.log(invitedUser[invitedUser.length - 1].email);
      setEmail("");
      setTimeout(() => {
        setVisible(true);
        setInvitedEmail(invitations[invitations.length - 1].email);
      }, 500)

      setTimeout(() => {
        setVisible(false);
      }, 4000)
    }
  }, [invitations]);


  const sendInvite = (e) => {
    e.preventDefault();
    const error = validateUserName();

    if (Object.keys(error).length !== 0) {
      setError(error);
      return;
    } 

    let user = {
      email: email,
      cleverstory: true
    }

    dispatch(inviteUser(user, (response) => {
      setEmail(null);
    }));
  }

  const validateUserName = () => {
    let error = {};
    var emailID = email;
    if (emailID === null || emailID.trim() === '') {
      error = "Enter a valid email id"
    }

    const regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!regex.test(emailID)) {
      error = "Enter a valid email id";
    }

    return error;
  }

  const renderButtonTitle = () => {
    if (inviting) {
      return 'Sending Invite...'
    } else return 'Send Invite'
  }

  const handleDismiss = () => {
    setVisible(false);
  }

  return (
    <>
      <Segment className={styles.userManagementView}>
        <h1 className={styles.header}>User Management</h1>
        <Header header as="h2" className={styles.header}>Invite Users</Header>
        <Segment className={styles.invitation}>
          <Input
            placeholder="Enter email here to invite"
            value={email}
            className={styles.email}
            onChange={(e) => {
              setEmail(e.target.value);
            }}
          ></Input>
          <Menu
            secondary
            size='large'
            borderless
            className={styles.actionMenu}
          >
            <Menu.Menu position='right' className={styles.buttonTray}>
              <Menu.Item className={styles.sendButton} onClick={sendInvite}>
                {renderButtonTitle()}
              </Menu.Item>
              <Menu.Item className={styles.cancelButton} onClick={() => {
                setEmail("");
              }}>
                Cancel
          </Menu.Item>
            </Menu.Menu>
          </Menu>
        </Segment>
        {error &&
          <span className={styles.error}>{error}</span>
        }

        <Menu secondary pointing borderless>
          <Menu.Item
            as={NavLink}
            to={`${url}/users`}
            className={styles.users}
          >Users <sup>({usersCount})</sup></Menu.Item>
          <Menu.Item
            name='Invitations'
            as={NavLink}
            to={`${url}/invitations`}
            className={styles.users}
          >Invitations <sup>({invitationsCount})</sup></Menu.Item>
        </Menu>
        <Switch>
          <Route path={`${path}/users`} component={UsersList}></Route>
          <Route path={`${path}/invitations`} component={InvitationsList}></Route>
        </Switch>
      </Segment>
      <Transition.Group animation={"fly left"} duration={"500"}>
        {visible &&
          <Message positive onDismiss={handleDismiss}>
            <Message.Header>Success!</Message.Header>
            <p>
              User {invitedEmail} <br />has been invited successfully!
            </p>
          </Message>
        }
      </Transition.Group>
    </>
  )
}

function UsersList() {
  const dispatch = useDispatch();

  const users = useSelector(state => _.get(state, 'userManagement.users.items', []));

  const currentPage = useSelector(state => _.get(state, "userManagement.users.pagination.current", 0));
  const totalPages = useSelector(state => _.get(state, "userManagement.users.pagination.totalPages", 0));

  const loading = useSelector(state => _.get(state, 'userManagement.users.loading', false));

  const loadMorePages = () => {
    if (loading || currentPage === totalPages - 1) {
      return null;
    }

    dispatch(fetchAllUsers(currentPage + 1, ''));
  }

  const toggleUserPermission = (user) => {
    let updatedUser = {
      "id": user.id,
      "email": user.email,
      "admin": user.admin,
      "accountNonLocked": user.accountNonLocked,
      "enabled": user.enabled,
      "permissions": {
        "cleverstory": !user.permissions.cleverstory
      }
    }
    dispatch(updateUser(updatedUser));
  }

  return (
    <Segment className={styles.usersList}>
      <Visibility className={styles.inner} onBottomVisible={loadMorePages} continuous fireOnMount={false}>
        {users.map(user => {
          return (
            <List divided verticalAlign='middle'>
              <List.Item>
                <List.Content floated='right'>
                  <Button onClick={
                    () => {
                      toggleUserPermission(user);
                    }}
                    className={cx(styles.action, { [user.permissions.cleverstory]: styles.activate })}>
                    {user.permissions.cleverstory ?
                      user.updatingAccess ? "Deactivating User" : "Deactivate User" :
                      user.updatingAccess ? "Activating User" : "Activate User"}
                  </Button>
                </List.Content>
                <Image avatar src={user.icon.thumbnail} />
                <List.Content className={styles.userName}>{user.firstName} {user.lastName}</List.Content>
              </List.Item>
            </List>
          )
        })}
        {loading && currentPage >= 0 &&
          <div className={styles.loading}>Loading...</div>
        }
        <Loader active={currentPage < 0 && loading} content='Loading...' />
      </Visibility>
    </Segment >
  )
}

function InvitationsList() {
  const dispatch = useDispatch();

  const invitations = useSelector(state => _.get(state, 'userManagement.invitations.items', []));

  const currentPage = useSelector(state => _.get(state, "userManagement.invitations.pagination.current", 0));
  const totalPages = useSelector(state => _.get(state, "userManagement.invitations.pagination.totalPages", 0));

  const loading = useSelector(state => _.get(state, 'userManagement.invitations.loading', false));

  const loadMorePages = () => {
    if (loading || currentPage === totalPages - 1) {
      return null;
    }

    dispatch(fetchAllInvitations(currentPage + 1, ''));
  }

  const handleResend = (userId) => {
    dispatch(resendInvitation(userId));
  }

  return (
    <Segment className={styles.invitationsList}>
      <Visibility className={styles.inner} onBottomVisible={loadMorePages} continuous fireOnMount={false}>
        {invitations.map(invitation => {
          return (
            <Table stackable key={invitation.id}>
              <Table.Body>
                <Table.Row>
                  <Table.Cell width={8} className={styles.email}>{invitation.email}</Table.Cell>
                  <Table.Cell className={styles.invitedText}>Invited on {dateformat(invitation.modifiedDate, 'dS mmmm')}</Table.Cell>
                  <Table.Cell className={styles.resendInvitation} onClick={() => handleResend(invitation.id)}>{invitation.resending ? "Resending Invitation..." : "Resend Invitation"}</Table.Cell>
                  <Table.Cell className={styles.delete} textAlign='right' onClick={() => dispatch(deleteInvitation(invitation.id))}><i aria-hidden="true" className="trash icon"></i></Table.Cell>
                </Table.Row>
              </Table.Body>
            </Table>
          )
        })}
        {loading && currentPage >= 0 &&
          <div className={styles.loading}>Loading...</div>
        }
        <Loader active={currentPage < 0 && loading} content='Loading...' />
      </Visibility>
    </Segment >
  )
}