import React, { PropTypes, Fragment, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchPaletteGroups, invalidateWidgetPlaceholder, processPaletteDropRequest } from 'actions/widgetEditor';
import { fetchLiveElements } from 'actions/live_elements';
import { fetchForms } from 'actions/forms';

import _, { stubFalse } from 'lodash';
import Truncate from 'react-truncate';
import cx from 'classnames';
import { DragPreviewImage, useDrag, useDrop } from 'react-dnd';
import dateformat from 'dateformat';

import { PALETTE_ITEM } from 'constants/ItemTypes.jsx';

import {
  Grid,
  Header,
  Menu,
  Button,
  Segment,
  Image,
  Popup,
  Visibility,
  Item
} from 'semantic-ui-react';

import styles from '../editor.module.scss';

const PaletteContext = React.createContext({
  onItemClick: () => { },
  closeMenu: () => { }
});

export default function WidgetsPaneView(props) {
  const dispatch = useDispatch();

  const loading = useSelector(state => _.get(state, 'widgetsEditor.palette.loading', false));
  const seekEnabled = useSelector(state => _.get(state, 'storyboards.storyboard.item.seekEnabled', false));

  let groups = useSelector(state => _.get(state, 'widgetsEditor.palette.groups', []));

  const [open, setOpen] = useState(false);
  const [hide, setHide] = useState(false);
  const [selected, setSelected] = useState(groups ? groups[0] : {});

  useEffect(() => {
    dispatch(fetchPaletteGroups());
    dispatch(fetchLiveElements(0, 20, "name", "ASC", true));
    dispatch(fetchForms(0, 20, 'name', 'ASC', true));
  }, []);

  useEffect(() => {
    if (groups) {
      if(!seekEnabled) {
        let index = groups.findIndex(item => {
          return item.name === "Seek";
        });

        groups.splice(index, 1);
      }
      setSelected(groups[0]);
    }
  }, [groups]);

  const handleGroupClick = (group) => {
    setSelected(group);
  }

  const handleItemClick = (item) => {
    if (props.onItemClick) {
      props.onItemClick(item);
    }

    setOpen(false);
  }

  if (loading || !groups) {
    return null;
  }

  return (
    <>
      <Popup
        className={cx(styles.paletteMenuPopup, props.className, {
          [styles.hide]: hide,
        })}
        on='click'
        basic
        trigger={
          <Button className={cx(styles.paletteMenu, { [styles.active]: open })}>
            <i aria-hidden="true" className="icon-widget icon"></i>
          </Button>
        }
        position='right center'
        popperModifiers={{
          placement: 'right-start'
        }}
        open={open}
        onOpen={() => {
          setOpen(true);
          setHide(false);
        }}
        onClose={() => {
          setOpen(false);
          setHide(false);
        }}
        content={
          <PaletteContext.Provider value={{
            onItemClick: handleItemClick,
            closeMenu: () => { setOpen(false) },
            hideMenu: () => { 
              console.log("Hiode");
              setHide(true) 
            }
          }}>
            <Segment basic className={cx(styles.widgetsPanel, {
               [styles.show]: true
            })}>
              <Grid width='equal'>
                <Grid.Column width={5} className={styles.widgetTypes}>
                  <Menu secondary vertical className={styles.widgetTypeMenu}>
                    {groups.map((group, index) => {
                      return (
                        <Menu.Item key={group.id}
                          className={cx(styles.widgetTypeItem,
                            { [styles.selected]: group.id === _.get(selected, 'id', null) })}
                          onClick={handleGroupClick.bind(this, group)}>
                          {group.name}
                        </Menu.Item>
                      )
                    })}
                  </Menu>
                </Grid.Column>
                <Grid.Column width={11} className={styles.paletteItems}>
                  {(selected && selected.items || []).map((group, index) => {
                    return (
                      <PaletteGroupView
                        key={group.id}
                        editor={props.editor}
                        entityReference={props.entityReference}
                        group={group}
                        onItemClick={props.onItemClick}
                      />
                    )
                  })}
                  {(selected && selected.id === "live_elements" &&
                    <LiveElementsView editor={props.editor}
                      onItemClick={props.onItemClick}
                      entityReference={props.entityReference} />
                  )}
                  {(selected && selected.id === "form_elements" &&
                    <FormElementsView editor={props.editor}
                      onItemClick={props.onItemClick}
                      entityReference={props.entityReference} />
                  )}
                </Grid.Column>
              </Grid>
            </Segment>
          </PaletteContext.Provider>
        }
      />
    </>
  );
}

function PaletteGroupView({ group, ...rest }) {
  let paletteItems = group.items || [];
  let multiple = paletteItems.length > 1;

  return (
    <div className={styles.paletteGroup}>
      <Header as={'h3'} className={styles.paletteGroupHeader}>
        {group.name}
      </Header>
      <Grid width='equal'>
        {
          paletteItems.map((widget, index) => {
            return (
              <PaletteItem
                key={widget.id}
                editor={rest.editor}
                entityReference={rest.entityReference}
                {...widget}
                onItemClick={rest.onItemClick}
                multiple={multiple}
              />
            )
          })
        }
      </Grid>
    </div>
  )
}

function PaletteItem(props) {
  const dispatch = useDispatch();
  const paletteContext = React.useContext(PaletteContext);

  const [{ opacity }, dragRef, previewRef] = useDrag(() => {
    
    return {
      type: PALETTE_ITEM,
      item: () => {
        paletteContext.hideMenu();
        return{
          type: PALETTE_ITEM,
          content: props.content,
          wrapWithRow: props.wrapWithRow,
          request: {
            index: -1
          },
          originalIndex: -1
        }
      },
      end: (item, monitor) => {
        dispatch(processPaletteDropRequest(props.editor, props.entityReference, item.content));
        dispatch(invalidateWidgetPlaceholder(props.editor));
        paletteContext.closeMenu();
      },
      collect: monitor => ({
        opacity: monitor.isDragging() ? 0.5 : 1,
      }),
    }
  });

  const handleClick = () => {
    paletteContext.onItemClick(props.content);
  }

  return (
    <Grid.Column className={cx(styles.paletteItem, { [styles.multiple]: props.multiple })} width={props.width || 16}>
      <DragPreviewImage src="/images/assets/add_url.png" connect={previewRef} />
      <div ref={dragRef}>
        <Image src={props.icon || '/images/default_asset.jpg'} onClick={handleClick} />
      </div>
    </Grid.Column>
  );
}

function LiveElementsView(props) {
  const dispatch = useDispatch();

  const elements = useSelector(state => _.get(state, "liveElements.items", [])).map(element => {
    return {
      ...element,
      content: {
        name: element.name,
        type: 'widgets.live_element',
        properties: {},
        content: element.id
      }
    }
  }).filter(element => {
    return element.id !== props.entityReference.id
  });

  const pagination = useSelector(state => _.get(state, "liveElements.pagination", {}));
  const currentPage = _.get(pagination, "currentPage", 0);
  const totalPages = _.get(pagination, "totalPages", 0);
  const loading = useSelector(state => _.get(state, "liveElements.loading", false));

  const loadMorePages = () => {
    if (loading || currentPage === totalPages - 1) {
      return null;
    }

    dispatch(fetchLiveElements(currentPage + 1, 20, "name", "ASC", true));
  }

  return (
    <div className={styles.paletteGroup}>
      <Header as={'h3'} className={styles.paletteGroupHeader}>
        Live Elements
      </Header>
      <Visibility className={styles.inner} onBottomVisible={loadMorePages} continuous fireOnMount={false}>
        {elements.map(element => {
          return (
            <LiveElementItem key={element.id} element={element}
              content={element.content}
              editor={props.editor}
              onItemClick={props.onItemClick}
              entityReference={props.entityReference} />
          )
        })}
      </Visibility>
    </div>
  )
}

function LiveElementItem({ element, content, editor, entityReference, ...rest }) {
  const dispatch = useDispatch();
  const paletteContext = React.useContext(PaletteContext);

  const [{ opacity }, dragRef, previewRef] = useDrag(() => ({
    type: PALETTE_ITEM,
    item: {
      type: PALETTE_ITEM,
      content: [content],
      wrapWithRow: false,
      request: {
        index: -1
      },
      originalIndex: -1
    },
    end: (item, monitor) => {
      dispatch(processPaletteDropRequest(editor, entityReference, item.content));
      dispatch(invalidateWidgetPlaceholder(editor));
      paletteContext.closeMenu();
    },
    collect: monitor => ({
      opacity: monitor.isDragging() ? 0.5 : 1,
    }),
  }));

  const handleClick = () => {
    paletteContext.onItemClick([content]);
  }

  return (
    <div ref={dragRef}>
      <Item className={styles.element} onClick={handleClick}>
        <Item.Image size='tiny' src='/images/liveElements/live_elements.png' />
        <Item.Content>
          <Item.Header as='h4' className={styles.elementName}><Truncate lines={2}>{element.name}</Truncate></Item.Header>
          <Item.Description>Created on: {dateformat(element.createdDate, 'dd mmm yyyy')}</Item.Description>
        </Item.Content>
      </Item>
    </div>
  )
}

function FormElementsView(props) {
  const dispatch = useDispatch();

  const forms = useSelector(state => _.get(state, "forms.items", [])).map(form => {
    return {
      ...form,
      content: {
        name: form.name,
        type: 'widgets.form_element',
        properties: {},
        content: form.id
      }
    }
  }).filter(form => {
    return form.id !== props.entityReference.id
  });

  const pagination = useSelector(state => _.get(state, "forms.pagination", {}));
  const currentPage = _.get(pagination, "currentPage", 0);
  const totalPages = _.get(pagination, "totalPages", 0);
  const loading = useSelector(state => _.get(state, "forms.loading", false));

  const loadMorePages = () => {
    if (loading || currentPage === totalPages - 1) {
      return null;
    }

    dispatch(fetchForms(currentPage + 1, 20, 'name', 'ASC', true));
  }

  return (
    <div className={styles.paletteGroup}>
      <Header as={'h3'} className={styles.paletteGroupHeader}>
        Forms
      </Header>
      <Visibility className={styles.inner} onBottomVisible={loadMorePages} offset='100' continuous fireOnMount={false}>
        {forms.map(form => {
          return (
            <FormItem key={form.id} form={form}
              content={form.content}
              editor={props.editor}
              onItemClick={props.onItemClick}
              entityReference={props.entityReference} />
          )
        })}
      </Visibility>
    </div>
  )
}

function FormItem({ form, content, editor, entityReference, ...rest }) {
  const dispatch = useDispatch();
  const paletteContext = React.useContext(PaletteContext);

  const [{ opacity }, dragRef, previewRef] = useDrag(() => ({
    type: PALETTE_ITEM,
    item: {
      type: PALETTE_ITEM,
      content: [content],
      wrapWithRow: false,
      request: {
        index: -1
      },
      originalIndex: -1
    },
    end: (item, monitor) => {
      dispatch(processPaletteDropRequest(editor, entityReference, item.content));
      dispatch(invalidateWidgetPlaceholder(editor));
      paletteContext.closeMenu();
    },
    collect: monitor => ({
      opacity: monitor.isDragging() ? 0.5 : 1,
    }),
  }));

  const handleClick = () => {
    paletteContext.onItemClick([content]);
  }

  return (
    <div ref={dragRef}>
      <Item className={styles.element} onClick={handleClick}>
        <Item.Image size='tiny' src='/images/formElements/form_elements.png' />
        <Item.Content>
          <Item.Header as='h4' className={styles.elementName}><Truncate lines={2}>{form.name}</Truncate></Item.Header>
          <Item.Description>Created on: {dateformat(form.createdDate, 'dd mmm yyyy')}</Item.Description>
        </Item.Content>
      </Item>
    </div>
  )
}