import {
  FETCH_FORMS_REQUEST, FETCHING_FORMS_SUCCEEDED, FETCHING_FORMS_FAILED, 
  FETCH_FORM_REQUEST, FETCHING_FORM_SUCCEEDED, FETCHING_FORM_FAILED,
  INVALIDATE_FORM_SELECTION,
  CREATE_FORM_REQUEST, CREATING_FORM_SUCCEEDED, CREATING_FORM_FAILED, 
  CLONE_FORM_REQUEST, CLONING_FORM_SUCCEEDED, CLONING_FORM_FAILED, 
  UPDATE_FORM_REQUEST, UPDATING_FORM_SUCCEEDED, UPDATING_FORM_FAILED, 
  PUBLISH_FORM_REQUEST, PUBLISHING_FORM_SUCCEEDED, PUBLISHING_FORM_FAILED, 
  RESTORE_FORM_REQUEST, RESTORING_FORM_SUCCEEDED, RESTORING_FORM_FAILED,
  DELETE_FORM_REQUEST, DELETING_FORM_SUCCEEDED, DELETING_FORM_FAILED
} from 'constants/actionTypes';

import { call, put, takeEvery } from 'redux-saga/effects';
import axios from 'axios';
import { createAction } from 'redux-actions';
import buildUrl from 'build-url'; 

const apiUrl = `${process.env.REACT_APP_API_URL}/api/2.0`;
const FORM_URL = `${apiUrl}/forms`;

/**
 * Fetch forms for storyboard
*/
export const fetchForms = createAction(FETCH_FORMS_REQUEST, (page, limit, orderBy, direction, published, selectedForm) => {
  return {
    page: page,
    limit: limit,
    orderBy: orderBy,
    direction: direction,
    published: published,
    selectedForm: selectedForm
  }
});

const fetchFormsSucceeded = createAction(FETCHING_FORMS_SUCCEEDED, (selectedForm, response) => {
  return {
    selectedForm: selectedForm,
    response: response
  };
})

const fetchFormsFailed = createAction(FETCHING_FORMS_FAILED, (error) => {
  return error;
})

const fetchFormsAPI = (params) => {
  return axios({
    method: 'GET',
    url: FORM_URL,
    params: params
  }).then(response => response.data);
}

function* fetchFormsSaga(action) {
  const { page, limit, orderBy, direction, published, selectedForm } = action.payload;

  var params = {
    page: page,
    limit: limit,
    published: published
  }

  try {
    const response = yield call(fetchFormsAPI, params);
    yield put(fetchFormsSucceeded(selectedForm, response));
  } catch (error) {
    yield put(fetchFormsFailed(error));
  }
}

/**
 * Create a new form in storyboard
*/
export const createForm = createAction(CREATE_FORM_REQUEST, (request, callback) => {
  return {
    request: request, 
    callback: callback
  }
});

const createFormSucceeded = createAction(CREATING_FORM_SUCCEEDED, (response) => {
  return response;
});

const createFormFailed = createAction(CREATING_FORM_FAILED, (error) => {
  return error;
});

function* createFormSaga(action) {
  const {request, callback} = action.payload;

  const createFormAPI = (params) => {
    return axios({
      method: 'POST',
      url: FORM_URL,
      data: request
    }).then(response => response.data);
  }

  try {
    const response = yield call(createFormAPI);
    yield put(createFormSucceeded(response));
    if (callback) {
      callback(response);
    }
  } catch (error) {
    yield put(createFormFailed(error));
  }
}

/**
 * Clone form for {id}
*/
export const cloneForm = createAction(CLONE_FORM_REQUEST, (id, request, callback) => {
  return {
    id: id,
    request: request,
    callback: callback
  }
})

const cloneFormSucceeded = createAction(CLONING_FORM_SUCCEEDED, response => {
  return response;
})

const cloneFormFailed = createAction(CLONING_FORM_FAILED, error => {
  return error;
})

function* cloneFormSaga(action) {
  const { id, request, callback } = action.payload;

  let url = buildUrl(FORM_URL, {
    path: `${id}/clone`
  });

  const cloneFormAPI = () => {
    return axios({
      method: 'POST',
      url: url,
      data: request
    }).then(response => response.data);
  }

  try {
    const response = yield call(cloneFormAPI);
    yield put(cloneFormSucceeded(response));
    if (callback) {
      callback(response);
    }
  } catch (error) {
    yield put(cloneFormFailed(error));
  }
}

/**
 * Fetch form for {id}
*/
export const fetchForm = createAction(FETCH_FORM_REQUEST, (id, callback) => {
  return {
    id: id,
    callback: callback
  }
});

const fetchFormSucceeded = createAction(FETCHING_FORM_SUCCEEDED, response => {
  return response;
})

const fetchFormFailed = createAction(FETCHING_FORM_FAILED, error => {
  return error;
})

function* fetchFormSaga(action) {
  const {id, callback} = action.payload;

  let url = buildUrl(FORM_URL, {
    path: `${id}`
  });

  const fetchFormAPI = () => {
    return axios.get(url).then(response => response.data);
  }

  try {
    const response = yield call(fetchFormAPI);
    yield put(fetchFormSucceeded(response));
    if(callback){
      callback(response);
    }
  } catch (error) {
    console.log(error);
    yield put(fetchFormFailed(error));
  }
}

/**
 * Invalidate form selection
*/
export const invalidateFormSelection = createAction(INVALIDATE_FORM_SELECTION);

/**
 * Update form for {id}
*/
export const updateForm = createAction(UPDATE_FORM_REQUEST, (id, request, callback) => {
  return {
    id: id,
    request: request,
    callback: callback
  }
})

const updateFormSucceeded = createAction(UPDATING_FORM_SUCCEEDED, response => {
  return response;
})

const updateFormFailed = createAction(UPDATING_FORM_FAILED, error => {
  return error;
})

function* updateFormSaga(action) {
  const { id,  request, callback } = action.payload;

  let url = buildUrl(FORM_URL, {
    path: `${id}`
  });

  const updateFormAPI = () => {
    return axios({
      method: 'PUT',
      url: url,
      data: request
    }).then(response => response.data);
  }

  try {
    const response = yield call(updateFormAPI);
    yield put(updateFormSucceeded(response));
    if(callback) yield call(callback, response);
  } catch (error) {
    yield put(updateFormFailed(error));
  }
}

/**
 * Publish form for {id}
*/
export const publishForm = createAction(PUBLISH_FORM_REQUEST, (id, request, callback) => {
  return {
    id: id,
    request: request,
    callback: callback || (() => {})
  }
})

const publishFormSucceeded = createAction(PUBLISHING_FORM_SUCCEEDED, response => {
  return response;
})

const publishFormFailed = createAction(PUBLISHING_FORM_FAILED, error => {
  return error;
})

function* publishFormSaga(action) {
  const { id, callback } = action.payload;

  let url = buildUrl(FORM_URL, {
    path: `${id}/publish`
  });

  const publishFormAPI = () => {
    return axios({
      method: 'POST',
      url: url
    }).then(response => response.data);
  }

  try {
    const response = yield call(publishFormAPI);
    yield put(publishFormSucceeded(response));
    yield call(callback, response);
  } catch (error) {
    console.log(error);
    yield put(publishFormFailed(error));
  }
}

/**
 * Restore form for {id}
*/
export const restoreForm = createAction(RESTORE_FORM_REQUEST, (id, callback) => {
  return {
    id: id,
    callback: callback
  }
})

const restorFormSucceeded = createAction(RESTORING_FORM_SUCCEEDED, (response) => {
  return response;
})

const restoreFormFailed = createAction(RESTORING_FORM_FAILED, (error) => {
  return error;
})

function* restoreFormSaga(action) {
  const { id, callback } = action.payload;

  let url = buildUrl(FORM_URL, {
    path: `${id}/restore`
  });

  const restoreFormAPI = () => {
    return axios({
      method: 'POST',
      url: url
    }).then(response => response.data);
  }

  try {
    const response = yield call(restoreFormAPI);
    yield put(restorFormSucceeded(response));
    yield call(callback, response);
  } catch (error) {
    console.log(error);
    yield put(restoreFormFailed(error));
  }
}

/**
 * Delete form for {id}
*/
export const deleteForm = createAction(DELETE_FORM_REQUEST, (id, callback) => {
  return {
    id: id,
    callback: callback
  };
})

const deleteFormSucceeded = createAction(DELETING_FORM_SUCCEEDED, (id, response) => {
  return {
    id: id,
    response: response
  }
})

const deleteFormFailed = createAction(DELETING_FORM_FAILED, (id, error) => {
  return {
    id: id,
    error: error
  }
})

function* deleteFormSaga(action) {
  const id = action.payload.id;
  const callback = action.payload.callback || (() => {});

  let url = buildUrl(FORM_URL, {
    path: `${id}`
  });

  const deleteFormAPI = () => {
    return axios({
      method: 'DELETE',
      url: url,
    }).then(response => response.data);
  }

  try {
    const response = yield call(deleteFormAPI);
    yield put(deleteFormSucceeded(id, response));
    console.log(callback);
    yield call(callback, response);
  } catch (error) {
    yield put(deleteFormFailed(id, error));
  }
}

export default function* formSaga() {
  yield takeEvery(FETCH_FORMS_REQUEST, fetchFormsSaga);
  yield takeEvery(CREATE_FORM_REQUEST, createFormSaga);
  yield takeEvery(CLONE_FORM_REQUEST, cloneFormSaga);
  yield takeEvery(FETCH_FORM_REQUEST, fetchFormSaga);
  yield takeEvery(UPDATE_FORM_REQUEST, updateFormSaga);
  yield takeEvery(PUBLISH_FORM_REQUEST, publishFormSaga);
  yield takeEvery(DELETE_FORM_REQUEST, deleteFormSaga);
  yield takeEvery(RESTORE_FORM_REQUEST, restoreFormSaga);
}