import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { 
  Link, useHistory, useRouteMatch
 } from 'react-router-dom';
 import { guid, padNumber, getBaseUrl } from 'services/util';
 import Truncate from 'react-truncate';

import styles from './reports.module.scss';

import cx from "classnames";
import moment from 'moment';
import _ from "lodash";

import {
  Grid,
  Statistic,
  Segment,
  Label,
  Table,
  Header,
  Image,
  Visibility,
  Button,
  Popup
} from 'semantic-ui-react';

import {
  LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip,
} from 'recharts';

import Measure from 'react-measure'
import { DatePicker, Pagination, ContentRow, EmptyMessage } from './components/row';
import ReadMoreTextView from 'components/ReadMoreTextView';

import { 
  fetchAssetSessionsTimeSeries,
  fetchAssets,
  fetchContentAnalytics,
  fetchContentReports,
  downloadContentReports
} from 'actions/reports/content';

import { normalise } from 'services/clusters.service';
import { generateQueryParams, parseQueryParams, convertDatesToTimezone } from 'services/report.service';
import BuildUrl from 'build-url';

export function ContentReports(props) {
  const dispatch = useDispatch();
  const history = useHistory();
  let { path, url } = useRouteMatch();

  const pagination = useSelector(state => _.get(state, 'reports.content.assets.pagination'));
  const loading = useSelector(state => _.get(state, 'reports.content.assets.loading'));
  const downloading = useSelector(state => _.get(state, 'reports.content.assets.downloading'));
  const currentPage = _.get(pagination, 'current');
  const totalPages = _.get(pagination, 'totalPages');

  const [downloadSuccess, setDownloadSuccess] = useState(false);
  
  const [reportDate, setReportDate] = useState(null);

  useEffect(() => {
    if(reportDate){  
      const updatedUrl = generateReportUrl(url, reportDate);
      history.replace(updatedUrl);
    }
  }, [reportDate])

  useEffect(() => {
    const parsedDate = parseQueryParams(props.location.search);
    setReportDate(parsedDate);
  }, []); 

  const generateReportUrl = (url, reportDate) => {
    const queryParams = generateQueryParams(reportDate, null);
    
    const paramsString = props.location.search;
    const params = new URLSearchParams(paramsString);

    const query = {
      ...params,
      ...queryParams
    };

    const updated = BuildUrl(url, {
      queryParams: query
    });

    return updated;
  }

  useEffect(() => {
    if(reportDate) {
      fetchContent(0);
    }
  }, [reportDate])

  const loadMoreAssets = () => {
    if (loading || currentPage === totalPages) {
      return null;
    }

    fetchContent(currentPage + 1);
  };

  const fetchContent = (page) => {
    dispatch(fetchContentReports(page, {
      ...convertDatesToTimezone(reportDate)
    }));
  }

  const handleDownloadClick = () => {
    dispatch(downloadContentReports({
      ...convertDatesToTimezone(reportDate)
    }, () => {
      setDownloadSuccess(true);
      setTimeout(() => {
        setDownloadSuccess(false);
      }, 3000);
    }));
  }

  return(
    <Grid className={styles.content}>
      <Grid.Row>
        <Grid.Column width={3}>
          <DatePicker
            value={reportDate}
            onChange={(value) => {setReportDate(value)}}
          />
        </Grid.Column>
        <Grid.Column className={styles.downloadColumn}>
          <SuccessPopupMessage
            trigger={
              <Button className={styles.downloadButton} onClick={handleDownloadClick}>
                {downloading ? 'Downloading...' : 'Download'}
              </Button>
            }
            open={downloadSuccess}
            onVisibilityChange={() => { setDownloadSuccess(false) }}
          />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column>
          <Visibility onBottomVisible={loadMoreAssets} continuous>
            <ContentsView />
          </Visibility>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
}

function ContentsView(props) {
  const dispatch = useDispatch();

  const items = useSelector(state => _.get(state, 'reports.content.assets.items')) || [];
  const pagination = useSelector(state => _.get(state, 'reports.content.assets.pagination'));
  const loading = useSelector(state => _.get(state, 'reports.content.assets.loading')) || false;

  return (
    <Table sortable selectable={!loading} className={styles.assetTable}>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell width={6}>Name</Table.HeaderCell>
          <Table.HeaderCell textAlign="center">Views</Table.HeaderCell>
          <Table.HeaderCell textAlign="center">Downloads</Table.HeaderCell>
          <Table.HeaderCell textAlign="center">Avg. Time</Table.HeaderCell>
        </Table.Row>
      </Table.Header>

      <Table.Body>
        {items && items.map(report => {
          return (
            <ReportRow
              key={report.id}
              report={report}
            />
          )
        })}
      </Table.Body>
    </Table>
  )
}

function ReportRow(props) {
  let { url } = useRouteMatch();
  let baseUrl = getBaseUrl(url);

  const report = props.report;
  const asset = report.asset;

  const timeSpentValue = (timeSpent) => {
    let timeSpentValue = `${padNumber(Math.round(timeSpent), 1)}s`;

    if (timeSpent >= 60) {
      timeSpentValue = `${padNumber(Math.round(timeSpent / 60), 1)}m`;
    }

    return timeSpentValue;
  }

  var icon = _.get(asset, 'icon.thumbnail', '/images/default_asset.jpg');
  
  return (
    <Table.Row className={cx(styles.asset)}>
      <Table.Cell width={8}>
        <Header as='h4' className={styles.assetDetail}>
          <Image src={icon} />
          <Header.Content className={styles.assetName}>
            <Link to={`${baseUrl}/assets/${asset.id}`}><Truncate lines={1}>{asset.name}</Truncate></Link>
            <Header.Subheader>
              {(asset.tags || []).map((tag, index) => <Tag key={index} name={tag} />)}
            </Header.Subheader>
          </Header.Content>
        </Header>
      </Table.Cell>
      <Table.Cell textAlign="center">{report.views || 0}</Table.Cell>
      <Table.Cell textAlign="center">{report.downloads || 0}</Table.Cell>
      <Table.Cell textAlign="center">{timeSpentValue(report.avgTimeSpent)}</Table.Cell>
    </Table.Row>
  );
}

function Tags({tags, ...rest}) {
  return (
    <ReadMoreTextView lines={3}>
      {(tags || []).map((tag, index) => <Tag key={index} name={tag} />)}
    </ReadMoreTextView>
  )
}

function Tag({name, ...rest}){
  return (
    <span className={styles.tag}>#{name}</span>
  );
}

function ReportHeader(props) {
  const dispatch = useDispatch();

  const analytics = useSelector(state => _.get(state, 'reports.content.cumulativeAnalytics.item')) || {};

  useEffect(() => {
    if (props.startDate && props.endDate) {
      dispatch(fetchContentAnalytics(props.startDate, props.endDate));
    }
  }, [props.dateRange]);

  const _renderTimeSpent = (seconds) => {
    let minutes = padNumber(Math.floor(seconds / 60), 2);
    seconds = padNumber(Math.floor(seconds % 60), 2);
    var hours = padNumber(Math.floor(minutes / 60), 2);
    minutes = padNumber(minutes % 60, 2);

    hours = hours > 0 ? `${hours}:` : ''
    return `${hours}${minutes}:${seconds}`;
  }

  return (
    <Grid.Row className={styles.header} columns='equal'>
      <Grid.Column width={3}>
        <DatePicker
          dateRange={props.dateRange}
          setDateRange={props.setDateRange}
          startDate={props.startDate}
          endDate={props.endDate}
          setStartDate={props.setStartDate}
          setEndDate={props.setEndDate} />
      </Grid.Column>
      <Grid.Column floated='right' textAlign='right' verticalAlign='middle'>
        <Statistic horizontal size='mini'>
          <Statistic.Value>{analytics.assets || 0}</Statistic.Value>
          <Statistic.Label>ASSETS</Statistic.Label>
        </Statistic>
        <Statistic horizontal size='mini'>
          <Statistic.Value>{analytics.assetViews || 0}</Statistic.Value>
          <Statistic.Label>ASSET VIEWS</Statistic.Label>
        </Statistic>
        <Statistic horizontal size='mini'>
          <Statistic.Value>{analytics.downloads || 0}</Statistic.Value>
          <Statistic.Label>DOWNLOADS</Statistic.Label>
        </Statistic>
        <Statistic horizontal size='mini'>
          <Statistic.Value>{_renderTimeSpent(analytics.timeSpent)}</Statistic.Value>
          <Statistic.Label>TIME SPENT</Statistic.Label>
        </Statistic>
      </Grid.Column>
    </Grid.Row>
  );
}

function Report({name, className, children}){
    return (
      <Segment className={cx(styles.report, className)}>
        <Label attached='top'>{name}</Label>
        {children}
      </Segment>
    );
}

function AssetViewsTimeSeriesReport(props){
    const dispatch = useDispatch();

    const sessions = useSelector(state => _.get(state, 'reports.content.asset_session_series.items')) || [];
    const data = sessions.map(item => {
      return {
        name: moment(item.name, 'DD MMM YYYY').format('DD/MM'),
        sessions: item.sessions
      }
    });

    console.log("data", data);

    useEffect(() => {
      if (props.startDate && props.endDate) {
        dispatch(fetchAssetSessionsTimeSeries(props.startDate, props.endDate, 0));
      }
    }, [props.startDate, props.endDate]);

    const [width, setWidth] = useState(null)
    const [height, setHeight] = useState(null)

    return (
      <Report name='ASSET VIEWS'>
        <Measure bounds
          onResize={(contentRect) => {
            setWidth(contentRect.bounds.width);
            setHeight(contentRect.bounds.height);
          }}>
  
          {({ measureRef }) =>
            <div ref={measureRef} >
              <LineChart
                width={width}
                height={350}
                data={data}
                margin={{
                  top: 28, right: 0, left: 0, bottom: 0,
                }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" axisLine={false} tickLine={false} minTickGap={75} />
                <YAxis hide />
                <Tooltip />
                <Line type="monotone" name='Asset Views' dataKey="sessions" stroke="#4285f4" dot={false} strokeWidth={2} />
              </LineChart>
            </div>
          }
        </Measure>
      </Report>
    );
}

function ContentReport(props){
  const dispatch = useDispatch();
  // const pagination = useSelector(state => _.get(state, 'reports.visitors.sessions.pagination'));

  const items = useSelector(state => _.get(state, 'reports.content.assets.items')) || [];
  const pagination = useSelector(state => _.get(state, 'reports.content.assets.pagination'));
  const loading = useSelector(state => _.get(state, 'reports.content.assets.loading')) || false;

  useEffect(() => {
    if (props.startDate && props.endDate) {
      dispatch(fetchAssets(props.startDate, props.endDate, 0));
    } 
  }, [props.dateRange]);

  const handlePageChange = (page) => {
    dispatch(fetchAssets(props.startDate, props.endDate, page));
  }

  return (
    <Table className={cx(styles.contentRow, styles.assetTable)}>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell width={6}>CONTENT</Table.HeaderCell>
          <Table.HeaderCell textAlign="center">VIEWS</Table.HeaderCell>
          <Table.HeaderCell textAlign="center">DOWNLOADS</Table.HeaderCell>
          <Table.HeaderCell textAlign="center">AVG TIME</Table.HeaderCell>
          <Table.HeaderCell textAlign="center">LAST VIEWED</Table.HeaderCell>
          <Table.HeaderCell textAlign="center">PUBLISHED</Table.HeaderCell>
        </Table.Row>
      </Table.Header>

      {items.length > 0 &&
        <Table.Body>
          {!loading && items.map(item => {
            return <ContentRow key={item.asset.id} 
              item={item}
            />
          })}
        </Table.Body>
      }
    
      {pagination &&
        <Grid.Row>
          <Grid.Column textAlign='center'><Pagination pagination={pagination} onClick={handlePageChange} /></Grid.Column>
        </Grid.Row>
      }

      {!loading && items.length == 0 &&
        <EmptyMessage title="Insufficient data to generate report." />
      }
    </Table>
  )
}

function SuccessPopupMessage({ className, open, trigger, onVisibilityChange }) {
  return (
    <Popup
      className={cx(styles.popup, className)}
      popperModifiers={{
        preventOverflow: {
          boundariesElement: "window"
        }
      }}
      open={open}
      on='click'
      onClose={() => onVisibilityChange(false)}
      onOpen={() => onVisibilityChange(true)}
      flowing
      pinned
      hoverable
      position='bottom right'
      trigger={trigger}
      size='tiny'
    >
      <div className={styles.closeButton} onClick={() => onVisibilityChange(false)}>
        <i aria-hidden="true" className="icon-close_1 icon">
          <span className="path1"></span><span className="path2"></span><span className="path3"></span>
        </i>
      </div>
      <div className={styles.content}>
        <div className={styles.details}>
          <h3 className={styles.title}>Success</h3>
          <p className={styles.subtext}>Your download has been initiated and a copy of the report will be delivered to your mailbox in less than 2 minutes</p>
        </div>
      </div>
    </Popup>
  )
}
