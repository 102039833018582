import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import dateformat from 'dateformat';
import cx from 'classnames';

import { guid, getBaseUrl } from 'services/util';

import {
  Menu,
  Grid,
  Form,
  Input,
  Segment,
  Label,
  List,
  TextArea,
  Button,
  Icon,
} from 'semantic-ui-react';

import {
  useParams, 
  useHistory, 
  useLocation,
  NavLink,
  Route,
  Switch,
  useRouteMatch,
} from "react-router-dom";

import Tooltip from 'components/Tooltip';

import _ from 'lodash';

import styles from './CustomFields.module.scss';

import { createCustomField, updateCustomField, deleteCustomField } from 'actions/customFields';

export default function CustomFieldsView() {
  const dispatch = useDispatch();
  let { path, url } = useRouteMatch();

  return (
    <Grid columns="equal" stretched divided='vertically' className={cx(styles.customFields)}>
      <Grid.Row>
        <Grid.Column width={6}>
          <CustomFieldList />
        </Grid.Column>
        <Grid.Column>
          <Switch>
            <Route path={`${path}/:id`} exact>
              <CustomFieldFormView />
            </Route>
          </Switch>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  )
}

function CustomFieldList() {
  const history = useHistory();
  let { path, url } = useRouteMatch();

  const location = useLocation();
  const pathname = location.pathname;
  const id = pathname.substring(pathname.lastIndexOf('/') + 1);

  const customFields = useSelector(state => _.get(state, 'customFields.items', []));

  const handleCreate = () => {
    history.push(`${url}/new`);
  }

  return (
    <Segment basic className={styles.list}>
      <Menu secondary>
        <Menu.Item header as="h2">Custom Fields <Label>{customFields.length}</Label></Menu.Item>
        <Menu.Menu position='right'>
          <Tooltip inverted content={'Create new custom field'} position="top right">
            <Menu.Item icon='add' onClick={handleCreate} disabled={false} />
          </Tooltip>
        </Menu.Menu>
      </Menu>

      <List selection celled verticalAlign='middle'>
        {id === 'new' && 
          <CustomFieldItem key={'new'} 
          customField={{
            id: 'new',
            name: 'untitled'
          }} />}
        {customFields.map(customField => {
          return <CustomFieldItem key={customField.id} customField={customField} customFields={customFields}/>
        })}
      </List>
    </Segment>
  )
}

function CustomFieldItem({ customField, customFields}) {
  const dispatch = useDispatch();
  const history = useHistory();
  let { path, url } = useRouteMatch();

  const handleDelete = () => {
    var index = customFields.findIndex(item => {
      return item.id === customField.id;
    })

    if(index === customFields.length - 1) {
      index = 0;
    }

    dispatch(deleteCustomField(customField.id, (res) => {
      if (index === 0 && customFields.length === 1) {
        history.push(`${url}/new`);
      } else {
        var next = customFields[index];
        history.push(`${url}/${next.id}`);
      }
    }));
  }
  return (
    <List.Item as={NavLink} to={`${url}/${customField.id}`}>
      {customField.id !== 'new' &&
      <List.Content floated='right'>
        <Tooltip inverted content={'Delete custom field'} position="top right">
          <Button icon='trash alternate' onClick={handleDelete} />
        </Tooltip>
      </List.Content>}
      <List.Icon name='newspaper outline' size='big' verticalAlign='middle' />
      <List.Content>
        <List.Header>{customField.name}</List.Header>
        {customField.id !== 'new' &&
        <List.Description>
          Last updated on {dateformat(customField.modifiedDate, 'dd mmmm')}
        </List.Description>}
      </List.Content>
    </List.Item>
  );
}

function CustomFieldFormView() {
  const dispatch = useDispatch();
  const history = useHistory();
  let { path, url } = useRouteMatch();

  const { id } = useParams();
  const customFields = useSelector(state => _.get(state, 'customFields.items', []));
  const updating = useSelector(state => _.get(state, 'customFields.updating', false));
  const creating = useSelector(state => _.get(state, 'customFields.creating', false));

  var customField = customFields.find(item => {
    return item.id === id;
  });
  
  const [name, setName] = useState(customField ? customField.name : '');
  const [helpText, setHelpText] = useState(customField ? customField.helpText : '');
  const [options, setOptions] = useState(customField ? customField.options : []);
  const [multiselect, setMultiSelect] = useState(customField ? customField.multiselect : false);
  const [searchable, setSearchable] = useState(customField ? customField.searchable : false);
  const [errors, setErrors] = useState({});

  useEffect(() => {
    var customField = customFields.find(item => {
      return item.id === id;
    });
    setName(customField ? customField.name : '');
    setHelpText(customField ? customField.helpText : '');
    setOptions(customField ? customField.options : []);
    setMultiSelect(customField ? customField.multiselect : false);
    setSearchable(customField ? customField.searchable : false);
    setErrors({});
  }, [id]);

  const handleNameChange = (e, { value }) => {
    setName(value);
    setErrors({
      ...errors,
      name: null
    })
  }

  const handleHelpTextChange = (e, { value }) => {
    setHelpText(value);
  }

  const handleMultiSelectChange = (e, {checked}) => {
    setMultiSelect(checked);
  }

  const handleSearchableChange = (e, { checked }) => {
    setSearchable(checked);
  }

  const handleOptionsChange = (options) => {
    setOptions(options);
    if(options.length > 0) {
      setErrors({
        ...errors,
        options: null
      })
    }
  }

  const validateForm = () => {
    var errors = {};

    if (!name || name.trim() === '') {
      errors['name'] = 'Please provide a valid name';
    }

    if(!options || options.length === 0) {
      errors['options'] = 'Please add options';
    }

    return errors;
  }

  const handleSave = () => {
    let errors = validateForm();

    if (Object.keys(errors).length > 0) {
      setErrors(errors);
      return;
    }

    var request = {
      name: name,
      helpText: helpText,
      options: options,
      multiselect: multiselect,
      searchable: searchable,
      type: "list_field"
    }

    if(id === 'new') {
      dispatch(createCustomField(request, (response) => {
        let baseUrl = getBaseUrl(url);
        history.push(`${baseUrl}/settings/custom_fields/${response.id}`);
      }))
    } else {
      dispatch(updateCustomField(id, request))
    }
  }

  const handleReset = () => {
    var customField = customFields.find(item => {
      return item.id === id;
    });
    setName(customField ? customField.name : '');
    setHelpText(customField ? customField.helpText : '');
    setOptions(customField ? customField.options : []);
    setMultiSelect(customField ? customField.multiselect : false);
    setSearchable(customField ? customField.searchable : false);
    setErrors({});
  }

  const buttonLabel = () => {
    if(id === 'new') {
      if(creating) {
        return 'Creating...'
      }

      return 'Create'
    }

    if(updating) {
      return 'Updating...'
    }

    return 'Update'
  }

  return (
    <Segment basic className={styles.form}>
      <Menu secondary className={styles.formMenu}>
        <Menu.Item header as="h2" className={styles.headerText}>{`${id !== 'new' ? 'Update': 'Create'} custom field`}</Menu.Item>
      </Menu>
      <Form className={styles.customFieldForm}>
        <Form.Field
          className={styles.inputField}
          id='form-input-control-first-name'
          control={Input}
          value={name}
          onChange={handleNameChange}
          label='NAME'
          placeholder='Provide a valid name'
          error={
            errors.name ? { content: errors.name, pointing: 'above' } : null
          }
        />

        <Form.Field
          className={styles.inputField}
          id='form-textarea-control-opinion'
          control={TextArea}
          value={helpText}
          onChange={handleHelpTextChange}
          label='HELP TEXT'
          placeholder='Provide a valid help text'
        />

        <ValuesDropDown values={options} onChange={handleOptionsChange} error={errors.options}/>

        <Form.Field
          className={cx(styles.allowMultiSelect, styles.inputField)}
          control={Form.Checkbox}
          checked={multiselect}
          onChange={handleMultiSelectChange}
          label='ALLOW MULTI SELECT'
          toggle
        />

        <Form.Field
          className={cx(styles.allowMultiSelect, styles.inputField)}
          control={Form.Checkbox}
          checked={searchable}
          onChange={handleSearchableChange}
          label='ALLOW SEARCH'
          toggle
        />

        <Menu
          secondary
          size='large'  
          borderless
          className={styles.actionMenu}
        >
          <Menu.Menu position='right'>
            <Menu.Item className={styles.menuButton}>
              <Button size='mini' onClick={handleSave} className={styles.saveButton}>
                <Icon loading={creating || updating} name={creating || updating ? 'spinner' : 'save'} />
                {buttonLabel()}
            </Button>
            </Menu.Item>
            <Menu.Item className={styles.menuButton}>
              <Button size='mini' onClick={handleReset} className={styles.resetButton}>
                <Icon name='redo' />Reset
              </Button>
            </Menu.Item>
          </Menu.Menu>
        </Menu>
      </Form>
    </Segment>
  )
}

function ValuesDropDown({ values, onChange, error, ...rest }) {
  const [options, setOptions] = useState((values || []).map(item => {
    return {
      key: item.id,
      text: item.value,
      value: item.id
    }
  }) || []);

  useEffect(() => {
    setOptions(
      (values || []).map(item => {
        return {
          key: item.id,
          text: item.value,
          value: item.id
        }
      }) || []
    )
  }, [values])

  const handleChange = (e, { value }) => {
    var changes = (value || []).map(item => {
      var option =  options.find(val => {
        return val.value === item;
      })

      if(!option) {
        option = options.find(val => {
          return val.text === item;
        })
      }

      var newOption = null;

      if(option) {
        newOption =  {
          id: option.value,
          value: option.text
        }
      } else {
        newOption = {
          id: guid(),
          value: item
        }
        
        setOptions([{ text: newOption.value, value: newOption.id }, ...options])
      }

      return newOption;
    })

    var uniqueChanges = [...new Set(changes.map(({ id }) => id))].map(e => changes.find(({id}) => id == e));	

    onChange(uniqueChanges);
  }

  var dropDownValues = values.map(item => {
    return item.id;
  })

  return (
    <Form.Field
      className={cx(styles.valuesDropdown, styles.inputField)}
      control={Form.Select}
      options={options}
      label={{ children: 'LIST VALUE', htmlFor: 'form-select-control-option' }}
      placeholder='Add values'
      multiple
      search
      selection
      fluid
      allowAdditions
      value={dropDownValues}
      onChange={handleChange}
      noResultsMessage={null}
      searchInput={{ id: 'form-select-control-option' }}
      error={
        error ? { content: error, pointing: 'above' } : null
      }
    />
  )
}