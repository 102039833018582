let uploaders = {};
export default class FileUploadService{
  
  static trackUpload(uploader){
    if(!uploader){
      return;
    }

    if(!uploader.files){
      return;
    }

    uploader.files.forEach((file)=>{
      console.log('Tracking uploader for file ' + file.id);
      uploaders[file.id] = uploader;
    })
  }

  static getUploader(id){
    return uploaders[id];
  }

  static markFinish(id){
    delete uploaders[id];
  }
}