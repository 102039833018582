import { createStore, applyMiddleware } from 'redux';
import rootReducer from './reducers/index.js';
import createSagaMiddleware from 'redux-saga';

import rootSaga from './sagas/index.jsx';
import { composeWithDevTools } from 'redux-devtools-extension';
import { createBrowserHistory } from "history";
export const history = createBrowserHistory();

const sagaMiddleware = createSagaMiddleware();

const middlewares = [sagaMiddleware];

const store = createStore(
    rootReducer,
    composeWithDevTools(applyMiddleware(...middlewares))
)
sagaMiddleware.run(rootSaga)

export default store;