import React, {useState} from 'react';

import Slider from 'react-slick';
import cx from 'classnames';

import { Icon } from 'semantic-ui-react'

import styles from './Carousel.module.scss';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export default function CarouselView(props){
  
  const [scrolled, setScrolled] = useState(false)
  const [slidesToShow] = useState(props.slidesToShow ? props.slidesToShow : 6)

  const slider = React.createRef();

  const handleSliderChange = () => {
    setScrolled(true)
  }

  const settings = {
    dots: props.dots || true,
    infinite: false,
    speed: 500,
    slidesToShow: slidesToShow,
    slidesToScroll: slidesToShow,
    center: false,
    dotsClass: styles.dots,
    variableWidth: true,
    adaptiveHeight: false,
    draggable: false,
    prevArrow: <div><Icon name="angle left" /></div>,
    nextArrow: <div><Icon name="angle right" /></div>
  };


  const handleMouseEnter = (event) => {
    if (slider) {
  
      var currentSlide = event.currentTarget;
      var width = currentSlide.offsetWidth - 20;
      var growthFactor = 1.15;
  
      var moveLeft = -(width * (growthFactor - 1)/2);
      var moveRight = (width * (growthFactor - 1)/2);
  
  
      var slides = document.querySelectorAll(`.slick-carousel_${props.id}`);
      if(slides){
  
        var left = true;
        var currentIndex = -1;
        slides.forEach((slide, index)=>{
          if(slide === currentSlide){
            currentIndex = index;
            left = false;
            slide.style.transform = `scale(${ growthFactor })`;
          }else{
            var offset = left ? `${moveLeft}px` : `${moveRight}px`;
            slide.style.transform = 'translate3D(' + offset +',0,0)';
          }
        })
      }
  
      props.onSlideChange(currentIndex);
    }
  }

  const handleMouseLeave = (event) => {
    if (slider) {
      var slides = document.querySelectorAll(`.slick-carousel_${props.id}`);
      if(slides){
        slides.forEach((slide)=>{
          slide.style.transform = 'translate3D(0,0,0)';
        })
      }
    }

    props.onSlideChange(-1);
  }

  return(
    <div>
      <Slider ref = {slider}
        {...settings} 
        className={cx(styles.carousel, {[styles.initial]: !scrolled})} 
        afterChange={handleSliderChange}>
          {props.children &&
            props.children.map((item) => {
              let cloned = React.cloneElement(item);

              return (
                <div
                  className={cx(styles.slide, props.itemClass, `slick-carousel_${props.id}`)} 
                  onMouseEnter={handleMouseEnter}
                  onMouseLeave={handleMouseLeave}
                  key={cloned.key}>
                  <div className={styles.inner}>
                    {cloned}
                  </div>
                </div>
              );

            })
          }
      </Slider>
    </div>
  );
}