import React, { useState, useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import {
  authenticateUser, authenticatingUserSucceeded, fetchSSOUrlFromEmail, ssoAuthenticationFailed, logoutUser,
  forgotPassword, fetchUserProfile
} from 'actions/users'


import { Link, Route, Switch, useHistory, useRouteMatch } from "react-router-dom";

import _ from 'lodash';
import cx from 'classnames';

import {
  Button,
  Form,
  Header,
  Input,
  Image
} from 'semantic-ui-react';

import styles from './signin.module.scss';

import AnonymousPage from '../common/index.jsx';

export default function SigninView(props) {

  const { path, url } = useRouteMatch();


  return (
    <div className={styles.content}>
      <div className={styles.left}>
        <div className={styles.inner}>
          <Image src="/images/logo_landscape.svg" className={styles.logo} />
          <h1 className={styles.heading}>
            The canvas for your masterpiece is calling you and you MUST go at once.
          </h1>
          <p className={styles.description}>
            Drag it, Drop it, Snip it or Rip it. <br /> With Cleverstory's endless possibilites, give your content the story it deserves.
          </p>
        </div>
      </div>
      <div className={styles.right}>
        <Switch>
          <Route path={`${path}`} exact>
            <SignInForm />
          </Route>
          <Route path={`${path}/forgot-password`} exact>
            <ForgotPasswordForm />
          </Route>
        </Switch>
      </div>
    </div>
  )
}

function SignInForm(props) {
  const history = useHistory();
  const dispatch = useDispatch();

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [errors, setErrors] = useState({});

  const ssoUrl = useSelector(state => _.get(state, 'users.sso.item.url'));
  const authenticated = useSelector(state => _.get(state, 'users.authentication.authenticated'));
  const error = useSelector(state => _.get(state, 'users.authentication.error'));
  const signinWithPassword = useSelector(state => _.get(state, 'users.sso.signinWithPassword'));
  const authenticating = useSelector(state => _.get(state, 'users.authentication.authenticating'));
  const cleverstory = useSelector(state => _.get(state, 'users.me.permissions.cleverstory'));
  const user = useSelector(state => _.get(state, 'users.me', null));

  var windowEventListener = null;

  useEffect(() => {
    dispatch(logoutUser());
  }, [])

  useEffect(() => {
    if (ssoUrl) {
      initiateSSO(ssoUrl);
    }

    if (authenticated) {
      dispatch(fetchUserProfile());
    }

  }, [ssoUrl, authenticated])

  useEffect(()=> {
    if (cleverstory){
      history.push('/');
    } 
    
    if (user && !cleverstory) {
      let errors = {};
      errors.email = "Access denied";
      setErrors(errors);
    }
    
  }, [cleverstory])

  const onLogin = (e) => {
    e.preventDefault();

    if (signinWithPassword) {
      dispatch(authenticateUser(username, password));
    } else {
      handleSSOClick(e);
    }
  }

  const handleSSOClick = (e) => {
    e.preventDefault();
    const errors = validateUserName;

    if (Object.keys(errors).length !== 0) {
      setErrors(errors);
      return;
    }

    dispatch(fetchSSOUrlFromEmail(username));
  }

  const validateUserName = () => {
    let errors = {};
    var email = username;
    if (!email || email.trim() === '') {
      errors.email = "Enter a valid email id"
    }

    const regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!regex.test(email)) {
      errors.email = "Enter a valid email id";
    }

    return errors;
  }

  const handleUserName = (e) => {
    var value = e.target.value;
    if (value) {
      value = value.trim().toLowerCase();
    }

    setUsername(value);
    setErrors({});
  }

  const handlePassword = (e) => {
    var value = e.target.value;
    if (value) {
      value = value.trim();
    }

    setPassword(value);
    setErrors({});
  }

  const initiateSSO = (url) => {
    //Calculate Position
    var dualScreenLeft = window.screenLeft != undefined ? window.screenLeft : window.screenLeft;
    var dualScreenTop = window.screenTop != undefined ? window.screenTop : window.screenTop;

    var width = window.innerWidth ? window.innerWidth : document.documentElement.clientWidth ? document.documentElement.clientWidth : window.screen.width;
    var height = window.innerHeight ? window.innerHeight : document.documentElement.clientHeight ? document.documentElement.clientHeight : window.screen.height;

    var w = 0.8 * width;
    var h = 0.8 * height;

    var left = ((width / 2) - (w / 2)) + dualScreenLeft;
    var top = ((height / 2) - (h / 2)) + dualScreenTop;

    var windowName = 'userConsole';
    var newWindow = window.open(url, windowName, `Initiating Single signon`, 'scrollbars=yes, width=' + w + ', height=' + h + ', top=' + top + ', left=' + left);

    if (newWindow == null || typeof (newWindow) == 'undefined') {
      // handlePopBlockedError(url);
    } else {
      newWindow.focus();
      windowEventListener = addEventListener(handleCodeRecievedEvent);
    }

    return false;
  }

  const addEventListener = (fn) => {
    var eventMethod = window.addEventListener ? "addEventListener" : "attachEvent";
    var eventer = window[eventMethod];
    var messageEvent = eventMethod == "attachEvent" ? "onmessage" : "message";

    eventer(messageEvent, fn, false);

    return function () {
      var detachEventMethod = window.removeEventListener ? 'removeEventListener' : 'detachEvent';
      var deEventer = window[detachEventMethod];
      var messageEvent = detachEventMethod == "detachEvent" ? "onmessage" : "message";

      deEventer(messageEvent, fn, false);
    };
  }

  const handleCodeRecievedEvent = (e) => {
    if (e.data.type == 'SSO_AUTHENTICATION_SUCCESS') {
      dispatch(authenticatingUserSucceeded(e.data.payload));
      history.push("/");

      if (windowEventListener) {
        windowEventListener();
      }

    } else if (e.data.type == 'SSO_AUTHENTICATION_FAILURE') {
      dispatch(ssoAuthenticationFailed(e.data.payload.reason));
      history.push('/sso/failure');

      if (windowEventListener) {
        windowEventListener();
      }
    }
  }

  const renderAuthenicateTitle = () => {
    if (!signinWithPassword) {
      return 'Sign In now';
    }
    if (authenticating === true) {
      return "Signing in...";
    } else {
      return "Sign in";
    }
  }

  return (
    <div className={cx(styles.form, styles.login)}>
      <Header as="h2" className={styles.header}>
        Sign in to unleash the power of storytelling!
      </Header>
      <div className={styles.error}>{error}</div>
      <Form onSubmit={onLogin}>
        <Form.Field
          control={Input}
          value={username}
          onChange={handleUserName}
          placeholder="Email"
          error={
            errors.email ? { content: errors.email, pointing: 'above' } : null
          }>
        </Form.Field>
        {signinWithPassword &&
          <>
            <Form.Field>
              <Input type='password' placeholder="Password"
                value={password}
                onChange={handlePassword}
              />
            </Form.Field>
            <Form.Field className={styles.forgotPasswordField}>
              {!authenticating &&
                <Button basic as={Link} to={'/login/forgot-password'} className={styles.forgotPassword}> Forgot password? </Button>
              }
            </Form.Field>
          </>
        }
        <Button primary type="submit" disabled={authenticating}>
          {renderAuthenicateTitle()}
        </Button>
      </Form>
    </div>
  );
}

function ForgotPasswordForm() {
  const dispatch = useDispatch();
  const [email, setEmail] = useState('');
  const [postConfirm, setPostConfirm] = useState(false);
  const [validationError, setValidationError] = useState(null);

  const error = useSelector(state => _.get(state, 'users.forgotPassword.error'));
  const processing = useSelector(state => _.get(state, 'users.forgotPassword.loading'));

  const onSubmit = () => {  
    dispatch(forgotPassword(email, () => {
      setPostConfirm(true);
    }));
  }

  return (
    <div className={cx(styles.form, styles.forgotPassword)}>
      <Header as="h2" className={styles.header}>
        FORGOT PASSWORD?
        <Header.Subheader className={styles.subHeader}>
          Please enter your email address to get instructions
        </Header.Subheader>
      </Header>

      {error && 
        <div className={styles.error}>{error}</div>
      }

      {!postConfirm &&
        <Form onSubmit={onSubmit}>
          <Form.Field
            control={Input}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Enter email address"
            error={
              validationError ? { content: validationError, pointing: 'above' } : null
            }>
          </Form.Field>
          
          <Button primary type="submit" disabled={processing}>
            {processing ? 'Processing...' : 'Continue'}
          </Button>
        </Form>
      }

      {postConfirm &&
        <>
          <p>The link to change your password has been sent to your email</p>
          <Button primary floated='left' as={Link} to='/login'>Dismiss</Button>
        </>  
      }
    </div>
  );
}