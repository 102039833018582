import React, { Fragment, useState, useEffect, useMemo } from 'react';
import {useDispatch, useSelector} from 'react-redux';
import { createCluster, updateCluster } from 'actions/clusters';
import { searchAssets } from 'actions/content';

import {  assetTypesOptions } from 'constants/filters';

import _ from 'lodash';
import { guid } from 'services/util';


import dateformat from 'dateformat';
import FileService from 'services/file.service.jsx';

import cx from 'classnames';

import {
  Button,
  Icon,
  Segment,
  Input,
  Label,
  Grid,
  Dropdown,
  Search,
  Item,
  Image,
  Header,
  Menu
} from 'semantic-ui-react';

import Truncate from 'react-truncate';

import styles from './clusters.module.scss';
import { Property } from 'components/Properties';

const properties = [{
  type: 'asset',
  name: 'Asset',
  data_type: 'asset',
  config: {

  }
},{
  type: 'file_type',
  name: 'File Type',
  data_type: 'list',
  config: {
    multiple: true,
    options: assetTypesOptions
  }
},{
  type: 'tags',
  name: 'Tags',
  data_type: 'tags',
  config: {
    multiple: true
  }
}]

const conditions = [{
  type: 'list',
  options:[{
    key: 'is',
    text: 'Is'
  },{
    key: 'is_not',
    text: 'Is Not'
  },{
    key: 'is_empty',
    text: 'Is Empty'
  },{
    key: 'is_not_empty',
    text: 'Is Not Empty'
  }]
},{
  type: 'asset',
  options:[{
    key: 'is',
    text: 'Is'
  },{
    key: 'is_not',
    text: 'Is Not'
  }]
},{
  type: 'input',
  options:[{
    key: 'is',
    text: 'Is'
  },{
    key: 'is_not',
    text: 'Is Not'
  },{
    key: 'contains',
    text: 'Contains'
  },{
    key: 'does_not_contain',
    text: 'Does Not Contain'
  },{
    key: 'starts_with',
    text: 'Starts With'
  },{
    key: 'ends_with',
    text: 'Ends With'
  },{
    key: 'is_empty',
    text: 'Is Empty'
  },{
    key: 'is_not_empty',
    text: 'Is Not Empty'
  }]
},{
  type: 'tags',
  options:[{
    key: 'is',
    text: 'Is'
  },{
    key: 'is_not',
    text: 'Is Not'
  },{
    key: 'is_empty',
    text: 'Is Empty'
  },{
    key: 'is_not_empty',
    text: 'Is Not Empty'
  }]
}]

function getPropertyByKey(properties, value){
  var property = properties.find(property => {
    return _.isEqual(property.value, value);
  })

  return property;
}

function getConditionsForProperty(property){
  if(!(property && property.data_type)){
    return [];
  }

  const filter = conditions.find(item => {
    return item.type === property.data_type;
  });

  if(!filter){
    return [];
  }

  return filter.options || [];
}

function getDefaultConditionForProperty(property){
  const conditions = getConditionsForProperty(property);
  if(conditions.length == 0){
    return '';
  }

  return conditions[0].key;
}

function getValuesForProperty(property){
  const type = property.type;
  if(type === 'custom_field'){
    return (property.options || []).map(option => {
      return {
        key: option.id,
        value: option.id,
        text: option.value
      }
    });
  }else{
    return property.config.options;
  }
}

function processfiltersForRequest(filters) {
  var updatedFilters = (filters || []).map(filter => {
    var property = filter.property;
    var operator = filter.operator;
    var value = filter.value;

    if(filter.property.type === 'asset'){
      value = (filter.value || []).map(item => item.id);
    }

    return {
      property: property,
      operator: operator,
      value: value  
    }
  });

  return updatedFilters;
}

const PropertyContext = React.createContext([]);

export function Filters({value, cluster, createDisabled, editable, className, onChange = () => {}}){
  const dispatch = useDispatch();


  var filters = (value || []).map(filter => {
    if(!filter._id){
      return {
        ...filter,
        _id: guid()
      }
    }

    return filter;
  });

  /**
   * Properties
  */
  const customFields = useSelector(state => _.get(state, 'customFields.items', []));
  const propertiesOptions = useMemo(() => {
    const basicProperties = properties.map(item => {
      return {
        ...item,
        key: item.type,
        value: {
          type: item.type
        }
      }
    })
    
    const customFieldProperties = customFields.map(field => {
      return {
        key: `custom_field_${field.id}`, 
        id: field.id,
        type: 'custom_field',
        name: field.name,
        data_type: 'list',
        value: {
          type: 'custom_field',
          fieldId: field.id
        },
        config: {
          multiple: true,
          options: (field.options || []).map(option => {
            return {
              key: option.id,
              value: option.id,
              text: option.value
            }
          })
        }
      }
    });

    return [...basicProperties, ...customFieldProperties];
  }, [customFields]);


  /**
   * Custom functions
  */
  const insertFilter = (index = -1) => {
    const filter = {
      _id: guid(),
      property: propertiesOptions[0].value,
      operator: getDefaultConditionForProperty(propertiesOptions[0])
    }

    var updated = [...filters];
    if(index < 0){
      updated = [...filters, filter];
    }else{
      updated.splice(index + 1, 0, filter);
    }
    onChange(updated);
  }

  const deleteFilter = (index) => {
    var updated = [...filters];
    updated.splice(index, 1);
    onChange(updated)
  }

  const onFilterChange = (filter) => {
    var updated = filters.map((item, index) => {
      if(item._id === filter._id){
        return filter;
      }

      return item;
    });

    onChange(updated);
  }

  const handleCreateCluster = (name) => {
    var request = {
      name: name,
      filters: processfiltersForRequest(filters)
    }

    dispatch(createCluster(request));
  }

  const handleSaveCluster = (name) => {
    var request = {
      id: cluster.id,
      name: name,
      filters: processfiltersForRequest(filters)
    }

    dispatch(updateCluster(cluster.id, request));
  }

  return (
    <Segment basic className={cx(styles.filters, className)}>
      <Grid>
        <PropertyContext.Provider value={propertiesOptions}>
          {filters.map((filter, index) => {
            return (
              <Filter 
                key={index}
                index={index}
                value={filter} 
                editable={editable}
                onChange={onFilterChange}
                onInsert={insertFilter} 
                onDelete={deleteFilter} 
              />
            )
          })}
        </PropertyContext.Provider>
        <Grid.Row>
          <Grid.Column>
            {editable &&
              <FiltersMenu 
                cluster={cluster}
                createDisabled={(!filters || filters.length == 0) || createDisabled || !editable}
                insertFilter={insertFilter.bind(this, -1)}
                createCluster={handleCreateCluster}
                updateCluster={handleSaveCluster}
              />
            }
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Segment>
  );
}

export function Filter({value, index, editable, onChange, onInsert, onDelete}){
  const properties = React.useContext(PropertyContext);
  const property = getPropertyByKey(properties, value.property);

  const handlePropertyTypeChange = (item) =>{
    var filter = {
      _id: value._id,
      property: item,
      operator: getDefaultConditionForProperty(property),
      value: null
    };

    onChange(filter);
  }

  const handleConditionChange = (operator) => {
    var filter = {
      ...value,
      operator: operator 
    };

    onChange(filter);
  }

  const handleValueChange = (changedValue) => {
    var filter = {
      ...value,
      value: changedValue,
      input: ''
    };

    onChange(filter);
  }

  const handleInputChange = (input) => {
    var filter = {
      ...value, 
      input: input
    };

    onChange(filter);
  }

  return (
    <Grid.Row columns="equal" className={styles.filter}>
      <Grid.Column className={styles.compact} >
        <PropertyTypeFilter value={value.property} onChange={handlePropertyTypeChange} editable={editable} />
      </Grid.Column>
      <Grid.Column className={styles.compact}>
        <ConditionFilter value={value} onChange={handleConditionChange} editable={editable} />
      </Grid.Column>
      <Grid.Column className={styles.values}>
        <ValueComponent property={value.property} 
          value={value.value} 
          input={value.input} 
          editable={editable}
          onChange={handleValueChange} 
          onInputChange={handleInputChange} 
        />
      </Grid.Column>
      <Grid.Column className={cx(styles.compact, styles.actions)} floated='right'>
        {editable &&
          <>
            <Button basic icon="add" size='mini' onClick={onInsert.bind(this, index)}/>
            <Button basic icon="delete" size='mini' onClick={onDelete.bind(this, index)}/>
          </>
        }
      </Grid.Column>
    </Grid.Row>
  );
}
Filters.Filter = Filter;

function PropertyTypeFilter({value, editable, onChange}){
  const properties = React.useContext(PropertyContext);
  const property = getPropertyByKey(properties, value);
  const selectedValue = _.get(property, 'key');

  const options = useMemo(() => {
    return properties.map(p => {
      return {
        key: p.key,
        text: p.name,
        value: p.key
      }
    })
  }, [properties]);

  const handleChange = (e, data) => {
    const selectedOption = properties.find(p => {
      return p.key === data.value;
    })

    onChange(selectedOption.value);
  }

  return (
    <Dropdown
      className={styles.dropdown}
      placeholder='Select a property...'
      search
      selection
      disabled={!editable}
      options={options}
      value={selectedValue}
      onChange={handleChange}
    >
    </Dropdown>  
  );
}

function ConditionFilter({value, editable, onChange}){
  const properties = React.useContext(PropertyContext);
  const property = getPropertyByKey(properties, value.property);

  var conditionOptions = getConditionsForProperty(property).map(option => {
    return {key: option.key, value: option.key, text: option.text};
  });

  const handleConditionChange = (e, data) => {
    const value = data.value;
    onChange(value);
  }

  return (
    <Dropdown
      className={cx(styles.dropdown, styles.condition)}
      selection
      compact
      disabled={!editable}
      value={value.operator}
      options={conditionOptions}
      onChange={handleConditionChange}
    />
  );
}

/**
 * Value Input
 * 
*/

function ValueComponent({property, value, editable, onChange, input, onInputChange}){
  const properties = React.useContext(PropertyContext);
  const propertyConfig = getPropertyByKey(properties, property);

  var type = propertyConfig ? propertyConfig.data_type : null;
  if(type === 'asset'){
    return (
      <AssetValue property={propertyConfig} 
        value={value} 
        editable={editable}
        onChange={onChange} 
        input={input} 
        onInputChange={onInputChange} />
    )
  }else if(type === 'list'){
    return (
      <ListValue property={propertyConfig} 
        value={value} 
        editable={editable}
        onChange={onChange} 
        input={input} 
        onInputChange={onInputChange} />
    );
  }

  return (
    <InputValue property={propertyConfig} 
      value={value} 
      editable={editable}
      onChange={onChange} 
      input={input} 
      onInputChange={onInputChange} />
  );
}

function AssetValue({ property, value, editable, onChange, input, onInputChange}){
  const values = value || [];
  
  const dispatch = useDispatch();
  const searching = useSelector(state => state.content.search.searching);
  const assets = useSelector(state => {
    return state.content.search.items.map(item => {
      return state.content.assetsById[item];
    })
  });
  
  const handleSearchChange = (e, { value }) => {
    dispatch(searchAssets(value, 0));
    onInputChange(value);
  }

  const handleAssetSelect = (e, { result })  => {
    var updated = values;
    if(!updated){
      updated = [];
    }

    if(result){
      updated = [...updated, result];
      _.uniqBy(updated, 'id');
      onChange(updated);
    }
  }

  const deleteValue = (index) => {
    var updated = [...values];
    updated.splice(index, 1);
    onChange(updated);
  }

  return (
    <>
      {values.map((item, index) => {
        return (
          <Label key={item.id} image className={cx(styles.value)}>
            <Truncate width={200}>{item.name}</Truncate>
            {editable &&
              <Icon name='delete' onClick={deleteValue.bind(this, index)}/>
            }
          </Label>
        );
      })}

      {editable &&
        <Search size='mini' className={styles.search}
          loading={searching}
          icon={null}
          placeholder='Type something'
          onSearchChange={_.debounce(handleSearchChange, 500, {
            leading: true,
          })}
          onResultSelect={handleAssetSelect}
          results={assets}
          resultRenderer={(result) => {
            return <AssetSearchResult {...result} />
          }}
          value={input}
        />
      }
    </>
  );
}

function ListValue({ property, value, editable, onChange, input, onInputChange}){
  var options = property.config.options;
  const values = value || [];
  const [selected, setSelected] = useState(null);

  const handleChange = (e, data) => {
    var option = data.value;
    var updated = value;
    if(!updated){
      updated = [];
    }

    if(option){
      updated = [...updated, option];
      updated = _.uniq(updated);
      onChange(updated);
    }


  }

  const deleteValue = (index) => {
    var updated = [...values];
    updated.splice(index, 1);
    onChange(updated);
  }

  return (
    <Fragment>
      {values.map((item, index) => {
        var option = options.find(o => {
          return o.value === item;
        })

        if(!option){
          return false;
        }

        return (
          <Label key={`${item}-${index}`} image className={cx(styles.value)}>
            {option.text}
            {editable && 
              <Icon name='delete' onClick={deleteValue.bind(this, index)}/>
            }
          </Label>
        );
      })}

      {editable &&
        <Dropdown
          className={cx(styles.dropdown, styles.listValueDropdown)}
          placeholder='Select values'
          openOnFocus={false}
          selection
          value={false}
          options={options}
          onChange={handleChange}
        />
      }
    </Fragment>  
  )
}

function InputValue({ property, value, editable, onChange, input, onInputChange}){
  const values = value || [];

  const handleKeyPressed = (e) => {
    if (e.key === "Enter") {
      handleChanges();
    }
  }

  const handleChanges = () => {
    var updated = value;
    if(!updated){
      updated = [];
    }

    if(input && input.trim()){
      updated = [...updated, input.trim()];
      _.uniq(updated);
      onChange(updated);
    }
  }

  const deleteValue = (index) => {
    var updated = [...values];
    updated.splice(index, 1);
    onChange(updated);
  }

  return (
    <Fragment>
      {values.map((item, index) => {
        return (
          <Label key={`${item}-${index}`} image className={cx(styles.value)}>
            {item}
            {editable &&
              <Icon name='delete' onClick={deleteValue.bind(this, index)}/>
            }
          </Label>
        );
      })}

      {editable &&
        <Input className={styles.input} size='mini'
          value={input} 
          placeholder='Type something'
          onChange={(e) => {
            onInputChange(e.target.value);
          }}
          onBlur={handleChanges}
          onKeyPress={handleKeyPressed}
        />
      }
    </Fragment>
  );
}

/** 
 * Asset Search Result
 * 
*/

function AssetSearchResult({name, icon, metadata, contentModifiedDate}) {
  const iconUrl = icon ? icon.thumbnail : '/images/default_asset.jpg';

  var description = null;
  if (metadata.pages) {
    description = `${metadata.pages} Pages`;
  } else if (metadata.video_duration) {
    description = `${FileService.formatDuration(metadata.video_duration)}`;
  }

  return (
    <div className={styles.result}>
      <Image size='tiny' src={iconUrl} />

      <div className={styles.content}>
        <Header as='h6'><Truncate lines={2}>{name}</Truncate></Header>
        <div className={styles.meta}>
          <span>{FileService.fileType(metadata)}</span>
          {description && <span>{description}</span>}
          <span>Updated on {dateformat(contentModifiedDate, 'dS mmmm')}</span>
        </div>
      </div>  
    </div>
  );
}


function FiltersMenu({disabled, cluster, createDisabled, insertFilter, createCluster, updateCluster}){
  const [name, setName] = useState('');
  const [error, setError] = useState(false);

  const updating = useSelector(state => _.get(state, 'clusters.cluster.updating', null));
  const creating = useSelector(state => _.get(state, 'clusters.cluster.creating', null));

  useEffect(() => {
    var name = cluster ? cluster.name : '';
    setName(name);
  }, [cluster]);

  const handleUpdateClick = () => {
    if(!name || name.trim().length === 0) {
      setError(true);
      return;
    }

    updateCluster(name);
  }
  
  const handleCreateClick = () => {
    if (!name || name.trim().length === 0) {
      setError(true);
      return;
    }

    createCluster(name);
  }

  return (
    <>
      <Menu secondary className={styles.filterMenu}>
        <Menu.Item className={styles.addFilter} onClick={insertFilter}>
          <Icon name='plus' />Add Filter
        </Menu.Item>
        {!createDisabled &&
          <Menu.Item>
            <Input action error={error}>  
              <input value={name} onChange={(e) => {
                setName(e.target.value);
                setError(false);
              }}/>

              {cluster && 
                <Button disabled={updating} onClick={handleUpdateClick}>{updating ? 'Updating...' : 'Update'}</Button>
              }
              <Button disabled={creating} onClick={handleCreateClick}>{creating ? 'Saving...' : 'Save as new'}</Button>
            </Input>
          </Menu.Item>
        }
      </Menu>
    </>
  );
}