import {
  AUTHENTICATE_ZOHO_REQUEST, AUTHENTICATE_ZOHO_SUCCEDDED, AUTHENTICATE_ZOHO_FAILED
} from 'constants/actionTypes.jsx';

const initialState = {
authentication: {
  authenticating: false,
  error: null
},
deactivation: {
  deactivating: false,
  error: null
}
};

function zohoReducer(state = initialState, action) {
switch (action.type) {
  case AUTHENTICATE_ZOHO_REQUEST:
    return Object.assign({}, state, {
      ...state,
      authentication: {
        authenticating: true,
        error: null
      }
    })
  case AUTHENTICATE_ZOHO_SUCCEDDED:
    return Object.assign({}, state, {
      ...state,
      authentication: {
        authenticating: false,
        error: null
      }
    })
  case AUTHENTICATE_ZOHO_FAILED:
    return Object.assign({}, state, {
      ...state,
      authentication: {
        authenticating: false,
        error: action.payload
      }
    }) 
  default:
    return state;
}
}

export { zohoReducer };