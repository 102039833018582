import {
  CREATE_CONTENT_EDITOR, UPDATE_CONTENT_EDITOR, INVALIDATE_CONTENT_EDITOR, ADD_ASSET_TO_CONTENT_EDITOR,
  UPDATE_ASSET_IN_CONTENT_EDITOR,
  FETCH_ASSETS_FOR_FILTERS, FETCH_ASSETS_FOR_FILTERS_SUCCEEDED, FETCH_ASSETS_FOR_FILTERS_FAILED,
  DELETE_ASSET_FROM_CONTENT_EDITOR, REARRANGE_ASSET_IN_CONTENT_EDITOR, 

  SAVING_CONTENT_IN_CONTENT_EDITOR, UPDATE_ASSET_IN_PLACEHOLDER

 } from 'constants/actionTypes';

import { createAction } from 'redux-actions';
import axios from 'axios';
import buildUrl from 'build-url';
import { call, put, takeLatest, takeEvery } from 'redux-saga/effects';

const apiUrl = `${process.env.REACT_APP_API_URL}/api/2.0`;
const CONTENT_BASE_URL = `${apiUrl}/content`;

export const createContentEditor = createAction(CREATE_CONTENT_EDITOR, (editor, content) => {
  return {
    editor: editor,
    content: content
  }
});

export const updateContentEditor = createAction(UPDATE_CONTENT_EDITOR, (editor, content) => {
  return {
    editor: editor,
    content: content
  }
});

export const invalidateContentEditor = createAction(INVALIDATE_CONTENT_EDITOR, (editor) => {
  return editor;
});

export const addAssetToContentEditor = createAction(ADD_ASSET_TO_CONTENT_EDITOR, (editor, asset) => {
  return  {
    editor: editor,
    asset: asset
  }
});

export const updateAssetInContentEditor = createAction(UPDATE_ASSET_IN_CONTENT_EDITOR, (editor, asset) => {
  return {
    editor: editor,
    asset: asset
  }
})

export const updateAssetInPlaceholder = createAction(UPDATE_ASSET_IN_PLACEHOLDER, (editor,asset) => {
  return {
    editor: editor,
    asset: asset
  }
})

export const deleteAssetFromContentEditor = createAction(DELETE_ASSET_FROM_CONTENT_EDITOR, (editor, asset) => {
  return {
    editor: editor,
    asset: asset
  }
})

export const rearrangeAssetInContentEditor = createAction(REARRANGE_ASSET_IN_CONTENT_EDITOR, (editor, asset, from, to) => {
  return {
    editor: editor,
    asset: asset,
    from: from,
    to: to
  }
})

export const savingContentInContentEditor = createAction(SAVING_CONTENT_IN_CONTENT_EDITOR, (editor) => {
  return {
    editor: editor
  }
});

/*
* Fetch all assets by filters
*/
export const fetchAssetsForFilters = createAction(FETCH_ASSETS_FOR_FILTERS, (editor, request, page, limit) => {
  return {
    editor: editor,
    request: request,
    page: page,
    limit: limit
  }
})

const fetchAssetsForFiltersSucceeded = createAction(FETCH_ASSETS_FOR_FILTERS_SUCCEEDED, (editor, request, page, limit, response) => {
  return {
    editor: editor,
    request: request,
    page: page,
    limit: limit,
    response: response
  }
})

const fetchAssetsForFiltersFailed = createAction(FETCH_ASSETS_FOR_FILTERS_FAILED, (editor, request, page, limit, error) => {
  return {
    editor: editor,
    request: request,
    page: page,
    limit: limit,
    error: error
  }
})

function* fetchAssetsForFiltersSaga(action) {
  const { editor, request, page, limit } = action.payload;

  var params = {
    page: page,
    limit: limit || 20
  }

  let url = buildUrl(CONTENT_BASE_URL, {
    queryParams: params
  });

  const fetchAssetsForFiltersAPI = () => {
    return axios.post(url, request).then(response => response.data);
  }

  try {
    const response = yield call(fetchAssetsForFiltersAPI);
    yield put(fetchAssetsForFiltersSucceeded(editor, request, page, limit, response));
  } catch (error) {
    yield put(fetchAssetsForFiltersFailed(editor, request, page, limit, error));
  }
}

export default function* contentSelectionSaga() {
  yield takeLatest(FETCH_ASSETS_FOR_FILTERS, fetchAssetsForFiltersSaga);
}