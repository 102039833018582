import { all, fork } from 'redux-saga/effects';


import watchAuthenticateUser from 'actions/users.jsx';
import storyboardSaga from 'actions/storyboard.jsx';
import watchFetchHubStreams from 'actions/paperflite.jsx';
import contentSaga from 'actions/content.jsx';
import aliasSaga from 'actions/aliases.jsx';
import pagesSaga from 'actions/pages';
import widgetEditorSaga from 'actions/widgetEditor';
import templatesSaga from 'actions/templates';
import clusterSaga from 'actions/clusters';
import customFieldsSaga from 'actions/customFields';
import journeysSaga from 'actions/journey';
import reportsSaga from 'actions/reports/reports';
import visitorsSaga from 'actions/reports/visitors';
import leadsSaga from 'actions/reports/leads';
import contentReportSaga from 'actions/reports/content';
import gatingSaga from 'actions/gatings';
import contentSelectionSaga from 'actions/contentSelection';
import userInvitationSaga from 'actions/userManagement';

import integrationsSaga from 'actions/integrations/integrations';
import freshmarketerSaga from 'actions/integrations/freshmarketer';
import pardotSaga from 'actions/integrations/pardot';
import formSaga from 'actions/forms';
import hubspotSaga from 'actions/integrations/hubspot';
import zohoSaga from 'actions/integrations/zoho';
import marketoSaga from 'actions/integrations/marketo';
import sixsenseSaga from 'actions/integrations/sixsense';



import liveElementsSaga from 'actions/live_elements';
import assetViewersSaga from 'actions/asset_viewer';

export default function* root() {
  yield all([
    fork(watchAuthenticateUser),
    fork(storyboardSaga),
    fork(watchFetchHubStreams),
    fork(contentSaga),
    fork(aliasSaga),
    fork(pagesSaga),
    fork(widgetEditorSaga),
    fork(templatesSaga),
    fork(clusterSaga),
    fork(liveElementsSaga),
    fork(customFieldsSaga),
    fork(journeysSaga),
    fork(reportsSaga),
    fork(visitorsSaga),
    fork(leadsSaga),
    fork(contentReportSaga),
    fork(gatingSaga),
    fork(integrationsSaga),
    fork(freshmarketerSaga),
    fork(contentSelectionSaga),
    fork(assetViewersSaga),
    fork(pardotSaga),
    fork(contentSelectionSaga),
    fork(formSaga),
    fork(hubspotSaga),
    fork(zohoSaga),
    fork(marketoSaga),
    fork(contentSelectionSaga),
    fork(userInvitationSaga),
    fork(sixsenseSaga),
  ]);
}