import React, {useState, Fragment, useRef} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {updateProperties, updateWidget} from 'actions/widgetEditor';

import _ from 'lodash';
import cx from 'classnames';

import {
  Grid
} from 'semantic-ui-react';

import { Widget, getStyles, getSpacingStyles } from '../widget.jsx';
import { denormalise } from 'services/widget.helper';
import { WidgetRegistry } from '../registry.jsx';
import { Property } from 'components/Properties';
import { QuickSettings, QuickSettingsProperty } from 'components/QuickSettings';
import { Banner } from 'components/Banner';

import { useWidgetDrop } from 'services/dnd.service';
import  ImageTools from 'services/imagetools.service';
import { ROW, HERO_BANNER, PASSWORD_PROTECTION} from 'constants/ItemTypes';

import styles from './heroBanner.module.scss';

export const HeroBanner = (props) => {
  const dispatch = useDispatch();
  const ref = useRef(null);
  const dropRef = useWidgetDrop(props, ref, {
    acceptTargets: (type) => {
      return _.includes([ROW, HERO_BANNER, PASSWORD_PROTECTION], type);
    }
  });
  
  const properties = props.properties;

  const bannerType = _.get(properties, 'bannerType', 'auto');
  const fluid = _.get(properties, 'fluid');
  const marginStyle = getSpacingStyles('margin', properties.margin) || {};
  const paddingStyle = getSpacingStyles('padding', properties.padding) || {};

  return (
    <Grid columns='equal' 
      container={!fluid}
      className={cx(props.className, styles.heroBanner, 'widgets__hero_banner')}
      id={`widget_${props.id}`}
      as={FowardableRow}
      forwardedRef={props.dragRef(dropRef(ref))}
      onMouseOver={props.onMouseOver}
      onMouseOut={props.onMouseOut}
      onClick={props.onClick}
      style={marginStyle}
    >  
      <Grid.Row>
        <Grid.Column>
          <div className={cx(styles.banner, styles[bannerType])} style={paddingStyle}>
            <Banner properties={properties} />
            <div className={styles.text}>
              <Widget.Children widgets={props.widgets} parent={props.id} editor={props.editor} editable={props.editable} />
            </div>  
          </div>
        </Grid.Column>
        {props.children}
      </Grid.Row>  
    </Grid>
  );
}

function HeroBannerQuickSettings(props) {
  const dispatch = useDispatch();

  const [selected, setSelected] = useState('spacing');

  const menuItems = [
    { id: 'spacing', title: 'Spacing', icon: 'icon-spacing'},
    { id: 'background', title: 'Background', icon: 'icon-background' },
    { id: 'border', title: 'Border', icon: 'icon-border' },
    { id: 'layout', title: 'Layout', icon: 'icon-layout' },
    { id: 'advanced', title: 'Advanced', icon: 'icon-custom' }
  ]

  const handleMenuClick = (item) => {
    setSelected(item);
  }

  var widget = useSelector(state => {
    const editorContext = state.widgetsEditor.editors[props.editor];

    if (editorContext) {
      return editorContext.widgetsById[props.id];
    }

    return null;
  });

  var widgetsById = useSelector(state => {
    const editorContext = state.widgetsEditor.editors[props.editor];

    if (editorContext) {
      return editorContext.widgetsById;
    }

    return {};
  });

  if (!widget) {
    return false;
  }

  var properties = widget.properties;
  var updating = widget.updating;
  var updatingBackground = updating && _.get(properties, 'background.upload') != null;

  const handleChange = (changeRequest) => {
    dispatch(updateProperties({
      id: widget.id,
      change: changeRequest,
      context: props.editor
    }));
  }

  const handleBackgroundImageChange = (media, type) => {
    console.log("Upload image " + type);

    var changeRequest = { ...widget };
    _.set(changeRequest, 'properties.background.upload', {
      type: type,
      data: media
    });

    var widgets = denormalise(changeRequest.widgets, widgetsById);
    changeRequest = _.set(changeRequest, 'widgets', widgets);
    changeRequest = WidgetRegistry.processRequest([changeRequest])[0];
    dispatch(updateWidget(props.id, changeRequest, props.editor));
  }

  const handleCssChange = (css) => {
    var changeRequest = { ...widget };
    _.set(changeRequest, 'properties.css', css);

    var widgets = denormalise(changeRequest.widgets, widgetsById);
    changeRequest = _.set(changeRequest, 'widgets', widgets);
    changeRequest = WidgetRegistry.processRequest([changeRequest])[0];
    dispatch(updateWidget(props.id, changeRequest, props.editor));
  }

  return (
    <QuickSettings menuItems={menuItems} selected={selected} onMenuClick={handleMenuClick} >
      {selected === 'spacing' && 
        <>
          <QuickSettingsProperty.Spacing
            title='Margin'
            value={properties.margin}
            onChange={(margin) => {
              handleChange({
                margin: margin
              });
            }}
          />
          <QuickSettingsProperty.Spacing
            title='Padding'
            value={properties.padding}
            onChange={(padding) => {
              handleChange({
                padding: padding
              });
            }}
          />
        </>
      }

      {selected === 'background' && 
        <QuickSettingsProperty.Background 
          expanded
          overlayEnabled
          updating={updatingBackground}
          value={properties.background}
          onChange={(background) => {
            handleChange({
              background: background
            });
          }}
          onImageChange={(image, type) => {
            handleBackgroundImageChange(image, type);
          }}
        />
      }

      {selected === 'border' && 
        <QuickSettingsProperty.Border expanded value={properties.border} onChange={(border) => {
          handleChange({
            border: border
          });
        }}
        />
      }

      {selected === 'layout' && 
        <>
          <QuickSettingsProperty.Switch tooltip='Banner extends full width of the page'
            title='Fluid'
            value={_.get(widget, 'properties.fluid', false)}
            onChange={() => {
              handleChange({
                fluid: !_.get(widget, 'properties.fluid', false)
              })
            }}
          />
          <QuickSettingsProperty.Dropdown title='Banner Height'
            tooltip='Indicate text position'
            placeholder='Text Alignment'
            selection
            options={[
              { key: 'auto', value: 'auto', text: 'Auto' },
              { key: 'small', value: 'small', text: 'Small' },
              { key: 'medium', value: 'medium', text: 'Medium' },
              { key: 'full_page', value: 'full_page', text: 'Full Page' }
            ]}
            value={properties.bannerType}
            onChange={(e, data) => {
              handleChange({
                bannerType: data.value
              })
            }}
          />
        </>
      } 

      {selected === 'advanced' && 
        <QuickSettingsProperty.CSS title='Stylesheets' value={properties.css} onChange={handleCssChange} />
      }
    </QuickSettings>
  )
}

HeroBanner.QuickSettings = HeroBannerQuickSettings;

function HeroBannerProperty(props){
  const dispatch = useDispatch();

  var widget = useSelector(state => {
    const editorContext = state.widgetsEditor.editors[props.editor];

    if (editorContext) {
      return editorContext.widgetsById[props.id];
    }

    return null;
  });

  var widgetsById = useSelector(state => {
    const editorContext = state.widgetsEditor.editors[props.editor];

    if (editorContext) {
      return editorContext.widgetsById;
    }

    return {};
  });

  if(!widget){
    return false;
  }

  var properties = widget.properties;

  const handleChange = (changeRequest) => {
    dispatch(updateProperties({
      id: widget.id,
      change: changeRequest,
      context: props.editor
    }));
  }

  const handleBackgroundImageChange = (media, type) => {
    console.log("Upload image " + type);

    var changeRequest = {...widget};
    _.set(changeRequest, 'properties.background.upload', {
      type: type,
      data: media
    });

    var widgets = denormalise(changeRequest.widgets, widgetsById);
    changeRequest = _.set(changeRequest, 'widgets', widgets);
    changeRequest  = WidgetRegistry.processRequest([changeRequest])[0];
    dispatch(updateWidget(props.id, changeRequest, props.editor));
  }

  const handleCssChange = (css) => {
    var changeRequest = {...widget};
    _.set(changeRequest, 'properties.css', css);
    
    var widgets = denormalise(changeRequest.widgets, widgetsById);
    changeRequest = _.set(changeRequest, 'widgets', widgets);
    changeRequest  = WidgetRegistry.processRequest([changeRequest])[0];
    dispatch(updateWidget(props.id, changeRequest, props.editor));
  }
  
  return (
    <Fragment>
      <Property.Background 
        expanded 
        overlayEnabled
        value={properties.background} 
        onChange={(background) => {
          handleChange({
            background: background
          });
        }} 
        onImageChange={(image, type) => {
          handleBackgroundImageChange(image, type);
        }} 
      />
      <Property.Section title='Layout' expanded>
        <Property.Switch tooltip='Banner extends full width of the page' 
          title='Fluid' 
          value={_.get(widget, 'properties.fluid', false)}
          onChange={() => {
            handleChange({
              fluid: !_.get(widget, 'properties.fluid', false)
            })
          }}
        />
        <Property.Dropdown title='Banner Height' 
          tooltip='Indicate text position'
          placeholder='Text Alignment'
          selection 
          options={[
            { key: 'auto', value: 'auto', text: 'Auto' },
            { key: 'small', value: 'small', text: 'Small' },
            { key: 'medium', value: 'medium', text: 'Medium' },
            { key: 'full_page', value: 'full_page', text: 'Full Page' }
          ]}
          value={properties.bannerType}
          onChange={(e, data) => {
            handleChange({
              bannerType: data.value
            })
          }}
        />
      </Property.Section>

      <Property.Section title='Spacing' expanded>
        <Property.Spacing 
          title='Margin' 
          value={properties.margin}
          onChange={(margin) => {
            handleChange({
              margin: margin
            });
          }}
        />
        <Property.Spacing 
          title='Padding' 
          value={properties.padding}
          onChange={(padding) => {
            handleChange({
              padding: padding
            });
          }}
        />
      </Property.Section>

      <Property.Border expanded value={properties.border} onChange={(border) => {
          handleChange({
            border: border
          });
        }} 
      />

      <Property.Section title='Advanced' expanded>
        <Property.CSS title='Stylesheets' value={properties.css} onChange={handleCssChange} />
      </Property.Section>
    </Fragment>
  );
}

HeroBanner.QuickAction = function QuickAction(props) {
  const dispatch = useDispatch();
  const uploadIconRef = React.createRef();

  var widget = useSelector(state => {
    const editorContext = state.widgetsEditor.editors[props.editor];

    if (editorContext) {
      return editorContext.widgetsById[props.id];
    }

    return null;
  });

  var widgetsById = useSelector(state => {
    const editorContext = state.widgetsEditor.editors[props.editor];

    if (editorContext) {
      return editorContext.widgetsById;
    }

    return {};
  });

  var properties = widget.properties;
  var updating = widget.updating;
  var updatingBackground = updating && _.get(properties, 'background.upload') != null;


  const handleFileChange= (e) => {
    const file = e.target.files[0];

    if(typeof file === 'string' || file.type.match('image.*')){ 
      ImageTools.resize(file, {
        width: 1600, // maximum width
        height: 1024 // maximum height
      }, (blob, didItResize) => {
        loadImageFile(blob).then(url => {
          onImageChange(url, 'image');
        })
      });
    } else if (file.type.match('video.*')) {
      loadImageFile(file).then(url => {
        onImageChange(url, 'video');
      })
    }
  }

  const onImageChange = (media, type) => {
    console.log("Upload image " + type);

    var changeRequest = {...widget};
    _.set(changeRequest, 'properties.background.upload', {
      type: type,
      data: media
    });

    var widgets = denormalise(changeRequest.widgets, widgetsById);
    changeRequest = _.set(changeRequest, 'widgets', widgets);
    changeRequest  = WidgetRegistry.processRequest([changeRequest])[0];
    dispatch(updateWidget(props.id, changeRequest, props.editor));
  }

  const loadImageFile = (imageFile) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.onload = e => resolve(e.target.result)
      reader.onerror = reject
      reader.readAsDataURL(imageFile)
    });
  }

  return (
    <Widget.Action className={styles.quickAction} 
      disabled={updatingBackground}
      onClick={() => {
        uploadIconRef.current.click();
      }}
    >
      {updatingBackground ? 'Uploading...' : 'Change Background'}
      <input
        ref={uploadIconRef}
        type="file"
        accept="image/* video/*"
        onChange={handleFileChange}
      />
    </Widget.Action>
  );
}

function FowardableRow({ forwardedRef, ...props }) {
  return <div ref={forwardedRef} {...props} />
};

HeroBanner.Property = HeroBannerProperty;