import {
  AUTHENTICATING_USER_REQUEST, AUTHENTICATING_USER_SUCCEDDED, AUTHENTICATING_USER_FAILED,
  FORGOT_PASSWORD_REQUEST, FORGOT_PASSWORD_SUCCEEDED, FORGOT_PASSWORD_FAILED,
  FETCHING_SSO_URL_REQUEST, FETCHING_SSO_URL_SUCCEEDED, FETCHING_SSO_URL_FAILED, SSO_AUTHENTICATION_FAILED,
  LOGOUT_USER_REQUEST, LOGOUT_USER,
  FETCHING_USER_PROFILE, FETCHING_USER_PROFILE_SUCCEEDED, FETCHING_USER_PROFILE_FAILED,
  UPDATING_USER_PROFILE, UPDATING_USER_PROFILE_SUCCEEDED, UPDATING_USER_PROFILE_FAILED,
  FETCHING_USER_INVITATION, FETCHING_USER_INVITATION_SUCCEDDED, FETCHING_USER_INVITATION_FAILED,
  USER_REGISTRATION_REQUEST, USER_REGISTRATION_SUCCEDDED, USER_REGISTRATION_FAILED,
  CHANGING_PASSWORD, CHANGING_PASSWORD_SUCCEEDED, CHANGING_PASSWORD_FAILED
} from 'constants/actionTypes.jsx';

import { createAction } from 'redux-actions';
import axios from 'axios';
import { call, put, takeLatest } from 'redux-saga/effects';
import buildUrl from 'build-url';

import AuthService from 'services/auth.service';
import AsyncLocalStorageService from 'services/localstorage.async.service';

const apiUrl = process.env.REACT_APP_API_URL;
const AUTHORIZE_URL = `${apiUrl}/oauth/token`;
const FORGOT_PASSWORD_URL = `${apiUrl}/api/2.0/users/forgot-password`;
const PROFILE_URL = `${apiUrl}/api/2.0/users/me`;
const SSO_URL = `${apiUrl}/api/2.0/users/sso/initiate`;
const LOGOUT_URL = `${apiUrl}/api/2.0/users/logout`;
const INVITATION_URL = `${apiUrl}/api/2.0/invitations`;
const CHANGE_PASSWORD_URL = `${apiUrl}/api/2.0/users/change-password`;

export const authenticateUser = createAction(AUTHENTICATING_USER_REQUEST, (username, password) => {
  return { username, password }
});

export const authenticatingUserSucceeded = createAction(AUTHENTICATING_USER_SUCCEDDED);
const authenticateUserFailed = createAction(AUTHENTICATING_USER_FAILED, (error) => {
  return error;
})

function* authenticateUserSaga(action) {
  const data = {
    grant_type: 'password',
    scope: 'write',
    username: action.payload.username,
    password: action.payload.password
  }

  const authorization = btoa("paperflite:paperflite-secret");

  const authenticateUserAPI = () => {
    return axios.post(AUTHORIZE_URL, data, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded', 'Authorization': `Basic ${authorization}`
      },
      params: data
    }).then(response => response.data);
  }

  try {
    const response = yield call(authenticateUserAPI);
    AuthService.saveToken(response.access_token, response.refresh_token);
    yield put(authenticatingUserSucceeded(response));
  } catch (error) {
    yield put(authenticateUserFailed(error));
  }
}

/** 
* Fetch SSO URL for email
*
*/
export const fetchSSOUrlFromEmail = createAction(FETCHING_SSO_URL_REQUEST, (email) => {
  return { email };
});

export const ssoAuthenticationFailed = createAction(SSO_AUTHENTICATION_FAILED, (reason) => {
  return { reason }
});

const fetchingSSOUrlSuccedded = createAction(FETCHING_SSO_URL_SUCCEEDED);
const fetchingSSOUrlFailed = createAction(FETCHING_SSO_URL_FAILED);

function* fetchSSOUrlFromEmailSaga(action) {
  let url = buildUrl(SSO_URL, {
    queryParams: {
      email: action.payload.email
    }
  });

  const fetchSSOUrlFromEmailAPI = () => {
    return axios.get(url).then(response => response.data);
  }

  try {
    const response = yield call(fetchSSOUrlFromEmailAPI);
    yield put(fetchingSSOUrlSuccedded(response));
  } catch (error) {
    yield put(fetchingSSOUrlFailed(error));
  }
}

/*
* Forgot password Request
*/
export function forgotPassword(email, callback) {
  return {
    type: FORGOT_PASSWORD_REQUEST,
    payload: {
      email: email,
      callback: callback
    }
  }
}

function* forgotPasswordRequest(action) {
  const { email, callback } = action.payload;

  var data = {
    email: email
  }

  const triggeringForgotPassword = () => {
    return axios.get(
      FORGOT_PASSWORD_URL,
      { params: data }
    ).then((response) => {
      return response.data;
    })
      .catch(err => {
        throw err;
      });
  }

  try {
    const response = yield call(triggeringForgotPassword);
    if (response) {
      yield put(triggeringForgotPasswordSucceeded(response));
      callback(response);
    }
  } catch (error) {
    yield put(triggeringForgotPasswordFailed(error))
  }
}


function triggeringForgotPasswordSucceeded(json) {
  return {
    type: FORGOT_PASSWORD_SUCCEEDED,
    payload: json
  }
}

function triggeringForgotPasswordFailed(error) {
  return {
    type: FORGOT_PASSWORD_FAILED,
    payload: error
  }
}

export const logoutUser = createAction(LOGOUT_USER_REQUEST);

function* logoutUserSaga() {
  AsyncLocalStorageService.removeItem('appId');

  const logoutUserAPI = () => {
    return axios.delete(LOGOUT_URL).then(response => response.data);
  }

  try {
    const response = yield call(logoutUserAPI);
  } catch (error) {
    console.log(error);
  } finally {
    yield put({ type: LOGOUT_USER, payload: {} });
    AuthService.clearAccessToken();
  }
}

/**
* User Profile
*/

export function fetchUserProfile() {
  return {
    type: FETCHING_USER_PROFILE,
    payload: {}
  }
}

function* fetchUserProfileRequest() {

  const fetchingUserProfile = () => {
    return axios.get(
      PROFILE_URL
    ).then(response => response.data)
      .catch(err => {
        throw err;
      });
  }

  try {
    const response = yield call(fetchingUserProfile);
    if (response) {
      yield put(fetchingUserProfileSuccedded(response));
    }
  } catch (error) {
    yield put(fetchingUserProfileFailed(error));
  }
}

function fetchingUserProfileSuccedded(json) {
  return {
    type: FETCHING_USER_PROFILE_SUCCEEDED,
    payload: json
  }
}

function fetchingUserProfileFailed(error) {
  console.log(error);
  return {
    type: FETCHING_USER_PROFILE_FAILED,
    payload: error
  }
}

export const updateProfile = createAction(UPDATING_USER_PROFILE, (request) => {
  return request;
})

const updateProfileSucceeded = createAction(UPDATING_USER_PROFILE_SUCCEEDED, (response) => {
  return response
})

const updateProfileFailed = createAction(UPDATING_USER_PROFILE_FAILED, (error) => {
  return error
})

function* updateProfileSaga(action) {
  var request = action.payload;

  const updateProfileAPI = () => {
    return axios.put(PROFILE_URL, request).then(response => response.data);
  }

  try {
    const response = yield call(updateProfileAPI);
    yield put(updateProfileSucceeded(response));
  } catch (error) {
    yield put(updateProfileFailed(error));
  }
}

/**
* Refresh token
*/
export function refreshToken(token) {
  const data = {
    grant_type: 'refresh_token',
    refresh_token: token
  }

  const authorization = btoa("paperflite:paperflite-secret");

  return axios({
    method: 'POST',
    url: AUTHORIZE_URL,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded', 'Authorization': `Basic ${authorization}` },
    params: data
  });
}

/**
* Fetch Invitation
*/

export const fetchAccountInvitation = createAction(FETCHING_USER_INVITATION, (id, invitationKey) => {
  return {
    id: id,
    invitationKey: invitationKey
  };
})

const fetchAccountInvitationSucceeded = createAction(FETCHING_USER_INVITATION_SUCCEDDED, (response) => {
  return response;
})

const fetchAccountInvitationFailed = createAction(FETCHING_USER_INVITATION_FAILED, (error) => {
  return error;
})

function* fetchAccountInvitationSaga(action) {
  var { id, invitationKey } = action.payload;
  console.log(action.payload);

  const params = {
    invitation_token: invitationKey,
  }

  let url = buildUrl(INVITATION_URL, {
    queryParams: params,
    path: `/${id}/accept`
  });

  const fetchAccountInvitationAPI = () => {
    return axios.get(url).then(response => response.data);
  }

  try {
    const response = yield call(fetchAccountInvitationAPI);
    yield put(fetchAccountInvitationSucceeded(response));
  } catch (error) {
    yield put(fetchAccountInvitationFailed(error));
  }
}

/**
* Fetch Invitation
*/

export const registerUser = createAction(USER_REGISTRATION_REQUEST, (id, user) => {
  return {
    id: id,
    user: user
  };
})

const registerUserSucceeded = createAction(USER_REGISTRATION_SUCCEDDED, (response) => {
  return response;
})

const registerUserFailed = createAction(USER_REGISTRATION_FAILED, (error) => {
  return error;
})

function* registerUserSaga(action) {
  var { id, user } = action.payload;


  let url = buildUrl(INVITATION_URL, {
    path: `/${id}/register`
  });

  const registerUserAPI = () => {
    return axios.post(url, user).then(response => response.data);
  }

  try {
    const response = yield call(registerUserAPI);
    yield put(registerUserSucceeded(response));
  } catch (error) {
    yield put(registerUserFailed(error));
  }
}

/**
* Change password
*/

export const changePassword = createAction(CHANGING_PASSWORD, (oldPassword, newPassword1, newPassword2, callback) => {
  return {
    oldPassword: oldPassword,
    newPassword1: newPassword1,
    newPassword2: newPassword2,
    callback: callback
  }
})

const changePasswordSucceeded = createAction(CHANGING_PASSWORD_SUCCEEDED, (response) => {
  return response;
})

const changePasswordFailed = createAction(CHANGING_PASSWORD_FAILED, (error) => {
  return error;
})

function* changePasswordSaga(action) {
  var { oldPassword, newPassword1, newPassword2, callback } = action.payload;

  const data = {
    oldPassword: oldPassword,
    newPassword1: newPassword1,
    newPassword2: newPassword2
  }

  const changePasswordAPI = () => {
    return axios.put(CHANGE_PASSWORD_URL, data).then(response => response.data);
  }

  try {
    const response = yield call(changePasswordAPI);
    yield put(changePasswordSucceeded(response));
    if (callback) {
      callback(response);
    }
  } catch (error) {
    yield put(changePasswordFailed(error));
  }
}


export default function* userSaga() {
  yield takeLatest(AUTHENTICATING_USER_REQUEST, authenticateUserSaga);
  yield takeLatest(FETCHING_SSO_URL_REQUEST, fetchSSOUrlFromEmailSaga);
  yield takeLatest(FORGOT_PASSWORD_REQUEST, forgotPasswordRequest);
  yield takeLatest(LOGOUT_USER_REQUEST, logoutUserSaga);
  yield takeLatest(FETCHING_USER_PROFILE, fetchUserProfileRequest);
  yield takeLatest(UPDATING_USER_PROFILE, updateProfileSaga)
  yield takeLatest(FETCHING_USER_INVITATION, fetchAccountInvitationSaga);
  yield takeLatest(USER_REGISTRATION_REQUEST, registerUserSaga);
  yield takeLatest(CHANGING_PASSWORD, changePasswordSaga);
};