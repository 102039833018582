import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useRouteMatch } from "react-router-dom";

import { createPage, deletePage } from 'actions/pages';

import cx from 'classnames';
import _ from 'lodash';
import dateformat from 'dateformat';

import { Card, Image, Icon, Button, Placeholder } from 'semantic-ui-react';

import styles from './template.module.scss';
import Measure from 'react-measure';
import { Line, LineChart } from 'recharts';
import Truncate from 'react-truncate';

import Tooltip from 'components/Tooltip';
import { clonePage, fetchPageAnalytics } from 'actions/pages';
import { useEffect } from 'react';

export function PageTemplate(props) {
  const dispatch = useDispatch();

  const template = props.value;
  const creating = useSelector(state => _.get(state, 'pages.create.creating'));
  const creatingCurrent = useSelector(state => _.get(state, 'pages.create.item.template')) === template.id;

  const iconUrl = _.get(template, 'icon.full') || "https://via.placeholder.com/600x338";
  const previewUrl = _.get(template, 'previewUrl');

  const _handlePreviewClicked = (e) => {
    e.stopPropagation();
    e.preventDefault();

    window.open(previewUrl, '_blank');
  }

  return (
    <div className={cx(styles.template, props.className, {
      [styles.creating]: creatingCurrent
    })}>
      <h5>{props.prefix || " "}</h5>
      <div className={styles.image}>
        <Image src={iconUrl} wrapped ui={false} fuild="true" />
        <div className={styles.add}>
          <Button onClick={() => {
              props.onClick(template);
          }}>
            {creatingCurrent ? 'Creating...' : '+'}
          </Button>
        </div>
      </div>
      <div className={styles.details}>
        <div className={styles.left}>
          <div className={styles.name}>{_.get(template, 'name')}</div>
          <div className={styles.category}>{_.get(template, 'category')}</div>
        </div>
        <div className={styles.right}>
          {previewUrl &&
            <Tooltip content='Preview Page' inverted size='mini' position='top center'>
              <Button onClick={_handlePreviewClicked} className={styles.preview} icon circular>
                <Icon name="eye" />
              </Button>
            </Tooltip>
          }
        </div>
      </div>
    </div>
  );
}

export function BlankTemplate(props) {
  const dispatch = useDispatch();

  const creating = useSelector(state => _.get(state, 'pages.create.creating'));
  const creatingCurrent = useSelector(state => _.get(state, 'pages.create.item.template')) == null && creating;

  return (
    <>
      <div className={cx(styles.template, styles.blank, props.className, {
        [styles.creating]: creatingCurrent
      })}>
        <h5>Create new page</h5>
        <div className={styles.image}>
          <div className="image" />
          <div className={styles.add}>
            <Button onClick={() => {
              props.onClick();
            }}>
              {creatingCurrent ? 'Creating...' : '+'}
            </Button>
          </div>
        </div>
        <div className={styles.details}>
          <div className={styles.left}>
            <div className={styles.name}>Blank</div>
            <div className={styles.category}>Start from scratch</div>
          </div>
        </div>
      </div>
    </>
  );
}

export function PageCard(props) {
  const dispatch = useDispatch();
  const history = useHistory();

  let { url } = useRouteMatch();
  const page = props.value || {};

  const baseUrl = useSelector(state => _.get(state, 'storyboards.storyboard.item.baseUrl'));
  const cloning = useSelector(state => _.get(state, 'pages.clone.cloning'));
  const cloningCurrent = useSelector(state => _.get(state, 'pages.clone.page')) === page.id && cloning;
  const loadingAnalytics = useSelector(state => _.get(state, `pages.analytics.${page.id}.loading`, false));
  const analytics = useSelector(state => _.get(state, `pages.analytics.${page.id}.items`)) || [];

  var icon = _.get(page, 'icon.thumbnail', null);
  if (!icon) {
    icon = '/images/pages/default_page.jpg';
  }

  useEffect(() => {
    dispatch(fetchPageAnalytics(page.id));
  }, [page.id])

  const _handleEditPage = () => {
    history.push(`${url}/${page.id}/edit`);
  }

  const _handleClonePage = () => {
    if (cloning) {
      return;
    }

    dispatch(clonePage(page.id, {
      name: `Clone of ${page.name}`
    }));
  }

  const _handlePreviewPage = () => {
    const viewUrl = `${baseUrl}${page.alias}`;
    window.open(viewUrl);
  }

  const author = () => {
    const checkDefault = (value, defaultValue) => value == null ? defaultValue : value;

    var firstName = checkDefault(_.get(page, 'createdBy.firstName'), '');
    var lastName = checkDefault(_.get(page, 'createdBy.lastName'), '');

    return `${firstName} ${lastName}`;
  }

  return (
    <div className={cx(styles.page, props.className, {
      [styles.cloning]: cloningCurrent
    })}>
      <div className={styles.image}>
        <div className={styles.analytics}>
          <div className={styles.date}>
            <strong>{page.published ? 'Published:' : 'Created:'}</strong>
            <span>{dateformat(page.published ? page.lastPublished : page.createdDate, 'dd mmm yyyy')}</span>
          </div>
          <div className={styles.date}>
            <strong>Last Update:</strong>
            <span>{dateformat(page.modifiedDate, 'dd mmm yyyy')}</span>
          </div>

          <div className={styles.graphs}>
            {!loadingAnalytics &&
              <PageViewChart value={analytics} />
            }
          </div>
        </div>

        <Button.Group className={styles.actions}>
          <Tooltip content='Edit Page' inverted size='mini' position='top center'>
            <Button icon onClick={_handleEditPage}>
              <Icon name='pencil' size='large' />
            </Button>
          </Tooltip>
          <Tooltip content='Clone Page' inverted size='mini' position='top center'>
            <Button icon onClick={_handleClonePage}>
              <Icon name='clone outline' size='large' />
            </Button>
          </Tooltip>
          {page.published &&
            <Tooltip content='View Page' inverted size='mini' position='top center'>
              <Button icon onClick={_handlePreviewPage}>
                <Icon name='eye' size='large' />
              </Button>
            </Tooltip>}
        </Button.Group>
        <Image src={icon} wrapped ui={false} fuild="true" />
        {cloningCurrent &&
          <div className={styles.progress}>Cloning...</div>
        }
      </div>
      <div className={styles.details}>
        <div className={styles.left}>
          <div className={styles.name}>{page.name}</div>
          <div className={styles.author}>By {author()}</div>
          <div className={styles.url}><Truncate lines={1}>{page.alias}</Truncate></div>
        </div>
      </div>
    </div>
  );
}

export function PageViewChart(props) {
  const [width, setWidth] = useState(100);
  const { total, sessions } = props.value;

  return (
    <div className={styles.pageView}>
      <div className={styles.value}>{total}</div>
      <div className={styles.metrics}>Total Sessions</div>
      <Measure bounds
        onResize={(contentRect) => {
          setWidth(contentRect.bounds.width);
        }}>

        {({ measureRef }) =>
          <div ref={measureRef} >
            <LineChart
              width={width}
              height={20}
              data={sessions}
              margin={{
                top: 2, right: 2, left: 2, bottom: 2,
              }}
            >
              <Line dataKey="sessions" stroke="#4EB5CA" strokeWidth={2} dot={false} />
            </LineChart>
          </div>
        }
      </Measure>
    </div>
  );
}

export function PageTemplatePlaceholder(props) {
  return (
    <div className={cx(styles.template, styles.placeholder, props.className)}>
      <h5>{props.prefix || "    "}</h5>
      <div className={styles.image}>
        <Placeholder fluid>
          <Placeholder.Image />
        </Placeholder>
      </div>
      <div className={styles.details}>
        <div className={styles.left}>
          <Placeholder>
            <Placeholder.Paragraph>
              <Placeholder.Line length='short' />
              <Placeholder.Line length='very short' />
            </Placeholder.Paragraph>
          </Placeholder>
        </div>
      </div>
    </div>
  );
}

export function PagePlaceholderCard(props) {

  return (
    <div className={cx(styles.page, styles.placeholder, props.className)}>
      <div className={styles.image}>
        <Placeholder fluid>
          <Placeholder.Image />
        </Placeholder>
      </div>
      <div className={styles.details}>
        <div className={styles.left}>
          <Placeholder>
            <Placeholder.Paragraph>
              <Placeholder.Line length='medium' />
              <Placeholder.Line length='short' />
              <Placeholder.Line length='very long' />
            </Placeholder.Paragraph>
          </Placeholder>
        </div>
      </div>
    </div>
  );
}