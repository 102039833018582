import React from "react";

import cx from "classnames";

import {
  Button,
  Form,
  Image,
} from 'semantic-ui-react';

import styles from './searchInput.module.scss';

export const SearchInputBar = (props) => {

  return (
    <div className={cx(styles.container)}>
      <Form className={styles.form}>
        <Form.Field className={styles.seekInputFormField}>
          <input
            className={styles.seekInput}
            disabled />
          {!props.placeholder &&
            <div className={styles.placeholder}>
              <div className={styles.wrapper}>
                <div className={styles.text}>How to...</div>
                <div className={styles.text}>Show me...</div>
                <div className={styles.text}>How does...</div>
                <div className={styles.text}>What is...</div>
                <div className={styles.text}>How to...</div>
              </div>
            </div>
          }
        </Form.Field>
        <Button className={styles.submitButton}>
          <Image className={styles.submitIcon} src='/images/seek/logo.svg' />
        </Button>
      </Form>
    </div>
  );
}
