import {
  FETCHING_LEAD_SESSIONS, FETCHING_LEAD_SESSIONS_SUCCEEDED, FETCHING_LEAD_SESSIONS_FAILED,
  FETCHING_LEAD, FETCHING_LEAD_SUCCEEDED, FETCHING_LEAD_FAILED,
  FETCHING_SESSIONS_FOR_LEAD, FETCHING_SESSIONS_FOR_LEAD_SUCCEEDED, FETCHING_SESSIONS_FOR_LEAD_FAILED,
  SELECT_LEAD_SESSION,
  FETCHING_ASSETS_FOR_SESSION, FETCHING_ASSETS_FOR_SESSION_SUCCEEDED, FETCHING_ASSETS_FOR_SESSION_FAILED,
  FETCHING_SESSION_ASSET_ANALYITCS, FETCHING_SESSION_ASSET_ANALYITCS_SUCCEEDED, FETCHING_SESSION_ASSET_ANALYITCS_FAILED,
  FETCHING_ASSET_PREVIEW, FETCHING_ASSET_PREVIEW_SUCCEEDED, FETCHING_ASSET_PREVIEW_FAILED,
  FETCHING_LEAD_ANALYTICS, FETCHING_LEAD_ANALYTICS_SUCCEEDED, FETCHING_LEAD_ANALYTICS_FAILED,
  DOWNLOAD_LEAD_REPORTS, DOWNLOADING_LEAD_REPORTS_SUCCEEDED, DOWNLOADING_LEAD_REPORTS_FAILED,

} from 'constants/actionTypes';

const initialState = {
  sessions: {
    loading: false,
    invalidated: true,
    items: [],
    error: null,
    pagination: {
      current: -1,
      totalPages: 1,
      totalRecords: 0
    }
  },
  lead: {
    loading: false,
    item: null,
    sessions: {
      loading: false,
      invalidated: true,
      items: [],
      error: null,
      pagination: {
        current: -1,
        totalPages: 1
      }
    }
  },
  session: {
    item: null,
    assetSessions: {
      loading: false,
      items: [],
      error: null
    }
  },
  cumulativeAnalytics: {
    loading: false,
    item: {}
  }
}

export function leadReportReducer(state = initialState, action) {
  switch (action.type) {
    case FETCHING_LEAD_SESSIONS:
      return {
        ...state,
        sessions: {
          ...state.sessions,
          loading: true
        }
      }

    case FETCHING_LEAD_SESSIONS_SUCCEEDED:
      var records = action.payload.records;

      return {
        ...state,
        sessions: {
          ...state.sessions,
          loading: false,
          invalidated: false,
          items: records,
          pagination: {
            ...state.sessions.pagination,
            current: action.payload.currentPage,
            totalPages: action.payload.totalPages,
            totalRecords: action.payload.totalRecordsCount
          }
        }
      }

    case FETCHING_LEAD_SESSIONS_FAILED:
      return {
        ...state,
        sessions: {
          ...state.sessions,
          loading: false,
          error: action.payload
        }
      }

    case FETCHING_LEAD:
      return {
        ...state,
        lead: {
          ...state.lead,
          loading: true,
          sessions: {
            loading: false,
            invalidated: true,
            items: [],
            error: null,
            pagination: {
              current: -1,
              totalPages: 1
            }
          }
        }
      }

    case FETCHING_LEAD_SUCCEEDED:
      var lead = action.payload;
      return {
        ...state,
        lead: {
          ...state.lead,
          loading: false,
          item: lead
        }
      }

    case FETCHING_LEAD_FAILED:
      return {
        ...state,
        lead: {
          ...state.lead,
          loading: false,
          item: null,
          error: action.payload
        }
      }

    case FETCHING_SESSIONS_FOR_LEAD:
      return {
        ...state,
        lead: {
          ...state.lead,
          sessions: {
            ...state.lead.sessions,
            loading: true
          }
        }
      }

    case FETCHING_SESSIONS_FOR_LEAD_SUCCEEDED:
      records = action.payload.records;

      var items = [];

      if (action.payload.currentPage !== 0) {
        items = [...state.lead.sessions.items, ...records];
      } else {
        items = records;
      }

      items = items.reduce((x, y) => {
        return x.findIndex((e) => {
          // eslint-disable-next-line no-unused-expressions
          e.id === y.id
        }) < 0 ? [...x, y] : x
      }, []);

      return {
        ...state,
        lead: {
          ...state.lead,
          sessions: {
            ...state.lead.sessions,
            loading: false,
            invalidated: false,
            items: items,
            pagination: {
              ...state.lead.sessions.pagination,
              current: action.payload.currentPage,
              totalPages: action.payload.totalPages
            }
          }
        }
      }

    case FETCHING_SESSIONS_FOR_LEAD_FAILED:
      return {
        ...state,
        lead: {
          ...state.lead,
          sessions: {
            ...state.lead.sessions,
            loading: false,
            item: null,
            error: action.payload.error
          }
        }
      }

    case SELECT_LEAD_SESSION:
      return {
        ...state,
        session: {
          ...state.session,
          item: action.payload
        }
      }

    case FETCHING_ASSETS_FOR_SESSION:
      var id = action.payload.id;

      if (!state.session.item || id !== state.session.item.id) {
        return state;
      }

      return {
        ...state,
        session: {
          ...state.session,
          assetSessions: {
            ...state.session.assetSessions,
            loading: true
          }
        }
      }

    case FETCHING_ASSETS_FOR_SESSION_SUCCEEDED:
      var id = action.payload.id;
      var assetSessions = action.payload.response || [];

      if (!state.session.item || id !== state.session.item.id) {
        return state;
      }

      assetSessions = assetSessions.map(item => {
        return {
          ...item,
          analytics: {
            fetch: true,
            loading: false,
            item: null
          },
          preview: {
            loading: false,
            item: null,
            fetch: true
          }
        }
      })

      return {
        ...state,
        session: {
          ...state.session,
          assetSessions: {
            ...state.session.assetSessions,
            loading: false,
            items: assetSessions
          }
        }
      }

    case FETCHING_ASSETS_FOR_SESSION_FAILED:
      var id = action.payload.id;

      if (!state.session.item || id !== state.session.item.id) {
        return state;
      }

      return {
        ...state,
        session: {
          ...state.session,
          assetSessions: {
            ...state.session.assetSessions,
            loading: false,
            items: [],
            error: action.payload.error
          }
        }
      }

    case FETCHING_SESSION_ASSET_ANALYITCS:
      var assetSessionId = action.payload.assetSessionId;

      assetSessions = state.session.assetSessions.items.map(item => {
        if (item.id === assetSessionId) {
          return {
            ...item,
            analytics: {
              ...item.analyticss,
              fetch: false,
              loading: true
            }
          }
        }

        return item;
      })

      return {
        ...state,
        session: {
          ...state.session,
          assetSessions: {
            ...state.session.assetSessions,
            loading: false,
            items: assetSessions
          }
        }
      }

    case FETCHING_SESSION_ASSET_ANALYITCS_SUCCEEDED:
      assetSessionId = action.payload.assetSessionId;

      assetSessions = state.session.assetSessions.items.map(item => {
        if (item.id === assetSessionId) {
          return {
            ...item,
            analytics: {
              fetch: false,
              loading: false,
              item: action.payload.json
            }
          }
        }

        return item;
      })

      return {
        ...state,
        session: {
          ...state.session,
          assetSessions: {
            ...state.session.assetSessions,
            loading: false,
            items: assetSessions
          }
        }
      }

    case FETCHING_SESSION_ASSET_ANALYITCS_FAILED:
      assetSessionId = action.payload.assetSessionId;

      assetSessions = state.session.assetSessions.items.map(item => {
        if (item.id === assetSessionId) {
          return {
            ...item,
            analytics: {
              fetch: true,
              loading: false
            }
          }
        }

        return item;
      })

      return {
        ...state,
        session: {
          ...state.session,
          assetSessions: {
            ...state.session.assetSessions,
            loading: false,
            items: assetSessions
          }
        }
      }

    case FETCHING_ASSET_PREVIEW:
      var assetId = action.payload.id;

      assetSessions = state.session.assetSessions.items.map(item => {
        if (item.asset.id === assetId) {
          return {
            ...item,
            preview: {
              ...item.preview,
              loading: true,
              fetch: false
            }
          }
        }

        return item;
      })

      return {
        ...state,
        session: {
          ...state.session,
          assetSessions: {
            ...state.session.assetSessions,
            loading: false,
            items: assetSessions
          }
        }
      }

    case FETCHING_ASSET_PREVIEW_SUCCEEDED:
      assetId = action.payload.id;

      assetSessions = state.session.assetSessions.items.map(item => {
        if (item.asset.id === assetId) {
          return {
            ...item,
            preview: {
              ...item.preview,
              loading: false,
              item: action.payload.result
            }
          }
        }

        return item;
      })

      return {
        ...state,
        session: {
          ...state.session,
          assetSessions: {
            ...state.session.assetSessions,
            loading: false,
            items: assetSessions
          }
        }
      }

    case FETCHING_ASSET_PREVIEW_FAILED:
      assetId = action.payload.id;

      assetSessions = state.session.assetSessions.items.map(item => {
        if (item.asset.id === assetId) {
          return {
            ...item,
            preview: {
              ...item.preview,
              loading: false,
              item: null,
              error: action.payload.error
            }
          }
        }

        return item;
      })

      return {
        ...state,
        session: {
          ...state.session,
          assetSessions: {
            ...state.session.assetSessions,
            loading: false,
            items: assetSessions
          }
        }
      }

    case FETCHING_LEAD_ANALYTICS:
      return {
        ...state,
        cumulativeAnalytics: {
          ...state.cumulativeAnalytics,
          loading: true
        }
      }

    case FETCHING_LEAD_ANALYTICS_SUCCEEDED:
      return {
        ...state,
        cumulativeAnalytics: {
          ...state.cumulativeAnalytics,
          loading: false,
          item: action.payload
        }
      }

    case FETCHING_LEAD_ANALYTICS_FAILED:
      return {
        ...state,
        cumulativeAnalytics: {
          ...state.cumulativeAnalytics,
          loading: false,
          item: {},
          error: action.payload.error
        }
      }

    case DOWNLOAD_LEAD_REPORTS:
      return {
        ...state,
        sessions: {
          ...state.sessions,
          downloading: true,
          downloaded: false
        }
      }

    case DOWNLOADING_LEAD_REPORTS_SUCCEEDED:
      return {
        ...state,
        sessions: {
          ...state.sessions,
          downloading: false,
          downloaded: true
        }
      }

    case DOWNLOADING_LEAD_REPORTS_FAILED:
      return {
        ...state,
        sessions: {
          ...state.sessions,
          downloading: false,
          downloaded: false,
          error: action.payload
        }
      }

    default:
      return state;
  }
}