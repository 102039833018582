import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { updateProperties, updateWidget } from 'actions/widgetEditor';

import _ from 'lodash';
import cx from 'classnames';

import { WidgetRegistry } from '../registry.jsx';
import { QuickSettings, QuickSettingsProperty } from 'components/QuickSettings';
import { Widget, getStyles } from '../widget.jsx';

import { useWidgetDrop } from "services/dnd.service";

import { InteractiveContext } from './index';

import styles from './interactive.module.scss';

export const ItemEntity = React.memo((props) => {
  const dispatch = useDispatch();
  const itemReferenence = React.createRef(null);
  const interactiveProperty = React.useContext(InteractiveContext);

  const editorContext = useSelector(state => {
    return state.widgetsEditor.editors[props.editor];
  });

  const editable = props.editable;
  const active = props.id === interactiveProperty.active;
  const [scrolled, setScrolled] = useState(false);

  var _isScrollPending = false;
  var _isScrolledAlready = false;
  var _touchSensitivity = 5;
  var _touchStart = 0;

  useEffect(() => {
    const element = itemReferenence.current;
    if(active && !editable){
      attachEvents(element);
    }
    
    return () => {
      deattachEvents(element);
    }
  }, [active, editable, scrolled]);

  useEffect(() => {
    interactiveProperty.onScroll(scrolled);
  }, [scrolled]);

  //Attach Events to resize, touch & wheel
  const attachEvents = (element) => {
    console.log("attachEvents");
    if(element){
      element.addEventListener('touchmove', onTouchMove, { passive: false });
      element.addEventListener('touchstart', onTouchStart, { passive: true });
      element.addEventListener('wheel', onScrollEvent, { passive: false });
    }
    window.addEventListener('keydown', onKeyDown);
  }

  //Deattach Events from resize, touch & wheel
  const deattachEvents = (element) => {
    console.log("deattachEvents");
    
    if(element){
      element.removeEventListener('touchmove', onTouchMove);
      element.removeEventListener('touchstart', onTouchStart);
      element.removeEventListener('wheel', onScrollEvent);
      window.removeEventListener('keydown', onKeyDown);
    }
  }

  //Events
  const onTouchStart = (e) => {
    _touchStart = e.touches[0].clientY;
    _isScrolledAlready = false;
  }

  const onTouchMove = (e) => {
    e.preventDefault();

    const touchEnd = e.changedTouches[0].clientY;

    if (!_isScrollPending && !_isScrolledAlready) {
      if (_touchStart > touchEnd + _touchSensitivity) {
        setScrolled(true);
      } else if (_touchStart < touchEnd - _touchSensitivity) {
        setScrolled(false);
      }
    }
  }

  const onScrollEvent = (e) => {
    if (!scrolled) {
      //e.preventDefault();
    }

    if (_isScrollPending) {
      return;
    }

    const scrollDown = (e.wheelDelta || -e.deltaY || -e.detail) < 0;

    if (scrollDown) {
      _scrollDown(e)
    } else {
      _scrollUp(e);
    }
  }

  const onKeyDown = (e) => {
    e = e || window.event;

    if (e.keyCode == '38') {
      //Scroll to cover when up arrow is pressed
      setScrolled(false);
    } else if (e.keyCode == '40') {
      //Scroll to content when down arrow is pressed      
      setScrolled(true);
    }
  }

  //Scroll Up to cover
  const _scrollUp = (e) => {
    var shouldScroll = true;
    if(e.currentTarget){
      var contentContainer = e.currentTarget.querySelector(`.${styles.content}`);
      shouldScroll = contentContainer.scrollTop === 0;
    }
      
    if(shouldScroll){
      setScrolled(false);
    }
  }

  //Scroll Down to content
  const _scrollDown = (e) => {
    setScrolled(true);
  }

  const properties = props.properties;
  var widgetStyles = getStyles(properties);

  if (properties.width) {
    widgetStyles = {
      ...widgetStyles,
      width: properties.width
    }
  }

  return (
    <div
      className={cx(styles.item, props.className, {
        [styles.empty]: (props.widgets || []).length == 0,
        [styles.editable]: editable,
        [styles.active]: active,
        [styles.scrolled]: scrolled
      })}
      id={`widget_${props.id}`}
      ref={itemReferenence}
      onMouseOver={props.onMouseOver}
      onMouseOut={props.onMouseOut}
      onClick={props.onClick}
      style={widgetStyles}
    >
      <div className={styles.inner}>
        <Widget.Children widgets={props.widgets} parent={props.id} editor={props.editor} editable={props.editable} />
      </div>
      {props.children}
    </div>
  );
});

export const CoverEntity = React.memo((props) => {
  const ref = React.useRef(null);
  const dispatch = useDispatch();

  const editorContext = useSelector(state => {
    return state.widgetsEditor.editors[props.editor];
  });

  const dropRef = useWidgetDrop(props, ref, {
    acceptTargets: (type) => {
      return true;
    },
    drop: (request) => {
      var dropTarget = props.id;
      var dropIndex = request.index;
      var anchor = request.anchor;

      var direction = "down";
      var identifedTarget = false;

      if (anchor) {
        if (!identifedTarget && (anchor.top || anchor.bottom)) {
          const parentWidget = editorContext.widgetsById[props.id];
          identifedTarget = _.get(parentWidget, 'capabilities.droppable', true);

          direction = anchor.bottom ? 'down' : 'up';
        }
      }

      if (!identifedTarget) {
        return null;
      }

      return {
        target: dropTarget,
        index: dropIndex,
        direction: direction
      }
    }
  });

  const editable = props.editable;
  const properties = props.properties;
  var widgetStyles = getStyles(properties);

  if (properties.width) {
    widgetStyles = {
      ...widgetStyles,
      width: properties.width
    }
  }

  return (
    <div className={cx(styles.cover, props.className, {
      [styles.empty]: (props.widgets || []).length == 0,
      [styles.editable]: editable
    })}
      id={`widget_${props.id}`}
      ref={props.dragRef(dropRef(ref))}
      onMouseOver={props.onMouseOver}
      onMouseOut={props.onMouseOut}
      onClick={props.onClick}
      style={widgetStyles}
    >
      <div className={styles.inner}>
        <Widget.Children widgets={props.widgets} parent={props.id} editor={props.editor} editable={props.editable} />
      </div>
      
      {props.children}
    </div>
  );
});

export const ContentEntity = React.memo((props) => {
  const ref = React.useRef(null);

  const editorContext = useSelector(state => {
    return state.widgetsEditor.editors[props.editor];
  });

  const dispatch = useDispatch();

  const dropRef = useWidgetDrop(props, ref, {
    acceptTargets: (type) => {
      return true;
    },
    drop: (request) => {
      var dropTarget = props.id;
      var dropIndex = request.index;
      var anchor = request.anchor;

      var direction = "down";
      var identifedTarget = false;

      if (anchor) {
        if (!identifedTarget && (anchor.top || anchor.bottom)) {
          const parentWidget = editorContext.widgetsById[props.id];
          identifedTarget = _.get(parentWidget, 'capabilities.droppable', true);

          direction = anchor.bottom ? 'down' : 'up';
        }
      }

      if (!identifedTarget) {
        return null;
      }

      return {
        target: dropTarget,
        index: dropIndex,
        direction: direction
      }
    }
  });

  const properties = props.properties;
  var widgetStyles = getStyles(properties);

  if (properties.width) {
    widgetStyles = {
      ...widgetStyles,
      width: properties.width
    }
  }

  return (
    <div className={cx(styles.content, props.className, {
      [styles.empty]: (props.widgets || []).length == 0
    })}
      id={`widget_${props.id}`}
      ref={props.dragRef(dropRef(ref))}
      onMouseOver={props.onMouseOver}
      onMouseOut={props.onMouseOut}
      onClick={props.onClick}
      style={widgetStyles}
    >
      <Widget.Children widgets={props.widgets} parent={props.id} editor={props.editor} editable={props.editable} />
      {props.children}
    </div>
  );
});

const ItemQuickSettings = (props) => {
  const dispatch = useDispatch();

  const [selected, setSelected] = useState('background');

  const menuItems = [
    { id: 'background', title: 'Background', icon: 'icon-background' },
    { id: 'advanced', title: 'Advanced', icon: 'icon-custom' }
  ]

  const handleMenuClick = (item) => {
    setSelected(item);
  }

  var widget = useSelector(state => {
    const editorContext = state.widgetsEditor.editors[props.editor];

    if (editorContext) {
      return editorContext.widgetsById[props.id];
    }

    return null;
  });
  if (!widget) {
    return false;
  }

  var properties = widget.properties;
  const color = _.get(properties, 'background.color') || 'transparent';

  const handleChange = (changeRequest) => {
    dispatch(updateProperties({
      id: widget.id,
      change: changeRequest,
      context: props.editor
    }));
  }

  const handleColorChange = (color) => {
    var updated = { ...(properties.background || {}) };
    _.set(updated, `color`, color);

    handleChange({
      background: updated
    });
  }

  const handleCssChange = (css) => {
    var changeRequest = { ...widget };
    _.set(changeRequest, 'properties.css', css);

    changeRequest = WidgetRegistry.processRequest([changeRequest])[0];
    dispatch(updateWidget(props.id, changeRequest, props.editor));
  }

  return (
    <QuickSettings menuItems={menuItems} selected={selected} onMenuClick={handleMenuClick} >
      
      {selected === "background" &&
        <QuickSettingsProperty.Color title='Color' value={color} onChange={handleColorChange} />
      }

      {selected === 'advanced' &&
        <QuickSettingsProperty.CSS title='Stylesheets' value={properties.css} onChange={handleCssChange} />
      }
    </QuickSettings>
  )
}

CoverEntity.QuickSettings = ItemQuickSettings;
ContentEntity.QuickSettings = ItemQuickSettings;