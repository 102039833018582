import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  Menu,
  Header,
  Accordion,
  Icon,
  Grid,
  Dimmer,
  Loader
} from 'semantic-ui-react';
import dateformat from 'dateformat';

import _ from 'lodash';
import cx from 'classnames';
import Truncate from 'react-truncate';

import styles from './sessionDetail.module.scss';

import FileService from 'services/file.service';

import PageAnalyticsCarouselView from '../../components/analytics/page/PageAnalyticsCarouselView';
import VideoAnalyticsView from '../../components/analytics/video/VideoAnalyticsView';

import { fetchVisitorSession, fetchVisitorAssetAnalytics } from 'actions/reports/reports';
import { fetchPreviewAsset } from 'actions/content';

export default function SessionDetailView({session, visitor, assetSessions, loading}) {
  if (!session) {
    return null;
  }

  let minutes = padNumber(Math.floor(session.duration / 60), 2);
  let seconds = padNumber(session.duration % 60, 2);

  return (
    <div className={styles.content}>
      <Menu secondary className={styles.menu}>
        <Menu.Item>
          <Header as='h4'>
            {`${_.get(visitor, 'firstName', '') || ''} ${_.get(visitor, 'lastName', '') || ''}`}
            <Header.Subheader>
              {dateformat(session.lastEventDate, 'dd mmm yyyy hh:mm TT')}
            </Header.Subheader>
          </Header>
        </Menu.Item>

        <Menu.Item position='right'>
          <Header as='h4'>
            <Header.Subheader>Session Duration</Header.Subheader>
            {`${minutes}:${seconds}`}
          </Header>
        </Menu.Item>
      </Menu>

      {loading &&
        <Dimmer active inverted>
          <Loader size='large'>Loading...</Loader>
        </Dimmer>
      }

      {!loading && assetSessions &&
        <div className={cx(styles.body)}>
          <SessionAnalyticsView session={session} assetSessions={assetSessions} />
        </div>
      }
    </div>
  )
}

function SessionAnalyticsView({ session, assetSessions }) {
  if (!assetSessions) {
    return null;
  }

  return (
    <>
      {assetSessions.map(item => {
        return <AssetSessionRow assetSession={item} session={session} />
      })}
    </>
  );
}


function AssetSessionRow({ assetSession, session }) {
  const dispatch = useDispatch();

  const [open, setOpen] = useState(false);

  const handleSessionClick = (item) => {
    var assetSessionId = assetSession.id;
    var sessionId = session.id;

    setOpen(!open);

    dispatch(fetchVisitorAssetAnalytics({
      sessionId, assetSessionId,
      callback: () => {
        if (FileService.hasPageAnalytics(assetSession.asset && assetSession.asset.metadata)) {
          if (assetSession.preview && assetSession.preview.item) {
            return;
          }

          dispatch(fetchPreviewAsset(assetSession.asset.id));
        }
      }
    }))
  }

  let minutes = padNumber(Math.floor(assetSession.totalTimeSpent / 60), 2);
  let seconds = padNumber(assetSession.totalTimeSpent % 60, 2)

  return (
    <Accordion vertical className={styles.accordion} key={assetSession.id}>
      <Accordion.Title
        onClick={handleSessionClick}
      >
        <Icon name={open ? 'minus' : 'plus'} size="small" />
        <Grid className={styles.assetSessionGrid} >
          <Grid.Column width='ten' textAlign='left' className={styles.assetName}>
            <Header as='h5'>
              <Truncate lines={1}>{assetSession.asset.name}</Truncate>
              <Header.Subheader>
                {`Time spent: ${minutes}:${seconds} | % Read: ${assetSession.percentageRead}`}
              </Header.Subheader>
            </Header>
          </Grid.Column>
          <Grid.Column width='six' textAlign='right'>
            <Header as='h5'>Last viewed
              <Header.Subheader>
                {dateformat(assetSession.lastEventDate, 'dd mmm yyyy')}
              </Header.Subheader>
            </Header>
          </Grid.Column>
        </Grid>
      </Accordion.Title>

      <Accordion.Content key={assetSession.id} active={open}>
        {assetSession.preview.item &&
          <PageAnalyticsCarouselView
            id={assetSession.id}
            preview={assetSession.preview.item}
            analytics={assetSession.analytics && assetSession.analytics.item} />
        }

        {assetSession.analytics.item && assetSession.analytics.item.type === 'VIDEO_ANALYTICS' &&
          <VideoAnalyticsView asset={assetSession.asset}
            analytics={assetSession.analytics.item} />
        }
      </Accordion.Content>
    </Accordion>
  )
}

function padNumber(number = 0, pad) {
  number = Math.ceil(number);

  const length = pad - String(number).length + 1;
  let padding = '';
  if (length > 0) {
    padding = Array(pad - String(number).length + 1).join('0');
  }
  return padding + number;
}
