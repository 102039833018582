import { 
  CREATE_ASSET_TEMPLATE_REQUEST, CREATING_ASSET_TEMPLATE_SUCCEEDED, CREATING_ASSET_TEMPLATE_FAILED,
  UPDATE_ASSET_TEMPLATE_REQUEST, UPDATING_ASSET_TEMPLATE_SUCCEEDED, UPDATING_ASSET_TEMPLATE_FAILED, 
  FETCH_ASSET_TEMPLATES_REQUEST, FETCHING_ASSET_TEMPLATES_SUCCEEDED, FETCHING_ASSET_TEMPLATES_FAILED,
  FETCH_ASSET_TEMPLATE_REQUEST, FETCHING_ASSET_TEMPLATE_SUCCEEDED,FETCHING_ASSET_TEMPLATE_FAILED,
  DELETE_ASSET_TEMPLATE_REQUEST, DELETING_ASSET_TEMPLATE_SUCCEEDED, DELETING_ASSET_TEMPLATE_FAILED,
  INVALIDATE_ASSET_TEMPLATES,

  FETCH_PAGE_TEMPLATES_REQUEST, FETCHING_PAGE_TEMPLATES_SUCCEEDED, FETCHING_PAGE_TEMPLATES_FAILED,
  FETCH_PAGE_TEMPLATE_REQUEST, FETCHING_PAGE_TEMPLATE_SUCCEEDED, FETCHING_PAGE_TEMPLATE_FAILED,
  UPDATE_PAGE_TEMPLATE_REQUEST, UPDATING_PAGE_TEMPLATE_SUCCEEDED, UPDATING_PAGE_TEMPLATE_FAILED,
  PUBLISH_PAGE_TEMPLATE_REQUEST, PUBLISHING_PAGE_TEMPLATE_SUCCEEDED, PUBLISHING_PAGE_TEMPLATE_FAILED,

  UPDATING_PAGE_TEMPLATE_PROPERTIES
} from 'constants/actionTypes.jsx';

import axios from 'axios';
import buildUrl from 'build-url';
import { createAction } from 'redux-actions';
import { call, put, takeEvery, takeLatest } from 'redux-saga/effects';

const apiUrl = process.env.REACT_APP_API_URL;
const TEMPLATE_URL = `${apiUrl}/api/2.0/templates/assets`;
const PAGE_TEMPLATES_URL = `${apiUrl}/api/2.0/templates/pages`;

/**
 * Create new template
*/
export const createAssetTemplate = createAction(CREATE_ASSET_TEMPLATE_REQUEST);
const creatingAssetTemplateSucceeded = createAction(CREATING_ASSET_TEMPLATE_SUCCEEDED);
const creatingAssetTemplateFailed = createAction(CREATING_ASSET_TEMPLATE_FAILED);

function* createAssetTemplateSaga(action) {
  const createAssetTemplateAPI = () => {
    return axios({
      method: 'POST',
      url: TEMPLATE_URL,
      data: action.payload
    }).then(response => response.data);
  }

  try {
    const response = yield call(createAssetTemplateAPI);
    yield put(creatingAssetTemplateSucceeded(response));
  } catch (error) {
    yield put(creatingAssetTemplateFailed(error));
  }
}

/**
 * update existing template
*/
export const updateAssetTemplate = createAction(UPDATE_ASSET_TEMPLATE_REQUEST);
const updatingAssetTemplateSucceeded = createAction(UPDATING_ASSET_TEMPLATE_SUCCEEDED);
const updatingAssetTemplateFailed = createAction(UPDATING_ASSET_TEMPLATE_FAILED);

function* updateAssetTemplateSaga(action) {
  const request = action.payload;
  const id = request.id;

  let url = buildUrl(TEMPLATE_URL, {
    path: `${id}`
  });

  const updateAssetTemplateAPI = () => {
    return axios({
      method: 'PUT',
      url: url,
      data: request
    }).then(response => response.data);
  }

  try {
    const response = yield call(updateAssetTemplateAPI);
    yield put(updatingAssetTemplateSucceeded(response));
  } catch (error) {
    yield put(updatingAssetTemplateFailed(error));
  }
}

/**
 * fetch all templates
*/
export const fetchAssetTemplates = createAction(FETCH_ASSET_TEMPLATES_REQUEST);
const fetchingAssetTemplatesSucceeded = createAction(FETCHING_ASSET_TEMPLATES_SUCCEEDED);
const fetchingAssetTemplatesFailed = createAction(FETCHING_ASSET_TEMPLATES_FAILED);

function* fetchAssetTemplatesSaga(action) {
  const fetchAssetTemplatesAPI = () => {
    return axios.get(TEMPLATE_URL).then(response => response.data);
  }

  try {
    const response = yield call(fetchAssetTemplatesAPI);
    yield put(fetchingAssetTemplatesSucceeded(response));
  } catch (error) {
    yield put(fetchingAssetTemplatesFailed(error));
  }
}

/**
 * fetch template by id
*/
export const fetchAssetTemplate = createAction(FETCH_ASSET_TEMPLATE_REQUEST);
const fetchingAssetTemplateSucceeded = createAction(FETCHING_ASSET_TEMPLATE_SUCCEEDED);
const fetchingAssetTemplateFailed = createAction(FETCHING_ASSET_TEMPLATE_FAILED);

function* fetchAssetTemplateSaga(action) {
  const id = action.payload;

  let url = buildUrl(TEMPLATE_URL, {
    path: `${id}`
  });

  const fetchAssetTemplateAPI = () => {
    return axios.get(url).then(response => response.data);
  }

  try {
    const response = yield call(fetchAssetTemplateAPI);
    yield put(fetchingAssetTemplateSucceeded(response));
  } catch (error) {
    yield put(fetchingAssetTemplateFailed(error));
  }
}

/**
 * Delete template by id
*/
export const deleteAssetTemplate = createAction(DELETE_ASSET_TEMPLATE_REQUEST);
const deletingAssetTemplateSucceeded = createAction(DELETING_ASSET_TEMPLATE_SUCCEEDED);
const deletingAssetTemplateFailed = createAction(DELETING_ASSET_TEMPLATE_FAILED);

function* deleteAssetTemplateSaga(action) {
  const id = action.payload;

  let url = buildUrl(TEMPLATE_URL, {
    path: `${id}`
  });

  const deleteAssetTemplateAPI = () => {
    return axios({
      method: 'DELETE',
      url: url
    }).then(response => response.data);
  }

  try {
    const response = yield call(deleteAssetTemplateAPI);
    yield put(deletingAssetTemplateSucceeded(response));
  } catch (error) {
    yield put(deletingAssetTemplateFailed(error));
  }
}

/**
 * Invalidate templates
*/
export const invalidateAssetTemplates = createAction(INVALIDATE_ASSET_TEMPLATES);


/**
 * Fetch page templates
*/
export const fetchPageTemplates = createAction(FETCH_PAGE_TEMPLATES_REQUEST);
export const fetchPageTemplatesSucceeded = createAction(FETCHING_PAGE_TEMPLATES_SUCCEEDED, response => {
  return response;
})
export const fetchPageTemplatesFailed = createAction(FETCHING_PAGE_TEMPLATES_FAILED, error => {
  return error;
})

function* fetchPageTemplatesSaga(action) {
  const fetchPageTemplatesAPI = () => {
    return axios.get(PAGE_TEMPLATES_URL).then(response => response.data);
  }

  try {
    const response = yield call(fetchPageTemplatesAPI);
    yield put(fetchPageTemplatesSucceeded(response));
  } catch (error) {
    yield put(fetchPageTemplatesFailed(error));
  }
}

/**
 * fetch page template by id
*/
export const fetchPageTemplate = createAction(FETCH_PAGE_TEMPLATE_REQUEST);
const fetchingPageTemplateSucceeded = createAction(FETCHING_PAGE_TEMPLATE_SUCCEEDED);
const fetchingPageTemplateFailed = createAction(FETCHING_PAGE_TEMPLATE_FAILED);

function* fetchPageTemplateSaga(action) {
  const id = action.payload;

  let url = buildUrl(PAGE_TEMPLATES_URL, {
    path: `${id}`
  });

  const fetchPageTemplateAPI = () => {
    return axios.get(url).then(response => response.data);
  }

  try {
    const response = yield call(fetchPageTemplateAPI);
    yield put(fetchingPageTemplateSucceeded(response));
  } catch (error) {
    yield put(fetchingPageTemplateFailed(error));
  }
}


/**
 * Update page template for {id}
*/
export const updatePageTemplate = createAction(UPDATE_PAGE_TEMPLATE_REQUEST, (id, request, callback) => {
  return {
    id: id,
    request: request,
    callback: callback
  }
});

const updatingPageTemplateSucceeded = createAction(UPDATING_PAGE_TEMPLATE_SUCCEEDED, response => {
  return response;
});
const updatingPageTemplateFailed = createAction(UPDATING_PAGE_TEMPLATE_FAILED, error => {
  return error;
});

function* updatePageTemplateSaga(action) {
  const { id,  request, callback } = action.payload;

  let url = buildUrl(PAGE_TEMPLATES_URL, {
    path: `${id}`
  });

  const updatePageTemplateAPI = () => {
    return axios({
      method: 'PUT',
      url: url,
      data: request
    }).then(response => response.data);
  }

  try {
    const response = yield call(updatePageTemplateAPI);
    yield put(updatingPageTemplateSucceeded(response));
    if(callback) yield call(callback, response);
  } catch (error) {
    yield put(updatingPageTemplateFailed(error));
  }
}

/**
 * Publish page template for {id}
*/
export const publishPageTemplate = createAction(PUBLISH_PAGE_TEMPLATE_REQUEST, (id, request, callback) => {
  return {
    id: id,
    request: request,
    callback: callback
  }
});

const publishingPageTemplateSucceeded = createAction(PUBLISHING_PAGE_TEMPLATE_SUCCEEDED, response => {
  return response;
});
const publishingPageTemplateFailed = createAction(PUBLISHING_PAGE_TEMPLATE_FAILED, error => {
  return error;
});

function* publishPageTemplateSaga(action) {
  const { id,  request, callback } = action.payload;

  let url = buildUrl(PAGE_TEMPLATES_URL, {
    path: `${id}/publish`
  });

  const publishPageTemplateAPI = () => {
    return axios({
      method: 'POST',
      url: url,
      data: request
    }).then(response => response.data);
  }

  try {
    const response = yield call(publishPageTemplateAPI);
    yield put(publishingPageTemplateSucceeded(response));
    if(callback) yield call(callback, response);
  } catch (error) {
    yield put(publishingPageTemplateFailed(error));
  }
}

/**
 * update Page Template Properties
 */
export const updatePageTemplateProperties = createAction(UPDATING_PAGE_TEMPLATE_PROPERTIES, (id, request) => {
  return {
    id: id,
    change: request
  }
});

export default function* templatesSaga() {
  yield takeEvery(CREATE_ASSET_TEMPLATE_REQUEST, createAssetTemplateSaga);
  yield takeLatest(UPDATE_ASSET_TEMPLATE_REQUEST, updateAssetTemplateSaga);
  yield takeLatest(FETCH_ASSET_TEMPLATES_REQUEST, fetchAssetTemplatesSaga);
  yield takeLatest(FETCH_ASSET_TEMPLATE_REQUEST, fetchAssetTemplateSaga);
  yield takeEvery(DELETE_ASSET_TEMPLATE_REQUEST, deleteAssetTemplateSaga);
  
  yield takeEvery(FETCH_PAGE_TEMPLATES_REQUEST, fetchPageTemplatesSaga);
  yield takeEvery(FETCH_PAGE_TEMPLATE_REQUEST, fetchPageTemplateSaga);
  yield takeEvery(UPDATE_PAGE_TEMPLATE_REQUEST, updatePageTemplateSaga);
  yield takeEvery(PUBLISH_PAGE_TEMPLATE_REQUEST, publishPageTemplateSaga);
};