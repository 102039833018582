import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import _ from 'lodash';
import cx from 'classnames';

import {
  Header,
  Step,
  Button,
  Icon,
  Grid,
  Segment,
  Accordion,
  Divider,
  Menu
} from 'semantic-ui-react';

import styles from './addContent.module.scss';

import SidePane from 'components/SidePane';
import CleverstoryAssets from './cleverstory';
import AssetList from './assets';
import EditAsset from './editAsset';

import { Clusters } from 'components/Clusters';
import { fetchAllClusters, fetchCluster } from 'actions/clusters';
import { Filters } from 'components/Clusters/filters';

import { createContentEditor, updateContentEditor } from 'actions/contentSelection';

import { guid } from 'services/util';

export const AddContentContext = React.createContext({
  editor: null,
  onSave: () => { },
  onClose: () => { },
  editable: false,
  sortable: false,
  deletable: false
});

export function AddContent(props) {
  const dispatch = useDispatch();

  const content = useSelector(state => {
    return state.contentSelection.editors[props.editor];
  });
  const selectedAsset = _.get(content, 'selectedAsset', null);

  const [type, setType] = useState(content ? content.type : null);

  useEffect(() => {
    dispatch(fetchAllClusters());
  }, []);

  useEffect(() => {
    if (content && content.type) {
      setType(content.type);
    }
  }, [content && content.type])

  useEffect(() => {
    if(!props.editor) {
      setType(null);
    }
  }, [props.editor]);

  const handleTypeChange = (type) => {
    var assets = content.assets;
    if (type != content.type) {
      assets = [];
    }

    var request = {
      ...content,
      type: type,
      assets: assets
    }

    dispatch(updateContentEditor(props.editor, request));

    setType(type);
  }

  const handleBackClick = () => {
    var request = {
      ...content,
      type: null,
      assets: [],
      filters: [],
      all: true,
      list: null
    }

    dispatch(updateContentEditor(props.editor, request));
    setType(null);
  }

  var closeButtonStyle = { "left": "calc(30vw - 20px)" };
  if (type) {
    closeButtonStyle = { "left": "calc(90vw - 20px)" };
  }

  if (!props.editor) {
    return null;
  }

  if (!content) {
    return null;
  }

  return (
    <SidePane open={props.expanded} width={'90vw'} onClose={props.onClose} closeOnDocumentClick={true}>
      <Button basic className={styles.closeButton} style={closeButtonStyle} onClick={props.onClose}>
        <i aria-hidden="true" className={cx('icon', 'icon-close_1')}>
          <span className="path1"></span><span className="path2"></span><span className="path3"></span>
        </i>
      </Button>
      <div className={cx(styles.contentOptions, { [styles.showContentOptions]: !type || type === 'placeholder_asset' })}>
        <AddContentOptions onChange={handleTypeChange} />
      </div>
      <AddContentContext.Provider value={{
        editor: props.editor,
        onSave: props.onSave,
        onClose: () => { },
        editable: props.editable,
        sortable: props.sortable,
        deletable: props.deletable
      }}>
        <div className={cx(styles.addContentContainer, {
           [styles.showAddContent]: type && type !== 'placeholder_asset',
           [styles.saving]: content.saving
          })}>
          
          {content.saving && 
            <div className={styles.savingMessage}>
              Saving content... {props.showPercentage && `${props.percentage}%`}
            </div>
          }

          <div className={cx(styles.addContent, { [styles.showEditAsset]: selectedAsset != null })}>
            <div className={styles.contentSelection}>
              {type === 'asset' &&
                <StaticContent editor={props.editor} onBack={handleBackClick} />
              }

              {type === 'cluster' &&
                <FilterContent editor={props.editor} content={content} onBack={handleBackClick} />
              }
            </div>
            <div className={styles.assetDetails}>
              <div className={styles.assetList}>
                <AssetList />
              </div>
              <div className={cx(styles.editAsset)}>
                <EditAsset  widget={props.widget}/>
              </div>
            </div>
          </div>
        </div>
      </AddContentContext.Provider>
    </SidePane>
  )
}

function AddContentOptions({ source = 'cluster', onChange, ...rest }) {
  const handleClick = (e, data) => {
    var id = data.id;
    onChange(id);
  }

  return (
    <div className={styles.contentType}>
      <Header as='h2'>Add Content</Header>
      <Step.Group fluid vertical className={styles.assetSource}>
        <Step id='cluster' link onClick={handleClick}>
          <i aria-hidden="true" className="icon-browse icon"></i>
          <Step.Content>
            <Step.Title>Cluster</Step.Title>
            <Step.Description>Choose cluster from filter condition</Step.Description>
          </Step.Content>
        </Step>
        <Step id='asset' link onClick={handleClick}>
          <i aria-hidden="true" className="icon-paperflite-upload icon"></i>
          <Step.Content>
            <Step.Title>Content Type</Step.Title>
            <Step.Description>Choose your assets from Cleverstory</Step.Description>
          </Step.Content>
        </Step>
      </Step.Group>
    </div>
  )
}

function StaticContent(props) {
  const [source, setSource] = useState('cleverstory');

  const handleSourceChange = (type) => {
    setSource(type);
  }

  return (
    <div className={styles.staticContent}>
      <Grid width='equal'>
        <Grid.Column width={8}>
          <AssetCreationOptions source={source} onChange={handleSourceChange} onBack={props.onBack} />
        </Grid.Column>
        <Grid.Column width={8} className={styles.creationContainer}>
          {source === 'cleverstory' &&
            <CleverstoryAssets />
          }
        </Grid.Column>
      </Grid>
    </div>
  )
}


function AssetCreationOptions({ source, onChange, onBack, ...rest }) {
  const handleClick = (e, data) => {
    var id = data.id;
    onChange(id);
  }

  return (
    <div className={styles.assetCreationOptions}>
      <Header as='h2' onClick={onBack} className={styles.header}>
        <Icon name='angle left' />
        <Header.Content>Content Type</Header.Content>
      </Header>
      <Step.Group fluid vertical>
        <Step id='cleverstory' link active={source === 'cleverstory'} onClick={handleClick}>
          <i aria-hidden="true" className="icon-paperflite-upload icon"></i>
          <Step.Content>
            <Step.Title>From Cleverstory</Step.Title>
            <Step.Description>Choose your assets from Cleverstory</Step.Description>
          </Step.Content>
        </Step>
      </Step.Group>
    </div>
  );
}

function FilterContent({ editor, content, onBack }) {
  const dispatch = useDispatch();

  const clusters = useSelector(state => state.clusters.items);
  const cluster = clusters.find(item => {
    return item.id === content.list;
  })

  const [clusterActive, setClusterActive] = useState(false);

  useEffect(() => {
    if(cluster != null){
      setClusterActive(true);
      return;
    }

    if(content.filters == null || content.filters.length == 0){
      setClusterActive(true); 
    }

  }, [])

  const handleTypeChange = (type) => {
    var request = {
      ...content,
      filters: [],
      list: null,
    }
    
    if(type === 'filter'){
      request = {
        ...request,
        all: false
      }
    }else{
      request = {
        ...request,
        all: true
      } 
    }

    dispatch(updateContentEditor(editor, request));
    setClusterActive(type === 'cluster');
  }

  const handleFilterChange = (filters) => {
    var request = {
      ...content,
      filters: filters,
      list: null
    }

    dispatch(updateContentEditor(editor, request));
    setClusterActive(false);
  }

  const handleClusterClicked = (cluster) => {
    if (cluster) {
      dispatch(fetchCluster(cluster.id, (item) => {
        const clusterFilters = _.get(item, 'filters', []);

        var request = {
          ...content,
          filters: clusterFilters,
          list: cluster.id,
          all: false
        }

        dispatch(updateContentEditor(editor, request));
      }));

    } else {
      var request = {
        ...content,
        filters: [],
        list: null,
        all: true
      }

      dispatch(updateContentEditor(editor, request));
    }
  }

  const handleSearchChange = (filter) => {
    var filters = content.filters;

    var request = {
      ...content,
      filters: [...filters, filter],
      list: null
    }

    dispatch(updateContentEditor(editor, request));
    setClusterActive(false);
  }

  return (
    <Segment basic className={styles.filterContent}>
      <Header as='h2' onClick={onBack} className={styles.header}>
        <Icon name='angle left' />
        <Header.Content>Conditions</Header.Content>
      </Header>

      <Accordion className={styles.filterAccordion}>
        <Accordion.Title active={!clusterActive} onClick={handleTypeChange.bind(this, 'filter')}>
          <Icon name='dropdown' />
          Search by condition
        </Accordion.Title>
        <Accordion.Content active={!clusterActive}>
          <Segment basic className={styles.filterAccordionContent}>
            <Clusters.Search onChange={handleSearchChange}/>
            <Filters createDisabled={true} editable={true} value={content.filters || []} onChange={handleFilterChange} />
          </Segment>
        </Accordion.Content>
      </Accordion>

      <Divider horizontal>Or</Divider>

      <Accordion className={styles.filterAccordion}>
        <Accordion.Title active={clusterActive} onClick={handleTypeChange.bind(this, 'cluster')}>
          <Icon name='dropdown' />
          Choose from the existing cluster
        </Accordion.Title>
        <Accordion.Content active={clusterActive}>
          <Segment basic className={styles.filterAccordionContent}>
            <Clusters active={cluster ? cluster.id : null}
              editable={false}
              onClusterClick={handleClusterClicked}
              hideHeading={true} />

            {cluster && cluster.id &&
              <Filters createDisabled={true} value={content.filters || []} onChange={handleFilterChange} />
            }
          </Segment>
        </Accordion.Content>
      </Accordion>
    </Segment>
  )
}

export function SubmitForm({ showArrows, ...rest }) {
  const dispatch = useDispatch();

  const { editor, onSave } = React.useContext(AddContentContext);
  const content = useContentSelection(editor);
  const assets = _.get(content, 'assets', []);
  const selectedAsset = _.get(content, 'selectedAsset', null);
  const saving = content.saving;

  const handlePreviousClick = () => {
    var currentIndex = assets.findIndex(item => {
      return item.id === selectedAsset.id;
    })

    if (currentIndex < 1) {
      currentIndex = assets.length - 1;
    } else {
      currentIndex -= 1;
    }

    var asset = assets[currentIndex];

    var request = {
      ...content,
      selectedAsset: asset
    }

    dispatch(updateContentEditor(editor, request));
  }

  const handleNextClick = () => {
    var currentIndex = assets.findIndex(item => {
      return item.id === selectedAsset.id;
    })

    if (currentIndex > assets.length - 1) {
      currentIndex = 0;
    } else {
      currentIndex += 1;
    }

    var asset = assets[currentIndex];

    var request = {
      ...content,
      selectedAsset: asset
    }

    dispatch(updateContentEditor(editor, request));
  }

  const buttonText = () => {
    if (saving) {
      return 'Adding assets...';
    }

    return 'Add to Element';
  }

  return (
    <Menu secondary className={cx(styles.actionMenu, rest.className)}>
      <Menu.Menu position='right'>
        {showArrows &&
          <>
            <Button basic className={styles.arrowButton} onClick={handlePreviousClick}>
              <Icon name='arrow alternate circle left outline' />
            </Button>
            <Button basic className={styles.arrowButton} onClick={handleNextClick}>
              <Icon name='arrow alternate circle right outline' />
            </Button>
          </>
        }
        <Button basic icon className={styles.addButton} onClick={onSave}>
          {buttonText()}
        </Button>
      </Menu.Menu>
    </Menu>
  )
}

export function useContentEditor(content) {
  const dispatch = useDispatch();

  var editor = guid();

  dispatch(createContentEditor(editor, content));

  return editor;
}

export function useContentSelection(editor) {
  const contentSelection = useSelector(state => {

    const editorContext = state.contentSelection.editors[editor];

    return editorContext;
  });

  return contentSelection;
}