import React, {Fragment, useState} from 'react';
import { 
	Button,
  Popup,
} from 'semantic-ui-react';

export default function InlineConfirmationDialog({message, children, onConfirm, ...rest}){
	const [open, setOpen] = useState(false);
	const onOpen = () => {
		setOpen(true)
	}	

	const onClose = () => {
		setOpen(false)
	}	

	const onConfirmClicked = () => {
		setOpen(false)
		if(onConfirm){
			onConfirm();
		}
	}	

	var config = {};
	if(!open){
		config.open = open;
	}
	
  return (
    <Popup 
    	on='hover'
    	trigger={children} 
			hoverable
    	content={
     	 <Fragment>
     	 	  <p>{message}</p>
     	 	  <Button positive onClick={onConfirmClicked}>Confirm</Button>
     	 	  <Button negative onClick={onClose}>Cancel</Button>
     	 </Fragment>
			}
			onOpen={onOpen}
			{...config}
      {...rest} 
    />
  )
}
