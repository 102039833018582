import React, { PropTypes, Fragment, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';

import styles from './zohoConfig.module.scss';
import {
  Grid,
  Form,
  Input
} from 'semantic-ui-react';
export function ZohoConfiguration() {

  const [email, setEmail] = useState(null);

  const integrations = useSelector(state => _.get(state, 'integrations.activated.items', []));

  const integration = integrations.filter(item => {
    return item != null;
  }).find(item => {
    return item.type == 'zoho';
  })

  useEffect(() => {
    let { email } = integration && integration.configuration;
    setEmail(email);
  }, [])

  return (
    <Grid columns="equal" className={styles.zoho}>
      <Grid.Row>
        <Grid.Column>
          <Form className={styles.zohoForm}>
            <Form.Group inline>
              <Form.Field>
                <div className={styles.wrapper}>
                  <label>EMAIL</label>
                  <Input
                    value={email}
                  />
                </div>
                <div className={styles.helpText}>Zoho integration is currently active for this email account.</div>
              </Form.Field>
            </Form.Group>
          </Form>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  )
}