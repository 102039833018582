import React, { Fragment, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import cx from 'classnames';
import { guid } from 'services/util';
import _ from 'lodash';
import Truncate from 'react-truncate';

import {
  Button,
  Icon,
  Segment,
  Header,
  Label,
  Placeholder,
  Search
} from 'semantic-ui-react';


import styles from './clusters.module.scss';
import { Filters } from './filters';

import InlineConfirmationDialog from 'components/InlineConfirmationDialog';

import { deleteCluster } from 'actions/clusters';
import { fetchContentSuggestions, updateFiltersInContent } from 'actions/content';

function Clusters(props) {
  const dispatch = useDispatch();

  const clusters = useSelector(state => state.clusters.items);
  const loading = useSelector(state => state.clusters.loading);
  const clustersCount = useSelector(state => _.get(state, 'clusters.items.length', 0));

  const _handleSelectCluster = (cluster) => {
    if(props.onClusterClick){
      props.onClusterClick(cluster);
    }
  }

  const handleDeleteClick = (id) => {
    dispatch(deleteCluster(id, (res) => {
      props.onClusterClick(null);
    }));
  }

  const _renderClusters = () => {
    const activeCluster = props.active;

    var defaultCluster = (
      <Cluster 
        key='all'
        cluster={{name: 'All Content'}} 
        editable={false}
        active={activeCluster == null ||  activeCluster === 'all'} 
        onClick={_handleSelectCluster.bind(this, null)} 
      />
    );

    var clustersItems = (clusters || []).map(cluster => {
      var active = cluster.id === activeCluster;
      return <Cluster 
        key={cluster.id}
        cluster={cluster} 
        active={active} 
        editable={props.editable}
        onClick={_handleSelectCluster.bind(this, cluster)} 
        onDelete={handleDeleteClick}
      />;
    });

    clustersItems.splice(0, 0, defaultCluster);

    return clustersItems;
  }

  return (
    <Segment basic className={styles.clusters}>
      {!props.hideHeading && 
        <Header as="h4">{`Clusters Created`} <sup>{`(${clustersCount})`}</sup></Header>
      }
      
      {loading && 
        _.times(5).map((index) => {
          return <ClusterPlaceholder key={index} index={index} />
        })
      }
      {!loading && _renderClusters()}
    </Segment>
  );
}

export function Cluster({ cluster, active, onClick, editable = true, onDelete, ...rest }) {

  const handleDeleteClick = () => {
    onDelete(cluster.id);
  }

  const deleting = useSelector(state => _.get(state, 'clusters.cluster.deleting', false));

  return (
    <Label as='a'
      className={cx(styles.cluster, {
        [styles.active]: active
      })}
      onClick={onClick.bind(this, cluster)}
    >
      {cluster.name}
      {active && editable &&
        <InlineConfirmationDialog onConfirm={handleDeleteClick} message={'Are you sure delete from cluster?'}>
          <i aria-hidden="true" className={cx('icon', deleting ? 'spinner loading' : 'icon-close_1')}>
            {!deleting && 
            <>
              <span className="path1"></span><span className="path2"></span><span className="path3"></span>
            </>}
          </i>
        </InlineConfirmationDialog>
      }
    </Label>
  );
}

function ClusterPlaceholder(){
  return (
    <Placeholder className={styles.placeholder}>
      <Placeholder.Image />
    </Placeholder>
  );
}

function SearchBlock(props) {
  const dispatch = useDispatch();

  const suggestions = useSelector(state => _.get(state, 'content.suggestions.items', []));
  const loading = useSelector(state => _.get(state, 'content.suggestions.loading', false));
  const filters = useSelector(state => _.get(state, 'content.filters', []));

  let defautSuggestions = [
    {
      type: "asset",
      label: "Asset",
      icon: 'file alternate outline',
      text: '',
      value: '',
    },
    {
      type: "tags",
      label: "Tags",
      icon: 'tags',
      text: '',
      value: '',
    }
  ];

  const [showSuggestions, setShowSuggestions] = useState(false);
  const [query, setQuery] = useState('');
  const [options, setOptions] = useState(defautSuggestions);


  useEffect(() => {
    if (query) {
      dispatch(fetchContentSuggestions(query));
    }

    let suggestions = defautSuggestions.map(item => {
      return {
        ...item,
        text: query,
      }
    });

    setOptions(suggestions || []);
  }, [query]);

  const handleQueryChange = (e, { value }) => {
    setQuery(value);
  }

  const handleChange = (e, { result }) => {
    console.log(result);
    setQuery('');

    var filter = {
      _id: guid(),
      property: {
        type: result.type
      },
      operator: 'is'
    }

    switch (result.type) {
      case 'asset':
        if (result.value.id) {
          filter = {
            ...filter,
            value: [result.value]
          }
        } else {
          filter = {
            ...filter,
            input: query,
          }
        }
        break;

      case 'file_type':
        filter = {
          ...filter,
          value: result.value ? [result.value] : null
        }
        break;

      case 'custom_field':
        let value = _.get(result, 'value.option._id', null);
        filter = {
          ...filter,
          property: {
            ...filter.property,
            fieldId: result.value.id
          },
          value: value ? [value] : null
        }
        break;

      case 'tags':
        filter = {
          ...filter,
          input: query
        }
        break;

      default:
        break;
    }

    props.onChange(filter);

    document.querySelector('#content_search').blur();
  }

  useEffect(() => {
    if (!_.isEmpty(suggestions)) {
      let options = suggestions.map(item => {
        switch (item.type) {
          case 'asset':
            return {
              type: item.type,
              label: 'Asset',
              icon: 'file alternate outline',
              text: item.value.name,
              value: item.value
            }

          case 'file_type':
            return {
              type: item.type,
              label: 'File type',
              icon: 'file outline',
              text: item.value,
              value: item.value
            }

          case 'custom_field':
            return {
              type: item.type,
              label: item.value.name,
              icon: 'sticky note outline',
              text: _.get(item, 'value.option.value'),
              value: item.value
            }

          default:
            return null;
        }
      }).filter(item => { return item !== null });

      let defaultOptions = defautSuggestions.map(item => {
        return {
          ...item,
          text: query,
          value: query
        }
      });

      let updatedOptions = [...options, ...defaultOptions];

      setOptions(updatedOptions || []);
    }
  }, [suggestions]);

  const resultRenderer = (item) => {
    return (
      <div className={styles.searchResult}>
        <Icon name={item.icon} />
        <span className={cx(styles.typeLabel, { [styles.typeValue]: !item.text })}>{`${item.label} ${item.text ? 'is: ' : ''}`}</span>
        <span className={styles.typeValue}><Truncate lines={1}>{item.text || ''}</Truncate></span>
      </div>
    )
  }

  const ref = React.useRef(null);

  return (
    <Search
      id="content_search"
      ref={ref}
      input={{ icon: 'search', iconPosition: 'left' }}
      loading={loading}
      onResultSelect={handleChange}
      onSearchChange={_.debounce(handleQueryChange, 500, {
        leading: true,
      })}
      value={query}
      placeholder='Search here'
      results={options}
      resultRenderer={resultRenderer}
      open={showSuggestions}
      onFocus={() => { setShowSuggestions(true) }}
      onBlur={() => {
        setQuery('')
        setShowSuggestions(false)
      }}
    />
  )
}

Clusters.Search = SearchBlock;

export { Clusters };