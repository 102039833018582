import { 
  CREATE_ALIAS_REQUEST, CREATING_ALIAS_SUCCEEDED, CREATING_ALIAS_FAILED,
  UPDATE_ALIAS_REQUEST, UPDATING_ALIAS_SUCCEEDED, UPDATING_ALIAS_FAILED,
  DELETE_ALIAS_REQUEST, DELETING_ALIAS_SUCCEEDED, DELETING_ALIAS_FAILED,
  FETCH_ALIASES_REQUEST, FETCHING_ALIASES_SUCCEEDED, FETCHING_ALIASES_FAILED,
  INVALIDATE_ALIASES
} from 'constants/actionTypes.jsx';

import axios from 'axios';
import buildUrl from 'build-url';
import { createAction } from 'redux-actions';
import { call, put, takeEvery } from 'redux-saga/effects';

const apiUrl = process.env.REACT_APP_API_URL;
const ALIAS_URL = `${apiUrl}/api/2.0/aliases`;


export const createAlias = createAction(CREATE_ALIAS_REQUEST, request => {
  return {
    request: request
  }
});

export const creatingAliasSucceeded = createAction(CREATING_ALIAS_SUCCEEDED, (request, response) => {
  return {
    request: request,
    response: response
  }
});

export const creatingAliasFailed = createAction(CREATING_ALIAS_FAILED, (request, error) => {
  return {
    request: request,
    error: error
  }
});

function* createAliasSaga(action) {
  const { request } = action.payload;

  const createAliasAPI = () => {
    return axios.post(ALIAS_URL, request).then(response => response.data);
  }

  try {
    const response = yield call(createAliasAPI);
    yield put(creatingAliasSucceeded(request, response));
  } catch (error) {
    console.log(error);
    yield put(creatingAliasFailed(request, error));
  }
}

/**
 * Update Alias
*/
export const updateAlias = createAction(UPDATE_ALIAS_REQUEST, (id, request) => {
  return {
    id: id,
    request: request
  }
});

export const updatingAliasSucceeded = createAction(UPDATING_ALIAS_SUCCEEDED, (id, response) => {
  return {
    id: id,
    response: response
  }
});

export const updatingAliasFailed = createAction(UPDATING_ALIAS_FAILED, (id, error) => {
  return {
    id: id,
    error: error
  }
});

function* updateAliasSaga(action) {
  const { id, request } = action.payload;

  let url = buildUrl(ALIAS_URL, {
    path: id
  });

  const updateAliasAPI = () => {
    return axios.put(url, request).then(response => response.data);
  }

  try {
    const response = yield call(updateAliasAPI);
    yield put(updatingAliasSucceeded(id, response));
  } catch (error) {
    console.log(error);
    yield put(updatingAliasFailed(id, error));
  }
}

/**
 * Delete Alias
*/
export const deleteAlias = createAction(DELETE_ALIAS_REQUEST, (id) => {
  return {
    id: id
  }
});

export const deletingAliasSucceeded = createAction(DELETING_ALIAS_SUCCEEDED, (id) => {
  return {
    id: id
  }
});

export const deletingAliasFailed = createAction(DELETING_ALIAS_FAILED, (id, error) => {
  return {
    id: id,
    error: error
  }
});

function* deleteAliasSaga(action) {
  const { id } = action.payload;

  let url = buildUrl(ALIAS_URL, {
    path: id
  });

  const deleteAliasAPI = () => {
    return axios.delete(url).then(response => response.data);
  }

  try {
    yield call(deleteAliasAPI);
    yield put(deletingAliasSucceeded(id));
  } catch (error) {
    console.log(error);
    yield put(deletingAliasFailed(id, error));
  }
}

/**
 * Fetch Page Aliases
 */
export const fetchAliases = createAction(FETCH_ALIASES_REQUEST, (request, page, limit) => {
  return {
    request: request,
    page: page,
    limit: limit
  };
})

const fetchingAliasesSucceeded = createAction(FETCHING_ALIASES_SUCCEEDED, (request, response) => {
  return {
    request: request,
    response: response
  }
});
const fetchingAliasesFailed = createAction(FETCHING_ALIASES_FAILED, (request, error) => {
  return {
    request: request,
    error: error
  }
});

function* fetchAliasesSaga(action) {
  const { request, page, limit } = action.payload;

  var queryParams = {
    page: page,
    limit: limit
  }

  if(request){
    queryParams = {
      ...queryParams,
      ...request
    }
  }

  let url = buildUrl(ALIAS_URL, {
    queryParams: queryParams
  });

  const fetchAliasesAPI = () => {
    return axios.get(url).then(response => response.data);
  }

  try {
    const response = yield call(fetchAliasesAPI);
    yield put(fetchingAliasesSucceeded(request, response));
  } catch (error) {
    console.log(error);
    yield put(fetchingAliasesFailed(request, error));
  }
}

export const invalidateAliases = createAction(INVALIDATE_ALIASES);

export default function* aliasSaga() {
  yield takeEvery(FETCH_ALIASES_REQUEST, fetchAliasesSaga);
  yield takeEvery(CREATE_ALIAS_REQUEST, createAliasSaga);
  yield takeEvery(UPDATE_ALIAS_REQUEST, updateAliasSaga);
  yield takeEvery(DELETE_ALIAS_REQUEST, deleteAliasSaga);
  
}