import { UPDATE_FILTER_FOR_REPORTS } from 'constants/actionTypes';

import { combineReducers } from "redux";
import { visitorReportReducer } from './visitorReportsReducer';
import { leadReportReducer } from './leadReportReducer';
import { contentReportSReducer } from './contentReportsReducer'; 
import { guid } from 'services/util';

const initialState = {
  filters: [{
    _id: guid(),
    property: {
      type: 'page'
    },
    operator: 'is'
  }]
}

function reportCommonReducer(state = initialState, action) {
  switch (action.type) {
    case UPDATE_FILTER_FOR_REPORTS: 
      return {
        ...state,
        filters: action.payload
      }

    default:
      return state;
  }
}

const reportReducer = combineReducers({
  common: reportCommonReducer,
  visitors: visitorReportReducer,
  leads: leadReportReducer,
  content: contentReportSReducer
});

export { reportReducer };