import React, { PropTypes, Fragment, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Route,
  NavLink,
  Switch,
  useHistory,
  useParams,
  useRouteMatch
} from "react-router-dom";

import styles from './gatings.module.scss';

import cx from 'classnames';
import _ from 'lodash';
import dateformat from 'dateformat';
import { guid } from 'services/util';

import {
  Segment,
  Grid,
  Menu,
  Label,
  List,
  Dimmer,
  Loader,
  Input,
  Button,
  Header,
  Dropdown,
  Modal,
  Icon,
  Form
} from 'semantic-ui-react';

import InlineConfirmationDialog from 'components/InlineConfirmationDialog';
import Tooltip from 'components/Tooltip';
import { WidgetsEditor } from "pages/pages/editor/editor";
import { WidgetRegistry } from 'components/Widgets/registry.jsx';

import { createWidgetFromPalette, createEditorContext } from 'actions/widgetEditor';
import { fetchForms } from 'actions/forms';

import {
  fetchAllGating, fetchGating, createGating,
  updateGating, deleteGating
} from 'actions/gatings';

import { denormalise } from 'services/widget.helper';

export default function GatingsView() {
  const dispatch = useDispatch();
  let { path, url } = useRouteMatch();

  useEffect(() => {
    dispatch(fetchAllGating());
    dispatch(fetchForms());
  }, []);

  return (
    <Grid columns="equal" stretched divided='vertically' className={cx(styles.gatings)}>
      <Grid.Row>
        <Grid.Column width={4}>
          <GatingList />
        </Grid.Column>
        <Grid.Column>
          <Switch>
            <Route path={`${path}/:id`} exact>
              <GatingView />
            </Route>
          </Switch>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  )
}

function GatingList() {
  const history = useHistory();
  const dispatch = useDispatch();
  let { path, url } = useRouteMatch();

  const { id } = useParams();
  const createGatingState = useSelector(state => state.gatings.create);
  const gatings = useSelector(state => state.gatings.items);
  const gating = useSelector(state => state.gatings.gating);

  const handleCreate = () => {
    dispatch(createGating({
      name: 'Untitled Gating',
      documents: {
        type: 'PERCENTAGE',
        value: 0
      },
      videos: {
        type: 'PERCENTAGE',
        value: 0
      },
      others: {
        type: 'TIME',
        value: 0
      }
    }));
  }

  //On create
  useEffect(() => {
    if (createGatingState.item) {
      history.push(`${url}/${createGatingState.item.id}`);
    }
  }, [createGatingState.item]);

  //On load or delete
  useEffect(() => {
    if (!gating.item) {
      if (gatings.length > 0) {
        var next = gatings[0].id;
        history.replace(`${url}/${next}`);
      }

    }
  }, [gatings.length, gating.item, id]);

  return (
    <Segment basic className={styles.list}>

      <Menu secondary>
        <Menu.Item header as="h2">Gating Form<Label>{gatings.length}</Label></Menu.Item>
        <Menu.Menu position='right'>
          <Tooltip inverted content={'Create new gating'} position="top right">
            <Menu.Item icon='add' onClick={handleCreate} disabled={createGatingState.creating} />
          </Tooltip>
        </Menu.Menu>
      </Menu>

      <List selection celled verticalAlign='middle'>
        {gatings.map(gating => {
          return <GatingItem key={gating.id} gating={gating} />
        })}
      </List>
    </Segment>
  )
}

function GatingItem({ gating }) {
  let { path, url } = useRouteMatch();

  return (
    <List.Item as={NavLink} to={`${url}/${gating.id}`}>
      <List.Icon name='id card' size='big' verticalAlign='middle' />
      <List.Content>
        <List.Header>{gating.name}</List.Header>
        <List.Description>
          Last updated on {dateformat(gating.modifiedDate, 'dd mmmm')}
        </List.Description>
      </List.Content>
    </List.Item>
  )
}

function GatingView() {
  const { id } = useParams();
  const dispatch = useDispatch();

  const gating = useSelector(state => _.get(state, 'gatings.gating.item', null));
  const updating = useSelector(state => _.get(state, 'gatings.gating.updating', false));
  const deleting = useSelector(state => _.get(state, 'gatings.gating.deleting', false));

  const [name, setName] = useState(gating ? gating.name : '');
  const [form, setForm] = useState(gating && gating.form ? gating.form.id : null);
  const [documents, setDocuments] = useState(_.get(gating, "documents", {}));
  const [vidoes, setVideos] = useState(_.get(gating, "vidoes", {}));
  const [others, setOthers] = useState(_.get(gating, "others", {}));

  const [editor, setEditor] = useState(null);
  const [optional, setOptional] = useState(_.get(gating, 'mandatory') || false);

  useEffect(() => {
    if (gating && gating.id && gating.version === 'v1') {
      var editorContext = guid();
      setEditor(editorContext);

      dispatch(createEditorContext(editorContext, [gating.widget]));
    }
  }, [gating && gating.id]);

  useEffect(() => {
    var shouldLoad = !(gating && gating.id === id);
    if (shouldLoad) {
      dispatch(fetchGating(id));
    }
  }, [id]);

  useEffect(() => {
    if (gating) {
      setName(gating.name);
      setDocuments(gating.documents);
      setVideos(gating.videos);
      setOthers(gating.others);
      setForm(gating && gating.form ? gating.form.id : null);
      setOptional(gating ? gating.optional : true);
    }
  }, [gating]);

  var widgetsById = useSelector(state => {
    var widgetsById = {};

    if (!editor) {
      return widgetsById;
    }

    var editorContext = state.widgetsEditor.editors[editor];

    if (editorContext) {
      widgetsById = editorContext.widgetsById;
    }

    return widgetsById;
  })

  var widgets = useSelector(state => {
    var widgets = [];

    if (!editor) {
      return widgets;
    }

    var editorContext = state.widgetsEditor.editors[editor];

    if (editorContext) {
      widgets = editorContext.widgets;
    }

    return widgets;
  })

  const handleSave = () => {
    var widgetsRequest = denormalise(widgets, widgetsById);
    widgetsRequest = WidgetRegistry.processRequest(widgetsRequest);

    var request = {
      name: name,
      documents: documents,
      videos: vidoes,
      others: others,
      optional: optional
    }

    if (gating.version === 'v1') {
      request = {
        ...request,
        widget: widgetsRequest[0]
      }
    } else {
      request = {
        ...request,
        form: form
      }
    }

    dispatch(updateGating(id, request));
  }

  const handleOptionalGating = (e, { checked }) => {
    setOptional(checked);
  }

  const handleDelete = () => {
    dispatch(deleteGating(id));
  }

  if (!gating) {
    return false;
  }

  return (
    <Dimmer.Dimmable as={Segment} basic className={styles.gating} dimmed={deleting}>
      <Dimmer className={styles.dimmer} active={deleting} inverted verticalAlign='top'>
        <Loader inverted inline size='large'>Deleting gating...</Loader>
      </Dimmer>

      <Menu secondary>
        <Menu.Item>
          <Input size='massive' className={styles.name}
            value={name}
            onChange={(e) => {
              setName(e.target.value);
            }}
          />
        </Menu.Item>

        <Menu.Menu position='right'>
          <Menu.Item fitted='horizontally'>
            <InlineConfirmationDialog
              message='Are you sure you would like to delete gating?'
              onConfirm={handleDelete}
            >
              <Button negative
                loading={deleting}
                disabled={deleting}
              >Delete Gating</Button>
            </InlineConfirmationDialog>
          </Menu.Item>
          <Menu.Item fitted='horizontally'>
            <Button primary
              loading={updating}
              disabled={updating}
              onClick={handleSave}>Save changes</Button>
          </Menu.Item>
        </Menu.Menu>
      </Menu>

      <Segment className={styles.gatingConfiguration}>
        <Header className={styles.header}>Configuration</Header>
        <Grid>
          <GatingRule
            title='Documents'
            description='Documents include PPTX, PDF, DOCX and others'
            configuration={documents}
            setConfiguration={setDocuments} />

          <GatingRule
            title='Videos'
            description='Videos, youtube, wistia &amp; vimeo'
            configuration={vidoes}
            setConfiguration={setVideos} />

          <GatingRule
            title='Others'
            description='Gating rules for all other kind of content'
            configuration={others}
            setConfiguration={setOthers} />

          <Grid.Row className={styles.rule}>
            <Grid.Column width={6}>
              <Header as='h4'>
                Enable Optional Gating
               <Header.Subheader>Switch to allow prospects to close gating form</Header.Subheader>
              </Header>
            </Grid.Column>
            <Grid.Column>
              <Form.Field
                control={Form.Checkbox}
                checked={optional}
                onChange={handleOptionalGating}
                toggle
              />
            </Grid.Column>
          </Grid.Row>

        </Grid>
      </Segment>

      {gating.version === 'v1' &&
        <GatingAppearance gating={gating} editor={editor} />
      }

      {gating.version === 'v2' &&
        <FormComponent gating={gating} form={form} setForm={setForm} />
      }

    </Dimmer.Dimmable>
  )
}

function FormComponent({ gating, form, setForm }) {
  const dispatch = useDispatch();

  const forms = useSelector(state => _.get(state, 'forms.items', []));
  const loading = useSelector(state => _.get(state, 'forms.loading', false));
  const pagination = useSelector(state => _.get(state, 'forms.pagination', {}));
  const { currentPage, totalPages } = pagination;

  var formOptions = forms.map(form => {
    return {
      key: form.id,
      value: form.id,
      text: form.name
    }
  });

  if (currentPage < totalPages) {
    formOptions = [...formOptions, {
      key: 'loadMore',
      value: 'loadMore',
      text: 'Load More',
      content: (
        <p className={styles.loadMoreButton}>Load More</p>
      )
    }]
  }

  const handleFormChange = (e, { value }) => {
    console.log("value " + value);

    if (value === 'loadMore') {
      dispatch(fetchForms(currentPage + 1, 20, "name", "ASC", false));
      return;
    }

    setForm(value);
  }

  return (
    <Segment className={styles.gatingAppearance}>
      <Menu secondary>
        <Menu.Item header as="h3">Form</Menu.Item>
      </Menu>

      <Grid>
        <Grid.Row className={styles.rule}>
          <Grid.Column width={6}>
            <Header as='h4'>
              Form
            <Header.Subheader>Select a form</Header.Subheader>
            </Header>
          </Grid.Column>
          <Grid.Column width={10}>
            <Dropdown
              selection
              options={formOptions}
              placeholder='Select placeholder'
              value={form}
              onChange={handleFormChange}
              closeOnChange={false}
              className={styles.formSelection}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Segment>
  )
}

function GatingAppearance({ gating, editor }) {
  const dispatch = useDispatch();

  const [open, setOpen] = useState(false);

  const _onItemDrop = (editor, request, parent, index, insertBefore) => {
    const entityReference = {
      id: gating.id,
      type: 'gating'
    };

    dispatch(createWidgetFromPalette(editor, entityReference, request[0], parent, index, insertBefore));
  }

  return (
    <Segment className={styles.gatingAppearance}>
      <Menu secondary>
        <Menu.Item header as="h3">Appearance</Menu.Item>
        <Menu.Menu position='right'>
          <Modal size='fullscreen'
            trigger={
              <Menu.Item as={Button} size='mini'>Customize Form</Menu.Item>
            }
            className={styles.popupConfig}
            open={open}
            onOpen={() => {
              setOpen(true);
            }}
          >
            <Modal.Header>Configure Gating</Modal.Header>
            <Modal.Content>
              <WidgetsEditor
                id='widget_viewer'
                className={styles.editor}
                paletteClassName={styles.palette}
                propertyClassName={styles.properties}
                editor={editor}
                entityReference={{
                  id: gating.id,
                  type: 'gating'
                }}
                widgets={[gating.widget.id]}
                editable={true}
                modal={true}
                onCreateWidget={_onItemDrop}
              />
            </Modal.Content>
            <Modal.Actions>
              <Button onClick={() => setOpen(false)}>
                <Icon name='remove' /> Close
              </Button>
            </Modal.Actions>
          </Modal>
        </Menu.Menu>
      </Menu>

      <WidgetsEditor
        className={styles.preview}
        editor={editor}
        entityReference={{
          id: gating.id,
          type: 'gating'
        }}
        widgets={[gating.widget.id]}
        editable={false}
        onCreateWidget={_onItemDrop}
      />

    </Segment>
  )
}

const gatingCriteria = [
  { key: 'PERCENTAGE', value: 'PERCENTAGE', text: 'Percentage' },
  { key: 'TIME', value: 'TIME', text: 'Time in seconds' }
]

function GatingRule({ title, description, configuration, setConfiguration }) {
  console.log(configuration);

  const handleTypeChange = (e, { value }) => {
    var item = {
      ...configuration,
      type: value
    }

    setConfiguration(item);
  }

  const handleValueChange = (e) => {
    var item = {
      ...configuration,
      value: e.target.value
    }

    setConfiguration(item);
  }

  return (
    <Grid.Row className={styles.rule}>
      <Grid.Column width={6}>
        <Header as='h4'>
          {title}
          <Header.Subheader>{description}</Header.Subheader>
        </Header>
      </Grid.Column>
      <Grid.Column>
        <Input
          type='number'
          label={
            <Dropdown
              selection
              options={gatingCriteria}
              value={configuration.type}
              onChange={handleTypeChange}
            />
          }
          onChange={handleValueChange}
          value={configuration.value}
        />
      </Grid.Column>
    </Grid.Row>
  )
}

const gatingFormWidget = {
  type: 'layout.row',
  name: 'Row',
  widgets: [{
    type: 'layout.column',
    name: 'Column',
    widgets: [{
      type: 'widgets.form',
      name: 'Contact Form',
      widgets: [{
        type: 'widgets.form.step',
        name: 'Default',
        widgets: [{
          type: 'widgets.text',
          name: 'Text',
          content: '<h1>Connect with us</h1><p>Please enter a valid business email and we’ll get back to you shortly.</p>'
        }, {
          type: 'widgets.form.input',
          name: 'Email',
          properties: {
            id: 'email',
            label: 'Email',
            placeholder: 'Enter valid email'
          }
        }, {
          type: 'widgets.form.button',
          name: 'Submit Button',
          content: 'Submit'
        }]
      }]
    }]
  }]
}