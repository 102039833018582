import {
  AUTHENTICATE_PARDOT_REQUEST, AUTHTENTICATING_PARDOT_SUCCEEDED, AUTHTENTICATING_PARDOT_FAILED,
  UPDATE_PARDOT_REQUEST, UPDATING_PARDOT_SUCCEEDED, UPDATING_PARDOT_FAILED,
  FETCH_PARDOT_FORMS, FETCHING_PARDOT_FORMS_SUCCEEDED, FETCHING_PARDOT_FORMS_FAILED
} from 'constants/actionTypes';

const initialState = {
  changes: {
    saving: false,
    updating: false,
    error: null
  },
  forms: {
    loading: false,
    items: [],
    page: 0,
    limit: 100,
    loadMore: false
  }
};

function pardotReducer(state = initialState, action) {
  switch (action.type) {
    case AUTHENTICATE_PARDOT_REQUEST: 
      return {
        ...state,
        changes: {
          ...state.changes,
          saving: true,
          error: null
        }
      }

    case AUTHTENTICATING_PARDOT_SUCCEEDED:
      return {
        ...state,
        changes: {
          ...state.changes,
          saving: false,
          error: null
        }
      }

    case AUTHTENTICATING_PARDOT_FAILED:
      return {
        ...state,
        changes: {
          ...state.changes,
          saving: false,
          error: action.payload
        }
      }

    case UPDATE_PARDOT_REQUEST: 
      return {
        ...state,
        changes: {
          ...state.changes,
          updating: true,
          error: null
        }
      }

    case UPDATING_PARDOT_SUCCEEDED:
      return {
        ...state,
        changes: {
          ...state.changes,
          updating: false,
          error: null
        }
      }

    case UPDATING_PARDOT_FAILED:
      return {
        ...state,
        changes: {
          ...state.changes,
          updating: false,
          error: action.payload
        }
      }

    case FETCH_PARDOT_FORMS:
      return {
        ...state,
        forms: {
          ...state.forms,
          loading: true
        }
      }

    case FETCHING_PARDOT_FORMS_SUCCEEDED:

      var items = state.forms.items;

      if (state.forms.page !== 0) {
        items = [...items, ...action.payload];
      } else {
        items = action.payload
      }

      items = items.reduce((x, y) => {
        var index = x.findIndex((e) => {
          return e.id === y.id;
        });

        if (index < 0) {
          return [...x, y];
        } else {
          x[index] = y;
          return x;
        }
      }, []);

      return {
        ...state,
        forms: {
          ...state.forms,
          loading: false,
          items: items,
          page: state.forms.page + state.forms.limit,
          loadMore: action.payload.length > 0
        }
      }

    case FETCHING_PARDOT_FORMS_FAILED:
      return {
        ...state,
        forms: {
          ...state.forms,
          loading: false,
          error: action.payload
        }
      }

    default: 
      return state;
  }
}

export { pardotReducer };