import {
  FETCH_ALL_GATING_REQUEST, FETCH_ALL_GATING_SUCCEEDED, FETCH_ALL_GATING_FAILED,
  FETCH_GATING_REQUEST, FETCH_GATING_SUCCEEDED, FETCH_GATING_FAILED,
  CREATE_GATING_REQUEST, CREATE_GATING_SUCCEEDED, CREATE_GATING_FAILED,
  UPDATE_GATING_REQUEST, UPDATE_GATING_SUCCEEDED, UPDATE_GATING_FAILED,
  DELETE_GATING_REQUEST, DELETE_GATING_SUCCEEDED, DELETE_GATING_FAILED
} from 'constants/actionTypes';

const initialState = {
  loading: false,
  invalidated: true,
  items: [],
  create: {
    creating: false,
    item: null,
    error: null
  },
  gating: {
    id: null,
    creating: false,
    updating: false,
    deleting: false,
    error: null,
    item: null
  }
}

export function gatingsReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_ALL_GATING_REQUEST:
      return {
        ...state,
        loading: true
      }

    case FETCH_ALL_GATING_SUCCEEDED:
      return {
        ...state,
        loading: false,
        invalidated: false,
        items: action.payload
      }

    case FETCH_ALL_GATING_FAILED:
      return {
        ...state,
        loading: false,
        invalidated: true,
        items: [],
        error: action.payload
      }

    case FETCH_GATING_REQUEST:
      var id = action.payload;

      return {
        ...state,
        gating: {
          ...state.gating,
          id: id,
          loading: true
        }
      }

    case FETCH_GATING_SUCCEEDED:
      var { gating } = action.payload;

      return {
        ...state,
        gating: {
          ...state.gating,
          loading: false,
          item: gating
        }
      }

    case FETCH_GATING_FAILED:
      var { error } = action.payload;

      return {
        ...state,
        gating: {
          ...state.gating,
          loading: false,
          error: error
        }
      }

    case CREATE_GATING_REQUEST: 
      return {
        ...state,
        create: {
          creating: true
        }
      }  

    case CREATE_GATING_SUCCEEDED:
      gating = action.payload;
      var items = [gating, ...state.items];
      
      return {
        ...state,
        items: items,
        create: {
          creating: false,
          item: gating,
          error: null
        },
        gating: {
          ...state.gating,
          creating: false,
          id: gating.id,
          item: gating
        }
      }

    case CREATE_GATING_FAILED:
      error = action.payload;

      return {
        ...state,
        create: {
          creating: false,
          item: null,
          error: action.payload
        }
      }

    case UPDATE_GATING_REQUEST:
      var { id } = action.payload;

      return {
        ...state,
        gating: {
          ...state.gating,
          id: id,
          updating: true
        } 
      }

    case UPDATE_GATING_SUCCEEDED:
      var { gating } = action.payload;

      var items = state.items.map(item => {
        if (item.id === gating.id) {
          return {
            ...item,
            name: gating.name,
            modifiedDate: gating.modifiedDate
          }
        }

        return item;
      });

      return {
        ...state,
        items: items,
        gating: {
          ...state.gating,
          item: gating,
          updating: false
        }
      }

    case UPDATE_GATING_FAILED: 
      return {
        ...state,
        gating: {
          ...state.gating,
          updating: false,
          error: action.payload.error
        }
      }

    case DELETE_GATING_REQUEST: 
      return {
        ...state,
        gating: {
          ...state.gating,
          deleting: true
        }
      }

    case DELETE_GATING_SUCCEEDED: 
      var { id } = action.payload;

      var items = state.items.filter(item => {
        return item.id !== id;
      })

      return {
        ...state,
        items: items,
        gating: {
          id: null,
          creating: false,
          updating: false,
          deleting: false,
          error: null,
          item: null
        }
      }

    case DELETE_GATING_FAILED:
      return {
        ...state,
        gating: {
          ...state.gating,
          deleting: false,
          error: action.payload.error
        }
      }

    default: 
      return state;
  }
}