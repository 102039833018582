import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { WidgetsEditor } from "pages/pages/editor/editor";

import _ from 'lodash';

import {
  Modal,
  Button
} from 'semantic-ui-react';

import styles from './password.module.scss';

import { denormalise } from 'services/widget.helper.jsx';

export const PasswordModal = (props) => {
  var id = props.id;
  var widget = useSelector(state => {
    const editorContext = state.widgetsEditor.editors[props.editor];

    if (editorContext) {
      return editorContext.widgetsById[id];
    }

    return null;
  });

  useEffect(() => {
    if(!widget) {
      props.onClose();
    }
  }, [widget]);

  if(!id || !widget) {
    return null;
  }

  return (
    <Modal size='fullscreen'
      className={styles.popupConfig}
      open={props.open}
    >
      <Modal.Content>
        <Button className={styles.widgetHeader} onClick={props.onClose}>
          Exit {widget.name} Popup Edit Mode
          <i aria-hidden="true" className="icon-close_1 icon">
            <span className="path1"></span><span className="path2"></span><span className="path3"></span>
          </i>
        </Button>
        <WidgetsEditor
          className={styles.passwordWidgetEditor}
          paletteClassName={styles.palette}
          propertyClassName={styles.properties}
          editor={props.editor}
          widgets={[id]}
          editable={true}
          modal={true}
          onCreateWidget={props.onCreateWidget}
        />
      </Modal.Content>
    </Modal>
  )
}

