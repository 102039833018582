import { 
  FETCH_PAGES_REQUEST, FETCHING_PAGES_SUCCEEDED, FETCHING_PAGES_FAILED,

  FETCH_RECENTLY_UPDATED_PAGES_REQUEST, FETCHING_RECENTLY_UPDATED_PAGES_SUCCEEDED, FETCHING_RECENTLY_UPDATED_PAGES_FAILED,

  FETCH_PAGE_REQUEST, FETCHING_PAGE_SUCCEEDED, FETCHING_PAGE_FAILED,
  
  FETCH_PAGE_ANALYTICS_REQUEST, FETCHING_PAGE_ANALYTICS_SUCCEEDED, FETCHING_PAGE_ANALYTICS_FAILED,

  CREATE_PAGE_REQUEST, CREATING_PAGE_SUCCEEDED, CREATING_PAGE_FAILED,

  UPDATE_PAGE_REQUEST, UPDATING_PAGE_SUCCEEDED, UPDATING_PAGE_FAILED,

  PUBLISH_PAGE_REQUEST, PUBLISHING_PAGE_SUCCEEDED, PUBLISHING_PAGE_FAILED,

  RESTORE_PAGE_REQUEST, RESTORE_PAGE_SUCCEEDED, RESTORE_PAGE_FAILED,

  CLONE_PAGE_REQUEST, CLONING_PAGE_SUCCEEDED, CLONING_PAGE_FAILED,

  DELETE_PAGE_REQUEST, DELETE_PAGE_SUCCEEDED, DELETE_PAGE_FAILED,

  INVALIDATE_PAGE,  UPDATE_PAGE_PROPERTIES, UPDATE_TRACKING_PIXEL_PROPERTIES
} from 'constants/actionTypes.jsx';

import axios from 'axios';
import buildUrl from 'build-url';
import { createAction } from 'redux-actions';
import { call, put, takeEvery, takeLatest } from 'redux-saga/effects';

const apiUrl = process.env.REACT_APP_API_URL;
const PAGE_URL = `${apiUrl}/api/2.0/pages`;

/**
 * Fetch pages for storyboard
*/
export const fetchPages = createAction(FETCH_PAGES_REQUEST, (query, page, limit, orderBy, direction) => {
  return {
    query: query,
    page: page,
    limit: limit,
    orderBy: orderBy,
    direction: direction
  }
});

const fetchingPagesSucceeded = createAction(FETCHING_PAGES_SUCCEEDED, (response) => {
  return response;
});

const fetchingPagesFailed = createAction(FETCHING_PAGES_FAILED, (error) => {
  return error;
});

const fetchPagesAPI = (params) => {
  return axios({
    method: 'GET',
    url: PAGE_URL,
    params: params
  }).then(response => response.data);
}

function* fetchPagesSaga(action) {
  const { query, page, limit, orderBy, direction } = action.payload;

  var params = {
    page: page,
    limit: limit,
    query: query,
    orderBy: orderBy,
    direction: direction
  }
  
  console.log(params);

  try {
    const response = yield call(fetchPagesAPI, params);
    yield put(fetchingPagesSucceeded(response));
  } catch (error) {
    yield put(fetchingPagesFailed(error));
  }
}

/**
 * Fetch recently updated pages for storyboard
*/
export const fetchRecentlyUpdatedPages = createAction(FETCH_RECENTLY_UPDATED_PAGES_REQUEST);

const fetchingRecentlyUpdatedPagesSucceeded = createAction(FETCHING_RECENTLY_UPDATED_PAGES_SUCCEEDED, (response) => {
  return response;
});

const fetchingRecentlyUpdatedPagesFailed = createAction(FETCHING_RECENTLY_UPDATED_PAGES_FAILED, (error) => {
  return error;
});

function* fetchRecentlyUpdatedPagesSaga(action) {

  var params = {
    page: 0,
    limit: 3,
    query: "",
    orderBy: "modifiedDate",
    direction: "DESC"
  }
  
  try {
    const response = yield call(fetchPagesAPI, params);
    yield put(fetchingRecentlyUpdatedPagesSucceeded(response));
  } catch (error) {
    yield put(fetchingRecentlyUpdatedPagesFailed(error));
  }
}


/**
 * Create a new page in storyboard
*/
export const createPage = createAction(CREATE_PAGE_REQUEST, (request, callback) => {
  return {
    request: request, 
    callback: callback
  }
});
const creatingPageSucceeded = createAction(CREATING_PAGE_SUCCEEDED);
const creatingPageFailed = createAction(CREATING_PAGE_FAILED);

function* createPageSaga(action) {
  const {request, callback} = action.payload;
  
  const createPageAPI = () => {
    return axios({
      method: 'POST',
      url: PAGE_URL,
      data: request
    }).then(response => response.data);
  }

  try {
    const response = yield call(createPageAPI);
    yield put(creatingPageSucceeded(response));
    if (callback) {
      callback(response);
    }
  } catch (error) {
    yield put(creatingPageFailed(error));
  }
}

/**
 * Clone page for {id}
*/
export const clonePage = createAction(CLONE_PAGE_REQUEST, (id, request, callback) => {
  return {
    id: id,
    request: request,
    callback: callback
  }
})

export const clonePageSucceeded = createAction(CLONING_PAGE_SUCCEEDED, response => {
  return response;
})

export const clonePageFailed = createAction(CLONING_PAGE_FAILED, error => {
  return error;
})

function* clonePageSaga(action) {
  const { id, request, callback } = action.payload;

  let url = buildUrl(PAGE_URL, {
    path: `${id}/clone`
  });

  const clonePageAPI = () => {
    return axios({
      method: 'POST',
      url: url,
      data: request
    }).then(response => response.data);
  }

  try {
    const response = yield call(clonePageAPI);
    yield put(clonePageSucceeded(response));
    if (callback) {
      callback(response);
    }
  } catch (error) {
    yield put(clonePageFailed(error));
  }
}

/**
 * Fetch page for {id}
*/
export const fetchPage = createAction(FETCH_PAGE_REQUEST, (id, callback) => {
  return {
    id: id,
    callback: callback
  }
});
const fetchingPageSucceeded = createAction(FETCHING_PAGE_SUCCEEDED);
const fetchingPageFailed = createAction(FETCHING_PAGE_FAILED);

function* fetchPageSaga(action) {
  const {id, callback} = action.payload;

  let url = buildUrl(PAGE_URL, {
    path: `${id}`
  });

  const fetchPageAPI = () => {
    return axios.get(url).then(response => response.data);
  }

  try {
    const response = yield call(fetchPageAPI);
    yield put(fetchingPageSucceeded(response));
    if(callback){
      callback(response);
    }
  } catch (error) {
    console.log(error);
    yield put(fetchingPageFailed(error));
  }
}

/**
 * Fetch page analytics for {id}
*/
export const fetchPageAnalytics = createAction(FETCH_PAGE_ANALYTICS_REQUEST, (id) => {
  return {
    id: id
  }
});

const fetchingPageAnalyticsSucceeded = createAction(FETCHING_PAGE_ANALYTICS_SUCCEEDED, (id, response) => {
  return {
    id: id,
    response: response
  }
});
const fetchingPageAnalyticsFailed = createAction(FETCHING_PAGE_ANALYTICS_FAILED, (id, error) => {
  return {
    id: id,
    error: error
  }
});

function* fetchPageAnalyticsSaga(action) {
  const {id} = action.payload;

  let url = buildUrl(PAGE_URL, {
    path: `${id}/analytics`
  });

  const fetchPageAnalyticsAPI = () => {
    return axios.get(url).then(response => response.data);
  }

  try {
    const response = yield call(fetchPageAnalyticsAPI);
    yield put(fetchingPageAnalyticsSucceeded(id, response));
  } catch (error) {
    console.log(error);
    yield put(fetchingPageAnalyticsFailed(id, error));
  }
}

/**
 * Update page for {id}
*/
export const updatePage = createAction(UPDATE_PAGE_REQUEST, (id, request, callback) => {
  return {
    id: id,
    request: request,
    callback: callback
  }
});

const updatingPageSucceeded = createAction(UPDATING_PAGE_SUCCEEDED, response => {
  return response;
});
const updatingPageFailed = createAction(UPDATING_PAGE_FAILED, error => {
  return error;
});

function* updatePageSaga(action) {
  const { id,  request, callback } = action.payload;

  let url = buildUrl(PAGE_URL, {
    path: `${id}`
  });

  const updatePageAPI = () => {
    return axios({
      method: 'PUT',
      url: url,
      data: request
    }).then(response => response.data);
  }

  try {
    const response = yield call(updatePageAPI);
    yield put(updatingPageSucceeded(response));
    if(callback) yield call(callback, response);
  } catch (error) {
    yield put(updatingPageFailed(error));
  }
}

/**
 * Publish page for {id}
*/
export const publishPage = createAction(PUBLISH_PAGE_REQUEST, (id, callback) => {
  return {
    id: id,
    callback: callback
  }
});

const publishingPageSucceeded = createAction(PUBLISHING_PAGE_SUCCEEDED, response => {
  return response;
});
const publishingPageFailed = createAction(PUBLISHING_PAGE_FAILED, error => {
  return error;
});

function* publishPageSaga(action) {
  const { id, callback } = action.payload;

  let url = buildUrl(PAGE_URL, {
    path: `${id}/publish`
  });

  const publishPageAPI = () => {
    return axios({
      method: 'POST',
      url: url
    }).then(response => response.data);
  }

  try {
    const response = yield call(publishPageAPI);
    yield put(publishingPageSucceeded(response));
    if(callback) yield call(callback, response);
  } catch (error) {
    yield put(publishingPageFailed(error));
  }
}

/**
 * Restore page for {id}
*/
export const restorePage = createAction(RESTORE_PAGE_REQUEST, (id, callback) => {
  return {
    id: id,
    callback: callback
  }
});

const restoringPageSucceeded = createAction(RESTORE_PAGE_SUCCEEDED, response => {
  return response;
});
const restoringPageFailed = createAction(RESTORE_PAGE_FAILED, error => {
  return error;
});

function* restorePageSaga(action) {
  const { id, callback } = action.payload;

  let url = buildUrl(PAGE_URL, {
    path: `${id}/restore`
  });

  const restorePageAPI = () => {
    return axios({
      method: 'POST',
      url: url
    }).then(response => response.data);
  }

  try {
    const response = yield call(restorePageAPI);
    yield put(restoringPageSucceeded(response));
    if (callback) yield call(callback, response);
  } catch (error) {
    yield put(restoringPageFailed(error));
  }
}

/**
 * Delete page for {id}
*/
export const deletePage = createAction(DELETE_PAGE_REQUEST, (id, callback) => {
  return {
    id: id,
    callback: callback
  };
})

const deletePageSucceeded = createAction(DELETE_PAGE_SUCCEEDED, (id, response) => {
  return {
    id: id,
    response: response
  };
})

const deletePageFailed = createAction(DELETE_PAGE_FAILED, (id, error) => {
  return {
    id: id,
    error: error
  };
})

function* deletePageSaga(action) {
  const id = action.payload.id;
  const callback = action.payload.callback;

  let url = buildUrl(PAGE_URL, {
    path: `${id}`
  });

  const deletePageAPI = () => {
    return axios({
      method: 'DELETE',
      url: url,
    }).then(response => response.data);
  }

  try {
    const response = yield call(deletePageAPI);
    yield put(deletePageSucceeded(id, response));
    if(callback) yield call(callback, response);
  } catch (error) {
    yield put(deletePageFailed(id, error));
  }
}

export const invalidatePage = createAction(INVALIDATE_PAGE);

/**
 * Update Page properties
*/
export const updatePageProperties = createAction(UPDATE_PAGE_PROPERTIES, request => {
  return request;
});

/**
 * Update TrackingPixel Properties
 */
export const updateTrackingPixelProperties = createAction(UPDATE_TRACKING_PIXEL_PROPERTIES, request => {
  return request;
});

export default function* pagesSaga() {
  yield takeEvery(FETCH_PAGES_REQUEST, fetchPagesSaga);
  yield takeEvery(FETCH_RECENTLY_UPDATED_PAGES_REQUEST, fetchRecentlyUpdatedPagesSaga);
  yield takeEvery(FETCH_PAGE_ANALYTICS_REQUEST, fetchPageAnalyticsSaga);
  yield takeEvery(CREATE_PAGE_REQUEST, createPageSaga);
  yield takeEvery(FETCH_PAGE_REQUEST, fetchPageSaga);
  yield takeEvery(UPDATE_PAGE_REQUEST, updatePageSaga);
  yield takeEvery(PUBLISH_PAGE_REQUEST, publishPageSaga);
  yield takeEvery(RESTORE_PAGE_REQUEST, restorePageSaga);
  yield takeEvery(CLONE_PAGE_REQUEST, clonePageSaga);
  yield takeEvery(DELETE_PAGE_REQUEST, deletePageSaga);
};