import React from 'react';
import { 
  Popup
} from 'semantic-ui-react';

export default function Tooltip({content, children, disabled=false, ...rest}){
  var showOptions = {};

  if(disabled){
    showOptions = {
      open: !disabled
    }
  }

  return (
      <Popup content={content} trigger={children} {...showOptions} {...rest} />
  )
}
