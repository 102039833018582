import React, {Fragment, useState} from 'react';
import cx from 'classnames';
import _ from 'lodash';

import Draggable from 'react-draggable';
import { 
  Portal, Segment, Icon
} from 'semantic-ui-react';

import styles from './floatingPane.module.scss';

export default function FloatingPane({width, height, position, children, className, onClose}){
  return (
    <Portal open>
      <Draggable 
        handle={`.${styles.handle}`} 
      >
        <Segment className={cx(styles.pane, className)} style={{
          ...position,
          width: width,
        }}>
          <DragHandle onClose={onClose}/>
          <div className={styles.inner} style={{height: height}}>
            {children}
          </div>
        </Segment>
      </Draggable>
    </Portal>
  )
}

function DragHandle(props){

  const handleClose = (e) => {
    e.preventDefault();
    props.onClose();
  }

  return (
    <div className={styles.handle}>
      <div className={styles.line}>
        {_.times(5).map((index) => {
          return <span key={index}/>
        })}
      </div>
      <div className={styles.line}>
        {_.times(5).map((index) => {
          return <span key={index}/>
        })}
      </div>
      
      {props.onClose && 
        <i aria-hidden="true" className={cx(styles.closeButton, 'icon-close icon')} onClick={handleClose}></i>
      }
    </div>
  )
}