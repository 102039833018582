import React from 'react';
import { connect } from 'react-redux';
import videojs from "video.js";
import youtubeVideo from "videojs-youtube/dist/Youtube.js";
import wistiaVideo from "./wistia/Wistia.js";
import vimeoVideo from './vimeo/Vimeo.js';
import vidyardVideo from "./vidyard/Vidyard.js" 

import 'video.js/dist/video-js.css';
import cx from 'classnames';
import _ from 'lodash';
import styles from './VideoViewer.module.scss';

import AuthService from 'services/auth.service';

import { 
  captureNewVideoRange, updateVideoRange, 
  removeVideoRange, syncAssetVideoRange 
} from 'actions/content';

class VideoView extends React.Component {
  constructor(props) {
    super(props);

    var videoProperties = this._extractVideoProperties(this.props);

    var initialSegment = this.props.initialSegment;
    initialSegment = initialSegment ? Number(initialSegment) : 0;

    this.state = {
      ...videoProperties,
      initialSegment: initialSegment
    }

    this.startRange = 0;
    this.timeSpent = 0;
    this.isPlaying = false;

    this.currentRange = null;
  }

  componentDidMount() {
    this.loadPlayer(this.props);
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.asset.id !== nextProps.asset.id ||
      this.props.asset.metadata.modified_date !== nextProps.asset.metadata.modified_date) {

      var videoProperties = this._extractVideoProperties(nextProps);

      this.setState({
        ...videoProperties
      }, () => {
        this.updatePlayerSource(nextProps);
      });
    }

    const oldPoster = _.get(this.props, 'asset.poster.full');
    const newPoster = _.get(nextProps, 'asset.poster.full');

    if(!_.isEqual(oldPoster, newPoster) && newPoster) {
      this.player.poster(newPoster)
    }
  }

  loadPlayer(props) {
    if (this.timer) {
      clearInterval(this.timer);
    }

    var videoProperties = this._extractVideoProperties(props);
    const poster = this.getPoster(this.props);

    if(poster){
      videoProperties = {
        ...videoProperties,
        poster: poster
      }
    }

    this.player = videojs(this.videoNode, videoProperties, () => {
      console.log('onPlayerReady', this)

      //Start Session
      this.startRange = 0;
      this.endRange = 0;
      this.timeSpent = 0;
      this.isPlaying = false;

      if (this.state.initialSegment > 0) {
        this.player.currentTime(this.state.initialSegment);
      }
    });

    this.player.on('timeupdate', (e) => {
      //console.log('timeupdate: ' + this.player.currentTime());
      var endRange = this.player.currentTime();

      if (!this.currentRange && (this.props.asset.metadata.external_video_provider === 'wistia')) {
        this.currentRange = this.props.captureNewVideoRange(this.props.contextId, this.player.currentTime()).payload.id;
      }

      if (this.currentRange && !this.player.paused()) {
        this.props.updateVideoRange(this.props.contextId, this.currentRange, endRange);
      }
    });

    this.player.on('playing', () => {
      console.log("Player is playing the video");

      if (this.currentRange) {
        this.props.updateVideoRange(this.props.contextId, this.currentRange, this.player.currentTime());
      } else {
        this.currentRange = this.props.captureNewVideoRange(this.props.contextId, this.player.currentTime()).payload.id;
      }

    });

    this.player.on('seeking', () => {
      console.log("Player started seeking");
    });

    this.player.on('seeked', () => {
      console.log("Player finished seeking at " + this.player.currentTime());

      this.currentRange = this.props.captureNewVideoRange(this.props.contextId, this.player.currentTime()).payload.id;
    });

    this.player.on('ended', () => {
      console.log("Player finished playing video");

      if (this.currentRange) {
        this.props.updateVideoRange(this.props.contextId, this.currentRange, this.player.currentTime());
        this.currentRange = null;
      }
    });

    this.player.on(['waiting', 'pause', 'stalled', 'suspend'], () => {
      this.isPlaying = false;

      console.log('waiting');
    });

    this.player.on('error', (e) => {
      console.log(e);
      var error = this.player.error();

      this.setState({
        error: error
      })
    });

    this.timer = setInterval(() => {
      this.syncRanges();
    }, 5000);
  }

  updatePlayerSource(props) {
    if (this.timer) {
      clearInterval(this.timer);
    }

    this.syncRanges();

    this.currentRange = null;
    this.startRange = 0;
    this.endRange = 0;
    this.timeSpent = 0;
    this.isPlaying = false;

    var videoProperties = this._extractVideoProperties(props);
    this.player.src(videoProperties);


    var posterUrl = this.getPoster(props);
    this.player.poster(posterUrl)

    this.timer = setInterval(() => {
      this.syncRanges();
    }, 5000);

    
  }

  getPoster(props){ 
    const externalVideoType = _.get(props, 'asset.metadata.external_video_provider');
    
    if(_.includes(['vimeo', 'wistia', 'vidyard'], externalVideoType)){
      return null; 
    }

    return _.get(props, 'asset.poster.full');
  }

  componentWillUnmount() {
    if (this.player) {
      this.player.dispose()
    }

    if (this.timer) {
      clearInterval(this.timer);
    }
  }

  syncRanges() {
    this.props.ranges.forEach((range) => {

      var shouldRemove = false;
      var isCurrent = range.id == this.currentRange;

      var duration = range.end - range.start;
      var shouldSync = duration >= 2 && (!(range.synced || range.syncing));
      shouldRemove = !shouldSync && !isCurrent;

      if (shouldSync && this.props.session) {
        console.log('Sync with server' + range.id);
        var request = {
          type: 103,
          session: this.props.session,
          rangeId: range.external_id,
          start: range.start,
          end: range.end,
          eventDate: range.updatedDate
        }

        this.props.syncAssetVideoRange(this.props.contextId, range.id, request);
      }

      if (shouldRemove) {
        console.log('Remove from store' + range.id);
        this.props.removeVideoRange(this.props.contextId, range.id);
      }

    })
  }

  _extractExternalVideoProperties(props) {
    var videoDetail = {
      src: props.asset.metadata.url,
      type: props.asset.metadata.external_video_type,
    }
    return videoDetail;
  }

  _extractDefaultVideoProperties(props) {
    var videoDetail = {
      src: AuthService.getURL(`api/2.0/content/${props.asset.id}/download`, {
        modifiedDate: props.asset.metadata.modified_date,
        preview: true,
        'PF-APPLICATION-ID': props.storyboard.id
      }),
      type: props.asset.metadata.content_type
    }

    return videoDetail;
  }

  _extractVideoProperties(props) {
    switch (props.asset.metadata.external_video_provider) {
      case 'youtube':
      case 'vimeo':
      case 'wistia':
      case 'vidyard':
        return this._extractExternalVideoProperties(props);
      default:
        return this._extractDefaultVideoProperties(props);
    }
  }

  render() {
    if (this.state.error) {
      return false;
    }

    var options = {};

    if (this.state.type !== "video/wistia") {
      options["controls"] = "controls";
    }

    return (
      <div className={cx(styles.viewer, { [styles.viewerInline]: this.props.inline})}>
        <div data-vjs-player>
          <video ref={node => this.videoNode = node} className={cx("vjs-16-9", "video-js", styles.vjs169)} {...options}>
            <source src={this.state.src} type={this.state.type} />
          </video>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  var assetState = state.asset[ownProps.contextId];
  return {
    session: assetState ? assetState.session.id : null,
    ranges: assetState ? assetState.session.ranges : [],
    storyboard: _.get(state, 'storyboards.storyboard.item')
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    captureNewVideoRange: (contextId, startRange) => {
      return dispatch(captureNewVideoRange(contextId, startRange))
    },

    updateVideoRange: (contextId, id, endRange) => {
      dispatch(updateVideoRange(contextId, id, endRange));
    },

    removeVideoRange: (contextId, id) => {
      dispatch(removeVideoRange(contextId, id));
    },

    syncAssetVideoRange: (contextId, rangeID, event) => {
      return dispatch(syncAssetVideoRange(contextId, rangeID, event));
    }
  }
}

const VideoViewer = connect(
  mapStateToProps,
  mapDispatchToProps
)(VideoView)

export default VideoViewer;