import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { Grid, Menu, Button, Icon, Segment, Input, Transition, Message } from 'semantic-ui-react';

import _ from 'lodash';

import styles from './Password.module.scss';

import { changePassword } from 'actions/users';

import cx from 'classnames';
import { SemanticToastContainer, toast } from 'react-semantic-toasts';

export default function ProfileView() {

  const dispatch = useDispatch();

  const me = useSelector(state => _.get(state, 'users.me'));

  const [oldPassword, setOldPassword] = useState(null);
  const [newPassword1, setNewPassword1] = useState(null);
  const [newPassword2, setNewPassword2] = useState(null);
  const [errors, setErrors] = useState({});
  const [visible, setVisible] = useState(false);

  var passwordErrors = useSelector(state => _.get(state, 'users.changePassword.error.errors'));
  var changing = useSelector(state => _.get(state, 'users.changePassword.changing', false));

  useEffect(() => {
    if (passwordErrors != null) {
      var error = {}
      passwordErrors.forEach(passwordError => {
        error[passwordError.field] = passwordError.message;
        console.log(error);
      })
      setErrors(error);
    }
  }, [passwordErrors]);

  const handleSave = () => {
    dispatch(changePassword(oldPassword, newPassword1, newPassword2, () => {
      setOldPassword("");
      setNewPassword1("");
      setNewPassword2("");
      setErrors(null);
      setTimeout(() => {
        setVisible(true);
      }, 500)

      setTimeout(() => {
        setVisible(false);
      }, 4000)
    }));
  }

  const handleCancel = () => {
    setOldPassword("");
    setNewPassword1("");
    setNewPassword2("");
    setErrors(null);
  }

  const handleDismiss = () => {
    setVisible(false);
  }

  return (
    <>
      <Grid.Row>
        <Grid.Column width={1}></Grid.Column>
        <Grid.Column width={4} />
        <Grid.Column width={10} className={styles.passwordView}>
          <Segment className={styles.passwordFields}>
            <span className={styles.heading}>Set up a password</span>
            <span className={styles.subHeading}>And no, ‘cleverstory123’ is definitely NOT a smart choice</span>
            <Input
              placeholder="Old Password"
              type='password'
              value={oldPassword}
              className={styles.oldPassword}
              error={errors ? errors.oldPassword : null}
              onChange={(e) => {
                setOldPassword(e.target.value);
              }}
            ></Input>
            <span className={styles.error}>{errors ? errors.oldPassword : ""}</span>
            <br />
            <Input
              placeholder="New Password"
              type='password'
              value={newPassword1}
              className={cx(styles.password, styles.newPassword)}
              error={errors ? errors.newPassword : null}
              onChange={(e) => {
                setNewPassword1(e.target.value);
              }}
            ></Input>
            <Input
              placeholder="Confirm Password"
              type='password'
              value={newPassword2}
              className={styles.password}
              error={errors ? errors.newPassword1 : null}
              onChange={(e) => {
                setNewPassword2(e.target.value);
              }}
            ></Input><span className={styles.error}>{errors ? errors.newPassword1 : ""}</span><br />
            <Menu
              secondary
              size='large'
              borderless
              className={styles.actionMenu}
            >
              <Menu.Menu position='right'>
                <Menu.Item>
                  <Button className={styles.saveButton} size='mini' onClick={handleSave}>
                    <Icon name='save' />
                    {changing ? 'Saving...' : 'Save Changes'}
                  </Button>
                </Menu.Item>
                <Menu.Item>
                  <Button className={styles.cancelButton} size='mini' onClick={handleCancel}>Cancel</Button>
                </Menu.Item>
              </Menu.Menu>
            </Menu>
          </Segment>
        </Grid.Column>
        <Grid.Column width={1}></Grid.Column>
      </Grid.Row>
      <Transition.Group animation={"fly left"} duration={"500"}>
        {visible &&
          <Message positive onDismiss={handleDismiss}>
            <Message.Header>Success!</Message.Header>
            <p>
              Password updated successfully.
            </p>
          </Message>
        }
      </Transition.Group>
    </>
  )
}