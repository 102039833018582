import { AUTHENTICATE_MARKETO_REQUEST, AUTHENTICATE_MARKETO_SUCCEEDED, AUTHENTICATE_MARKETO_FAILED,
  FETCH_MARKETO_FORMS, FETCHING_MARKETO_FORMS_SUCCEEDED, FETCHING_MARKETO_FORMS_FAILED,
  SEARCH_MARKETO_FORM, SEARCH_MARKETO_FORM_SUCCEEDED, SEARCH_MARKETO_FORM_FAILED
} from 'constants/actionTypes';

import axios from 'axios';
import { createAction } from 'redux-actions';
import buildUrl from 'build-url';
import { call, put, takeEvery } from 'redux-saga/effects';


const apiUrl = `${process.env.REACT_APP_API_URL}/api/2.0`;
const MARKETO_BASE_URL = `${apiUrl}/integrations/marketo`;

/*
* Authenticate marketo
*/
export const authenticateMarketo = createAction(AUTHENTICATE_MARKETO_REQUEST, (request, callback) => {
  return { request, callback };
})

const authenticateMarketoSucceeded = createAction(AUTHENTICATE_MARKETO_SUCCEEDED, (response) => {
  return response;
})

const authenticateMarketoFailed = createAction(AUTHENTICATE_MARKETO_FAILED, (error) => {
  return error;
})

function* authenticateMarketoSaga(action) {
  const { request, callback } = action.payload;

    const authenticateMarketAPI = () => {
      return axios({
        method: 'POST',
        url: MARKETO_BASE_URL,
        data: request
      }).then(response => response.data);
    }

  try {
    const response = yield call(authenticateMarketAPI);
    if (response) {
      yield put(authenticateMarketoSucceeded(response));
      if(callback) {
        callback(response);
      }
    }
  } catch (error) {
    yield put(authenticateMarketoFailed(error));
  }
}

/*
* Fetching marketo forms
*/
export const fetchMarketoForms = createAction(FETCH_MARKETO_FORMS, (page, limit) => {
  return {
    page: page,
    limit: limit
  }
});

const fetchMarketoFormsSucceeded = createAction(FETCHING_MARKETO_FORMS_SUCCEEDED, (page, limit, response)  => {
  return {
    page: page,
    limit: limit,
    response: response
  }
});

const fetchMarketoFormsFailed = createAction(FETCHING_MARKETO_FORMS_FAILED, error => {
  return error;
})

function* fetchMarketoFormsSaga(action) {

  const { page, limit } = action.payload;

  let url = buildUrl(`${MARKETO_BASE_URL}/forms`, {
    queryParams: {
      page: page,
      limit: limit
    }
  });

  const fetchMarketoFormsAPI = () => {
    return axios.get(url).then(response => response.data);
  }

  try {
    const response = yield call(fetchMarketoFormsAPI);
    if (response) {
      yield put(fetchMarketoFormsSucceeded(page, limit, response));
    }
  } catch (error) {
    yield put(fetchMarketoFormsFailed(error));
  }
}


export const searchMarketoForm = createAction(SEARCH_MARKETO_FORM, (query) => {
  return {
    query: query
  }
});

const searchMarketoFormSucceeded = createAction(SEARCH_MARKETO_FORM_SUCCEEDED, response => {
  return response;
});

const searchMarketoFormFailed = createAction(SEARCH_MARKETO_FORM_FAILED, error => {
  return error;
})

function* searchMarketoFormSaga(action) {

  const { query } = action.payload;

  let url = buildUrl(`${MARKETO_BASE_URL}/form/search`, {
    queryParams: {
      query: query
    }
  });

  const searchMarketoFormAPI = () => {
    return axios.get(url).then(response => response.data);
  }

  try {
    const response = yield call(searchMarketoFormAPI);
    if (response) {
      yield put(searchMarketoFormSucceeded(response));
    }
  } catch (error) {
    yield put(searchMarketoFormFailed(error));
  }
}

export default function* marketoSaga() {
  yield takeEvery(AUTHENTICATE_MARKETO_REQUEST, authenticateMarketoSaga);
  yield takeEvery(FETCH_MARKETO_FORMS, fetchMarketoFormsSaga);
  yield takeEvery(SEARCH_MARKETO_FORM, searchMarketoFormSaga);
}
