import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, NavLink, useRouteMatch,  useHistory } from "react-router-dom";

import { WidgetRegistry } from 'components/Widgets/registry.jsx';
import { WidgetsEditor  } from "pages/pages/editor/editor";
import SidePane from 'components/SidePane';
import { Property } from 'components/Properties';
import FloatingPane from 'components/FloatingPane';

import cx from 'classnames';
import { Helmet } from "react-helmet";

import {
  Header,
  Menu,
  Segment,
  Input,
  Icon,
  Button,
  Popup
} from 'semantic-ui-react';

import styles from './liveElements.module.scss';
import _ from 'lodash';

import { getBaseUrl } from 'services/util';

import {
  createEditorContext, createWidgetFromPalette
} from 'actions/widgetEditor';

import { fetchIntegrations } from 'actions/integrations/integrations';

import { fetchLiveElement, updateLiveElement, publishLiveElement, deleteLiveElement, updateLiveElementName } from 'actions/live_elements';
import { fetchAssetTemplates } from 'actions/templates';

import { denormalise } from 'services/widget.helper';
import { guid } from 'services/util';
import InlineConfirmationDialog from 'components/InlineConfirmationDialog';

import { useWidgetSelecion } from 'components/Widgets/widget';

const applicationUrl = `${process.env.REACT_APP_UI_URL}`;

export default function LiveElementEditor() {
  const dispatch = useDispatch();
  const history = useHistory();
  let { url } = useRouteMatch();

  const { id } = useParams();

  const [editor, setEditor] = useState(null);
  const [editable, setEditable] = useState(true);

  const selectedWidget = useWidgetSelecion(editor);

  const element = useSelector(state => _.get(state, 'liveElements.element.item', {}));
  const loading = useSelector(state => _.get(state, 'liveElements.element.loading', false));
  const updating = useSelector(state => _.get(state, 'liveElements.element.updating', false));

  var widgetsById = useSelector(state => {
    var widgetsById = {};

    if (!editor) {
      return widgetsById;
    }
    
    var editorContext = state.widgetsEditor.editors[editor];

    if (editorContext) {
      widgetsById = editorContext.widgetsById;
    }

    return widgetsById;
  })

  var widgets = useSelector(state => {
    var widgets = [];

    if (!editor) {
      return widgets;
    }

    var editorContext = state.widgetsEditor.editors[editor];

    if (editorContext) {
      widgets = editorContext.widgets;
    }

    return widgets;
  })

  useEffect(() => {
    dispatch(fetchLiveElement(id));
    dispatch(fetchAssetTemplates());
    dispatch(fetchIntegrations());

    var editor = guid();
    setEditor(editor);
  }, []);

  useEffect(() => {
    if (element && element.draft && element.draft.widgets && editor) {
      dispatch(createEditorContext(editor, element.draft.widgets));
    }
  }, [element && element.id, editor]);

  const _onItemDrop = (editor, request, parent, index, insertBefore) => {
    const entityReference = {
      id: id,
      type: 'live_element'
    };

    dispatch(createWidgetFromPalette(editor, entityReference, request[0], parent, index, insertBefore));
  }

  const _handleUpdatePage = () => {
    var widgetsRequest = denormalise(widgets, widgetsById);
    widgetsRequest = WidgetRegistry.processRequest(widgetsRequest);

    var properties = _.get(element, 'draft.properties', {});
    
    var request = {
      widgets: widgetsRequest,
      properties: properties
    }

    var name = element.name;
    if(name && name.trim() != ''){
      request = {
        ...request,
        name: name
      }
    }

    dispatch(updateLiveElement(element.id, request));
  }

  const _handlePublishPage = () => {
    var widgetsRequest = denormalise(widgets, widgetsById);
    widgetsRequest = WidgetRegistry.processRequest(widgetsRequest);

    var properties = _.get(element, 'draft.properties', {});

    dispatch(publishLiveElement(element.id, {
      widgets: widgetsRequest,
      properties: properties
    }));
  }

  const _handleDeleteClicked = () => {
    setEditable(false);
    dispatch(deleteLiveElement(element.id, () => {
      let appUrl = getBaseUrl(url);
      history.push(`${appUrl}/settings/live_elements`);
    }));
  }

  if (loading || !element) {
    return false;
  }

  const stylesheets = _.get(element, 'draft.properties.css', ''); 
  const script = _.get(element, 'draft.properties.script', '');

  return (
    <div className={styles.liveElementEditor}>
      {(stylesheets || script) &&
        <Helmet>
          <style type="text/css">{stylesheets}</style>
          <script type="text/javascript">{script}</script>
        </Helmet>
      }
      
      <PageHeader
        id={element.id}
        name={element.name}
        height={300}
        save={_handleUpdatePage}
        publish={_handlePublishPage}
        delete={_handleDeleteClicked}
      />

      <Segment basic className={styles.widgetsEditor}>
        <div className={styles.editor}>
          <WidgetsEditor
            id='widget_viewer'
            editor={editor}
            entityReference={{
              id: element.id,
              type: 'live_element'
            }}
            widgets={widgets}
            editable={editable}
            onCreateWidget={_onItemDrop}
          />

          <FloatingPane
            width={260}
            height='80vh'
            position={{ top: 50, right: 20 }}
            className={cx({ [styles.hide]: (!editable || selectedWidget != null) })}
          >
              <PropertiesPane editor={editor} />
          </FloatingPane>
        </div>
      </Segment>
    </div>
  )
}

function PageHeader(props){
  const dispatch = useDispatch();

  const baseUrl = useSelector(state => _.get(state, 'storyboards.storyboard.item.baseUrl'));
  const element = useSelector(state => _.get(state, 'liveElements.element.item', {}));
  const updating = useSelector(state => _.get(state, 'liveElements.element.updating', false));
  const publishing = useSelector(state => _.get(state, 'liveElements.element.publishing', false));
  const deleting = useSelector(state => _.get(state, 'liveElements.element.deleting', false));

  let { path, url } = useRouteMatch();
  let appUrl = getBaseUrl(url);

  const handleNameChange = (e) => {
    dispatch(updateLiveElementName(props.id, e.target.value));
  }

  return (
    <>
      <Menu fluid className={styles.pageHeader}>
        <Menu.Item as={NavLink} exact name='Pages' to={`${appUrl}/settings/live_elements`} icon ><Icon name='arrow left' /></Menu.Item>
        <Menu.Item className={styles.nameInput}>
          <Input className='icon' placeholder='Live element name...' value={props.name} onChange={handleNameChange}/>
        </Menu.Item>
        <Menu.Menu position='right'>
          <CopyCodeAction id={props.id} baseUrl={baseUrl} height={props.height} />

          <InlineConfirmationDialog
            message='Are you sure you would like to delete Live element?'
            onConfirm={props.delete}
          >
            <Menu.Item icon>
              {!deleting && 
                <Icon name='trash' />
              }
              {deleting && 
                'Deleting...'
              }
            </Menu.Item>
          </InlineConfirmationDialog>
          
          <Menu.Item as='a' icon href={`${appUrl}/settings/live_elements/${element.id}/preview`} target='_blank'>
            <Icon name='eye' />
          </Menu.Item>
          <Menu.Item onClick={props.save} disabled={updating}>
            <Icon name={updating ? 'spinner' : 'save'} loading={updating} />
            {updating ? 'Saving...' : 'Save'}
          </Menu.Item>
          <Menu.Item>
            <Button basic primary className={styles.publish} onClick={props.publish} disabled={publishing}>
              {publishing && <Icon name='spinner' loading={publishing} /> }
              {publishing ? 'Publishing...' : 'Publish'}
            </Button>
          </Menu.Item>
        </Menu.Menu>
      </Menu>
    </>
  );
}

function CopyCodeAction({id, baseUrl, height}){
  const [open, setOpen] = useState(false);
  const textareaRef = React.createRef(null);

	const onOpen = () => {
		setOpen(true)
  }	

  const handleCopy = () => {
    textareaRef.current.select();
    document.execCommand('copy');
  }

  return (
    <Popup 
      on='click'
      hoverable={false}
      onOpen={onOpen}
      position='bottom right'
      trigger={
        <Menu.Item><Icon name={'code'}/>Copy Code</Menu.Item>
      }
    >
      <Segment basic className={styles.copyCode}>
        <Header as={'h3'}>Embed in your website</Header>
        <textarea ref={textareaRef} readOnly={true} value={getTemplate(id, baseUrl, height)}></textarea>
        <Button onClick={handleCopy}>Copy Code</Button>
      </Segment>
    </Popup>
  );
}

function getTemplate(id, baseUrl, height){
  return (
    `<div class="cleverstory__live_element" data-live-element="${baseUrl}/embedded/${id}" data-width="100%" data-height="${height}px"></div>
    <script>
    !function(){var t=window,e=t.Cleverstory;if("function"==typeof e)e();else{var a=document,n=function(){var t=a.createElement("script");t.type="text/javascript",t.async=!0,t.src="${applicationUrl}/scripts/live_elements.min.js";var e=a.getElementsByTagName("script")[0];e.parentNode.insertBefore(t,e)};t.attachEvent?t.attachEvent("onload",n):t.addEventListener("load",n,!1)}}();
    </script>`
  );
}

function PropertiesPane(props) {
  const selectedWidget = useWidgetSelecion(props.editor);

  if (!selectedWidget) {
    return <LiveElementProperties />;
  }

  return null
}

function LiveElementProperties(props) {
  const dispatch = useDispatch();

  const element = useSelector(state => _.get(state, 'liveElements.element.item', {}));
  const properties = _.get(element, 'draft.properties', {}) || {}; 

  const handleCssChange = (css) => {
    var changeRequest = properties;
    _.set(changeRequest, 'css', css);

    var request = {
      properties: changeRequest
    }

    dispatch(updateLiveElement(element.id, request));
  }

  const handleScriptChange = (script) => {
    var changeRequest = properties;
    _.set(changeRequest, 'script', script);

    var request = {
      properties: changeRequest
    }

    dispatch(updateLiveElement(element.id, request));
  }

  return (
    <>
      <Property.Section title='Advanced' expanded>
        <Property.CSS title='Stylesheets' value={properties ? properties.css : ''} onChange={handleCssChange} />
        <Property.Script title='Java script' value={properties ? properties.script : ''} onChange={handleScriptChange} />
      </Property.Section>
    </>
  )
}