import { connect } from 'react-redux'
import ApplicationMenuView from './view.jsx';
import {fetchUserProfile} from '../../actions/users';

const mapStateToProps = (state, ownProps) => {
  return {
    profile: state.users.me
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    fetchUserProfile: () => {
      dispatch(fetchUserProfile());
    }
  }
}

const ApplicationMenu = connect(
  mapStateToProps,
  mapDispatchToProps
)(ApplicationMenuView);

export default ApplicationMenu;