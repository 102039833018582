import {
  CREATE_JOURNEY_REQUEST, CREATING_JOURNEY_SUCCEEDED, CREATING_JOURNEY_FAILED,
  FETCH_JOURNEYS_REQUEST, FETCHING_JOURNEYS_SUCCEEDED, FETCHING_JOURNEYS_FAILED,
  FETCH_JOURNEY_REQUEST, FETCHING_JOURNEY_SUCCEEDED, FETCHING_JOURNEY_FAILED,
  UPDATE_JOURNEY_REQUEST, UPDATING_JOURNEY_SUCCEEDED, UPDATING_JOURNEY_FAILED,

  CREATE_JOURNEY_STEP, CREATE_JOURNEY_STEP_SUCCEEDED, CREATE_JOURNEY_STEP_FAILED,
  UPDATE_JOURNEY_STEP, UPDATE_JOURNEY_STEP_SUCCEEDED, UPDATE_JOURNEY_STEP_FAILED,
  FETCH_JOURNEY_STEP, FETCH_JOURNEY_STEP_SUCCEEDED, FETCH_JOURNEY_STEP_FAILED,

  REARRANDE_STEP_IN_JOURNEY, DELETE_STEP_FROM_JOURNEY,

  DELETE_JOURNEY_REQUEST, DELETE_JOURNEY_SUCCEEDED, DELETE_JOURNEY_FAILED
} from 'constants/actionTypes';

import _ from 'lodash';

const initialState = {
  loading: false,
  invalidated: true,
  items: [],
  pagination:{
    current: -1,
    totalPages: 1,
    totalRecordsCount: 0
  },
  journey: {
    creating: false,
    loading: false,
    creatingStep: false,
    item: {}
  },
  create: {
    creating: false,
    item: null,
    error: null
  }
};

export function journeyReducer(state = initialState, action) {
  switch (action.type) {

    case CREATE_JOURNEY_REQUEST:
    return {
      ...state,
      journey: {
        ...state.journey,
        creating: true
      }
    };

    case CREATING_JOURNEY_SUCCEEDED:
    var items = state.items || [];

    items = [action.payload, ...items];
    
    return {
      ...state,
      items: items,
      journey: {
        ...state.journey,
        creating: false,
        item: action.payload
      }
    };

    case CREATING_JOURNEY_FAILED:
    return {
      ...state,
      journey: {
        ...state.journey,
        creating: false
      }
    };

    case FETCH_JOURNEYS_REQUEST:
      return {
        ...state,
        loading: true
      }

    case FETCHING_JOURNEYS_SUCCEEDED:
      var records = action.payload.records;
      var items = [];

      if(action.payload.currentPage != 0) {
        items = [...state.items, ...action.payload.records];
      }else {
        items = action.payload.records
      }
      
      items = items.reduce((x, y) => {
        var index = x.findIndex((e) => {
          return e.id === y.id;
        });

        if (index < 0) {
          return [...x, y];
        } else {
          x[index] = y;
          return x;
        }
      }, []);

      items = items.sort((a, b) => {
        return a.name.localeCompare(b.name);
      });

      return {
        ...state,
        loading: false,
        items: items,
        pagination: {
          ...state.pagination,
          current: action.payload.currentPage,
          totalPages: action.payload.totalPages,
          totalRecordsCount: action.payload.totalRecordsCount
        }
      }

    case FETCHING_JOURNEYS_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload.error
      }    

    case FETCH_JOURNEY_REQUEST:
      var id = action.payload.id;
      
      return {
        ...state,
        journey: {
          ...initialState.journey,
          loading: true
        }
      };

    case FETCHING_JOURNEY_SUCCEEDED:
      var id = action.payload.id;

      var steps = action.payload.steps || [];
      steps = steps.map(item => {
        return {
          ...item,
          fetching: false,
          fetched: false
        }
      })

      return {
        ...state,
        journey: {
          ...state.journey,
          loading: false,
          item: {
            ...state.journey.item,
            ...action.payload,
            steps: steps
          }
        }
      }

    case FETCHING_JOURNEY_FAILED:
      var id = action.payload.id;

      return {
        ...state,
        journey: {
          ...state.journey,
          loading: false,
          error: action.payload.error
        }
      };

      case UPDATE_JOURNEY_REQUEST:
      var { id, request } = action.payload;

      return {
        ...state,
        journey: {
          ...state.journey,
          updating: !('disabled' in request)
        }
      };

    case UPDATING_JOURNEY_SUCCEEDED:
      var id = action.payload.id;

      var steps = (action.payload.steps || []).map(item => {
        var step = (state.journey.item.steps || []).find(step => {
          return step.id  === item.id
        });

        return step;
      });

      return {
        ...state,
        items: state.items.map(item => {
          if(item.id === id){
            return action.payload;
          }

          return item;
        }),
        journey: {
          ...state.journey,
          updating: false,
          item: {
            ...action.payload,
            steps: steps
          }
        }
      };

    case UPDATING_JOURNEY_FAILED:
      var id = action.payload.id;

      return {
        ...state,
        journey: {
          ...state.journey,
          updating: false,
          error: action.payload.error
        }
      };

      case CREATE_JOURNEY_STEP: 
        var {id, step} = action.payload;

        if(!state.journey.item || state.journey.item.id !== id) {
          return state;
        }

        return {
          ...state,
          journey: {
            ...state.journey,
            creatingStep: true
          }
        }

      case CREATE_JOURNEY_STEP_SUCCEEDED: 
        var {id, step, response} = action.payload;

        var journey = {...state.journey.item};
        
        if(response.start){
          journey = {
            ...journey,
            start: {
              ...response,
              fetching: false,
              fetched: false
            }
          }
        }else{
          var steps = (journey.steps || []);

          journey = {
            ...journey,
            steps: [...steps, {
              ...response,
              fetching: false,
              fetched: false
            }]
          }
        }

        return {
          ...state,
          journey: {
            ...state.journey,
            creatingStep: false,
            item: journey
          }
        }

      case CREATE_JOURNEY_STEP_FAILED:
        var {id, step, error} = action.payload;

        return {
          ...state,
          journey: {
            ...state.journey,
            creatingStep: false,
            error: error
          }
        }

      case UPDATE_JOURNEY_STEP:
        var {id, step} = action.payload;

        var journey = {...state.journey.item};
        
        if(step === _.get(journey, 'start.id')){
          journey = {
            ...journey,
            start: {
              ...journey.start,
              updating: true
            }
          }
        }else{
          var steps = journey.steps || [];
          steps = steps.map(item => {
            if(item.id === step) {
              return {
                ...item,
                updating: true
              }
            }

            return item;
          });

          journey = {
            ...journey,
            steps: steps
          }
        }

        return {
          ...state,
          journey: {
            ...state.journey,
            item: journey
          }
        }

      case UPDATE_JOURNEY_STEP_SUCCEEDED:
        var {id, step, response} = action.payload;

        var journey = {...state.journey.item};
        
        if(step === _.get(journey, 'start.id')){
          journey = {
            ...journey,
            start: {
              ...journey.start,
              ...response,
              updating: false,
              fetching: false,
              fetched: true
            }
          }
        }else{
          var steps = journey.steps || [];
          steps = steps.map(item => {
            if(item.id === step) {
              return {
                ...item,
                ...response,
                updating: false,
                fetching: false,
                fetched: true
              }
            }

            return item;
          });

          journey = {
            ...journey,
            steps: steps
          }
        }

        return {
          ...state,
          journey: {
            ...state.journey,
            item: journey
          }
        }

      case UPDATE_JOURNEY_STEP_FAILED: 
        var {id, step, error} = action.payload;

        var journey = {...state.journey.item};
        
        if(step === _.get(journey, 'start.id')){
          journey = {
            ...journey,
            start: {
              ...journey.start,
              error: error,
              updating: false,
              fetching: false,
              fetched: false
            }
          }
        }else{
          var steps = journey.steps || [];
          steps = steps.map(item => {
            if(item.id === step) {
              return {
                ...item,
                error: error,
                updating: false,
                fetching: false,
                fetched: false
              }
            }

            return item;
          });

          journey = {
            ...journey,
            steps: steps
          }
        }

        return {
          ...state,
          journey: {
            ...state.journey,
            item: journey
          }
        }

      case FETCH_JOURNEY_STEP: 
        var {id, step} = action.payload;

        var journey = {...state.journey.item};
        
        if(step === _.get(journey, 'start.id')){
          journey = {
            ...journey,
            start: {
              ...journey.start,
              fetching: true,
              fetched: false
            }
          }
        }else{
          var steps = journey.steps || [];
          steps = steps.map(item => {
            if(item.id === step) {
              return {
                ...item,
                fetching: true,
                fetched: false
              }
            }

            return item;
          });

          journey = {
            ...journey,
            steps: steps
          }
        }

        return {
          ...state,
          journey: {
            ...state.journey,
            item: journey
          }
        }

      case FETCH_JOURNEY_STEP_SUCCEEDED:
        var {id, step, response} = action.payload;

        var journey = {...state.journey.item};
        
        if(step === _.get(journey, 'start.id')){
          journey = {
            ...journey,
            start: {
              ...journey.start,
              ...response,
              fetching: false,
              fetched: true
            }
          }
        }else{
          var steps = journey.steps || [];
          steps = steps.map(item => {
            if(item.id === step) {
              return {
                ...item,
                ...response,
                fetching: false,
                fetched: true
              }
            }

            return item;
          });

          journey = {
            ...journey,
            steps: steps
          }
        }

        return {
          ...state,
          journey: {
            ...state.journey,
            item: journey
          }
        }
        
      case FETCH_JOURNEY_STEP_FAILED:
        var {id, step, error} = action.payload;

        var journey = {...state.journey.item};
        
        if(step === _.get(journey, 'start.id')){
          journey = {
            ...journey,
            start: {
              ...journey.start,
              error: error,
              fetching: false,
              fetched: false
            }
          }
        }else{
          var steps = journey.steps || [];
          steps = steps.map(item => {
            if(item.id === step) {
              return {
                ...item,
                error: error,
                fetching: false,
                fetched: false
              }
            }

            return item;
          });

          journey = {
            ...journey,
            steps: steps
          }
        }

        return {
          ...state,
          journey: {
            ...state.journey,
            item: journey
          }
        }

      case REARRANDE_STEP_IN_JOURNEY:
        var { step, from, to } = action.payload;

        var steps = state.journey.item.steps;

        var index = steps.findIndex(item => {
          return item.id === step;
        });

        var sourceStep = steps[index];

        steps.splice(index, 1);
        steps.splice(to, 0, sourceStep);

        return {
          ...state,
          journey: {
            ...state.journey,
            item: {
              ...state.journey.item,
              steps: steps
            }
          }
        }

      case DELETE_STEP_FROM_JOURNEY:
        var step = action.payload;

        var steps = (state.journey.item.steps || []).filter(item => {
          return item.id !== step;
        });

        return {
          ...state,
          journey: {
            ...state.journey,
            item: {
              ...state.journey.item,
              steps: steps
            }
          }
        }

      case DELETE_JOURNEY_REQUEST: 
        var { id } = action.payload;

        return {
          ...state,
          journey: {
            ...state.journey,
            deleting: true
          }
        }

      case DELETE_JOURNEY_SUCCEEDED:
        var { id, error } = action.payload;

        return {
          ...state,
          items: state.items.filter(item => {
            return item.id !== id;
          }),
          journey: initialState.journey
        }

      case DELETE_JOURNEY_FAILED: 
        var {id, error} = action.payload;

        return {
          ...state,
          journey: {
            ...state.journey,
            deleting: false,
            error: error
          }
        }

      default:
        return state
    }
}