import 
{
  UPLOADING_FILE_PROGRESS, CREATING_ASSET_REQUEST, CREATING_ASSET_SUCCEEDED, CREATING_ASSET_FAILED, UPLOADING_FILE_REQUEST,
  ADD_ASSET_URL, ADD_ASSET_URL_SUCCEDDED, ADD_ASSET_URL_FAILED, 
  SELECT_ASSETS, REMOVE_ASSET_SELECTION, INVALIDATE_ASSET_SELECTION,
  ADDING_ASSETS_REQUEST, ADDING_ASSETS_SUCCEEDED, ADDING_ASSETS_FAILED,
  WS_ASSET_PROCESSED
}
from 'constants/actionTypes.jsx';

import _ from 'lodash';

const initialState = {
  assets: [],
  uploading: false
};
  
  export function selectionReducer(state = initialState, action) {
    switch (action.type) {

      case ADDING_ASSETS_REQUEST:
        var asset = action.payload ? action.payload[0] : null;

        if(!asset) {
          return state;
        }

        var assetItem = {
          type: 'asset',
          updating: true,
          asset: asset
        }

        var assets =  [...state.assets];
        const index = assets.findIndex(item => {
          return item.type === 'asset' && item.asset.id === asset.id;
        })

        if(index >= 0){
          assets[index] = assetItem;
        }else{
          assets.push(assetItem); 
        }
        
        return Object.assign({}, state, {
          ...state,
          assets: assets
        });

      case ADDING_ASSETS_SUCCEEDED:
        asset = action.payload.response ? action.payload.response[0] : null;

        if (!asset) {
          return state;
        }

        assetItem = {
          type: 'asset',
          updating: false,
          asset: asset
        }

        assets = state.assets.map(item => {
          if (item.type === 'asset' && item.asset.id === asset.id) {
            return assetItem;
          }

          return item;
        });

        return Object.assign({}, state, {
          ...state,
          assets: assets,
          uploading: true
        });

      case ADDING_ASSETS_FAILED:
         asset = action.payload.assets[0];

         assets = state.assets.filter(item => {
          if (item.type === 'asset' && item.asset.id === asset.id) {
            return assetItem;
          }

          return item;
         })
        
         return Object.assign({}, state, {
           ...state,
           assets: assets
         });

      case UPLOADING_FILE_PROGRESS:
        var assets = state.assets.map(item => {
          if (
            item.type == "file" &&
            item.file.path == action.payload.file.path
          ) {
            return {
              ...item,
              uploading: true,
              progress: action.payload.progress
            };
          } else {
            return item;
          }
        });
  
        return Object.assign({}, state, {
          ...state,
          assets: assets,
          uploading: true 
        });

        case CREATING_ASSET_REQUEST:
          var assets = state.assets.map(item => {
            if (
              item.type == "file" &&
              item.file.path == action.payload.file.path
            ) {
              return {
                ...item,
                uploading: false,
                creating: true
              };
            } else {
              return item;
            }
          });
    
          return Object.assign({}, state, {
            ...state,
            assets: assets
          });
  
      case CREATING_ASSET_SUCCEEDED:
        var assets = state.assets.map(item => {
          if (
            item.type == "file" &&
            item.file.path == action.payload.file.path
          ) {
            return {
              type: "asset",
              asset: action.payload.asset
            };
          } else {
            return item;
          }
        });
  
        return Object.assign({}, state, {
          ...state,
          assets: assets,
          uploading: false
        });
  
      case CREATING_ASSET_FAILED:
        var assets = state.assets.map(item => {
          if (
            item.type == "file" &&
            item.file.path == action.payload.file.path
          ) {
            return {
              ...item,
              creating: false,
              error: action.payload.error
            };
          } else {
            return item;
          }
        });
  
        return Object.assign({}, state, {
          ...state,
          assets: assets,
          uploading: false
        });

      case UPLOADING_FILE_REQUEST:
        return Object.assign({}, state, {
          ...state,
          assets: [
            ...state.assets,
            {
              type: "file",
              uploading: true,
              file: action.payload.file
            }
          ],
          uploading: true
        });

      case ADD_ASSET_URL:
        var url = {
          type: "url",
          url: action.payload.url
        };
  
        return Object.assign({}, state, {
          ...state,
          assets: [...state.assets, url],
          uploading: true
        });

      case ADD_ASSET_URL_SUCCEDDED:
        var assets = state.assets.map(item => {
          if (item.type == "url" && item.url == action.payload.url) {
            return {
              type: "asset",
              url: action.payload.url,
              asset: action.payload.asset
            };
          } else {
            return item;
          }
        });
  
        return Object.assign({}, state, {
          ...state,
          assets: assets,
          uploading: false
        });
  
        case ADD_ASSET_URL_FAILED:
        var assets = state.assets.filter(item => {
          if (item.type == "url" && item.url == action.payload.url) {
            return false;
          }
  
          return true;
        });
  
        return Object.assign({}, state, {
          ...state,
          assets: assets,
          uploading: false
        });

      case REMOVE_ASSET_SELECTION:
        var assets = state.assets.filter(item => {
          if (item.type == "asset") {
            var id = action.payload.id || action.payload.asset.id;
            return item.asset.id != id;
          }
  
          if (
            item.type == "file" &&
            item.file.preview == action.payload.file.preview
          ) {
            return false;
          }
  
          return true;
        });

        return Object.assign({}, state, {
          ...state,
          assets: assets
        });

      case INVALIDATE_ASSET_SELECTION:
        return Object.assign({}, state, {
          ...state,
          assets: []
        });

      case WS_ASSET_PROCESSED:
        var assets = state.assets.map(item => {
          if (item.type == "asset" && item.asset.id == action.payload.id) {
            return {
              ...item,
              asset: action.payload
            };
          } else {
            return item;
          }
        });

        return {
          ...state,
          assets: assets
        }
  
      default:
        return state;
    }
  }
  