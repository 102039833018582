import React, {useState, Fragment, useRef, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {updateProperties, updatingWidgetContentSucceeded, updateWidget} from 'actions/widgetEditor';

import _ from 'lodash';
import cx from 'classnames';

import {
  Button as SemanticButton,
  Modal,
  Icon,
  Grid
} from 'semantic-ui-react';

import { Editor } from '@tinymce/tinymce-react';
import { Widget, getStyles } from '../../widget.jsx';
import { WidgetRegistry } from '../../registry.jsx';
import { Property } from 'components/Properties';
import { QuickSettings, QuickSettingsProperty } from 'components/QuickSettings';

import styles from '../form.module.scss';
import { useWidgetDrop } from 'services/dnd.service.jsx';

export const FormButton = (props) => {
  const dispatch = useDispatch();
  var widgets = props.widgets || [];
  const properties = props.properties;

  const handleEditorChange = (content) => {
    dispatch(updatingWidgetContentSucceeded(props.id, content, props.editor));
  }

  const ref = useRef(null);
  const dropRef = useWidgetDrop(props, ref, {
    
  });

  const widgetStyles = getStyles(properties);

  return (
    <div 
      className={cx(props.className)}
      id={`widget_${props.id}`}
      forwardedRef={props.dragRef(dropRef(ref))}
      onMouseOver={props.onMouseOver}
      onMouseOut={props.onMouseOut}
      onClick={props.onClick}
    >
      <SemanticButton className={styles.button} style={widgetStyles}>
        <ButtonTextEditor value={props.content} onChange={handleEditorChange} />
      </SemanticButton>

      <Widget.Children widgets={props.widgets} parent={props.id} editor={props.editor} editable={props.editable} />
      {props.children}
    </div>
  );
}

function ButtonTextEditor({value, onChange}) {
  var selection_toolbar = 'bold italic | alignleft aligncenter alignright alignjustify | formatselect | numlist bullist | fontselect | fontsizeselect | forecolor backcolor | code';

  const [currentValue, setCurrentValue] = useState(value);

  useEffect(() => {
    setCurrentValue(value);
  }, [value]);

  return (
    <Editor
      value={currentValue}
      inline={true}
      init={{
        plugins: [ 'quickbars', 'autoresize', 'advcode', 'lists advlist'],
        menubar: false,
        toolbar: false,
        quickbars_insert_toolbar: 'h1 P | hr pagebreak',
        quickbars_selection_toolbar: selection_toolbar,
        browser_spellcheck : true,
        autoresize_bottom_margin: 50,
        autoresize_overflow_padding: 50,
        min_height: 100
      }}
      onEditorChange={onChange}
    />
  );
}

function FormButtonQuickSenttings(props) {
  const dispatch = useDispatch();

  const [selected, setSelected] = useState('spacing');

  const menuItems = [
    { id: 'spacing', title: 'Spacing', icon: 'icon-spacing' },
    { id: 'background', title: 'Background', icon: 'icon-background' },
    { id: 'border', title: 'Border', icon: 'icon-border' },
    { id: 'advanced', title: 'Advanced', icon: 'icon-custom' }
  ]

  const handleMenuClick = (item) => {
    setSelected(item);
  }

  var widget = useSelector(state => {
    const editorContext = state.widgetsEditor.editors[props.editor];

    if (editorContext) {
      return editorContext.widgetsById[props.id];
    }

    return null;
  });

  if (!widget) {
    return false;
  }

  var properties = widget.properties;

  const handleChange = (changeRequest) => {
    dispatch(updateProperties({
      id: widget.id,
      change: changeRequest,
      context: props.editor
    }));
  }

  const handleBackgroundImageChange = (media, type) => {
    var changeRequest = { ...widget };
    _.set(changeRequest, 'properties.background.upload', {
      type: type,
      data: media
    });

    changeRequest = WidgetRegistry.processRequest([changeRequest])[0];
    dispatch(updateWidget(props.id, changeRequest, props.editor));
  }

  const handleCssChange = (css) => {
    var changeRequest = { ...widget };
    _.set(changeRequest, 'properties.css', css);

    changeRequest = WidgetRegistry.processRequest([changeRequest])[0];
    dispatch(updateWidget(props.id, changeRequest, props.editor));
  }

  return (
    <QuickSettings menuItems={menuItems} selected={selected} onMenuClick={handleMenuClick} >
      {selected === 'spacing' &&
        <>
          <QuickSettingsProperty.Spacing
            title='Margin'
            value={properties.margin}
            onChange={(margin) => {
              handleChange({
                margin: margin
              });
            }}
          />
          <QuickSettingsProperty.Spacing
            title='Padding'
            value={properties.padding}
            onChange={(padding) => {
              handleChange({
                padding: padding
              });
            }}
          />
        </>
      }

      {selected === 'background' &&
        <QuickSettingsProperty.Background expanded
          value={properties.background}
          onChange={(background) => {
            handleChange({
              background: background
            });
          }}
          onImageChange={(image, type) => {
            handleBackgroundImageChange(image, type);
          }}
        />
      }

      {selected === 'border' &&
        <QuickSettingsProperty.Border expanded value={properties.border} onChange={(border) => {
          handleChange({
            border: border
          });
        }}
        />
      }

      {selected === 'advanced' &&
        <QuickSettingsProperty.CSS title='Stylesheets' value={properties.css} onChange={handleCssChange} />
      }
    </QuickSettings>
  )
}

FormButton.QuickSettings = FormButtonQuickSenttings;

function FormButtonProperty(props){
  const dispatch = useDispatch();

  var widget = useSelector(state => {
    const editorContext = state.widgetsEditor.editors[props.editor];

    if (editorContext) {
      return editorContext.widgetsById[props.id];
    }

    return null;
  });

  if(!widget){
    return false;
  }

  var properties = widget.properties;

  const handleChange = (changeRequest) => {
    dispatch(updateProperties({
      id: widget.id,
      change: changeRequest,
      context: props.editor
    }));
  }

  const handleBackgroundImageChange = (media, type) => {
    var changeRequest = {...widget};
    _.set(changeRequest, 'properties.background.upload', {
      type: type,
      data: media
    });

    changeRequest  = WidgetRegistry.processRequest([changeRequest])[0];
    dispatch(updateWidget(props.id, changeRequest, props.editor));
  }

  const handleCssChange = (css) => {
    var changeRequest = {...widget};
    _.set(changeRequest, 'properties.css', css);

    changeRequest  = WidgetRegistry.processRequest([changeRequest])[0];
    dispatch(updateWidget(props.id, changeRequest, props.editor));
  }


  return (
    <Fragment>
      <Property.Section title='Spacing' expanded>
        <Property.Spacing 
          title='Margin' 
          value={properties.margin}
          onChange={(margin) => {
            handleChange({
              margin: margin
            });
          }}
        />
        <Property.Spacing 
          title='Padding' 
          value={properties.padding}
          onChange={(padding) => {
            handleChange({
              padding: padding
            });
          }}
        />
      </Property.Section>

      <Property.Background expanded 
        value={properties.background} 
        onChange={(background) => {
          handleChange({
            background: background
          });
        }} 
        onImageChange={(image, type) => {
          handleBackgroundImageChange(image, type);
        }} 
      />
      
      <Property.Border expanded value={properties.border} onChange={(border) => {
          handleChange({
            border: border
          });
        }} 
      />

      <Property.Section title='Advanced' expanded>
        <Property.CSS title='Stylesheets' value={properties.css} onChange={handleCssChange} />
      </Property.Section>
    </Fragment>
  );
}

FormButton.Property = FormButtonProperty;