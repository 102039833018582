import React, {Fragment, useState} from 'react';
import cx from 'classnames';

import styles from './device.module.scss';
import tabletStyles from './tablet.scss';
import mobileStyles from './mobile.scss';

export default function Device({mode, className, children}){

  return (
    <div className={cx(styles.device, className, {
      'device-iphone-x': mode === 'mobile',
      'device-ipad-pro': mode === 'tablet',
      'device-ipad-pro landscape': mode === 'tablet-landscape',
      [styles.desktop]: mode === 'desktop'
    })}>
      <div className="device-frame">
        <div className='device-content'>{children}</div>
      </div>
      <div className="device-stripe"></div>
      <div className="device-header"></div>
      <div className="device-sensors"></div>
      <div className="device-btns"></div>
      <div className="device-power"></div>
    </div>
  )
}
