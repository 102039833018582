import React, { useCallback, useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { connect, useSelector } from 'react-redux';

import _ from 'lodash';
import cx from 'classnames';
import styles from './PDFViewer.module.scss';

import PDF from 'pdfjs-dist/build/pdf.js';

import { PDFPresentationMode } from 'pdfjs-dist/lib/web/pdf_presentation_mode.js';
import { PDFViewer } from 'pdfjs-dist/lib/web/pdf_viewer.js';
import { EventBus } from 'pdfjs-dist/lib/web/ui_utils';

import AuthService from 'services/auth.service';

import {
  Image,
  Loader,
  Menu
} from 'semantic-ui-react';

const ZoomOutIcon = '/images/pdfViewer/zoom_out_icon.png';
const ZoomInIcon = '/images/pdfViewer/zoom_in_icon.png';
const NextPageIcon = '/images/pdfViewer/up_arrow_icon.png';
const PreviousPageIcon = '/images/pdfViewer/down_arrow_icon.png';
const FullScreenIcon = '/images/pdfViewer/full_screen_icon.png';

function PDFAssetViewer({asset, inline, ...rest}){
  const containerElementRef = React.useRef(null);
  const viewerElementRef = React.useRef(null);

  const viewer = React.useRef(null);
  const presentationMode = React.useRef(null);
  const documentRef = React.useRef(null);
  const loadingTask = React.useRef();

  const [state, setState] = useState({
    loading: true
  });

  const storyboard = useSelector(state => _.get(state, 'storyboards.storyboard.item'));

  useEffect(() => {
    PDF.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${PDF.version}/pdf.worker.min.js`;
  }, [])

  useEffect(() => {
    if(asset){
      loadPDF(asset);
    }
  }, [asset]);

  const loadPDF = (asset) => {
    resetViewer(viewer.current);
    cancelRunningTask(loadingTask.current);

    const url = AuthService.getURL(`api/2.0/content/${asset.id}/download`, {
      modifiedDate: asset.metadata.modifiedDate,
      preview: true,
      'PF-APPLICATION-ID': storyboard.id
    });

    viewer.current = new PDFViewer({
      container: containerElementRef.current,
      viewer: viewerElementRef.current,
      renderer: 'canvas',
      eventBus: new EventBus()
    });

    presentationMode.current = new PDFPresentationMode({
      container: containerElementRef.current,
      viewer: viewerElementRef.current,
      pdfViewer: viewer.current,
      eventBus: viewer.current.eventBus
    });

    loadingTask.current = PDF.getDocument(url).promise;

    loadingTask.current.onProgress = (progress) => {
      var total = progress.total || _.get(asset, 'metadata.size');
      var percent = parseInt((progress.loaded / total) * 100) || 0;

      if (percent > 100) {
        percent = 99;
      }
    }

    loadingTask.current.then((doc) => {
      viewer.current.setDocument(doc);

      viewer.current.eventBus.on('pagechanging', _handlePageChanging)
      window.addEventListener('resize', _handleViewerResize);
      window.addEventListener('keydown', _handleKeyDown);

      documentRef.current = doc;

      setState({
        totalPages: doc.numPages,
        currentPage: viewer.current.currentPageNumber,
        loading: false,
        progress: 0,
        error: null
      });

      if (rest.onPageChange) {
        rest.onPageChange(viewer.current.currentPageNumber);
      }

      viewer.current.firstPagePromise.then(() => {
        viewer.current.currentScaleValue = 'auto';
        viewer.current.currentPageNumber = 1;
      });

    }).catch(e => {
      console.log(e);
      setState({
        ...state,
        error: e
      })
    });
  }

  const _handleZoomInClick = () => {
    if (viewer.current) {
      var newScale = viewer.current.currentScale;
      newScale = (newScale * 1.1).toFixed(2);
      newScale = Math.ceil(newScale * 10) / 10;
      newScale = Math.min(10, newScale);

      viewer.current.currentScaleValue = newScale
    }
  }

  const _handleZoomOutClick = () => {
    if (viewer.current) {
      var newScale = viewer.current.currentScale;
      newScale = (newScale / 1.1).toFixed(2);
      newScale = Math.floor(newScale * 10) / 10;
      newScale = Math.max(0.25, newScale);
      
      viewer.current.currentScaleValue = newScale
    }
  }

  const _handleNextPageClick = () => {
    if (viewer.current) {
      let total = viewer.current.pagesCount;
      let current = viewer.current.currentPageNumber;

      current = current + 1;
      if (current <= total) {
        viewer.current.currentPageNumber = current;
      }
    }
  }

  const _handlePreviousPageClick = () => {
    if (viewer.current) {
      let current = viewer.current.currentPageNumber;

      current = current - 1;
      if (current > 0) {
        viewer.current.currentPageNumber = current;
      }
    }
  }

  const _handleFullScreenClick = () => {
    if(presentationMode.current){
      presentationMode.current.request();
    }
  }

  const _handlePageChanging = useCallback((e) => {
    if (e.pageNumber) {
      setState((state) => {
        return {
          ...state,
          currentPage: e.pageNumber
        }
      });
    }
  }, [state]);

  const _handleViewerResize = (e) => {
    if (viewer.current) {
      var currentScaleValue = viewer.current.currentScaleValue;
      if (currentScaleValue === 'auto' || currentScaleValue === 'page-fit' || currentScaleValue === 'page-width') {
        viewer.current.currentScaleValue = currentScaleValue;
      } else if (!currentScaleValue) {
        viewer.current.currentScaleValue = 'auto';
      }
      viewer.current.update();
    }
  }

  const _handleKeyDown = (e) => {
    if (viewer.current) {
      var handled = false,
        ensureViewerFocused = false;
      var cmd = (e.ctrlKey ? 1 : 0) | (e.altKey ? 2 : 0) | (e.shiftKey ? 4 : 0) | (e.metaKey ? 8 : 0);
      var isInPresentationMode = viewer.current.isInPresentationMode;

      if (cmd === 0) {
        switch (e.keyCode) {
          case 38:
            if (!isInPresentationMode && viewer.current.currentScaleValue !== 'page-fit') {
              break;
            }
          case 37:
            if (viewer.current.isHorizontalScrollbarEnabled) {
              break;
            }
          case 33:
            _handlePreviousPageClick();
            handled = true;
            break;

          case 40:
            if (!isInPresentationMode && viewer.current.currentScaleValue !== 'page-fit') {
              break;
            }
          case 39:
            if (viewer.current.isHorizontalScrollbarEnabled) {
              break;
            }
          case 34:
            _handleNextPageClick();
            handled = true;
            break;
        }
      }

      if (handled) {
        e.preventDefault();
      }
    }
  }

  const resetViewer = (viewer) => {
    if (viewer) {
      viewer.eventBus.off('pagechanging', _handlePageChanging);
    }

    var pdfDocument = documentRef.current;
    if (pdfDocument) {
      pdfDocument.destroy();
    }
  }

  const cancelRunningTask = (runningTask) => {
    if (!runningTask || !runningTask.cancel) {
      return;
    }
  
    runningTask.cancel();
  };

  console.log(asset);

  return (
    <div className={cx(styles.pdfViewer, {
      [styles.inline]: inline,
      [styles.loading]: state.loading,
    })}>
        <div ref={containerElementRef} className='asset-pdf-container'>
          <div ref={viewerElementRef} className='asset-pdf-viewer'></div>
        </div>
        
        {!state.loading && !inline && 
          <ViewerToolbar 
            currentPage={state.currentPage}
            totalPages={state.totalPages}
            onZoomOut={_handleZoomOutClick}
            onZoomIn={_handleZoomInClick}
            onPrevious={_handlePreviousPageClick}
            onNext={_handleNextPageClick}
            onFullscreen={_handleFullScreenClick}
          />
        }

        <Loader active={state.loading}>Generating Preview...</Loader>
    </div>
  );
}

const ViewerToolbar = React.memo(function ViewerToolbar({
  currentPage, totalPages,
  onZoomOut, onZoomIn,
  onPrevious, onNext, onFullscreen
}){
  return (
    <Menu 
      compact 
      icon='labeled'
      fixed='bottom'
      size='small' 
      className={styles.controlMenu}>
      <Menu.Item content={`Page ${currentPage} of ${totalPages}`} />
      <Menu.Item name='angle up' onClick={onZoomOut}>
        <Image src={ZoomOutIcon} size='mini' spaced />
      </Menu.Item>

      <Menu.Item name='angle up' onClick={onZoomIn}>
        <Image src={ZoomInIcon} size='mini' spaced />
      </Menu.Item>

      <Menu.Item name='angle up' onClick={onPrevious}>
        <Image src={NextPageIcon} size='mini' spaced />
      </Menu.Item>

      <Menu.Item name='angle up' onClick={onNext}>
        <Image src={PreviousPageIcon} size='mini' spaced />
      </Menu.Item>
      <Menu.Item name='angle up' onClick={onFullscreen}>
        <Image src={FullScreenIcon} size='mini' spaced />
      </Menu.Item>
    </Menu>
  )
});

export default React.memo(PDFAssetViewer);