import {
  FETCH_ASSET_VIEWERS_REQUEST, FETCHING_ASSET_VIEWERS_SUCCEEDED, FETCHING_ASSET_VIEWERS_FAILED,
  FETCH_ASSET_VIEWER_REQUEST, FETCHING_ASSET_VIEWER_SUCCEEDED, FETCHING_ASSET_VIEWER_FAILED,
  CREATE_ASSET_VIEWER_REQUEST, CREATING_ASSET_VIEWER_SUCCEEDED, CREATING_ASSET_VIEWER_FAILED,
  CLONE_ASSET_VIEWER_REQUEST, CLONING_ASSET_VIEWER_SUCCEEDED, CLONING_ASSET_VIEWER_FAILED,
  UPDATE_ASSET_VIEWER_REQUEST, UPDATING_ASSET_VIEWER_SUCCEEDED, UPDATING_ASSET_VIEWER_FAILED,
  PUBLISH_ASSET_VIEWER_REQUEST, PUBLISHING_ASSET_VIEWER_SUCCEEDED, PUBLISHING_ASSET_VIEWER_FAILED,
  RESTORE_ASSET_VIEWER_REQUEST, RESTORING_ASSET_VIEWER_SUCCEEDED, RESTORING_ASSET_VIEWER__FAILED,
  DELETE_ASSET_VIEWER_REQUEST, DELETING_ASSET_VIEWER_SUCCEEDED, DELETING_ASSET_VIEWER_FAILED
} from 'constants/actionTypes.jsx'; 

import axios from 'axios';
import buildUrl from 'build-url';
import { createAction } from 'redux-actions';
import { call, put, takeEvery, takeLatest } from 'redux-saga/effects';

const apiUrl = process.env.REACT_APP_API_URL;
const ASSET_VIEWERS_URL = `${apiUrl}/api/2.0/asset_viewers`;

/**
 * Fetch asset viewers
*/
export const fetchAssetViwers = createAction(FETCH_ASSET_VIEWERS_REQUEST, (page, limit, orderBy, direction, published) => {
  return {
    page: page,
    limit: limit,
    orderBy: orderBy,
    direction: direction,
    published: published
  }
})

const fetchingAssetViewersSucceeded = createAction(FETCHING_ASSET_VIEWERS_SUCCEEDED, (response) => {
  return response;
})

const fetchingAssetViewersFailed = createAction(FETCHING_ASSET_VIEWERS_FAILED, (error) => {
  return error;
})

const fetchAssetViewersAPI = (params) => {
  return axios({
    method: 'GET',
    url: ASSET_VIEWERS_URL,
    params: params
  }).then(response => response.data);
}

function* fetchAssetViewersSaga(action) {
  const { page, limit, orderBy, direction, published } = action.payload;

  var params = {
    page: page,
    limit: limit,
    published: published
  }

  try {
    const response = yield call(fetchAssetViewersAPI, params);
    yield put(fetchingAssetViewersSucceeded(response));
  } catch (error) {
    yield put(fetchingAssetViewersFailed(error));
  }
}

/**
 * Fetch asset viewer for {id}
*/
export const fetchAssetViewer = createAction(FETCH_ASSET_VIEWER_REQUEST, (id, callback) => {
  return {
    id: id,
    callback: callback
  }
});

export const fetchAssetViewerSucceeded = createAction(FETCHING_ASSET_VIEWER_SUCCEEDED, response => {
  return response;
})

export const fetchAssetViewerFailed = createAction(FETCHING_ASSET_VIEWER_FAILED, error => {
  return error;
})

function* fetchAssetViewerSaga(action) {
  const { id, callback } = action.payload;

  let url = buildUrl(ASSET_VIEWERS_URL, {
    path: `${id}`
  });

  const fetchAssetViewerAPI = () => {
    return axios.get(url).then(response => response.data);
  }

  try {
    const response = yield call(fetchAssetViewerAPI);
    yield put(fetchAssetViewerSucceeded(response));
    if (callback) {
      callback(response);
    }
  } catch (error) {
    console.log(error);
    yield put(fetchAssetViewerFailed(error));
  }
}

/**
 * Update asset viewer for {id}
*/
export const updateAssetViewer = createAction(UPDATE_ASSET_VIEWER_REQUEST, (id, request, callback) => {
  return {
    id: id,
    request: request,
    callback: callback
  }
});

const updateAssetViewerSucceeded = createAction(UPDATING_ASSET_VIEWER_SUCCEEDED, response => {
  return response;
})

const updateAssetViewerFailed = createAction(UPDATING_ASSET_VIEWER_FAILED, error => {
  return error;
})

function* updateAssetViewerSaga(action) {
  const { id, request, callback } = action.payload;

  let url = buildUrl(ASSET_VIEWERS_URL, {
    path: `${id}`
  })

  const updateAssetViewerAPI = () => {
    return axios({
      method: 'PUT',
      url: url,
      data: request
    }).then(response => response.data);
  }

  try {
    const response = yield call(updateAssetViewerAPI);
    yield put(updateAssetViewerSucceeded(response));
    if (callback) yield call(callback, response);
  } catch (error) {
    yield put(updateAssetViewerFailed(error));
  }
}


/**
 * Publish asset viewer for {id}
*/
export const publishAssetViewer = createAction(PUBLISH_ASSET_VIEWER_REQUEST, (id, callback) => {
  return {
    id: id,
    callback: callback
  }
});

const publishAssetViewerSucceeded = createAction(PUBLISHING_ASSET_VIEWER_SUCCEEDED, response => {
  return response;
});
const publishAssetViewerFailed = createAction(PUBLISHING_ASSET_VIEWER_FAILED, error => {
  return error;
});

function* publishAssetViewerSaga(action) {
  const { id, callback } = action.payload;

  let url = buildUrl(ASSET_VIEWERS_URL, {
    path: `${id}/publish`
  });

  const publishAssetViewerAPI = () => {
    return axios({
      method: 'POST',
      url: url
    }).then(response => response.data);
  }

  try {
    const response = yield call(publishAssetViewerAPI);
    yield put(publishAssetViewerSucceeded(response));
    if (callback) yield call(callback, response);
  } catch (error) {
    yield put(publishAssetViewerFailed(error));
  }
}

/**
 * Restore page for {id}
*/
export const restoreAssetViewer = createAction(RESTORE_ASSET_VIEWER_REQUEST, (id, callback) => {
  return {
    id: id,
    callback: callback
  }
});

const restoreAssetViewerSucceeded = createAction(RESTORING_ASSET_VIEWER_SUCCEEDED, response => {
  return response;
});
const restoreAssetViewerFailed = createAction(RESTORING_ASSET_VIEWER__FAILED, error => {
  return error;
});

function* restoreAssetViewerSaga(action) {
  const { id, callback } = action.payload;

  let url = buildUrl(ASSET_VIEWERS_URL, {
    path: `${id}/restore`
  });

  const restoreAssetViewerAPI = () => {
    return axios({
      method: 'POST',
      url: url
    }).then(response => response.data);
  }

  try {
    const response = yield call(restoreAssetViewerAPI);
    yield put(restoreAssetViewerSucceeded(response));
    if (callback) yield call(callback, response);
  } catch (error) {
    yield put(restoreAssetViewerFailed(error));
  }
}

/**
 * Delete page for {id}
*/
export const deleteAssetViewer = createAction(DELETE_ASSET_VIEWER_REQUEST, (id, callback) => {
  return {
    id: id,
    callback: callback
  };
})

const deleteAssetViewerSucceeded = createAction(DELETING_ASSET_VIEWER_SUCCEEDED, (id, response) => {
  return {
    id: id,
    response: response
  };
})

const deleteAssetViewerFailed = createAction(DELETING_ASSET_VIEWER_FAILED, (id, error) => {
  return {
    id: id,
    error: error
  };
})

function* deleteAssetViewerSaga(action) {
  const id = action.payload.id;
  const callback = action.payload.callback;

  let url = buildUrl(ASSET_VIEWERS_URL, {
    path: `${id}`
  });

  const deleteAssetViewerAPI = () => {
    return axios({
      method: 'DELETE',
      url: url,
    }).then(response => response.data);
  }

  try {
    const response = yield call(deleteAssetViewerAPI);
    yield put(deleteAssetViewerSucceeded(id, response));
    if (callback) yield call(callback, response);
  } catch (error) {
    yield put(deleteAssetViewerFailed(id, error));
  }
}

export default function* assetViewersSaga() {
  yield takeEvery(FETCH_ASSET_VIEWERS_REQUEST, fetchAssetViewersSaga);
  yield takeEvery(UPDATE_ASSET_VIEWER_REQUEST, updateAssetViewerSaga);
  yield takeEvery(FETCH_ASSET_VIEWER_REQUEST, fetchAssetViewerSaga);
  yield takeEvery(PUBLISH_ASSET_VIEWER_REQUEST, publishAssetViewerSaga);
  yield takeEvery(RESTORE_ASSET_VIEWER_REQUEST, restoreAssetViewerSaga);
  yield takeEvery(DELETE_ASSET_VIEWER_REQUEST, deleteAssetViewerSaga);
};