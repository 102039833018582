import videojs from "video.js";

const Component = videojs.getComponent("Component");
const Tech = videojs.getComponent("Tech");
let cssInjected = false;

/**
 * Vidyard - Wrapper for Video Player API
 *
 * @param {Object=} options Object of option names and values
 * @param {Function=} ready Ready callback function
 * @extends Tech
 * @class Vidyard
 */
class Vidyard extends Tech {
  constructor(options, ready) {
    super(options, ready);

    this.setTimeout(function() {
      if (this.el_) {
        this.el_.parentNode.className += ' vjs-vidyard';
        this.initVidyardPlayer();
      }
    }.bind(this));
  }

  initVidyardPlayer() {
    this.videoId = this.parseUrl(this.options_.source.src);
    
    this.VidyardPlayer = new window.Vidyard.player(this.videoId);
    
    this.initVidyardState();

    this.VidyardPlayer.on("timeupdate", (progress) => {
      var previousTime = this._vidyardState.duration || 0;
      this._vidyardState.duration = progress;

      if(Math.abs(progress - previousTime) <= 1.5){
        this.trigger("timeupdate");
      }else{
        if(this._vidyardState.seeking){
          this.trigger("seeked");
        }

        this._vidyardState.seeking = false;
      }

      if (this._vidyardState.duration >= (this.VidyardPlayer.metadata && this.VidyardPlayer.metadata.length_in_seconds)) {
        this.trigger("ended");
      }
    });

    this.VidyardPlayer.on("pause", () => {
      this._vidyardState.playing = false;
      this.trigger("pause");
    });

    this.VidyardPlayer.on("play", () => {
      this._vidyardState.playing = true;
      this._vidyardState.ended = false;
      this.trigger("playing");
    });

    this.VidyardPlayer.on("playerComplete", () => {
      this._vidyardState.playing = false;
      this._vidyardState.ended = true;
      this.trigger("ended");
    });

    this.VidyardPlayer.on("beforeSeek",(progress) =>{
      this._vidyardState.seeking = true;
      this.trigger("seeking");
    })

    this.VidyardPlayer.on("seek", s => {
      this._vidyardState.duration = s[1];

      if(this._vidyardState.seeking){
        this.trigger("seeked");
      }

      this._vidyardState.seeking = false;
    });

    this.VidyardPlayer.on("volumeChange", v => (this._vidyardState.volume = v));
    this.VidyardPlayer.on("error", e => this.trigger("error", e));

    this.triggerReady();
  }

  createEl() {
    var protocol =
    document.location.protocol === "file:"
      ? "http:"
      : document.location.protocol;

    this.VidyardPlayer = {};
    this.videoId = this.parseUrl(this.options_.source.src);

    var divWrapper = videojs.dom.createEl("div", {
      id: this.options_.techId,
    });

    divWrapper.style.cssText = "width:100%;height:100%;top:0;left:0;position:absolute";
    divWrapper.className = "vjs-vidyard";
   
     this.vidyardScriptElement = videojs.dom.createEl("script", {
      src: protocol + `//play.vidyard.com/${this.videoId}.js`,
      id: `vidyard_embed_code_${this.videoId}`
    });

    divWrapper.insertBefore(this.vidyardScriptElement, divWrapper.firstChild);

    return divWrapper;
  }

  initVidyardState() {
    const state = (this._vidyardState = {
      ended: false,
      playing: false,
      volume: 0,
      duration: 0,
      seeking: false
    });
  }

  pause() {
    this.VidyardPlayer.pause();
  }

  play() {
    this.VidyardPlayer.play();
  }

  playbackRate() {
    return this.suggestedRate ? this.suggestedRate : 1;
  }

  controls() {
    return true;
  }

  supportsFullScreen() {
    return true;
  }

  src() {
    // @note: Not sure why this is needed but videojs requires it
    return this.options_.source;
  }

  currentSrc() {
    return this.options_.source.src;
  }

  currentTime() {
    return this.VidyardPlayer.currentTime();
  }

  volume() {
    return this._vidyardState.volume;
  }

  muted() {
    return this._vidyardState.volume === 0;
  }

  duration() {
    return this._vidyardState.duration;
  }

  paused() {
    return !this._vidyardState.playing;
  }

  ended() {
    return this._vidyardState.ended;
  }

  parseUrl(url) {
    var videoId = null;
    var regex = (regex = /^.*(vidyard\.com\/)([A-z0-9]+\/)([A-z0-9]+)/);
    var match = url.match(regex);

    if (match) {
      videoId = match[3];
    }
    return videoId;
  }  

  dispose () {
    this.VidyardPlayer.off();
    this.el_.parentNode.removeChild(this.el_);
  }


  supportsFullScreen() {
    return document.fullscreenEnabled ||
           document.webkitFullscreenEnabled ||
           document.mozFullScreenEnabled ||
           document.msFullscreenEnabled;
  }
}

Vidyard.prototype.featuresTimeupdateEvents = true;

Vidyard.isSupported = function() {
  return true;
};

// Add Source Handler pattern functions to this tech
Tech.withSourceHandlers(Vidyard);

Vidyard.nativeSourceHandler = {};

/**
 * Check if Vidyard can play the given videotype
 * @param  {String} type    The mimetype to check
 * @return {String}         'maybe', or '' (empty string)
 */
Vidyard.nativeSourceHandler.canPlayType = function(source) {
  if (source === "video/vidyard") {
    return "maybe";
  }

  return "";
};

function loadScript(src) {
  var loaded = false;
  var tag = document.createElement('script');
  var firstScriptTag = document.getElementsByTagName('script')[0];
  firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
  tag.src = src;
}

function injectCss() {
  var css =   `.vjs-vidyard .vjs-big-play-button { display: none !important; }
    .vjs-vidyard .vjs-control-bar { display: none !important; }
    .vjs-vidyard .vjs-loading-spinner { display: none !important; }
    .vjs-vidyard .vidyard_player > span {
    width: 100% !important;
    height: 100% !important;
    margin: 0 auto !important;
    }`;

  var head = document.head || document.getElementsByTagName('head')[0];

  var style = document.createElement('style');
  style.type = 'text/css';

  if (style.styleSheet){
    style.styleSheet.cssText = css;
  } else {
    style.appendChild(document.createTextNode(css));
  }

  head.appendChild(style);
}

if (typeof document !== 'undefined'){
 loadScript('https://play.vidyard.com/v0/api.js');
  injectCss();
}

/*
 * Check Vidyard can handle the source natively
 *
 * @param  {Object} source  The source object
 * @return {String}         'maybe', or '' (empty string)
 * @note: Copied over from YouTube — not sure this is relevant
 */
Vidyard.nativeSourceHandler.canHandleSource = function(source) {
  if (source.type) {
    return Vidyard.nativeSourceHandler.canPlayType(source.type);
  } else if (source.src) {
    return Vidyard.nativeSourceHandler.canPlayType(source.src);
  }

  return "";
};

// @note: Copied over from YouTube — not sure this is relevant
Vidyard.nativeSourceHandler.handleSource = function(source, tech) {
  tech.src(source.src);
};

// @note: Copied over from YouTube — not sure this is relevant
Vidyard.nativeSourceHandler.dispose = () => {
  this.el_.parentNode.removeChild(this.el_);
};



Vidyard.registerSourceHandler(Vidyard.nativeSourceHandler);

if (typeof Tech.registerTech !== "undefined") {
  Tech.registerTech("Vidyard", Vidyard);
} else {
  Component.registerComponent("Vidyard", Vidyard);
}

// Include the version number.
Vidyard.VERSION = "0.0.1";

export default Vidyard;
