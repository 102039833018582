import React, {Fragment} from 'react';
import FileService from 'services/file.service';

import _ from 'lodash';

import Mustache from 'mustache';
import ReactMustache from 'react-mustache';
import dateformat from 'dateformat';

import { stripHtml } from 'services/util';
 
const DefaultAssetIcon = '/images/default_asset.jpg';

export default function AssetTemplateView({template, asset, type}){
  if(!template){
    return false;
  }

  var url = `/${asset.path}`;
  if(asset.viewer === 'html_viewer'){
    url = asset.metadata.url;
  }

  const renderAdditionalInfo = (asset) => {
    var detail = '';

    if(type === 'placeholder_asset') {
      var filetype = _.get(asset, 'metadata.content_type');
 
      switch(filetype) {
        case 'PDF':
        case 'PPTX':
        case 'PPT':
        case 'DOC':
        case 'DOCX':
          detail = '10 Pages';
          break;
        case 'VIDEO':
        case 'YOUTUBE':
        case 'VIMEO':
        case 'WISTIA':
        case 'VIDYARD':
          detail = '2 Minutes';
          break
        default: 
          detail = '';
      }
    }

    if(asset.metadata && asset.metadata.pages){
      detail = `${asset.metadata.pages} Pages`;
    }else if(asset.metadata && asset.metadata.video_duration){
      detail = `${FileService.formatDuration(asset.metadata.video_duration)}`;
    }

    return detail;
  }

  const renderTags = (asset) => {
    var tagsString = "";

    var tags = asset.tags || [];
    tags.forEach((tag, index) => {
      tagsString = tagsString + `#${tag} `;
    });

    return tagsString;
  }

  const processCustomFields = (asset) => {
    var data = {};

    var fields = asset.customFields;
    if(fields == null || fields.length == 0){
      return data;
    }

    fields.forEach(field => {
      var value = field.value.map(o => o.value).join(", ");
      data[field.id]= value;
    });

    return data;
  }

  const processAuthor = (asset) => {

    if (type === 'placeholder_asset') {
      return {
        firstName: 'Owner',
        lastName: '',
        name: 'Owner'
      }
    }

    var firstName = _.get(asset, 'author.firstName', '');
    var lastName = _.get(asset, 'author.lastName', '');
    var name = firstName;
    if(lastName){
      name = `${name} ${lastName}`;
    }

    return {
      firstName: firstName,
      lastName: lastName,
      name: name
    };
  }

  var updatedDate = new Date();
  if(type !== "placeholder_asset") {
    updatedDate = _.get(asset, 'metadata.modified_date') || _.get(asset, 'modifiedDate')
  }

  var fileType = asset.metadata && typeof asset.metadata.content_type != undefined ? FileService.fileType(asset.metadata) : null
  if(type === "placeholder_asset") {
    fileType = _.get(asset, 'metadata.extension', null);
  }

  var summary = stripHtml(asset.summary);
  
  var parameters = {...asset,
    summary: summary,
    thumbnail: asset.icon ? asset.icon.thumbnail : DefaultAssetIcon,
    icon: asset.icon ? asset.icon.full : DefaultAssetIcon,
    url: url,
    type: fileType,
    updatedDate: dateformat(updatedDate, "mmm dd"),
    additionalInfo: renderAdditionalInfo(asset),
    tags: asset.tags && renderTags(asset),
    author: processAuthor(asset),
    customFields: asset.customFields && processCustomFields(asset)
  };

  const compileTemplate = (template, data) => {
    // lazy template compiling
    return Mustache.render(template,data);
  }

  const __html = compileTemplate(template.html, parameters);
  return (
    <Fragment>
      <style type="text/css">{template.styles}</style>
      <div style={{height: '100%'}} dangerouslySetInnerHTML={{__html}}/>
    </Fragment>
  );
}