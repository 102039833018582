import React, { useState, useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { fetchAllGating } from 'actions/gatings';
import { fetchPages } from 'actions/pages';
import { createAlias, updateAlias, deleteAlias } from 'actions/aliases';

import cx from 'classnames';
import _ from 'lodash';

import { Dropdown, Form, FormField, Header, Radio, Search, Transition, Image, Button, Icon, Checkbox } from 'semantic-ui-react';
import Truncate from 'react-truncate';
import InlineConfirmationDialog from 'components/InlineConfirmationDialog';

import styles from './alias.module.scss';

export default function EditAlias({ className, alias, entity, onChange }) {
  const dispatch = useDispatch();
  const editorRef = React.useRef();

  const [state, setState] = useState({
    request: null,
    error: null,
    success: null
  });

  const createNew = _.get(alias, 'id') == null;
  const type = _.get(alias, 'type');
  const creating = useSelector(state => _.get(state, 'aliases.alias.creating'));
  const updating = useSelector(state => _.get(state, 'aliases.alias.updating'));

  const created = useSelector(state => _.get(state, 'aliases.alias.created'));
  const updated = useSelector(state => _.get(state, 'aliases.alias.updated'));

  const saving = creating || updating;
  const deleting = useSelector(state => _.get(state, 'aliases.alias.deleting'));

  const error = useSelector(state => _.get(state, 'aliases.alias.error.errors[0].message'));

  useEffect(() => {
    setState((state) => {
      return {
        ...state,
        request: null
      }
    });
  }, [alias]);

  useEffect(() => {

    var message = null;
    if (created) {
      message = "Alias created successfully";
    } else if (updated) {
      message = "Alias updated successfully";
    }

    setState((state) => {
      return {
        ...state,
        error: error,
        success: message
      }
    });
  }, [error, created, updated]);

  const handleChange = (value) => {
    setState((state) => {
      return {
        ...state,
        request: value
      }
    });
  }

  const validateForm = (request) => {
    const url = _.get(request, 'url');
    const autoCreate = _.get(request, 'autoCreate');
    var aliasRegex = /^[a-z_-]|[`!@#$%^&*()+\=\[\]{};':"\\|,.<>\?~]/;

    if(autoCreate){
      return null;
    }
    
    if (aliasRegex.test(url)) {
      return {
        url: "Please provide valid alias"
      };
    }

    if(!url || url.trim() == ''){
      return {
        url: "Alias cannot be empty"
      };
    }
    
    if(!url.startsWith('/')){
      return {
        url: "Alias must start with /"
      }
    }
  }

  const handleSave = () => {
    const error = validateForm(state.request);
    setState((state) => {
      return {
        ...state,
        validation: error
      }
    });

    if(error){
      return;
    }

    if (alias.id == null) {
      dispatch(createAlias(state.request));
    } else {
      dispatch(updateAlias(alias.id, state.request));
    }
  }

  const handleDelete = () => {
    dispatch(deleteAlias(alias.id));
  }

  const handleReset = () => {
    editorRef.current.reset();
  }

  return (
    <div className={cx(styles.edit, className)}>

      {alias && !alias.canonical && alias.deletable && !createNew &&
        <InlineConfirmationDialog
          message='Are you sure you would like to delete this alias?'
          onConfirm={handleDelete}
        >
          <Button basic primary disabled={deleting}
            className={styles.delete}>
            {deleting ? 'DELETING...' : 'DELETE'}
          </Button>
        </InlineConfirmationDialog>
      }

      {
        {
          'asset': <EditAssetAlias ref={editorRef} alias={alias} entity={entity} validationErrors={state.validation} onChange={handleChange} />,
          'page': <EditPageAlias ref={editorRef} alias={alias} entity={entity} validationErrors={state.validation} onChange={handleChange} />,
          'interactive_content': <EditInteractiveContentAlias ref={editorRef} alias={alias} entity={entity} validationErrors={state.validation} onChange={handleChange} />
        }[type]
      }

      {!type &&
        <div>Please select an alias to edit</div>
      }

      {state.error &&
        <ToastMessage title="Error!" message={state.error} onClose={() => {
          setState((state) => {
            return {
              ...state,
              error: null
            }
          });
        }} />
      }

      {state.success &&
        <ToastMessage title="Success!" success message={state.success} onClose={() => {
          setState((state) => {
            return {
              ...state,
              success: null
            }
          });
        }} />
      }

      {type &&
        <div className={styles.buttonTray}>
          <Button basic primary
            disabled={state.request == null || saving}
            onClick={handleSave}>
            {saving ? 'Saving Changes...' : 'Save Changes'}
          </Button>
          <Button basic disabled={saving}
            onClick={handleReset}>
            Reset
          </Button>
        </div>
      }
    </div>
  );
}

const EditAssetAlias = React.forwardRef(({ entity, alias, validationErrors, onChange }, ref) => {
  const initialAliasContext = (data) => {
    if (!data) {
      return {};
    }

    return {
      id: data.id,
      url: {
        value: data.url,
        originalValue: data.url,
        autoCreate: data.autoCreate,
        error: null
      },
      canonical: {
        value: data.canonical,
        originalValue: data.canonical
      },
      gating: {
        enabled: data.gating != null,
        value: data.gating
      },
      page: {
        enabled: data.page != null,
        value: data.page
      }
    }
  }

  const [state, setState] = useState({
    context: initialAliasContext(alias)
  });

  useEffect(() => {
    const id = _.get(state, 'context.id');
    if (alias) {
      setState((state) => {
        return {
          ...state,
          context: initialAliasContext(alias)
        }
      })
    }
  }, [alias]);

  React.useImperativeHandle(ref, () => ({
    reset: () => {
      setState((state) => {
        return {
          ...state,
          context: initialAliasContext(alias)
        }
      })
    }
  }));

  const handleChange = (updated) => {
    const value = {
      ...state.context,
      ...updated
    }

    setState((state) => {
      return {
        ...state,
        context: value
      }
    });

    const request = {
      id: _.get(value, 'id'),
      type: 'asset',
      target: entity.id,
      url: _.get(value, 'url.value'),
      autoCreate: _.get(value, 'url.autoCreate'),
      canonical: _.get(value, 'canonical.value')
    }

    if (_.get(value, 'gating.enabled')) {
      request['gating'] = _.get(value, 'gating.value.id');
    }

    if (_.get(value, 'page.enabled')) {
      request['page'] = _.get(value, 'page.value.id');
    }

    onChange(request);
  }

  return (
    <>
      <h2 className={styles.heading}>Edit Asset Url</h2>
      <div className={styles.asset}>
        <Form>
          <UrlInput value={_.get(state, 'context.url')} errors={validationErrors} onChange={handleChange} />
          <CanonicalControl value={_.get(state, 'context.canonical')} onChange={handleChange} />
          <GatingControl value={_.get(state, 'context.gating')} onChange={handleChange} />
          <PageControl value={_.get(state, 'context.page')} onChange={handleChange} />
        </Form>
      </div>
    </>
  );
});


const EditPageAlias = React.forwardRef(({ entity, alias, validationErrors, onChange }, ref) => {
  const initialAliasContext = (data) => {
    if (!data) {
      return {};
    }

    return {
      id: data.id,
      url: {
        value: data.url,
        originalValue: data.url,
        autoCreate: data.autoCreate
      },
      canonical: {
        value: data.canonical,
        originalValue: data.canonical
      }
    }
  }

  const [state, setState] = useState({
    context: initialAliasContext(alias)
  });

  useEffect(() => {
    const id = _.get(state, 'context.id');
    if (alias) {
      setState((state) => {
        return {
          ...state,
          context: initialAliasContext(alias)
        }
      })
    }
  }, [alias]);

  React.useImperativeHandle(ref, () => ({
    reset: () => {
      setState((state) => {
        return {
          ...state,
          context: initialAliasContext(alias)
        }
      })
    }
  }));

  const handleChange = (updated) => {
    const value = {
      ...state.context,
      ...updated
    }

    setState((state) => {
      return {
        ...state,
        context: value
      }
    });

    const request = {
      id: _.get(value, 'id'),
      type: 'page',
      target: entity.id,
      url: _.get(value, 'url.value'),
      autoCreate: _.get(value, 'url.autoCreate'),
      canonical: _.get(value, 'canonical.value')
    }

    onChange(request);
  }

  return (
    <>
      <h2 className={styles.heading}>Edit Page Url</h2>
      <div className={styles.asset}>
        <Form>
          <UrlInput value={_.get(state, 'context.url')} errors={validationErrors} onChange={handleChange} />
          <CanonicalControl value={_.get(state, 'context.canonical')} onChange={handleChange} />
        </Form>
      </div>
    </>
  );
});


const EditInteractiveContentAlias = React.forwardRef(({ entity, alias, validationErrors, onChange }, ref) => {
  const initialAliasContext = (data) => {
    if (!data) {
      return {};
    }

    return {
      id: data.id,
      url: {
        value: data.url,
        originalValue: data.url,
        autoCreate: data.autoCreate
      }
    }
  }

  const [state, setState] = useState({
    context: initialAliasContext(alias)
  });

  useEffect(() => {
    const id = _.get(state, 'context.id');
    if (alias) {
      setState((state) => {
        return {
          ...state,
          context: initialAliasContext(alias)
        }
      })
    }
  }, [alias]);

  React.useImperativeHandle(ref, () => ({
    reset: () => {
      setState((state) => {
        return {
          ...state,
          context: initialAliasContext(alias)
        }
      })
    }
  }));

  const handleChange = (updated) => {
    const value = {
      ...state.context,
      ...updated
    }

    setState((state) => {
      return {
        ...state,
        context: value
      }
    });

    const request = {
      id: _.get(value, 'id'),
      type: 'page',
      target: entity.id,
      url: _.get(value, 'url.value'),
      autoCreate: _.get(value, 'url.autoCreate'),
    }

    onChange(request);
  }

  return (
    <>
      <h2 className={styles.heading}>Edit Interactive Content Url</h2>
      <div className={styles.asset}>
        <Form>
          <UrlInput value={_.get(state, 'context.url')} errors={validationErrors} onChange={handleChange} />
        </Form>
      </div>
    </>
  );
});

function UrlInput({ value, errors, onChange }) {
  const handleUrlChange = (e) => {
    const url = e.target.value;
    onChange({
      url: {
        ...value,
        value: url
      }
    });
  }

  const handleAutoGenerate = () => {
    const autoCreate = _.get(value, 'autoCreate');
    const originalValue = _.get(value, 'originalValue');

    onChange({
      url: {
        ...value,
        autoCreate: !autoCreate,
        value: originalValue
      }
    });
  }

  return (
    <Form.Group className={styles.url} grouped>
      <Form.Field>
        <label className={styles.name}>URL</label>
        <input placeholder='Please enter the alias url'
          value={_.get(value, 'value') || ''}
          disabled={_.get(value, 'autoCreate')}
          onChange={handleUrlChange}
        />
        {_.get(errors, 'url') && 
          <span className={styles.error}>{_.get(errors, 'url')}</span>
        }
      </Form.Field>
      <Form.Field className={styles.autogenerate}>
        <Checkbox
          label='Autogenerate url from name'
          checked={_.get(value, 'autoCreate')}
          onChange={handleAutoGenerate}
        />
      </Form.Field>
    </Form.Group>
  );
}

function CanonicalControl({ value, onChange }) {
  const canonicalValue = _.get(value, 'value');
  const originalValue = _.get(value, 'originalValue');

  const handleToggle = () => {
    onChange({
      canonical: {
        ...value,
        value: !canonicalValue
      }
    });
  }

  if (originalValue) {
    return (
      <Form.Group className={cx(styles.canonical, styles.switch)} grouped>
        <Form.Field inline>
          <label className={styles.name}>Current Alias is canonical</label>
        </Form.Field>
        <label className={styles.current}>This alias will be used as a default url</label>
      </Form.Group>
    );
  }

  return (
    <Form.Group className={cx(styles.canonical, styles.switch)} grouped>
      <Form.Field inline>
        <label className={styles.name}>Mark as canonical</label>
        <Radio toggle checked={canonicalValue} disabled={originalValue} onChange={handleToggle} />
      </Form.Field>
      {/*
        <label className={styles.current}><b>Current:</b> /sample-page/a-very-long-alias-for-a-very-long-asset-name-is-the-generated-from-the-name-of-the-asset</label>
      */}
    </Form.Group>
  )
}

function GatingControl({ value, onChange }) {
  const dispatch = useDispatch();
  const fetchedGating = React.useRef(false);

  const gatings = useSelector(state => state.gatings.items);
  const loadingGating = useSelector(state => state.gatings.loading) && _.size(gatings) == 0;
  const gatingOptions = (gatings || []).map(item => {
    return {
      key: item.id,
      value: item.id,
      text: item.name
    }
  });

  const enabled = _.get(value, 'enabled');
  const gating = _.get(value, 'value.id') || '';

  useEffect(() => {
    if (enabled && !fetchedGating.current) {
      dispatch(fetchAllGating());
      fetchedGating.current = true;
    }
  }, [enabled]);

  const _handleToggle = (e) => {
    onChange({
      gating: {
        ...value,
        enabled: !enabled
      }
    });
  }

  const _handleGatingChanged = (data) => {

    const gatingValue = (gatings || []).find(item => item.id === data);

    onChange({
      gating: {
        ...value,
        value: gatingValue
      }
    });
  }


  return (
    <Form.Group className={cx(styles.gating, styles.switch)} grouped>
      <Form.Field inline>
        <label className={styles.name}>Enable gating for asset</label>
        <Radio toggle checked={enabled} onChange={_handleToggle} />
      </Form.Field>

      {enabled &&
        <FormField>
          <Dropdown className={styles.dropdown}
            placeholder={loadingGating ? 'Fetching gating...' : 'Choose a gating'}
            disabled={loadingGating}
            selection
            clearable
            options={gatingOptions}
            value={gating}
            onChange={(e, data) => {
              _handleGatingChanged(data.value);
            }}
          >

          </Dropdown>
        </FormField>
      }
    </Form.Group>
  );
}

function PageControl({ value, onChange }) {

  const enabled = _.get(value, 'enabled');
  const page = _.get(value, 'value');

  const dispatch = useDispatch();
  const searching = useSelector(state => state.pages.loading);
  const pages = useSelector(state => state.pages.items);

  const handleSearchChange = (e, { value }) => {
    dispatch(fetchPages(value, 0, 20, "name", "ASC"));
    //onInputChange(value);
  }

  const handlePageSelect = (e, { result }) => {
    if (!result) {
      return;
    }

    const page = {
      id: result.id,
      name: result.name,
      icon: result.icon,
      alias: result.alias
    }

    onChange({
      page: {
        ...value,
        value: page
      }
    });
  }

  const handlePageRemove = () => {
    onChange({
      page: {
        ...value,
        value: null
      }
    });
  }

  const _handleToggle = (e) => {
    onChange({
      page: {
        ...value,
        enabled: !enabled
      }
    });
  }

  return (
    <Form.Group className={cx(styles.pages, styles.switch)} grouped>
      <Form.Field inline>
        <label className={styles.name}>Map asset with page</label>
        <Radio toggle checked={enabled} onChange={_handleToggle} />
      </Form.Field>

      {enabled && !page &&
        <FormField>
          <Search className={styles.search}
            loading={searching}
            icon={null}
            placeholder='Type something'
            onSearchChange={_.debounce(handleSearchChange, 500, {
              leading: true,
            })}
            onResultSelect={handlePageSelect}
            results={pages}
            resultRenderer={(result) => {
              return (
                <div className={styles.page}>
                  <Image src={_.get(result, 'icon.thumbnail') || '/images/default_asset.jpg'} />
                  <div className={styles.details}>
                    <div className={styles.pageName}><Truncate lines={2}>{result.name}</Truncate></div>
                    <div className={styles.pageUrl}>{result.alias}</div>
                  </div>
                </div>
              )
            }}
          />
        </FormField>
      }

      {enabled && page &&
        <div className={styles.selectedPage}>
          <Image src={_.get(page, 'icon.thumbnail') || '/images/default_asset.jpg'} />
          <div className={styles.details}>
            <div className={styles.pageName}><Truncate lines={2}>{page.name}</Truncate></div>
            <div className={styles.pageUrl}>{page.alias}</div>
          </div>
          <Button onClick={handlePageRemove}><Icon name='remove' /></Button>
        </div>
      }

    </Form.Group>
  );
}

function ToastMessage({ success, title, message, onClose }) {
  return (
    <div className={cx(styles.toast, {
      [styles.success]: success
    })}>
      <Button className={styles.close} onClick={onClose}>
        <Icon name='close' />
      </Button>
      <h5>
        <Icon name={success ? 'check circle' : "warning circle"} />
        {title}
      </h5>
      <p>{message}</p>
    </div>
  );
}