import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchJourneys, createJourney } from 'actions/journey';

import {
  NavLink,
  Route,
  Switch,
  Link,
  useParams,
  useHistory, useRouteMatch
} from "react-router-dom";

import {
  Grid,
  Segment,
  Icon,
  Input,
  Menu,
  Label,
  Header,
  Table,
  Visibility,
  List
} from 'semantic-ui-react';

import styles from './journey.module.scss';

import _ from 'lodash';
import cx from 'classnames';
import dateformat from 'dateformat';

import Tooltip from 'components/Tooltip';
import JourneyDetailView from './detail';
import journeysSaga from 'actions/journey';

export default function JourneyView(props) {
  let { path } = useRouteMatch();
  const dispatch = useDispatch();
  
  useEffect(() => {
    dispatch(fetchJourneys(0, 20));
  }, []);

  return (
    <Switch>
      <Route>
        <Grid columns="equal" stretched divided='vertically' className={cx(styles.journeys)}>
          <Grid.Row>
            <Grid.Column width={4}>
              <JourneyList />
            </Grid.Column>
            <Grid.Column>
              <Switch>
                <Route path={`${path}/:id`} exact>
                  <JourneyDetailView />
                </Route>
              </Switch>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Route>
    </Switch>
  )
}

function JourneyList(){
  const history = useHistory();
  const dispatch = useDispatch();

  const {id} = useParams();
  const { path, url } = useRouteMatch();
  const createJourneyState = useSelector(state => state.journeys.create);
  const loading = useSelector(state => state.journeys.loading);
  const journeys = useSelector(state => state.journeys.items);
  const journey = useSelector(state => state.journeys.journey);
  const pagination = useSelector(state => state.journeys.pagination);
  const currentPage = _.get(pagination, 'current');
  const totalPages = _.get(pagination, 'totalPages');
  const totalRecordsCount = _.get(pagination, 'totalRecordsCount', 0);

  const handleCreate = () => {
    var request = {
      name: 'Untitled'
    }

    dispatch(createJourney(request, (res) => {
      history.push(`${url}/${res.id}`);
    }));
  }

  //On create
  useEffect(() => {
    if(createJourneyState.item){
      history.push(`${url}/${createJourneyState.item.id}`);
    }
  }, [createJourneyState.item]);

  //On load or delete
  useEffect(() => {
    if(!journey.item){
      if(journeys.length > 0){
        var next = journeys[0].id;
        history.replace(`${url}/${next}`);
      }
      
    }
  }, [journeys.length, journey.item, id]);

  const loadMoreJourneys = () => {
    if (loading || currentPage === totalPages) {
      return;
    }

    dispatch(fetchJourneys(currentPage + 1, 20));
  }

  return (
    <Segment basic className={styles.list}>
      <Menu secondary>
        <Menu.Item header as="h2">Journey <Label>{totalRecordsCount}</Label></Menu.Item>
        <Menu.Menu position='right'>
          <Tooltip inverted content={'Create new journey'} position="top right">
            <Menu.Item icon='add' onClick={handleCreate} disabled={createJourneyState.creating}/>
          </Tooltip>  
        </Menu.Menu>
      </Menu>
      
      <Visibility onBottomVisible={loadMoreJourneys} continuous fireOnMount={false}>
        <List selection celled verticalAlign='middle'>
          {journeys.map(journey => {
            return <JourneyItem key={journey.id} journey={journey} />
          })}


          {!loading && journeys.length == 0 &&
            <div className={styles.empty}>No journeys</div>
          }
        </List>
      </Visibility>
    </Segment>
  );
}

function JourneyItem({journey}){
  const { path, url } = useRouteMatch();
  return (
    <List.Item as={NavLink} to={`${url}/${journey.id}`}>
      <i aria-hidden="true" className={cx("icon-journey", "big", "icon", "middle", "aligned")}></i>
      <List.Content>
        <List.Header>{journey.name}</List.Header>
        <List.Description>
          Last updated on {dateformat(journey.modifiedDate, 'dd mmmm')}
        </List.Description>
      </List.Content>
    </List.Item>
  );
}