import React, { useEffect, useState } from 'react';
import cx from 'classnames';
import Measure from 'react-measure';

import {AreaChart, Area, XAxis, Tooltip} from 'recharts';
import styles from '../Analytics.module.scss';


export default function VideoAnalyticsView(props) {

  const [segments, setSegments] = useState([])
  const [chart, setChart] = useState({width: 250, visible: false, height: 150})
  const [duration, setDuration] = useState(0)

  const [activeSegment, setActiveSegment] = useState({
    index: 0,
    totalTimeSpent: 0,
    count: 0
  })

  const [segmentLength, setSegmentLength] = useState(props.asset.metadata.video_segment_length || 5)
  const [ activeIndex, setActiveIndex] = useState(0)
  const [ seekTime, setSeekTime] = useState(0)

  const processAnalytics = (analytics) => {
    if(!analytics || analytics.dataPoints.length == 0){
      return [];
    }

    var totalDuration = props.asset.metadata.video_duration || 0;

    if(totalDuration == 0){
      var lastSegment = analytics.dataPoints[analytics.dataPoints.length - 1];
      totalDuration = lastSegment.index + segmentLength;
    }

    var segmentCounts = Math.ceil(totalDuration / segmentLength);
    segmentCounts = Math.max(segmentCounts, analytics.dataPoints.length);

    var segments = []
    for (var i = 0; i < segmentCounts; i++) {
      var segment = {
        index: i * segmentLength,
        totalTimeSpent: 0.0,
        count: 0
      }

      segments.push(segment);
    }

    analytics.dataPoints.forEach((element) => {
      var index = segments.findIndex(item => {
        return item.index == element.index;
      });

      if(index != -1){
        segments[index] = element;
      }

    })

    setSegments(segments)
    setDuration(totalDuration)
    setSegmentLength(segmentLength)
  }


  const renderToolTip = (o) => {
    return false;
  }

  const _handleChartMouseOut = () => {
    setActiveIndex(0)
    setActiveSegment(null)
    setSeekTime(0)
  }

const _handleChartMouseMove = (data, index) => {
  if(!(data.activeCoordinate && data.activePayload)){
    return;
  }

  var seek = data.activeCoordinate.x / chart.width;
  var totalDuration = duration;
  seek = seek * totalDuration;

  setActiveIndex(index)
  setSeekTime(seek)
  setActiveSegment(data.activePayload[0].payload)
}

useEffect(() => {
  const analytics = props.analytics;

  if(analytics && analytics.dataPoints){
    processAnalytics(analytics);
  }

}, [])

  if(segments.length == 0 || props.analytics.type != 'VIDEO_ANALYTICS'){
    return null;
  }

  let segmentCounts = segments.length;
  let width = chart.width;
  let height = 150;

  let timeSpent = activeSegment? activeSegment.index : 0;
  let views = activeSegment ? padNumber(activeSegment.count, 2) : 0;

  return (
    <Measure bounds >
      {({measureRef}) =>
        <div ref={measureRef} className={styles.assetViewChart}>
          <>
            <div>
              <div className={cx(styles.metrics, styles.page)}>
                {`Time #: ${formatTimeSpent(timeSpent)}`}
              </div>
              <div className={cx(styles.metrics, styles.timeSpent)}>
                {`Views: ${views}`}
              </div>
            </div>
            <AreaChart width={width} height={height} 
                data={segments} 
                onMouseMove={_handleChartMouseMove}
                onMouseLeave={_handleChartMouseOut}
            >
              <XAxis dataKey="index" minTickGap={30} interval={'preserveStartEnd'} tickFormatter={formatTimeSpent}/>  
              <Tooltip content={renderToolTip} />
        
              <Area
                type="monotone"
                dataKey='count'
                yAxisId='count'
                fill='#7f7f7f'
                stroke='#000'
                strokeWidth={2}
                >
              </Area>
              
            </AreaChart>
          </>
        </div>
      }
    </Measure>  
  )

}

function formatTimeSpent(timeSpent){
  let minutes = padNumber(Math.floor(timeSpent / 60), 2);
  let seconds = padNumber(timeSpent % 60, 2);

  return `${minutes}:${seconds}`;
}

function padNumber(number=0, pad){
  number = Math.ceil(number);
  
  const length = pad-String(number).length+1;
  let padding = '';
  if(length>0){
    padding = Array(pad-String(number).length+1).join('0');
  }
  return padding + number;
}