import React, { useState, Fragment, createRef, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import _ from 'lodash';
import { AddContent, useContentSelection } from 'components/Asset/AddContent';
import { createContentEditor, invalidateContentEditor, savingContentInContentEditor } from 'actions/contentSelection';
import { fetchJourneyStep, updateJourney, createJourneyStep, updateJourneyStep } from 'actions/journey';


import { guid } from 'services/util';

const defaultContent = {
  type: null,
  list: null,
  filters: [],
  assets: [],
  all: false
}

export function ContentModal({ trigger, journey, step, ...rest }) {
  const dispatch = useDispatch();

  const [contentEditor, setContentEditor] = useState(null);

  const contentSelection = useContentSelection(contentEditor);

  const fetchedStep = useSelector(state => {
    if(!step){
      return false;
    }

    if(rest.start){
      return _.get(step, 'fetched');
    }

    if(step) {
      var steps = state.journeys.journey.item.steps;
      
      var stepValue = steps.find(item => {
        return item.id === step.id;
      })

      return _.get(stepValue, 'fetched');
    }

    return false;
  });
  

  useEffect(() => {
    if ((step && !fetchedStep) || !contentEditor) {
      return;
    }

    var content = _.get(step, "content", defaultContent);
    dispatch(createContentEditor(contentEditor, content));
  }, [fetchedStep, contentEditor]);

  const handleStepStateChanged = () => {
    if (contentEditor) {
      dispatch(invalidateContentEditor(contentEditor));
      setContentEditor(null)
    }
  }

  const handleSave = () => {
    dispatch(savingContentInContentEditor(contentEditor));

    let type = contentSelection.type;

    let updatedFilters = [];
    let assetsRequest = [];

    if (type === 'cluster') {
      updatedFilters = (contentSelection.filters || []).map(filter => {
        var property = filter.property;
        var operator = filter.operator;
        var value = filter.value;

        if (filter.property.type === 'asset') {
          value = (filter.value || []).map(item => item.id);
        }

        return {
          property: property,
          operator: operator,
          value: value
        }
      });
    }

    if (type === 'asset') {
      assetsRequest = (contentSelection.assets || []).map(asset => {
        return asset.id
      })
    }

    
    if(step && step.id) {
      dispatch(updateJourneyStep(journey.id, step.id,
        {
          content: {
            type: type,
            filters: updatedFilters,
            list: contentSelection.list,
            all: contentSelection.all,
            assets: assetsRequest
          }
        },
        (response) => {
          handleStepStateChanged();
        }
      ))
    } else {
      dispatch(createJourneyStep(journey.id, 
        {
          start: rest.start,
          content: {
            type: type,
            filters: updatedFilters,
            list: contentSelection.list,
            all: contentSelection.all,
            assets: assetsRequest
          }
        },
        (response) => {
          handleStepStateChanged();
        }
      ))
    }
  }

  const handleEditContent = () => {
    var contentEditor = guid();
    setContentEditor(contentEditor);

    if (step && !fetchedStep) {
      dispatch(fetchJourneyStep(journey.id, step.id));
    }
  }

  return (
    <>
      {React.cloneElement(trigger, {
        key: "content_trigger",
        onClick: () => {
          handleEditContent();
        }
      })}

      <AddContent expanded={contentEditor !== null}
        editor={contentEditor}
        content={contentSelection}
        onSave={handleSave}
        onClose={handleStepStateChanged}
        sortable={true}
        deletable={true}
        editable={false}
      />
    </>
  );

}