import {
  FETCH_ALL_GATING_REQUEST, FETCH_ALL_GATING_SUCCEEDED, FETCH_ALL_GATING_FAILED,
  FETCH_GATING_REQUEST, FETCH_GATING_SUCCEEDED, FETCH_GATING_FAILED,
  CREATE_GATING_REQUEST, CREATE_GATING_SUCCEEDED, CREATE_GATING_FAILED,
  UPDATE_GATING_REQUEST, UPDATE_GATING_SUCCEEDED, UPDATE_GATING_FAILED, 
  DELETE_GATING_REQUEST, DELETE_GATING_SUCCEEDED, DELETE_GATING_FAILED
} from 'constants/actionTypes';

import { call, put, takeLatest } from 'redux-saga/effects';
import axios from 'axios';
import { createAction } from 'redux-actions';
import buildUrl from 'build-url'; 

const apiUrl = `${process.env.REACT_APP_API_URL}/api/3`;
const GATING_URL = `${apiUrl}/gating`;

/*
* fetch all gating
*/
export const fetchAllGating = createAction(FETCH_ALL_GATING_REQUEST);

const fetchAllGatingSucceeded = createAction(FETCH_ALL_GATING_SUCCEEDED, response => {
  return response;
})

const fetchAllGatingFailed = createAction(FETCH_ALL_GATING_FAILED, error => {
  return error;
})

function* fetchAllGatingSaga(action) {

  const fetchAllGatingAPI = () => {
    return axios.get(GATING_URL).then(response => response.data);
  }

  try {
    const response = yield call(fetchAllGatingAPI);
    yield put(fetchAllGatingSucceeded(response));
  } catch (error) {
    yield put(fetchAllGatingFailed(error));
  }
}

/*
* fetch gating for {id}
*/
export const fetchGating = createAction(FETCH_GATING_REQUEST, id => {
  return id;
})

const fetchGatingSucceeded = createAction(FETCH_GATING_SUCCEEDED, (id, gating) => {
  return {
    id: id,
    gating: gating
  }
})

const fetchGatingFailed = createAction(FETCH_GATING_FAILED, (id, error) => {
  return {
    id: id, 
    error: error
  }
})

function* fetchGatingSaga(action) {
  const id = action.payload;

  let url = buildUrl(GATING_URL, {
    path: id
  });

  const fetchGatingAPI = () => {
    return axios.get(url).then(response => response.data);
  }

  try {
    const response = yield call(fetchGatingAPI);
    yield put(fetchGatingSucceeded(id, response));
  } catch (error) {
    yield put(fetchGatingFailed(id, error));
  }
}

/*
* Create gating
*/
export const createGating = createAction(CREATE_GATING_REQUEST, request => {
  return request;
})

const createGatingSucceeded = createAction(CREATE_GATING_SUCCEEDED, gating => {
  return gating;
})

const createGatingFailed = createAction(CREATE_GATING_FAILED, error => {
  return error;
})

function* createGatingSaga(action) {
  const data = action.payload;

  const createGatingAPI = () => {
    return axios.post(GATING_URL, data).then(response => response.data);
  }

  try {
    const response = yield call(createGatingAPI);
    yield put(createGatingSucceeded(response));
  } catch (error) {
    yield put(createGatingFailed(error));
  }
}

/*
* Update gating for {id}
*/
export const updateGating = createAction(UPDATE_GATING_REQUEST, (id, request) => {
  return {
    id: id, 
    request: request
  }
})

const updateGatingSucceeded = createAction(UPDATE_GATING_SUCCEEDED, (id, gating) => {
  return {
    id: id,
    gating: gating
  }
})

const updateGatingFailed = createAction(UPDATE_GATING_FAILED, (id, error) => {
  return {
    id: id,
    error: error
  }
})

function* updateGatingSaga(action) {
  const { id, request } = action.payload;

  let url = buildUrl(GATING_URL, {
    path: id
  });

  const updateGatingAPI = () => {
    return axios.put(url, request).then(response => response.data);
  }

  try {
    const response = yield call(updateGatingAPI);
    yield put(updateGatingSucceeded(id, response));
  } catch (error) {
    yield put(updateGatingFailed(id, error));
  }
}

/*
* Delete gating for {id}
*/
export const deleteGating = createAction(DELETE_GATING_REQUEST, id => {
  return id;
})

const deleteGatingSucceeded = createAction(DELETE_GATING_SUCCEEDED, (id, gating) => {
  return {
    id: id,
    gating: gating
  }
})

const deleteGatingFailed = createAction(DELETE_GATING_FAILED, (id, error) => {
  return {
    id: id,
    error: error
  }
})

function* deleteGatingSaga(action) {
  const id = action.payload;

  let url = buildUrl(GATING_URL, {
    path: id
  });

  const deleteGatingAPI = () => {
    return axios.delete(url).then(response => response.data);
  }

  try {
    const response = yield call(deleteGatingAPI);
    yield put(deleteGatingSucceeded(id, response));
  } catch (error) {
    yield put(deleteGatingFailed(id, error));
  }
}

export default function* gatingSaga() {
  yield takeLatest(FETCH_ALL_GATING_REQUEST, fetchAllGatingSaga);
  yield takeLatest(FETCH_GATING_REQUEST, fetchGatingSaga);
  yield takeLatest(CREATE_GATING_REQUEST, createGatingSaga);
  yield takeLatest(UPDATE_GATING_REQUEST, updateGatingSaga);
  yield takeLatest(DELETE_GATING_REQUEST, deleteGatingSaga);
};