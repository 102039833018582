import React, {useState, Fragment, useRef, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import { updateProperties, updatingWidgetContentSucceeded, updateWidget, createEditorContext, deleteWidget, mergeWidgetEditors} from 'actions/widgetEditor';
import { fetchForms } from 'actions/forms';

import _ from 'lodash';
import cx from 'classnames';
import { guid, validateEmail } from 'services/util';

import {
  Button as SemanticButton,
  Modal,
  Icon,
  Input,
  Label
} from 'semantic-ui-react';

import Animation from 'components/Animation';
import Truncate from 'react-truncate';
import Tooltip from 'components/Tooltip';
import { Editor } from '@tinymce/tinymce-react';
import { Widget, getStyles, getSpacingStyles, getBorderStyles, getBackgroundStyles } from '../widget.jsx';
import { WidgetRegistry } from '../registry.jsx';
import { Property } from 'components/Properties';
import { QuickSettings, QuickSettingsProperty } from 'components/QuickSettings';

import styles from './button.module.scss';
import { WidgetsEditor, WidgetContext } from "pages/pages/editor/editor";
import { useWidgetDrop } from "services/dnd.service";
import { denormalise } from 'services/widget.helper.jsx';
import { ColumnResizer } from '../resizable.jsx';
import { defaultFonts } from 'constants/fonts.jsx';
import { style } from 'd3';

const contactFormPopup = [{
  type: 'widgets.modal',
  name: 'Modal',
  capabilities: {
    "draggable": false,
    "droppable": false,
    "editable": false,
    "resizable": false
  },
  widgets: [{
    type: 'widgets.form',
    name: 'Contact Form',
    capabilities: {
      "draggable": false,
      "droppable": true,
      "editable": true,
      "resizable": false
    },
    widgets: [{
      type: 'widgets.form.step',
      name: 'Default',
      widgets: [{
        type: 'layout.row',
        name: 'Row',
        capabilities: {
          "draggable": false,
          "droppable": true,
          "editable": false,
          "resizable": false
        },
        widgets: [{
          type: 'layout.column',
          name: 'Column',
          capabilities: {
            "draggable": false,
            "droppable": true,
            "editable": false,
            "resizable": false
          },
          properties: {
            padding: {
              top: '0px',
              right: '0px',
              bottom: '15px',
              left: '0px'
            }
          },
          widgets: [{
            type: 'widgets.text',
            name: 'Text',
            content: '<h1 style="margin: 0px;">Connect with us</h1><p>Please enter a valid business email and we&rsquo;ll get back to you shortly.</p>',
            properties: {
              
            },
          }]
        }] 
      },{
        type: 'widgets.form.input',
        name: 'Email',
        properties: {
          id: 'email',
          label: 'Email',
          placeholder: 'Enter a valid email'
        }
      },{
        type: 'widgets.form.button',
        name: 'Submit Button',
        content: 'Submit'
      }]   
    },{
      type: 'widgets.form.step',
      name: 'Success',
      properties: {
        success: true
      },
      widgets: [{
        type: 'layout.row',
        name: 'Row',
        capabilities: {
          "draggable": false,
          "droppable": true,
          "editable": false,
          "resizable": false
        },
        widgets: [{
          type: 'layout.column',
          name: 'Column',
          capabilities: {
            "draggable": false,
            "droppable": true,
            "editable": false,
            "resizable": false
          },
          properties: {
            padding: {
              top: '15px',
              right: '30px',
              bottom: '15px',
              left: '30px'
            }
          },
          widgets: [{
            type: 'widgets.text',
            name: 'Text',
            content: '<h1>Thank you</h1><p>Our team will reachout to you shorly.</p>'
          }]
        }]
      }]
    }]
  }]
}];

function getButtonStyles(properties){
  var styles = {};

  if(!properties){
    return styles;
  }

  var border = getBorderStyles(properties.border);
  if(border){
    styles = {
      ...styles,
      ...border
    };
  }

  const padding = getSpacingStyles('padding', properties.padding);
  if(padding){
    styles = {
      ...styles,
      ...padding
    };
  }

  const background = getBackgroundStyles(properties.background);
  if(background){
    styles = {
      ...styles,
      ...background
    };
  }

  return styles;
}

export const Button = (props) => {
  const ref = useRef(null);
  const dropRef = useWidgetDrop(props, ref, {
    
  });

  const dispatch = useDispatch();
  const properties = props.properties;

  const handleEditorChange = (content, editor) => {
    dispatch(updatingWidgetContentSucceeded(props.id, content, props.editor));
  }

  const marginStyle = getSpacingStyles('margin', properties.margin) || {};
  const buttonStyles = getButtonStyles(properties);

  const wrapWithAnimation = (component, editable) => {
    if(editable){
      return component;
    }

    return (
      <Animation {...(properties.animation || {})}>
        {component}
      </Animation>
    );
  }

  return (
    <div className={cx(props.className, styles.button)}
      id={`widget_${props.id}`}  
      ref={props.dragRef(dropRef(ref))}
      onMouseOver={props.onMouseOver}
      onMouseOut={props.onMouseOut}
      onClick={props.onClick}
      style={marginStyle}
    >
      {
        wrapWithAnimation(
          <SemanticButton 
            style={buttonStyles}
            as='div'
          >
            <ButtonTextEditor value={props.content} disabled={!props.editable} onChange={handleEditorChange} />
          </SemanticButton>, props.editable
        )
      }

      {props.children}
      
      {props.selected &&
        <ColumnResizer item={props} editor={props.editor} />
      }
    </div>
  );
}

function ButtonTextEditor({value, disabled, onChange}) {
  var selection_toolbar = 'bold italic | alignleft aligncenter alignright alignjustify | formatselect | numlist bullist | fontselect | fontsizeselect | forecolor backcolor | code';

  const customFonts = useSelector(state => _.get(state, 'storyboards.storyboard.item.branding.fonts') || []);
  const customfontStyle = useSelector(state => _.get(state, 'storyboards.storyboard.item.branding.customStyleSheet') || '');

  const fonts = () => {
    return [...customFonts, ...defaultFonts].map(font => {
      return `${font.name}=${font.fontFamily}`
    }).join(';');
  };

  return (
    <Editor
      initialValue={value}
      inline={true}
      disabled={disabled}
      init={{
        plugins: ['quickbars', 'autoresize', 'code', 'paste', 'lists advlist'],
        menubar: false,
        toolbar: false,
        quickbars_insert_toolbar: 'h1 P | hr pagebreak',
        quickbars_selection_toolbar: selection_toolbar,
        browser_spellcheck : true,
        autoresize_bottom_margin: 50,
        autoresize_overflow_padding: 50,
        min_height: 100,
        font_formats: fonts(),
        content_css: [
          customfontStyle
        ]
      }}
      onEditorChange={onChange}
    />
  );
}

function ButtonQuickSettings(props) {
  const dispatch = useDispatch();

  const [selected, setSelected] = useState('action');

  const menuItems = [
    { id: 'action', title: 'Action', icon: 'icon-layout' },
    { id: 'spacing', title: 'Spacing', icon: 'icon-spacing' },
    { id: 'background', title: 'Background', icon: 'icon-background' },
    { id: 'border', title: 'Border', icon: 'icon-border' },
    { id: 'animation', title: 'Animation', icon: 'icon-spacing'},
    { id: 'advanced', title: 'Advanced', icon: 'icon-custom' }
  ]

  const handleMenuClick = (item) => {
    setSelected(item);
  }

  var editorContext = useSelector(state => state.widgetsEditor.editors[props.editor]);
  var widget = editorContext.widgetsById[props.id];
  const { onCreateWidget } = React.useContext(WidgetContext);

  const children = (widget.widgets || []);
  const modal = useSelector(state => {
    var item = null;
    var editorContext = state.widgetsEditor.editors[props.editor];

    if (editorContext) {
      item = children.map(id => {
        return editorContext.widgetsById[id];
      }).find(item => {
        const type = _.get(item, 'type');
        return type === 'widgets.modal';
      });
    }

    if (item) {
      item = denormalise([item.id], editorContext.widgetsById)[0];
    }

    return item;
  });

  if (!widget) {
    return false;
  }

  var properties = widget.properties;

  const handleChange = (changeRequest) => {
    dispatch(updateProperties({
      id: widget.id,
      change: changeRequest,
      context: props.editor
    }));
  }

  const handleCssChange = (css) => {
    var changeRequest = { ...widget };
    _.set(changeRequest, 'properties.css', css);

    changeRequest = WidgetRegistry.processRequest([changeRequest])[0];
    dispatch(updateWidget(props.id, changeRequest, props.editor));
  }

  const handleActionTypeChange = (value) => {
    if (value === 'contact') {
      dispatch(updateProperties({
        id: widget.id,
        change: {
          action: {
            type: value,
            emails: []
          }
        },
        context: props.editor
      }));
    } else if (value === 'link') {
      dispatch(updateProperties({
        id: widget.id,
        change: {
          action: {
            type: value,
            url: "",
            newTab: true
          }
        },
        context: props.editor
      }));

      if (widget.widgets && widget.widgets.length > 0) {
        widget.widgets.forEach(child => {
          dispatch(deleteWidget(child, props.editor));
        })
      }
    }else{
      dispatch(updateProperties({
        id: widget.id,
        change: {
          action: {
            type: value
          }
        },
        context: props.editor
      }));
    }
  }

  const actionType = _.get(properties, 'action.type');

  return (
    <QuickSettings menuItems={menuItems} selected={selected} onMenuClick={handleMenuClick} >
      {selected === 'action' &&
      <>
        <QuickSettingsProperty.Dropdown title='Type'
          tooltip='Select what the action should do'
          placeholder='Action type'
          selection
          options={[
            { key: 'link', value: 'link', text: 'Link' },
            { key: 'contact', value: 'contact', text: 'Contact Form' },
            { key: 'section_segue', value: 'section_segue', text: 'Segue Section' }
          ]}
          value={actionType}
          onChange={(e, data) => {
            handleActionTypeChange(data.value);
          }}
        />

        {actionType === 'link' &&
          <>
            <QuickSettingsProperty.Input
              title={'Url'}
              value={properties.action.link}
              onChange={(value) => {
                handleChange({
                  action: {
                    ...properties.action,
                    link: value
                  }
                });
              }}
            />
            <QuickSettingsProperty.Switch
              title={'Open in new Tab'}
              value={properties.action.newTab}
              onChange={() => {
                handleChange({
                  action: {
                    ...properties.action,
                    newTab: !properties.action.newTab
                  }
                })
              }}
            />
          </>
        }

        {actionType === 'contact' && 
          <FormQuickSettings properties={properties} onChange={handleChange}/>
        }
      </>
      }

      {selected === 'spacing' &&
        <>
          <QuickSettingsProperty.Spacing
            title='Margin'
            value={properties.margin}
            onChange={(margin) => {
              handleChange({
                margin: margin
              });
            }}
          />
          <QuickSettingsProperty.Spacing
            title='Padding'
            value={properties.padding}
            onChange={(padding) => {
              handleChange({
                padding: padding
              });
            }}
          />
        </>
      }

      {selected === 'background' &&
        <QuickSettingsProperty.Color 
          title='Color' 
          value={_.get(properties, 'background.color')} 
          onChange={(value) => {
            handleChange({
              background: {
                color: value
              }
            })
          }} 
        />
      }

      {selected === 'border' &&
        <QuickSettingsProperty.Border expanded value={properties.border} onChange={(border) => {
          handleChange({
            border: border
          });
        }}
        />
      }

      {selected === 'animation' &&
        <QuickSettingsProperty.Animation 
          title='Animation' 
          value={properties.animation} 
          onChange={(animation) => {
            handleChange({
              animation: animation
            });
          }}
        />
      }

      {selected === 'advanced' &&
        <QuickSettingsProperty.CSS title='Stylesheets' value={properties.css} onChange={handleCssChange} />
      }
    </QuickSettings>
  )
}

Button.QuickSettings = ButtonQuickSettings;

function FormQuickSettings(props) {
  const dispatch = useDispatch();
  const forms = useSelector(state => _.get(state, 'forms.items', []));
  const loading = useSelector(state => _.get(state, 'forms.loading', false));
  const pagination = useSelector(state => _.get(state, 'forms.pagination', {}));
  const { currentPage, totalPages } = pagination;
  const selectedForm = _.get(props, 'properties.form', null);

  useEffect(() => {
    dispatch(fetchForms(0, 20, 'name', 'ASC', false, selectedForm));
  }, []);

  const handleChange = (e, data) => {
    console.log(data);
    if (data.value === 'loadMore') {
      dispatch(fetchForms(currentPage + 1, 20, "name", "ASC", false, null));
      return;
    }

    var value = forms.find(form => {
      return form.id === data.value;
    })

    props.onChange({
      form: value
    });
  }

  var options = forms.map(option => {
    return {
      key: option.id,
      value: option.id,
      text: option.name,
      content: (
        <p>{option.name}</p>
      )
    }
  });

  if (currentPage < totalPages) {
    options = [...options, {
      key: 'loadMore',
      value: 'loadMore',
      text: 'Load More',
      content: (
        <p className={styles.loadMoreButton}>Load More</p>
      )
    }]
  }

  return (
    <Property.Dropdown title='Forms'
      tooltip='Forms'
      placeholder='Select form'
      selection
      multiple={false}
      closeOnChange={false}
      options={options}
      value={selectedForm && selectedForm.id}
      onChange={handleChange}
    />
  )
}

function ButtonProperty(props){
  const dispatch = useDispatch();

  var editorContext = useSelector(state => state.widgetsEditor.editors[props.editor]);
  var widget = editorContext.widgetsById[props.id];
  const { onCreateWidget } = React.useContext(WidgetContext);
  
  const children = (widget.widgets || []);
  const modal = useSelector(state => {
    var item = null;
    var editorContext = state.widgetsEditor.editors[props.editor];

    if(editorContext) {
      item = children.map(id => {
        return editorContext.widgetsById[id];
      }).find(item => {
        const type = _.get(item, 'type');
        return type === 'widgets.modal';
      });
    }

    if(item){
      item = denormalise([item.id], editorContext.widgetsById)[0];
    }

    return item;
  });

  if(!widget){
    return false;
  }
  
  var properties = widget.properties;

  const handleChange = (changeRequest) => {
    dispatch(updateProperties({
      id: widget.id,
      change: changeRequest,
      context: props.editor
    }));
  }

  const handleBackgroundImageChange = (media, type) => {
    var changeRequest = {...widget};
    _.set(changeRequest, 'properties.background.upload', {
      type: type,
      data: media
    });

    changeRequest  = WidgetRegistry.processRequest([changeRequest])[0];
    dispatch(updateWidget(props.id, changeRequest, props.editor));
  }

  const handleCssChange = (css) => {
    var changeRequest = {...widget};
    _.set(changeRequest, 'properties.css', css);

    changeRequest  = WidgetRegistry.processRequest([changeRequest])[0];
    dispatch(updateWidget(props.id, changeRequest, props.editor));
  }

  const handleActionTypeChange = (value) => {
    console.log(value);
    if(value === 'contact'){
      dispatch(updateProperties({
        id: widget.id,
        change: {
          action: {
            type: value,
            emails: []
          }
        },
        context: props.editor
      }));

      onCreateWidget(props.editor, contactFormPopup, widget.id, -1, false);
    }else if(value === 'link'){
      dispatch(updateProperties({
        id: widget.id,
        change: {
          action: {
            type: value,
            url: "",
            newTab: true
          }
        },
        context: props.editor
      }));

      if(widget.widgets && widget.widgets.length > 0){
        widget.widgets.forEach(child =>{
          dispatch(deleteWidget(child, props.editor));
        })
      }
    }else{
      dispatch(updateProperties({
        id: widget.id,
        change: {
          action: {
            type: value
          }
        },
        context: props.editor
      }));
    }
  }

  const handleClosePopup = (modalEditor) =>  {
    dispatch(mergeWidgetEditors(modalEditor, props.editor));
  }
  
  const actionType = _.get(properties, 'action.type');
  return (
    <Fragment>
      <Property.Section title='Action' expanded>
        <Property.Dropdown title='Type' 
          tooltip='Select what the action should do'
          placeholder='Action type'
          selection 
          options={[
            { key: 'link', value: 'link', text: 'Link' },
            { key: 'contact', value: 'contact', text: 'Contact Form' }
          ]}
          value={actionType}
          onChange={(e, data) => {
            handleActionTypeChange(data.value);
          }}
        />

        {actionType === 'link' && 
          <>
            <Property.Input 
              title={'Url'}
              value={properties.action.link}
              onChange={(value) => {
                handleChange({
                  action: {
                    ...properties.action,
                    link: value
                  }
                });
              }}
            />
            <Property.Switch 
              title={'Open in new Tab'}
              value={properties.action.newTab}
              onChange = {() => {
                handleChange({
                  action: {
                    ...properties.action,
                    newTab: !properties.action.newTab
                  }
                })
              }}
            />
          </>
        }

        {actionType === 'contact' &&  modal != null && 
          <>
          <PopupConfig value={modal} onCreateWidget={onCreateWidget} onClosePopup={handleClosePopup}/>
          </>
        }
      </Property.Section>
      <Property.Section title='Spacing' expanded>
        <Property.Spacing 
          title='Margin' 
          value={properties.margin}
          onChange={(margin) => {
            handleChange({
              margin: margin
            });
          }}
        />
        <Property.Spacing 
          title='Padding' 
          value={properties.padding}
          onChange={(padding) => {
            handleChange({
              padding: padding
            });
          }}
        />
      </Property.Section>

      <Property.Background expanded 
        value={properties.background} 
        onChange={(background) => {
          handleChange({
            background: background
          });
        }} 
        onImageChange={(image, type) => {
          handleBackgroundImageChange(image, type);
        }} 
      />
      
      <Property.Border expanded value={properties.border} onChange={(border) => {
          handleChange({
            border: border
          });
        }} 
      />

      <Property.Section title='Advanced' expanded>
        <Property.CSS title='Stylesheets' value={properties.css} onChange={handleCssChange} />
      </Property.Section>
    </Fragment>
  );
}

/**
 * Property CSS
 */
function PopupConfig({ title, tooltip, value, onChange, onClosePopup, ...rest}){
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [css, setCss] = useState(value);
  const [editor, setEditor] = useState(null);

  var widgets = useSelector(state => {
    var widgets = [];

    if (!editor) {
      return widgets;
    }

    var editorContext = state.widgetsEditor.editors[editor];

    if (editorContext) {
      widgets = editorContext.widgets;
    }

    return widgets;
  })

  useEffect(() => {
    const id = guid();
    dispatch(createEditorContext(id, [value]));
    setEditor(id);
  }, []);

  const handleClose = () => {
    setOpen(false);
    onClosePopup(editor);
  }


  if(!(value && value.id)){
    return false;
  }

  return (
    <Property title={'Popup'} tooltip={tooltip}>
      <Modal size='fullscreen' 
        trigger={
          <SemanticButton size='mini'>Configure</SemanticButton>
        } 
        className={styles.popupConfig}
        open={open}
        onOpen={() => {
          setOpen(true);
        }}
      >
        <Modal.Header>Configure Popup</Modal.Header>
        <Modal.Content>
          <WidgetsEditor 
            className={styles.editor}
            paletteClassName={styles.palette}
            propertyClassName={styles.properties}
            editor={editor} 
            widgets={widgets}
            editable={true}
            onCreateWidget={rest.onCreateWidget}
          />
        </Modal.Content>
        <Modal.Actions>
          <SemanticButton onClick={handleClose}>
            <Icon name='remove' /> Close
          </SemanticButton>
        </Modal.Actions>
      </Modal>
    </Property>
  );
}

Button.Property = ButtonProperty;

function EmailProperty({value, onChange}){
  const [input, setInput] = useState('');
  const emails = value || [];

  const handleKeyPressed = (e) => {
    if (e.key === "Enter") {
      handleChanges();
    }
  }

  const handleChanges = () => {
    var updated = emails;
    if(!updated){
      updated = [];
    }

    if(input && input.trim() && validateEmail(input.trim())){
      updated = [...updated, input.trim()];
      updated = _.uniq(updated);
      onChange(updated);
    }

    setInput('');
  }

  const deleteValue = (index) => {
    var updated = [...emails];
    updated.splice(index, 1);
    onChange(updated);
  }

  return (
    <Property title={'Notify'} tooltip={'Notify users'}>
      {emails.map((item, index) => {
        return (
          <Tooltip content={item} inverted>
            <Label key={`${item}-${index}`} image className={cx(styles.email)}>
              <div className={styles.value}><Truncate lines={1}>{item}</Truncate></div>
              <Icon name='delete' onClick={deleteValue.bind(this, index)}/>
            </Label>
          </Tooltip>
        );
      })}
      <Input className={styles.emailInput} size='mini'
        placeholder='Email address'
        value={input} 
        onChange={(e) => {
          setInput(e.target.value);
        }}
        onBlur={handleChanges}
        onKeyPress={handleKeyPressed}
      />

    </Property>
  );
}