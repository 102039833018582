import React, {Fragment} from 'react';
import { connect } from 'react-redux';

import {
  Image,
  Menu
} from 'semantic-ui-react';

import _ from 'lodash';

import styles from './ImageViewer.module.scss';

import AuthService from 'services/auth.service';

import Draggable from 'react-draggable';

const ZoomOutIcon = '/images/pdfViewer/zoom_out_icon.png';
const ZoomInIcon = '/images/pdfViewer/zoom_in_icon.png';

class ImageView extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      source: AuthService.getURL(`api/2.0/content/${this.props.asset.id}/download`, {
        modifiedDate: this.props.asset.metadata.modified_date,
        preview: true,
        'PF-APPLICATION-ID': props.storyboard.id
      }),
      name: this.props.asset.name,
      pageDimensions: null,
      zooming: false,
      imageLoaded: false,
    }
  }

  componentDidMount() {
    if (this.imageNode) {
      this.imageNode.addEventListener('load', () => {
        this._renderImageViewer();
      }, false);
    }
  }

  _renderImageViewer() {
    if (this.imageNode && !this.props.inline) {
      document.getElementById('pfImageViewer').scrollTop -= 1;

      this.setState({
        currentScale: 1,
        pageDimensions: {
          width: this.imageNode.naturalWidth,
          height: this.imageNode.naturalHeight
        }
      }, () => {
          this.resizeImage(this.imageNode.naturalWidth, this.imageNode.naturalHeight);
      })
    }
  }

  resizeImage(naturalWidth, naturalHeight) {
    const aspectRatio = naturalWidth / naturalHeight;

    let viewerWidth = this.placeHolderNode.scrollWidth;
    let viewerHeight = this.placeHolderNode.scrollHeight;

    let width = viewerWidth;
    let height = viewerHeight;

    var imageData = {
      naturalWidth,
      naturalHeight,
      aspectRatio
    };

    if (viewerHeight * aspectRatio > viewerWidth) {
      height = viewerWidth / aspectRatio;
    } else {
      width = viewerHeight * aspectRatio;
    }

    width = Math.min(width * 0.9, naturalWidth);
    height = Math.min(height * 0.9, naturalHeight);

    imageData = {
      ...imageData,
      ratio: width / naturalWidth,
      width,
      height
    }

    this.setState({
      imageData: imageData,
      imageLoaded: true
    })

    console.log(imageData.left);
  }

  zoomTo(ratio) {

    if (ratio < 0) {
      ratio = 1 / (1 - ratio);
    } else {
      ratio = 1 + ratio;
    }

    var width = this.state.imageData.width;
    var height = this.state.imageData.height;
    var naturalWidth = this.imageNode.naturalWidth;
    var naturalHeight = this.imageNode.naturalHeight;

    ratio = width * Math.max(0, ratio) / naturalWidth;

    var minZoomRatio = 0.01;
    var maxZoomRatio = 100;
    ratio = Math.min(Math.max(ratio, minZoomRatio), maxZoomRatio);

    var newWidth = naturalWidth * ratio;
    var newHeight = naturalHeight * ratio;
    var offsetWidth = newWidth - width;
    var offsetHeight = newHeight - height;

    this.setState({
      imageData : {
        ...this.state.imageData,
        left: this.state.imageData.left -= offsetWidth / 2,
        top: this.state.imageData.top -= offsetHeight / 2,
        width: newWidth,
        height: newHeight,
        ratio: ratio
      },
      zooming: ratio > 0.75 && true
    })
  }
  
  render() {
    return (
      <Fragment>
        {!this.props.inline && this.renderAssetViewer()}

        {this.props.inline && this.renderInlineViewer()}
      </Fragment>
    )
  }

  renderAssetViewer() {
    var style = {};
    if (this.state.imageData) {
      style = {
        width: this.state.imageData.width,
        height: this.state.imageData.height,
        marginLeft: this.state.imageData.left,
        marginTop: this.state.imageData.top,
      }
    }

    return (
      <div className={styles.viewer} id="pfImageViewer">
        <div ref={node => this.placeHolderNode = node} className={styles.inner}>
          <Draggable>
            <img
              draggable={false}
              ref={node => this.imageNode = node}
              style={style} className={styles.image}
              src={this.state.source}
              alt={this.state.name}
            />
          </Draggable>
          {this.renderToolbar()}
        </div>
      </div>
    )
  }

  renderInlineViewer() {
    return (
      <div className={styles.inlineViewer}>
        <img
          draggable={false}
          ref={node => this.imageNode = node}
          src={this.state.source}
          alt={this.state.name}
        />
      </div>
    )
  }

  renderToolbar() {
    if (!this.state.imageData || !this.state.imageData.ratio) {
      return null;
    }

    var scaleValue = Math.ceil(this.state.imageData.ratio * 100);

    return (
      <Menu compact icon='labeled' fixed='bottom' size='small' className={styles.controlMenu}>
        <Menu.Item
          name='angle up'
          onClick={this.zoomTo.bind(this, -0.1)}
        >
          <Image src={ZoomOutIcon} size='mini' spaced />
        </Menu.Item>
        <Menu.Item
          name={`${scaleValue}%`}
        >
          {scaleValue}%
        </Menu.Item>
        <Menu.Item
          name='angle up'
          onClick={this.zoomTo.bind(this, 0.1)}
        >
          <Image src={ZoomInIcon} size='mini' spaced />
        </Menu.Item>
      </Menu>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    storyboard: _.get(state, 'storyboards.storyboard.item')
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    
  }
}

const ImageViewer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ImageView)

export default ImageViewer;