import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, NavLink, useRouteMatch, useHistory } from "react-router-dom";

import { WidgetRegistry } from 'components/Widgets/registry.jsx';
import { WidgetsEditor } from "./editor";
import SidePane from 'components/SidePane';
import FloatingPane from 'components/FloatingPane';
import { useWidgetSelecion } from 'components/Widgets/widget';

import cx from 'classnames';
import { Helmet } from "react-helmet";
import dateformat from 'dateformat';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import {
  List,
  Dropdown,
  Form,
  Header,
  Image,
  Menu,
  Segment,
  Input,
  Icon,
  Label,
  TextArea,
  Popup,
  Button,
  Checkbox,
  FormGroup,
  Radio
} from 'semantic-ui-react';

import styles from './editor.module.scss';
import _ from 'lodash';

import { getBaseUrl, guid } from 'services/util';

import {
  createEditorContext, updateEditorContext, createWidgetFromPalette
} from 'actions/widgetEditor';
import { fetchIntegrations } from 'actions/integrations/integrations';

import { fetchPage, updatePage, publishPage, restorePage, deletePage } from 'actions/pages';
import { fetchAssetTemplates } from 'actions/templates';
import { PropertiesPane } from './properties';

import ImageTools from 'services/imagetools.service';
import { denormalise } from 'services/widget.helper';
import { PasswordModal } from 'components/Widgets/custom/password/passwordModal';
import ManageAlias from 'components/Alias';

import { PASSWORD_PROTECTION } from 'constants/ItemTypes';

export default function PageEditor() {
  const dispatch = useDispatch();
  const history = useHistory();
  let { url } = useRouteMatch();

  const { id } = useParams();

  const [editor, setEditor] = useState(null);
  const [editable, setEditable] = useState(true);

  const page = useSelector(state => _.get(state, 'pageEditor.item', {}));
  const loading = useSelector(state => _.get(state, 'pageEditor.loading', false));

  const selectedWidget = useWidgetSelecion(editor);

  var widgetsById = useSelector(state => {
    var widgetsById = {};

    if (!editor) {
      return widgetsById;
    }

    var editorContext = state.widgetsEditor.editors[editor];

    if (editorContext) {
      widgetsById = editorContext.widgetsById;
    }

    return widgetsById;
  })

  var widgets = useSelector(state => {
    var widgets = [];

    if (!editor) {
      return widgets;
    }

    var editorContext = state.widgetsEditor.editors[editor];

    if (editorContext) {
      widgets = editorContext.widgets;
    }

    return widgets;
  })


  var pageWidgets = widgets.filter(item => {
    var widget = widgetsById[item];
    return widget.type !== PASSWORD_PROTECTION;
  })

  useEffect(() => {
    dispatch(fetchPage(id));
    dispatch(fetchAssetTemplates());
    dispatch(fetchIntegrations());

    var editor = guid();
    setEditor(editor);
  }, []);

  useEffect(() => {
    if (page && page.draft && page.draft.widgets && editor) {
      dispatch(createEditorContext(editor, page.draft.widgets));
    }
  }, [page && page.id, editor]);

  const _onItemDrop = (editor, request, parent, index, insertBefore) => {
    const entityReference = {
      id: id,
      type: 'page'
    };

    dispatch(createWidgetFromPalette(editor, entityReference, request[0], parent, index, insertBefore));
  }

  const _handlePreviewClicked = () => {
    setEditable(!editable);
  }

  const _handleDeleteClicked = () => {
    setEditable(false);
    dispatch(deletePage(page.id, () => {
      let appUrl = getBaseUrl(url);
      history.push(`${appUrl}/pages`);
    }));
  }

  if (loading || !page) {
    return false;
  }

  const stylesheets = _.get(page, 'draft.properties.css', '');
  const script = _.get(page, 'draft.properties.script', '');

  return (
    <div className={styles.content}>
      {stylesheets &&
        <Helmet>
          <style type="text/css">{stylesheets}</style>
          <script type="text/javascript">{script}</script>
        </Helmet>
      }

      <PageHeader
        name={page.name}
        widgets={widgets}
        editor={editor}
        widgetsById={widgetsById}
        preview={_handlePreviewClicked}
        delete={_handleDeleteClicked}
        onCreateWidget={_onItemDrop}
      />

      <Segment basic className={styles.widgetsEditor}>
        <div className={styles.editor}>
          <WidgetsEditor
            id='widget_viewer'
            editor={editor}
            entityReference={{
              id: page.id,
              type: 'page'
            }}
            widgets={pageWidgets}
            editable={editable}
            onCreateWidget={_onItemDrop}
          />

          <FloatingPane
            width={260}
            height='80vh'
            position={{ top: 85, right: 20 }}
            className={cx(styles.pageProperties, { [styles.hide]: (!editable || selectedWidget != null) })}
          >
            <PropertiesPane editor={editor} />
          </FloatingPane>
        </div>
      </Segment>
    </div>
  )
}

function PageHeader(props) {
  const dispatch = useDispatch();

  const baseUrl = useSelector(state => _.get(state, 'storyboards.storyboard.item.baseUrl'));
  const page = useSelector(state => _.get(state, 'pageEditor.item', {}));
  const updating = useSelector(state => _.get(state, 'pageEditor.updating', false));
  const publishing = useSelector(state => _.get(state, 'pageEditor.publishing', false));
  const deleting = useSelector(state => _.get(state, 'pageEditor.deleting', false));

  const [saveSuccess, setSaveSuccess] = useState(false);
  const [publishSuccess, setPublishSuccess] = useState(false);
  const [passwordWidget, setPasswordWidget] = useState(false);

  const [state, setState] = useState({
    alias: false
  });


  const disableActions = updating || publishing || deleting;
  const viewUrl = `${baseUrl}${page.alias}`;

  let { path, url } = useRouteMatch();
  let appUrl = getBaseUrl(url);

  const [showEdit, setShowEdit] = useState(false);

  const toggleShowEdit = () => {
    setShowEdit(!showEdit);
  }

  const _handleUpdatePage = () => {
    var widgetsRequest = denormalise(props.widgets, props.widgetsById);
    widgetsRequest = WidgetRegistry.processRequest(widgetsRequest);

    var properties = _.get(page, 'draft.properties', {});
    var trackingPixels = _.get(page, 'draft.trackingPixels', []);

    dispatch(updatePage(page.id, {
      widgets: widgetsRequest,
      properties: properties,
      trackingPixels: trackingPixels
    }, (res) => {
      setTimeout(() => {
        setSaveSuccess(true);
      }, 1000);
    }));
  }

  const _handlePublishPage = (saveSuccess) => {
    dispatch(publishPage(page.id, (res) => {
      setSaveSuccess(false);
      setPublishSuccess(true);
    }));
  }

  const _handleClosePasswordWidget = () => {
    setPasswordWidget(null);
  }

  return (
    <>
      <Menu fluid className={styles.pageHeader}>
        <Menu.Item as={NavLink} exact name='Pages' to={`${appUrl}/pages`} icon onClick={props.preview}><Icon name='arrow left' /></Menu.Item>

        <PageTitleWidget editor={props.editor} onWidgetClick={setPasswordWidget} />

        <Menu.Item icon onClick={toggleShowEdit} className={styles.pageSettings}><i aria-hidden="true" className="icon-page-settings icon"></i></Menu.Item>

        <Menu.Menu position='right'>
          <DeleteConfirmPopup
            trigger={
              <Menu.Item disabled={disableActions}>
                <Icon name='trash' /> Delete
              </Menu.Item>
            }
            onDelete={props.delete}
          />

          <Menu.Item onClick={(state) => {
              setState({
                ...state,
                alias: true
              })
            }}>
            <Icon name='linkify' />Manage Alias
          </Menu.Item>
          <Menu.Item as='a' href={`${appUrl}/pages/${page.id}/preview`} target='_blank' disabled={disableActions}>
            <Icon name='eye' />
            Preview
          </Menu.Item>

          <SaveSuccessPopup
            trigger={
              <Menu.Item onClick={_handleUpdatePage} disabled={disableActions}>
                <Icon name={updating ? 'spinner' : 'save'} loading={updating} />
                {updating ? 'Saving...' : 'Save'}
              </Menu.Item>
            }
            open={saveSuccess}
            onVisibilityChange={() => { setSaveSuccess(false) }}
            onPublish={() => {
              _handlePublishPage();
              setSaveSuccess(false);
            }}
          />

          <PublishSuccessPopup
            open={publishSuccess}
            onVisibilityChange={() => setPublishSuccess(false)}
            viewUrl={viewUrl}
            trigger={
              <Menu.Item className={styles.publish}>
                <Button basic primary onClick={_handlePublishPage.bind(this, false)} disabled={disableActions}>
                  {publishing && <Icon name='spinner' loading={publishing} />}
                  {publishing ? 'Publishing...' : 'Publish'}
                </Button>
              </Menu.Item>
            }
          />

          {page.published &&
            <Menu.Item className={styles.restoreMenuItem}>
              <Header as='h4' className={styles.lastPublised}>
                <Header.Content>
                  Last Published
                  <Header.Subheader>
                    on {dateformat(page.lastPublised, 'dd mmm yyyy')}
                  </Header.Subheader>
                </Header.Content>
              </Header>
              <Menu secondary className={styles.restoreMenu}>
                <Menu.Item as='a' icon href={viewUrl} target='_blank'>
                  VIEW
                </Menu.Item>

                {page.changes &&
                  <RestoreConfirmPopup
                    trigger={
                      <Menu.Item onClick={() => { }}>
                        RESTORE
                      </Menu.Item>
                    }
                    page={page}
                    editor={props.editor}
                  />
                }
              </Menu>
            </Menu.Item>
          }
        </Menu.Menu>
      </Menu>
      <SidePane right
        header='Update Page'
        close
        background='#ffffff'
        open={showEdit}
        className={cx(showEdit ? styles.pageEditSlider : styles.hidePageEditSlider)}
        onClose={() => {
          setShowEdit(false);
        }}
        closeOnDocumentClick={false}>
        <EditPageView page={page} open={showEdit} onCancel={() => { setShowEdit(false) }} />
      </SidePane>

      <PasswordModal id={passwordWidget}
        editor={props.editor}
        open={passwordWidget !== null}
        onClose={_handleClosePasswordWidget}
        onCreateWidget={props.onCreateWidget}
      />

      <ManageAlias entity={page} open={state.alias} onClose={() => {
        setState((state) => {
          return {
            ...state,
            alias: false
          }
        })
      }} />
    </>
  );
}

function PageTitleWidget(props) {
  const [open, setOpen] = useState(false);

  const page = useSelector(state => _.get(state, 'pageEditor.item', {}));

  const hiddenWidgets = useSelector(state => {
    if (!props.editor) {
      return [];
    }

    var widgets = _.get(state, `widgetsEditor.editors.${props.editor}.widgets`) || [];

    return widgets.map(widget => {
      return _.get(state, `widgetsEditor.editors.${props.editor}.widgetsById.${widget}`);
    }).filter(widget => widget != null && widget.type === PASSWORD_PROTECTION);
  });

  const statusLabel = () => {
    if (page && !page.changes && page.published) {
      return 'Published';
    }

    return 'Draft';
  }

  const handlePopupClick = (id) => {
    props.onWidgetClick(id);
    setOpen(false);
  }

  return (
    <Popup
      basic
      className={styles.hiddenWidgetsPopup}
      disabled={hiddenWidgets.length === 0}
      on='click'
      onOpen={() => { setOpen(true) }}
      onClose={() => setOpen(false)}
      open={open}
      position='bottom left'
      trigger={
        <Menu.Item className={styles.pageName}>
          <Header as='h3'>
            <Header.Content>
              {page.name}
              <Header.Subheader>
                <Label>{statusLabel()}</Label>
                Last Saved on {dateformat(page.modifiedDate, 'dd mmm yyyy')}
              </Header.Subheader>
            </Header.Content>
            {hiddenWidgets.length > 0 && <Icon name="caret down" />}
          </Header>
        </Menu.Item>
      }
    >
      <Segment basic className={styles.hiddenWidgetsContent}>
        <Header as="h2" className={styles.popupLabels} onClick={() => setOpen(false)}>
          {page.name}
        </Header>

        <Header as="h4" className={styles.popupBoxHeader}>
          Pop Up
        </Header>

        {hiddenWidgets.map(widget => {
          return (
            <Header as="h2" className={styles.popupLabels} onClick={handlePopupClick.bind(this, widget.id)}>
              {widget.name}
            </Header>
          )
        })}
      </Segment>
    </Popup>
  );
}

function SaveSuccessPopup({ trigger, open, onVisibilityChange, onPublish, publishing }) {
  return (
    <PopupMessage
      open={open}
      onVisibilityChange={onVisibilityChange}
      className={styles.save}
      trigger={trigger}
    >
      <div className={styles.content}>
        <div className={styles.details}>
          <h3 className={styles.title}>All your changes are now saved</h3>
          <p className={styles.subtext}>You can now preview your site or publish to see the changes on your live site</p>

          <Image src="/images/pages/save-dog.svg" />
          <Menu secondary>
            <Menu.Item onClick={onPublish.bind(this)}>
              Publish
            </Menu.Item>
            <Menu.Item onClick={() => onVisibilityChange(false)}>
              Done
            </Menu.Item>
          </Menu>
        </div>
      </div>
    </PopupMessage>
  )
}

function PublishSuccessPopup({ viewUrl, trigger, open, onVisibilityChange }) {
  const [copied, setCopied] = useState(false);

  return (
    <PopupMessage
      open={open}
      onVisibilityChange={onVisibilityChange}
      className={styles.publish}
      trigger={trigger}
    >
      <div className={styles.content}>
        <div className={styles.details}>
          <h3 className={styles.title}>Yayyy! Your page is live and kicking</h3>
          <p className={styles.subtext}>We have successfully published your site. Here's the URL handy if you'd like to share</p>
          <div className={styles.link}>
            {viewUrl}

            <CopyToClipboard text={viewUrl}
              onCopy={() => {
                setCopied(true);

                setTimeout(() => {
                  setCopied(false);
                }, 3000);
              }}>
              <span className={styles.copy}>{copied ? 'COPIED' : 'COPY LINK'}</span>
            </CopyToClipboard>
          </div>
          <Menu secondary>
            <Menu.Item as='a' icon href={viewUrl} target='_blank'>
              View site
            </Menu.Item>
            <Menu.Item onClick={() => onVisibilityChange(false)}>
              Continue Editing
            </Menu.Item>
          </Menu>
        </div>
        <Image className={styles.icon} src="/images/pages/publish-dog.svg" />
      </div>
    </PopupMessage>
  )
}

function RestoreConfirmPopup({ trigger, page, editor }) {
  const dispatch = useDispatch();

  const [open, setOpen] = useState(false);

  const restoring = useSelector(state => _.get(state, 'pageEditor.restoring', false));

  const handleRestore = () => {
    dispatch(restorePage(page.id, (res) => {
      dispatch(updateEditorContext(editor, res.draft.widgets))
      handleVisibilityChange(false);
    }));
  }

  const handleVisibilityChange = (visibile) => {
    setOpen(visibile);
  }

  return (
    <PopupMessage
      open={open || restoring}
      onVisibilityChange={handleVisibilityChange}
      className={styles.restore}
      trigger={trigger}>
      <div className={styles.content}>
        <div className={styles.details}>
          <h3 className={styles.title}>Restore last published version</h3>
          <p className={styles.subtext}>If you choose to continue, All edits to this draft page will be discarded and cannot be undone</p>
          <Menu secondary>
            <Menu.Item onClick={() => { handleVisibilityChange(false) }} disabled={restoring}>
              Cancel
            </Menu.Item>
            <Menu.Item onClick={handleRestore} disabled={restoring}>
              {restoring ? 'Restoring...' : 'Discard changes and restore'}
            </Menu.Item>
          </Menu>
        </div>
        <Image className={styles.icon} src="/images/pages/restore-dog.svg" />
      </div>
    </PopupMessage>
  )
}

function DeleteConfirmPopup({ trigger, onDelete }) {
  const [open, setOpen] = useState(false);
  const deleting = useSelector(state => _.get(state, 'pageEditor.deleting', false));

  const handleDelete = () => {
    onDelete();
  }

  const handleVisibilityChange = (visibile) => {
    setOpen(visibile);
  }

  return (
    <PopupMessage
      open={open || deleting}
      onVisibilityChange={handleVisibilityChange}
      className={styles.restore}
      trigger={trigger}>
      <div className={styles.content}>
        <div className={styles.details}>
          <h3 className={styles.title}>Delete the page</h3>
          <p className={styles.subtext}>If you choose to continue, the page will be deleted and cannot be undone</p>
          <Menu secondary>
            <Menu.Item onClick={() => { handleVisibilityChange(false) }} disabled={deleting}>
              Cancel
            </Menu.Item>
            <Menu.Item onClick={handleDelete} disabled={deleting}>
              {deleting ? 'Deleting...' : 'Confirm'}
            </Menu.Item>
          </Menu>
        </div>
        <Image className={styles.icon} src="/images/pages/restore-dog.svg" />
      </div>
    </PopupMessage>
  )
}

function PopupMessage({ className, open, trigger, children, onVisibilityChange }) {
  return (
    <Popup
      className={cx(styles.popup, className)}
      popperModifiers={{
        preventOverflow: {
          boundariesElement: "window"
        }
      }}
      open={open}
      on='click'
      onClose={() => onVisibilityChange(false)}
      onOpen={() => onVisibilityChange(true)}
      flowing
      pinned
      hoverable
      position='bottom right'
      trigger={trigger}
    >
      <div className={styles.closeButton} onClick={() => onVisibilityChange(false)}>
        <i aria-hidden="true" className="icon-close_1 icon">
          <span className="path1"></span><span className="path2"></span><span className="path3"></span>
        </i>
      </div>
      {children}
    </Popup>
  )
}

function EditPageView(props) {
  const dispatch = useDispatch();

  const page = useSelector(state => _.get(state, 'pageEditor.item', {}));
  const updating = useSelector(state => _.get(state, 'pageEditor.updating', false));
  const error = useSelector(state => _.get(state, 'pageEditor.error', false));
  const aliases = useSelector(state => _.get(state, 'aliases.items', []));

  var metadata = page.metadata || {};

  const [name, setName] = useState(page.name);
  const [alias, setAlias] = useState(page.alias);
  const [autoCreateAlias, setAutoCreateAlias] = useState(page.autoCreateAlias);
  const [errors, setErrors] = useState({})

  const [description, setDescription] = useState(metadata.description || '');
  const [icon, setIcon] = useState(null);
  const [keywords, setKeywords] = useState(metadata.keywords || []);
  const [excludeFromSearch, setExcludeFromSearch] = useState(metadata.excludeFromSearch);

  useEffect(() => {
    if (props.open) {
      setName(page.name);
      setAlias(page.alias);
      setAutoCreateAlias(page.autoCreateAlias);
      setErrors({});

      metadata = page.metadata || {};
      setDescription(metadata.description || '');
      setIcon(null);
      setKeywords(metadata.keywords || [])
    }
  }, [props.open])

  useEffect(() => {
    if (error && error.errors) {
      var errors = {};
      error.errors.forEach(item => {
        if (item.code === 34001) {
          errors['alias'] = item.message;
          setErrors(errors);
        }
      })
    }
  }, [error])

  const handleNameChange = (value) => {
    setName(value);
    setErrors({
      ...errors,
      name: null
    })
  }

  const toggleAutoCreateAlias = (value) => {
    if (!autoCreateAlias) {
      var alias = page.alias;
      setAlias(alias);
      setErrors({
        ...errors,
        alias: null
      })
    }
    setAutoCreateAlias(!autoCreateAlias);
  }

  const handleAliasChange = (value) => {
    setAlias(value);
    setErrors({
      ...errors,
      alias: null
    })
  }

  const handleDescriptionChange = (value) => {
    setDescription(value);
  }

  const validateForm = () => {
    var errors = {};

    if (!name || name.trim() === '') {
      errors['name'] = 'Please provide a valid name';
    }

    if (!autoCreateAlias && (!alias || alias.length === 0)) {
      errors['alias'] = 'Please provide a valid alias or check auto create';
    }

    return errors;
  }

  const onFormSubmit = () => {
    let errors = validateForm();

    if (Object.keys(errors).length > 0) {
      setErrors(errors);
      return;
    }

    var request = {
      name: name,
      autoCreateAlias: autoCreateAlias,
      alias: autoCreateAlias ? page.alias : alias,
      metadata: {
        title: name,
        description: description,
        image: icon,
        keywords: keywords,
        excludeFromSearch: excludeFromSearch
      }
    }

    dispatch(updatePage(page.id, request, (res) => {
      props.onCancel();
    }));
  }

  const onCancel = () => {
    props.onCancel();
  }

  const renderIcon = () => {
    if (icon) {
      return icon;
    }

    return metadata.image ? metadata.image.full : null;
  }

  return (
    <Segment basic className={styles.pageEditorForm}>
      <Segment basic className={styles.pageEditView}>
        <Form>
          <InputField
            title='NAME'
            value={name}
            placeholder='Provide page name'
            error={errors.name}
            onChange={handleNameChange}
          />

          <Segment className={styles.seoMetadataSegment}>

            <Header as='h5'>SEO METADATA</Header>

            <ImageInput value={renderIcon()} onChange={setIcon} />

            <TextAreaField title='DESCRIPTION'
              value={description}
              placeholder='Please describe the page'
              onChange={handleDescriptionChange}
            />

            <TagsField tags={keywords} onChange={setKeywords} />
            
            <div className={styles.excludeSearch}>
              <div className={styles.inner}>
                <h5>EXCLUDE FROM SEARCH</h5>
                <p>Prevent search engines from indexing this page</p>
              </div>
              <Radio toggle checked={excludeFromSearch} onChange={() => {
                setExcludeFromSearch(!excludeFromSearch);
              }}/>
            </div>
          </Segment>
        </Form>
      </Segment>

      <Menu borderless fixed={'bottom'}>
        <Button.Group fluid className={styles.buttonTray}>
          <Button onClick={onCancel} disabled={updating}>
            <Icon name={'repeat'} />
            Cancel
          </Button>
          <Button.Or />
          <Button
            disabled={updating}
            positive
            onClick={onFormSubmit}>
            <Icon name={updating ? 'spinner' : 'save'} loading={updating} disabled={updating} />
            {updating ? 'Updating' : 'Update'}
          </Button>
        </Button.Group>
      </Menu>
    </Segment>
  )
}

function InputField({ title, placeholder, value, onChange, error, ...rest }) {

  const handleChange = (e) => {
    onChange(e.target.value);
  }

  return (
    <Form.Field className={styles.field}>
      <Label size='small' active>{title}</Label>
      <Input error={error} placeholder={placeholder}
        value={value}
        onChange={handleChange}
        disabled={rest.disabled} />
    </Form.Field>
  )
}

function TextAreaField({ title, placeholder, value, onChange, ...rest }) {

  const handleChange = (e) => {
    onChange(e.target.value);
  }

  return (
    <Form.Field className={styles.field}>
      <Label size='small' active>{title}</Label>
      <Form>
        <TextArea placeholder={placeholder}
          rows={4}
          onChange={handleChange}
          value={value} />
      </Form>
    </Form.Field>
  )
}

function TagsField({ tags, onChange, ...rest }) {
  const [options, setOptions] = useState((tags || []).map(item => {
    return {
      key: item,
      text: item,
      value: item
    }
  }) || '');

  const handleAddition = (e, { value }) => {
    setOptions([{ text: value, value }, ...options])
  }

  const handleChange = (e, { value }) => {
    onChange(value);
  }

  return (
    <Form.Field className={styles.field}>
      <Label size='small' active>TAGS</Label>
      <Dropdown
        className={styles.tags}
        search
        selection
        fluid
        multiple
        allowAdditions
        placeholder='Add Tags'
        options={options}
        value={tags}
        onAddItem={handleAddition}
        onChange={handleChange}
        noResultsMessage={null}
      />
    </Form.Field>
  )
}

function ImageInput({ value, onChange }) {
  const uploadIconRef = React.createRef();
  const handleUploadIcon = (e) => {
    ImageTools.resize(e.target.files[0], {
      width: 1024, // maximum width
      height: 1024 // maximum height
    }, (blob, didItResize) => {
      var reader = new FileReader();
      reader.onload = (e) => {
        onChange(e.target.result)
      };

      reader.readAsDataURL(blob);
    });
  }

  return (
    <Form.Group inline className={styles.icon}>
      <label size='small'>IMAGE</label>
      <div className={styles.image}>
        {value ?
          <Image src={value} />
          :
          <div className={styles.empty}>NO ICON</div>
        }
      </div>

      <div className={styles.upload}>
        <Button size='mini' onClick={() => uploadIconRef.current.click()}>Upload Icon</Button>
        <input
          ref={uploadIconRef}
          type="file"
          hidden
          onChange={handleUploadIcon}
        />
      </div>
    </Form.Group>
  );
}