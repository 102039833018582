import React, { useState, Fragment } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { updateWidget, updateProperties } from 'actions/widgetEditor';

import _ from 'lodash';

import {
  Header,
  Grid,
  Segment,
  Icon,
  Accordion,
  Form,
  Input,
  Select,
  Dropdown,
  Button
} from 'semantic-ui-react';

import { QuickSettings, QuickSettingsProperty } from 'components/QuickSettings';
import { WidgetRegistry } from '../registry';
import ContentModal from './content';

const menuItems = [
  { id: 'spacing', title: 'Spacing', icon: 'icon-spacing' },
  { id: 'border', title: 'Border', icon: 'icon-border' },
  { id: 'layout', title: 'Layout', icon: 'icon-layout' },
  { id: 'content', title: 'Content', icon: 'icon-content' },
  { id: 'advanced', title: 'Advanced', icon: 'icon-custom' }
]

export default function MenuQuickSettings(props) {
  const dispatch = useDispatch();

  const [selected, setSelected] = useState('spacing');

  const handleMenuClick = (item) => {
    setSelected(item);
  }

  var widget = useSelector(state => {
    var widgetEditor = state.widgetsEditor.editors[props.editor];

    if (widgetEditor) {
      return widgetEditor.widgetsById[props.widget.id];
    }

    return null;
  });

  if (!widget) {
    return false;
  }

  var properties = widget.properties;

  const handleChange = (changeRequest) => {
    dispatch(updateProperties({
      id: props.id,
      change: changeRequest,
      context: props.editor
    }));
  }

  const handleCssChange = (css) => {
    var changeRequest = { ...widget };
    _.set(changeRequest, 'properties.css', css);

    changeRequest = WidgetRegistry.processRequest([changeRequest])[0];
    dispatch(updateWidget(props.id, changeRequest));
  }

  return (
    <QuickSettings menuItems={menuItems} selected={selected} onMenuClick={handleMenuClick} >
      {selected === 'spacing' &&
        <>
          <QuickSettingsProperty.Spacing
            title='Margin'
            value={properties.margin}
            onChange={(margin) => {
              handleChange({
                margin: margin
              });
            }}
          />
          <QuickSettingsProperty.Spacing
            title='Padding'
            value={properties.padding}
            onChange={(padding) => {
              handleChange({
                padding: padding
              });
            }}
          />
        </>
      }

      {selected === 'border' &&
        <QuickSettingsProperty.Border expanded value={properties.border} onChange={(border) => {
          handleChange({
            border: border
          });
        }}
        />
      }

      {selected === 'layout' && 
        <Layout widget={widget} onChange={handleChange} />
      }

      {selected === 'content' && 
        <Content widget={widget} onChange={handleChange} editor={props.editor} />
      }

      {selected === 'advanced' &&
        <QuickSettingsProperty.CSS title='Stylesheets' value={properties.css} onChange={handleCssChange} />
      }
    </QuickSettings>
  )
}

function Content(props) {
  return (
    <>
      <QuickSettingsProperty title='Menu'>
        <ContentModal trigger={
          <Button>Edit Menu</Button>
        } widget={props.widget} editor={props.editor} />
      </QuickSettingsProperty>
    </>
  )
}

function Layout(props) {
  var widget = props.widget;
  var properties = widget.properties;

  const handleInvertedChange = (value) => {
    var change = {
      inverted: value
    };

    props.onChange(change);
  }

  const handleBorderlessChange = (value) => {
    var change = {
      borderless: value
    };

    props.onChange(change);
  }

  return (
    <Fragment>
      <QuickSettingsProperty.Switch tooltip='Inverted'
        title='Inverted'
        value={properties.inverted || false}
        onChange={handleInvertedChange}
      />

      <QuickSettingsProperty.Switch tooltip='Borderless'
        title='Borderless'
        value={properties.borderless || false}
        onChange={handleBorderlessChange}
      />
    </Fragment>
  )
}