import React, { useState } from 'react';

import cx from 'classnames';
import _ from 'lodash';

import {
  Dropdown,
  Radio,
  Input,
  Button,
  Icon,
  Accordion,
  Image,
  Popup,
  Modal
} from 'semantic-ui-react';

import  ImageTools from 'services/imagetools.service';
import BrowserService from 'services/browser.service';
import { SketchPicker } from 'react-color';
import Tooltip from 'components/Tooltip';

import AceEditor from "react-ace";
import "ace-builds/src-noconflict/mode-css";
import "ace-builds/src-noconflict/theme-tomorrow";
import "ace-builds/src-min-noconflict/ext-searchbox";
import "ace-builds/src-min-noconflict/ext-language_tools";

import styles from './properties.module.scss';

export function Property({title, tooltip, className, children}){
  const label = tooltip ? <Tooltip content={tooltip} inverted><span>{title}</span></Tooltip> : title;
  return (
    <table className={styles.propertyTable}>
      <tbody>
        <tr className={cx(styles.property, className)}>
          <td className={styles.label}><label>{label}</label></td>
          <td align='right' className={styles.content}>{children}</td>
        </tr>
      </tbody>
    </table>
  );
}

/**
 * Property Section
 */
function PropertySection({title, expanded, className, children}){
  const [expandedState, setExpanded] = useState(expanded);

  const handleClick = () => {
    setExpanded(!expandedState);
  }

  return (
    <Accordion fluid styled className={cx(styles.section, className)}>
      <Accordion.Title active={expandedState} className={styles.sectionTitle} onClick={handleClick}>
        <Icon name='dropdown' />
        {title}
      </Accordion.Title>
      <Accordion.Content active={expandedState}>
        {children}
      </Accordion.Content>
    </Accordion>
  );
}

Property.Section = PropertySection;

/**
 * Property Dropdown
 */
function PropertyDropdown({title, tooltip, options, children, ...rest}){
  return (
    <Property title={title} tooltip={tooltip}>
      <Dropdown className={styles.dropdown} options={options} {...rest}>
        {children}
      </Dropdown>
    </Property>
  );
}

Property.Dropdown = PropertyDropdown;

/**
 * Property Switch
 */
function PropertySwitch({title, tooltip, value, onChange, ...rest}){
  const handleChange = (e) =>{
    if(onChange){
      onChange(!value);
    }
  }

  return (
    <Property title={title} tooltip={tooltip}>
      <Radio toggle checked={value} onChange={handleChange} />
    </Property>
  );
}

Property.Switch = PropertySwitch;


/**
 * Property Input
 */
function PropertyInput({title, tooltip, value, onChange, ...rest}){
  return (
    <Property title={title} tooltip={tooltip}>
      <Input className={styles.input} value={value} 
        onChange={(e) => {
          if(onChange){
            onChange(e.target.value);
          }
        }}/>
    </Property>
  );
}

Property.Input = PropertyInput;

/**
 * Property Number Input
 */
function PropertyNumberInput({title, tooltip, value, onChange, min, max, ...rest}){
  const regexp = new RegExp(`^-?[0-9]*$`);
  const [internalValue, setInternalValue] = useState(value);

  const handleChange = (e) => {
    const newValue = e.target.value;
    if (regexp.test(newValue)) {
      setInternalValue(newValue);
      
      if (onChange) {
        onChange(newValue);
        return;
      }
    }
  }

  const handleBlur = (e) => {
    if (internalValue < min) {
      setInternalValue(min);
    } else if (internalValue > max) {
      setInternalValue(max);
    } else {
      setInternalValue(value);
    }
  }

  return (
    <Property title={title} tooltip={tooltip}>
      <Input className={styles.input} 
        value={internalValue} 
        onChange={handleChange}
        onBlur={handleBlur}
      />
    </Property>
  );
}

Property.NumericInput = PropertyNumberInput;

/**
 * Background Color
 */
function PropertyColor({title, value, onChange, presetColors}){
  value = value || 'transparent';
  const pickerColor = value === 'transparent' ? {r: 255, g: 255, b: 255, a: 1} : value;

  var colorConfig = {};
  if(presetColors){
    colorConfig['presetColors'] = presetColors;
  }

  const handleColorChange = (color) => {
    console.log(color);
    onChange(color.rgb);
  }

  return (
    <Property title={title} className={styles.color}>
      <Button.Group>
        <Popup 
          className={styles.colorPopup}
          on='click'
          hoverable
          position='bottom right'
          trigger={
            <Button className={cx(styles.picker, {
                [styles.none]: value === 'transparent'
              })} 
              style={{
                background: `rgba(${pickerColor.r}, ${pickerColor.g}, ${pickerColor.b}, ${pickerColor.a})`,
              }}
            >
              {value === 'transparent' && 
                <svg width='100%' height='100%'>
                  <rect width="100%" height="100%" style={{
                    fill: 'transparent',
                    stroke: 'var(--primaryAppColor)',
                    strokeWidth: 2
                  }}/>
                  <line x1={0} y1={0} x2='100%' y2='100%' style={{
                    stroke: 'var(--primaryAppColor)',
                    strokeWidth: 1
                  }}/>
                </svg>
              }
            </Button>
          }
          content={
            <SketchPicker 
              {...colorConfig}
              color={pickerColor} 
              onChangeComplete={handleColorChange} />
          }
        />
        {value !== 'transparent' &&   
          <Button icon className={styles.reset} onClick={() => {
            onChange(null);
          }}>
            <Icon name='close' size='small' />
          </Button>
        }
      </Button.Group>
    </Property>
  );
}

Property.Color = PropertyColor;


/**
 * Background Property
 */
function PropertyImage({ title, className, value, onChange, onRemove, filter, accept, ...rest}){
  const uploadIconRef = React.createRef();
  const imageUrl = _.get(value, 'full');
  const type = _.get(value, 'type');
  const isSafari = BrowserService.getCurrentBrowser() === 'safari';

  const handleFileChange= (e) => {
    const file = e.target.files[0];
    if(onChange){
      onChange(file)
    }
  }

  const handleRemove = () => {
    console.log("Remove background");
    if(onRemove){
      onRemove();
    }
  }

  return (
    <Property className={cx(styles.image, className)} title={title}>
      <div className={styles.media}>
        <div className={cx(styles.overlay, styles[filter])}></div>

        {imageUrl && type === 'image' &&
          <Image src={imageUrl} />
        }

        {imageUrl && type === 'video' &&
          <video height="auto" width="100%" autoPlay muted loop playsInline={isSafari} src={!isSafari && imageUrl}>
            {isSafari &&
              <source src={imageUrl} type="video/mp4" />
            }
          </video>
        }

        <Button className={styles.remove} onClick={handleRemove}>Remove Background</Button>
      </div>
      
      {!imageUrl && 
        <Image>
          <div>NO IMAGE</div>
        </Image>  
      }

      <Button basic onClick={() => {uploadIconRef.current.click()}}>
        Upload new image
        <input
          ref={uploadIconRef}
          type="file"
          accept={accept}
          onChange={handleFileChange}
        />
      </Button>
    </Property>
  );
}

Property.Image = PropertyImage;

/**
 * Background Property
 */
function PropertyBackground({expanded, value, onImageChange, onChange, ...rest}){
  const media = _.get(value, 'media');
  const type = _.get(value, 'type');
  const color = _.get(value, 'color') || 'transparent';
  const overlay = _.get(value, 'overlay') || 'transparent';
  const position = _.get(value, 'position' || 'center center');

  const handleBackgroundChange = (file) => {

    if(typeof file === 'string' || file.type.match('image.*')){ 
      ImageTools.resize(file, {
        width: 1600, // maximum width
        height: 1024 // maximum height
      }, (blob, didItResize) => {
        loadImageFile(blob).then(url => {
          onImageChange(url, 'image');
        })
      });
    } else if (file.type.match('video.*')) {
      loadImageFile(file).then(url => {
        onImageChange(url, 'video');
      })
    }
  }

  const loadImageFile = (imageFile) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.onload = e => resolve(e.target.result)
      reader.onerror = reject
      reader.readAsDataURL(imageFile)
    });
  }

  const handleRemoveImage = () => {
    var background = value || {};
    background = {
      ...background,
      media: null
    }

    onChange(background);
  }

  const handleColorChange = (color) => {
    var updated = {...(value || {})};
    _.set(updated, `color`, color);
    if(onChange){
      onChange(updated);
    }
  }

  const handleOverlayChange = (color) => {
    var updated = { ...(value || {}) };
    _.set(updated, `overlay`, color);
    if (onChange) {
      onChange(updated);
    }
  }

  const handlePositionChange = (position) => {
    var updated = { ...(value || {}) };
    _.set(updated, `position`, position);
    if (onChange) {
      onChange(updated);
    }
  }

  return (
    <Property.Section title='Background' expanded={expanded} className={styles.background}>
      <Property.Image title='Image' 
        value={media} 
        type={type}
        accept="image/* video/*"
        onChange={handleBackgroundChange}
        onRemove={handleRemoveImage}
      />
      <Property.Color title='Color' value={color} onChange={handleColorChange}/>
      {rest.overlayEnabled &&
        <Property.Color 
          title='Overlay' 
          value={overlay} 
          onChange={handleOverlayChange}
          presetColors={[
            {
              title: "Dark",
              color: {r: 0, g: 0, b: 0, a: 0.15}
            },
            {
              title: "Light",
              color: {r: 255, g: 255, b: 255, a: 0.15}
            }
          ]}
        />
      }
    </Property.Section>
  );
}

Property.Background = PropertyBackground;

/**
 * Property Image upload
 */
function PropertyImageUpload({ expanded, value, onImageChange, onChange, ...rest }) {
  const media = _.get(value, 'media');
  const type = _.get(value, 'type');

  const handleBackgroundChange = (file) => {
    ImageTools.resize(file, {
      width: 1600, // maximum width
      height: 1024 // maximum height
    }, (blob, didItResize) => {
      loadImageFile(blob).then(url => {
        onImageChange(url, 'image');
      })
    });
  }

  const loadImageFile = (imageFile) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.onload = e => resolve(e.target.result)
      reader.onerror = reject
      reader.readAsDataURL(imageFile)
    });
  }

  const handleRemoveImage = () => {
    var background = value || {};
    background = {
      ...background,
      media: null
    }

    onChange(background);
  }

  return (
    <Property.Section title='Image' expanded={expanded} className={styles.background}>
      <Property.Image title='Image'
        value={media}
        type={type}
        accept="image/*"
        onChange={handleBackgroundChange}
        onRemove={handleRemoveImage}
      />
    </Property.Section>
  );
}

Property.ImageUpload = PropertyImageUpload;

/**
 * Background position
 */
function PropertyPosition({value, onChange}) {
  var horizontalOptions = ['left', 'center', 'right'];
  var verticalOptions = ['top', 'center', 'bottom'];

  const handlePositionClick = (horizontal, vertical) => {
    var position = `${vertical} ${horizontal}`;
    if (onChange) {
      onChange(position)
    }
  }

  return (
    <Property title='Position'>
      <div className={styles.positions}>
        {verticalOptions.map(vertical => {
          return horizontalOptions.map(horizontal => {
            var position = `${vertical} ${horizontal}`;
            var active = position === value;

            return (
              <div className={cx(
                styles.position,
                styles[horizontal],
                styles[vertical],
                { [styles.active]: active }
              )}
                eventKey={`${horizontal}_${vertical}`}
                onClick={handlePositionClick.bind(this, horizontal, vertical)}
              >
                <a href="#">
                  {!(horizontal === 'center' && vertical === 'center') &&
                    <Icon name='long arrow alternate up' />
                  }
                  {(horizontal === 'center' && vertical === 'center') &&
                    <span>&nbsp;</span>
                  }
                </a>
              </div>
            );
          })
        })}
      </div>
    </Property>
  )
}

Property.Position = PropertyPosition;

/**
 * Border Property
 */
function PropertyBorder({expanded, value, onChange, ...rest}){
  const defaultBorder = {
    sides: {
      top: false,
      bottom: false,
      left: false,
      right: false
    },
    color: {r: 0, g: 0, b: 0, a: 1},
    width: '1px',
    radius: '0px'
  }

  const top = _.get(value, 'sides.top', false);
  const left = _.get(value, 'sides.left', false);
  const right = _.get(value, 'sides.right', false);
  const bottom = _.get(value, 'sides.bottom', false);

  const color = _.get(value, 'color') || defaultBorder.color;
  const width = _.get(value, 'width', defaultBorder.width);
  const radius = _.get(value, 'radius', defaultBorder.radius);

  const handleSideToggle = (side, state) => {
    var updated = {...(value || defaultBorder)};
    _.set(updated, `sides.${side}`, state);
    if(onChange){
      onChange(updated);
    }
  }

  const handleWidthChange = (width) => {
    var updated = {...(value || defaultBorder)};
    _.set(updated, `width`, width);
    if(onChange){
      onChange(updated);
    }
  }

  const handleRadiusChange = (radius) => {
    var updated = {...(value || defaultBorder)};
    _.set(updated, `radius`, radius);
    if(onChange){
      onChange(updated);
    }
  }

  const handleColorChange = (color) => {
    var updated = {...(value || defaultBorder)};
    _.set(updated, `color`, color);
    if(onChange){
      onChange(updated);
    }
  }
  
  return (
    <Property.Section title='Border' expanded={expanded}>
      <Property title='Border' className={styles.borders}>
        <Button.Group floated='right'>
          <Button className={cx(styles.top, {[styles.active]: top})} onClick={() => {
            handleSideToggle('top', !top);
          }}>
            <Image size='mini' src='/images/pages/properties/border_top.svg' />  
          </Button>
          <Button className={cx(styles.left, {[styles.active]: left})} onClick={() => {
            handleSideToggle('left', !left);
          }}>
            <Image size='mini' src='/images/pages/properties/border_left.svg' />  
          </Button>
          <Button className={cx(styles.right, {[styles.active]: right})} onClick={() => {
            handleSideToggle('right', !right);
          }}>
            <Image size='mini' src='/images/pages/properties/border_right.svg' />  
          </Button>
          <Button className={cx(styles.bottom, {[styles.active]: bottom})} onClick={() => {
            handleSideToggle('bottom', !bottom);
          }}>
            <Image size='mini' src='/images/pages/properties/border_bottom.svg' />  
          </Button>
        </Button.Group>
      </Property>

      <Property.Color 
        title="Border Color" 
        value={color}
        onChange={handleColorChange}
      />
      
      <Property.Input 
        title="Border Width"
        value={width}
        onChange={handleWidthChange}
      />
      <Property.Input 
        title="Border Radius"
        value={radius}
        onChange={handleRadiusChange}
      />
    </Property.Section>
  );
}

Property.Border = PropertyBorder;


/**
 * Margin Property
 */
function PropertySpacing({title, expanded, value, onChange, ...rest}){
  const [expandedState, setExpanded] = useState(expanded);

  const handleClick = () => {
    setExpanded(!expandedState);
  }

  const top = _.get(value, 'top', '');
  const left = _.get(value, 'left', '');
  const right = _.get(value, 'right', '');
  const bottom = _.get(value, 'bottom', '');

  var values = [top, left, right,  bottom];
  values = _.uniq(values);

  const equalSpacing = values.length === 1 ? values[0] : null;

  const handleAllChange = (e) => {
    var spacing  = e.target.value;

    if(onChange){
      onChange({
        top: spacing,
        left: spacing,
        right: spacing,
        bottom: spacing
      });
    }
  }

  const handleChange = (side, v) => {
    if(onChange){
      onChange({
        ...(value ||  {}),
        [side]: v
      });
    }
  }

  return (
    <tr className={styles.property}>
      <td colSpan={2}>     
        <Accordion.Accordion className={styles.spacing}>
          <Accordion.Title active={expandedState} className={styles.title} onClick={handleClick}>
            {title}
            <Icon name='dropdown' />
            <Input 
              className={styles.input} 
              value={equalSpacing} 
              onClick={(e) => {e.stopPropagation()}}
              onChange={handleAllChange}
            />
          </Accordion.Title>
          <Accordion.Content active={expandedState}>
            <table>
              <tbody>
                <Property.Input 
                  title={`${title} Top`}
                  value={top}
                  onChange={(e) => {handleChange('top', e)}}
                />
                <Property.Input 
                  title={`${title} Right`}
                  value={right}
                  onChange={(e) => {handleChange('right', e)}}
                />
                <Property.Input 
                  title={`${title} Bottom`} 
                  value={bottom}
                  onChange={(e) => {handleChange('bottom', e)}}
                />
                <Property.Input 
                  title={`${title} Left`}
                  value={left}
                  onChange={(e) => {handleChange('left', e)}}
                />
              </tbody> 
            </table>   
          </Accordion.Content>
        </Accordion.Accordion>
      </td>
    </tr>
  );
}

Property.Spacing = PropertySpacing;

/**
 * Property CSS
 */
function PropertyCss({title, tooltip, value, onChange, ...rest}){
  const [open, setOpen] = useState(false);
  const [css, setCss] = useState(value);

  const handleSave = () => {
    onChange(css);
    setOpen(false);
  }

  return (
    <Property title={title} tooltip={tooltip}>
      <Modal size='large' 
        trigger={
          <Button size='mini'>Edit</Button>
        } 
        className={styles.cssEditorModal}
        open={open}
        onOpen={() => {
          setOpen(true);
        }}
      >
        <Modal.Header>Custom  Stylesheet</Modal.Header>
        <Modal.Content scrolling>
          <AceEditor
            placeholder={'Edit styles'}
            mode={'css'}
            theme="tomorrow"
            className={styles.aceEditor}
            fontSize={14}
            width='100%'
            showPrintMargin={true}
            showGutter={true}
            highlightActiveLine={true}
            value={css || ''}
            onChange={(value) => {setCss(value)}}
            setOptions={{
              useWorker: false,
              enableBasicAutocompletion: true,
              enableLiveAutocompletion: true,
              enableSnippets: false,
              showLineNumbers: true,
              tabSize: 2
            }}
        />  
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={() => setOpen(false)}>
            <Icon name='remove' /> Cancel
          </Button>
          <Button primary onClick={handleSave}>
            <Icon name='checkmark' /> Save Changes
          </Button>
        </Modal.Actions>
      </Modal>
    </Property>
  );
}

Property.CSS = PropertyCss;

/**
 * Property Script
 */
function PropertyScript({title, tooltip, value, onChange, ...rest}) {
  const [open, setOpen] = useState(false);
  const [script, setScript] = useState(value);

  const handleSave = () => {
    onChange(script);
    setOpen(false);
  }

  return (
    <Property title={title} tooltip={tooltip}>
      <Modal size='large'
        trigger={
          <Button size='mini'>Edit</Button>
        }
        className={styles.cssEditorModal}
        open={open}
        onOpen={() => {
          setOpen(true);
        }}
      >
        <Modal.Header>Custom Script</Modal.Header>
        <Modal.Content scrolling>
          <AceEditor
            placeholder={'Edit script'}
            mode={'javascript'}
            theme="tomorrow"
            className={styles.aceEditor}
            fontSize={14}
            width='100%'
            showPrintMargin={true}
            showGutter={true}
            highlightActiveLine={true}
            value={script || ''}
            onChange={(value) => { setScript(value) }}
            setOptions={{
              useWorker: false,
              enableBasicAutocompletion: true,
              enableLiveAutocompletion: true,
              enableSnippets: false,
              showLineNumbers: true,
              tabSize: 2
            }}
          />
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={() => setOpen(false)}>
            <Icon name='remove' /> Cancel
          </Button>
          <Button primary onClick={handleSave}>
            <Icon name='checkmark' /> Save Changes
          </Button>
        </Modal.Actions>
      </Modal>
    </Property>
  );
}

Property.Script = PropertyScript;