import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, NavLink, useRouteMatch, useHistory } from "react-router-dom";

import { WidgetRegistry } from 'components/Widgets/registry.jsx';
import { WidgetsEditor } from "pages/pages/editor/editor";
import SidePane from 'components/SidePane';
import { PasswordModal } from 'components/Widgets/custom/password/passwordModal';
import { useWidgetSelecion } from 'components/Widgets/widget';

import { Property } from 'components/Properties';
import FloatingPane from 'components/FloatingPane';

import cx from 'classnames';
import _ from 'lodash';
import dateformat from 'dateformat';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import { PASSWORD_PROTECTION } from 'constants/ItemTypes';

import { fetchForm, updateForm, publishForm, deleteForm, restoreForm, invalidateFormSelection } from 'actions/forms';

import { guid } from 'services/util';
import { denormalise } from 'services/widget.helper';
import { getBaseUrl } from 'services/util';

import {
  createEditorContext, createWidgetFromPalette
} from 'actions/widgetEditor';

import {
  Header,
  Menu,
  Segment,
  Image,
  Icon,
  Button,
  Popup,
  Label,
  Input
} from 'semantic-ui-react';

import styles from './form.module.scss';
import formSaga from 'actions/forms';

const apiUrl = `${process.env.REACT_APP_API_URL}/api/2.0`;

export default function FormEditor() {
  const dispatch = useDispatch();
  const history = useHistory();
  
  let { url } = useRouteMatch();

  const { id } = useParams();

  const [editor, setEditor] = useState(null);
  const [editable, setEditable] = useState(true);

  const selectedWidget = useWidgetSelecion(editor);

  const form = useSelector(state => _.get(state, 'forms.form.item', {}));
  const loading = useSelector(state => _.get(state, 'form.form.loading', false));
  const updating = useSelector(state => _.get(state, 'forms.form.updating', false));

  var widgetsById = useSelector(state => {
    var widgetsById = {};

    if (!editor) {
      return widgetsById;
    }
    
    var editorContext = state.widgetsEditor.editors[editor];

    if (editorContext) {
      widgetsById = editorContext.widgetsById;
    }

    return widgetsById;
  })

  var widgets = useSelector(state => {
    var widgets = [];

    if (!editor) {
      return widgets;
    }

    var editorContext = state.widgetsEditor.editors[editor];

    if (editorContext) {
      widgets = editorContext.widgets;
    }

    return widgets;
  })

  useEffect(() => {
    dispatch(fetchForm(id));

    var editor = guid();
    setEditor(editor);
  }, []);

  useEffect(() => {
    if (form && form.draft && form.draft.widgets && editor) {
      dispatch(createEditorContext(editor, form.draft.widgets));
    }
  }, [form && form.id, editor]);

  const _onItemDrop = (editor, request, parent, index, insertBefore) => {
    const entityReference = {
      id: id,
      type: 'form'
    };

    dispatch(createWidgetFromPalette(editor, entityReference, request[0], parent, index, insertBefore));
  }

  const _handleUpdateForm = () => {
    var widgetsRequest = denormalise(widgets, widgetsById);
    widgetsRequest = WidgetRegistry.processRequest(widgetsRequest);

    var properties = _.get(form, 'draft.properties', {});
    
    var request = {
      widgets: widgetsRequest,
      properties: properties
    }

    var name = form.name;
    if(name && name.trim() != ''){
      request = {
        ...request,
        name: name
      }
    }

    dispatch(updateForm(form.id, request));
  }

  const _handlePublishForm = () => {
    var widgetsRequest = denormalise(widgets, widgetsById);
    widgetsRequest = WidgetRegistry.processRequest(widgetsRequest);

    var properties = _.get(form, 'draft.properties', {});

    dispatch(publishForm(form.id, {
      widgets: widgetsRequest,
      properties: properties
    }));
  }

  const _handleDeleteClicked = () => {
    setEditable(false);
    dispatch(deleteForm(form.id, () => {
      let appUrl = getBaseUrl(url);
      history.push(`${appUrl}/settings/forms`);
    }));
  }

  if (loading || !form) {
    return false;
  }

  const stylesheets = _.get(form, 'draft.properties.css', ''); 
  const script = _.get(form, 'draft.properties.script', '');

  return (
    <div className={styles.liveElementEditor}>
      <PageHeader
        id={formSaga.id}
        name={form.name}
        widgets={widgets}
        editor={editor}
        widgetsById={widgetsById}
        height={300}
        save={_handleUpdateForm}
        publish={_handlePublishForm}
        delete={_handleDeleteClicked}
      />

      <Segment basic className={styles.widgetsEditor}>
        <div className={styles.editor}>
          <WidgetsEditor
            id='widget_viewer'
            editor={editor}
            entityReference={{
              id: form.id,
              type: 'form'
            }}
            widgets={widgets}
            editable={editable}
            onCreateWidget={_onItemDrop}
          />
        </div>
      </Segment>
    </div>
  )
}

function PageHeader(props){
  const dispatch = useDispatch();

  const baseUrl = useSelector(state => _.get(state, 'storyboards.storyboard.item.baseUrl'));
  const form = useSelector(state => _.get(state, 'forms.form.item', {}));
  const updating = useSelector(state => _.get(state, 'forms.form.updating', false));
  const publishing = useSelector(state => _.get(state, 'forms.form.publishing', false));
  const deleting = useSelector(state => _.get(state, 'forms.form.deleting', false));
  const formWidget = props.widgets[0];
  var formType = formWidget ? _.get(props.widgetsById[formWidget], 'type', '') : '';

  const [saveSuccess, setSaveSuccess] = useState(false);
  const [name, setName] = useState(_.get(form, 'name', ''));
  const [publishSuccess, setPublishSuccess] = useState(false);
  const [passwordWidget, setPasswordWidget] = useState(false);
  
  const disableActions = updating || publishing || deleting;
  const viewUrl = `${baseUrl}${form.alias}`;

  let { path, url } = useRouteMatch();
  let appUrl = getBaseUrl(url);

  const [showEdit, setShowEdit] = useState(false);

  useEffect(() => {
    if(form) {
      setName(_.get(form, 'name', ''));
    }
  }, [form])

  const toggleShowEdit = () => {
    setShowEdit(!showEdit);
  }

  const handleNameChange = (name) => {
    setName(name);
  }

  useEffect(() => {
    if(form) {
      var name = _.get(form, 'name', '');
      handleNameChange(name);
    }
  }, [form]);

  useEffect(() => {
    return () => {
      dispatch(invalidateFormSelection());
    };
  }, []);

  const _handleUpdatePage = () => {
    var widgetsRequest = denormalise(props.widgets, props.widgetsById);
    widgetsRequest = WidgetRegistry.processRequest(widgetsRequest);

    var properties = _.get(form, 'draft.properties', {});

    dispatch(updateForm(form.id, {
      name: name,
      widgets: widgetsRequest,
      properties: properties
    }, (res) => {
      setTimeout(() => {
        setSaveSuccess(true);
      }, 1000);
    }));
  }

  const _handlePublishPage = (saveSuccess) => {
    dispatch(publishForm(form.id, (res) => {
      setSaveSuccess(false);
      setPublishSuccess(true);
    }));
  }

  const _handleRestore = () => {
    dispatch(restoreForm(form.id));
  }

  return (
    <>
      <Menu fluid className={styles.pageHeader}>
        <Menu.Item as={NavLink} exact name='Pages' to={`${appUrl}/settings/forms`} icon onClick={props.preview}><Icon name='arrow left' /></Menu.Item>

        <PageTitleWidget editor={props.editor} onWidgetClick={setPasswordWidget} name={name} onNameChange={handleNameChange}/>
          
        <Menu.Item icon onClick={toggleShowEdit} className={styles.pageSettings}><i aria-hidden="true" className="icon-page-settings icon"></i></Menu.Item>

        <Menu.Menu position='right'>
          {(formType === 'widgets.form.pardot' || formType === 'widgets.form.external') &&
            <CopyCodeAction formType={formType}/>
          }

          <DeleteConfirmPopup 
            trigger={
              <Menu.Item disabled={disableActions}>
                <Icon name='trash' /> Delete
              </Menu.Item>  
            }
            onDelete={props.delete}
          />

          <SaveSuccessPopup
            trigger={
              <Menu.Item onClick={_handleUpdatePage} disabled={disableActions}>
                <Icon name={updating ? 'spinner' : 'save'} loading={updating} />
                {updating ? 'Saving...' : 'Save'}
              </Menu.Item>
            }
            open={saveSuccess}
            onVisibilityChange={() => { setSaveSuccess(false) }}
            onPublish={() => {
              _handlePublishPage();
              setSaveSuccess(false);
            }}
          />

          <PublishSuccessPopup
            open={publishSuccess}
            onVisibilityChange={() => setPublishSuccess(false)}
            viewUrl={viewUrl}
            trigger={
              <Menu.Item className={styles.publish}>
                <Button basic primary onClick={_handlePublishPage.bind(this, false)} disabled={disableActions}>
                  {publishing && <Icon name='spinner' loading={publishing} />}
                  {publishing ? 'Publishing...' : 'Publish'}
                </Button>
              </Menu.Item>
            }
          />

          {form.published &&
            <Menu.Item className={styles.restoreMenuItem}>
              <Header as='h4' className={styles.lastPublised}>
                <Header.Content>
                  Last Published
                  <Header.Subheader>
                    on {dateformat(form.lastPublised, 'dd mmm yyyy')}
                  </Header.Subheader>
                </Header.Content>
              </Header>
              {form.changes &&
              <Menu secondary className={styles.restoreMenu}>
                  <RestoreConfirmPopup
                    trigger={
                      <Menu.Item onClick={_handleRestore}>
                        RESTORE
                    </Menu.Item>
                    }
                    page={form}
                    editor={props.editor}
                  />
              </Menu>
              }
            </Menu.Item>
          }
        </Menu.Menu>
      </Menu>
      <SidePane right
        header='Update Page'
        close
        background='#ffffff'
        open={showEdit}
        className={styles.pageEditSlider}
        onClose={() => {
          setShowEdit(false);
        }}>
      </SidePane>
    </>
  );
}

function CopyCodeAction({ formType }) {
  const [open, setOpen] = useState(false);
  const textareaRef = React.createRef(null);

  const onOpen = () => {
    setOpen(true)
  }

  const handleCopy = () => {
    textareaRef.current.select();
    document.execCommand('copy');
  }

  var completionCode = `
    <script>
      var email = encodeURIComponent('%%email{js}%%')
      window.location="${apiUrl}/forms/integrations/pardot/form-submit?email=" + email
    </script >`;

  if (formType === 'widgets.form.external') {
    completionCode = `${apiUrl}/forms/integrations/forms/external/form-submit?email=email`
  }

  return (
    <Popup
      on='click'
      hoverable={false}
      onOpen={onOpen}
      position='bottom right'
      trigger={
        <Menu.Item><Icon name={'code'} />{formType === 'widgets.form.external' ? 'Copy URL' : 'Copy Code'}</Menu.Item>
      }
    >
      <Segment basic className={styles.copyCode}>
        <Header as={'h3'}>Embed in your website</Header>
        <textarea ref={textareaRef} readOnly={true} value={completionCode}></textarea>
        <Button onClick={handleCopy}>Copy Code</Button>
      </Segment>
    </Popup>
  );
}

function PageTitleWidget(props){
  const form = useSelector(state => _.get(state, 'forms.form.item', {}));
  
  const hiddenWidgets = useSelector(state => {
    if (!props.editor) {
      return [];
    }
    
    var widgets = _.get(state, `widgetsEditor.editors.${props.editor}.widgets`) || [];
    
    return widgets.map(widget => {
      return _.get(state, `widgetsEditor.editors.${props.editor}.widgetsById.${widget}`);
    }).filter(widget => widget != null && widget.type === PASSWORD_PROTECTION);
  });
  
  const statusLabel = () => {
    if (form && !form.changes && form.published) {
      return 'Published';
    }

    return 'Draft';
  }

  return (
    <Menu.Item className={styles.pageName}>
      <Header as='h3'>
        <Header.Content>
          <Input size='small' className={styles.name} placeholder='Form name...' className={styles.formNameInput}
            value={props.name}
            onChange={(e) => {
              props.onNameChange(e.target.value)
          }}
          />
          <Header.Subheader>
            <Label>{statusLabel()}</Label>
                Last Saved on {dateformat(form.modifiedDate, 'dd mmm yyyy')}
          </Header.Subheader>
        </Header.Content>
        {hiddenWidgets.length > 0 && <Icon name="caret down" />}
      </Header>
    </Menu.Item>
  );
}

function SaveSuccessPopup({ trigger, open, onVisibilityChange, onPublish, publishing }) {
  return (
    <PopupMessage
      open={open}
      onVisibilityChange={onVisibilityChange}
      className={styles.save}
      trigger={trigger}
    >
      <div className={styles.content}>
        <div className={styles.details}>
          <h3 className={styles.title}>All your changes are now saved</h3>
          <p className={styles.subtext}>You can now preview your site or publish to see the changes on your live site</p>
          
          <Image src="/images/pages/save-dog.svg" />
          <Menu secondary>
            <Menu.Item onClick={onPublish.bind(this)}>
              Publish
            </Menu.Item>
            <Menu.Item onClick={() => onVisibilityChange(false)}>
              Done
            </Menu.Item>
          </Menu>
        </div>
      </div>
    </PopupMessage>
  )
}

function PublishSuccessPopup({ viewUrl, trigger, open, onVisibilityChange }) {
  const [copied, setCopied] = useState(false);

  return (
    <PopupMessage
      open={open}
      onVisibilityChange={onVisibilityChange}
      className={styles.publish}
      trigger={trigger}
    >
      <div className={styles.content}>
        <div className={styles.details}>
          <h3 className={styles.title}>Yayyy! Your page is live and kicking</h3>
          <p className={styles.subtext}>We have successfully published your site. Here's the URL handy if you'd like to share</p>
          <div className={styles.link}>
            {viewUrl}

            <CopyToClipboard text={viewUrl}
              onCopy={() => {
                setCopied(true);

                setTimeout(() => {
                  setCopied(false);
                }, 3000);
              }}>
              <span className={styles.copy}>{copied ? 'COPIED' : 'COPY LINK'}</span>
            </CopyToClipboard>
          </div>
          <Menu secondary>
            <Menu.Item as='a' icon href={viewUrl} target='_blank'>
              View site
            </Menu.Item>
            <Menu.Item onClick={() => onVisibilityChange(false)}>
              Continue Editing
            </Menu.Item>
          </Menu>
        </div>
        <Image className={styles.icon} src="/images/pages/publish-dog.svg" />
      </div>
    </PopupMessage>
  )
}

function RestoreConfirmPopup({ trigger, page, editor }) {
  const dispatch = useDispatch();

  const [open, setOpen] = useState(false);

  const restoring = useSelector(state => _.get(state, 'pageEditor.restoring', false));

  const handleRestore = () => {
    dispatch(restoreForm(page.id, (res) => {
      // dispatch(updateEditorContext(editor, res.draft.widgets))
      handleVisibilityChange(false);
    }));
  }

  const handleVisibilityChange = (visibile) => {
    setOpen(visibile);
  }

  return (
    <PopupMessage
      open={open || restoring}
      onVisibilityChange={handleVisibilityChange}
      className={styles.restore}
      trigger={trigger}>
      <div className={styles.content}>
        <div className={styles.details}>
          <h3 className={styles.title}>Restore last published version</h3>
          <p className={styles.subtext}>If you choose to continue, All edits to this draft page will be discarded and cannot be undone</p>
          <Menu secondary>
            <Menu.Item onClick={() => { handleVisibilityChange(false) }} disabled={restoring}>
              Cancel
              </Menu.Item>
            <Menu.Item onClick={handleRestore} disabled={restoring}>
              {restoring ? 'Restoring...' : 'Discard changes and restore'}
            </Menu.Item>
          </Menu>
        </div>
        <Image className={styles.icon} src="/images/pages/restore-dog.svg" />
      </div>
    </PopupMessage>
  )
}

function DeleteConfirmPopup({ trigger, onDelete }) {
  const [open, setOpen] = useState(false);
  const deleting = useSelector(state => _.get(state, 'pageEditor.deleting', false));

  const handleDelete = () => {
    onDelete();
  }

  const handleVisibilityChange = (visibile) => {
    setOpen(visibile);
  }

  return (
    <PopupMessage
      open={open || deleting}
      onVisibilityChange={handleVisibilityChange}
      className={styles.restore}
      trigger={trigger}>
      <div className={styles.content}>
        <div className={styles.details}>
          <h3 className={styles.title}>Delete the page</h3>
          <p className={styles.subtext}>If you choose to continue, the page will be deleted and cannot be undone</p>
          <Menu secondary>
            <Menu.Item onClick={() => { handleVisibilityChange(false) }} disabled={deleting}>
              Cancel
              </Menu.Item>
            <Menu.Item onClick={handleDelete} disabled={deleting}>
              {deleting ? 'Deleting...' : 'Confirm'}
            </Menu.Item>
          </Menu>
        </div>
        <Image className={styles.icon} src="/images/pages/restore-dog.svg" />
      </div>
    </PopupMessage>
  )
}

function PopupMessage({ className, open, trigger, children, onVisibilityChange }) {
  return (
    <Popup
      className={cx(styles.popup, className)}
      popperModifiers={{
        preventOverflow: {
          boundariesElement: "window"
        }
      }}
      open={open}
      on='click'
      onClose={() => onVisibilityChange(false)}
      onOpen={() => onVisibilityChange(true)}
      flowing
      pinned
      hoverable
      position='bottom right'
      trigger={trigger}
    >
      <div className={styles.closeButton} onClick={() => onVisibilityChange(false)}>
        <i aria-hidden="true" className="icon-close_1 icon">
          <span className="path1"></span><span className="path2"></span><span className="path3"></span>
        </i>
      </div>
      {children}
    </Popup>
  )
}