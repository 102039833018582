import React from 'react';
import ReactDOM from 'react-dom';

import store, { history } from "./store.jsx";

import Application from 'pages/application';
import { WidgetRegistry } from 'components/Widgets/registry.jsx';
import { Provider } from "react-redux";

import AuthInterceptor from './services/auth.interceptor.js';

import 'semantic-ui-less/semantic.less';

import 'index.css';

new AuthInterceptor(history);

ReactDOM.render(
	<Provider store={store}>
    <Application />
  </Provider>, 
  document.getElementById('root')
);