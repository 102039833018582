import React, { useState, Fragment, createRef, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import _ from 'lodash';
import { AddContent, useContentEditor, useContentSelection } from 'components/Asset/AddContent';
import { fetchAssetsForWidget, updateWidget, updateProperties, fetchWidgetContent, updateWidgetContent, updateAssetsForWidget, addPlaceholderAssetsForWidget } from 'actions/widgetEditor';
import { createContentEditor, updateContentEditor, invalidateContentEditor, savingContentInContentEditor } from 'actions/contentSelection';

import { guid, chunks } from 'services/util';
import PlaceholderContent from  'components/Asset/AddContent/placeholderContent.jsx'
import { ItemHeader } from 'semantic-ui-react';

export function ContentModal({ trigger, entityReference, widget, editor, ...rest }) {
  const dispatch = useDispatch();


  const [state, setState] = useState({
    contentEditor: null,
    percentage: 0,
    showPercentage: false
  })

  const contentSelection = useContentSelection(state.contentEditor);

  const fetchedContent = useSelector(state => {
    return _.get(state, `widgetsEditor.editors[${editor}].widgetsById[${widget.id}].content.fetched`);
  });


  useEffect(() => {
    if (!fetchedContent || !state.contentEditor) {
      return;
    }

    dispatch(createContentEditor(state.contentEditor, widget.content));

  }, [fetchedContent])

  const handleAddAssetStateChanged = () => {
    if (state.contentEditor) {
      dispatch(invalidateContentEditor(state.contentEditor));
      setState((state) => {
        return {
          ...state,
          contentEditor: null
        }
      })
      setTimeout(() => {
        dispatch(fetchAssetsForWidget(widget.id, 0, editor));
      }, 1000);
    }
  }

  const handleAddAsset = () => {
    dispatch(savingContentInContentEditor(state.contentEditor));

    let type = contentSelection.type;

    let updatedFilters = [];
    let assetsRequest = [];

    if (type === 'cluster') {
      updatedFilters = (contentSelection.filters || []).map(filter => {
        var property = filter.property;
        var operator = filter.operator;
        var value = filter.value;

        if (filter.property.type === 'asset') {
          value = (filter.value || []).map(item => item.id);
        }

        return {
          property: property,
          operator: operator,
          value: value
        }
      });
    }

    if (type === 'asset') {
      assetsRequest = (contentSelection.assets || []).map(asset => {
        return asset.id
      })
    }

    if(type === 'placeholder_asset') {
        const assets = contentSelection.assets;
        if(assets == null || assets.length == 0){
          return Promise.resolve();
        }
    
        const promises = [...chunks(assets, 20)].map(chunk => {
          return new Promise((resolve, reject) => {
    
            var request = chunk.map(item => {
              return item
            })
    
            dispatch(addPlaceholderAssetsForWidget(editor, widget.id, request, () => {
              handleAddAssetStateChanged();
              return
            }));
          });
        });
    
        return Promise.all(promises);
    }

    dispatch(updateWidgetContent(
      widget.id,
      {
        content: {
          type: type,
          filters: updatedFilters,
          list: contentSelection.list,
          all: contentSelection.all,
          assets: assetsRequest
        }
      },
      editor,
      (response) => {
        if (type === 'cluster') {
          handleAddAssetStateChanged();
          return;
        }

        //Update Assets
        var assets = (contentSelection.assets || []).filter(item => {
          return item !== null && item.updated;
        });

        if (assets.length === 0) {
          handleAddAssetStateChanged();
          return;
        }

        var request = {
          ...contentSelection,
          updatingAssets: true
        }

        dispatch(updateContentEditor(editor, request));

        setState(state => {
          return {
            ...state,
            showPercentage: true,
            percentage: 0
          }
        })

        var requests = [...chunks(assets, 5)].map(chunk => {
          var request = chunk.map(item => {
            var itemRequest = {
              id: item.id,
              override: item.override
            }

            if (item.override) {
              itemRequest = {
                ...itemRequest,
                name: item.name,
                summary: item.summary,
                alias: item.alias,
                icon: item.isIconSet ? item.icon : null,
              }
            }

            return itemRequest;
          })

          return request;
        });

        updateAssets(widget.id, editor, requests, 0);
      }
    ));
  }

  const updateAssets = (id, editor, requests, index) => {
     var requestSize = requests.length;

     var totalAssets = requests.length * 5;

     if(index === requestSize) {
       handleAddAssetStateChanged();
       return;
     }

     var request = requests[index];

    dispatch(updateAssetsForWidget(editor, id, request, () => {
        var assetsCompleted = (index + 1) * 5;
        
        var percentage = assetsCompleted/totalAssets * 100;

        setState(state => {
          return {
            ...state,
            percentage: percentage
          }
        })

        index += 1;
        updateAssets(id, editor, requests, index)
    }));
  }

  const handleEditContent = () => {
    var contentEditor = guid();
    setState(state => {
      return {
        ...state,
        contentEditor: contentEditor
      }
    });
    dispatch(fetchWidgetContent(widget.id, editor));
  }

  return (
    <>
      {React.cloneElement(trigger, {
        key: "content_trigger",
        onClick: () => {
          handleEditContent();
        }
      })}
     { entityReference && entityReference.type != "page.template" &&
        <AddContent expanded={state.contentEditor !== null}
          editor={state.contentEditor}
          widgetEditor={editor}
          content={contentSelection}
          onSave={handleAddAsset}
          onClose={handleAddAssetStateChanged}
          sortable={true}
          deletable={true}
          editable={true}
          widget={widget}
          percentage={state.percentage}
          showPercentage={state.showPercentage}/>
      }
     {entityReference && entityReference.type === "page.template" && 
        <PlaceholderContent 
          expanded={state.contentEditor !== null}
          editor={state.contentEditor}
          onSave={handleAddAsset}
          onClose={handleAddAssetStateChanged}
          sortable={true}
          deletable={true}
          editable={true}/>
      }
    </>
  );
}