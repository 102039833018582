import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import SockJsClient from 'react-stomp';
import AuthService from 'services/auth.service';
const websocketUrl = process.env.REACT_APP_WEBSOCKET_URL;

export default function WebSocket({children}) {
  const dispatch = useDispatch();
  
  const url = `${websocketUrl}/ws?access_token=${AuthService.getAccessToken()}`;
  const topics = [
    '/user/queue/assetProcessed',
    '/user/queue/datasourceSync',
    '/user/queue/account'
  ];

  const handleMessageReceived = (msg) => {
    console.log(msg);
    dispatch(msg);
  }
  
  return (
    <div>
      <SockJsClient
        url={url} topics={topics}
        onMessage={handleMessageReceived}
      >
      </SockJsClient>
      {children}
    </div>
  )
}