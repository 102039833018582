import { 
  FETCHING_ASSETS, FETCHING_ASSETS_SUCCEEDED, FETCHING_ASSETS_FAILED,
  SEARCH_ASSETS, SEARCHING_ASSETS_SUCCEEDED, SEARCHING_ASSETS_FAILED, INVALIDATE_ASSET_SEARCH,

  UPDATE_FILTERS_IN_CONTENT,

  ADDING_ASSETS_SUCCEEDED, CREATING_ASSET_SUCCEEDED,

  BULK_UPDATING_ASSETS_REQUEST,  BULK_UPDATING_ASSETS_SUCCEEDED, BULK_UPDATING_ASSETS_FAILED,
  DELETING_ASSETS_REQUEST, DELETING_ASSETS_SUCCEEDED, DELETING_ASSETS_FAILED,

  SELECT_ASSET, DESELECT_ASSET, SELECT_ALL_ASSETS, DESELECT_ALL_ASSETS, INVALIDATE_ASSET_SELECTION,

  SWITCH_STORYBOARD,

  FETCHING_CONTENT_SUGGESTIONS, FETCHING_CONTENT_SUGGESTIONS_SUCCEEDED, FETCHING_CONTENT_SUGGESTIONS_FAILED,

  FETCHING_CONTENT_ANALYTICS, FETCHING_CONTENT_ANALYTICS_SUCCEEDED, FETCHING_CONTENT_ANALYTICS_FAILED,
  WS_ASSET_PROCESSED
} from 'constants/actionTypes';

import _ from 'lodash';

const initialState = {
  loading: false,
  invalidated: false,
  updating: false,
  deleting: false,
  items: [], 
  pagination: {
    current: -1,
    totalPages: 1,
    totalRecords: 0
  },
  search: {
    searching: false,
    invalidated: true,
    query: '',
    items: []
  },
  filters: [],
  assetsById: {},
  selection: {
    selectAll: false,
    assets: []
  },
  suggestions: {
    loading: false, 
    items: []
  }
}

export function contentReducer(state = initialState, action) {
  switch (action.type) {
    case FETCHING_ASSETS: 
      return Object.assign({}, state, {
        ...state,
        loading: true,
        pagination: {
          ...state.pagination,
          current: action.payload.page,
        }
      });
    
    case FETCHING_ASSETS_SUCCEEDED:
      var { response } = action.payload;

      var records = response.records.map(item => {
        return {
          ...item,
          loadAnalytics: true,
          loadingAnalytics: false,
          analytics: {}
        }
      })

      var items = state.items;

      if (response.currentPage !== 0) {
        items = [...state.items, ...records];
      } else {
        items = records
      }
      
      items = items.reduce((x, y) => {
        var index = x.findIndex((e) => {
          return e.id === y.id;
        });

        if (index < 0) {
          return [...x, y];
        } else {
          x[index] = y;
          return x;
        }
      }, []);

      return Object.assign({}, state, {
        ...state,
        loading: false,
        updating: false,
        deleting: false,
        items: items,
        pagination: {
          ...state.pagination,
          current: response.currentPage,
          totalPages: response.totalPages,
          totalRecords: response.totalRecordsCount
        }
      });

    case FETCHING_ASSETS_FAILED:
      var { error } = action.payload;

      return Object.assign({}, state, {
        ...state,
        loading: false,
        error: error
      });

    case FETCHING_CONTENT_ANALYTICS:
      var assetIds = action.payload;
      
      var records = state.items.map(item => {
        if (assetIds.includes(item.id)) {
          return {
            ...item,
            loadAnalytics: false,
            loadingAnalytics: true,
          }
        }

        return item;
      });
      
      return {
        ...state,
        items: records
      };

    case FETCHING_CONTENT_ANALYTICS_SUCCEEDED:
      var response = action.payload.response;
      var assetIds = action.payload.assets;

      var  records = state.items.map(item => {
        if(assetIds.includes(item.id)) {
          var analytics = response.find(asset => {
            return item.id === asset.id;
          });

          return {
            ...item,
            loadingAnalytics: false,
            analytics: analytics || {}
          }
        }

        return item;
      })

      return {
        ...state,
        items: records
      };

    case FETCHING_CONTENT_ANALYTICS_FAILED:
      var error = action.payload.error;
      var assetIds = action.payload.assets;

      var records = state.items.map(item => {
        if (assetIds.includes(item.id)) {
          return {
            ...item,
            loadingAnalytics: false,
          }
        }

        return item;
      })

      return {
        ...state,
        items: records,
        error: error
      };

    case SEARCH_ASSETS:
      return Object.assign({}, state, {
        ...state,
        search: {
          ...state.search,
          searching: true,
          query: action.payload.query
        }
      });

    case SEARCHING_ASSETS_SUCCEEDED:
      let query = action.payload.query;

      if(query !== state.search.query) {
        return state;
      }

      var assetsById = {...assetsById};
      var items = action.payload.response.records.map(item => {
        assetsById[item.id] = item;
        return item.id;
      });

      return Object.assign({}, state, {
        ...state,
        assetsById: assetsById,
        search: {
          ...state.search,
          searching: false,
          invalidated: false,
          items: items,
        }
      });

    case SEARCHING_ASSETS_FAILED:
      return Object.assign({}, state, {
        ...state,
        search: {
          ...state.search,
          searching: false,
          invalidated: true,
          items: []
        }
      });

    case INVALIDATE_ASSET_SEARCH:
      return Object.assign({}, state, {
        ...state,
        search: {
          ...state.search,
          searching: false,
          invalidated: true,
          items: []
        }
      });

    
    case UPDATE_FILTERS_IN_CONTENT:
      return Object.assign({}, state, {
        ...state,
        filters: action.payload.filters
      });      

    case CREATING_ASSET_SUCCEEDED:
      var { asset } = action.payload;

      var items = _.get(state, 'items', []);
      items = [asset, ...items];
      
      items = items.reduce((x, y) => {
        var index = x.findIndex((e) => {
          return e.id === y.id;
        });

        if (index < 0) {
          return [...x, y];
        } else {
          x[index] = y;
          return x;
        }
      }, []);

      return Object.assign({}, state, {
        ...state,
        items: items
      });
    
    case DELETING_ASSETS_REQUEST:
      return {
        ...state,
        deleting: true
      }

    case DELETING_ASSETS_SUCCEEDED:
      return {
        ...state,
        deleting: false
      }

    case DELETING_ASSETS_FAILED: 
      return {
        ...state,
        deleting: false
      }

    
    case BULK_UPDATING_ASSETS_REQUEST: 
      return {
        ...state,
        updating: true
      }

    case BULK_UPDATING_ASSETS_SUCCEEDED: 
      return {
        ...state,
        updating: false
      }
      
    case BULK_UPDATING_ASSETS_FAILED: 
      return {
        ...state,
        updating: false,
        error: action.payload.error
      }  


    case SELECT_ASSET: 
      var assetIds = (action.payload || []).map(item => {
        return item.id;
      })

      var assets = [...state.selection.assets, ...assetIds];

      assets = assets.reduce((x, y) => {
        var index = x.findIndex((e) => {
          return e === y;
        });

        if (index < 0) {
          return [...x, y];
        } else {
          x[index] = y;
          return x;
        }
      }, []);

      return {
        ...state,
        selection: {
          ...state.selection,
          selectAll: false,
          assets: assets
        }
      }
    
    case DESELECT_ASSET:
      asset = action.payload;

      return {
        ...state,
        selection: {
          ...state.selection,
          selectAll: false,
          assets: state.selection.assets.filter(item => {
            return item !== asset.id;
          })
        }
      }

    case SELECT_ALL_ASSETS:
      return {
        ...state,
        selection: {
          ...state.selection,
          selectAll: true,
          assets: []
        }
      }

      case DESELECT_ALL_ASSETS:
        return {
          ...state,
          selection: {
            ...state.selection,
            selectAll: false,
            assets: []
          }
        }  

    case INVALIDATE_ASSET_SELECTION: 
      return {
        ...state,
        selectedAssets: []
      }

    case SWITCH_STORYBOARD:
      return initialState;  

    case FETCHING_CONTENT_SUGGESTIONS: 
      return {
        ...state,
        suggestions: {
          ...state.suggestions,
          loading: true
        }
      }

    case FETCHING_CONTENT_SUGGESTIONS_SUCCEEDED:
      return {
        ...state,
        suggestions: {
          ...state.suggestions,
          loading: false,
          items: action.payload.response
        }
      }

    case FETCHING_CONTENT_SUGGESTIONS_FAILED:
      return {
        ...state,
        suggestions: {
          ...state.suggestions,
          loading: false,
          items: [],
          error: action.payload.error
        }
      }

    case WS_ASSET_PROCESSED:
      var assets = state.items.map(item => {
        if (item.id === action.payload.id) {
          return action.payload;
        } else {
          return item;
        }
      });

      return {
        ...state,
        items: assets
      }  

    default: 
      return state;
  }
}