import { AUTHENTICATE_MARKETO_REQUEST, AUTHENTICATE_MARKETO_SUCCEEDED, AUTHENTICATE_MARKETO_FAILED,
  FETCH_MARKETO_FORMS, FETCHING_MARKETO_FORMS_SUCCEEDED, FETCHING_MARKETO_FORMS_FAILED,
  SEARCH_MARKETO_FORM, SEARCH_MARKETO_FORM_SUCCEEDED, SEARCH_MARKETO_FORM_FAILED
} from 'constants/actionTypes';
import _ from 'lodash';

const initialState = {
  changes: {
    saving: false,
    error: null,
    configuration: {}
  },
  forms: {
    loading: false,
    items: [],
    page: 0,
    limit: 20,
    loadMore: false,
    message:"",
    searching: false
  }
  
};

function marketoReducer(state = initialState, action) {
  switch (action.type) {
    case AUTHENTICATE_MARKETO_REQUEST:
      return {
        ...state,
        changes: {
          saving: true,
          error: null
        }
      }

    case AUTHENTICATE_MARKETO_SUCCEEDED:
      const {configuration} = action.payload;
      return {
        ...state,
        changes: {
          ...state.changes,
          saving: false,
          configuration: configuration,
          error: null
        }
      }

    case AUTHENTICATE_MARKETO_FAILED: 
      return {
        ...state,
        changes: {
          ...state.changes,
          saving: false,
          error: action.payload
        }
      }

      case FETCH_MARKETO_FORMS:
      return {
        ...state,
        forms: {
          ...state.forms,
          loading: true
        }
      }

    case FETCHING_MARKETO_FORMS_SUCCEEDED:
   
      var value = action.payload.response[0];
      var isItems = true;
      var items = state.forms.items;

      if (typeof value === 'string' || value instanceof String) {
        isItems = false;
      } else {
        var message = "";
        isItems = true;
    
        if (state.forms.page !== 0) {
          items = [...items, ...action.payload.response];
          items = _.orderBy(items, [form => form.name.toLowerCase()], ['asc']);
        } else {
          items = action.payload.response
        }
    
        items = items.reduce((x, y) => {
          var index = x.findIndex((e) => {
            return e.id === y.id;
          });
    
          if (index < 0) {
            return [...x, y];
          } else {
            x[index] = y;
            return x;
          }
        }, []);
      }
    
      return {
        ...state,
        forms: {
          ...state.forms,
          loading: false,
          items: items,
          page: action.payload.page + action.payload.limit,
          message: message,
          loadMore: isItems,
        }
      }

    case FETCHING_MARKETO_FORMS_FAILED:
      return {
        ...state,
        forms: {
          ...state.forms,
          loading: false,
          error: action.payload
        }
      }

    case SEARCH_MARKETO_FORM:
      return {
        ...state,
        forms: {
          ...state.forms,
          loading: true,
          searching: true
        }
      }


    case SEARCH_MARKETO_FORM_SUCCEEDED:

      var value = action.payload[0];
      var items = [];
      var message = "";

      if (typeof value === 'string' || value instanceof String) {
        items = [];
        message = action.payload[0];
      } else {
        items = action.payload;
        message="";
      }

      return {
        ...state,
        forms: {
          ...state.forms,
          loading: false,
          items: items,
          message: message,
          loadMore: false,
          searching: false
        }
      }

    default: 
      return state;
  }
}

export { marketoReducer }