import { 
  UPLOADING_FILE_PROGRESS, REMOVE_ASSET_SELECTION, INVALIDATE_ASSET_SELECTION 
} from 'constants/actionTypes.jsx';

import { createAction } from 'redux-actions';

export function uploadingFileProgress(file, progress){
    return {
      type: UPLOADING_FILE_PROGRESS,
      payload: {
        progress: progress,
        file: file
      }
    }
}  

export function deselectAsset(item) {
  return {
    type: REMOVE_ASSET_SELECTION,
    payload: item
  }
}

export function invalidateAssetSelection() {
  return {
    type: INVALIDATE_ASSET_SELECTION,
    payload: {}
  }
}