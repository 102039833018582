import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { updateProperties, updateWidget } from 'actions/widgetEditor';

import _ from 'lodash';
import cx from 'classnames';

import Flicking from "@egjs/react-flicking";
import html2canvas from 'html2canvas';

import { WidgetRegistry } from '../registry.jsx';
import { QuickSettings, QuickSettingsProperty } from 'components/QuickSettings';
import { Widget, getStyles } from '../widget.jsx';
import { ItemEntity, CoverEntity, ContentEntity } from "./item";
import { Button, Icon, Portal, Transition, Image } from 'semantic-ui-react';
import { AnimatePresence, motion, useAnimation } from 'framer-motion';
import AfterEffect from 'components/AfterEffect/index.jsx';

import { ManageSectionModal } from './sections';

import { useWidgetDrop } from "services/dnd.service";

import styles from './interactive.module.scss';

export const InteractiveContext = React.createContext({
  active: false,
  onScroll: () => { }
});

export const InteractiveContent = React.memo((props) => {
  const ref = React.useRef(null);
  const carouselRef = React.useRef(null);
  const dropRef = useWidgetDrop(props, ref, {});

  const [currentItem, setCurrentItem] = useState(0);
  const [disableCarousel, setDisableCarousel] = useState(false);

  const editable = props.editable;
  const properties = props.properties;
  var widgetStyles = getStyles(properties);

  useEffect(() => {
    if (!editable) {
      window.addEventListener('keydown', onKeyDown);
    }

    return () => {
      window.removeEventListener('keydown', onKeyDown);
    }
  }, [disableCarousel, editable]);

  useEffect(() => {
    if (carouselRef.current) {
      carouselRef.current.resize();
      console.log("refreshed carousel");
    }
  }, [currentItem]);

  const onKeyDown = (e) => {
    console.log("Disabled scroll: " + disableCarousel);
    if (disableCarousel) {
      return;
    }

    e = e || window.event;

    if (e.keyCode == '37') {
      //Move to the previous section when left arrow is pressed
      carouselRef.current.prev();
    } else if (e.keyCode == '39') {
      //Move to the next section when right arrow is pressed
      carouselRef.current.next();
    }
  };

  return (
    <div className={cx(styles.interactive, props.className)}
      id={`widget_${props.id}`}
      ref={props.dragRef(dropRef(ref))}
      onMouseOver={props.onMouseOver}
      onMouseOut={props.onMouseOut}
      onClick={props.onClick}
      style={widgetStyles}
    >
      <InteractiveContext.Provider value={{
        active: props.widgets[currentItem],
        onScroll: (scrolled) => {
          setDisableCarousel(scrolled);
        }
      }}>
        {!editable &&
          <ItemsPagination show={!disableCarousel}
            items={props.widgets}
            active={currentItem}
            editor={props.editor}
            onSelect={(index) => {
              carouselRef.current.moveTo(index, 1000);
            }}
          />
        }

        <Navigation
          show={!disableCarousel}
          color={properties.arrowsColor}
          current={currentItem}
          total={(props.widgets || {}).length}
          onChange={(index) => {
            carouselRef.current.moveTo(index);
          }}
        />

        <Flicking
          ref={carouselRef}
          gap={0}
          duration={800}
          hanger="50%"
          anchor="50%"
          autoResize={true}
          bound={false}
          adaptive={true}
          isEqualSize={false}
          isConstantSize={false}
          collectStatistics={false}
          plugins={editable ? [] : [new Parallax()]}
          panelEffect={(x) => {
            return 1 - Math.pow(1 - x, 3);
          }}
          onChange={(e) => {
            setCurrentItem(e.index);
            carouselRef.current.resize();
          }}
          onHoldStart={(e) => {
            if (disableCarousel) {
              e.stop();
            }
          }}
        >
          <Widget.Children widgets={props.widgets} parent={props.id} editor={props.editor} editable={props.editable} />
        </Flicking>

        {props.children}
      </InteractiveContext.Provider>
    </div>
  );
});

function Navigation({ show, color, current, total, onChange }) {
  if (total < 1) {
    return false;
  }

  var arrowColor = `rgba(${_.get(color, 'r') || 0}, ${_.get(color, 'g') || 0}, ${_.get(color, 'b') || 0}, ${_.get(color, 'a') || 1})`;

  const buttonStyle = {
    backgroundColor: `${arrowColor}`,
    padding: 20
  }

  const handlePrevious = () => {
    if (current == 0) {
      return;
    }

    onChange(current - 1);
  }

  const handleNext = () => {
    if (current >= total - 1) {
      return;
    }

    onChange(current + 1);
  }

  return (
    <>
      {current > 0 &&
        <Button className={cx(styles.arrow, styles.previous, {
          [styles.show]: show
        })}
          style={buttonStyle}
          icon circular size='big'
          disabled={current == 0}
          onClick={handlePrevious}
        >
          <Icon name="chevron left" />
        </Button>
      }
      {current < total - 1 &&
        <Button className={cx(styles.arrow, styles.next, {
          [styles.show]: show
        })}
          style={buttonStyle}
          icon circular size='big'
          disabled={current >= total - 1}
          onClick={handleNext}
        >
          <Icon name="chevron right" />
        </Button>
      }
    </>
  );
}

class Parallax {
  constructor() {
    this.onMove = this.onMove.bind(this);
  }

  init(flicking) {
    flicking.on("move", this.onMove);
    this.move(flicking);
  }

  update(flicking) {
    this.move(flicking);
  }

  destroy(flicking) {
    flicking.off("move", this.onMove);
  }

  onMove(e) {
    this.move(e.currentTarget);
  }

  move(flicking) {
    const panels = flicking.getVisiblePanels();

    panels.forEach((panel, index) => {
      const progress = panel.getOutsetProgress();
      const el = panel.getElement();
      const target = el.querySelector(`.${styles.cover} .${styles.inner}`);
      const parentTarget = target.parentNode;
      const rect = target.getBoundingClientRect();
      const parentRect = parentTarget.getBoundingClientRect();
      const position = -0.80 * rect.width * progress;
      const transform = `translate(-50%) translate(${position}px)`;
      const style = target.style;

      style.cssText += `transform: ${transform};-webkit-transform: ${transform};-ms-transform:${transform}`;
    });
  }
}

function ItemsPagination({ show, items, editor, active, onSelect }) {

  var itemName = useSelector(state => {
    return _.get(state, `widgetsEditor.editors[${editor}].widgetsById[${items[active]}].name`);
  });

  var itemDescription = useSelector(state => {
    return _.get(state, `widgetsEditor.editors[${editor}].widgetsById[${items[active]}].properties.sectionDescription`);
  });

  var image = useSelector(state => {
    return _.get(state, `widgetsEditor.editors[${editor}].widgetsById[${items[active]}].properties.icon.thumbnail`);
  });

  const progressRef = React.useRef(null);

  const [open, setOpen] = useState(false);
  const [exiting, setExiting] = useState(false);
  const [hover, setHover] = useState(false);
  const [mystyle, setMystyle] = useState({ marginLeft: -230 });
  const [activeItem, setActiveItem] = useState(active);

  const controls = useAnimation();

  useEffect(() => {
    setActiveItem(active);
  }, [active])

  useEffect(() => {
    if (activeItem >= 0 && progressRef.current != null) {
      progressRef.current.style.width = ((activeItem + 1) / items.length) * 100 + "%";
    }
    setMystyle({
      marginLeft: -(230 * (activeItem))
    });
    controls.start("visible");
  }, [activeItem, open])


  const variants = {
    open: {
      transition: { opacity: 1, staggerChildren: 0.07 }
    },
    closed: {
      transition: { opacity: 0, staggerChildren: 0.05, staggerDirection: -1 }
    }
  };

  const headingVariant = {
    visible: {
      opacity: 1,
      y: 0,
      transition: { ease: "easeIn", duration: 0.7 }
    },
    hidden: {
      opacity: 0,
      y: 20,
      transition: { ease: "easeOut", duration: 0.05 }
    }
  }

  const subHeadingVariant = {
    visible: {
      opacity: 1,
      y: 0,
      transition: { ease: "easeIn", duration: 1 }
    },
    hidden: {
      opacity: 0,
      y: 20,
      transition: { ease: "easeOut", duration: 0.03 }
    }
  }

  const prevArrowVariant = {
    visible: {
      opacity: 1,
      x: 0,
      transition: { ease: "easeIn", duration: 1 }
    },
    hidden: {
      opacity: 0,
      x: -20,
      transition: { ease: "easeOut", duration: 0.03 }
    }
  }

  const nextArrowVariant = {
    visible: {
      opacity: 1,
      x: 0,
      transition: { ease: "easeIn", duration: 0.8 }
    },
    hidden: {
      opacity: 0,
      x: -50,
      transition: { ease: "easeOut", duration: 0.03 }
    }
  }



  return (
    <div className={cx(styles.pagination, {
      [styles.show]: show
    })}>
      <Portal
        open={open}
        closeOnTriggerClick
        openOnTriggerClick
        trigger={
          <div className={styles.dots}
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
          >
            <Image className={styles.hamburger} src='/images/widgets/hamburger.svg' />
          </div>
        }
        onOpen={() => {
          setOpen(true);
          setExiting(false);
        }}
        onClose={() => { setExiting(true) }}
      >

        <div className={styles.paginationMenu} style={{
          position: 'fixed'
        }}>
          <div className={styles.overlay} style={{
          }}>
            <Image src={image ? image : '/images/widgets/defaultMenuBackground.png'} />
          </div>
          <AnimatePresence onExitComplete={() => {
            setOpen(false);
          }}>
            {!exiting &&
              <>
                {activeItem >= 0 &&
                  <div>
                    <motion.div
                      initial="hidden"
                      animate={controls}
                      exit="hidden"
                      variants={headingVariant}
                    >
                      <span className={styles.itemName}> {itemName} </span>
                    </motion.div>
                    <motion.div
                      initial="hidden"
                      animate="visible"
                      exit="hidden"
                      variants={subHeadingVariant}
                    >
                      <span className={styles.itemDescription}> {itemDescription} </span>
                    </motion.div>

                  </div>
                }
                <motion.div
                  key='menu'
                  initial='closed'
                  variants={variants}
                  animate={exiting ? "closed" : "open"}
                  exit={'closed'}
                  className={styles.menu}
                  style={mystyle}
                >
                  {items.map((item, index) => {
                    return (
                      <>
                        <ItemPreview key={index}
                          index={index}
                          active={index === activeItem}
                          item={item}
                          editor={editor}
                          onClick={() => { setActiveItem(index); onSelect(index) }}
                        />
                      </>
                    )
                  })}
                </motion.div>
                <div className={styles.arrows}>
                  <motion.div
                    initial="hidden"
                    animate="visible"
                    exit="hidden"
                    variants={prevArrowVariant}
                  >
                  <Image onClick={() => activeItem > 0 ? setActiveItem(activeItem - 1) : activeItem} width={40} src='/images/widgets/menu_previous_button.svg' />
                  </motion.div>
                  <motion.div
                    initial="hidden"
                    animate="visible"
                    exit="hidden"
                    variants={nextArrowVariant}
                  >
                    <Image onClick={() => activeItem < items.length ? setActiveItem(activeItem + 1) : items.length} width={40} src='/images/widgets/menu_next_button.svg' />
                  </motion.div>
                </div>
                <motion.div
                  initial="hidden"
                  animate="visible"
                  exit="hidden"
                  variants={headingVariant}
                >
                  <div className={styles.progress}>
                    <div
                      ref={progressRef}
                    />
                  </div>
                </motion.div>
                <motion.div
                  initial="hidden"
                  animate="visible"
                  exit="hidden"
                  variants={headingVariant}
                >
                <span className={styles.exit}>Press <span className={styles.escText}>esc</span> to head back</span>
                </motion.div>
              </>
            }
          </AnimatePresence>
        </div>

      </Portal>
    </div>
  )
}

function ItemPreview({ index, active, item, editor, onClick }) {
  const image = useSelector(state => {
    return _.get(state, `widgetsEditor.editors[${editor}].widgetsById[${item}].properties.icon.thumbnail`);
  });

  const variants = {
    open: {
      y: 0,
      opacity: 1,
      transition: {
        y: { stiffness: 1000, velocity: -100 }
      }
    },
    closed: {
      y: -100,
      opacity: 0,
      transition: {
        y: { stiffness: 1000 }
      }
    }
  };

  return (
    <>
      <motion.div key={index} variants={variants} className={cx(styles.preview, {
        [styles.active]: active
      })}
        onClick={() => onClick(index)}>
        <div className={styles.inner}>
          {image &&
            <img className={styles.image} src={image} />
          }

          {!image &&
            <Icon loading name='spinner' />
          }
        </div>
      </motion.div>
    </>
  );
}

InteractiveContent.QuickAction = function QuickAction(props) {
  return (
    <ManageSectionModal trigger={
      <Widget.Action className={styles.quickAction}>
        Manage Sections
      </Widget.Action>
    } widget={props.widget} editor={props.editor} />
  );
}

InteractiveContent.QuickSettings = function CarouselQuickSettings(props) {
  const dispatch = useDispatch();

  const [selected, setSelected] = useState('basic');

  const menuItems = [
    { id: 'basic', title: 'Basic', icon: 'icon-custom' },
    { id: 'spacing', title: 'Spacing', icon: 'icon-spacing' },
    { id: 'background', title: 'Background', icon: 'icon-background' },
    { id: 'border', title: 'Border', icon: 'icon-border' },
    { id: 'advanced', title: 'Advanced', icon: 'icon-custom' }
  ]

  const handleMenuClick = (item) => {
    setSelected(item);
  }

  var widget = useSelector(state => {
    const editorContext = state.widgetsEditor.editors[props.editor];

    if (editorContext) {
      return editorContext.widgetsById[props.id];
    }

    return null;
  });
  if (!widget) {
    return false;
  }

  var properties = widget.properties;

  const handleChange = (changeRequest) => {
    dispatch(updateProperties({
      id: widget.id,
      change: changeRequest,
      context: props.editor
    }));
  }

  const handleBackgroundImageChange = (media, type) => {
    var changeRequest = { ...widget };
    _.set(changeRequest, 'properties.background.upload', {
      type: type,
      data: media
    });

    changeRequest = WidgetRegistry.processRequest([changeRequest])[0];
    dispatch(updateWidget(props.id, changeRequest, props.editor));
  }

  const handleCssChange = (css) => {
    var changeRequest = { ...widget };
    _.set(changeRequest, 'properties.css', css);

    changeRequest = WidgetRegistry.processRequest([changeRequest])[0];
    dispatch(updateWidget(props.id, changeRequest, props.editor));
  }

  return (
    <QuickSettings menuItems={menuItems} selected={selected} onMenuClick={handleMenuClick} >
      {selected === 'basic' &&
        <>
          <QuickSettingsProperty.Switch
            tooltip='Show dots pagination'
            title='Dots'
            value={properties.dots}
            onChange={() => {
              handleChange({
                dots: !properties.dots
              })
            }}
          />

          {properties.dots &&
            <QuickSettingsProperty.Color
              title='Dots Color'
              value={properties.dotsColor}
              onChange={(value) => {
                handleChange({
                  dotsColor: value
                })
              }}
            />
          }

          <QuickSettingsProperty.Switch
            tooltip='Show arrows for navigation'
            title='Arrows'
            value={properties.arrows}
            onChange={() => {
              handleChange({
                arrows: !properties.arrows
              })
            }}
          />

          {properties.arrows &&
            <QuickSettingsProperty.Color
              title='Arrow Color'
              value={properties.arrowsColor}
              onChange={(value) => {
                handleChange({
                  arrowsColor: value
                })
              }}
            />
          }
        </>
      }

      {selected === 'spacing' &&
        <>
          <QuickSettingsProperty.Spacing
            title='Margin'
            value={properties.margin}
            onChange={(margin) => {
              handleChange({
                margin: margin
              });
            }}
          />
          <QuickSettingsProperty.Spacing
            title='Padding'
            value={properties.padding}
            onChange={(padding) => {
              handleChange({
                padding: padding
              });
            }}
          />
        </>
      }

      {selected === "background" &&
        <QuickSettingsProperty.Background expanded
          value={properties.background}
          onChange={(background) => {
            handleChange({
              background: background
            });
          }}
          onImageChange={(image, type) => {
            handleBackgroundImageChange(image, type);
          }}
        />
      }

      {selected === 'border' &&
        <QuickSettingsProperty.Border expanded value={properties.border} onChange={(border) => {
          handleChange({
            border: border
          });
        }}
        />
      }

      {selected === 'advanced' &&
        <QuickSettingsProperty.CSS title='Stylesheets' value={properties.css} onChange={handleCssChange} />
      }
    </QuickSettings>
  )
}

InteractiveContent.Item = ItemEntity;
InteractiveContent.Cover = CoverEntity;
InteractiveContent.Content = ContentEntity;