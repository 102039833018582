import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory, useLocation } from "react-router-dom";
import _ from 'lodash';

import {
  Button,
  Form,
  Header,
  Input,
  Loader
} from 'semantic-ui-react';

import {
 fetchAccountInvitation
} from 'actions/users'

import styles from './acceptInvitation.module.scss'

import AnonymousPage from '../common/index.jsx';

import { registerUser } from 'actions/users';

export default function AcceptInvitation(props) {

  const dispatch = useDispatch();
  const history = useHistory();

  const [password, setPassword] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [errors, setErrors] = useState({});
  const [invitationError, setInvitationError] = useState(null);

  const email = useSelector(state => _.get(state, 'users.invitation.item.email'));
  const registering = useSelector(state => _.get(state, 'users.registration.loading', false));
  const userId = useSelector(state => _.get(state, 'users.registration.item.id', null));
  const invitationErrors = useSelector(state => _.get(state, 'users.invitation.error.errors', null));
  const loading = useSelector(state => _.get(state, 'users.invitation.loading', false))

  const location = useLocation();
  const paths = location.pathname.split('/');
  const paramsString = location.search;
  const params = new URLSearchParams(paramsString);
  var key = params.get('key');

  useEffect(() => {
    dispatch(fetchAccountInvitation(paths[2], key));
  }, []);

  useEffect(() => {
    if (userId){
      console.log(userId);
      history.push(`/login`);
    }
    console.log(userId);
  }, [userId]);

  useEffect(() => {
    if (invitationErrors){
      console.log(invitationErrors[0].message);
      if (invitationErrors[0].code) {
        setInvitationError(invitationErrors[0].code)
      }
    }
  }, [invitationErrors])

  const onSignUp = (e) => {
    e.preventDefault();

    const error = validateForm;

    if (Object.keys(error).length !== 0) {
      setErrors(error);
      return;
    }

    let user = {
      firstName: firstName,
      lastName: lastName,
      email: email,
      password: password
    }

    dispatch(registerUser(paths[2], user));

  }

  const validateForm = () => {
    let errors = {};
    
    if (!firstName || firstName.trim() === '') {
      errors.firstName = 'Enter a valid name';
    }

    if (!password || password.length < 8) {
      errors.password = "Password should contains atleast 8 characters"
    }

    return errors;
  }

  const renderAuthenicateTitle = () => {
    if (registering) {
      return 'Signing Up...';
    } else return 'Sign Up'
  }

  return (
    <AnonymousPage>
      {invitationError == null && !loading &&
        <div>
          <Header as="h2" textAlign="center" className={styles.header}>
            Please enter details for your {email} account
           </Header>
          <Form onSubmit={onSignUp} autoComplete="false">
            <Form.Field
              control={Input}
              value={email}
              placeholder="Email"
              readOnly={true}
            >
            </Form.Field>
            <Form.Field>
              <Input type='password' placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </Form.Field>
            <div className={styles.error}>{errors.password}</div>
            <Form.Field
              control={Input}
              value={firstName}
              placeholder="First Name"
              onChange={(e) => setFirstName(e.target.value)}
            >
            </Form.Field>
            <div className={styles.error}>{errors.firstName}</div>
            <Form.Field
              control={Input}
              value={lastName}
              placeholder="Last Name"
              onChange={(e) => setLastName(e.target.value)}
            >
            </Form.Field>

            <Button primary fluid type="submit" >
              {renderAuthenicateTitle()}
            </Button>
          </Form>
        </div>
      }
      {invitationError !== null && !loading &&
      <div>
        <Header as="h2" textAlign="center" className={styles.header}>
          Unable to activate invitation
        </Header>
        <p className={styles.invitationError}>This invite seems to be invalid. Get in touch with your administrator.</p>
      </div>
      }
      <Loader active={loading} content='Loading...' />
    </AnonymousPage>
  )
}