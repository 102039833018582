export const defaultFonts = [{
  name: 'Centra No2',
  fontFamily: "'Centra No2', sans-serif"
}, {
  name: 'Andale Mono',
  fontFamily: 'andale mono,times'
}, {
  name: 'Arial',
  fontFamily: 'arial,helvetica,sans-serif'
}, {
  name: 'Arial Black',
  fontFamily: 'arial black,avant garde'
}, {
  name: 'Book Antiqua',
  fontFamily: 'book antiqua,palatino'
}, {
  name: 'Comic Sans MS',
  fontFamily: 'comic sans ms,sans-serif'
}, {
  name: 'Courier New',
  fontFamily: 'courier new,courier'
}, {
  name: 'Georgia',
  fontFamily: 'georgia,palatino'
}, {
  name: 'Helvetica',
  fontFamily: 'helvetica'
}, {
  name: 'Impact',
  fontFamily: 'impact,chicago'
}, {
  name: 'Symbol',
  fontFamily: 'symbol'
}, {
  name: 'Tahoma',
  fontFamily: 'tahoma,arial,helvetica,sans-serif'
}, {
  name: 'Times New Roman',
  fontFamily: 'times new roman,times'
}, {
  name: 'Trebuchet MS',
  fontFamily: 'trebuchet ms,geneva'
}, {
  name: 'Verdana',
  fontFamily: 'verdana,geneva'
}, {
  name: 'Webdings',
  fontFamily: 'webdings'
}, {
  name: 'Wingdings',
  fontFamily: 'wingdings,zapf dingbat'
}];