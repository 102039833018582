export const TODAY = 'today';
export const YESTERDAY = 'yesterday';
export const SEVEN_DAYS = '7Days';
export const FOURTEEN_DAYS = '14Days';
export const WEEK_TO_DATE = 'WTD';
export const MONTH_TO_DATE = 'MTD';
export const THIRTY_DAYS = '30Days';
export const NINETY_DAYS = '90Days';
export const CUSTOM = 'custom';

export const DATE_RANGES = [{
  label: TODAY,
  value: 'Today'
},{
  label: YESTERDAY,
  value: 'Yesterday'
},{
  label: SEVEN_DAYS,
  value: '7 Days'
},{
  label: FOURTEEN_DAYS,
  value: '14 Days'
},{
  label: WEEK_TO_DATE,
  value: 'Week to Date'
},{
  label: MONTH_TO_DATE,
  value: 'Month to Date'
},{
  label: THIRTY_DAYS,
  value: '30 Days'
},{
  label: NINETY_DAYS,
  value: '90 Days'
}];