import React, { PropTypes, Fragment, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';

import cx from 'classnames';
import _ from 'lodash';
import dateformat from 'dateformat';
import FileService from 'services/file.service.jsx';

import {
  Button,
  Image,
  Header,
  Segment
} from 'semantic-ui-react';


import styles from './asset.module.scss';

export default function AnalyticsView({ asset, ...rest }) {
  const customFields = useSelector(state => { return state.customFields.items });

  return (
    <div className={styles.detailView}>
      <div className={styles.basicInfo}>
        <Image
          as='div'
          className={styles.icon}
          src={_.get(asset, 'icon.thumbnail','/images/default_asset.jpg')} />
        <Header as='h3'>{asset.name}</Header>
      </div>

      <AssetDetails asset={asset} />

      <Segment basic className={styles.summary}>
        <p>{asset.summary}</p>
      </Segment>

      <Segment basic className={styles.tags}>
        {(asset.tags || []).map((tag, index) => <Tag name={tag} />)}
      </Segment>

      <div className={styles.metadata}>
        <div>
          <label>Publisher:</label>
          <span>{asset.author.firstName} {asset.author.lastName}</span>
        </div>
        <div>
          <label>Last update:</label>
          <span>{dateformat(asset.modifiedDate, 'dd mmm yyyy')}</span>
        </div>

        <CustomFields customFields={customFields} asset={asset} />
      </div>
    </div>
  )
}

function AssetDetails({asset, ...rest}){
  const renderAdditionalInfo = (asset) => {
    var detail = '';

    if(asset.metadata.content_length > 0){
      detail = `${FileService.formatSize(asset.metadata.content_length)} | `;
    }

    detail = `${detail}${FileService.fileType(asset.metadata)}`;

    if(asset.metadata.pages){
      detail = `${detail} | ${asset.metadata.pages} Pages`;
    }else if(asset.metadata.video_duration){
      detail = `${detail} | ${FileService.formatDuration(asset.metadata.video_duration)}`;
    }

    detail = `${detail} | ${asset.source || "Paperflite Cloud"}`;

    return detail;
  }

  return (
    <div className={styles.additionalInfo}>{renderAdditionalInfo(asset)}</div>
  );
}

function Tag({ name, ...rest }) {
  return (
    <Button basic className={styles.tag}>#{name}</Button>
  );
}

function CustomFields({ customFields, asset, ...rest }) {
  const [showAll, setShowAll] = useState(false);

  if (!customFields || customFields.length === 0) {
    return null;
  }

  var assetFields = asset.customFields || [];
  var count = assetFields.length;
  if (count === 0) {
    return null;
  }

  var fields = [];
  if (!showAll) {
    var assetField = assetFields[0];
    var customField = customFields.find(field => {
      return field.id === assetField.id;
    })

    fields.push(<CustomField assetField={assetField} customField={customField} />)
  } else {
    fields = assetFields.map(item => {
      var customField = customFields.find(field => {
        return field.id === item.id;
      })

      return (
        <CustomField assetField={item} customField={customField} />
      )
    })
  }

  return (
    <Fragment>
      {fields}

      {count > 1 &&
        <Button basic 
          className={styles.showMoreButton} 
          onClick={setShowAll.bind(this, !showAll)}>{!showAll ? 'show more' : 'show less'}</Button>}
    </Fragment>
  );
}

function CustomField({ assetField, customField }) {
  if (!customField) {
    return null;
  }

  var options = assetField && assetField.value || [];

  if (_.isEmpty(options) && !_.isEmpty(customField.defaultValue)) {
    options.push(customField.defaultValue);
  }

  if (_.isEmpty(options)) {
    return null;
  }

  const values = options.map(item => {
    var isLong = false;
    var value = item.value;
    if (value.trim().length > 40) {
      value = value.substring(0, 29);
      isLong = true;
    }
    return (
      <Button basic className={styles.tag}>#{value}{isLong && `...`}</Button>
    )
  })

  return (
    <div className={styles.customField}>
      <label className={styles.label}>{customField.name || ''}:</label>
      {values}
    </div>
  );
}