
import { 
  FETCHING_USERS_REQUEST, FETCHING_USERS_SUCCEEDED, FETCHING_USERS_FAILED,
  INVITE_USER_REQUEST, INVITE_USER_SUCCEEDED, INVITE_USER_FAILED,
  UPDATE_USER_ACCESS, UPDATE_USER_ACCESS_SUCCEEDED, UPDATE_USER_ACCESS_FAILED,
  FETCHING_INVITATIONS_REQUEST, FETCHING_INVITATIONS_SUCCEEDED, FETCHING_INVITATIONS_FAILED,
  RESEND_USER_INVITATION, RESEND_USER_INVITATION_SUCCEEDED, RESEND_USER_INVITATION_FAILED,
  DELETE_USER_INVITATION, DELETE_USER_INVITATION_SUCCEEDED, DELETE_USER_INVITATION_FAILED
} from 'constants/actionTypes.jsx';

const initialState = {
  error: {},
  users: {
    loading: false,
    invalidated: true,
    items: [],
    user: {
      updatingAccess: false
    },
    pagination: {
      current: -1,
      totalPages: 1
    },
    updatingAccess: false
  },
  invitations: {
    loading: false,
    invalidated: true,
    items: [],
    invitation: {},
    pagination: {
      current: -1,
      totalPages: 1
    },
    deleting: false,
    updating: false
  }
}

function userManagementReducer(state = initialState, action) {
  switch (action.type) {
    case FETCHING_USERS_REQUEST:
      return {
        ...state,
        users: {
          ...state.users,
          loading: true,
          invalidated: true,
        }
      }
    case FETCHING_USERS_SUCCEEDED:
      var records = action.payload.records;
      var items = [];

      if (action.payload.currentPage !== 0) {
        items = [...state.users.items, ...action.payload.records];
      } else {
        items = action.payload.records
      }

      items = items.reduce((x, y) => {
        var index = x.findIndex((e) => {
          return e.id === y.id;
        });

        if (index < 0) {
          return [...x, y];
        } else {
          x[index] = y;
          return x;
        }

      }, []);

      return {
        ...state,
        users: {
          ...state.users,
          loading: false,
          invalidated: false,
          items: items,
          pagination: {
            ...state.users.pagination,
            current: action.payload.currentPage,
            totalPages: action.payload.totalPages,
            totalRecordsCount: action.payload.totalRecordsCount
          }
        }
      }
    case FETCHING_USERS_FAILED:
      return {
        ...state,
        users: {
          ...state.users,
          loading: false,
          invalidated: false,
          error: action.payload.error,
          pagination: {
            current: -1,
            totalPages: 1
          }
        }
      }
    case INVITE_USER_REQUEST:
      return {
        ...state,
        inviting: true,
        inviteError: null
      }

    case INVITE_USER_SUCCEEDED:
      var items = state.invitations.items.slice();
      items.splice(state.invitations.items.length, 0, action.payload);

      // let uniqueItems = new Set(items).toJSON();

      return {
        ...state,
        inviting: false,
        invitations: {
          ...state.invitations,
          items: items
        }
      }

    case INVITE_USER_FAILED:
      console.log(action.payload);
      return {
        ...state,
        inviting: false,
        inviteError: action.payload
      }

    case UPDATE_USER_ACCESS:
      var id = action.payload.user.id;

      var users = state.users.items.map(user => {
        if (user.id == id) {
          return {
            ...user,
            updatingAccess: true
          }
        } else {
          return user;
        }
      })

    case UPDATE_USER_ACCESS_SUCCEEDED:
      var id = action.payload.id;
      return {
        ...state,
        users: {
          ...state.users,
          items: state.users.items.map((user, index) => {
            if (user.id != id) {
              return user;
            }

            return {
              ...action.payload,
              updatingAccess: false
            };
          })
        }
      }

    case UPDATE_USER_ACCESS_FAILED:
      return {
        ...state,
        users: {
          ...state.users,
          updatingAccess: false
        }
      }

    case FETCHING_INVITATIONS_REQUEST:
      return {
        ...state,
        invitations: {
          ...state.invitations,
          loading: true,
          invalidated: true,
        }
      }

    case FETCHING_INVITATIONS_SUCCEEDED:
      var records = action.payload.records;
      var items = [];

      records = records.map((item) => {
        return {
          ...item,
          resending: false,
          deleting: false
        }
      });

      if (action.payload.currentPage != 0) {
        items = [...state.invitations.items, ...action.payload.records];
      } else {
        items = action.payload.records
      }

      items = items.reduce((x, y) => {
        var index = x.findIndex((e) => {
          return e.id == y.id;
        });

        if (index < 0) {
          return [...x, y];
        } else {
          x[index] = y;
          return x;
        }

      }, []);

      return {
        ...state,
        invitations: {
          ...state.invitations,
          loading: false,
          invalidated: false,
          items: items,
          pagination: {
            ...state.users.pagination,
            current: action.payload.currentPage,
            totalPages: action.payload.totalPages
          }
        }
      }

    case FETCHING_INVITATIONS_FAILED:
      return {
        ...state,
        invitations: {
          ...state.invitations,
          loading: false,
          invalidated: false,
          error: action.payload.error,
          pagination: {
            current: -1,
            totalPages: 1
          }
        }
      }

    case RESEND_USER_INVITATION:
      var invitationId = action.payload;

      var invitations = state.invitations.items.map(invitation => {
        if (invitation.id == invitationId) {
          return {
            ...invitation,
            resending: true
          }
        } else {
          return invitation;
        }
      })
      return {
        ...state,
        invitations: {
          ...state.invitations,
          items: invitations
        }
      }

    case RESEND_USER_INVITATION_SUCCEEDED:
      var invitationId = action.payload;

      var invitations = state.invitations.items.map(invitation => {
        if (invitation.id == invitationId) {
          return {
            ...invitation,
            resending: false

          }
        } else {
          return invitation;
        }
      })
      return {
        ...state,
        invitations: {
          ...state.invitations,
          items: invitations
        }
      }

    case RESEND_USER_INVITATION_FAILED:
      var invitationId = action.payload.id;

      var invitations = state.invitations.items.map(invitation => {
        if (invitation.id == invitationId) {
          return {
            ...invitation,
            resending: false

          }
        } else {
          return invitation;
        }
      })
      return {
        ...state,
        invitations: {
          ...state.invitations,
          items: invitations
        }
      }

    case DELETE_USER_INVITATION:
      var invitationId = action.payload.id;

      var invitations = state.invitations.items.map(invitation => {
        if (invitation.id == invitationId) {
          return {
            ...invitation,
            deleting: true
          }
        } else {
          return invitation;
        }
      })
      return Object.assign({}, state, {
        ...state,
        invitations: {
          ...state.invitations
        }
      })
      
    case DELETE_USER_INVITATION_SUCCEEDED:
      var id = action.payload;
      
      return Object.assign({}, state, {
        ...state,
        invitations: {
          ...state.invitations,
          items: state.invitations.items.filter((user, index) => {
            return user.id != id;
          }),
          deleting: false,
        }
      })

    case DELETE_USER_INVITATION_FAILED:
      return Object.assign({}, state, {
        ...state,
        invitations: {
          ...state.invitations,
          deleting: false
        }
      })

    default:
      return state
  }
}

export { userManagementReducer };