import React, { useState, Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateProperties, updateWidget } from 'actions/widgetEditor';

import _ from 'lodash';
import cx from 'classnames';
import { Helmet } from "react-helmet";

import styles from './formElements.module.scss';

import { Widget, getStyles } from '../widget.jsx';
import { WidgetRegistry } from '../registry.jsx';
import { QuickSettings, QuickSettingsProperty } from 'components/QuickSettings';

export const FormElement = (props) => {
  const properties = props.properties;

  const widgetStyles = getStyles(properties);

  var widgets = props.widgets;

  const stylesheets = _.get(props, 'css', '');
  const script = _.get(props, 'script', '');

  return (
    <div className={cx(styles.formElement, props.className)}
      id={`widget_${props.id}`}
      onMouseOver={props.onMouseOver}
      onMouseOut={props.onMouseOut}
      onClick={props.onClick}
      style={widgetStyles}
    >
      {(stylesheets || script) &&
        <Helmet>
          <style type="text/css">{stylesheets}</style>
          <script type="text/javascript">{script}</script>
        </Helmet>
      }

      {!props.content &&
        <div className={styles.liveElementPlaceholder}>The Form has been deleted.</div>}

        <Widget.Children widgets={widgets} editor={props.editor} editable={false} />

      {props.children}
    </div>
  )
}

function FormElementQuickSettings(props) {
  const dispatch = useDispatch();

  const [selected, setSelected] = useState('spacing');

  const menuItems = [
    { id: 'spacing', title: 'Spacing', icon: 'icon-spacing' },
    { id: 'border', title: 'Border', icon: 'icon-border' },
    { id: 'advanced', title: 'Advanced', icon: 'icon-custom' }
  ]

  const handleMenuClick = (item) => {
    setSelected(item);
  }

  var widget = useSelector(state => {
    const editorContext = state.widgetsEditor.editors[props.editor];

    if (editorContext) {
      return editorContext.widgetsById[props.id];
    }

    return null;
  });
  if (!widget) {
    return false;
  }

  var properties = widget.properties;

  const handleChange = (changeRequest) => {
    dispatch(updateProperties({
      id: widget.id,
      change: changeRequest,
      context: props.editor
    }));
  }

  const handleCssChange = (css) => {
    var changeRequest = { ...widget };
    _.set(changeRequest, 'properties.css', css);

    changeRequest = WidgetRegistry.processRequest([changeRequest])[0];
    dispatch(updateWidget(props.id, changeRequest, props.editor));
  }

  return (
    <QuickSettings menuItems={menuItems} selected={selected} onMenuClick={handleMenuClick} >
      {selected === 'spacing' &&
        <>
          <QuickSettingsProperty.Spacing
            title='Margin'
            value={properties.margin}
            onChange={(margin) => {
              handleChange({
                margin: margin
              });
            }}
          />
          <QuickSettingsProperty.Spacing
            title='Padding'
            value={properties.padding}
            onChange={(padding) => {
              handleChange({
                padding: padding
              });
            }}
          />
        </>
      }

      {selected === 'border' &&
        <QuickSettingsProperty.Border expanded value={properties.border} onChange={(border) => {
          handleChange({
            border: border
          });
        }}
        />
      }

      {selected === 'advanced' &&
        <QuickSettingsProperty.CSS title='Stylesheets' value={properties.css} onChange={handleCssChange} />
      }
    </QuickSettings>
  )
}

FormElement.QuickSettings = FormElementQuickSettings;