import {
  FETCH_LIVE_ELEMENTS_REQUEST, FETCHING_LIVE_ELEMENTS_SUCCEEDED, FETCHING_LIVE_ELEMENTS_FAILED,
  FETCH_LIVE_ELEMENT_REQUEST, FETCHING_LIVE_ELEMENT_SUCCEEDED, FETCHING_LIVE_ELEMENT_FAILED,
  CREATE_LIVE_ELEMENT_REQUEST, CREATING_LIVE_ELEMENT_SUCCEEDED, CREATING_LIVE_ELEMENT_FAILED,
  CLONE_LIVE_ELEMENT_REQUEST, CLONING_LIVE_ELEMENT_SUCCEEDED, CLONING_LIVE_ELEMENT_FAILED,
  UPDATE_LIVE_ELEMENT_REQUEST, UPDATING_LIVE_ELEMENT_SUCCEEDED, UPDATING_LIVE_ELEMENT_FAILED,
  PUBLISH_LIVE_ELEMENT_REQUEST, PUBLISHING_LIVE_ELEMENT_SUCCEEDED, PUBLISHING_LIVE_ELEMENT_FAILED,
  DELETE_LIVE_ELEMENT_REQUEST, DELETE_LIVE_ELEMENT_SUCCEEDED, DELETE_LIVE_ELEMENT_FAILED,

  UPDATE_LIVE_ELEMENT_NAME
} from 'constants/actionTypes.jsx'; 

import axios from 'axios';
import buildUrl from 'build-url';
import { createAction } from 'redux-actions';
import { call, put, takeEvery, takeLatest } from 'redux-saga/effects';

const apiUrl = process.env.REACT_APP_API_URL;
const LIVE_ELEMENTS_URL = `${apiUrl}/api/2.0/live_elements`;

/**
 * Fetch live elements for storyboard
*/
export const fetchLiveElements = createAction(FETCH_LIVE_ELEMENTS_REQUEST, (page, limit, orderBy, direction, published) => {
  return {
    page: page,
    limit: limit,
    orderBy: orderBy,
    direction: direction,
    published: published
  }
});

const fetchingLiveElementsSucceeded = createAction(FETCHING_LIVE_ELEMENTS_SUCCEEDED, (response) => {
  return response;
});

const fetchingLiveElementsFailed = createAction(FETCHING_LIVE_ELEMENTS_FAILED, (error) => {
  return error;
});

const fetchLiveElementsAPI = (params) => {
  return axios({
    method: 'GET',
    url: LIVE_ELEMENTS_URL,
    params: params
  }).then(response => response.data);
}

function* fetchLiveElementsSaga(action) {
  const { page, limit, orderBy, direction, published } = action.payload;

  var params = {
    page: page,
    limit: limit,
    published: published
  }

  try {
    const response = yield call(fetchLiveElementsAPI, params);
    yield put(fetchingLiveElementsSucceeded(response));
  } catch (error) {
    yield put(fetchingLiveElementsFailed(error));
  }
}


/**
 * Create a new live element in storyboard
*/
export const createLiveElement = createAction(CREATE_LIVE_ELEMENT_REQUEST, (request, callback) => {
  return {
    request: request, 
    callback: callback
  }
});

const creatingLiveElementSucceeded = createAction(CREATING_LIVE_ELEMENT_SUCCEEDED);
const creatingLiveElementFailed = createAction(CREATING_LIVE_ELEMENT_FAILED);

function* createLiveElementSaga(action) {
  const {request, callback} = action.payload;
  
  const createLiveElementAPI = () => {
    return axios({
      method: 'POST',
      url: LIVE_ELEMENTS_URL,
      data: request
    }).then(response => response.data);
  }

  try {
    const response = yield call(createLiveElementAPI);
    yield put(creatingLiveElementSucceeded(response));
    if (callback) {
      callback(response);
    }
  } catch (error) {
    yield put(creatingLiveElementFailed(error));
  }
}

/**
 * Clone live element for {id}
*/
export const cloneLiveElement = createAction(CLONE_LIVE_ELEMENT_REQUEST, (id, request, callback) => {
  return {
    id: id,
    request: request,
    callback: callback
  }
})

export const cloneLiveElementSucceeded = createAction(CLONING_LIVE_ELEMENT_SUCCEEDED, response => {
  return response;
})

export const cloneLiveElementFailed = createAction(CLONING_LIVE_ELEMENT_FAILED, error => {
  return error;
})

function* cloneLiveElementSaga(action) {
  const { id, request, callback } = action.payload;

  let url = buildUrl(LIVE_ELEMENTS_URL, {
    path: `${id}/clone`
  });

  const cloneLiveElementAPI = () => {
    return axios({
      method: 'POST',
      url: url,
      data: request
    }).then(response => response.data);
  }

  try {
    const response = yield call(cloneLiveElementAPI);
    yield put(cloneLiveElementSucceeded(response));
    if (callback) {
      callback(response);
    }
  } catch (error) {
    yield put(cloneLiveElementFailed(error));
  }
}

/**
 * Fetch page for {id}
*/
export const fetchLiveElement = createAction(FETCH_LIVE_ELEMENT_REQUEST, (id, callback) => {
  return {
    id: id,
    callback: callback
  }
});
const fetchingLiveElementSucceeded = createAction(FETCHING_LIVE_ELEMENT_SUCCEEDED);
const fetchingLiveElementFailed = createAction(FETCHING_LIVE_ELEMENT_FAILED);

function* fetchLiveElementSaga(action) {
  const {id, callback} = action.payload;

  let url = buildUrl(LIVE_ELEMENTS_URL, {
    path: `${id}`
  });

  const fetchLiveElementAPI = () => {
    return axios.get(url).then(response => response.data);
  }

  try {
    const response = yield call(fetchLiveElementAPI);
    yield put(fetchingLiveElementSucceeded(response));
    if(callback){
      callback(response);
    }
  } catch (error) {
    console.log(error);
    yield put(fetchingLiveElementFailed(error));
  }
}

/**
 * Update page for {id}
*/
export const updateLiveElement = createAction(UPDATE_LIVE_ELEMENT_REQUEST, (id, request, callback) => {
  return {
    id: id,
    request: request,
    callback: callback
  }
});

const updatingLiveElementSucceeded = createAction(UPDATING_LIVE_ELEMENT_SUCCEEDED, response => {
  return response;
});
const updatingLiveElementFailed = createAction(UPDATING_LIVE_ELEMENT_FAILED, error => {
  return error;
});

function* updateLiveElementSaga(action) {
  const { id,  request, callback } = action.payload;

  let url = buildUrl(LIVE_ELEMENTS_URL, {
    path: `${id}`
  });

  const updateLiveElementAPI = () => {
    return axios({
      method: 'PUT',
      url: url,
      data: request
    }).then(response => response.data);
  }

  try {
    const response = yield call(updateLiveElementAPI);
    yield put(updatingLiveElementSucceeded(response));
    if(callback) yield call(callback, response);
  } catch (error) {
    yield put(updatingLiveElementFailed(error));
  }
}

/**
 * Update name for live element for {id}
*/
export const updateLiveElementName = createAction(UPDATE_LIVE_ELEMENT_NAME, (id, name) => {
  return {
    id: id,
    name: name
  }
});

/**
 * Publish live element for {id}
*/
export const publishLiveElement = createAction(PUBLISH_LIVE_ELEMENT_REQUEST, (id, request, callback) => {
  return {
    id: id,
    request: request,
    callback: callback || (() => {})
  }
});

const publishingLiveElementSucceeded = createAction(PUBLISHING_LIVE_ELEMENT_SUCCEEDED, response => {
  return response;
});
const publishingLiveElementFailed = createAction(PUBLISHING_LIVE_ELEMENT_FAILED, error => {
  return error;
});

function* publishLiveElementSaga(action) {
  const { id, callback } = action.payload;

  let url = buildUrl(LIVE_ELEMENTS_URL, {
    path: `${id}/publish`
  });

  const publishLiveElementAPI = () => {
    return axios({
      method: 'POST',
      url: url
    }).then(response => response.data);
  }

  try {
    const response = yield call(publishLiveElementAPI);
    yield put(publishingLiveElementSucceeded(response));
    yield call(callback, response);
  } catch (error) {
    console.log(error);
    yield put(publishingLiveElementFailed(error));
  }
}

/**
 * Delete page for {id}
*/
export const deleteLiveElement = createAction(DELETE_LIVE_ELEMENT_REQUEST, (id, callback) => {
  return {
    id: id,
    callback: callback
  };
})

const deleteLiveElementSucceeded = createAction(DELETE_LIVE_ELEMENT_SUCCEEDED, (id, response) => {
  return {
    id: id,
    response: response
  };
})

const deleteLiveElementFailed = createAction(DELETE_LIVE_ELEMENT_FAILED, (id, error) => {
  return {
    id: id,
    error: error
  };
})

function* deleteLiveElementSaga(action) {
  const id = action.payload.id;
  const callback = action.payload.callback || (() => {});

  let url = buildUrl(LIVE_ELEMENTS_URL, {
    path: `${id}`
  });

  const deleteLiveElementAPI = () => {
    return axios({
      method: 'DELETE',
      url: url,
    }).then(response => response.data);
  }

  try {
    const response = yield call(deleteLiveElementAPI);
    yield put(deleteLiveElementSucceeded(id, response));
    console.log(callback);
    yield call(callback, response);
  } catch (error) {
    yield put(deleteLiveElementFailed(id, error));
  }
}

export default function* liveElementsSaga() {
  yield takeEvery(FETCH_LIVE_ELEMENTS_REQUEST, fetchLiveElementsSaga);
  yield takeEvery(CREATE_LIVE_ELEMENT_REQUEST, createLiveElementSaga);
  yield takeEvery(FETCH_LIVE_ELEMENT_REQUEST, fetchLiveElementSaga);
  yield takeEvery(UPDATE_LIVE_ELEMENT_REQUEST, updateLiveElementSaga);
  yield takeEvery(PUBLISH_LIVE_ELEMENT_REQUEST, publishLiveElementSaga);
  yield takeEvery(CLONE_LIVE_ELEMENT_REQUEST, cloneLiveElementSaga);
  yield takeEvery(DELETE_LIVE_ELEMENT_REQUEST, deleteLiveElementSaga);
};