import React, { PropTypes, Fragment, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { createEditorContext } from 'actions/widgetEditor';
import { fetchIntegrations } from 'actions/integrations/integrations';
import { fetchPage } from 'actions/pages';
import { fetchAssetTemplates } from 'actions/templates';

import _ from 'lodash';
import cx from 'classnames';
import { Helmet } from "react-helmet";
import { guid } from 'services/util';

import {
  Menu,
  Segment
} from 'semantic-ui-react';

import { WidgetsEditor } from 'pages/pages/editor/editor';
import Device from 'components/Device';
import Frame from 'react-frame-component';
import { Route, Switch, useRouteMatch, useParams } from 'react-router-dom';
import styles from './preview.module.scss';

import { PASSWORD_PROTECTION } from 'constants/ItemTypes';

export function PreviewPage() {
  let { path, url } = useRouteMatch();
  let {id} = useParams();
  const [device, setDevice]  = useState('none');

  return (
    <div className={styles.content}>
      <Switch>
        <Route path={`${path}/embedded`} exact>
          <EmbeddedPageView />
        </Route>
      </Switch>
      <Device className={cx(styles.devices, styles[device])} mode={device}>
        <iframe src={`${url}/embedded`}></iframe>
      </Device>
      <Menu className={styles.devicesSwitcher}>
        <Menu.Item icon="desktop" className={cx({[styles.active]: device === 'none'})} onClick={() => {
          setDevice('none');
        }}></Menu.Item>
        <Menu.Item icon="tablet alternate" 
          className={cx({[styles.active]: device === 'tablet'})} 
          onClick={() => {
            setDevice('tablet');
          }}  
        />
        <Menu.Item className={styles.landscape} icon="tablet alternate" 
          className={cx(styles.landscape, {[styles.active]: device === 'tablet-landscape'})} 
          onClick={() => {
            setDevice('tablet-landscape');
          }} />
        <Menu.Item icon="mobile alternate"  
          className={cx({[styles.active]: device === 'mobile'})} 
          onClick={() => {
            setDevice('mobile');
          }}  
        />
      </Menu>
    </div>
  );
}

export function EmbeddedPageView(props){
  const dispatch = useDispatch();

  const {id} = useParams();
  const page = useSelector(state => _.get(state, 'pageEditor.item', {}));
  const loading = useSelector(state => _.get(state, 'pageEditor.loading', false));

  const [editor, setEditor] = useState(null);

  var widgetsById = useSelector(state => {
    var widgetsById = {};

    if (!editor) {
      return widgetsById;
    }
    
    var editorContext = state.widgetsEditor.editors[editor];

    if (editorContext) {
      widgetsById = editorContext.widgetsById;
    }

    return widgetsById;
  });

  var widgets = useSelector(state => {
    var widgets = [];

    if (!editor) {
      return widgets;
    }

    var editorContext = state.widgetsEditor.editors[editor];

    if (editorContext) {
      widgets = editorContext.widgets;
    }

    return widgets;
  });

  var pageWidgets = widgets.filter(item => {
    var widget = widgetsById[item];
    return widget.type !== PASSWORD_PROTECTION;
  })

  useEffect(() => {
    dispatch(fetchPage(id));
    dispatch(fetchAssetTemplates());
    dispatch(fetchIntegrations());

    var editor = guid();
    setEditor(editor);
  }, []);

  useEffect(() => {
    if (page && page.draft && page.draft.widgets && editor) {
      dispatch(createEditorContext(editor, page.draft.widgets));
    }
  }, [page && page.id, editor]);


  const stylesheets = _.get(page, 'draft.properties.css', ''); 
  const script = _.get(page, 'draft.properties.script', '');
  const customfontStyle = useSelector(state => _.get(state, 'storyboards.storyboard.item.branding.customStyleSheet'));
  const resourceCenterStyle = useSelector(state => _.get(state, 'storyboards.storyboard.item.css'));
  const resourceCenterScript = useSelector(state => _.get(state, 'storyboards.storyboard.item.script'));

  return (
    <div className={styles.content}>
      <Helmet>
        <style type="text/css">{`body{font-size: 16px;}`}</style>
        {stylesheets && <style type="text/css">{stylesheets}</style>}
        {script && <script type="text/javascript">{script}</script>}
        {resourceCenterStyle && <style type="text/css">{resourceCenterStyle}</style>}
        {resourceCenterScript && <script type="text/javascript">{resourceCenterScript}</script>}
        {customfontStyle && 
          <link href={customfontStyle} rel="stylesheet" />
        }
     </Helmet>

      <Segment basic className={styles.widgetsEditor}>
        <WidgetsEditor
          id='widget_viewer'
          className={styles.editor}
          editor={editor}
          widgets={pageWidgets}
          editable={false}
          entityReference={{
            id: page && page.id,
            type: 'page'
          }}
        />
      </Segment>
    </div>  
  );
}