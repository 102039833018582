import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createPage, fetchPages, fetchRecentlyUpdatedPages, deletePage } from 'actions/pages';
import { fetchPageTemplates } from 'actions/templates';
import { fetchAllGating } from 'actions/gatings';

import {
  Route,
  Switch,
  useRouteMatch,
  useHistory
} from "react-router-dom";

import cx from 'classnames';
import _ from 'lodash';

import {
  Button,
  Grid,
  Header,
  Menu,
  Input,
  Visibility,
} from 'semantic-ui-react';

import Flicking from "@egjs/react-flicking";

import { BlankTemplate, PageTemplate, PageCard, PagePlaceholderCard, PageTemplatePlaceholder } from 'pages/pages/components/template';
import AssetTemplatesPage from 'pages/pages/templates/assets';
import PageTemplatesPage from 'pages/pages/templates/pages';
import PageEditor from 'pages/pages/editor/view';
import GatingsView from 'pages/pages/gatings';

import styles from './pages.module.scss';

export default function PagesView() {
  const dispatch = useDispatch();
  const history = useHistory();
  let { path, url } = useRouteMatch();

  const [createPage, showCreatePage] = useState(false);
  const createdPage = useSelector(state => _.get(state, 'pages.create.item.id'));
  const clonedPage = useSelector(state => _.get(state, 'pages.clone.item.id'));

  useEffect(() => {
    dispatch(fetchAllGating());
  }, [])

  useEffect(() => {
    if (createdPage) {
      history.push(`${url}/${createdPage}/edit`);
    }
  }, [createdPage])

  useEffect(() => {
    if (clonedPage) {
      history.push(`${url}/${clonedPage}/edit`);
    }
  }, [clonedPage])

  return (
    <Switch>
      <Route path={`${path}/:id/edit`} exact>
        <PageEditor />
      </Route>
      <Route path={`${path}/templates/pages`}>
        <PageTemplatesPage />
      </Route>
      <Route path={`${path}/templates/assets`}>
        <AssetTemplatesPage />
      </Route>
      <Route>
        <Grid columns="equal" stretched className={styles.content}>
          <Grid.Row>
            <Grid.Column>
              <Switch>
                <Route path={`${path}`} exact>
                  <div className={styles.details}>
                    <h1 className={styles.header}>Pages</h1>
                    <TemplatesView />
                    <RecentlyUpdatedPages />
                    <AllPages />
                  </div>
                </Route>
                <Route path={`${path}/gating`}>
                  <GatingsView />
                </Route>
              </Switch>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Route>
    </Switch>
  )
}

function TemplatesView(props) {
  const dispatch = useDispatch();
  const carousel = React.createRef();

  const loading = useSelector(state => _.get(state, "templates.pages.loading", false));
  const templates = useSelector(state => _.get(state, "templates.pages.items", []));
  const creating = useSelector(state => _.get(state, 'pages.create.creating'));

  useEffect(() => {
    dispatch(fetchPageTemplates());
  }, []);

  const handleBlankClicked = () => {
    if(creating){
      return;
    }

    if(carousel.current.isPlaying()){
      return;
    }

    var request = {
      name: "Untitled Page",
      template: null
    }

    dispatch(createPage(request));
  }

  const handleTemplateClicked = (template) => {
    if(creating){
      return;
    }

    if(carousel.current.isPlaying()){
      return;
    }

    var request = {
      name: template.name,
      template: template.id
    }

    dispatch(createPage(request));
  }

  const handlePrevious  = () => {
    carousel.current.prev();
  }

  const handleNext  = () => {
    carousel.current.next();
  }

  return (
    <div className={styles.templates}>
      <Button 
        className={cx(styles.arrow, styles.left)} 
        circular 
        icon="chevron left"
        color='black'
        onClick={handlePrevious}
      ></Button>
        
      <Flicking
        className={styles.carousel}
        ref={carousel}
        gap={24}
        hanger="0px"
        anchor="0px"
        autoResize={true}
        moveType="freeScroll"
        bound={true}
        adaptive={true}
        isEqualSize={false}
        isConstantSize={false}
        collectStatistics={false}
      >
        
        <BlankTemplate onClick={handleBlankClicked}  />
        {loading && _.times(3, (index) => {
          const prefix = index === 0 ? 'Start with a template' : ' ';
          return <PageTemplatePlaceholder key={index} className={styles.template} prefix={prefix} />
        })}

        {!loading && templates.map((template, index) => {
          const prefix = index === 0 ? 'Start with a template' : ' ';
          return <PageTemplate 
            key={template.id} 
            className={styles.template} 
            value={template} 
            prefix={prefix} 
            onClick={handleTemplateClicked}
          />
        })}

      </Flicking>

      <Button 
        className={cx(styles.arrow, styles.right)} 
        circular 
        icon="chevron right"
        color='black'
        onClick={handleNext}
        ></Button>
    </div>
  );
}

function RecentlyUpdatedPages(props) {
  const dispatch = useDispatch();
  const loading = useSelector(state => _.get(state, "pages.recentlyUpdated.loading", false));
  const pages = useSelector(state => _.get(state, "pages.recentlyUpdated.items", []));

  useEffect(() => {
    dispatch(fetchRecentlyUpdatedPages());
  }, []);

  return (
    <div className={cx(styles.pages, styles.recentlyUpdated)}>
      <h2>Recently updated pages</h2>
      <div className={styles.inner}>
        {loading && _.times(3, (index) => {
          return <PagePlaceholderCard key={index} />
        })}

        {!loading && pages.map(page => {
          return <PageCard key={page.id} value={page} placeholder />
        })}
      </div>
    </div>
  );
}

function AllPages({ createPage }) {
  const dispatch = useDispatch();

  const [query, setQuery] = useState('');

  const pages = useSelector(state => _.get(state, "pages.items", []));
  const pagination = useSelector(state => _.get(state, "pages.pagination", {}));
  const currentPage = _.get(pagination, "currentPage", 0);
  const totalPages = _.get(pagination, "totalPages", 0);
  const loading = useSelector(state => _.get(state, "pages.loading", false));
  const placeholder = loading && currentPage < 0;

  useEffect(() => {
    dispatch(fetchPages(query, 0, 8, "name", "ASC"));
  }, [query])

  const loadMorePages = () => {
    if (loading || currentPage === totalPages - 1) {
      return null;
    }

    dispatch(fetchPages(query, currentPage + 1, 8, "name", "ASC"));
  }

  const handleSearchChange = (e) => {
    setQuery(e.target.value);
  }

  return (
    <div className={cx(styles.pages, styles.all)}>
      <Menu className={styles.header} secondary icon>
        <Menu.Item header>
          <Header as="h2">All Pages</Header>
        </Menu.Item>
        <Menu.Item>
          <Input
            icon='search'
            onChange={handleSearchChange}
            value={query}
            className={styles.search}
            placeholder='Search pages...' />
        </Menu.Item>
      </Menu>
      <Visibility className={styles.inner} onBottomVisible={loadMorePages} continuous fireOnMount={false}>
        {placeholder && _.times(5, (index) => {
          return <PagePlaceholderCard key={index} className={styles.page} />
        })}

        {!placeholder && pages.map(page => {
          return <PageCard key={page.id} className={styles.page} value={page} />
        })}
      </Visibility>
    </div>

  );
}