import React, { useState, Fragment, useRef, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateProperties, updateWidget, showPlaceholderAtIndex, moveWidgetToIndex } from 'actions/widgetEditor';

import _ from 'lodash';
import cx from 'classnames';

import {
  Grid
} from 'semantic-ui-react';

import { useDrop } from 'react-dnd';
import { PALETTE_ITEM, WIDGET_ITEM } from 'constants/ItemTypes.jsx';
import { calculateDropRequest } from 'services/dnd.service';

import { WidgetRegistry } from '../registry.jsx';
import { Widget, getStyles } from '../widget.jsx';
import { Property } from 'components/Properties';
import { QuickSettings, QuickSettingsProperty } from 'components/QuickSettings';

import { WidgetContext } from 'pages/pages/editor/editor.jsx';

import styles from './layout.module.scss';
import { ROW, HERO_BANNER, LIVE_ELEMENT, PASSWORD_PROTECTION } from 'constants/ItemTypes.jsx';
import { useWidgetDrop } from 'services/dnd.service.jsx';
import { denormalise, getClosestColumnForWidget } from 'services/widget.helper.jsx';
import { ColumnResizer } from '../resizable.jsx';
import { Banner } from 'components/Banner/index.jsx';

const Column = React.memo((props) => {
  const ref = useRef(null);
  const dispatch = useDispatch();

  const editorContext = useSelector(state => {
    return state.widgetsEditor.editors[props.editor];
  });

  var widgets = props.widgets || [];
  widgets = useSelector(state => {
    var items = [];

    var editorContext = state.widgetsEditor.editors[props.editor];

    if (editorContext) {
      items = widgets.map(widget => {
        return editorContext.widgetsById[widget];
      });
    }

    return items;
  }).filter(widget => widget != null);
  widgets = widgets || [];

  const resizing = useSelector(state => {
    var editorContext = state.widgetsEditor.editors[props.editor];

    if (!(editorContext && editorContext.resizing)) {
      return null;
    }

    if (editorContext.resizing.id === props.id) {
      return editorContext.resizing;
    }

    return null;
  });

  var properties = props.properties;
  const editable = props.editable;

  const selectedWidget = useSelector(state => state.widgetsEditor.selection);
  const pageId = useSelector(state => _.get(state, 'pageEditor.item.id', null));

  const { onPaletteItemDrop, onCreateWidget } = React.useContext(WidgetContext);

  const dropRef = useWidgetDrop(props, ref, {
    acceptTargets: (type) => {
      return !_.includes([HERO_BANNER, LIVE_ELEMENT, PASSWORD_PROTECTION], type);
    },
    drop: (request) => {
      var dropTarget = props.id;
      var dropIndex = request.index;
      var anchor = request.anchor;
      var clientRect = request.clientRect;

      var direction = "down";
      var identifedTarget = false;

      if (anchor) {
        if (anchor.left || anchor.right) {
          const rowWidget = editorContext.widgetsById[props.parent];
          const droppable = _.get(rowWidget, 'capabilities.droppable', true);

          if (rowWidget && droppable) {
            const columnIndex = _.indexOf(rowWidget.widgets, props.id);

            if (columnIndex >= 0) {
              dropTarget = props.parent;
              dropIndex = columnIndex;
              direction = anchor.right ? 'right' : 'left';

              identifedTarget = true;
            }
          }
        }

        if (!identifedTarget && (anchor.top || anchor.bottom)) {
          const parentWidget = editorContext.widgetsById[props.id];
          identifedTarget = _.get(parentWidget, 'capabilities.droppable', true);

          direction = anchor.bottom ? 'down' : 'up';
        }
      }

      if (!identifedTarget) {
        const parentWidget = editorContext.widgetsById[props.id];
        identifedTarget = _.get(parentWidget, 'capabilities.droppable', true);

        if (!identifedTarget) {
          return;
        }

        // const empty = parentWidget.widgets.length == 0;

        // if(!empty){
        //   console.log(parentWidget.widgets);
        //   const widget = parentWidget.widgets[parentWidget.widgets.length - 1];
        //   const widgetElement = document.querySelector(`#widget_${widget}`);

        //   clientRect = widgetElement.getBoundingClientRect();
        //   direction = 'down';
        // }
      }

      return {
        target: dropTarget,
        index: dropIndex,
        direction: direction,
        clientRect: clientRect
      }
    }
  });

  const hasEditCapability = _.get(props, 'capabilities.editable', true);
  const empty = widgets.length == 0;
  var widgetStyles = getStyles(props.properties);

  if (resizing && resizing.dimension) {
    widgetStyles = {
      ...widgetStyles,
      minWidth: resizing.dimension.width,
      maxWidth: resizing.dimension.width
    }
  }

  return (
    <Grid.Column
      as={FowardableColumn}
      forwardedRef={props.dragRef(dropRef(ref))}
      width={properties.width === 0 ? null : properties.width}
      id={`widget_${props.id}`}
      className={cx(props.className, styles.column, 
        styles[properties.horizontalAlignment],
        styles[properties.verticalAlignment],
        {
          [styles.empty]: empty && editable && hasEditCapability,
          [styles.horizontal]: properties.direction === 'horizontal'
        })
      }
      onMouseOver={props.onMouseOver}
      onMouseOut={props.onMouseOut}
      onClick={props.onClick}
      style={widgetStyles}
    >
      <Banner properties={properties} />
      <Widget.Children widgets={props.widgets} parent={props.id} editor={props.editor} editable={props.editable} />
      {props.children}

      {props.selected &&
        <ColumnResizer item={props} editor={props.editor} />
      }
    </Grid.Column>
  );
});

function ColumnQuickSettings(props) {
  const dispatch = useDispatch();

  const [selected, setSelected] = useState('layout');

  const menuItems = [
    { id: 'layout', title: 'Layout', icon: 'icon-layout' },
    { id: 'spacing', title: 'Spacing', icon: 'icon-spacing' },
    { id: 'background', title: 'Background', icon: 'icon-background' },
    { id: 'border', title: 'Border', icon: 'icon-border' },
    { id: 'advanced', title: 'Advanced', icon: 'icon-custom' }
  ]

  const handleMenuClick = (item) => {
    setSelected(item);
  }

  var widget = useSelector(state => {
    const editorContext = state.widgetsEditor.editors[props.editor];

    if (editorContext) {
      return editorContext.widgetsById[props.id];
    }

    return null;
  });

  var widgetsById = useSelector(state => {
    const editorContext = state.widgetsEditor.editors[props.editor];

    if (editorContext) {
      return editorContext.widgetsById;
    }

    return {};
  });

  if (!widget) {
    return false;
  }

  var properties = widget.properties;
  var updating = widget.updating;
  var updatingBackground = updating && _.get(properties, 'background.upload') != null;

  const handleChange = (changeRequest) => {
    dispatch(updateProperties({
      id: widget.id,
      change: changeRequest,
      context: props.editor
    }));
  }

  const handleBackgroundImageChange = (media, type) => {
    console.log("Upload image " + type);

    var changeRequest = { ...widget };
    _.set(changeRequest, 'properties.background.upload', {
      type: type,
      data: media
    });

    var widgets = denormalise(changeRequest.widgets || [], widgetsById);
    changeRequest = _.set(changeRequest, 'widgets', widgets);
    changeRequest = WidgetRegistry.processRequest([changeRequest])[0];
    dispatch(updateWidget(props.id, changeRequest, props.editor));
  }

  const handleCssChange = (css) => {
    var changeRequest = { ...widget };
    _.set(changeRequest, 'properties.css', css);

    changeRequest = WidgetRegistry.processRequest([changeRequest])[0];
    dispatch(updateWidget(props.id, changeRequest, props.editor));
  }

  return (
    <QuickSettings menuItems={menuItems} selected={selected} onMenuClick={handleMenuClick} >
      {selected === 'spacing' &&
        <>
          <QuickSettingsProperty.Spacing
            title='Margin'
            value={properties.margin}
            onChange={(margin) => {
              handleChange({
                margin: margin
              });
            }}
          />
          <QuickSettingsProperty.Spacing
            title='Padding'
            value={properties.padding}
            onChange={(padding) => {
              handleChange({
                padding: padding
              });
            }}
          />

          <div className={styles.alignments}>
            <label>Alignment</label>

            <QuickSettingsProperty.Alignment
              title='Horizontal'
              tooltip='Controls where the element will anchor to within the column'
              value={properties.horizontalAlignment}
              onChange={(alignment) => {
                handleChange({
                  horizontalAlignment: alignment
                });
              }}
            />

            <QuickSettingsProperty.Alignment
              title='Vertical'
              tooltip='Controls where the element will anchor to within the column'
              value={properties.verticalAlignment}
              onChange={(alignment) => {
                handleChange({
                  verticalAlignment: alignment
                });
              }}
              vertical
            />

          <QuickSettingsProperty.Direction
            value={properties.direction}
            tooltip='Controls how elements within the column are placed'
            onChange={(direction) => {
              handleChange({
                direction: direction
              });
            }}
          />
          </div>
        </>
      }

      {selected === 'background' &&
        <QuickSettingsProperty.Background
          expanded
          overlayEnabled
          updating={updatingBackground}
          value={properties.background}
          onChange={(background) => {
            handleChange({
              background: background
            });
          }}
          onImageChange={(image, type) => {
            handleBackgroundImageChange(image, type);
          }}
        />
      }

      {selected === 'border' &&
        <QuickSettingsProperty.Border expanded value={properties.border} onChange={(border) => {
          handleChange({
            border: border
          });
        }}
        />
      }

      {selected === 'layout' &&
        <Property.NumericInput
          tooltip='Number of columns'
          title='Width'
          value={properties.width}
          min={1} max={16}
          onChange={(value) => {
            handleChange({
              width: value
            })
          }}
        />
      }

      {selected === 'advanced' &&
        <QuickSettingsProperty.CSS title='Stylesheets' value={properties.css} onChange={handleCssChange} />
      }
    </QuickSettings>
  )
}

Column.QuickSettings = ColumnQuickSettings;

function ColumnProperty(props) {
  const dispatch = useDispatch();

  var editorContext = useSelector(state => state.widgetsEditor.editors[props.editor]);
  var widget = editorContext.widgetsById[props.id];
  if (!widget) {
    return false;
  }

  var properties = widget.properties;

  const handleChange = (changeRequest) => {
    dispatch(updateProperties({
      id: widget.id,
      change: changeRequest,
      context: props.editor
    }));
  }

  const handleBackgroundImageChange = (media) => {
    var changeRequest = { ...widget };
    _.set(changeRequest, 'properties.background.upload', {
      type: 'image',
      data: media
    });

    changeRequest = WidgetRegistry.processRequest([changeRequest])[0];
    dispatch(updateWidget(props.id, changeRequest, props.editor));
  }

  const handleCssChange = (css) => {
    var changeRequest = { ...widget };
    _.set(changeRequest, 'properties.css', css);

    changeRequest = WidgetRegistry.processRequest([changeRequest])[0];
    dispatch(updateWidget(props.id, changeRequest, props.editor));
  }

  return (
    <Fragment>
      <Property.Section title='Spacing' expanded>
        <Property.Spacing
          title='Margin'
          value={properties.margin}
          onChange={(margin) => {
            handleChange({
              margin: margin
            });
          }}
        />
        <Property.Spacing
          title='Padding'
          value={properties.padding}
          onChange={(padding) => {
            handleChange({
              padding: padding
            });
          }}
        />
      </Property.Section>

      <Property.Background expanded
        value={properties.background}
        onChange={(background) => {
          handleChange({
            background: background
          });
        }}
        onImageChange={(image) => {
          handleBackgroundImageChange(image);
        }}
      />

      <Property.Border expanded value={properties.border} onChange={(border) => {
        handleChange({
          border: border
        });
      }}
      />

      <Property.Section title='Layout' expanded>
        <Property.NumericInput
          tooltip='Number of columns'
          title='Width'
          value={properties.width}
          min={1} max={16}
          onChange={(value) => {
            handleChange({
              width: value
            })
          }}
        />
      </Property.Section>

      <Property.Section title='Advanced' expanded>
        <Property.CSS title='Stylesheets' value={properties.css} onChange={handleCssChange} />
      </Property.Section>
    </Fragment>
  );
}

function FowardableColumn({ forwardedRef, ...props }) {
  return <div ref={forwardedRef} {...props} />
};

Column.Property = React.memo(ColumnProperty);

export { Column };