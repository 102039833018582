import {
  ACTIVATING_SIX_SENSE_REQUEST, ACTIVATING_SIX_SENSE_SUCCEDDED, ACTIVATING_SIX_SENSE_FAILED,
  UPDATING_SIX_SENSE_REQUEST, UPDATING_SIX_SENSE_SUCCEDDED, UPDATING_SIX_SENSE_FAILED
  } from 'constants/actionTypes';

const initialState = {
  changes: {
    saving: false,
    error: null,
  },
  activation: {
    activating: false,
    error: null
  },
};

function sixSenseReducer(state = initialState, action) {
  switch (action.type) {
    case ACTIVATING_SIX_SENSE_REQUEST:
      return {
        ...state,
        activation: {
          activating: true,
          error: null
        }
      }

    case ACTIVATING_SIX_SENSE_SUCCEDDED:
      return {
        ...state,
        activation: {
          activating: false,
          error: null
        }
      }

    case ACTIVATING_SIX_SENSE_FAILED:
      return {
        ...state,
        activation: {
          activating: false,
          error: action.payload
        }
      }

    case UPDATING_SIX_SENSE_REQUEST:
      return {
        ...state,
        changes: {
          saving: true,
          error: null
        }
      }
    case UPDATING_SIX_SENSE_SUCCEDDED:
      return {
        ...state,
        changes: {
          saving: false,
          error: null
        }
      }
    case UPDATING_SIX_SENSE_FAILED:
      return {
        ...state,
        changes: {
          saving: false,
          error: action.payload
        }
      }
    default:
      return state
  }
}

export { sixSenseReducer };
