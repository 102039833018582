import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateProperties, updateWidgetContent, updatingWidgetContentSucceeded } from 'actions/widgetEditor';
import { fetchFreshmarketerLists } from 'actions/integrations/freshmarketer';
import { fetchHubspotForms, fetchHubspotForm } from 'actions/integrations/hubspot';

import _ from 'lodash';
import cx from 'classnames';

import {
  Form as SemanticForm,
  Dropdown,
  Icon,
  Input,
  Label,
  Button,
  Modal,
  Table,
  Checkbox,
  Dimmer,
  Loader,
  Segment,
  Header
} from 'semantic-ui-react';

import { guid, validateEmail } from 'services/util';
import Truncate from 'react-truncate';
import Tooltip from 'components/Tooltip';
import { Widget, getStyles, getDimensionStyles } from '../widget.jsx';
import { Property } from 'components/Properties';
import dateformat from 'dateformat';
import { QuickSettings, QuickSettingsProperty } from 'components/QuickSettings';

import { WidgetContext } from 'pages/pages/editor/editor.jsx';

import styles from './form.module.scss';

export const HubspotForm = (props) => {
  const dispatch = useDispatch();
  const [step, setStep] = useState(null);

  var widgets = props.widgets || [];
  var content = props.content;
  const properties = props.properties;
  var pardotFormStep = widgets[0];

  const { entityReference } = React.useContext(WidgetContext);

  const dimension = _.get(properties, 'dimension') || {};
  var dimensionStyles = calculateModalDimension(dimension);

  useEffect(() => {
    setStep(widgets[0]);
    dispatch(fetchHubspotForm(props.id, props.editor));
  }, [])

  return (
    <>
      {entityReference.type === 'page' &&
        <>
          <SemanticForm className={styles.externalForm}>
          <StepWidget step={step}
            id={props.id}
            pardotFormStep={pardotFormStep}
            editor={props.editor}
            content={content}
            properties={props.properties}
            editable={props.editable}
          />
        </SemanticForm>
        {props.children}

        <div className={styles.actions}>
          {props.selected &&
            <Steps
              editor={props.editor}
              widgets={widgets}
              value={step}
              pardotFormStep={pardotFormStep}
              onChange={(value) => {
                setStep(value);
              }}
            />
          }

          {props.selected &&
            <HubspotFormModal
              trigger={
                <div className={styles.steps}>
                  <Button className={styles.pardotFormButton}>Select Form</Button>
                </div>
              } widget={props.id} editor={props.editor} properties={properties} />
          }
        </div>
        </>
      }

      {entityReference.type !== 'page' &&
        <div className={cx('ui', 'medium', 'modal', styles.modal, props.className)}
          id={`widget_${props.id}`}
          onMouseOver={props.onMouseOver}
          onMouseOut={props.onMouseOut}
          onClick={props.onClick}
          style={dimensionStyles}
        >
          <SemanticForm className={styles.externalForm}>
            <StepWidget step={step}
              id={props.id}
              pardotFormStep={pardotFormStep}
              editor={props.editor}
              content={content}
              properties={props.properties}
              editable={props.editable}
            />
          </SemanticForm>
          {props.children}

          <div className={styles.actions}>
            {props.selected &&
              <Steps
                editor={props.editor}
                widgets={widgets}
                value={step}
                pardotFormStep={pardotFormStep}
                onChange={(value) => {
                  setStep(value);
                }}
              />
            }

            {props.selected &&
              <HubspotFormModal
                trigger={
                  <div className={styles.steps}>
                    <Button className={styles.pardotFormButton}>Select Form</Button>
                  </div>
                } widget={props.id} editor={props.editor} properties={properties} />
            }
          </div>
        </div>
      }
    </>
  );
}

function HubspotFormModal(props) {
  const dispatch = useDispatch();

  const [open, setOpen] = useState(false);
  const [selectedForm, setSelectedForm] = useState(props.properties.form);
  const [portalId, setPortalId] = useState(props.properties.guid);


  const forms = useSelector(state => _.get(state, "integrations.hubspot.forms.items", []));
  const page = useSelector(state => _.get(state, "integrations.hubspot.forms.page", 0));
  const limit = useSelector(state => _.get(state, "integrations.hubspot.forms.limit", 20));
  const loadMore = useSelector(state => _.get(state, "integrations.hubspot.forms.loadMore", false));
  const loading = useSelector(state => _.get(state, "integrations.hubspot.forms.loading", false));

  const integrations = useSelector(state => _.get(state, 'integrations.activated.items', []));
  const integration = integrations.filter(item => {
    return item !== null;
  }).find(item => {
    return item.type === 'hubspot';
  })
  const region = _.get(integration, 'configuration.region');

  useEffect(() => {
    if(page === 0 && !loading) {
      dispatch(fetchHubspotForms(page, limit));
    }
  }, [props.widget])

  const handleFormSelection = (form) => {

    if (form.guid === selectedForm) {
      return;
    }

    setSelectedForm(form.guid);
    setPortalId(form.portalId);
  }

  const handleAddForm = () => {

    var currentForm = forms.find(item => {
      return item.guid === selectedForm;
    })

    dispatch(updatingWidgetContentSucceeded(props.widget, currentForm, props.editor));

    dispatch(updateProperties({
      id: props.widget,
      change: {
        form: selectedForm,
        portalId: portalId,
        region: region
      },
      context: props.editor
    }));

    setOpen(false);
  }

  const handleClose = () => {
    setSelectedForm(props.properties.form);
    setOpen(false)
  }

  const handleLoadMore = () => {
    if (loading) {
      return;
    }

    dispatch(fetchHubspotForms(page, limit));
  }

  return (
    <Modal size='large'
      trigger={props.trigger}
      className={styles.formSelectionModal}
      open={open}
      onOpen={() => {
        setOpen(true);
      }}>
      <Modal.Header>Select Hubspot Form</Modal.Header>
      <Modal.Content>
        <Table sortable className={styles.pardotFormTable}>
          <Table.Row>
            <Table.Header>
              <Table.HeaderCell collapsing></Table.HeaderCell>
              <Table.HeaderCell width={8}>Name</Table.HeaderCell>
              <Table.HeaderCell width={5} textAlign="center">Created on</Table.HeaderCell>
              <Table.HeaderCell width={5} textAlign="center">Last Updated</Table.HeaderCell>
            </Table.Header>
            <Table.Body>
              {forms.map(form => {
                var selected = form.guid === selectedForm;
                return (
                  <Table.Row>
                    <Table.Cell collapsing className={cx(styles.checkbox, { [styles.selected]: props.showCheckBox })} textAlign="center">
                      <Checkbox checked={selected} onChange={handleFormSelection.bind(this, form)} />
                    </Table.Cell>
                    <Table.Cell>{form.name}</Table.Cell>
                    <Table.Cell textAlign="center">{dateformat(form.createdAt, 'dd mmm yyyy')}</Table.Cell>
                    <Table.Cell textAlign="center">{dateformat(form.updatedAt, 'dd mmm yyyy')}</Table.Cell>
                  </Table.Row>
                )
              })}
            </Table.Body>
          </Table.Row>
        </Table>
        {loadMore &&
          <div className={styles.loadMoreButton}>
            <Button disabled={loading} onClick={handleLoadMore}>{!loading ? 'Load More' : 'Loading...'}</Button>
          </div>
        }
      </Modal.Content>
      <Modal.Actions>
        <Button className={styles.closeButton} onClick={handleClose}>
          <Icon /> CLOSE
        </Button>
        <Button className={styles.addFormButton} onClick={handleAddForm}>
          <Icon /> ADD
        </Button>
      </Modal.Actions>
    </Modal>
  )
}

function Steps({ widgets, value, onChange, editor }) {
  const { scale } = React.useContext(WidgetContext);

  const [style, setStyle] = useState({});

  useEffect(() => {
    if (scale < 1) {
      var updatedScale = 1 / scale;
      setStyle(
        {
          transform: `scale(${updatedScale})`,
          transformOrigin: `bottom right`,
          marginLeft: '40px'
        }
      )
    } else {
      setStyle({})
    }
  }, [scale])

  widgets = widgets || [];
  widgets = useSelector(state => {
    var items = [];

    var editorContext = state.widgetsEditor.editors[editor];

    if (editorContext) {
      items = widgets.map(widget => {
        return editorContext.widgetsById[widget];
      }).filter(widget => {
        return widget.type === 'widgets.form.step';
      }).map(widget => {
        return { key: widget.id, value: widget.id, text: widget.name }
      });
    }

    return items;
  });

  return (
    <div className={styles.steps} style={style}>
      State {' '}
      <Dropdown
        inline
        options={widgets}
        value={value}
        onChange={(e, data) => {
          onChange(data.value);
        }}
      />
    </div>
  );
}

function StepWidget({ step, id, pardotFormStep, editor, content, properties, ...rest }) {
  const widget = useSelector(state => {
    const editorContext = state.widgetsEditor.editors[editor];

    if (editorContext) {
      return editorContext.widgetsById[step];
    }

    return null;
  });

  if (!widget) {
    return false;
  }

  if (step === pardotFormStep) {
    return <HubspotStepWidget 
      id={id}
      widget={widget} 
      content={content} 
      editor={editor} 
      properties={properties} />
  }

  return (
    <div>
      <Widget.Children widgets={widget.widgets} editor={editor} editable={rest.editable} />
    </div>
  );
}

function HubspotStepWidget({ widget, id, content, editor, properties }) {
  var fetching = _.get(content, 'fetching', false);
  var formId = _.get(properties, 'form', null);
  var portalId = _.get(properties, 'portalId', null);
  var region = _.get(properties, 'region', null);

  const dimension = _.get(properties, 'dimension') || {};
  var dimensionStyles = calculateModalDimension(dimension);

  useEffect(() => {
    if (window.hbspt && portalId && formId) {
      window.hbspt.forms.create({
        region: region || "na1",
        portalId: portalId,
        formId: formId,
        target: "#contact_form",
      });
    }
  }, [content])

  if (dimensionStyles.height) {
    dimensionStyles = {
      ...dimensionStyles,
      height: `calc(${dimensionStyles.height} + 60px)`
    }
  }


  return (
    <>
      {fetching &&
        <div className={styles.loader}>
          <Dimmer active inverted>
            <Loader active>Loading Hubspot form...</Loader>
          </Dimmer>
        </div>
      }

      {!fetching &&
        <div id="contact_form"></div>
      }

      {!fetching && !formId &&
        <EmptyView id={id} editor={editor} properties={properties} />
      }
    </>
  );
}

function EmptyView(props) {
  return (
    <Segment basic>
      <Header as='h3' icon textAlign='center'>
        <Icon name='settings' size='small' />
        No Form Selected
        <Header.Subheader>
          Please select a hubspot form.
          <HubspotFormModal
            trigger={
              <a href='#'>Click here</a>
            } widget={props.id} editor={props.editor} properties={props.properties} />
        </Header.Subheader>
      </Header>
    </Segment>
  );
}

function FormQuickSettings(props) {
  const dispatch = useDispatch();

  const integrations = useSelector(state => _.get(state, 'integrations.activated.items', []));
  const freshmarketer = integrations.filter(item => {
    return item != null;
  }).find(item => {
    return item.type = 'freshmarketer';
  });

  const [selected, setSelected] = useState('action');

  const menuItems = [
    { id: 'action', title: 'Action', icon: 'icon-layout' },
    { id: 'dimension', title: 'Dimension', icon: 'icon-spacing' }
  ]

  const handleMenuClick = (item) => {
    setSelected(item);
  }

  var widget = useSelector(state => {
    const editorContext = state.widgetsEditor.editors[props.editor];

    if (editorContext) {
      return editorContext.widgetsById[props.id];
    }

    return null;
  });
  if (!widget) {
    return false;
  }

  var properties = widget.properties;
  var integrationProperties = _.get(widget.properties, 'integrations') || [];
  var freshmarketerProperty = integrationProperties.find(item => {
    return item.type === 'properties.integrations.freshmarketer';
  });

  const handleChange = (changeRequest) => {
    dispatch(updateProperties({
      id: widget.id,
      change: changeRequest,
      context: props.editor
    }));
  }

  return (
    <QuickSettings menuItems={menuItems} selected={selected} onMenuClick={handleMenuClick} >
      {selected === 'action' &&
        <>
          <EmailProperty
            value={properties.emails}
            onChange={(value) => {
              handleChange({
                emails: value
              })
            }}
          />

          {freshmarketer &&
            <FreshMarketerProperty
              value={freshmarketerProperty}
              onChange={(value) => {
                var updated = integrationProperties.filter(item => {
                  return item.type != "properties.integrations.freshmarketer"
                });

                const request = {
                  type: "properties.integrations.freshmarketer",
                  lists: value
                };

                updated = [...updated, request]

                handleChange({
                  integrations: updated
                });
              }}
            />
          }
        </>
      }

      {selected === 'dimension' &&
        <>
          <QuickSettingsProperty.Input
            title='Width'
            value={_.get(properties, 'dimension.width')}
            onChange={(value) => {
              const dimension = _.get(properties, 'dimension') || {};

              var changes = {
                width: value
              };

              handleChange({
                dimension: {
                  ...dimension,
                  ...changes
                }
              });
            }}
          />

          <QuickSettingsProperty.Input
            title='Height'
            value={_.get(properties, 'dimension.height')}
            onChange={(value) => {
              const dimension = _.get(properties, 'dimension') || {};

              var changes = {
                height: value
              };

              handleChange({
                dimension: {
                  ...dimension,
                  ...changes
                }
              });

            }}
          />
        </>}
    </QuickSettings>
  )
}

HubspotForm.QuickSettings = FormQuickSettings;

function FormProperty(props) {
  const dispatch = useDispatch();

  const integrations = useSelector(state => _.get(state, 'integrations.activated.items', []));
  const freshmarketer = integrations.filter(item => {
    return item != null;
  }).find(item => {
    return item.type = 'freshmarketer';
  });

  var widget = useSelector(state => {
    const editorContext = state.widgetsEditor.editors[props.editor];

    if (editorContext) {
      return editorContext.widgetsById[props.id];
    }

    return null;
  });
  if (!widget) {
    return false;
  }

  var properties = widget.properties;
  var integrationProperties = _.get(widget.properties, 'integrations') || [];
  var freshmarketerProperty = integrationProperties.find(item => {
    return item.type === 'properties.integrations.freshmarketer';
  });

  console.log(freshmarketerProperty);

  const handleChange = (changeRequest) => {
    dispatch(updateProperties({
      id: widget.id,
      change: changeRequest,
      context: props.editor
    }));
  }

  return (
    <>
      <Property.Section title='Action' expanded>
        <EmailProperty
          value={properties.emails}
          onChange={(value) => {
            handleChange({
              emails: value
            })
          }}
        />

      </Property.Section>

      {freshmarketer &&
        <Property.Section title='Freshmarketer' expanded>
          <FreshMarketerProperty
            value={freshmarketerProperty}
            onChange={(value) => {
              var updated = integrationProperties.filter(item => {
                return item.type != "properties.integrations.freshmarketer"
              });

              const request = {
                type: "properties.integrations.freshmarketer",
                lists: value
              };

              updated = [...updated, request]

              handleChange({
                integrations: updated
              });
            }}
          />
        </Property.Section>
      }
    </>
  );
}

HubspotForm.Property = FormProperty;
function EmailProperty({ value, onChange }) {
  const [input, setInput] = useState('');
  const emails = value || [];
  const handleKeyPressed = (e) => {
    if (e.key === "Enter") {
      handleChanges();
    }
  }
  const handleChanges = () => {
    var updated = emails;
    if (!updated) {
      updated = [];
    }
    if (input && input.trim() && validateEmail(input.trim())) {
      updated = [...updated, input.trim()];
      updated = _.uniq(updated);
      onChange(updated);
    }
    setInput('');
  }
  const deleteValue = (index) => {
    var updated = [...emails];
    updated.splice(index, 1);
    onChange(updated);
  }
  return (
    <Property title={'Notify'} tooltip={'Notify users'}>
      {emails.map((item, index) => {
        return (
          <Tooltip content={item} inverted>
            <Label key={`${item}-${index}`} image className={cx(styles.email)}>
              <div className={styles.value}><Truncate lines={1}>{item}</Truncate></div>
              <Icon name='delete' onClick={deleteValue.bind(this, index)} />
            </Label>
          </Tooltip>
        );
      })}
      <Input className={styles.emailInput} size='mini'
        placeholder='Email address'
        value={input}
        onChange={(e) => {
          setInput(e.target.value);
        }}
        onBlur={handleChanges}
        onKeyPress={handleKeyPressed}
      />
    </Property>
  );
}

function FreshMarketerProperty({ value, onChange }) {
  const dispatch = useDispatch();
  const lists = useSelector(state => _.get(state, 'integrations.freshmarketer.lists.items', []));
  const pagination = useSelector(state => _.get(state, 'integrations.freshmarketer.lists.pagination', {}));
  const { current, totalPages } = pagination;
  useEffect(() => {
    dispatch(fetchFreshmarketerLists(1));
  }, []);
  const handleChange = (e, data) => {
    console.log(data);
    if (data.value[data.value.length - 1] === 'loadMore') {
      dispatch(fetchFreshmarketerLists(current + 1));
      return;
    }
    var values = (data.value || []).map(id => {
      var option = lists.find(list => {
        return list.id === id;
      })
      return option;
    });
    onChange(values);
  }
  var selectedOptions = (_.get(value, 'lists') || []).map(option => {
    return {
      key: option.id,
      value: option.id,
      text: option.name,
      content: (
        <p>{option.name}</p>
      )
    }
  })
  var options = lists.map(option => {
    return {
      key: option.id,
      value: option.id,
      text: option.name,
      content: (
        <p>{option.name}</p>
      )
    }
  });
  options = [...selectedOptions, ...options].reduce((x, y) => {
    var index = x.findIndex((e) => {
      return e.key === y.key;
    });
    if (index < 0) {
      return [...x, y];
    } else {
      x[index] = y;
      return x;
    }
  }, []);
  if (current < totalPages) {
    options = [...options, {
      key: 'loadMore',
      value: 'loadMore',
      text: 'Load More',
      content: (
        <p>Load More</p>
      )
    }]
  }
  return (
    <Property.Dropdown title='Lists'
      tooltip='Freshmarketer lists to push contacts'
      placeholder='Freshmarketer lists'
      selection
      multiple
      options={options}
      value={(_.get(value, 'lists') || []).map(item => {
        return item.id;
      })}
      onChange={handleChange}
    />
  )
}

function calculateModalDimension(dimension) {
  if (!dimension) {
    return null;
  }

  var width = dimension.width;
  var height = dimension.height;

  if (width) {
    var viewportWidth = window.innerWidth - 100;
    width = Math.min(width, viewportWidth);
  }

  if (height) {
    var viewportHeight = window.innerHeight - 80;
    height = Math.min(height, viewportHeight);
  }

  dimension = {
    ...dimension,
    width: width,
    height: height
  }

  var dimensionStyles = getDimensionStyles(dimension);

  if (dimensionStyles.height) {
    dimensionStyles = {
      ...dimensionStyles,
      height: `calc(${dimensionStyles.height} - 60px)`
    }
  }

  return dimensionStyles;
}