import React, { PropTypes, Fragment, useState, useEffect } from 'react';
import { useSelector, useDispatch, connect } from 'react-redux';
import { selectWidget, deleteWidget, updateWidgetName, moveWidgetToIndex } from 'actions/widgetEditor';

import cx from 'classnames';
import _ from 'lodash';

import {
  Tab,
  Button,
  Grid,
  Header,
  Icon,
  Input,
  List,
  Popup
} from 'semantic-ui-react';

import { DndProvider, useDrop, useDrag, useDragLayer } from 'react-dnd';
import Backend from 'react-dnd-html5-backend';

import Truncate from 'react-truncate';
import { WidgetRegistry } from 'components/Widgets/registry.jsx';
import styles from './templates.module.scss';
import { useWidgetSelecion } from 'components/Widgets/widget';
import { Property } from 'components/Properties';

import { updatePageTemplateProperties, updatePageTemplate } from 'actions/templates';

export function PropertyPane(props) {
  const panes = [
    {
      menuItem: 'Properties', render: () => {
        return (
          <TabPane title='Properties' onClick={props.onClick}>
            <PropertiesPane editor={props.editor} />
          </TabPane>
        )
      }
    },
    {
      menuItem: 'Page Structure', render: () => {
        return (
          <TabPane title='Page Structure' onClick={props.onClick}>
            <PageStructurePane editor={props.editor} widgets={props.widgets} />
          </TabPane>
        )
      }
    }];
  const [activeTabIndex, setActiveTabIndex] = useState(0);

  const handleTabChange = (e, data) => {
    setActiveTabIndex(data.activeIndex);
    if (!props.expanded) {
      props.onClick();
    }
  }

  return (
    <Tab
      className={cx(styles.propertiesPane, {
        [styles.visible]: props.visible,
        [styles.expanded]: props.expanded
      })}
      menu={{ fluid: true, secondary: true }}
      panes={panes}
      activeIndex={props.expanded ? activeTabIndex : -1}
      onTabChange={handleTabChange}
    />
  );
}

function TabPane({ title, children, onClick }) {
  return (
    <Tab.Pane as={Grid} padded='vertically' className={cx(styles.pane)}>
      <Grid.Row className={styles.header}>
        <Grid.Column verticalAlign='middle' width={11}>
          <Header as='h4'>{title}</Header>
        </Grid.Column>
        <Grid.Column floated='right' verticalAlign='middle' width={1}>
          <Button icon floated='right' onClick={onClick}><Icon name='delete' /></Button>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row className={styles.body} stretched>
        <Grid.Column>
          {children}
        </Grid.Column>
      </Grid.Row>
    </Tab.Pane>
  );
}

export function PropertiesPane(props) {
  const selectedWidget = useWidgetSelecion(props.editor);

  if (!selectedWidget) {
    return <TemplatePageProperties />;
  }

  return (
    WidgetRegistry.properties(selectedWidget.type,
      {
        id: selectedWidget.id,
        widget: selectedWidget,
        editor: props.editor
      }
    )
  );
}

const DragDropContext = React.createContext({
  placeholder: null,
  setPlaceholder: () => { }
});

const PageStructurePane = React.memo(function PageStructurePane(props) {
  const dispatch = useDispatch();
  const ref = React.useRef(null);
  const [placeholder, setPlaceholder] = useState(null);

  const widgets = useSelector(state => {
    var items = props.widgets || [];
    const editorContext = state.widgetsEditor.editors[props.editor];

    if (editorContext) {
      return items.map(id => {
        return editorContext.widgetsById[id];
      })
    }

    return [];
  });

  const [{ isOver, draggingColor, canDrop }, drop] = useDrop({
    accept: ['WIDGET_NODE_ITEM'],
    drop(item, monitor) {
      const hasDroppedOnChild = monitor.didDrop();
      if (hasDroppedOnChild) {
        return;
      }

      handleDrop(item.index, item.widget, item.parent);
      return;
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      isOverCurrent: monitor.isOver({ shallow: true }),
      canDrop: monitor.canDrop(),
      draggingColor: monitor.getItemType(),
    }),
  });

  const handleDragHover = (data) => {
    setPlaceholder(data);
  }

  const handleDrop = (index, widget, parent) => {
    console.log(`Move widget ${widget.id} to index ${index} and parent ${parent}`);
    dispatch(moveWidgetToIndex(widget, parent, index, props.editor));
    dispatch(selectWidget(props.editor, widget));
    setPlaceholder(null);
  }

  drop(ref);

  return (
    <DragDropContext.Provider value={{
      placeholder: placeholder,
      setPlaceholder: setPlaceholder
    }}>
      <div className={styles.pageStructure} ref={ref}>
        <List>
          <WidgetChildren
            editor={props.editor}
            parent={null}
            widgets={widgets}
            onDragHover={handleDragHover}
            onDrop={handleDrop}
          />
        </List>
      </div>
    </DragDropContext.Provider>
  );
});

const DraggableWidgetNode = React.memo(function DraggableWidgetNode({ index, widget, parent, editor, onDragHover, onDrop }) {
  const ref = React.useRef(null);
  const { placeholder } = React.useContext(DragDropContext);

  var widgetType = "WIDGET_NODE_ITEM";
  // if(widget.type != 'layout.row' && widget.type != 'layout.column'){
  //   widgetType = 'WIDGET_NODE_ITEM';
  // }

  var acceptTarget = ['WIDGET_NODE_ITEM'];
  // if(widgetType === 'layout.row'){
  //   acceptTarget = ['layout.row', 'layout.column'];
  // }else if(widgetType === 'layout.column'){
  //   acceptTarget = ['layout.column', 'WIDGET_NODE_ITEM'];
  // }

  const [{ opacity }, drag] = useDrag(() => ({
    type: widgetType,
    item: {
      type: widgetType,
      parent: parent,
      widget: widget,
      index: index
    },
    canDrag: true,
    collect: monitor => ({
      opacity: monitor.isDragging() ? 0.5 : 1,
    }),
  }));

  const [{ isOver, draggingColor, canDrop }, drop] = useDrop({
    accept: acceptTarget,
    drop(item, monitor) {
      const hasDroppedOnChild = monitor.didDrop();
      if (hasDroppedOnChild) {
        return;
      }

      if (onDrop) {
        onDrop(item.index, item.widget, item.parent);
      }

      return undefined
    },
    hover(item, monitor) {
      if (!ref.current || !monitor.isOver({ shallow: true })) {
        return
      }
      const dragIndex = item.index;
      const hoverIndex = index;
      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return
      }

      const hoverBoundingRect = ref.current.getBoundingClientRect()
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2
      const clientOffset = monitor.getClientOffset()
      const hoverClientY = clientOffset.y - hoverBoundingRect.top
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return
      }

      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return
      }

      var dropParent = parent;
      if (item.type != widgetType) {
        dropParent = widget.id;
      }

      if (onDragHover) {
        console.log(dropParent + ' - ' + widget.id + ' - ' + '[' + dragIndex + ',' + hoverIndex + ']');
        onDragHover({
          above: dragIndex > hoverIndex,
          index: hoverIndex,
          parent: dropParent
        });
      }

      item.parent = dropParent;
      item.index = hoverIndex;
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      isOverCurrent: monitor.isOver({ shallow: true }),
      canDrop: monitor.canDrop(),
      draggingColor: monitor.getItemType(),
    }),
  });

  drag(drop(ref));

  var shouldRender = placeholder !== null;
  if (shouldRender) {
    shouldRender = shouldRender && placeholder.parent === widget.id;
  }

  return (
    <div ref={ref} style={{ opacity: opacity }}>
      {shouldRender && placeholder.above && placeholder.index === index &&
        <div className={styles.placeholder} />
      }

      <WidgetNode key={widget.id} content={widget} editor={editor} />

      {shouldRender && !placeholder.above && placeholder.index === index &&
        <div className={styles.placeholder} />
      }
    </div>
  );
});

const WidgetNode = React.memo(function WidgetNode({ content, editor }) {
  const dispatch = useDispatch();

  const widgets = useSelector(state => {
    var items = _.get(content, 'widgets') || [];
    const editorContext = state.widgetsEditor.editors[editor];

    if (editorContext) {
      return items.map(id => {
        return _.get(editorContext, `widgetsById.${id}`, null);
      })
    }

    return [];
  });

  const [open, setOpen] = useState(false);
  const [edit, setEdit] = useState(false);
  const [name, setName] = useState(content.name);
  const { placeholder, setPlaceholder } = React.useContext(DragDropContext);

  const selectedWidget = useWidgetSelecion(editor);
  const selected = selectedWidget ? selectedWidget.id === content.id : false;
  const hasChildren = widgets.length > 0;

  useEffect(() => {
    if (!selected) {
      setEdit(false);
    }
  }, [selected])

  const handleClick = () => {
    console.log("Widget clicked");
    dispatch(selectWidget(editor, content.id));
  }

  const toggleMenu = () => {
    setOpen(!open);
  }

  const handleEdit = () => {
    setName(content.name);
    setEdit(true);
  }

  const handleUpdateName = () => {
    dispatch(updateWidgetName(content.id, name, editor));
    setEdit(false);
  }

  const handleDelete = () => {
    dispatch(deleteWidget(content.id, editor));
  }

  const handleDragHover = (data) => {
    setPlaceholder(data);

  }

  const handleDrop = (index, widget, parent) => {
    console.log(`Move widget ${widget.id} to index ${index} and parent ${parent}`);
    dispatch(moveWidgetToIndex(widget, parent, index, editor));
    dispatch(selectWidget(editor, widget));
    setPlaceholder(-1);
  }

  return (
    <List.Item className={cx(styles.node, { [styles.active]: selected })} active={open}>
      <List.Content>
        <List.Header onClick={handleClick}>
          {hasChildren > 0 &&
            <Icon link name={open ? 'caret down' : 'caret right'} onClick={toggleMenu} />
          }

          {!hasChildren &&
            <Icon color='red' size="small" />
          }

          <Icon name='newspaper outline' />
          {!edit &&
            <>
              <div className={styles.name}>
                <Truncate lines={1}>{content.name}</Truncate>
              </div>

              <div className={styles.actions}>
                <List.Icon link name='pencil' size="small" onClick={handleEdit} />
                <List.Icon link name='trash' size="small" onClick={handleDelete} />
              </div>
            </>
          }

          {edit &&
            <Input action>
              <input value={name} onChange={(e) => {
                setName(e.target.value);
              }} />
              <Button basic icon="check" size='mini' color='green' onClick={handleUpdateName} />
              <Button basic icon="close" size='mini' color='red' onClick={() => {
                setEdit(false)
              }} />
            </Input>
          }
        </List.Header>

        {(widgets.length > 0 && open) &&
          <List.List>
            <WidgetChildren
              parent={content.id}
              widgets={widgets}
              editor={editor}
              onDragHover={handleDragHover}
              onDrop={handleDrop}
            />
          </List.List>
        }
      </List.Content>
    </List.Item>
  );
})

function CustomDragLayer(props) {
  const {
    itemType,
    isDragging,
    item,
    initialOffset,
    currentOffset,
  } = useDragLayer((monitor) => ({
    item: monitor.getItem(),
    itemType: monitor.getItemType(),
    initialOffset: monitor.getInitialSourceClientOffset(),
    currentOffset: monitor.getSourceClientOffset(),
    isDragging: monitor.isDragging(),
  }));

  function renderItem() {
    switch (itemType) {
      case 'WIDGET_NODE_ITEM':
        return <WidgetPreview content={item.content} />
      default:
        return null
    }
  }

  if (!isDragging) {
    return null
  }

  return (
    <div className={styles.dragLayer}>
      <div>
        {renderItem()}
      </div>
    </div>
  )
}

const WidgetPreview = React.memo(function WidgetPreview({ content }) {
  return (
    <div className={styles.preview}>
      <Icon name='newspaper outline' />
      <div className={styles.name}>
        <Truncate lines={1}>{content.name}</Truncate>
      </div>
    </div>
  )
});

const WidgetChildren = React.memo(function WidgetChildren({ parent, widgets, editor, onDragHover, onDrop }) {
  const { placeholder, setPlaceholder } = React.useContext(DragDropContext);

  var shouldRender = placeholder !== null;
  if (shouldRender) {
    shouldRender = shouldRender && placeholder.parent === parent;
  }

  widgets = widgets || [];

  return (
    <>
      {widgets.map((widget, index) => {
        return (
          <Fragment key={index}>
            <DraggableWidgetNode
              key={widget.id}
              index={index}
              widget={widget}
              parent={parent}
              editor={editor}
              onDragHover={onDragHover}
              onDrop={onDrop}
            />
          </Fragment>
        )
      })}
    </>
  );
});

function TemplatePageProperties(props) {
  const dispatch = useDispatch();

  const template = useSelector(state => _.get(state, 'templates.pages.template.item', {}));
  const properties = _.get(template, 'draft.properties', {}) || {};

  const handleChange = (changeRequest) => {
    dispatch(updatePageTemplateProperties(template.id, changeRequest));
  }

  const handleBackgroundImageChange = (media, type) => {
    var changeRequest = properties;
    _.set(changeRequest, 'background.upload', {
      type: type,
      data: media
    });

    var request = {
      properties: changeRequest
    }

    dispatch(updatePageTemplate(template.id, request));
  }

  const handleCssChange = (css) => {
    var changeRequest = properties;
    _.set(changeRequest, 'css', css);

    var request = {
      properties: changeRequest
    }

    dispatch(updatePageTemplate(template.id, request));
  }

  const handleScriptChange = (script) => {
    var changeRequest = properties;
    _.set(changeRequest, 'script', script);

    console.log(script);
    console.log(changeRequest);

    var request = {
      properties: changeRequest
    }

    dispatch(updatePageTemplate(template.id, request));
  }

  return (
    <Fragment>
      <Property.Background expanded
        value={properties ? properties.background : {}}
        onChange={(background) => {
          handleChange({
            background: background
          });
        }}
        onImageChange={(image, type) => {
          handleBackgroundImageChange(image, type);
        }}
      />
      <Property.Section title='Advanced' expanded>
        <Property.CSS title='Stylesheets' value={properties ? properties.css : ''} onChange={handleCssChange} />
        <Property.Script title='Java script' value={properties ? properties.script : ''} onChange={handleScriptChange} />
      </Property.Section>
    </Fragment>
  )
}