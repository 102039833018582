import React, { PropTypes, Fragment, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import cx from 'classnames';
import {
  Menu,
  Grid,
  Button,
  Icon,
  Segment,
  Image,
  Header,
  Form,
  Input,
  Label,
  Checkbox
} from 'semantic-ui-react';

import {
  useRouteMatch,
} from "react-router-dom";

import _ from 'lodash';

import styles from './pardot.module.scss';

import InlineConfirmationDialog from 'components/InlineConfirmationDialog';

import { authenticatePardot, updatePardot } from 'actions/integrations/pardot';
import { deactivateIntegration, activatingIntegrationSuccedded } from 'actions/integrations/integrations';

import { getBaseUrl } from 'services/util';

import buildUrl from 'build-url';
const apiUrl = `${process.env.REACT_APP_API_URL}`;

export default function Pardot() {
  const dispatch = useDispatch();
  let { path, url } = useRouteMatch();
  let baseUrl = getBaseUrl(url);
  const history = useHistory();

  const integrations = useSelector(state => _.get(state, 'integrations.activated.items', []));
  const deactivating = useSelector(state => _.get(state, 'integrations.activated.deactivating', false));
  const errors = useSelector(state => _.get(state, 'integrations.pardot.changes.error', null));
  const authenticating = useSelector(state => _.get(state, 'integrations.pardot.changes.saving', false));
  const updating = useSelector(state => _.get(state, 'integrations.pardot.changes.updating', false));

  const integration = integrations.filter(item => {
    return item != null;
  }).find(item => {
    return item.type === 'pardot';
  })
  
  const [state, setState] = useState({
    authenticated: false,
    businessUnit: null,
    syncPageViewedEvent: true,
    syncReadAssetEvent: true,
    syncAssetDownloadEvent: true
  })

  useEffect(state => {
    if (integration && integration.state.code === "ok" && !errors) {
      console.log('data of integration updat',_.get(integration, 'configuration.syncPageViewedEvent'))
      setState((state)=>{
        return {
          ...state,
          authenticated: true,
          syncPageViewedEvent: _.get(integration, 'configuration.syncPageViewedEvent'),
          syncReadAssetEvent: _.get(integration, 'configuration.syncAssetViewedEvent'),
          syncAssetDownloadEvent: _.get(integration, 'configuration.syncAssetDownloadEvent')
        }
      });
    } else {
      setState((state)=>{
        return {
          ...state,
          authenticated: false
        }
      });
    }

    const businessUnitId = _.get(integration, 'configuration.businessUnit', '');
    setState((state)=>{
      return {
        ...state,
        businessUnit: businessUnitId
      }
    });
  }, [integration]);

  const handleDeleteClick = () => {
    dispatch(deactivateIntegration('pardot', (res) => {
      handleBackClick();
    }))
  }

  const handleBackClick = () => {
    history.push(`${baseUrl}/settings/integrations`);
  }

  const handleSyncEvents =(event)=> {
    setState((state)=>{
      return {
        ...state,
        [event]: !state[event]
      }
    })
  }

  const handleAuthenticate = () => {
    //Calculate Position
    var dualScreenLeft = window.screenLeft != undefined ? window.screenLeft : window.screen.left;
    var dualScreenTop = window.screenTop != undefined ? window.screenTop : window.screen.top;

    var width = window.innerWidth ? window.innerWidth : document.documentElement.clientWidth ? document.documentElement.clientWidth : window.screen.width;
    var height = window.innerHeight ? window.innerHeight : document.documentElement.clientHeight ? document.documentElement.clientHeight : window.screen.height;

    var w = 0.8 * width;
    var h = 0.8 * height;

    var left = ((width / 2) - (w / 2)) + dualScreenLeft;
    var top = ((height / 2) - (h / 2)) + dualScreenTop;

    const url = buildUrl(apiUrl, {
      path: `/api/2.0/integrations/pardot/authorize`
    })

    var newWindow = window.open(url, `Authorize pardot Account`, 'scrollbars=yes, width=' + w + ', height=' + h + ', top=' + top + ', left=' + left);

    // Puts focus on the newWindow
    if (newWindow.focus) {
      newWindow.focus();
    }

    //Attaching message event listner
    var eventMethod = window.addEventListener ? "addEventListener" : "attachEvent";
    var eventer = window[eventMethod];
    var messageEvent = eventMethod == "attachEvent" ? "onmessage" : "message";

    // Listen to message from child window
    eventer(messageEvent, handleAuthenticateEvent, false);
    return false;
  }

  const handleAuthenticateEvent = (e) => {
    //Removing message event listner
    var detachEventMethod = window.removeEventListener ? 'removeEventListener' : 'detachEvent';
    var deEventer = window[detachEventMethod];
    var messageEvent = detachEventMethod == "detachEvent" ? "onmessage" : "message";

    if (e.data.type === 'PARDOT_AUTHORIZATION_SUCCESS') {
      var code = e.data.payload.code;
      var state = e.data.payload.state;
      var error = e.data.payload.error;

      if (!error) {
        dispatch(authenticatePardot(code, (response) => {
          dispatch(activatingIntegrationSuccedded(response))
        }))
      }

      deEventer(messageEvent, handleAuthenticateEvent, false);
    }
  }

  const handleBusinessIdChange = (e) => {
    e.persist()
    setState((state)=>{
      return {
        ...state,
        businessUnit: e.target.value
      }
    })
  }

  const handleRestClick = () => {
    const businessUnitId = _.get(integration, 'configuration.businessUnit', '');

    setState((state)=>{
      return {
        ...state,
        businessUnit: businessUnitId
      }
    })
  }

  const handleSaveClick = () => {
    var request = {
      businessUnit: state.businessUnit,
      syncPageViewedEvent: state.syncPageViewedEvent,
      syncAssetViewedEvent: state.syncReadAssetEvent,
      syncAssetDownloadEvent: state.syncAssetDownloadEvent
    }

    dispatch(updatePardot(request, (response) => {
      dispatch(activatingIntegrationSuccedded(response))
    }))
  }

  return (
    <Grid columns="equal" className={styles.pardot}>
      <Grid.Row>
        <Grid.Column width={5} className={styles.detailsView}>
          <Menu secondary>
            <Button className={styles.backButton} onClick={handleBackClick}>
              <Icon name={'angle left'} />BACK
            </Button>
          </Menu>

          <div className={styles.details}>
            <Image src={`/images/integrations/pardot.png`} />
            <Header as='h2'>Pardot</Header>
            <p className={styles.description}>
              Integrate with Pardot to sync events across contacts
            </p>
            {integration && integration.state.code === 'ok' &&
              <InlineConfirmationDialog onConfirm={handleDeleteClick} message={'Are you sure to deactivate Pardot?'}>
                <Button className={styles.deactivateButton}>{deactivating ? 'DEACTIVATING...' : 'DEACTIVATE'}</Button>
              </InlineConfirmationDialog>
            }
          </div>
        </Grid.Column>

        <Grid.Column className={styles.authView}>
          <Segment basic className={styles.authSegment}>
            {!state.authenticated &&
              <Segment basic className={styles.authorize}>
                <h3 className={styles.integrationHeading}>Log into your Pardot account</h3>
                <p className={styles.integrationSummary}>A secure Pardot account authorization window will <br />open when you click authenticate below</p>
                <Button className={styles.authButton} onClick={handleAuthenticate} disabled={authenticating}>
                  {authenticating &&
                    'Authenticating...'
                  }

                  {!authenticating &&
                    'Authenticate'
                  }
                </Button>
              </Segment>
            }

            {state.authenticated &&
              <Segment basic className={styles.authorize}>
                <h3 className={styles.integrationHeading}>Pardot Integration</h3>
                <Form className={styles.form} autoComplete="new-password">
                  <Form.Field>
                    <Input labelPosition='left'
                      type='text'
                      placeholder='Enter your Pardot Business Unit Id'
                      value={state.businessUnit}
                      onChange={handleBusinessIdChange}
                    >
                      <Label basic>PARDOT BUSINESS UNIT ID</Label>
                      <input />
                    </Input>
                  </Form.Field>
                </Form>
                <div className={styles.syncEventWrapper}>
                  <Header as='h4'> Sync Events </Header>
                  <Grid.Row className={styles.event}>
                    <Header.Subheader className={styles.header}>
                      Contact/Lead views an asset
                    </Header.Subheader>
                    <Checkbox toggle checked={state.syncReadAssetEvent} onChange={(e) => handleSyncEvents('syncReadAssetEvent')}/>
                  </Grid.Row>
                  <Grid.Row className={styles.event}>
                    <Header.Subheader className={styles.header}>
                      Contact/Lead downloads an asset
                    </Header.Subheader>
                    <Checkbox toggle checked={state.syncAssetDownloadEvent} onChange={(e) => handleSyncEvents('syncAssetDownloadEvent')}/>
                  </Grid.Row>
                  <Grid.Row className={styles.event}>
                    <Header.Subheader className={styles.header}>
                      Contact/Lead visits a page
                    </Header.Subheader>
                    <Checkbox toggle checked={state.syncPageViewedEvent} onChange={(e) => handleSyncEvents('syncPageViewedEvent')}/>
                  </Grid.Row>
                </div>

                <div className={styles.actions}>
                  <Button onClick={handleSaveClick}>
                    <Icon loading={updating} name={updating ? 'spinner' : 'save'} />
                    {updating ? 'Saving...' : 'Save Changes'}
                  </Button>
                  <Button onClick={handleRestClick}>
                    Reset
                  </Button>
                </div>
              </Segment>
            }
          </Segment>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  )
}