import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { createWidget, moveWidgetToIndex, rearrangeWidgets, updateWidgetName, deleteWidget } from 'actions/widgetEditor';

import _ from 'lodash';

import {
  Modal,
  Menu,
  Label,
  Segment,
  Button,
  Input,
  Icon
} from 'semantic-ui-react';
import Tooltip from 'components/Tooltip';

import cx from 'classnames';
import styles from './section.module.scss';

import { useDrag, DndProvider, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { WidgetContext } from 'pages/pages/editor/editor.jsx';

const tab = {
  name: "Untitled",
  type: "widgets.section.item",
  properties: {},
  capabilities: {
    draggable: false,
    droppable: false,
    editable: true,
    resizable: false
  },
  widgets: [
    {
      name: "container",
      type: "widgets.container",
      properties: {},
      capabilities: {
        draggable: false,
        droppable: true,
        editable: false,
        resizable: false,
        deletable: false
      }
    }
  ]
}

export default function TabsModal({trigger, widget, editor, ...rest}) {
  const dispatch = useDispatch();
  const { entityReference } = React.useContext(WidgetContext);

  const tabs = widget.widgets || [];

  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  }

  const handleAddTab = () => {
    const request = {
      widgets: [tab],
      reference: entityReference
    };

    dispatch(createWidget(editor, request, (response) => {
      dispatch(moveWidgetToIndex(editor, response[0].id, widget.id, tabs.length, false))
    }))
  }

  const handleRearrangeMenu = (item, index) => {
    dispatch(rearrangeWidgets(editor, entityReference, item, widget.id, index, false));
  }

  return (
    <DndProvider backend={HTML5Backend}>
      <Modal trigger={trigger} size='small'
        className={styles.content}
        open={open}
        onOpen={() => {
          setOpen(true);
        }}
      >
        <Modal.Header>
          <Menu secondary>
            <Menu.Item header as="h2">Tabs <Label>{tabs.length}</Label></Menu.Item>

            <Menu.Menu position='right'>
              <Menu.Item className={styles.action}>
                <Button className={styles.addTabButton} onClick={handleAddTab}>
                  Add Tab
                  <Icon name='add' />
                </Button>
              </Menu.Item>
            </Menu.Menu>
          </Menu>
        </Modal.Header>
          <Modal.Content>
            {tabs.map((item, index) => {
              return <TabItem key={item} 
                id={item} 
                index={index}
                editor={editor}
                onRearrange={handleRearrangeMenu}
                 />
            })}
          </Modal.Content>
        <Modal.Actions>
          <Button className={styles.closeButton} onClick={handleClose}>CLOSE</Button>
        </Modal.Actions>
      </Modal>
    </DndProvider>
  )
}

function TabItem({ id, editor, index, selected, onChange, onRearrange, ...rest}) {
  const dispatch = useDispatch();

  const ref = React.useRef(null);
  const handleRef = React.useRef(null);

  var widget = useSelector(state => {
    const editorContext = state.widgetsEditor.editors[editor];

    if (editorContext) {
      return editorContext.widgetsById[id];
    }

    return null;
  });


  const [{ isDragging }, drag, preview] = useDrag(() => ({
    type: 'menu',
    item: { type: 'menu', widget, index },
    canDrag: () => {
      return true;
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  }));

  const [, drop] = useDrop(() => ({
    accept: "menu",
    hover(item, monitor) { 
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;

      if (dragIndex === hoverIndex) {
        return;
      }

      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      const clientOffset = monitor.getClientOffset();
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;

      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      // Dragging upwards

      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }

      onRearrange(item.widget, hoverIndex);

      item.index = hoverIndex;
    },
  }));

  drag(handleRef);

  const handleChange = (e) => {
    var name = e.target.value;
    dispatch(updateWidgetName(id, name, editor));
  }

  const handleDelete = () => {
    dispatch(deleteWidget(id, editor));
  }

  return (
    <div className={cx(styles.menu, {
      [styles.active]: selected,
      [styles.selectable]: rest.editable || rest.deletable,
      [styles.dragging]: isDragging
    })} ref={drag(drop(ref))}>
      <Handle ref={handleRef} />
      <Input className={styles.input}
        value={widget.name}
        onChange={handleChange}
      />
      <Tooltip inverted content={'Delete Tab'} position='top right'>
        <Button
          icon="trash"
          floated='right'
          size='mini'
          compact
          onClick={handleDelete}
        />
      </Tooltip>
    </div>
  )
}

const Handle = React.forwardRef((props, ref) => {
  return (
    <div className={styles.handle} ref={ref}>
      <div className={styles.line}>
        {_.times(2).map((index) => {
          return <span key={index} />
        })}
      </div>
      <div className={styles.line}>
        {_.times(2).map((index) => {
          return <span key={index} />
        })}
      </div>
      <div className={styles.line}>
        {_.times(2).map((index) => {
          return <span key={index} />
        })}
      </div>
    </div>
  );
});