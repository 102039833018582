import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Truncate from 'react-truncate';

import styles from './Style.css';
import cx from 'classnames';

class ReadMoreTextView extends React.Component {
  constructor(props){
    super(props);

    this.state = {
      expanded: false,
      truncated: false,
      initialised: false,
      needsTruncation: true
    };

    this.handleTruncate = this.handleTruncate.bind(this);
    this.toggleLines = this.toggleLines.bind(this);
  }

  handleTruncate(truncated) {
    if (this.state.truncated !== truncated || !this.state.initialised) {
      this.setState({
        truncated: truncated,
        initialised: true,
        needsTruncation: this.state.initialised ? this.state.needsTruncation : truncated
      });
    }
  }

  toggleLines(event) {
    event.preventDefault();

    this.setState({
      expanded: !this.state.expanded
    });
  }


  render() {
    let {
        children,
        more,
        less,
        lines
    } = this.props;

    const {
      expanded,
      truncated,
      needsTruncation
    } = this.state;

    return (
      <div>
        <Truncate
          lines={needsTruncation && !expanded && lines}
          ellipsis={(
            <span>... {more && <div className={styles.link} onClick={this.toggleLines}>{more}</div>}</span>
          )}
          onTruncate={this.handleTruncate}
        >
          {children}
        </Truncate>
        {!truncated && expanded && (
          <div> <div className={styles.link} onClick={this.toggleLines}>{less}</div></div>
        )}
      </div>
    );
  }
}

ReadMoreTextView.defaultProps = {
  lines: 3,
  more: 'Read more',
  less: 'Show less'
};

ReadMoreTextView.propTypes = {
  children: PropTypes.node.isRequired,
  lines: PropTypes.number,
  less: PropTypes.string,
  more: PropTypes.string
};

export default ReadMoreTextView;
