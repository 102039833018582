import {
  CREATING_CUSTOM_FIELD_REQUEST, CREATING_CUSTOM_FIELD_SUCCEEDED, CREATING_CUSTOM_FIELD_FAILED,
  UPDATING_CUSTOM_FIELD_REQUEST, UPDATING_CUSTOM_FIELD_SUCCEEDED, UPDATING_CUSTOM_FIELD_FAILED,
  FETCH_CUSTOM_FIELDS, FETCH_CUSTOM_FIELDS_SUCCEDDED, FETCH_CUSTOM_FIELDS_FAILED,
  INVALIDATE_CUSTOM_FIELDS,
  DELETING_CUSTOM_FIELD_REQUEST, DELETING_CUSTOM_FIELD_SUCCEEDED, DELETING_CUSTOM_FIELD_FAILED
} from 'constants/actionTypes';

import _ from 'lodash';

const initialState = {
  loading: false,
  creating: false, 
  updating: false,
  invalidated: true,
  items: [],
  error: null
}

export function customFieldsReducer(state = initialState, action) {
  switch (action.type) {
    case CREATING_CUSTOM_FIELD_REQUEST: 
      return {
        ...state,
        creating: true
      }

    case CREATING_CUSTOM_FIELD_SUCCEEDED: 
      var items = state.items.slice();
      items.splice(state.items.length, 0, action.payload);

      items = items.sort((a, b) => {
        return a.name.localeCompare(b.name);
      });

      return {
        ...state,
        creating: false,
        items: items
      }

    case CREATING_CUSTOM_FIELD_FAILED:
      return {
        ...state,
        creating: false,
        error: action.payload
      }

    case UPDATING_CUSTOM_FIELD_REQUEST: 
      return {
        ...state,
        updating: true,
      }

    case UPDATING_CUSTOM_FIELD_SUCCEEDED:
      var id = action.payload.id;

      items = state.items.map(item => {
        if (item.id !== id) {
          return item;
        }

        return {
          ...action.payload.response
        }
      })

      items = items.sort((a, b) => {
        return a.name.localeCompare(b.name);
      });

      return {
        ...state,
        updating: false,
        items: items
      }

    case UPDATING_CUSTOM_FIELD_FAILED:
      return {
        ...state,
        updating: false,
        error: action.payload.error
      }

    case FETCH_CUSTOM_FIELDS:
      return {
        ...state,
        loading: true
      }

    case FETCH_CUSTOM_FIELDS_SUCCEDDED:
      items = action.payload.response;

      items = _.orderBy(items, 'order', 'asc');

      return {
        ...state,
        loading: false,
        invalidated: false,
        items: items
      }

    case FETCH_CUSTOM_FIELDS_FAILED:
      return {
        ...state,
        loading: false,
        invalidated: true,
        items: []
      }

    case INVALIDATE_CUSTOM_FIELDS:
      return {
        ...state,
        loading: false,
        invalidated: true,
        items: []
      }

    case DELETING_CUSTOM_FIELD_REQUEST:
      return {
        ...state,
        deleting: true
      }

    case DELETING_CUSTOM_FIELD_SUCCEEDED:
     items = state.items.filter((item, index) => {
        return item.id !== action.payload.id;
      })

    return {
      ...state,
      deleting: false,
      items: items
    }

    case DELETING_CUSTOM_FIELD_FAILED:
      return {
        ...state,
        deleting: false,
        error: action.payload.error
      }

    default: 
      return state;
  }
}