import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import cx from "classnames";
import {
  Menu,
  Grid,
  Button,
  Icon,
  Segment,
  Image,
  Header,
  Form,
  Input,
  Label,
} from "semantic-ui-react";

import { useRouteMatch } from "react-router-dom";

import InlineConfirmationDialog from "components/InlineConfirmationDialog";

import { getBaseUrl } from "services/util";

import {
  activatingAccount,
  updatingSixSenseConfiguration,
} from "actions/integrations/sixsense";
import {
  deactivateIntegration,
  activatingIntegrationSuccedded,
} from "actions/integrations/integrations";

import _ from "lodash";
import styles from "./sixsense.module.scss";

export default function SixSense() {
  const dispatch = useDispatch();
  let { url } = useRouteMatch();
  let baseUrl = getBaseUrl(url);
  const history = useHistory();

  const integrations = useSelector((state) =>
    _.get(state, "integrations.activated.items", [])
  );
  const deactivating = useSelector((state) =>
    _.get(state, "integrations.activated.deactivating", false)
  );
  const activating = useSelector((state) =>
    _.get(state, "integrations.sixsense.activation.activating")
  );
  const activatingErrors = useSelector((state) =>
    _.get(state, "integrations.sixsense.activation.error", {})
  );
  const changes = useSelector((state) =>
    _.get(state, "integrations.sixsense.changes.saving")
  );
  const updatingErrors = useSelector((state) =>
    _.get(state, "integrations.sixsense.changes.error")
  );

  const integration = integrations
    .filter((item) => {
      return item != null;
    })
    .find((item) => {
      return item.type === "six_sense";
    });

  const [apiKey, setApiKey] = useState("");
  const [current, setCurrent] = useState(
    _.get(integration, "configuration.apiKey", false)
  );
  const [error, setError] = useState("");

  useEffect(() => {
    handleResetClick();
  }, [integration]);

  useEffect(() => {
    if (activatingErrors && activatingErrors.errors) {
      setError("Please provide valid Six Sense API key.");
    }
    if (updatingErrors && updatingErrors.errors) {
      setError("Please provide valid Six Sense API key.");
    }
  }, [activatingErrors, updatingErrors]);

  const handleBackClick = () => {
    history.push(`${baseUrl}/settings/integrations`);
  };

  const validateForm = () => {
    if (!apiKey || apiKey.trim().length === 0) {
      return "Please provide valid Six Sense API key.";
    }

    return null;
  };

  const handleSaveClick = () => {
    const errorMessage = validateForm();
    if (errorMessage) {
      setError(errorMessage);
      return;
    }

    let request = {
      apiKey: apiKey,
    };

    if (integration) {
      dispatch(
        updatingSixSenseConfiguration(request, (integration) => {
          dispatch(activatingIntegrationSuccedded(integration));
          setCurrent(true);
        })
      );
    } else {
      dispatch(
        activatingAccount(request, (integration) => {
          dispatch(activatingIntegrationSuccedded(integration));
          setCurrent(true);
        })
      );
    }
  };

  const handleResetClick = () => {
    setCurrent(_.get(integration, "configuration.apiKey", false));
  };

  const handleApikeyChange = (e) => {
    setApiKey(e.target.value);
  };

  const handleDeleteClick = () => {
    dispatch(
      deactivateIntegration("six-sense", (res) => {
        handleBackClick();
      })
    );
  };

  return (
    <Grid columns="equal" className={styles.sixsense}>
      <Grid.Row>
        <Grid.Column width={5} className={styles.detailsView}>
          <Menu secondary>
            <Button className={styles.backButton} onClick={handleBackClick}>
              <Icon name={"angle left"} />
              BACK
            </Button>
          </Menu>

          <div className={styles.details}>
            <Image src={`/images/integrations/sixsense.png`} />
            <Header as="h2">Six Sense</Header>
            <p className={styles.description}>
              Integrate for Six Sense to track shared links
            </p>

            {integration && integration.state.code === "ok" && (
              <InlineConfirmationDialog
                onConfirm={handleDeleteClick}
                message={"Are you sure to deactivate six sense?"}
              >
                <Button>
                  {deactivating ? "DEACTIVATING..." : "DEACTIVATE"}
                </Button>
              </InlineConfirmationDialog>
            )}
          </div>
        </Grid.Column>
        <Grid.Column className={styles.formView}>
          <Segment basic className={styles.formSegment}>
            <div
              className={cx(styles.errorMessage, { [styles.active]: error })}
            >
              <p>
                <Icon name="exclamation circle"></Icon> {error}
              </p>
            </div>
            <Form className={styles.form} autoComplete="new-password">
              <ProtectedPasswordField
                value={apiKey}
                onChange={handleApikeyChange}
                current={current}
                setCurrent={setCurrent}
              />
            </Form>

            <div className={styles.actions}>
              <Button onClick={handleSaveClick}>
                <Icon
                  loading={activating}
                  name={activating || changes ? "spinner" : "save"}
                />
                {activating || changes ? "Saving..." : "Save Changes"}
              </Button>
              <Button onClick={handleResetClick}>Reset</Button>
            </div>
          </Segment>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
}

function ProtectedPasswordField({
  value,
  onChange,
  current,
  setCurrent,
  ...rest
}) {
  const [show, setShow] = useState(false);

  const handleTogglePassword = () => {
    setShow(!show);
  };

  const handleChangeAPIKey = () => {
    setCurrent(false);
  };

  return (
    <>
      {!current && (
        <Form.Field>
          <Input
            labelPosition="right"
            type={show ? "text" : "password"}
            autoComplete="new-password"
            placeholder="Enter your API key"
            value={value}
            onChange={onChange}
          >
            <Label basic>API KEY</Label>
            <input />
            <Label basic onClick={handleTogglePassword}>
              <Icon name="eye" />
            </Label>
          </Input>
          <span className={styles.helpText}>
            To find your API key, Click your Profile picture and select Settings
            and click the API settings tab.
          </span>
        </Form.Field>
      )}

      {current && (
        <Form.Field>
          <Input
            labelPosition="right"
            type={"text"}
            autoComplete="new-password"
            placeholder="Enter your API key"
            value="******"
            disabled
          >
            <Label basic>API KEY</Label>
            <input />
            <Label
              basic
              onClick={handleChangeAPIKey}
              className={styles.apiKeyButton}
            >
              CHANGE API KEY
            </Label>
          </Input>
          <span className={styles.helpText}>
            To find your API key, Click your Profile picture and select Settings
            and click the API settings tab.
          </span>
        </Form.Field>
      )}
    </>
  );
}
