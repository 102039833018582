import React, { useState, Fragment, useRef, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateProperties, updatingWidgetContentSucceeded, updateWidget, showPlaceholderAtIndex, moveWidgetToIndex } from 'actions/widgetEditor';

import _ from 'lodash';
import cx from 'classnames';

import { useWidgetDrop } from 'services/dnd.service';

import { Editor } from '@tinymce/tinymce-react';
import { Widget, getStyles } from '../widget.jsx';
import { WidgetRegistry } from '../registry.jsx';
import { Property } from 'components/Properties';
import { QuickSettings, QuickSettingsProperty } from 'components/QuickSettings';
import Animation from 'components/Animation';

import styles from './heroBanner.module.scss';
import { ColumnResizer } from '../resizable.jsx';
import { defaultFonts } from 'constants/fonts.jsx';

export const Text = React.memo((props) => {
  const dispatch = useDispatch();

  const ref = useRef(null);
  const dropRef = useWidgetDrop(props, ref, {

  });

  var widgets = props.widgets || [];
  const properties = props.properties;

  const handleEditorChange = useCallback((content, editor) => {
    dispatch(updatingWidgetContentSucceeded(props.id, content, props.editor));
  }, []);

  const anchor = _.get(props, 'properties.anchor.name');
  const widgetStyles = getStyles(props.properties);

  return (
    <div className={cx(styles.text, props.className)}
      id={`widget_${props.id}`}
      ref={props.dragRef(dropRef(ref))}
      onMouseOver={props.onMouseOver}
      onMouseOut={props.onMouseOut}
      onClick={props.onClick}
      style={widgetStyles}
      data-anchor={anchor}
    >
      {props.editable &&
        <TextEditor id={props.id} value={props.content} onChange={handleEditorChange} />
      }

      {!props.editable &&
        <>
        <Animation {...(properties.animation || {}) }>
          <div dangerouslySetInnerHTML={{ __html: props.content }} />
        </Animation>
        </>
      }

      <Widget.Children widgets={props.widgets} parent={props.id} editor={props.editor} editable={props.editable} />
      {props.children}

      {props.selected &&
        <ColumnResizer item={props} editor={props.editor} />
      }
    </div>
  );
})

const TextEditor = React.memo(function TextEditor({ id, value, onChange }) {
  const editorRef = React.useRef(null);

  const selection_toolbar = 'bold italic | alignleft aligncenter alignright alignjustify | formatselect | numlist bullist | fontselect | fontsizeselect | forecolor backcolor | link | code';

  const customFonts = useSelector(state => _.get(state, 'storyboards.storyboard.item.branding.fonts') || []);
  const customfontStyle = useSelector(state => _.get(state, 'storyboards.storyboard.item.branding.customStyleSheet') || '');

  const fonts = () => {
    return [...customFonts, ...defaultFonts].map(font => {
      return `${font.name}=${font.fontFamily}`
    }).join(';');
  };

  return (
    <Editor
      key={id}
      initialValue={value}
      inline={true}
      init={{
        plugins: ['quickbars', 'autoresize', 'code', 'paste', 'link', 'lists advlist'],
        menubar: false,
        toolbar: false,
        quickbars_insert_toolbar: false,
        quickbars_selection_toolbar: selection_toolbar,
        browser_spellcheck: true,
        autoresize_bottom_margin: 50,
        autoresize_overflow_padding: 50,
        min_height: 100,
        valid_elements: "*[*]",
        paste_as_text: true,
        font_formats: fonts(),
        content_css: [
          customfontStyle
        ],
        setup: (editor) => {
          editorRef.current = editor;

          editor.on('drop', e => {
            console.log(e);
            e.preventDefault();
          });

        }
      }}
      onEditorChange={onChange}
    />
  );
});

function TextQuickSettings(props) {
  const dispatch = useDispatch();

  const [selected, setSelected] = useState('spacing');

  const menuItems = [
    { id: 'spacing', title: 'Spacing', icon: 'icon-spacing' },
    { id: 'background', title: 'Background', icon: 'icon-background' },
    { id: 'border', title: 'Border', icon: 'icon-border' },
    { id: 'anchor', title: 'Anchor', icon: 'icon-custom' },
    { id: 'animation', title: 'Animation', icon: 'icon-custom' },
    { id: 'advanced', title: 'Advanced', icon: 'icon-custom' }
  ]

  const handleMenuClick = (item) => {
    setSelected(item);
  }

  var widget = useSelector(state => {
    const editorContext = state.widgetsEditor.editors[props.editor];

    if (editorContext) {
      return editorContext.widgetsById[props.id];
    }

    return null;
  });

  if (!widget) {
    return false;
  }

  var properties = widget.properties;

  const handleChange = (changeRequest) => {
    dispatch(updateProperties({
      id: widget.id,
      change: changeRequest,
      context: props.editor
    }));
  }

  const handleBackgroundImageChange = (media, type) => {
    var changeRequest = { ...widget };
    _.set(changeRequest, 'properties.background.upload', {
      type: type,
      data: media
    });

    changeRequest = WidgetRegistry.processRequest([changeRequest])[0];
    dispatch(updateWidget(props.id, changeRequest, props.editor));
  }

  const handleCssChange = (css) => {
    var changeRequest = { ...widget };
    _.set(changeRequest, 'properties.css', css);

    changeRequest = WidgetRegistry.processRequest([changeRequest])[0];
    dispatch(updateWidget(props.id, changeRequest, props.editor));
  }

  return (
    <QuickSettings menuItems={menuItems} selected={selected} onMenuClick={handleMenuClick} >
      {selected === 'spacing' &&
        <>
        <QuickSettingsProperty.Spacing
          title='Margin'
          value={properties.margin}
          onChange={(margin) => {
            handleChange({
              margin: margin
            });
          }}
        />
        <QuickSettingsProperty.Spacing
          title='Padding'
          value={properties.padding}
          onChange={(padding) => {
            handleChange({
              padding: padding
            });
          }}
        />
        </>
      }

      {selected === 'background' &&
        <QuickSettingsProperty.Color
          title='Color'
          value={_.get(properties, 'background.color')}
          onChange={(value) => {
            handleChange({
              background: {
                color: value
              }
            })
          }}
        />
      }

      {selected === 'border' &&
        <QuickSettingsProperty.Border expanded value={properties.border} onChange={(border) => {
          handleChange({
            border: border
          });
        }}
        />
      }

      {selected === 'layout' &&
        <Property.NumericInput
          tooltip='Number of columns'
          title='Width'
          value={properties.width}
          min={1} max={16}
          onChange={(value) => {
            handleChange({
              width: value
            })
          }}
        />
      }

      {selected === 'animation' &&
        <QuickSettingsProperty.Animation
          title='Animation'
          value={properties.animation}
          onChange={(animation) => {
            handleChange({
              animation: animation
            });
          }}
        />
      }

      {selected === 'advanced' &&
        <QuickSettingsProperty.CSS title='Stylesheets' value={properties.css} onChange={handleCssChange} />
      }

      {selected === 'anchor' &&
        <QuickSettingsProperty.Anchor title='Anchor' value={_.get(widget, 'properties.anchor.name')} onChange={(anchor) => {
          handleChange({
            anchor: {
              name: anchor 
            }
          })
        }} />
      }
    </QuickSettings>
  )
}

Text.QuickSettings = TextQuickSettings;
