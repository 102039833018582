import {
  FETCHING_INTEGRATIONS_REQUEST, FETCHING_INTEGRATIONS_SUCCEDDED, FETCHING_INTEGRATIONS_FAILED, INVALIDATE_INTEGRATIONS,
  DEACTIVATING_INTEGRATION_REQUEST, DEACTIVATING_INTEGRATION_SUCCEDDED, DEACTIVATING_INTEGRATION_FAILED,
  ACTIVATING_INTEGRATION_SUCCEDDED, INTEGRATION_AUTH_EXPIRED,
} from 'constants/actionTypes';

import axios from 'axios';
import { createAction } from 'redux-actions';
import buildUrl from 'build-url';
import { call, put, takeEvery } from 'redux-saga/effects';

const apiUrl = `${process.env.REACT_APP_API_URL}/api/2.0`;
const RESOURCE_CENTER_BASE_URL = `${apiUrl}/resource_center`;
const INTEGRATION_BASE_URL = `${apiUrl}/integrations`;

/*
* Fetch Integrations
*/
export const fetchIntegrations = createAction(FETCHING_INTEGRATIONS_REQUEST);

const fetchIntegrationsSucceeded = createAction(FETCHING_INTEGRATIONS_SUCCEDDED, (response) => {
  return response;
});

const fetchIntegrationsFailed = createAction(FETCHING_INTEGRATIONS_FAILED, (error) => {
  return error;
})

function* fetchIntegrationsSaga(action) {

  var url = buildUrl(RESOURCE_CENTER_BASE_URL, {
    path: 'integrations'
  })

  const fetchIntegrationsAPI = () => {
    return axios.get(url).then(response => response.data);
  }

  try {
    const response = yield call(fetchIntegrationsAPI);
    yield put(fetchIntegrationsSucceeded(response));
  } catch (error) {
    yield put(fetchIntegrationsFailed(error));
  }
}

/*
* Deactivate Integrartion
*/
export const deactivateIntegration = createAction(DEACTIVATING_INTEGRATION_REQUEST, (type, callback) => {
  return {
    type: type,
    callback: callback
  };
})

const deactivateIntegrationSucceeded = createAction(DEACTIVATING_INTEGRATION_SUCCEDDED, (response) => {
  return response;
})

const deactivateIntegrationFailed = createAction(DEACTIVATING_INTEGRATION_FAILED, (error) => {
  return error;
})

function* deactivateIntegrationSaga(action) {
  const {type, callback} = action.payload;
  
  var url = buildUrl(INTEGRATION_BASE_URL, {
    path: type
  });
  
  const deactivateIntegrationAPI = () => {
    return axios.delete(url).then(response => response.data);
  }

  try {
    const response = yield call(deactivateIntegrationAPI);
    yield put(deactivateIntegrationSucceeded(response));
    if(callback) {
      callback(response);
    }
  } catch (error) {
    yield put(deactivateIntegrationFailed(error));
  }
}

export const activatingIntegrationSuccedded = createAction(ACTIVATING_INTEGRATION_SUCCEDDED, (integration) => {
  return integration;
})

export const integrationAuthExpired = createAction(INTEGRATION_AUTH_EXPIRED, (state, type) => {
  return {
    state: state,
    type: type
  }
})

export default function* integrationSaga() {
  yield takeEvery(FETCHING_INTEGRATIONS_REQUEST, fetchIntegrationsSaga);
  yield takeEvery(DEACTIVATING_INTEGRATION_REQUEST, deactivateIntegrationSaga);
}