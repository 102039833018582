import React, { PropTypes } from 'react';

import Linkify from 'react-linkify';

import cx from 'classnames';
import styles from './../EnhancedDocumentViewer.module.scss';

class TextView extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      textScale: 1
    }
  }

  componentDidMount(){
    var width = this.props.item.r.w;
    if(this.element){
      width = this.element.offsetWidth;
    }

    var scale = this.props.item.r.w / width; 

    this.setState({
      textScale: scale
    })
  }

  render() {
    var textStyle = {
      top: this.props.item.r.y,
      left: this.props.item.r.x,
      fontSize: `${this.props.item.l}px`,
      lineHeight: `${this.props.item.r.h}px`,
      transformOrigin: `center left`,
      transform: `rotate(${-this.props.item.a}rad) scale(${this.state.textScale}, 1)`
    };

    this.updateFontFamily(textStyle);

    var linkProperties = {
      target: '_blank',
      rel: 'nofollow'
    };

    return (
      <p className={styles.text} style={textStyle} ref={element => this.element = element}>
        <span>
          <Linkify className={styles.link} properties={linkProperties}>
            {this.props.item.t}
          </Linkify>
        </span>
      </p>
    );
  }

  updateFontFamily(textStyle){
    if(!this.props.font){
      return null;
    }

    if(this.props.font.f){
      textStyle['fontFamily'] = `${this.props.font.f}${this.props.font.s ? ', serif' : ', san-serif'}`;
    }

    textStyle['fontWeight'] = this.props.font.b ? 'bold' : 'normal';
    textStyle['fontStyle'] = this.props.font.i ? 'italics' : 'normal';
  }
}

export default TextView;
