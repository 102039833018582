import React, {useState, Fragment} from 'react';

import _ from 'lodash';
import cx from 'classnames';
import styles from './banner.module.scss';

import BrowserService from 'services/browser.service';
import { getBorderStyles } from 'components/Widgets/widget';

export function Banner({ properties }){
  const isSafari = BrowserService.getCurrentBrowser() === "safari";

  const media = _.get(properties, 'background.media');
  const color = _.get(properties, 'background.color');
  const overlay = _.get(properties, 'background.overlay');
  const position = _.get(properties, 'background.position', 'center center');

  const type = _.get(media, 'type', 'image');
  const mediaUrl = _.get(media, 'full');

  const backgroundStyles = getBackgroundStyles(media, color, position) || {};
  const overlayStyles = getBackgroundStyles(null, overlay);
  const borderStyles = getBorderStyles(properties.border) || {};

  const widgetStyles = {
    ...(type === "image") ? backgroundStyles : {},
    ...borderStyles
  }

  return (
    <div className={styles.banner} style={widgetStyles}>
      {overlay && 
        <div className={cx(styles.overlay)} style={overlayStyles}></div>
      }

      {type === 'video' && 
        <div className={styles.videoContainer}>
          <video autoPlay disablePictureInPicture muted loop playsinline={isSafari} src={!isSafari && mediaUrl}
          style={{
            objectPosition: position
          }}>
              {isSafari && 
              <source src={mediaUrl} type="video/mp4"/>}
          </video>
        </div>
      }
    </div>
  );
}

function getBackgroundStyles(media, color, position){
  if(!media && !color){
    return null;
  }

  const mediaUrl = _.get(media, 'full');

  var colorValue = 'inherit';
  if(color){
    colorValue = `rgba(${color.r}, ${color.g}, ${color.b}, ${color.a})`;
  }

  var styles = {
    backgroundColor: colorValue
  };

  if(mediaUrl){
    styles['backgroundImage'] = `url('${mediaUrl}')`;
    styles['backgroundPosition'] = position;
    styles['backgroundSize'] = 'cover';
    styles['backgroundRepeat'] = 'no-repeat';
  }
  
  return styles;
}