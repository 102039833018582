import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { Grid, Menu, Button, Icon, Segment, Image, Input, Label, Divider } from 'semantic-ui-react';

import _ from 'lodash';
import Password from './password';
import styles from './Profile.module.scss';

import { updateProfile } from 'actions/users';
import ImageTools from 'services/imagetools.service';

export default function ProfileView() {

  const dispatch = useDispatch();

  const me = useSelector(state => _.get(state, 'users.me'));
  const updating = useSelector(state => _.get(state, 'users.updating'));

  const [firstName, setFirstName] = useState(me ? me.firstName : '');
  const [lastName, setLastName] = useState(me ? me.lastName : '');
  const [title, setTitle] = useState(me ? me.title : '');
  const [email, setEmail] = useState(me ? me.title : '');
  const [workPhone, setWorkPhone] = useState(me ? me.workPhone : '');
  const [mobilePhone, setMobilePhone] = useState(me ? me.mobilePhone : '');
  const [error, setError] = useState(null);
  const [icon, setIcon] = useState(null);

  useEffect(() => {
    setFirstName(me ? me.firstName : '');
    setLastName(me ? me.lastName : '');
    setTitle(me ? me.title : '');
    setEmail(me ? me.email : '');
    setWorkPhone(me ? me.workPhone : '');
    setMobilePhone(me ? me.mobilePhone : '');
  }, [me]);


  const uploadIconRef = React.createRef();

  const handleFirstNameChange = (e) => {
    setFirstName(e.target.value);
  }

  const handleUploadIcon = (e) => {
    ImageTools.resize(e.target.files[0], {
      width: 1024, // maximum width
      height: 1024 // maximum height
    }, (blob, didItResize) => {
      var reader = new FileReader();
      reader.onload = (e) => {
        setIcon(e.target.result);
      };
      reader.readAsDataURL(blob);
    });
  }

  const handleSave = () => {
    var request = {
      firstName: firstName,
      lastName: lastName,
      title: title,
      email: email,
      workPhone: workPhone,
      mobilePhone: mobilePhone,
      icon: icon
    }

    if (firstName == null || firstName === '') {
      setError("Enter a valid first name")
    } else {
      setError(null)
      dispatch(updateProfile(request))
    }
  }

  const handleCancel = () => {
    setFirstName(me ? me.firstName : '');
    setLastName(me ? me.lastName : '');
    setTitle(me ? me.title : '');
    setEmail(me ? me.email : '');
    setWorkPhone(me ? me.workPhone : '');
    setMobilePhone(me ? me.mobilePhone : '');
    setError(null);
  }

  const renderIcon = () => {
    if (icon) {
      return icon;
    }

    return _.get(me, 'icon.thumbnail', null);
  }

  if (!me) {
    return null;
  }

  return (
    <Grid className={styles.profilePage}>
      <Grid.Row className={styles.profileTitle}>
        <Grid.Column width={1}></Grid.Column>
        <Grid.Column width={15}>
          <h1>Profile</h1>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row className={styles.profileView}>
        <Grid.Column width={1}></Grid.Column>
        <Grid.Column width={4} className={styles.profilePicture}>
          <span className={styles.heading}>Add a profile picture</span>
          <span className={styles.subHeading}>Preferably with a hat and a huge smile!</span>
          <Image
            className={styles.icon}
            src={renderIcon()} />
          <Divider fitted/>
          <Label className={styles.editText}><Icon name='upload' />Upload Profile Picture</Label>
          <input
            className={styles.fileUpload}
            ref={uploadIconRef}
            type="file"
            onChange={handleUploadIcon} />
        </Grid.Column>
        <Grid.Column width={10}>
          <Segment className={styles.userDetails}>
            <span className={styles.heading}>Add details to prove humanity</span>
            <span className={styles.subHeading}>Kidding. Simply fill up these fields so people know who you are!</span>
            <div className={styles.inputFields}>
              <Input
                placeholder="First Name"
                value={firstName}
                onChange={handleFirstNameChange}
                className={styles.firstName}
              ></Input>
              <Input
                placeholder="Last Name"
                value={lastName}
                onChange={(e) => {
                  setLastName(e.target.value);
                }}
                className={styles.lastName}
              ></Input><br />
              {error &&
                <span className={styles.error}>{error}<br /></span>
              }
              <Input
                placeholder="Title"
                value={title}
                className={styles.title}
                onChange={(e) => {
                  setTitle(e.target.value);
                }}
              ></Input>
              <Input
                placeholder="Email"
                value={email}
                className={styles.email}
                readOnly={true}
              ></Input><br />
              <Input
                placeholder="Work Phone"
                value={workPhone}
                className={styles.workPhone}
                onChange={(e) => {
                  setWorkPhone(e.target.value);
                }}
              ></Input>
              <Input
                placeholder="Mobile Number"
                value={mobilePhone}
                className={styles.mobilePhone}
                onChange={(e) => {
                  setMobilePhone(e.target.value);
                }}
              ></Input>
            </div>
            <Menu
              secondary
              size='large'
              borderless
              className={styles.actionMenu}
            >
              <Menu.Menu position='right'>
                <Menu.Item>
                  <Button size='mini' className={styles.saveButton} onClick={handleSave} disabled={updating}>
                    <Icon name='save' />
                    {updating ? 'Saving...' : 'Save Changes'}
                  </Button>
                </Menu.Item>
                <Menu.Item>
                  <Button onClick={handleCancel} className={styles.cancelButton} size='mini'>Cancel</Button>
                </Menu.Item>
              </Menu.Menu>
            </Menu>
          </Segment>
        </Grid.Column>
        <Grid.Column width={1}></Grid.Column>
      </Grid.Row>
      <Password />
    </Grid>
  )
}