import React, { useState } from 'react';
import { Switch, Route, Link, NavLink, useLocation, useRouteMatch, } from "react-router-dom";

import { VisitorReports } from './visitors';
import { LeadReports } from './leads';
import { ContentReports } from './content';

import styles from './reports.module.scss';

import {
  Menu
} from 'semantic-ui-react';

function ReportsPage(props) {
  const location = useLocation();
  let { path, url } = useRouteMatch();
  
  const paramsString = location.search;
  const params = new URLSearchParams(paramsString);
  var type = params.get('dateRange');
  var startDate = params.get('startDate');
  var endDate = params.get('endDate');

  return(
    <div className={styles.reports}>
      <h1 className={styles.header}>Reports</h1>
      <Menu secondary pointing borderless>
        <Menu.Item
          name='Visitors'
          as={NavLink} 
          to={`${url}/visitors?dateRange=${type}&startDate=${startDate}&endDate=${endDate}`}
        />
        <Menu.Item
          name='Leads'
          as={NavLink} 
          to={`${url}/leads?dateRange=${type}&startDate=${startDate}&endDate=${endDate}`}
        />
        <Menu.Item
          name='Content'
          as={NavLink} 
          to={`${url}/content?dateRange=${type}&startDate=${startDate}&endDate=${endDate}`}
        />
      </Menu>

      <Switch>
        <Route path={`${path}/visitors`} component={VisitorReports}></Route>
        <Route path={`${path}/leads`} component={LeadReports}></Route>
        <Route path={`${path}/content`} component={ContentReports}></Route>
      </Switch>
    </div>
  );
}

export default ReportsPage;