import {
  CREATE_EDITOR_CONTEXT, UPDATE_EDITOR_CONTEXT,

  CREATE_WIDGET_REQUEST, CREATING_WIDGET_SUCCEEDED, CREATING_WIDGET_FAILED,
  UPDATE_WIDGET_REQUEST, UPDATING_WIDGET_SUCCEEDED, UPDATING_WIDGET_FAILED,
  CLONE_WIDGET_REQUEST, CLONING_WIDGET_SUCCEEDED, CLONING_WIDGET_FAILED,

  FETCH_WIDGET_CONTENT_REQUEST, FETCHING_WIDGET_CONTENT_SUCCEEDED, FETCHING_WIDGET_CONTENT_FAILED,
  UPDATE_WIDGET_CONTENT_REQUEST, UPDATING_WIDGET_CONTENT_SUCCEEDED, UPDATING_WIDGET_CONTENT_FAILED,

  FETCH_WIDGET_ASSETS_REQUEST, FETCHING_WIDGET_ASSETS_SUCCEEDED, FETCHING_WIDGET_ASSETS_FAILED,

  UPDATE_FILTERS_IN_WIDGET_ASSETS, UPDATE_SEARCH_QUERY_IN_WIDGET_ASSETS,

  INSERT_WIDGETS_AT_INDEX, MOVE_WIDGET_TO_INDEX, SHOW_WIDGET_PLACEHOLDER, INVALIDATE_WIDGET_PLACEHOLDER,
  RESIZING_WIDGET, INVALIDATE_RESIZING_WIDGET, REARRANGE_WIDGETS, CREATE_WIDGETS_FROM_PALETTE,

  WIDGET_DROP_REQUEST, PALETTE_DROP_REQUEST,

  DELETE_WIDGET, SELECT_WIDGET, DESELECT_WIDGET,
  UPDATE_WIDGET_NAME, UPDATE_WIDGET_PROPERTIES,

  FETCH_PALETTE_GROUPS_REQUEST, FETCHING_PALETTE_GROUPS_SUCCEEDED, FETCHING_PALETTE_GROUPS_FAILED,

  MERGE_WIDGET_EDITORS,

  UPDATE_ASSETS_FOR_WIDGET, UPDATE_ASSETS_FOR_WIDGET_SUCCEEDED, UPDATE_ASSETS_FOR_WIDGET_FAILED,

  ADD_PLACEHOLDER_ASSETS_FOR_WIDGET, ADD_PLACEHOLDER_ASSETS_FOR_WIDGET_SUCCEEDED, ADD_PLACEHOLDER_ASSETS_FOR_WIDGET_FAILED,

  FETCH_WIDGET_ASSET_REQUEST, FETCHING_WIDGET_ASSET_SUCCEEDED, FETCHING_WIDGET_ASSET_FAILED,

  UPDATE_ALIAS_FOR_SECTION, UPDATE_ALIAS_FOR_SECTION_SUCCEEDED, UPDATE_ALIAS_FOR_SECTION_FAILED
} from 'constants/actionTypes.jsx';

import _ from 'lodash';
import axios from 'axios';
import buildUrl from 'build-url';
import { createAction } from 'redux-actions';
import { ActionCreators } from 'redux-undo';
import { call, put, takeEvery, takeLatest, select } from 'redux-saga/effects';
import { withCallback, putWait } from 'redux-saga-callback';
import { resolvePromiseAction } from '@adobe/redux-saga-promise';


import { ROW, COLUMN, HERO_BANNER, LIVE_ELEMENT, PASSWORD_PROTECTION } from 'constants/ItemTypes';
import { createRow, createColumn } from 'services/widget.helper';
import { REMOVE_WIDGET_FROM_PARENT } from 'constants/actionTypes';
import { wrapWithRow, wrapWithColumn, denormalise } from 'services/widget.helper';
import { WidgetRegistry } from 'components/Widgets/registry';
import { getClosestColumnForWidget } from 'services/widget.helper';

const apiUrl = process.env.REACT_APP_API_URL;
const PAGE_URL = `${apiUrl}/api/2.0/pages`;
const WIDGET_URL = `${apiUrl}/api/2.0/widgets`;
const CONTENT_BASE_URL = `${apiUrl}/api/2.0/content`;
const PALETTE_URL = `${apiUrl}/api/2.0/widgets/palette`;

export const undo = ActionCreators.undo();
export const redo = ActionCreators.redo();

export const createEditorContext = createAction(CREATE_EDITOR_CONTEXT, (context, widgets) => {
  return {
    context: context,
    widgets: widgets
  };
});

export const updateEditorContext = createAction(UPDATE_EDITOR_CONTEXT, (context, widgets) => {
  return {
    context: context,
    widgets: widgets
  };
});

export const insertWidgetsAtIndex = createAction(INSERT_WIDGETS_AT_INDEX, (context, widgets, parent, index, insertBefore) => {
  return {
    widgets: widgets,
    parent: parent,
    index: index,
    context: context,
    insertBefore: insertBefore
  };
});

export const showPlaceholderAtIndex = createAction(SHOW_WIDGET_PLACEHOLDER, (context, parent, index, data) => {
  return {
    context: context,
    parent: parent,
    index: index,
    data: data
  };
});

export const invalidateWidgetPlaceholder = createAction(INVALIDATE_WIDGET_PLACEHOLDER, (context) => {
  return {
    context: context
  };
});

export const moveWidgetToIndex = createAction(MOVE_WIDGET_TO_INDEX, (context, widget, parent, index, insertBefore) => {
  return {
    widget: widget,
    parent: parent,
    index: index,
    context: context,
    insertBefore: insertBefore
  };
});

export const resizeWidget = createAction(RESIZING_WIDGET, (context, widget, dimension) => {
  return {
    context: context,
    widget: widget,
    dimension: dimension
  }
})

export const invalidateWidgetResize = createAction(INVALIDATE_RESIZING_WIDGET, (context) => {
  return {
    context: context
  }
})

export const deleteWidget = createAction(DELETE_WIDGET, (id, context) => {
  return {
    id: id,
    context: context
  }
});

export const selectWidget = createAction(SELECT_WIDGET, (context, widget) => {
  return {
    context: context,
    widget: widget
  }
});

export const deselectWidget = createAction(DESELECT_WIDGET, (context) => {
  return {
    context: context
  }
});

export const updateProperties = createAction(UPDATE_WIDGET_PROPERTIES, (request) => {
  return request;
});

export const updateWidgetName = createAction(UPDATE_WIDGET_NAME, (id, name, context) => {
  return {
    id,
    name,
    context
  }
});

/**
 * create widget
*/
export const createWidget = createAction(CREATE_WIDGET_REQUEST, (context, widgets, callback) => {
  return {
    context: context,
    widgets: widgets,
    callback: callback
  };
});

const creatingWidgetSucceeded = createAction(CREATING_WIDGET_SUCCEEDED, (context, response) => {
  return {
    context: context,
    widgets: response
  };
});

const creatingWidgetFailed = createAction(CREATING_WIDGET_FAILED);

function* createWidgetSaga(action) {
  const { context, widgets, callback } = action.payload;

  const createWidgetAPI = () => {
    return axios({
      method: 'POST',
      url: WIDGET_URL,
      data: widgets
    }).then(response => response.data);
  }

  try {
    const response = yield call(createWidgetAPI);
    yield put(creatingWidgetSucceeded(context, response));

    if (callback) {
      callback(response);
    }

    return response;

  } catch (error) {
    console.log(error);
    yield put(creatingWidgetFailed(error, context));
  }
}

/**
 * Clone widget
*/
export const cloneWidget = createAction(CLONE_WIDGET_REQUEST, (editor, widget, entityReference, index) => {
  return {
    editor: editor,
    widget: widget,
    entityReference: entityReference,
    index: index,
  };
});


const cloningWidgetSucceeded = createAction(CLONING_WIDGET_SUCCEEDED, (context, response) => {
  return {
    context: context,
    widgets: response
  };
});

const cloningWidgetFailed = createAction(CLONING_WIDGET_FAILED);

function* cloneWidgetSaga(action) {
  const { editor, widget, entityReference, index } = action.payload;

  const cloneWidgetAPI = () => {
    return axios({
      method: 'POST',
      url: `${WIDGET_URL}/${widget.id}`,
    }).then(response => response.data);
  }

  try {
    const editorContext = yield select(state => _.get(state, `widgetsEditor.editors.${editor}`));
    var changeRequest = denormalise([widget.id], editorContext.widgetsById)[0];
    changeRequest = WidgetRegistry.processRequest([changeRequest])[0];

    yield putWait(updateWidget(widget.id, changeRequest, editor));
    const widgetResponse = yield call(cloneWidgetAPI);
    yield put(cloningWidgetSucceeded(editor, [widgetResponse]));
    yield put(moveWidgetToIndex(editor, widgetResponse.id, widget.parent, index, false));

    return widgetResponse;

  } catch (error) {
    console.log(error);
    yield put(cloningWidgetFailed(error, editor));
  }
}

/**
 * update widget
*/
export const updateWidget = createAction(UPDATE_WIDGET_REQUEST, (id, request, context) => {
  return {
    id: id,
    request: request,
    context: context
  };
});

const updatingWidgetSucceeded = createAction(UPDATING_WIDGET_SUCCEEDED, (response, context) => {
  return {
    response: response,
    context: context
  }
});

const updatingWidgetFailed = createAction(UPDATING_WIDGET_FAILED, (id, error, context) => {
  return {
    id: id,
    error: error,
    context: context
  }
});

function* updateWidgetSaga(action) {
  const { id, request, context } = action.payload;

  let url = buildUrl(WIDGET_URL, {
    path: `${id}/properties`
  });

  const updateWidgetAPI = () => {
    return axios({
      method: 'PUT',
      url: url,
      data: request
    }).then(response => response.data);
  }

  try {
    const response = yield call(updateWidgetAPI);
    yield put(updatingWidgetSucceeded(response, context));
  } catch (error) {
    yield put(updatingWidgetFailed(id, error, context));
  }
}


/**
 * fetch widget content
*/
export const fetchWidgetContent = createAction(FETCH_WIDGET_CONTENT_REQUEST, (id, context) => {
  return {
    id: id,
    context: context
  }
});

const fetchingWidgetContentSucceeded = createAction(FETCHING_WIDGET_CONTENT_SUCCEEDED, (id, response, context) => {
  return {
    id: id,
    response: response,
    context: context
  }
});

const fetchingWidgetContentFailed = createAction(FETCHING_WIDGET_CONTENT_FAILED, (id, error, context) => {
  return {
    id: id,
    error: error,
    context: context
  }
});

function* fetchWidgetContentSaga(action) {
  const { id, context } = action.payload;

  let url = buildUrl(WIDGET_URL, {
    path: `${id}/content`
  });

  const fetchWidgetContentAPI = () => {
    return axios.get(url).then(response => response.data);
  }

  try {
    const response = yield call(fetchWidgetContentAPI);
    yield put(fetchingWidgetContentSucceeded(id, response, context));
  } catch (error) {
    console.log(error);
    yield put(fetchingWidgetContentFailed(id, error, context));
  }
}

/**
 * update widget content
*/
export const updateWidgetContent = createAction(UPDATE_WIDGET_CONTENT_REQUEST, (id, request, context, callback) => {
  return {
    id: id,
    request: request,
    context: context,
    callback: callback
  };
});

export const updatingWidgetContentSucceeded = createAction(UPDATING_WIDGET_CONTENT_SUCCEEDED, (id, content, context) => {
  console.log('action ' + content);
  return {
    id: id,
    content: content,
    context: context
  };
});

const updatingWidgetContentFailed = createAction(UPDATING_WIDGET_CONTENT_FAILED, (id, error, context) => {
  return {
    id: id,
    error: error,
    context: context
  };
});

function* updateWidgetContentSaga(action) {
  const id = action.payload.id;
  const request = action.payload.request;
  const callback = action.payload.callback;
  const context = action.payload.context;

  let url = buildUrl(WIDGET_URL, {
    path: `${id}/content`
  });

  const updateWidgetContentAPI = () => {
    return axios({
      method: 'PUT',
      url: url,
      data: request
    }).then(response => response.data);
  }

  try {
    const response = yield call(updateWidgetContentAPI);
    yield put(updatingWidgetContentSucceeded(id, response.content, context));
    if (callback) {
      callback(response);
    }
  } catch (error) {
    yield put(updatingWidgetContentFailed(error));
  }
}

/**
 * fetch assets for widgets
*/
export const fetchAssetsForWidget = createAction(FETCH_WIDGET_ASSETS_REQUEST, (id, page, context) => {
  return {
    id: id,
    page: page,
    context: context
  }
});

const fetchingAssetsForWidgetSucceeded = createAction(FETCHING_WIDGET_ASSETS_SUCCEEDED, (id, response, context) => {
  return {
    id: id,
    response: response,
    context: context
  }
});
const fetchingAssetsForWidgetFailed = createAction(FETCHING_WIDGET_ASSETS_FAILED, (id, error, context) => {
  return {
    id: id,
    error: error,
    context: context
  }
});

function* fetchAssetsForWidgetSaga(action) {
  const { id, page, context } = action.payload;

  var params = {
    page: page
  }

  let url = buildUrl(WIDGET_URL, {
    path: `${id}/assets`,
    queryParams: params
  });

  const filters = yield select(state => _.get(state, `widgetsEditor.editors[${context}].widgetsById[${id}].selectedFilters`));
  const query = yield select(state => _.get(state, `widgetsEditor.editors[${context}].widgetsById[${id}].searchQuery`));

  var assetsRequest = {
    filters: filters,
    query : query
  }

  const fetchAssetsForWidgetAPI = () => {
    return axios.post(url, assetsRequest).then(response => response.data);
  }

  try {
    const response = yield call(fetchAssetsForWidgetAPI);
    yield put(fetchingAssetsForWidgetSucceeded(id, response, context));
  } catch (error) {
    console.log(error);
    yield put(fetchingAssetsForWidgetFailed(id, error, context));
  }
}


/*
* Fetch asset by id
*/
export const fetchWidgetAsset = createAction(FETCH_WIDGET_ASSET_REQUEST, (id, contextId, callback) => {
  return {
    id: id,
    contextId: contextId,
    callback: callback
  };
});

export const fetchingWidgetAssetSucceeded = createAction(FETCHING_WIDGET_ASSET_SUCCEEDED, (contextId, asset) => {
  return {
    contextId: contextId,
    asset: asset
  };
})

export const fetchingWidgetAssetFailed = createAction(FETCHING_WIDGET_ASSET_FAILED, (contextId, error) => {
  return {
    contextId: contextId,
    error: error
  };
})

function* fetchWidgetAssetSaga(action) {
  var { id, contextId, callback } = action.payload;

  let url = buildUrl(CONTENT_BASE_URL, {
    path: id
  });

  const fetchAssetAPI = () => {
    return axios.get(url).then(response => response.data);
  }

  try {
    const response = yield call(fetchAssetAPI);
    if (response) {
      yield put(fetchingWidgetAssetSucceeded(contextId, response));
      callback(response);
    }
  } catch (error) {
    console.log(error);
    yield put(fetchingWidgetAssetFailed(error));
  }
}


/**
 * Updating filters on change
*/
export const updateFiltersInWidgetAssets = createAction(UPDATE_FILTERS_IN_WIDGET_ASSETS, (id, editor, filters) => {
  return { id, editor, filters };
});

/**
 * Search on change
*/
export const updateSearchQueryInWidgetAssets = createAction(UPDATE_SEARCH_QUERY_IN_WIDGET_ASSETS, (id, editor, searchQuery) => {
  return { id, editor, searchQuery };
});

/**
 * fetch palette editor
*/
export const fetchPaletteGroups = createAction(FETCH_PALETTE_GROUPS_REQUEST);
const fetchingPaletteGroupsSucceeded = createAction(FETCHING_PALETTE_GROUPS_SUCCEEDED);
const fetchingPaletteGroupsFailed = createAction(FETCHING_PALETTE_GROUPS_FAILED);

function* fetchPaletteGroupsSaga(action) {
  const fetchPaletteGroupsAPI = () => {
    return axios.get(PALETTE_URL).then(response => response.data);
  }

  try {
    const response = yield call(fetchPaletteGroupsAPI);
    yield put(fetchingPaletteGroupsSucceeded(response));
  } catch (error) {
    console.log(error);
    yield put(fetchingPaletteGroupsFailed(error));
  }
}

/**
 * Process drop request on editor
 * 
*/

export const processWidgetDropRequest = createAction(WIDGET_DROP_REQUEST, (editor, entityReference, widget) => {
  return {
    editor: editor,
    entityReference: entityReference,
    widget: widget,
  };
});

function* processWidgetDropRequestSaga(action) {
  const {
    editor, entityReference, widget
  } = action.payload;
  
  const placeholder = yield select(state => _.get(state, `widgetsEditor.editors[${editor}].placeholder`));
  console.log(placeholder);

  if(!placeholder){
    return;
  }

  const {parent, index, data} = placeholder;
  yield put(rearrangeWidgets(editor, entityReference, widget, parent, index, data.insertBefore));
}

/**
 * Process palette widget drop request on editor
*/
export const processPaletteDropRequest = createAction(PALETTE_DROP_REQUEST, (editor, entityReference, widget) => {
  return {
    editor: editor,
    entityReference: entityReference,
    widget: widget
  };
});

function* processPaletteDropRequestSaga(action) {
  const {
    editor, entityReference, widget
  } = action.payload;
  
  const placeholder = yield select(state => _.get(state, `widgetsEditor.editors[${editor}].placeholder`));
  console.log(placeholder);

  if(!placeholder){
    return;
  }

  const {parent, index, data} = placeholder;
  console.log(widget);
  yield put(createWidgetFromPalette(editor, entityReference, widget[0], parent, index, data.insertBefore));
}

/**
 * Rearrange widgets
*/
export const rearrangeWidgets = createAction(REARRANGE_WIDGETS, (editor, entityReference, widget, parent, index, insertBefore) => {
  return {
    editor: editor,
    entityReference: entityReference,
    widget: widget,
    parent: parent,
    index: index,
    insertBefore: insertBefore
  };
});

function* rearrangeWidgetsSaga(action) {
  const {
    editor, entityReference, widget, parent, index, insertBefore
  } = action.payload;

  const editorContext = yield select(state => {
    return state.widgetsEditor.editors[editor];
  })

  const getColumn = (widgets) => {
    if (widgets && widgets.length == 0) {
      return null;
    }

    const widget = widgets[0];

    if (widget.type === ROW) {
      return getColumn(widget.widgets);
    }

    if (widget.type === COLUMN) {
      return widget;
    }

    return null;
  }

  const parentWidget = editorContext.widgetsById[parent];

  const rootWidget = _.includes([ROW, HERO_BANNER, LIVE_ELEMENT], widget.type);
  const needsRow = parentWidget == null;
  const needsColumn = _.get(parentWidget, 'type') === ROW && widget.type != COLUMN;

  var newWidgets = null;
  var parentType = null;
  var targetParent = parent;
  var targetIndex = index;

  if (!rootWidget) {
    if (needsRow) {
      newWidgets = [createRow()];
      parentType = ROW;
    } else if (needsColumn) {
      var width = null;
      const previousParent = getClosestColumnForWidget(editorContext, widget.parent);
      if (previousParent) {
        width = _.get(previousParent, 'properties.width');
      }

      newWidgets = [createColumn(width)];
      parentType = COLUMN;
    }
  }

  if (newWidgets) {
    const request = {
      widgets: newWidgets,
      reference: entityReference
    };

    const widgetResponse = yield putWait(createWidget(editor, request));
    yield put(moveWidgetToIndex(editor, widgetResponse[0].id, parent, index, insertBefore));

    const newParent = getColumn(widgetResponse);
    targetParent = newParent.id;
    targetIndex = 0;

    yield put(removeWidgetFromParent(editor, widget.parent, widget));
  }

  yield put(moveWidgetToIndex(editor, widget.id, targetParent, targetIndex, insertBefore));
}

/**
 * Create widget from palette
*/
export const createWidgetFromPalette = createAction(CREATE_WIDGETS_FROM_PALETTE, (editor, entityReference, widget, parent, index, insertBefore) => {
  return {
    editor: editor,
    entityReference: entityReference,
    widget: widget,
    parent: parent,
    index: index,
    insertBefore: insertBefore
  };
});


function* createWidgetFromPaletteSaga(action) {
  var {
    editor, entityReference, widget, parent, index, insertBefore
  } = action.payload;

  const editorContext = yield select(state => {
    return state.widgetsEditor.editors[editor];
  })

  if (widget.type === PASSWORD_PROTECTION) {
    index = editorContext.widgets.length;
    insertBefore = false;
  }

  const getColumn = (widgets) => {
    if (widgets && widgets.length == 0) {
      return null;
    }

    const widget = widgets[0];

    if (widget.type === ROW) {
      return getColumn(widget.widgets);
    }

    if (widget.type === COLUMN) {
      return widget;
    }

    return null;
  }

  const parentWidget = editorContext.widgetsById[parent];

  const rootWidget = _.includes([ROW, HERO_BANNER, LIVE_ELEMENT, PASSWORD_PROTECTION], widget.type);
  const needsRow = parentWidget == null;
  const needsColumn = _.get(parentWidget, 'type') === ROW;

  var newWidgets = [widget];

  if (!rootWidget) {
    if (needsRow) {
      newWidgets = [WidgetRegistry.widgets[widget.type].wrapWithRow(widget)];
    } else if (needsColumn) {
      newWidgets = [WidgetRegistry.widgets[widget.type].wrapWithColumn(widget)];
    }
  }

  const request = {
    widgets: newWidgets,
    reference: entityReference
  };

  const widgetResponse = yield putWait(createWidget(editor, request));
  yield put(moveWidgetToIndex(editor, widgetResponse[0].id, parent, index, insertBefore));
}

export const removeWidgetFromParent = createAction(REMOVE_WIDGET_FROM_PARENT, (editor, parent, widget) => {
  return {
    editor: editor,
    widget: widget,
    parent: parent
  };
});

export const mergeWidgetEditors = createAction(MERGE_WIDGET_EDITORS, (source, destination) => {
  return {
    source: source,
    destination: destination
  }
});

/**
 * Add assets for widget
 */
export const addPlaceholderAssetsForWidget = createAction(ADD_PLACEHOLDER_ASSETS_FOR_WIDGET, (editor, widget, request, callback) => {
  return {
    editor: editor,
    widget: widget,
    request: request,
    callback: callback
  }
});

export const addPlaceholderAssetsForWidgetSucceeded = createAction(ADD_PLACEHOLDER_ASSETS_FOR_WIDGET_SUCCEEDED, (editor, widget, response) => {
  return {
    editor: editor,
    widget: widget,
    response: response
  }
})

export const addPlaceholderAssetsForWidgetFailed = createAction(ADD_PLACEHOLDER_ASSETS_FOR_WIDGET_FAILED, (editor, widget, error) => {
  return {
    editor: editor,
    widget: widget,
    error: error
  }
})
/**
 * Update assets for widget
*/
export const updateAssetsForWidget = createAction(UPDATE_ASSETS_FOR_WIDGET, (editor, widget, request, callback) => {
  return {
    editor: editor,
    widget: widget,
    request: request,
    callback: callback
  }
});

export const updateAssetsForWidgetSucceeded = createAction(UPDATE_ASSETS_FOR_WIDGET_SUCCEEDED, (editor, widget, response) => {
  return {
    editor: editor,
    widget: widget,
    response: response
  }
})

export const updateAssetsForWidgetFailed = createAction(UPDATE_ASSETS_FOR_WIDGET_FAILED, (editor, widget, error) => {
  return {
    editor: editor,
    widget: widget,
    error: error
  }
})

function* updateAssetsForWidgetSaga(action) {
  const { editor, widget, request, callback } = action.payload;

  let url = buildUrl(WIDGET_URL, {
    path: `${widget}/assets`
  });

  const updateAssetsForWidgetAPI = () => {
    return axios({
      method: 'PUT',
      url: url,
      data: request
    }).then(response => response.data);
  }

  try {
    const response = yield call(updateAssetsForWidgetAPI);
    yield put(updateAssetsForWidgetSucceeded(editor, widget, response));
    yield call(callback, response);
  } catch (error) {
    yield put(updateAssetsForWidgetFailed(editor, widget, error));
  }
}

/**
 * Update alias for section
*/
export const updateAliasForSection = createAction(UPDATE_ALIAS_FOR_SECTION, (widget, request, context, callback) => {
  return {
    widget: widget,
    request: request,
    context: context
  }
});

export const updateAliasForSectionSucceeded = createAction(UPDATE_ALIAS_FOR_SECTION_SUCCEEDED, (widget, context, response) => {
  return {
    widget: widget,
    context: context,
    response: response
  }
})

export const updateAliasForSectionFailed = createAction(UPDATE_ALIAS_FOR_SECTION_FAILED, (widget, context, error) => {
  return {
    widget: widget,
    context: context,
    error: error
  }
})

function* updateAliasForSectionSaga(action) {
  const { widget, request, context } = action.payload;

  let url = buildUrl(WIDGET_URL, {
    path: `${widget}/alias`
  });

  const updateAliasForSectionAPI = () => {
    return axios({
      method: 'POST',
      url: url,
      data: request
    }).then(response => response.data);
  }

  try {
    const response = yield call(updateAliasForSectionAPI);
    yield put(updateAliasForSectionSucceeded(widget, context, response));
  } catch (error) {
    yield put(updateAliasForSectionFailed(widget, context, error));
  }
}

function* addPlaceholderAssetsForWidgetSaga(action) {
  const { editor, widget, request, callback } = action.payload;

  let url = buildUrl(WIDGET_URL, {
    path: `${widget}/placeholder_assets`
  });

  const addPlaceholderAssetForWidgetAPI = () => {
    return axios({
      method: 'POST',
      url: url,
      data: request
    }).then(response => response.data);
  }

  try {
    const response = yield call(addPlaceholderAssetForWidgetAPI);
    yield put(addPlaceholderAssetsForWidgetSucceeded(editor, widget, response));
    yield call(callback, response);
  } catch (error) {
    yield put(addPlaceholderAssetsForWidgetFailed(editor, widget, error));
  }
}
export default function* widgetEditorSaga() {
  yield takeEvery(FETCH_WIDGET_CONTENT_REQUEST, fetchWidgetContentSaga);
  yield takeEvery(UPDATE_WIDGET_CONTENT_REQUEST, updateWidgetContentSaga);
  yield takeEvery(CREATE_WIDGET_REQUEST, withCallback(createWidgetSaga));
  yield takeEvery(CLONE_WIDGET_REQUEST, cloneWidgetSaga);
  yield takeEvery(UPDATE_WIDGET_REQUEST, withCallback(updateWidgetSaga));
  yield takeEvery(FETCH_WIDGET_ASSETS_REQUEST, fetchAssetsForWidgetSaga);
  yield takeLatest(FETCH_PALETTE_GROUPS_REQUEST, fetchPaletteGroupsSaga);

  yield takeEvery(PALETTE_DROP_REQUEST, processPaletteDropRequestSaga);
  yield takeEvery(WIDGET_DROP_REQUEST, processWidgetDropRequestSaga);
  yield takeEvery(REARRANGE_WIDGETS, rearrangeWidgetsSaga);
  yield takeEvery(CREATE_WIDGETS_FROM_PALETTE, createWidgetFromPaletteSaga);
  yield takeEvery(UPDATE_ASSETS_FOR_WIDGET, updateAssetsForWidgetSaga);
  yield takeEvery(ADD_PLACEHOLDER_ASSETS_FOR_WIDGET, addPlaceholderAssetsForWidgetSaga);
  yield takeEvery(FETCH_WIDGET_ASSET_REQUEST, fetchWidgetAssetSaga);
  yield takeEvery(UPDATE_ALIAS_FOR_SECTION, updateAliasForSectionSaga);
};