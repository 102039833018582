import React, { useState, useEffect} from 'react';
import cx from 'classnames';

import { 
  Dimmer,
  Menu,
  TransitionablePortal
} from 'semantic-ui-react';

import styles from './sidepane.module.scss';

export default function SidePane({open, right, className, children, closeOnDocumentClick, ...rest}){
  const [_open, setOpen] = useState(open);
  
  useEffect(()=> {
    setOpen(open);
  }, [open])

  const handleClick = () => {
    setOpen(!open);
  }

  const handleClose = () => {
    if(rest.onClose){
      rest.onClose();
    }
  }
  var componentStyle = {};
  if(rest.width){
    componentStyle.width = rest.width;
  }

  if(rest.background) {
    componentStyle.background = rest.background;
  }

  var animation = "fly right";
  if(right){
    animation = "fly left";
  }

  return (
    <>
      <Dimmer active={open} page></Dimmer>
      <TransitionablePortal open={_open} 
        transition={{animation: animation, duration: 300}}
        openOnTriggerClick 
        closeOnTriggerClick
        closeOnDocumentClick={closeOnDocumentClick}
        onClose={handleClose}>
        <div className={cx(styles.SlidePane, className, {[styles.right]: right})} style={componentStyle}>
          {rest.header && 
            <SidePaneHeader header={rest.header} close={rest.close} onClose={handleClose}/>
          }
          <div className={styles.body}>
            {children}
          </div>
        </div>
      </TransitionablePortal>
    </>
  )
}

function SidePaneHeader({header, close, onClose}){
  return (
    <Menu size='large' borderless className={styles.header}>
      <Menu.Item header>{header}</Menu.Item>
      {close && 
        <Menu.Menu position='right'>
          <Menu.Item icon="close" onClick={onClose}></Menu.Item>
        </Menu.Menu>
      }
    </Menu>
  );
}