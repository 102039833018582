import React from 'react';
import { useSelector } from 'react-redux';
import { connect } from 'react-redux';
import cx from 'classnames';
import _ from 'lodash';

import styles from './AssetViewer.module.scss';

import EnhancedDocumentViewer from './EnhancedDocumentViewer';
import VideoViewer from './VideoViewer';
import ImageViewer from './ImageViewer';
import PDFAssetViewer from './PDFViewer';
import HtmlViewer from './HtmlViewer';
import AudioViewer from './AudioViewer';

import { recordAnalyticsEvent } from 'actions/content';

import AuthService from 'services/auth.service';

import DefaultAssetIcon from 'images/default_asset.jpg';

class AssetView extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      
    }
  }

  render() {
    return (
      <div className={cx({ [styles.viewer]: !this.props.inline}, {[styles.inlineViewer]: this.props.inline})}>
        {this._renderViewer()}
      </div>
    )
  }

  _renderViewer() {
    switch (this.props.asset.viewer) {
      case 'enhanced_viewer': 
        return <EnhancedDocumentViewer asset={this.props.asset} contextId={this.props.contextId} inline={this.props.inline}/>
      case 'pdf_viewer':
        return <PDFAssetViewer asset={this.props.asset} contextId={this.props.contextId} inline={this.props.inline} />
      case  'video_viewer':
        return <VideoViewer asset={this.props.asset} contextId={this.props.contextId} inline={this.props.inline} />
      case 'image_viewer':
        return <ImageViewer asset={this.props.asset} contextId={this.props.contextId} inline={this.props.inline} />
      case 'html_viewer':
        return <HtmlViewer asset={this.props.asset} contextId={this.props.contextId} inline={this.props.inline} />
      case 'audio_viewer':
        return <AudioViewer asset={this.props.asset} contextId={this.props.contextId} inline={this.props.inline} />

      default: 
        return <DefaultViewer asset={this.props.asset} contextId={this.props.contextId} inline={this.props.inline} />;
    }
  }
}

function DefaultViewer({asset, inline, ...rest}) {
  const storyboard = useSelector(state => _.get(state, 'storyboards.storyboard.item'));

  var icon = asset.icon ? asset.icon.thumbnail : DefaultAssetIcon;
  return (
    <div className={cx(styles.defaultViewer, { [styles.defaultViewerInline]: inline })}>
      <div className={styles.icon}>
        <img src={icon} alt={asset.name}/>
      </div>
      <div className={styles.message}>Click here to download this file</div>
      <div className={styles.name}>{asset.name}</div>
      <a href={AuthService.getURL(`api/2.0/content/${asset.id}/download`, { 'PF-APPLICATION-ID': storyboard.id})} className={styles.downloadButton} target="_blank">Download</a>
    </div>
  )
}

const mapStateToProps = (state, ownProps) => {
  var sessionState = state.asset[ownProps.contextId];
  return {
    session: sessionState && sessionState.session.id
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    recordAnalyticsEvent: (event) => {
      return dispatch(recordAnalyticsEvent(event));
    }
  }
}

const AssetViewer = connect(
  mapStateToProps,
  mapDispatchToProps
)(AssetView)

export default AssetViewer;