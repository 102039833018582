import React, { PropTypes, Fragment, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import cx from 'classnames';
import {
  Menu,
  Grid,
  Button,
  Icon,
  Segment,
  Image,
  Header,
  Form,
  Input,
  Label,
  Checkbox
} from 'semantic-ui-react';

import {
  useRouteMatch,
} from "react-router-dom";

import _ from 'lodash';
import dateformat  from 'dateformat';

import styles from './marketo.module.scss';

import InlineConfirmationDialog from 'components/InlineConfirmationDialog';

import { getBaseUrl } from 'services/util';

import { authenticateMarketo } from 'actions/integrations/marketo';
import { deactivateIntegration, activatingIntegrationSuccedded } from 'actions/integrations/integrations';

export default function Marketo() {
  const dispatch = useDispatch();
  let { path, url } = useRouteMatch();
  let baseUrl = getBaseUrl(url);
  const history = useHistory();

  const integrations = useSelector(state => _.get(state, 'integrations.activated.items', []));
  const deactivating = useSelector(state => _.get(state, 'integrations.activated.deactivating', false));
  const errors = useSelector(state => _.get(state, 'integrations.marketo.changes.error', {}));
  const saving = useSelector(state => _.get(state, 'integrations.marketo.changes.saving', false));

  let integration = integrations.filter(item => {
    return item != null;
  }).find(item => {
    return item.type == 'marketo';
  });

  const [clientId, setClientId] = useState(_.get(integration, 'configuration.clientId', null));
  const [clientSecret, setClientSecret] = useState(_.get(integration, 'configuration.clientSecret', null));
  const [munchkinId, setMunchkinId] = useState(null);
  const [syncPageViewedEvent, setSyncPageViewedEvent] = useState(true);
  const [syncReadAssetEvent, setSyncReadAssetEvent] = useState(true);
  const [syncAssetDownloadEvent, setSyncAssetDownloadEvent] = useState(true);
  const [error, setError] = useState('');

  useEffect (() => {
    if(integration != null) {
      setMunchkinId(_.get(integration, 'configuration.munchkinId'));
      setSyncPageViewedEvent(_.get(integration, 'configuration.syncPageViewedEvent'));
      setSyncReadAssetEvent(_.get(integration, 'configuration.syncReadAssetEvent'));
      setSyncAssetDownloadEvent(_.get(integration, 'configuration.syncAssetDownloadEvent'));
    }
  }, [integrations]);

  const handleBackClick = () => {
    history.push(`${baseUrl}/settings/integrations`);
  }

  const validateForm = () => {
    if (!integration && (!clientId || clientId.trim().length === 0)) {
      return 'Please provide valid Client Id.';
    }

    if (!integration && (!clientSecret || clientSecret.trim().length === 0)) {
      return 'Please provide valid Client Secret.'
    }

    return null;
  }

  const handleSaveClick = () => {
    const errorMessage = validateForm();
    if (errorMessage) {
      setError(errorMessage);
      return;
    }

    let request = {
      munchkinId: munchkinId,
      syncPageViewedEvent: syncPageViewedEvent,
      syncReadAssetEvent: syncReadAssetEvent,
      syncAssetDownloadEvent: syncAssetDownloadEvent
    }

    if(clientId) {
      request = {
        ...request,
        clientId: clientId
      }
    }

    if(clientSecret) {
      request = {
        ...request,
        clientSecret: clientSecret
      }
    }

    dispatch(authenticateMarketo(request, (integration) => {
      dispatch(activatingIntegrationSuccedded(integration));
    }));
  }

  const handleRestClick = () => {
    setClientId(null);
    setClientSecret(null);
    setMunchkinId(_.get(integration, 'configuration.munchkinId', null));
  }

  const handleClientIdChange = (e) => {
    setClientId(e.target.value);
  }
  const handleClientSecretChange = (e) => {
    setClientSecret(e.target.value);
  }
  const handleMunchkinIdChange = (e) => {
    setMunchkinId(e.target.value);
  }

  const handleDeleteClick = () => {
    dispatch(deactivateIntegration('marketo', (res) => {
      handleBackClick();
    }))
  }

  const calculatePlaceholder = (field) => {
    var placeholder = field === 'clientId' ? 'Enter your Client Id' : 'Enter your Client Secret';
    return integration ? `*************** last updated: ${dateformat(integration.createdDate, 'dd mmm yyyy')}` : placeholder;
  }

  return (
    <Grid columns="equal" className={styles.marketo}>
      <Grid.Row>
        <Grid.Column width={5} className={styles.detailsView}>
          <Menu secondary>
            <Button className={styles.backButton} onClick={handleBackClick}>
              <Icon name={'angle left'} />BACK
            </Button>
          </Menu>

          <div className={styles.details}>
            <Image src={`/images/integrations/marketo.png`} />
            <Header as='h2'>Marketo</Header>
            <p className={styles.description}>
              Integrate with Marketo to sync events across contacts
            </p>
            {integration && integration.state.code === 'ok' &&
              <InlineConfirmationDialog onConfirm={handleDeleteClick} message={'Are you sure to deactivate marketo?'}>
                <Button>{deactivating ? 'DEACTIVATING...' : 'DEACTIVATE'}</Button>
              </InlineConfirmationDialog>
            }
          </div>
        </Grid.Column>
        <Grid.Column className={styles.formView}>
          <Segment basic className={styles.formSegment}>
            <div className={cx(styles.errorMessage, { [styles.active]: error })}>
              <p><Icon name="exclamation circle"></Icon> {error}</p>
            </div>
            <Form className={styles.form} autoComplete="new-password">
              <Form.Field>
                <Input labelPosition='left'
                  type= 'text'
                  placeholder='Enter your Munchkin Id'
                  value={munchkinId}
                  onChange={handleMunchkinIdChange}
                  >
                  <Label basic>MUNCHKIN-ID</Label>
                  <input />
                </Input>
              </Form.Field>
              <Form.Field>
                <Input labelPosition='left'
                  type= 'text'
                  placeholder={calculatePlaceholder('clientId')}
                  value={clientId}
                  onChange={handleClientIdChange}
                >
                  <Label basic>CLIENT-ID</Label>
                  <input />
                </Input>
              </Form.Field>
              <Form.Field>
                <Input labelPosition='left'
                  type= 'text'
                  placeholder={calculatePlaceholder('clientSecret')}
                  value={clientSecret}
                  onChange={handleClientSecretChange}
                  >
                  <Label basic>CLIENT-SECRET</Label>
                  <input />
                </Input>
              </Form.Field>
            </Form>

            <Header as='h4'> Sync Events </Header>
            <Grid.Row className={styles.event}>
              <Header.Subheader className={styles.header}>
                Contact/Lead views an asset
              </Header.Subheader> 
              <Checkbox toggle checked={syncReadAssetEvent} onChange={() => setSyncReadAssetEvent(!syncReadAssetEvent)}/>
            </Grid.Row>
            <Grid.Row className={styles.event}>
              <Header.Subheader className={styles.header}>
                Contact/Lead downloads an asset 
              </Header.Subheader> 
              <Checkbox toggle checked={syncAssetDownloadEvent} onChange={() => setSyncAssetDownloadEvent(!syncAssetDownloadEvent)}/>
            </Grid.Row>
            <Grid.Row className={styles.event}>
              <Header.Subheader className={styles.header}>
                Contact/Lead visits a page
              </Header.Subheader> 
              <Checkbox toggle checked={syncPageViewedEvent} onChange={() => setSyncPageViewedEvent(!syncPageViewedEvent)}/>
            </Grid.Row>

            <div className={styles.actions}>
              <Button onClick={handleSaveClick}>
                <Icon loading={saving} name={saving ? 'spinner' : 'save'} />
                {saving ? 'Saving...' : 'Save Changes'}
              </Button>
              <Button onClick={handleRestClick}>
                Reset
              </Button>
            </div>
          </Segment>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  )
}
