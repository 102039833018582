export const filterOptions = [
  { key: "ASSET", value: "ASSET", text: "Asset" },
  { key: "ASSET_TAG", value: "ASSET_TAG", text: "Tag" },
  { key: "FILE_TYPE", value: "FILE_TYPE", text: "File" },
  { key: "LIST_FIELD", value: "LIST_FIELD", text: "Field" }
];

export const operationOptions = [
  { key: "IN", value: "IN", text: "Contains" },
  { key: "NOT_IN", value: "NOT_IN", text: "Does Not Contains" }
]

export const assetTypesOptions = [
  { key: 'PDF', value: 'PDF', text: 'PDF' },
  { key: 'VIDEO', value: 'VIDEO', text: 'VIDEO' },
  { key: 'IMAGE', value: 'IMAGE', text: 'IMAGE' },
  { key: 'PPTX', value: 'PPTX', text: 'PPTX' },
  { key: 'PPT', value: 'PPT', text: 'PPT' },
  { key: 'DOC', value: 'DOC', text: 'DOC' },
  { key: 'DOCX', value: 'DOCX', text: 'DOCX' },
  { key: 'YOUTUBE', value: 'YOUTUBE', text: 'YOUTUBE' },
  { key: 'VIMEO', value: 'VIMEO', text: 'VIMEO' },
  { key: 'WISTIA', value: 'WISTIA', text: 'WISTIA' },
  { key: 'VIDYARD', value: 'VIDYARD', text: 'VIDYARD' },
  { key: 'LINK', value: 'LINK', text: 'LINK ' },
  { key: 'AUDIO', value: 'AUDIO', text: 'AUDIO'}
]