import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  useParams,
  useHistory,
  useRouteMatch
} from "react-router-dom";

import {
  Segment,
  Icon,
  Input,
  Menu,
  Label,
  Header,
  Image,
  Button,
  Radio
} from 'semantic-ui-react';

import styles from './journey.module.scss';

import cx from 'classnames';
import _ from 'lodash';
import Truncate from 'react-truncate';
import { useDrag, DndProvider, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

import { ContentModal } from './content';

import { fetchJourney, rearrangeStepInJourney, deleteStepFromJourney, updateJourney, deleteJourney } from 'actions/journey';

import { getBaseUrl } from 'services/util';
import journeysSaga from 'actions/journey';

export default function JourneyDetailView(props) {
  const dispatch = useDispatch();
  const params = useParams();
  const history = useHistory();
  let { url } = useRouteMatch();
  let baseUrl = getBaseUrl(url);

  const journey = useSelector(state => _.get(state, 'journeys.journey.item', {}));
  const updating = useSelector(state => _.get(state, 'journeys.journey.updating', false));
  const deleting = useSelector(state => _.get(state, 'journeys.journey.deleting', false));
  const loading = useSelector(state => _.get(state, 'journeys.journey.loading', false));
  const disabled = useSelector(state => _.get(state, 'journeys.journey.item.disabled', false));

  const [name, setName] = useState(_.get(journey, 'name', 'Untitled'));

  useEffect(() => { 
    if (params.id !== 'new') {
      dispatch(fetchJourney(params.id));
    }
  }, [params.id])

  useEffect(() => {
    if(journey) {
      setName(journey.name);
    }
  }, [loading]);

  const handleUpdateJourney = () => {
    var request = {
      name: name,
      start: _.get(journey, 'start.id'),
      steps: (journey.steps || []).map(item => { return item.id })
    }

    dispatch(updateJourney(journey.id, request));
    
  }

  const handleJourney = () => {

    var request = {
      disabled: !disabled
    }

    dispatch(updateJourney(journey.id, request));
  }

  const handleDeleteJourney = () => {
    dispatch(deleteJourney(journey.id, (response) => {
      history.push(`${baseUrl}/journeys`);
    }))
  }

  if(!journey) {
    return null;
  }

  return (
    <DndProvider backend={HTML5Backend}>
      <Segment basic className={cx(styles.details, 
         {[styles.loading]: loading })}>
        {loading &&
          <div className={styles.loadingJourney}>
            Loading Journey...
            </div>
        }
        <Menu secondary className={styles.header}>
          <Menu.Item>
            <Input
              placeholder='Enter Journey Name'
              value={name}
              onChange={(e) => {
                setName(e.target.value); 
              }}
              className={styles.input}
            />
          </Menu.Item>

          <Menu.Menu position='right'>
            
            {!loading && journey &&
            <Menu.Item>
              <Radio toggle disabled={updating} onChange={handleJourney} checked={!disabled} />
            </Menu.Item>
            }

            <Menu.Item>
              <Button basic
                className={styles.deleteButton}
                onClick={handleDeleteJourney}>
                  <Icon name="trash" />
                  {deleting ? 'Deleting...' : 'Delete'}
              </Button>
            </Menu.Item>
            <Menu.Item>
              <Button basic
                className={styles.saveButton} 
                onClick={handleUpdateJourney}>
                  <Icon name="save" />
                  {updating  ? 'Saving...' : 'Save Changes'}
              </Button>
            </Menu.Item>
          </Menu.Menu>
        </Menu>

        <Segment basic className={styles.journeySteps}>

          {disabled &&
          <div className={styles.disableJourney}>
            <p>'This Journey is turned off. To make this journey actively available, please turn on the Toggle'
            </p>
          </div>
          }
          
          {!disabled &&
          <Segment basic className={styles.journeyStepsInner}>
            
            <StartNode journey={journey} step={journey.start} />

            {(journey.steps || []).map((step, index) => {
              return <JourneyStep index={index} journey={journey} step={step} />
            })}

            <AddNode journey={journey} />

          </Segment>
          }
          
        </Segment>
      </Segment>
    </DndProvider>
  )
}

function JourneyStep({journey, step, index, ...rest}) {
  const dispatch = useDispatch();
  const ref = React.useRef(null);
  const handleRef = React.useRef(null);

  var summary = _.get(journey, 'startNodeSummary');
  if(!rest.start) {
    summary = _.get(step, 'summary');
  }

  const [{ isDragging }, drag, preview] = useDrag(() => ({
    type: rest.start ? 'start' : 'step',
    item: { type: rest.start ? 'start' : 'step', step, index},
    canDrag: () => {
      return true;
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  }));

  const [, drop] = useDrop(() => ({
    accept: "step",
    hover(item, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;

      if (dragIndex === hoverIndex) {
        return;
      }

      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      const clientOffset = monitor.getClientOffset();
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;

      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      // Dragging upwards

      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }

      dispatch(rearrangeStepInJourney(item.step.id, dragIndex, hoverIndex));

      item.index = hoverIndex;
    },
  }));

  const handleDeleteClick = (step) => {
    dispatch(deleteStepFromJourney(step.id));
  }

  return (
    <div className={cx(styles.step, {
      [styles.dragging]: isDragging
    })} ref={drag(drop(ref))}>

      {step.type === 'asset' &&
        <AssetsNode 
          journey={journey} 
          step={step} 
          onDelete={handleDeleteClick} 
        />
      }

      {step.type === 'cluster' &&
        <FilterNode 
          journey={journey} 
          step={step} 
          onDelete={handleDeleteClick} 
        />
      }

    </div>
  )
}

function StartNode({journey}){
  if(!journey){
    return false;
  }

  const start = journey.start;

  return (
    <div className={styles.step}>
      <div className={cx(styles.node, styles.start)}>
        <div className={styles.name}>START</div>
        <Edge />
      </div>
      <div className={styles.description}>
        <div className={styles.label}>When visitor views assets meeting the criteria</div>
        {start == null && 
          <ContentModal journey={journey} step={journey.start} start={true} trigger={
            <Button basic className={styles.chooseCriteria}>Choose criteria</Button>
          }></ContentModal>
        }

        {start && 
          <div className={styles.value} dangerouslySetInnerHTML={{ __html: start.description }}  />
        }
      </div>
      <div className={styles.actions}>
        {start && 
          <ContentModal journey={journey} step={journey.start} start={true} trigger={
            <Button basic circular className={styles.edit} >
              <i aria-hidden="true" className="edit icon"></i>
            </Button>
          }></ContentModal>
        }
      </div>
    </div>
  );
}

function AssetsNode({handleRef, journey, step, onDelete}){
  const ref = React.createRef(null);

  const icon = _.get(step, 'icon.thumbnail') || '/images/default_asset.jpg';

  const handleDeleteClick = () => {
    onDelete(step);
  }
  return (
    <>
      <div className={cx(styles.node, styles.asset)}>
        <div className={styles.icon}>
          <Handle handleRef={handleRef} />
          <img src={icon} /> 
          <div className={styles.layer}></div>
          <div className={styles.layer}></div>
        </div>
        <Edge />
      </div>
      <div className={styles.description}>
        <div className={styles.label}>Show asset matching criteria</div>
        <div className={styles.value} dangerouslySetInnerHTML={{ __html: step.description }}  />
      </div>
      <div className={styles.actions}>
        <ContentModal journey={journey} step={step} trigger={
          <Button basic circular className={styles.edit} >
            <i aria-hidden="true" className="edit icon"></i>
          </Button>
        }></ContentModal>
        <Button basic circular className={styles.delete} onClick={handleDeleteClick}>
          <i aria-hidden="true" className="trash alternate outline icon"></i>
        </Button>
      </div>
    </>
  );
}

function FilterNode({handleRef, journey, step, onDelete}){

  const handleDeleteClick = () => {
    onDelete(step);
  }

  return (
    <>
      <div className={cx(styles.node, styles.filter)}>
        <div className={styles.icon}>
          <Handle handleRef={handleRef} />
          <Icon name="filter" size='large' />
        </div>
        <Edge />
      </div>
      <div className={styles.description}>
        <div className={styles.label}>Show asset matching criteria</div>
        <div className={styles.value} dangerouslySetInnerHTML={{ __html: step.description }}  />
      </div>
      <div className={styles.actions}>
        <ContentModal journey={journey} step={step} trigger={
          <Button basic circular className={styles.edit} >
            <i aria-hidden="true" className="edit icon"></i>
          </Button>
        }></ContentModal>
        <Button basic circular className={styles.delete} onClick={handleDeleteClick}>
          <i aria-hidden="true" className="trash alternate outline icon"></i>
        </Button>
      </div>
    </>
  );
}

function AddNode({journey}){
  return (
    <div className={styles.step}>
      <ContentModal journey={journey} trigger={
        <div className={cx(styles.node, styles.add)}>
          <div className={styles.icon}>
            <Icon name="add" size='large' />
          </div>
        </div>
      }></ContentModal>
    </div>
  );
}

function Edge(){
  return (
    <div className={styles.edge}>
      <div className={styles.line}></div>
      <div className={styles.arrow}></div>
    </div>
  );
}

function Handle({handleRef}){
  const ref = React.createRef(null);

  return (
    <div className={styles.handle}>
      <div className={styles.line}>
        {_.times(3).map((index) => {
          return <span key={index} />
        })}
      </div>
    </div>
  );
}