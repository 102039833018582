import { guid } from './util';
import _ from 'lodash';
import { ROW, COLUMN, LIVE_ELEMENT, MARKETO_FORM, PARDOT_FORM, EXTERNAL_FORM, HUBSPOT_FORM, FORM } from 'constants/ItemTypes';
import { WidgetRegistry } from 'components/Widgets/registry';

export function normalise(widgets, widgetsById) {
  var data = {
    widgets: [],
    widgetsById: widgetsById
  };

  function _normalise(widget) {
    const children = widget.widgets;
    if (!children || children.length === 0) {
      return widget;
    }

    var normalisedChildren = children.map(child => {
      child = _normalise(child);
      data.widgetsById[child.id] = child;

      return child.id;
    });

    var id = widget.id;
    if (!id) {
      id = guid();
    }

    return {
      ...widget,
      id: id,
      _id: widget.id,
      widgets: normalisedChildren
    }
  }

  if (!widgets || widgets.length === 0) {
    return data;
  }

  data.widgets = widgets.map(widget => {
    widget = _normalise(widget);
    data.widgetsById[widget.id] = widget;

    return widget.id;
  });

  return data;
}

export function denormalise(widgets, widgetsById) {
  function _denormalise(id) {
    var denormalised = widgetsById[id];

    if (!denormalised) {
      return null;
    }

    const children = denormalised.widgets;
    if (!children || children.length === 0) {
      return denormalised;
    }

    var denormalisedChildren = children.map(child => {
      child = _denormalise(child);
      return child;
    });

    const { _id, ...rest } = denormalised;
    return {
      ...rest,
      id: _id,
      widgets: denormalisedChildren
    };
  }

  return widgets.map(id => {
    return _denormalise(id);
  }).filter(item => item != null);
}

export function deleteWidget(id, widgetsById) {
  var widget = widgetsById[id];

  if ([MARKETO_FORM, PARDOT_FORM, EXTERNAL_FORM, HUBSPOT_FORM, LIVE_ELEMENT, FORM].includes(widget.type)) {
    return widgetsById;
  }
  
  widgetsById = _.omit(widgetsById, [id]);

  if (!widget) {
    return widgetsById;
  }

  var parent = widgetsById[widget.parent];
  if (parent) {
    parent = {
      ...parent,
      widgets: (parent.widgets || []).filter(w => {
        return w != id;
      })
    }

    widgetsById[parent.id] = parent;
  }
  
  var children = widget.widgets || [];
  children.forEach(child => {
    widgetsById = deleteWidget(child, widgetsById);
  })

  return widgetsById;
}

export function moveWidget(context, widget, parent, index, insertBefore) {
  const moveWithinParent = _belongsToParent(context, parent, widget.id);

  if (moveWithinParent) {
    console.log("Move within parent");
    context = _moveWidget(context, widget, index, insertBefore);
  } else {
    context = removeWidgetFromParent(context, widget.parent, widget);
    context = _addWidgetToParent(context, parent, widget, index, insertBefore);
  }

  return context;
}

function _moveWidget(context, widget, index, insertBefore) {
  var parentWidget = context.widgetsById[widget.parent];
  var widgets = getChildrenWidgetsForId(context, widget.parent);
  const fromIndex = widgets.findIndex(id => id === widget.id);

  if (index < 0) {
    index = 0;
  }

  const target = widgets[index];
  console.log(target, widget.id);

  if(target === widget.id){
    return context;
  }

  //Remove Widget
  widgets = widgets.filter(item => {
    return item != widget.id;
  })

  var updated = new Array();
  widgets.forEach(item => {
    if(item === target && insertBefore){
      updated.push(widget.id)
    }

    updated.push(item);

    if(item === target && !insertBefore){
      updated.push(widget.id)
    }
  });

  if (widget.parent == null) {
    context.widgets = updated;
  } else {
    parentWidget = {
      ...parentWidget,
      widgets: updated
    }

    context.widgetsById[widget.parent] = parentWidget;
  }

  return context;
}

function _addWidgetToParent(context, parent, widget, toIndex, insertBefore) {
  var parentWidget = context.widgetsById[parent];
  var widgets = getChildrenWidgetsForId(context, parent);

  if (!insertBefore) {
    toIndex = toIndex + 1;
  }

  widgets.splice(toIndex, 0, widget.id);

  if (parentWidget == null) {
    context.widgets = widgets;
  } else {
    parentWidget = {
      ...parentWidget,
      widgets: widgets
    }

    context.widgetsById[parent] = parentWidget;
  }

  widget = context.widgetsById[widget.id];
  widget.parent = parent;
  context.widgetsById[widget.id] = widget;

  return context;
}

export function removeWidgetFromParent(context, parent, widget) {
  var parentWidget = context.widgetsById[parent];
  var widgets = getChildrenWidgetsForId(context, parent);
  widgets = widgets.filter(id => id != widget.id);

  if (parentWidget == null) {
    context.widgets = widgets;
  } else {
    parentWidget = {
      ...parentWidget,
      widgets: widgets
    }

    context.widgetsById[parent] = parentWidget;
  }

  widget = context.widgetsById[widget.id];
  widget.parent = null;
  context.widgetsById[widget.id] = widget;

  return context;
}

function _belongsToParent(context, parent, child){
  var children = context.widgets;

  if(parent != null){
    var parentWidget = context.widgetsById[parent];
    children = parentWidget.widgets;
  }

  return _.includes(children, child);
}

function getChildrenWidgetsForId(context, id) {
  const parent = context.widgetsById[id];
  var widgets = []
  if (parent == null) {
    widgets = [...context.widgets];
  } else {
    widgets = [...parent.widgets || []];
  }

  return widgets;
}

/**
 *  Get Closest column for widget
*/
export function getClosestColumnForWidget(context, id) {
  const widget = context.widgetsById[id];

  if (!widget) {
    return null;
  }

  if (widget.type === COLUMN) {
    return widget;
  }

  return getClosestColumnForWidget(context, widget.parent);
}

/**
 *  Get Closest row for widget
*/
export function getClosestRowForWidget(context, id) {
  const widget = context.widgetsById[id];

  if (!widget) {
    return null;
  }

  if (widget.type === ROW) {
    return widget;
  }

  return getClosestRowForWidget(context, widget.parent);
}

/**
 * Get Closest selectable parent for widget
 * 
*/

export function getClosestEditableParentForWidget(context, id) {
  const widget = context.widgetsById[id];

  if (!widget) {
    return null;
  }

  if(!widget.parent){
    return null;
  }

  const parent = context.widgetsById[widget.parent];
  if(!parent){
    return null;
  }

  const editable = _.get(parent, 'capabilities.editable', true);
  if(!editable){
    return getClosestEditableParentForWidget(context, parent.id);
  }

  return parent;
} 

/**
 *  Get resizable widget
*/
export function getResizableWidget(context, widget) {
  var  resizableWidget = null;
  if(_.includes(['widgets.image'], widget.type)){
    resizableWidget = widget;
  }else{
    resizableWidget = getClosestColumnForWidget(context, widget.id);
  }
  
  return resizableWidget;
}

/**
 *  Is widget resizable
*/
export function isResizable(context, widget) {
  var  resizableWidget = getResizableWidget(context, widget);
  
  if (!resizableWidget) {
    return null;
  }

  const resizable = _.get(resizableWidget, 'capabilities.resizable', true);
  return resizable;
}

/**
 * Prepare palette item drop request
 * Check for the widgets being dropped & calculate if widget needs to be wrapped in a row/column
 */
export function preparePaletteDropRequest(context, parent, request) {
  if (!request) {
    return request;
  }

  const parentWidget = context.widgetsById[parent];

  const processed = (request.content || []).map(widget => {
    const componentConfig = WidgetRegistry.widgets[widget.type];
    if(!componentConfig){
      return widget;
    }

    var processed = widget;
    if(componentConfig.preparePaletteDropRequest){
      processed = componentConfig.preparePaletteDropRequest(context, parentWidget, widget);
    }

    return processed;
  }).filter(item => item != null);

  return processed;
}

/**
 * Wrap widget in row
*/
export function wrapWithRow(widget, width = 16){
  if(!widget){
    return widget;
  }

  var column = widget;
  if(widget.type !== COLUMN){
    column = wrapWithColumn(widget, width);
  }

  const row = {
    "name": "Row",
    "type": ROW,
    "widgets": [column]
  }

  return row;
}

/**
 * Wrap widget in column
*/
export function wrapWithColumn(widget, width){
  const column = {
    "name": "Column",
    "type": COLUMN,
    "properties": {
      "width": width
    },
    "widgets": [widget]
  }

  return column;
}

/**
 * create row
*/
export function createRow(){
  const row = {
    "name": "Row",
    "type": ROW,
    "widgets": [
      createColumn()
    ]
  }

  return row;
}

/**
 * Wrap widget in column
*/
export function createColumn(width){
  var properties = {};
  if(width != undefined){
    properties.width = width
  }

  const column = {
    "name": "Column",
    "type": COLUMN,
    "properties": properties,
    "widgets": []
  }

  return column;
}


/**
 * Get Inner width of an element
 * 
 */
export function getInnerWidthOfElement(element){
  if(!element){
    return null;
  }

  var computed = window.getComputedStyle(element);
  var padding = parseInt(computed.paddingLeft) + parseInt(computed.paddingRight);

  return element.clientWidth - padding;
}

 /**
 * Get Inner height of an element
 * 
 */
export function getInnerHeightOfElement(element){
  if(!element){
    return null;
  }

  var computed = window.getComputedStyle(element);
  var padding = parseInt(computed.paddingTop) + parseInt(computed.paddingBottom);

  return element.clientHeight - padding
 }