import React, { useState, useEffect, createRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import _ from 'lodash';
import cx from 'classnames';
import Truncate from 'react-truncate';

import {
  Menu,
  Visibility,
  Button,
  Segment,
  Step,
  Header,
  Image,
  Icon,
  Label
} from 'semantic-ui-react';

import { AddContentContext, SubmitForm } from './index';

import styles from './addContent.module.scss';

import { fetchAssetsForFilters, updateContentEditor, deleteAssetFromContentEditor, rearrangeAssetInContentEditor } from 'actions/contentSelection';

import FileService from 'services/file.service.jsx';
import { normalise } from 'services/clusters.service';

import { useDrag, DndProvider, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import Tooltip from "components/Tooltip/index.jsx";

export default function AssetList(props) {
  const dispatch = useDispatch();

  const [sort, setSort] = useState({ sortBy: 'modifiedDate', orderBy: 'DESC' });

  const { editor, editable, sortable, deletable } = React.useContext(AddContentContext);
  const content = useSelector(state => state.contentSelection.editors[editor]);
  const assets = content.assets || [];
  const type = content.type;
  const currentPage = _.get(content, 'pagination.current');
  const totalPages = _.get(content, 'pagination.totalPages');
  const totalRecords = type === 'cluster' ? _.get(content, 'pagination.totalRecords') : assets.length;

  const loading = _.get(content, 'loading');
  const selectedAsset = _.get(content, 'selectedAsset', null);
  const assetsLength = assets.length;

  useEffect(() => {
    if (type === 'cluster') {
      var filters = content.filters || [];
      dispatch(fetchAssetsForFilters(editor, {
        ...props.sort,
        filters: normalise(filters)
      }, 0, 20));
    }
  }, [content.filters, content.all, sort]);

  const loadMoreAssets = () => {
    if (type !== 'cluster' || loading || currentPage === totalPages) {
      return;
    }

    dispatch(fetchAssetsForFilters(editor, {
      ...sort,
      filters: normalise(content.filters)
    }, currentPage + 1, 20));
  }

  const handleEditAsset = (asset) => {
    var request = {
      ...content,
      selectedAsset: asset
    }

    dispatch(updateContentEditor(editor, request));
  }

  const handleDeleteAsset = (asset) => {
    dispatch(deleteAssetFromContentEditor(editor, asset));
  }

  const handleRearrangeAsset = (asset, from, to) => {
    dispatch(rearrangeAssetInContentEditor(editor, asset.id, from, to));
  }

  const handleBackClick = () => {
    var request = {
      ...content,
      selectedAsset: null
    }

    dispatch(updateContentEditor(editor, request));
  }

  return (
    <DndProvider backend={HTML5Backend}>
      <Segment basic className={styles.assets}>
        {!selectedAsset &&
          <Header as='h3'>{`(${totalRecords}) Assets`}</Header>
        }

        {selectedAsset &&
          <Header as='h3' onClick={handleBackClick} className={styles.header}>
            <Icon name='angle left' />
            <Header.Content>Add More Content</Header.Content>
          </Header>
        }

        <Visibility onBottomVisible={loadMoreAssets} continuous>
          <Segment basic className={styles.list}>
            {assets.map((item, index) => {
              return (
                <AssetRow key={item.id}
                  asset={item}
                  type={type}
                  assetsLength={assetsLength}
                  index={index}
                  sortable={sortable && type === 'asset'}
                  deletable={deletable && type === 'asset'}
                  editable={editable && type === 'asset'}
                  onEdit={handleEditAsset}
                  onDelete={handleDeleteAsset}
                  onRearrange={handleRearrangeAsset}
                  selected={item.id === _.get(selectedAsset, 'id', null)}
                />
              );
            })}
          </Segment>
        </Visibility>

        {!selectedAsset &&
          <SubmitForm showArrows={false} />
        }
      </Segment>
    </DndProvider>
  )
}

function AssetRow({ asset, type, assetsLength, index, selected, onEdit, onDelete, onRearrange, ...rest }) {
  const ref = React.useRef(null);
  const handleRef = React.useRef(null);
  const dispatch = useDispatch();

  var icon = _.get(asset, 'icon') || '/images/default_asset.jpg';

  const [{ isDragging }, drag, preview] = useDrag(() => ({
    type: "asset",
    item: { type: 'asset', asset, index },
    canDrag: () => {
      return rest.sortable
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  }));

  const [, drop] = useDrop(() => ({
    accept: "asset",
    hover(item, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;

      if (dragIndex === hoverIndex) {
        return;
      }

      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      const clientOffset = monitor.getClientOffset();
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;

      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      // Dragging upwards

      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }

      onRearrange(item.asset, dragIndex, hoverIndex);

      item.index = hoverIndex;
    },
  }));


  const handleMoveDown = () => {
    onRearrange(asset, index, assetsLength - 1);
  }

  const handleMoveUp = () => {
    onRearrange(asset, index, 0);
  }

  const handleEditClick = () => {
    if (!rest.editable) {
      return
    }

    onEdit(asset);
  }

  const handleDeleteClick = () => {
    onDelete(asset);
  }

  drag(handleRef);

  return (
    <div className={cx(styles.asset, {
      [styles.active]: selected,
      [styles.selectable]: rest.editable || rest.deletable,
      [styles.dragging]: isDragging
    })} ref={drop(preview(ref))}>
      {rest.sortable &&
        <Handle ref={handleRef} />
      }
      <Header as='h4' onClick={handleEditClick}>
        <Image src={icon} />
        <Header.Content className={styles.name}>
          <Truncate lines={2}>{asset.name}</Truncate>
          <Header.Subheader>
            {asset.metadata && `${FileService.fileType(asset.metadata)} | ${FileService.formatSize(asset.metadata.content_length)}`}
          </Header.Subheader>
        </Header.Content>
      </Header>

      <div className={styles.leftActions}>
        {index !== 0 &&
          <div className={styles.moveUp}>
            <Tooltip content={'Move to top'}
              position='top left'
              inverted
              size='mini'
            >
              <Label size='mini' onClick={handleMoveUp}>
                  <Icon name={"angle double up"} />
              </Label>
            </Tooltip>
          </div>
        }
        {index != assetsLength - 1 &&
          <div className={styles.moveDown}>
            <Tooltip content={'Move to bottom'}
              inverted
              size='mini'
              position='top left'
            >
              <Label size='mini' onClick={handleMoveDown}>
                  <Icon name={"angle double down"} />
              </Label>
            </Tooltip>
          </div>
        }
      </div>

      <div className={styles.actions}>
        {rest.editable &&
          <Button basic className={styles.edit} onClick={handleEditClick}>
            <i aria-hidden="true" className="edit icon"></i>
          </Button>
        }
        {rest.deletable &&
          <Button basic className={styles.delete} onClick={handleDeleteClick}>
            <i aria-hidden="true" className="trash alternate outline icon"></i>
          </Button>
        }
      </div>
    </div>
  )
}

const Handle = React.forwardRef((props, ref) => {
  return (
    <div className={styles.handle} ref={ref}>
      <div className={styles.line}>
        {_.times(2).map((index) => {
          return <span key={index} />
        })}
      </div>
      <div className={styles.line}>
        {_.times(2).map((index) => {
          return <span key={index} />
        })}
      </div>
      <div className={styles.line}>
        {_.times(2).map((index) => {
          return <span key={index} />
        })}
      </div>
    </div>
  );
});