import {
  FETCH_LIVE_ELEMENTS_REQUEST, FETCHING_LIVE_ELEMENTS_SUCCEEDED, FETCHING_LIVE_ELEMENTS_FAILED,
  FETCH_LIVE_ELEMENT_REQUEST, FETCHING_LIVE_ELEMENT_SUCCEEDED, FETCHING_LIVE_ELEMENT_FAILED,
  CREATE_LIVE_ELEMENT_REQUEST, CREATING_LIVE_ELEMENT_SUCCEEDED, CREATING_LIVE_ELEMENT_FAILED,
  CLONE_LIVE_ELEMENT_REQUEST, CLONING_LIVE_ELEMENT_SUCCEEDED, CLONING_LIVE_ELEMENT_FAILED,
  UPDATE_LIVE_ELEMENT_REQUEST, UPDATING_LIVE_ELEMENT_SUCCEEDED, UPDATING_LIVE_ELEMENT_FAILED,
  PUBLISH_LIVE_ELEMENT_REQUEST, PUBLISHING_LIVE_ELEMENT_SUCCEEDED, PUBLISHING_LIVE_ELEMENT_FAILED,
  DELETE_LIVE_ELEMENT_REQUEST, DELETE_LIVE_ELEMENT_SUCCEEDED, DELETE_LIVE_ELEMENT_FAILED,

  UPDATE_LIVE_ELEMENT_NAME,

  SWITCH_STORYBOARD
} from 'constants/actionTypes.jsx';
import { act } from 'react-dom/test-utils';

const initialState = {
  loading: false,
  items: [],
  pagination: {
    currentPage: -1,
    totalPages: 1,
    recordsPerPage: 20,
    totalRecordsCount: 0
  },
  element: {
    loading: false,
    item: null
  },
  create: {
    creating: false,
    item: null,
    error: null
  },
  clone: {
    cloning: false,
    item: null,
    error: null
  }
}

const initialAnalyticsState = {
  loading: false,
  items: []
}

function liveElementsReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_LIVE_ELEMENTS_REQUEST:
      const { page } = action.payload;

      return {
        ...state,
        loading: true
      }

    case FETCHING_LIVE_ELEMENTS_SUCCEEDED:
      var items = state.items;
      var { currentPage, totalPages, recordsPerPage, totalRecordsCount, records = [] } = action.payload;

      if (currentPage !== 0) {
        items = [...state.items, ...records];
      } else {
        items = records
      }

      items = items.reduce((x, y) => {
        var index = x.findIndex((e) => {
          return e.id === y.id;
        });

        if (index < 0) {
          return [...x, y];
        } else {
          x[index] = y;
          return x;
        }
      }, []);

      return {
        ...state,
        loading: false,
        items: items,
        pagination: {
          ...state.pagination,
          currentPage,
          totalPages,
          recordsPerPage,
          totalRecordsCount
        }
      }
    
    case FETCHING_LIVE_ELEMENTS_FAILED:
      const { error } = action.payload;

      return {
        ...state,
        loading: false,
        error: error
      }

    case CREATE_LIVE_ELEMENT_REQUEST:
      return {
        ...state,
        create: {
          creating: true,
          item: action.payload.request
        }
      }

    case CREATING_LIVE_ELEMENT_SUCCEEDED:
      var items = [action.payload, ...state.items];

      return {
        ...state,
        items: items,
        create: {
          creating: false,
          item: action.payload,
          error: null
        }
      }

    case CREATING_LIVE_ELEMENT_FAILED:
      return {
        ...state,
        create: {
          creating: false,
          item: null,
          error: action.payload
        }
      }

    case CLONE_LIVE_ELEMENT_REQUEST:
      var { id } = action.payload;

      return {
        ...state,
        clone: {
          element: id,
          cloning: true
        }
      }

    case CLONING_LIVE_ELEMENT_SUCCEEDED:
      items = [action.payload, ...state.items];

      return {
        ...state,
        items: items,
        clone: {
          cloning: false,
          item: action.payload,
          error: null
        }
      }

    case CLONING_LIVE_ELEMENT_FAILED:
      return {
        ...state,
        clone: {
          ...state.clone,
          cloning: false,
          item: null,
          error: action.payload
        }
      }

    
    case FETCH_LIVE_ELEMENT_REQUEST:
      return {...state,
        element: {
          ...state.element,
          loading: true,
          error: null
        }
      }

    case FETCHING_LIVE_ELEMENT_SUCCEEDED:
      return {...state,
        element: {
          ...state.element,
          loading: false,
          item: action.payload,
          error: null
        },
        create: {
          creating: false,
          item: null,
          error: null 
        },
        clone: {
          cloning: false,
          item: null,
          error: null 
        }
      }

    case FETCHING_LIVE_ELEMENT_FAILED:
      return {...state,
        element: {
          ...state.element,
          loading: false,
          error: action.payload
        }
      }

    
    case UPDATE_LIVE_ELEMENT_REQUEST:
      return {...state,
        element: {
          ...state.element,
          updating: true,
          error: null
        }
      }

    case UPDATING_LIVE_ELEMENT_SUCCEEDED:
      return {...state,
        element: {
          ...state.element,
          updating: false,
          item: action.payload,
          error: null
        }
      }

    case UPDATING_LIVE_ELEMENT_FAILED:
      return {...state,
        element: {
          ...state.element,
          updating: false,
          error: action.payload
        }
      }

    case UPDATE_LIVE_ELEMENT_NAME:  
    var item = state.element.item;

    if(!item){
      return state;
    }

    if(item.id !== action.payload.id){
      return state;
    }

    return {
      ...state,
      element: {
        ...state.element,
        item: {
          ...item,
          name: action.payload.name
        }
      }
    }

    
    case PUBLISH_LIVE_ELEMENT_REQUEST:
      return {...state,
        element: {
          ...state.element,
          publishing: true,
          error: null
        }
      }

    case PUBLISHING_LIVE_ELEMENT_SUCCEEDED:
      return {...state,
        element: {
          ...state.element,
          publishing: false,
          item: action.payload,
          error: null
        }
      }

    case PUBLISHING_LIVE_ELEMENT_FAILED:
      return {...state,
        element: {
          ...state.element,
          publishing: false,
          error: action.payload
        }
      }  

    case DELETE_LIVE_ELEMENT_REQUEST:
      return state;

    case DELETE_LIVE_ELEMENT_SUCCEEDED:
      var { id } = action.payload;

      var items = state.items.filter(item => {
        return item.id !== id;
      })

      return {
        ...state,
        items: items,
        element: {
          loading: false,
          item: null,
          error: null
        }
      }

    case DELETE_LIVE_ELEMENT_FAILED:
      return state;

      

    case SWITCH_STORYBOARD:
      return initialState;

    default:
      return state
  }
}

export { liveElementsReducer };