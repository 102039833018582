import React, { useState } from 'react';

import _ from 'lodash';
import cx from 'classnames';

import { Button, Grid, Label } from 'semantic-ui-react';
import TimeAgo from 'react-timeago';

import SidePane from 'components/SidePane';
import EditAlias from './edit';

import styles from './alias.module.scss';

import { fetchAliases, invalidateAliases } from 'actions/aliases';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';


export default function ManageAlias({open, entity, onClose}){
  const dispatch = useDispatch();

  const [state, setState] = useState({
    alias: null
  });

  const aliases = useSelector(state => _.get(state, 'aliases.items'));
  const created = useSelector(state => _.get(state, 'aliases.alias.created'));
  const updated = useSelector(state => _.get(state, 'aliases.alias.updated'));
  const persistedItem = useSelector(state => _.get(state, 'aliases.alias.item'));
  const deleted = useSelector(state => _.get(state, 'aliases.alias.deleted'));
  
  useEffect(()=>{
    if(open){
      _handleSelection(null);
      
      dispatch(fetchAliases({
        type: entity.entityType,
        target: entity.id
      }, 0, 20));
    }else{
      dispatch(invalidateAliases());
    }
  }, [open, entity]);

  useEffect(() => {
    if(state.alias || _.size(aliases) == 0){
      return;
    }

    const selection = aliases[0];
    _handleSelection(selection);

  }, [aliases]);

  useEffect(() => {
    if((created || updated) && persistedItem){
      _handleSelection(persistedItem);
    }
  }, [created, updated]);

  useEffect(() => {
    if(deleted){
      if(aliases.length == 0){
        return;
      }

      const next = aliases[0];
      _handleSelection(next);
    }
  }, [deleted])

  const _handleCreate = () => {
    setState((state) => {
      return (
        {
          ...state,
          alias: {
            type: entity.entityType,
            autoCreate: false,
            url: '',
            canonical: false,
          }
        }
      );
      
    })
  }

  const _handleSelection = (alias) => {
    setState((state) => {
      return (
        {
          ...state,
          alias: alias
        }
      );
    })
  }

  const handleClose = () => {
    onClose();
  }

  return (
    <SidePane open={open} width='60vw' closeOnDocumentClick={false}>
      <div className={styles.content}>
      <Button basic className={styles.closeButton} onClick={handleClose}>
        <i aria-hidden="true" className={cx('icon', 'icon-close_1')}>
          <span className="path1"></span><span className="path2"></span><span className="path3"></span>
        </i>
      </Button>

        <div className={styles.manage}>
          <h1 className={styles.heading}>Manage Alias</h1>
          <div className={styles.aliases}>
            <div className={styles.scrollContainer}>
              <Button className={styles.create} onClick={_handleCreate}>+ Add new alias</Button>
              {state.alias && !state.alias.id &&
                <Alias key='new_alias' value={state.alias} selected={true} />
              }

              {aliases.map(item => {
                const selected = item.id === _.get(state, 'alias.id');
                return (
                  <Alias key={item.id} value={item} selected={selected} onClick={_handleSelection}/>
                );
              })}
            </div>
          </div>
        </div>
        <EditAlias className={styles.editContainer} alias={state.alias} entity={entity} />
      </div>
    </SidePane>
  );
}

function Alias({value, selected, onClick}){
  const createNew = value.id == null;
  const baseUrl = useSelector(state => _.get(state, 'storyboards.storyboard.item.baseUrl'));
  const url = _

  return (
    <div className={cx(styles.alias, {
      [styles.selected]: selected
    })} onClick={() => {
      onClick(value);
    }}>
      <div className={styles.details}>
        <div className={styles.meta}>
          <div className={styles.createdDate}>{createNew ? '' : <TimeAgo date={value.createdDate} live={false} />}</div>
          {value.canonical && <Label size="tiny">Canonical</Label>}
        </div>
        
        <div className={styles.url}>{createNew ? 'Untitled' : _.get(value, 'url')}</div>
        <div className={styles.createdBy}>{createNew ? 'Created By User' : `Created By ${_.get(value, 'createdBy.firstName') || ''} ${_.get(value, 'createdBy.lastName') || ''}`}</div>
      </div>
      
      {!createNew && 
        <div className={styles.actions}>
          <a href={`${baseUrl}${_.get(value, 'url')}`} target="_blank" className={styles.view}>PREVIEW</a>
        </div>
      }
    </div>
  );
}