import React, { PropTypes, Fragment, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import cx from 'classnames';
import {
  Menu,
  Grid,
  Button,
  Icon,
  Segment,
  Image,
  Header,
  Form,
  Input,
  Label
} from 'semantic-ui-react';

import {
  useRouteMatch,
} from "react-router-dom";

import _ from 'lodash';

import styles from './freshmarketer.module.scss';

import InlineConfirmationDialog from 'components/InlineConfirmationDialog';

import { getBaseUrl } from 'services/util';

import { authenticateFreshMarketer } from 'actions/integrations/freshmarketer';
import { deactivateIntegration, activatingIntegrationSuccedded } from 'actions/integrations/integrations';

export default function Freshmarketer() {
  const dispatch = useDispatch();
  let { path, url } = useRouteMatch();
  let baseUrl = getBaseUrl(url);
  const history = useHistory();

  const integrations = useSelector(state => _.get(state, 'integrations.activated.items', []));
  const deactivating = useSelector(state => _.get(state, 'integrations.activated.deactivating', false));
  const errors = useSelector(state => _.get(state, 'integrations.freshmarketer.changes.error', {}));
  const saving = useSelector(state => _.get(state, 'integrations.freshmarketer.changes.saving', false));

  const integration = integrations.filter(item => {
    return item != null;
  }).find(item => {
    return item.type = 'freshmarketer';
  })

  const [domain, setDomain] = useState(_.get(integration, 'configuration.domainName', ''));
  const [apikey, setApikey] = useState('');
  const [current, setCurrent] = useState(_.get(integration, 'apikey', false));
  const [error, setError] = useState('');

  useEffect(() => {
    handleRestClick();
  }, [integration]);

  useEffect(() => {
    if(errors && errors.errors) {
      setError('Please provide valid Freshmarketer API key.');
    }
  }, [errors]);

  const handleBackClick = () => {
    history.push(`${baseUrl}/settings/integrations`);
  }

  const validateForm = () => {
    if(!domain || domain.trim().length === 0) {
      return 'Please provide valid Freshmarketer domain name.';
    }

    if(!apikey || apikey.trim().length === 0) {
      return 'Please provide valid Freshmarketer API key.'
    }

    return null;
  }

  const handleSaveClick = () => {
    const errorMessage = validateForm();
    if(errorMessage) {
      setError(errorMessage);
      return;
    }

    let request = {
      domain: domain,
      apikey: apikey
    }

    dispatch(authenticateFreshMarketer(request, (integration) => {
      dispatch(activatingIntegrationSuccedded(integration));
      setCurrent(true);
    }));
  }

  const handleRestClick = () => {
    setDomain(_.get(integration, 'configuration.domainName', ''));
    setCurrent(_.get(integration, 'apikey', false));
  }

  const handleDomainChange = (e) => {
    setDomain(e.target.value);
  }

  const handleApikeyChange = (e) => {
    setApikey(e.target.value);
  }

  const handleDeleteClick = () => {
    dispatch(deactivateIntegration('freshmarketer', (res) => {
      handleBackClick();
    }))
  }

  return (
    <Grid columns="equal" className={styles.freshmarketer}>
      <Grid.Row>
        <Grid.Column width={5} className={styles.detailsView}>
          <Menu secondary>
            <Button className={styles.backButton} onClick={handleBackClick}>
              <Icon name={'angle left'} />BACK
            </Button>
          </Menu>

          <div className={styles.details}>
            <Image src={`/images/integrations/freshmarketer.png`} />
            <Header as='h2'>Freshmarketer</Header>
            <p className={styles.description}>
              Integrate with Allocadia to sync events across contacts
            </p>
            
            {integration && integration.state.code === 'ok' &&
              <InlineConfirmationDialog onConfirm={handleDeleteClick} message={'Are you sure to deactivate freshmarketer?'}>
                <Button>{deactivating ? 'DEACTIVATING...' : 'DEACTIVATE'}</Button>
              </InlineConfirmationDialog>
            }
          </div>
        </Grid.Column>
        <Grid.Column className={styles.formView}>
          <Segment basic className={styles.formSegment}>
            <div className={cx(styles.errorMessage, {[styles.active]: error})}>
              <p><Icon name="exclamation circle"></Icon> {error}</p>
            </div>
            <Form className={styles.form} autoComplete="new-password">
              <Form.Field>
                <Input labelPosition='right' 
                  type='text' 
                  autoComplete='new-password' 
                  placeholder='Enter your domain name'
                  value={domain}
                  onChange={handleDomainChange}>
                  <Label basic>DOMAIN NAME</Label>
                  <input />
                  <Label>.freshmarketer.com</Label>
                </Input>
              </Form.Field>

              <ProtectedPasswordField value={apikey} onChange={handleApikeyChange}
                current={current} setCurrent={setCurrent} />
            </Form>

            <div className={styles.actions}>
              <Button onClick={handleSaveClick}>
                <Icon loading={saving} name={saving ? 'spinner' : 'save'} />
                {saving ? 'Saving...' : 'Save Changes'}
              </Button>
              <Button onClick={handleRestClick}>
                Reset
              </Button>
            </div>
          </Segment>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  )
}

function ProtectedPasswordField({value, onChange, current, setCurrent, ...rest}) {

  const [show, setShow] = useState(false);

  const handleTogglePassword = () => {
    setShow(!show);
  }

  const handleChangeAPIKey = () => {
    setCurrent(false);
  }

  return (
    <>
      {!current && 
        <Form.Field>
        <Input labelPosition='right' 
            type={show ? 'text' : 'password'}
            autoComplete='new-password'
            placeholder='Enter your API key'
            value={value}
            onChange={onChange}>
            <Label basic>API KEY</Label>
            <input />
            <Label basic onClick={handleTogglePassword}><Icon name='eye'/></Label>
          </Input>
          <span className={styles.helpText}>To find your API key, Click your Profile picture and select Settings and click the API settings tab.</span>
        </Form.Field>
      }

      {current && 
        <Form.Field>
          <Input labelPosition='right' 
            type={'text'}
            autoComplete='new-password'
            placeholder='Enter your API key'
            value="******"
            disabled>
            <Label basic>API KEY</Label>
            <input />
            <Label basic onClick={handleChangeAPIKey} className={styles.apiKeyButton}>CHANGE API KEY</Label>
          </Input>
          <span className={styles.helpText}>To find your API key, Click your Profile picture and select Settings and click the API settings tab.</span>
        </Form.Field>
      }
    </>
  )
}