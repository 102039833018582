import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import {
  Menu,
  Grid
} from 'semantic-ui-react';

import {
  NavLink,
  BrowserRouter as Router,
  Route,
  Switch,
  useRouteMatch
} from "react-router-dom";

import ProfileView from './profile';
import CustomFieldsView from './customFields';
import IntegrationsView from './integrations';
import UserManagementView from './userManagement'
import Freshmarketer from './integrations/freshmarketer';
import Pardot from './integrations/pardot';
import Hubspot from './integrations/hubspot';
import Zoho from './integrations/zoho';
import TemplatesPage from 'pages/pages/templates/assets';
import GatingsView from 'pages/pages/gatings';
import LiveElementsPage from 'pages/live_elements/';
import LiveElementEditor from 'pages/live_elements/editor';
import AssetViewersPage from 'pages/asset_viewer/';
import AssetViewerEditor from 'pages/asset_viewer/editor';
import UrlManagementView from 'pages/pages/urlManagement'
import FormEditor from 'pages/form/editor';
import FormPage from 'pages/form';
import Marketo from './integrations/marketo'
import SixSense from './integrations/six-sense';


import _ from 'lodash';

import styles from './Settings.module.scss';

import { fetchIntegrations } from 'actions/integrations/integrations';

export default function SettingsView() {
  const dispatch = useDispatch();
  let { path, url } = useRouteMatch();

  useEffect(() => {
    dispatch(fetchIntegrations());
  }, [])
  
  return (
    <Switch>
      <Route path={`${path}/integrations/freshmarketer`} exact>
        <Freshmarketer />
      </Route>
      <Route path={`${path}/integrations/pardot`} exact>
        <Pardot />
      </Route>
      <Route path={`${path}/integrations/hubspot`} exact>
        <Hubspot />
      </Route>
      <Route path={`${path}/integrations/zoho`} exact>
        <Zoho />
      </Route>  
      <Route path={`${path}/integrations/marketo`} exact>
        <Marketo />
      </Route>
      <Route path={`${path}/integrations/six-sense`} exact>
        <SixSense />
      </Route>
      <Route path={`${path}/live_elements/:id/edit`} exact>
        <LiveElementEditor />
      </Route>
      <Route path={`${path}/forms/:id/edit`} exact>
        <FormEditor />
      </Route>
      <Route path={`${path}/asset_viewers/:id/edit`}>
        <AssetViewerEditor />
      </Route>
      <Route path={path}>
        <Grid columns="equal" stretched className={styles.content}>
          <Grid.Row>
            <Grid.Column width={2}>
              <Menu pointing vertical secondary borderless className={styles.secondaryMenu}>
                <Menu.Item as={NavLink} name='Profile' to={`${url}/profile`} />
                <Menu.Item as={NavLink} name='User Management' to={`${url}/user_management/users`} />
                <Menu.Item as={NavLink} name='CustomFields' to={`${url}/custom_fields`} />
                <Menu.Item as={NavLink} name='Integrations' to={`${url}/integrations`} />
                <Menu.Item as={NavLink} name='Asset Cards' to={`${url}/templates/assets`} />
                <Menu.Item as={NavLink} name='Gating' to={`${url}/gating`} />
                <Menu.Item as={NavLink} name='Live Elements' to={`${url}/live_elements`} />
                <Menu.Item as={NavLink} name='Forms' to={`${url}/forms`} />
              </Menu>
            </Grid.Column>
            <Grid.Column>
              <Switch>
                <Route path={`${path}/profile`}>
                  <ProfileView />
                </Route>
                <Route path={`${path}/user_management`}>
                  <UserManagementView />
                </Route>
                <Route path={`${path}/custom_fields`}>
                  <CustomFieldsView />
                </Route>
                <Route path={`${path}/integrations`} exact>
                  <IntegrationsView />
                </Route>
                <Route path={`${path}/templates/assets`} component={TemplatesPage} />
                <Route path={`${path}/gating`} component={GatingsView} />
                <Route path={`${path}/live_elements`} component={LiveElementsPage} />
                <Route path={`${path}/forms`} component={FormPage} />
              </Switch>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Route>
    </Switch>
  )
}