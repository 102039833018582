import {
  FETCH_FORMS_REQUEST, FETCHING_FORMS_SUCCEEDED, FETCHING_FORMS_FAILED, 
  FETCH_FORM_REQUEST, FETCHING_FORM_SUCCEEDED, FETCHING_FORM_FAILED,
  INVALIDATE_FORM_SELECTION,
  CREATE_FORM_REQUEST, CREATING_FORM_SUCCEEDED, CREATING_FORM_FAILED, 
  CLONE_FORM_REQUEST, CLONING_FORM_SUCCEEDED, CLONING_FORM_FAILED, 
  UPDATE_FORM_REQUEST, UPDATING_FORM_SUCCEEDED, UPDATING_FORM_FAILED, 
  PUBLISH_FORM_REQUEST, PUBLISHING_FORM_SUCCEEDED, PUBLISHING_FORM_FAILED, 
  RESTORE_FORM_REQUEST, RESTORING_FORM_SUCCEEDED, RESTORING_FORM_FAILED,
  DELETE_FORM_REQUEST, DELETING_FORM_SUCCEEDED, DELETING_FORM_FAILED
} from 'constants/actionTypes';

import _ from 'lodash';

const initialState = {
  loading: false,
  items: [],
  pagination: {
    currentPage: -1,
    totalPages: 1,
    recordsPerPage: 20,
    totalRecordsCount: 0
  },
  form: {
    loading: false,
    item: null
  },
  create: {
    creating: false,
    item: null,
    error: null
  },
  clone: {
    cloning: false,
    item: null,
    error: null
  }
}

const initialAnalyticsState = {
  loading: false,
  items: []
}

function formReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_FORMS_REQUEST:
      const { page } = action.payload;

      return {
        ...state,
        loading: true
      }

    case FETCHING_FORMS_SUCCEEDED:
      var items = state.items;
      var { currentPage, totalPages, recordsPerPage, totalRecordsCount, records = [] } = action.payload.response;
      var selectedForm = action.payload.selectedForm;

      if (currentPage !== 0) {
        items = [...state.items, ...records];
      } else {
        items = records
      }

      items = items.reduce((x, y) => {
        var index = x.findIndex((e) => {
          return e.id === y.id;
        });

        if (index < 0) {
          return [...x, y];
        } else {
          x[index] = y;
          return x;
        }
      }, []);

      if(selectedForm && !_.includes(items, selectedForm)) {
        items.push(selectedForm);
      }

      return {
        ...state,
        loading: false,
        items: items,
        pagination: {
          ...state.pagination,
          currentPage,
          totalPages,
          recordsPerPage,
          totalRecordsCount
        }
      }

    case FETCHING_FORMS_FAILED:
      const { error } = action.payload;

      return {
        ...state,
        loading: false,
        error: error
      }

    case CREATE_FORM_REQUEST: 
      return {
        ...state,
        create: {
          creating: true,
          item: action.payload.request
        }
      }

    case CREATING_FORM_SUCCEEDED:
      var items = [action.payload, ...state.items];

      return {
        ...state,
        items: items,
        create: {
          creating: false,
          item: action.payload,
          error: null
        }
      }

    case CREATING_FORM_FAILED:
      return {
        ...state,
        create: {
          creating: false,
          item: null,
          error: action.payload
        }
      }

    case CLONE_FORM_REQUEST: 
      var { id } = action.payload;

      return {
        ...state,
        clone: {
          element: id,
          cloning: true
        }
      }

    case CLONING_FORM_SUCCEEDED:
      items = [action.payload, ...state.items];

      return {
        ...state,
        items: items,
        clone: {
          cloning: false,
          item: action.payload,
          error: null
        }
      }

    case CLONING_FORM_FAILED:
      return {
        ...state,
        clone: {
          ...state.clone,
          cloning: false,
          item: null,
          error: action.payload
        }
      }

    case FETCH_FORM_REQUEST:
      return {...state,
        form: {
          ...state.form,
          loading: true,
          error: null
        }
      }

    case FETCHING_FORM_SUCCEEDED:
      return {...state,
        form: {
          ...state.form,
          loading: false,
          item: action.payload,
          error: null
        },
        create: {
          creating: false,
          item: null,
          error: null 
        },
        clone: {
          cloning: false,
          item: null,
          error: null 
        }
      }

    case FETCHING_FORM_FAILED:
      return {
        ...state,
        form: {
          ...state.form,
          loading: false,
          error: action.payload
        }
      }

    case INVALIDATE_FORM_SELECTION: 
      return {
        ...state,
        form: {
          ...state.form,
          item: null
        }
      }

    case UPDATE_FORM_REQUEST:
      return {
        ...state,
        form: {
          ...state.form,
          updating: true,
          error: null
        }
      }

    case UPDATING_FORM_SUCCEEDED:
      return {
        ...state,
        form: {
          ...state.form,
          updating: false,
          item: action.payload,
          error: null
        }
      }

    case UPDATING_FORM_FAILED:
      return {
        ...state,
        form: {
          ...state.form,
          updating: false,
          error: action.payload
        }
      }

    case PUBLISH_FORM_REQUEST:
      return {...state,
        form: {
          ...state.form,
          publishing: true,
          error: null
        }
      }

    case PUBLISHING_FORM_SUCCEEDED:
      return {
        ...state,
        form: {
          ...state.form,
          publishing: false,
          item: action.payload,
          error: null
        }
      }

    case PUBLISHING_FORM_FAILED:
      return {...state,
        form: {
          ...state.form,
          publishing: false,
          error: action.payload
        }
      }  

    case RESTORE_FORM_REQUEST:
      return {
        ...state,
        form: {
          ...state.form,
          restoring: true,
          error: null
        }
      }

    case RESTORING_FORM_SUCCEEDED:
      return {
        ...state,
        form: {
          ...state.form,
          restoring: false,
          item: action.payload,
          error: null
        }
      }

    case RESTORING_FORM_FAILED:
      return {
        ...state,
        form: {
          ...state.form,
          restoring: false,
          error: error
        }
      }

    case DELETE_FORM_REQUEST:
      return state;

    case DELETING_FORM_SUCCEEDED:
      var { id } = action.payload;

      var items = state.items.filter(item => {
        return item.id !== id;
      })

      return {
        ...state,
        items: items,
        form: {
          loading: false,
          item: null,
          error: null
        }
      }

    case DELETING_FORM_FAILED:
      return state;

    default: 
      return state;
  }
}

export { formReducer };