import {
  FETCH_STORYBOARDS_REQUEST, FETCHING_STORYBOARDS_SUCCEEDED, FETCHING_STORYBOARDS_FAILED,
  FETCH_STORYBOARD_REQUEST, FETCHING_STORYBOARD_SUCCEEDED, FETCHING_STORYBOARD_FAILED,
  SWITCH_STORYBOARD_REQUEST, SWITCH_STORYBOARD
} from 'constants/actionTypes.jsx';

import { logoutUser } from './users';

import { call, put, takeEvery, takeLatest } from 'redux-saga/effects';
import axios from 'axios';
import { createAction } from 'redux-actions';
import buildUrl from 'build-url';
import AsyncLocalStorageService from '../services/localstorage.async.service';
import AuthInterceptor from "services/auth.interceptor";
import { act } from 'react-dom/test-utils';

const apiUrl = process.env.REACT_APP_API_URL;
const STORYBOARD_URL = `${apiUrl}/api/2.0/resource_center`;

/**
 * Fetch storyboards for account
*/
export const fetchStoryboards = createAction(FETCH_STORYBOARDS_REQUEST);
const fetchingStoryboardsSucceeded = createAction(FETCHING_STORYBOARDS_SUCCEEDED);
const fetchingStoryboardsFailed = createAction(FETCHING_STORYBOARDS_FAILED);

function* fetchStoryboardsSaga() {
  const fetchStoryboardsAPI = () => {
    return axios.get(STORYBOARD_URL).then(response => response.data);
  }

  try {
    const response = yield call(fetchStoryboardsAPI);
    yield put(fetchingStoryboardsSucceeded(response));
  } catch (error) {
    yield put(fetchingStoryboardsFailed(error));
  }
}

/**
 * Fetch storyboard for account
*/
export const fetchStoryboard = createAction(FETCH_STORYBOARD_REQUEST);
const fetchingStoryboardSucceeded = createAction(FETCHING_STORYBOARD_SUCCEEDED);
const fetchingStoryboardFailed = createAction(FETCHING_STORYBOARD_FAILED);

function* fetchStoryboardSaga(action) {
  const id = action.payload;

  const fetchStoryboardAPI = () => {
    return axios.get(`${STORYBOARD_URL}/${id}`).then(response => response.data);
  }

  try {
    const response = yield call(fetchStoryboardAPI);
    yield put(fetchingStoryboardSucceeded(response));
  } catch (error) {
    yield put(logoutUser());
    yield put(fetchingStoryboardFailed(error));
  }
}

/**
 * Switch storyboard
*/
export const switchStoryboard = createAction(SWITCH_STORYBOARD_REQUEST);
export const switchStoryboardSucceeded = createAction(SWITCH_STORYBOARD);

function* switchStoryboardSaga(action) {
  const storyboard = action.payload;
  AsyncLocalStorageService.setItem('appId', storyboard.id);
  AuthInterceptor.updateAppId();
  yield put(switchStoryboardSucceeded(action.payload));
}

export default function* storyboardSaga() {
  yield takeLatest(FETCH_STORYBOARDS_REQUEST, fetchStoryboardsSaga);
  yield takeEvery(FETCH_STORYBOARD_REQUEST, fetchStoryboardSaga);
  yield takeEvery(SWITCH_STORYBOARD_REQUEST, switchStoryboardSaga);
};