import {
  AUTHENTICATE_FRESHMARKETER_REQUEST, AUTHENTICATE_FRESHMARKETER_SUCCEEDED, AUTHENTICATE_FRESHMARKETER_FAILED,
  FETCHING_FRESHMARKETER_LISTS, FETCHING_FRESHMARKETER_LISTS_SUCCEEDED, FETCHING_FRESHMARKETER_LISTS_FAILED
} from 'constants/actionTypes';

const initialState = {
  changes: {
    saving: false,
    error: null
  },
  lists: {
    loading: false,
    items: [],
    pagination: {
      current: -1,
      totalPages: 1,
      totalRecords: 0
    }
  }
};

function freshmarketerReducer(state = initialState, action) {
  switch (action.type) {
    case AUTHENTICATE_FRESHMARKETER_REQUEST:
      return {
        ...state,
        changes: {
          saving: true,
          error: null
        }
      }

    case AUTHENTICATE_FRESHMARKETER_SUCCEEDED:
      return {
        ...state,
        changes: {
          ...state.changes,
          saving: false,
          error: null
        }
      }

    case AUTHENTICATE_FRESHMARKETER_FAILED: 
      return {
        ...state,
        changes: {
          ...state.changes,
          saving: false,
          error: action.payload
        }
      }

    case FETCHING_FRESHMARKETER_LISTS:
      return {
        ...state,
        lists: {
          ...state.lists,
          loading: true
        }
      }

    case FETCHING_FRESHMARKETER_LISTS_SUCCEEDED:
      var { currentPage, totalPages, totalRecordsCount, records} = action.payload;

      var items = state.lists.items;

      if(currentPage === 1) {
        items = records;
      } else {
        items = [...state.lists.items, ...records];
      }

      items = items.reduce((x, y) => {
        var index = x.findIndex((e) => {
          return e.id === y.id;
        });

        if (index < 0) {
          return [...x, y];
        } else {
          x[index] = y;
          return x;
        }
      }, []);

      return {
        ...state,
        lists: {
          loading: false,
          items: items,
          pagination: {
            ...state.pagination,
            current: currentPage,
            totalPages: totalPages,
            totalRecords: totalRecordsCount
          }
        }
      }

    case FETCHING_FRESHMARKETER_LISTS_FAILED:
      return {
        ...state,
        lists: {
          ...state.lists,
          loading: false,
          error: action.payload
        }
      }

    default: 
      return state;
  }
}

export { freshmarketerReducer }