export const integrations = [
  {
    type: 'freshmarketer',
    name: 'Freshmarketer',
    category: 'MARKETING_AUTOMATION',
    description: 'Integrate with Freshmarketer to sync events across contacts',
    icon: '/images/integrations/freshmarketer.png',
    route: '/freshmarketer',
    adminOnly: true
  },
  {
    type: 'pardot',
    name: 'Pardot',
    category: 'MARKETING_AUTOMATION',
    description: 'Integrate with Pardot to sync events across contacts',
    icon: '/images/integrations/pardot.png',
    route: '/pardot',
    adminOnly: true
  },
  {
    type: 'hubspot',
    name: 'Hubspot',
    category: 'CRM',
    description: 'Integrate hubspot CRM to sync events across contacts',
    icon: '/images/integrations/hubspot.svg',
    route: '/hubspot',
    adminOnly: true
  },
  {
    type: 'zoho',
    name: 'Zoho',
    category: 'CRM',
    description: 'Integrate zoho CRM to sync events across contacts',
    icon: '/images/integrations/zoho.png',
    route: '/zoho',
  },
  {
    type: 'marketo',
    name: 'Marketo',
    category: 'MARKETING_ATTRIBUTION',
    description: 'Integrate with Marketo to sync events across contacts',
    icon: '/images/integrations/marketo.png ',
    route: '/marketo',
    adminOnly: true
  },
  {
    type: 'six_sense',
    name: 'Six Sense',
    category: 'SIX_SENSE',
    description: 'Integrate with Six Sense to track analytics',
    icon: '/images/integrations/sixsense.png ',
    route: '/six-sense',
    adminOnly: true
  }
]