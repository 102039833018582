import { 
  FETCHING_HUB_STREAMS_REQUEST, FETCHING_HUB_STREAMS_SUCCEEDED, FETCHING_HUB_STREAMS_FAILED,
  FETCHING_STREAM_REQUEST, FETCHING_STREAM_SUCCEEDED, FETCHING_STREAM_FAILED,
  FETCHING_GROUP_REQUEST, FETCHING_GROUP_SUCCEEDED, FETCHING_GROUP_FAILED
}
  from 'constants/actionTypes.jsx';

const initialState = {
    loading: false,
    invalidated: true,
    items: [],
    stream: {},
    group: {}
}

const entityState = {
  loading: false,
  item: null
}

function hubReducer(state = initialState, action) {
    switch (action.type) {
      case FETCHING_HUB_STREAMS_REQUEST:
        return Object.assign({}, state, {
          loading: true,
          invalidated: true
        })
      case FETCHING_HUB_STREAMS_SUCCEEDED:
        return Object.assign({}, state, {
          loading: false,
          invalidated: false,
          items: action.payload.sort((a, b) => {
            return a.name > b.name ? 1 : a.name < b.name ? -1 : 0;
          })
        })
      case FETCHING_HUB_STREAMS_FAILED:
        return Object.assign({}, state, {
          loading: false,
          invalidated: false
        })

      case FETCHING_STREAM_REQUEST:
        var id = action.payload.id;
        var stream = state.stream[id] || entityState;
  
        return Object.assign({}, state, {
          ...state,
          stream: {
            ...state.stream,
            [id]: {
              ...stream,
              loading: true,
              invalidated: true
            }
          }
        })

      case FETCHING_STREAM_SUCCEEDED:

        var id = action.payload.stream.id;
        var stream = state.stream[id] || entityState;

        var items = action.payload.stream.children || [];

        var sortedSections = items.filter((item) => {
          return item.entityType === 'section';
        }).sort((a, b) => {
          return new Date(a.createdDate) - new Date(b.createdDate);
        });

        var sortedGroup = items.filter((item) => {
          return item.entityType === 'group';
        })

        var sortedAsset = items.filter((item) => {
          return item.entityType === 'asset';
        })

        var sortedChildren = [...sortedSections, ...sortedGroup, ...sortedAsset];

        return Object.assign({}, state, {
          ...state,
          stream: {
            ...state.stream,
            [id]: {
              ...stream,
              loading: false,
              invalidated: false,
              item: {
                ...action.payload.stream,
                children: sortedChildren
              }
            }
          }
        })

      case FETCHING_STREAM_FAILED:
        var id = action.payload.id;
        var stream = state.stream[id] || entityState;
        return Object.assign({}, state, {
          ...state,
          stream: {
            ...state.stream,
            [id]: {
              ...stream,
              loading: false,
              invalidated: false,
              item: null,
              errors: action.payload.error.errors
            }
          }
        })

      case FETCHING_GROUP_REQUEST:
        var id = action.payload.id;
        var group = state.group[id] || entityState;

        return Object.assign({}, state, {
          ...state,
          group: {
            ...state.group,
            [id]: {
              ...group,
              loading: true,
              invalidated: true
            }
          }
        })

      case FETCHING_GROUP_SUCCEEDED:
        var id = action.payload.id;
        var group = state.group[id] || entityState;

        var items = action.payload.group.children || [];

        var sortedSections = items.filter((item) => {
          return item.entityType === 'section';
        }).sort((a, b) => {
          return new Date(a.createdDate) - new Date(b.createdDate);
        });

        var sortedGroup = items.filter((item) => {
          return item.entityType === 'group';
        })

        var sortedAsset = items.filter((item) => {
          return item.entityType === 'asset';
        })

        var sortedChildren = [...sortedSections, ...sortedGroup, ...sortedAsset];

        return Object.assign({}, state, {
          ...state,
          group: {
            ...state.group,
            [id]: {
              ...group,
              loading: false,
              invalidated: false,
              item: {
                ...action.payload.group,
                children: sortedChildren
              }
            }
          }
        })

      case FETCHING_GROUP_FAILED:
        var id = action.payload.id;
        var group = state.group[id] || entityState;

        return Object.assign({}, state, {
          ...state,
          group: {
            ...state.group,
            [id]: {
              ...group,
              loading: false,
              invalidated: true
            }
          }
        })
      default:
      return state
    }
}

export {hubReducer};