import {
  FETCHING_ASSETS_FOR_SESSION, FETCHING_ASSETS_FOR_SESSION_SUCCEEDED, FETCHING_ASSETS_FOR_SESSION_FAILED,
  FETCHING_SESSION_ASSET_ANALYITCS, FETCHING_SESSION_ASSET_ANALYITCS_SUCCEEDED, FETCHING_SESSION_ASSET_ANALYITCS_FAILED,
  UPDATE_FILTER_FOR_REPORTS
} from 'constants/actionTypes.jsx';

import { createAction } from 'redux-actions';
import axios from 'axios';
import { call, put, takeLatest, fork } from 'redux-saga/effects';

const apiUrl = process.env.REACT_APP_API_URL;
const API_BASE_URL = `${apiUrl}/api/2.1`;
const REPORTS_URL = `${API_BASE_URL}/resource_center/reports`;
const REPORTS2_URL = `${apiUrl}/api/2.0/resource_center/reports`;

export const fetchVisitorSession = createAction(FETCHING_ASSETS_FOR_SESSION, (id) => {
  return {id}
});

export const fetchingVisitorSessionSucceeded = createAction(FETCHING_ASSETS_FOR_SESSION_SUCCEEDED, (id, response) => {
  return  {
    id: id,
    response: response
  }
});

export const fetchingVisitorSessionFailed = createAction(FETCHING_ASSETS_FOR_SESSION_FAILED, (id, error) => {
  return {
    id: id,
    error: error
  }
}); 

function* fetchAssetsForVisitorSessionSaga(action) {

  const id = action.payload.id

  const fetchingVisitorSession = () => {
    return axios.get(`${REPORTS2_URL}/sessions/${id}`
    ).then(response => response.data);
  }

  try {
    const response = yield call(fetchingVisitorSession);
    yield put(fetchingVisitorSessionSucceeded(id, response));
  } catch (error) {
    yield put(fetchingVisitorSessionFailed(id, error));
  }
}

export const fetchVisitorAssetAnalytics = createAction(FETCHING_SESSION_ASSET_ANALYITCS, ({sessionId, assetSessionId, callback}) => {
  return {sessionId, assetSessionId, callback}
});

export const fetchingVisitorAssetAnalyticsSucceeded = createAction(FETCHING_SESSION_ASSET_ANALYITCS_SUCCEEDED, (sessionId, assetSessionId, json)=>{
  return {sessionId, assetSessionId, json}
});

export const fetchingVisitorAssetAnalyticsFailed = createAction(FETCHING_SESSION_ASSET_ANALYITCS_FAILED); 

function* fetchVisitorAssetAnalyticsSaga(action) {

  const assetSessionId = action.payload.assetSessionId;

  const callback = action.payload.callback;

  const fetchingVisitorAssetAnalytics = () => {
    return axios.get(`${REPORTS2_URL}/sessions/${assetSessionId}/analytics`
    ).then(response => response.data);
  }

  try {
    const response = yield call(fetchingVisitorAssetAnalytics);
    yield put(fetchingVisitorAssetAnalyticsSucceeded(action.payload.sessionId, action.payload.assetSessionId, response));
    callback(response);
  } catch (error) {
    yield put(fetchingVisitorAssetAnalyticsFailed(error));
  }
}

export const updateFiltersForReports = createAction(UPDATE_FILTER_FOR_REPORTS, (filters) => {
  return filters;
})

export default function* reportsSaga() {
  yield takeLatest(FETCHING_ASSETS_FOR_SESSION, fetchAssetsForVisitorSessionSaga);
  yield takeLatest(FETCHING_SESSION_ASSET_ANALYITCS, fetchVisitorAssetAnalyticsSaga);
};

