import React from "react";
import { connect } from 'react-redux'
import cx from 'classnames';

import styles from "./HtmlViewer.module.scss";

import {
  Image, Loader
} from 'semantic-ui-react';

import _ from 'lodash';

import UrlIcon from "images/invalidFileType/url.jpg";
import linkIcon from "images/htmlViewer/icon.png";

import { fetchHtmlPreview } from "actions/content";


class HtmlView extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    if (this.props.asset.viewerMode === 'render_optimized_view') {
      var previewUrl = this.props.asset.previewUrl;

      if(!previewUrl) {
        previewUrl = this.props.asset.htmlPreviewUrl;
      }

      if (previewUrl) {
        this.props.fetchHtmlPreview(this.props.contextId, previewUrl);
      }
    }
  }

  render() {
    const loading = _.get(this.props, 'preview.loading');
    return (
      <div className={cx(styles.viewer, {
        [styles.inline]: this.props.inline
      })}>
        {this._renderViewer(this.props.asset)}
        <Loader active={loading}>Generating Preview...</Loader>
      </div>
    );
  }

  _renderViewer(asset) {
    switch (this.props.asset.viewerMode) {
      case 'render_in_viewer':
        if (asset.preview) {
          var url = asset.metadata.url;
          return this._renderUrlInIFrame(url);
        }

        return this._renderDefaultViewer();
      case 'render_optimized_view':
        var html = this.props.preview ? this.props.preview.htmlPreview : null;
        if (html) {
          return this._renderHTMLInIFrame(html);
        } else {
          return this._renderDefaultViewer()
        }
        
      case 'open_in_new_tab':
      default:
        return this._renderDefaultViewer()
    }
  }

  _renderUrlInIFrame(url) {
    return (
      // eslint-disable-next-line jsx-a11y/iframe-has-title
      <iframe
        sandbox="allow-scripts allow-forms allow-same-origin allow-popups allow-popups-to-escape-sandbox"
        src={url}
        width="100%"
        height="100%"
      />
    );
  }

  _renderHTMLInIFrame(html) {
    return (
      // eslint-disable-next-line jsx-a11y/iframe-has-title
      <iframe
        sandbox="allow-scripts allow-forms allow-same-origin allow-popups allow-popups-to-escape-sandbox"
        srcDoc={html}
        width="100%"
        height="100%"
      />
    );
  }

  _renderDefaultViewer() {
    var icon = this.props.asset.icon
      ? this.props.asset.icon.full
      : UrlIcon;

    return (
      <div className={styles.defaultViewer}>
        <a href={this.props.asset.metadata.url} target="_blank">
          <div className={styles.icon}>
            <Image src={icon} />
          </div>
          <Image src={linkIcon} className={styles.linkIcon} />
          <div className={styles.name}>{this.props.asset.name}</div>
          <div className={styles.summary} dangerouslySetInnerHTML={{ __html: this.props.asset.summary }}></div>
        </a>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  var assetState = state.asset[ownProps.contextId];
  return {
    preview: assetState ? assetState.preview : null
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    fetchHtmlPreview: (contextId, url) => {
      return dispatch(fetchHtmlPreview(contextId, url));
    }
  }
}

const HtmlViewer = connect(
  mapStateToProps,
  mapDispatchToProps
)(HtmlView)

export default HtmlViewer;