import { 
  FETCH_STORYBOARDS_REQUEST, FETCHING_STORYBOARDS_SUCCEEDED, FETCHING_STORYBOARDS_FAILED, 
  SWITCH_STORYBOARD,
  FETCH_STORYBOARD_REQUEST, FETCHING_STORYBOARD_SUCCEEDED, FETCHING_STORYBOARD_FAILED
} from 'constants/actionTypes.jsx';

const initialState = {
  loading: false,
  invalidated: false,
  items: [],
  resourceCenter: {
    loading: false,
    item: null
  }
}

export function storyboardReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_STORYBOARDS_REQUEST:
      return Object.assign({}, state, {
        ...state,
        loading: true,
        invalidated: true
      })
    
    case FETCHING_STORYBOARDS_SUCCEEDED:
      var items = action.payload
      return Object.assign({}, state, {
        ...state,
        loading: false,
        invalidated: false,
        items: items
      })

    case FETCHING_STORYBOARDS_FAILED:
      return Object.assign({}, state, {
        ...state,
        loading: false,
        invalidated: false,
        items: []
      })
    
    case FETCH_STORYBOARD_REQUEST:
      return Object.assign({}, state, {
        ...state,
        storyboard: {
          ...state.storyboard,
          loading: true
        }
      })
    
    case FETCHING_STORYBOARD_SUCCEEDED:
      var item = action.payload
      return Object.assign({}, state, {
        ...state,
        storyboard: {
          ...state.storyboard,
          loading: false,
          item: item
        }
      })

    case FETCHING_STORYBOARD_FAILED:
      return Object.assign({}, state, {
        ...state,
        storyboard: {
          ...state.storyboard,
          loading: false,
          item: null
        }
      })

    case SWITCH_STORYBOARD:
      var storyboard = action.payload;
    
      return Object.assign({}, state, {
        ...state,
        loading: false,
        invalidated: false,
        storyboard: {
          item: storyboard
        }
      })

    default:
      return state
  }
}
