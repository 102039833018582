import React, { useState, Fragment } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fetchAssetsForWidget, updateWidget, updateProperties } from 'actions/widgetEditor';

import { QuickSettings, QuickSettingsProperty } from 'components/QuickSettings';
import { Property } from 'components/Properties';
import { WidgetContext } from 'pages/pages/editor/editor.jsx';

import cx from 'classnames';

import { ContentModal } from './content';
import { FiltersModal } from './filters';
import { WidgetRegistry } from '../registry';

import _ from 'lodash';

import {
  Button,
  Tab
} from 'semantic-ui-react';

import styles from "./assets.module.scss";

const layouts = [{
  type: 'grid',
  label: 'Grid',
  properties: {
    layout: {
      columns: 4
    },
    pagination: true,
    itemsPerPage: 10
  }
}, {
  type: 'metro',
  label: 'Metro',
  properties: {
    layout: {
      columns: 4
    },
    pagination: true,
    itemsPerPage: 10
  }
}, {
  type: 'carousel',
  label: 'Carousel',
  properties: {
    layout: {
      infinite: true,
      arrows: true,
      autoPlay: false,
      autoPlayDuration:3000
    },
    pagination: true,
    itemsPerPage: 5
  }
}, {
  type: 'inline',
  label: 'Inline',
  properties: {
    layout: {

    },
    pagination: false,
    itemsPerPage: 1
  }
}];

const menuItems = [
  { id: 'layout', title: 'Layout', icon: 'icon-layout' },
  { id: 'content', title: 'Content', icon: 'icon-content' },
  { id: 'gating', title: 'Gating', icon: 'icon-custom' },
  { id: 'spacing', title: 'Spacing', icon: 'icon-spacing' },
  { id: 'background', title: 'Background', icon: 'icon-background' },
  { id: 'border', title: 'Border', icon: 'icon-border' },
  { id: 'advanced', title: 'Advanced', icon: 'icon-custom' }
]

export default function AssetsQuickSettings(props) {
  const dispatch = useDispatch();

  const [selected, setSelected] = useState('layout');
  const { entityReference } = React.useContext(WidgetContext);

  const handleMenuClick = (item) => {
    setSelected(item);
  }

  var widget = useSelector(state => {
    var widgetEditor = state.widgetsEditor.editors[props.editor];

    if (widgetEditor) {
      return widgetEditor.widgetsById[props.widget.id];
    }

    return null;
  });

  if (!widget) {
    return false;
  }

  var properties = widget.properties;

  const handleChange = (changeRequest) => {
    dispatch(updateProperties({
      id: props.id,
      change: changeRequest,
      context: props.editor
    }));
  }

  const handleBackgroundImageChange = (media, type) => {
    var changeRequest = { ...widget };
    _.set(changeRequest, 'properties.background.upload', {
      type: type,
      data: media
    });

    changeRequest = WidgetRegistry.processRequest([changeRequest])[0];
    dispatch(updateWidget(widget.id, changeRequest, props.editor));
  }

  const handleCssChange = (css) => {
    var changeRequest = { ...widget };
    _.set(changeRequest, 'properties.css', css);

    changeRequest = WidgetRegistry.processRequest([changeRequest])[0];
    dispatch(updateWidget(widget.id, changeRequest, props.editor));
  }

  return (
    <QuickSettings menuItems={menuItems}
      selected={selected} onMenuClick={handleMenuClick}
      segmentClassName={selected === 'filtersBorder' ? styles.tabStyleSettings : null} >
      {selected === 'spacing' &&
        <>
          <QuickSettingsProperty.Spacing
            title='Margin'
            value={properties.margin}
            onChange={(margin) => {
              handleChange({
                margin: margin
              });
            }}
          />
          <QuickSettingsProperty.Spacing
            title='Padding'
            value={properties.padding}
            onChange={(padding) => {
              handleChange({
                padding: padding
              });
            }}
          />
        </>
      }

      {selected === 'background' &&
        <QuickSettingsProperty.Color
          title='Color'
          value={_.get(properties, 'background.color')}
          onChange={(value) => {
            handleChange({
              background: {
                color: value
              }
            })
          }}
        />
      }

      {selected === 'border' &&
        <QuickSettingsProperty.Border expanded value={properties.border} onChange={(border) => {
          handleChange({
            border: border
          });
        }}
        />
      }

      {selected === 'layout' &&
        <>
          <Layout value={properties.layout} onChange={handleChange} />
          {properties.layout.type !== 'inline' &&
            <AssetTemplateProperty value={properties.template} onChange={handleChange} />
          }
        </>
      }

      {selected === 'content' &&
        <Content widget={widget} onChange={handleChange} editor={props.editor} entityReference={entityReference} type={properties.layout.type} />
      }

      {selected === 'gating' &&
        <Gating widget={widget} onChange={handleChange} editor={props.editor} />
      }

      {selected === 'advanced' &&
        <QuickSettingsProperty.CSS title='Stylesheets' value={properties.css} onChange={handleCssChange} />
      }
    </QuickSettings>
  )
}

function Layout(props) {
  const layoutProps = props.value || {};
  const [regular, setRegular] = useState(true);

  const handleTypeChange = (e, data) => {
    var type = data.value;
    var layout = layouts.find(layout => {
      return layout.type === type;
    });

    var change = {
      ...layout.properties,
      layout: {
        ...layout.properties.layout,
        type: layout.type
      }
    };

    props.onChange(change);
  }

  const handleLayoutChange = (changes) => {
    var change = {
      layout: {
        ...layoutProps,
        ...changes
      }
    };

    props.onChange(change);
  }

  return (
    <Fragment>
      <QuickSettingsProperty.Dropdown title='Type'
        tooltip='Layout type for the widget'
        placeholder='Layout type'
        selection
        options={layouts.map(layout => {
          return { key: layout.type, value: layout.type, text: layout.label }
        })}
        value={layoutProps.type}
        onChange={handleTypeChange}
      />

      {layoutProps.type != 'carousel' && layoutProps.type != 'inline' &&
        <QuickSettingsProperty.Dropdown title='Items'
          tooltip='# of items per row'
          placeholder='# of items per row'
          selection
          options={[
            { key: '1', value: 1, text: 'One' },
            { key: '2', value: 2, text: 'Two' },
            { key: '3', value: 3, text: 'Three' },
            { key: '4', value: 4, text: 'Four' },
            { key: '5', value: 5, text: 'Five' },
            { key: '6', value: 6, text: 'Six' }
          ]}
          value={layoutProps.columns}
          onChange={(e, data) => {
            handleLayoutChange({
              columns: data.value
            })
          }}
        />
      }

      {layoutProps.type === 'carousel' &&
        <Fragment>
          <QuickSettingsProperty.Switch
            tooltip='Looped carousel'
            title='Infinite'
            value={layoutProps.infinite}
            onChange={() => {
              handleLayoutChange({
                infinite: !layoutProps.infinite
              })
            }}
          />
          <ArrowProperty
            value={layoutProps}
            onChange={(change) => {
              console.log(change);
              handleLayoutChange(change);
            }}
          />

          <AutoplayProperty
            value={layoutProps}
            onChange={(change) => {
              handleLayoutChange(change);
            }}
          />

          <QuickSettingsProperty.NumericInput
            tooltip='Card Width in %'
            title='Card Width'
            value={layoutProps.cardWidth}
            min={25}
            max={100}
            onChange={(value) => {
              handleLayoutChange({
                cardWidth: Math.min(value, 100)
              })
            }}
          />
        </Fragment>
      }
    </Fragment>
  );
}

function AssetTemplateProperty(props) {
  const handleTemplateChange = (e, data) => {
    var template = data.value;

    var change = {
      template: template
    };

    props.onChange(change);
  }

  var templates = useSelector(state => {
    return state.templates.items.map(template => {
      return {
        key: template.id,
        value: template.id,
        text: template.name
      }
    })
  });

  var loading = useSelector(state => state.templates.loading);

  return (
    <QuickSettingsProperty.Dropdown title='Card Style'
      tooltip='Select asset template'
      placeholder='Select template...'
      selection
      loading={loading}
      options={templates}
      value={props.value}
      onChange={handleTemplateChange}
    />
  );
}

function ArrowProperty({ value, onChange }) {
  var activeForegroundColor = _.get(value, 'arrowStyle.active.foregroundColor') || { r: 255, g: 255, b: 255, a: 1 };
  var activeBackgroundColor = _.get(value, 'arrowStyle.active.backgroundColor') || { r: 224, g: 225, b: 226, a: 1 };
  var hoverForegroundColor = _.get(value, 'arrowStyle.hover.foregroundColor') || { r: 255, g: 255, b: 255, a: 1 };
  var hoverBackgroundColor = _.get(value, 'arrowStyle.hover.backgroundColor') || { r: 202, g: 203, b: 205, a: 1 };

  return (
    <>
      <QuickSettingsProperty.Switch
        tooltip='Show arrows'
        title='Arrow'
        value={value.arrows}
        onChange={() => {
          onChange({
            ...value,
            arrows: !value.arrows
          })
        }}
      />

      {value.arrows &&
        <div className={styles.arrowProperty}>
          <p className={styles.description}>Configure the styles for the arrows buttons</p>
          <div className={cx(styles.state, styles.default)}>
            <label>Default</label>
            <div className={styles.color}>
              <QuickSettingsProperty.Color
                value={activeForegroundColor}
                onChange={(color) => {

                  var property = value.arrowStyle || {};
                  _.set(property, 'active.foregroundColor', color);

                  onChange({
                    ...value,
                    arrowStyle: property
                  });
                }}
              />
              <label>Foreground</label>
            </div>
            <div className={styles.color}>
              <QuickSettingsProperty.Color
                value={activeBackgroundColor}
                onChange={(color) => {
                  var property = value.arrowStyle || {};
                  _.set(property, 'active.backgroundColor', color);

                  onChange({
                    ...value,
                    arrowStyle: property
                  });
                }}
              />
              <label>Background</label>
            </div>
          </div>
          <div className={cx(styles.state, styles.hover)}>
            <label>Hover</label>
            <div className={styles.color}>
              <QuickSettingsProperty.Color
                value={hoverForegroundColor}
                onChange={(color) => {

                  var property = value.arrowStyle || {};
                  _.set(property, 'hover.foregroundColor', color);

                  onChange({
                    ...value,
                    arrowStyle: property
                  });
                }}
              />
              <label>Foreground</label>
            </div>
            <div className={styles.color}>
              <QuickSettingsProperty.Color
                value={hoverBackgroundColor}
                onChange={(color) => {
                  var property = value.arrowStyle || {};
                  _.set(property, 'hover.backgroundColor', color);

                  onChange({
                    ...value,
                    arrowStyle: property
                  });
                }}
              />
              <label>Background</label>
            </div>
          </div>
        </div>
      }
    </>
  );
}

function AutoplayProperty({ value, onChange }) {
  return (
    <>
      <QuickSettingsProperty.Switch
        tooltip='Autoplay'
        title='Autoplay'
        value={value.autoPlay}
        onChange={() => {
          onChange({
            ...value,
            autoPlay: !value.autoPlay,
            autoPlayDuration: !value.autoPlay && 3000
          });
        }}
      />

      {value.autoPlay &&
        <div className={styles.autoplayProperty}>
          <div className={cx(styles.state)}>
            <lable> Duration</lable>
            <QuickSettingsProperty.NumericInput
              tooltip='Set autoplay duration'
              value={_.get(value, 'autoPlayDuration', 3000) || 3000}
              min={3000}
              max={9000}
              onChange={(autoPlayDuration) => {
                onChange({
                  ...value,
                  autoPlayDuration: autoPlayDuration
                });
              }}
            />
          </div>
        </div>
      }
    </>
  );
}

function Gating(props) {
  var widget = props.widget;
  var properties = widget.properties;

  const gatings = useSelector(state => state.gatings.items);

  const gatingOptions = (gatings || []).map(item => {
    return {
      key: item.id,
      value: item.id,
      text: item.name
    }
  })

  const handleGatingChange = (value) => {
    props.onChange({
      gating: value
    })
  }

  return (
    <QuickSettingsProperty.Dropdown title='Gating'
      tooltip='Select gating'
      placeholder='Gating'
      selection
      clearable
      options={gatingOptions}
      value={properties.gating}
      onChange={(e, data) => {
        handleGatingChange(data.value);
      }}
    />
  )
}

function Content(props) {
  const dispatch = useDispatch();
  var widget = props.widget;
  var properties = widget.properties;

  const handleItemsChange = (value) => {
    var change = {
      itemsPerPage: value
    };

    props.onChange(change);
    dispatch(fetchAssetsForWidget(widget.id, 0, props.editor));
  }

  const handlePaginationChange = (value) => {
    var change = {
      pagination: value
    };

    props.onChange(change);
    dispatch(fetchAssetsForWidget(widget.id, 0, props.editor));
  }

  const handleSearchChange = (value) => {
    var change = {
      search: value
    };

    props.onChange(change);
    dispatch(fetchAssetsForWidget(widget.id, 0, props.editor));
  }

  const handleInfiniteScrollChange = (value) => {
    var change = {
      infiniteScroll: value
    };

    props.onChange(change);
    dispatch(fetchAssetsForWidget(widget.id, 0, props.editor));
  }

  return (
    <Fragment>
      <QuickSettingsProperty title='Query'>
        <ContentModal widget={widget} editor={props.editor} entityReference={props.entityReference} trigger={
          <Button>Edit Content</Button>
        }></ContentModal>
      </QuickSettingsProperty>

      {(props.type == 'grid' || props.type == 'metro') &&
        <>
          <QuickSettingsProperty.Switch tooltip='Paginate items'
            title='Pagination'
            value={properties.pagination}
            onChange={handlePaginationChange}
          />

          {properties.pagination &&
            <QuickSettingsProperty.Switch tooltip='Infinite Scroll'
              title='Infinite Scroll'
              value={properties.infiniteScroll}
              onChange={handleInfiniteScrollChange}
            />
          }

          <QuickSettingsProperty title='Filters'>
            <FiltersModal widget={widget} editor={props.editor} entityReference={props.entityReference} trigger={
              <Button>Edit</Button>
            }></FiltersModal>
          </QuickSettingsProperty>
        </>
      }

      {props.type !== 'inline' &&
        <Fragment>
          <QuickSettingsProperty.NumericInput
            tooltip='Number of items to display'
            title='Items'
            value={properties.itemsPerPage} min={1} max={20}
            onChange={handleItemsChange}
          />
        </Fragment>
      }

      <QuickSettingsProperty.Switch tooltip='Enable Search'
        title='Search'
        value={properties.search}
        onChange={handleSearchChange}
      />

    </Fragment>
  );
}