import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router-dom';

import styles from './reports.module.scss';

import cx from "classnames";
import moment from 'moment';
import _ from "lodash";

import {
  Grid,
  Statistic,
  Segment,
  Label,
  Button,
  Popup
} from 'semantic-ui-react';

import { VisitorRow, Pagination, DatePicker, EmptyMessage } from './components/row';

import { fetchLeadSessions, fetchSessionsForLead, fetchLead, selectLeadSession, fetchLeadAnalytics } from 'actions/reports/leads';
import { fetchVisitorSession, updateFiltersForReports } from 'actions/reports/reports';

import SidePane from 'components/SidePane'
import VisitorDetailView from './components/visitorDetail/VisitorDetailView';
import SessionDetailView from './components/sessionDetail/SessionDetailView';
import { Filters } from './components/filters/filters';

import { padNumber } from 'services/util';
import { normalise } from 'services/clusters.service';
import { generateQueryParams, parseQueryParams, convertDatesToTimezone } from 'services/report.service';
import BuildUrl from 'build-url';
import { downloadLeadReports } from 'actions/reports/leads'

export function LeadReports(props) {
  const history = useHistory();
  let { path, url } = useRouteMatch();

  const filters = useSelector(state => _.get(state, 'reports.common.filters', []));

  const [reportDate, setReportDate] = useState(null);

  useEffect(() => {
    if (reportDate) {
      const updatedUrl = generateReportUrl(url, reportDate);
      history.replace(updatedUrl);
    }
  }, [reportDate])

  useEffect(() => {
    const parsedDate = parseQueryParams(props.location.search);
    setReportDate(parsedDate);
  }, []);

  const generateReportUrl = (url, reportDate) => {
    const queryParams = generateQueryParams(reportDate, null);

    const paramsString = props.location.search;
    const params = new URLSearchParams(paramsString);

    const query = {
      ...params,
      ...queryParams
    };

    const updated = BuildUrl(url, {
      queryParams: query
    });

    return updated;
  }

  const handleDateChange = (value) => {
    setReportDate(value);
  }

  return (
    <Grid className={styles.visitors}>
      <ReportHeader
        value={reportDate}
        onChange={handleDateChange}
      />
      <Grid.Row>
        <Grid.Column>
          <LeadReport
            reportDate={reportDate}
            filters={filters}
          />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
}

function ReportHeader(props) {
  const dispatch = useDispatch();

  const analytics = useSelector(state => _.get(state, 'reports.leads.cumulativeAnalytics.item')) || {};
  const filters = useSelector(state => _.get(state, 'reports.common.filters', []));

  useEffect(() => {
    var requestFitlers = normalise(props.filters);
    if (props.value) {
      dispatch(fetchLeadAnalytics(props.value, requestFitlers));
    }
  }, [props.value, props.filters]);

  const _renderTimeSpent = (seconds) => {
    let minutes = padNumber(Math.floor(seconds / 60), 2);
    seconds = padNumber(Math.floor(seconds % 60), 2);
    var hours = padNumber(Math.floor(minutes / 60), 2);
    minutes = padNumber(minutes % 60, 2);

    hours = hours > 0 ? `${hours}:` : ''
    return `${hours}${minutes}:${seconds}`;
  }

  const handleFiltersChange = (filters) => {
    dispatch(updateFiltersForReports(filters))
  }

  return (
    <>
      <Grid.Row className={styles.header} columns='equal'>
        <Grid.Column width={3}>
          <DatePicker
            value={props.value}
            onChange={props.onChange}
          />
        </Grid.Column>
        <Grid.Column floated='right' textAlign='right' verticalAlign='middle'>
          <Statistic horizontal size='mini' className={styles.reportStatistics}>
            <Statistic.Value>{analytics.leads || 0}</Statistic.Value>
            <Statistic.Label>LEADS</Statistic.Label>
          </Statistic>
          <Statistic horizontal size='mini' className={styles.reportStatistics}>
            <Statistic.Value>{analytics.sessions || 0}</Statistic.Value>
            <Statistic.Label>SESSIONS</Statistic.Label>
          </Statistic>
          <Statistic horizontal size='mini' className={styles.reportStatistics}>
            <Statistic.Value>{analytics.assets || 0}</Statistic.Value>
            <Statistic.Label>ASSET VIEWS</Statistic.Label>
          </Statistic>
          <Statistic horizontal size='mini' className={styles.reportStatistics}>
            <Statistic.Value>{_renderTimeSpent(analytics.duration || 0)}</Statistic.Value>
            <Statistic.Label>TIME SPENT</Statistic.Label>
          </Statistic>
        </Grid.Column>
      </Grid.Row>
      <Segment basic className={styles.reportFilters}>
        <Filters
          cluster={null}
          value={filters}
          editable={true}
          onChange={handleFiltersChange}
        />
      </Segment>
    </>
  );
}

function Report({ name, reportDate, className, children, filters }) {
  const dispatch = useDispatch();

  const [downloadSuccess, setDownloadSuccess] = useState(false);
  const downloading = useSelector(state => _.get(state, 'reports.leads.sessions.downloading'));

  const handleDownloadClick = () => {
    var commonFilters = normalise(filters);

    dispatch(downloadLeadReports({
      ...convertDatesToTimezone(reportDate),
      filters: commonFilters,
      userType: 'all'
    }, () => {
      setDownloadSuccess(true);
      setTimeout(() => {
        setDownloadSuccess(false);
      }, 3000);
    }));
  }

  return (
    <Segment className={cx(styles.report, className)}>
      <Segment.Inline className={styles.reportHeader}>
        <Label>{name}</Label>
        <SuccessPopupMessage
          trigger={
            <Button className={styles.downloadButton} onClick={handleDownloadClick}>
              {downloading ? 'Downloading...' : 'Download'}
            </Button>
          }
          open={downloadSuccess}
          onVisibilityChange={() => { setDownloadSuccess(false) }}
        />
      </Segment.Inline>
      {children}
    </Segment>
  );
}

function LeadReport(props) {
  const dispatch = useDispatch();

  const sessions = useSelector(state => _.get(state, 'reports.leads.sessions.items')) || [];
  const pagination = useSelector(state => _.get(state, 'reports.leads.sessions.pagination'));
  const loading = useSelector(state => _.get(state, 'reports.leads.sessions.loading')) || false;

  useEffect(() => {
    if (props.reportDate) {
      handleFetchLeadSessions(0);
    }
  }, [props.reportDate, props.filters]);

  const handlePageChange = (page) => {
    handleFetchLeadSessions(page);
  }

  const handleFetchLeadSessions = (page) => {
    var filters = normalise(props.filters);
    dispatch(fetchLeadSessions(page, {
      ...convertDatesToTimezone(props.reportDate),
      filters: filters
    }));
  }

  const [visitor, setVisitor] = useState(null);
  const [session, setSession] = useState(null);

  const handleSessionClick = (session) => {
    if (visitor) {
      session.visitor = visitor;
    }

    dispatch(selectLeadSession(session));
    setSession(session);
  }

  var sidePaneWidth = '30vw';
  if (session) {
    if (visitor) {
      sidePaneWidth = '70vw';
    } else {
      sidePaneWidth = '40vw';
    }
  }

  return (
    <>
      <Report name='Sessions' reportDate={props.reportDate} className={styles.visitorReport}  filters={props.filters}>
        <Grid>
          {sessions.map(session => {
            return <VisitorRow key={session.id}
              session={session}
              visitor={session.contact}
              onVisitorClick={setVisitor}
              onSessionClick={handleSessionClick} />
          })}

          {pagination &&
            <Grid.Row>
              <Grid.Column textAlign='center'><Pagination pagination={pagination} onClick={handlePageChange} /></Grid.Column>
            </Grid.Row>
          }
        </Grid>

        {!loading && sessions.length == 0 &&
          <EmptyMessage title="Insufficient data to generate report." />}
      </Report>
      <SidePane right
        close
        open={visitor}
        width={sidePaneWidth}
        onClose={() => {
          setVisitor(null)
          setSession(null)
        }}
        closeOnDocumentClick={true}>
        <div className={styles.visitorDetail}>
          <div className={styles.visitor}>
            <VisitorDetail
              leadId={visitor ? visitor.id : null}
              reportDate={props.reportDate}
              filters={props.filters}
              selectedSession={session}
              onSessionClicked={handleSessionClick}
            />
          </div>
          {session &&
            <div className={styles.session}>
              <SessionDetail session={session} />
            </div>}
        </div>
      </SidePane>

      <SidePane right
        close
        open={!visitor && session}
        width={'40vw'}
        onClose={() => {
          setVisitor(null)
          setSession(null)
        }}
        closeOnDocumentClick={true}>
        <div className={styles.session}>
          <SessionDetail session={session} />
        </div>
      </SidePane>
    </>
  )
}

function VisitorDetail({ leadId, reportDate, filters, onSessionClicked, selectedSession }) {

  const dispatch = useDispatch();

  const visitor = useSelector(state => _.get(state, 'reports.leads.lead.item', null))
  const sessions = useSelector(state => _.get(state, 'reports.leads.lead.sessions.items', []))

  const loadingVisitor = useSelector(state => _.get(state, 'reports.leads.lead.loading', null))
  const loadingSessions = useSelector(state => _.get(state, 'reports.leads.lead.sessions.loading', false))

  useEffect(() => {
    if (leadId) {
      dispatch(fetchLead(leadId))

      if (reportDate) {
        var requestFilters = normalise(filters);
        dispatch(fetchSessionsForLead(leadId, {
          ...convertDatesToTimezone(reportDate),
          filters: requestFilters
        }, 0));
      }
    }
  }, [leadId, reportDate, filters])

  return (
    <VisitorDetailView
      visitor={visitor}
      sessions={sessions}
      loadingVisitor={loadingVisitor}
      loadingSessions={loadingSessions}
      selectedSession={selectedSession}
      onSessionClicked={onSessionClicked}
    />
  )
}

function SessionDetail(props) {
  const dispatch = useDispatch();

  const session = useSelector(state => _.get(state, 'reports.leads.session.item', null));
  const assetSessions = useSelector(state => _.get(state, 'reports.leads.session.assetSessions.items', []))
  const loading = useSelector(state => _.get(state, 'reports.leads.session.assetSessions.loading', false))

  useEffect(() => {
    if (session) {
      dispatch(fetchVisitorSession(session.id))
    }
  }, [session])

  if (!session) {
    return null;
  }

  return (
    <SessionDetailView
      session={session}
      visitor={session.contact}
      assetSessions={assetSessions}
      loading={loading}
    />
  )
}

function SuccessPopupMessage({ className, open, trigger, onVisibilityChange }) {
  return (
    <Popup
      className={cx(styles.popup, className)}
      popperModifiers={{
        preventOverflow: {
          boundariesElement: "window"
        }
      }}
      open={open}
      on='click'
      onClose={() => onVisibilityChange(false)}
      onOpen={() => onVisibilityChange(true)}
      flowing
      pinned
      hoverable
      position='bottom right'
      trigger={trigger}
      size='tiny'
    >
      <div className={styles.closeButton} onClick={() => onVisibilityChange(false)}>
        <i aria-hidden="true" className="icon-close_1 icon">
          <span className="path1"></span><span className="path2"></span><span className="path3"></span>
        </i>
      </div>
      <div className={styles.content}>
        <div className={styles.details}>
          <h3 className={styles.title}>Success</h3>
          <p className={styles.subtext}>Your download has been initiated and a copy of the report will be delivered to your mailbox in less than 2 minutes</p>
        </div>
      </div>
    </Popup>
  )
}