import {
  FETCHING_CONTENT_ANALYTICS_DAILY, FETCHING_CONTENT_ANALYTICS_DAILY_SUCCEEDED, FETCHING_CONTENT_ANALYTICS_DAILY_FAILED,
  FETCHING_CONTENT_REPORTS, FETCHING_CONTENT_REPORTS_SUCCEEDED, FETCHING_CONTENT_REPORTS_FAILED,
  DOWNLOAD_CONTENT_REPORTS, DOWNLOADING_CONTENT_REPORTS_SUCCEEDED, DOWNLOADING_CONTENT_REPORTS_FAILED,
  FETCH_ASSET_SESSIONS_TIME_SERIES, FETCHING_ASSET_SESSIONS_TIME_SERIES_SUCCEEDED, FETCHING_ASSET_SESSIONS_TIME_SERIES_FAILED,
  FETCH_ASSETS_FOR_REPORT, FETCHING_ASSETS_FOR_REPORT_SUCCEEDED, FETCHING_ASSETS_FOR_REPORT_FAILED,
  FETCH_CONTENT_ANALYTICS_FOR_REPORTS, FETCHING_CONTENT_ANALYTICS_FOR_REPORTS_SUCCEEDED, FETCHING_CONTENT_ANALYTICS_FOR_REPORTS_FAILED
} from 'constants/actionTypes';

import { createAction } from 'redux-actions';
import axios from 'axios';
import { call, put, takeLatest } from 'redux-saga/effects';
import buildUrl from 'build-url';

const apiUrl = process.env.REACT_APP_API_URL;
const API_BASE_URL = `${apiUrl}/api/2.1`;
const VISITOR_REPORTS_URL = `${API_BASE_URL}/resource_center/reports/visitors`;
const CONTENT_REPORTS_URL = `${API_BASE_URL}/resource_center/reports/content`;

/**
* Fetch content analytics daily
*/
export const fetchContentAnalyticsDaily = createAction(FETCHING_CONTENT_ANALYTICS_DAILY, (fromDate, toDate) => {
  return { fromDate, toDate }
});

const fetchContentAnalyticsDailySuccedded = createAction(FETCHING_CONTENT_ANALYTICS_DAILY_SUCCEEDED, response => {
  return response;
});

const fetchContentAnalyticsDailyFailed = createAction(FETCHING_CONTENT_ANALYTICS_DAILY_FAILED, error => {
  return error;
});

function* fetchContentAnalyticsDailySaga(action) {
  const data = {
    from: action.payload.fromDate,
    to: action.payload.toDate
  }

  const fetchContentAnalyticsDailysDailyAPI = () => {
    return axios.get(`${VISITOR_REPORTS_URL}/daily`,
      { params: data }
    ).then(response => response.data);
  }

  try {
    const response = yield call(fetchContentAnalyticsDailysDailyAPI);
    yield put(fetchContentAnalyticsDailySuccedded(response));
  } catch (error) {
    yield put(fetchContentAnalyticsDailyFailed(error));
  }
}

/**
* Fetch content reports
*/
export const fetchContentReports = createAction(FETCHING_CONTENT_REPORTS, (page, request) => {
  return {
    page, request
  }
})

const fetchContentReportsSucceeded = createAction(FETCHING_CONTENT_REPORTS_SUCCEEDED, response => {
  return response;
})

const fetchContentReportsFailed = createAction(FETCHING_CONTENT_REPORTS_FAILED, error => {
  return error;
})

function* fetchContentReportsSaga(action) {
  const params = {
    page: action.payload.page,
    limit: 20
  }

  let url = buildUrl(`${CONTENT_REPORTS_URL}`, {
    queryParams: params
  });

  const fetchContentReportsAPI = () => {
    return axios.post(url,
      action.payload.request
    ).then(response => response.data);
  }

  try {
    const response = yield call(fetchContentReportsAPI);
    yield put(fetchContentReportsSucceeded(response));
  } catch (error) {
    yield put(fetchContentReportsFailed(error));
  }
}

/**
* Download content reports
*/
export const downloadContentReports = createAction(DOWNLOAD_CONTENT_REPORTS, (request, callback) => {
  return {
    request: request,
    callback: callback
  }
});

export const downloadContentReportsSucceeded = createAction(DOWNLOADING_CONTENT_REPORTS_SUCCEEDED, (response) => {
  return response;
})

export const downloadContentReportsFailed = createAction(DOWNLOADING_CONTENT_REPORTS_FAILED, (error) => {
  return error;
})

function* downloadContentReportsSaga(action) {
  const { request, callback } = action.payload;

  const downloadContentReportsAPI = () => {
    return axios.post(`${CONTENT_REPORTS_URL}/download`, request).then(response => response.data);
  }

  try {
    const response = yield call(downloadContentReportsAPI);
    yield put(downloadContentReportsSucceeded(response));
    if(callback) {
      callback();
    }
  } catch (error) {
    yield put(downloadContentReportsFailed(error));
  }
}

/**
* Fetch visitor sessions time series
*/
export const fetchAssetSessionsTimeSeries = createAction(FETCH_ASSET_SESSIONS_TIME_SERIES, (startDate, endDate, page) => {
  return { 
    startDate: startDate,
    endDate: endDate,
    page: page  
  }
});

export const fetchingAssetSessionsTimeSeriesSuccedded = createAction(FETCHING_ASSET_SESSIONS_TIME_SERIES_SUCCEEDED, response => {
  return response;
});

export const fetchingAssetSessionsTimeSeriesFailed = createAction(FETCHING_ASSET_SESSIONS_TIME_SERIES_FAILED, error => {
  return error;
});

function* fetchAssetSessionsTimeSeriesSaga(action) {
  const params = {
    startDate: action.payload.startDate,
    endDate: action.payload.endDate
  }

  const fetchSessionsTimeSeriesAPI = () => {
    return axios.get(`${CONTENT_REPORTS_URL}/asset_sessions/series`,
      { params: params }
    ).then(response => response.data);
  }

  try {
    const response = yield call(fetchSessionsTimeSeriesAPI);
    yield put(fetchingAssetSessionsTimeSeriesSuccedded(response));
  } catch (error) {
    yield put(fetchingAssetSessionsTimeSeriesFailed(error));
  }
}


/**
* Fetch Assets
*/
export const fetchAssets = createAction(FETCH_ASSETS_FOR_REPORT, (startDate, endDate, page) => {
  return { 
    startDate: startDate,
    endDate: endDate,
    page: page  
  }
});

export const fetchingAssetsSucceeded = createAction(FETCHING_ASSETS_FOR_REPORT_SUCCEEDED, response => {
  return response;
});

export const fetchingAssetsFailed = createAction(FETCHING_ASSETS_FOR_REPORT_FAILED, error => {
  return error;
});

function* fetchAssetsSaga(action) {
  const params = {
    startDate: action.payload.startDate,
    endDate: action.payload.endDate,
    page: action.payload.page,
    limit: 5
  }

  const fetchAssetsAPI = () => {
    return axios.get(`${CONTENT_REPORTS_URL}`,
      { params: params }
    ).then(response => response.data);
  }

  try {
    const response = yield call(fetchAssetsAPI);
    yield put(fetchingAssetsSucceeded(response));
  } catch (error) {
    yield put(fetchingAssetsFailed(error));
  }
}

export const fetchContentAnalytics = createAction(FETCH_CONTENT_ANALYTICS_FOR_REPORTS, (fromDate, toDate) => {
  return { fromDate, toDate};
});

export const fetchingContentAnalyticsSucceeded = createAction(FETCHING_CONTENT_ANALYTICS_FOR_REPORTS_SUCCEEDED, response => {
  return response;
});

export const fetchingContentAnalyticsFailed = createAction(FETCHING_CONTENT_ANALYTICS_FOR_REPORTS_FAILED, error => {
  return error;
});

function* fetchingContentAnalyticsSaga(action) {
  const { fromDate, toDate } = action.payload;

  const data = {
    startDate: fromDate,
    endDate: toDate
  }
  const fetchingContentAnalytics = () => {
    return axios.get(`${CONTENT_REPORTS_URL}/analytics`,
    { params: data }
    ).then(response => response.data);
  }

  try {
    const response = yield call(fetchingContentAnalytics);
    yield put(fetchingContentAnalyticsSucceeded(response));
  } catch (error) {
    yield put(fetchingContentAnalyticsFailed(error));
  }
}

export default function* contentReportSaga() {
  yield takeLatest(FETCHING_CONTENT_ANALYTICS_DAILY, fetchContentAnalyticsDailySaga);
  yield takeLatest(FETCHING_CONTENT_REPORTS, fetchContentReportsSaga);
  yield takeLatest(DOWNLOAD_CONTENT_REPORTS ,downloadContentReportsSaga);
  yield takeLatest(FETCH_ASSET_SESSIONS_TIME_SERIES, fetchAssetSessionsTimeSeriesSaga);
  yield takeLatest(FETCH_ASSETS_FOR_REPORT, fetchAssetsSaga);
  yield takeLatest(FETCH_CONTENT_ANALYTICS_FOR_REPORTS, fetchingContentAnalyticsSaga);
}