import {
  FETCHING_INTEGRATIONS_REQUEST, FETCHING_INTEGRATIONS_SUCCEDDED, FETCHING_INTEGRATIONS_FAILED, INVALIDATE_INTEGRATIONS,
  DEACTIVATING_INTEGRATION_REQUEST, DEACTIVATING_INTEGRATION_SUCCEDDED, DEACTIVATING_INTEGRATION_FAILED,
  ACTIVATING_INTEGRATION_SUCCEDDED, INTEGRATION_AUTH_EXPIRED,
} from 'constants/actionTypes';

import { combineReducers } from "redux";

import { freshmarketerReducer } from './freshmarketerReducer';
import { pardotReducer } from './pardotReducer';
import { hubspotReducer } from './hubspotReducer';
import { zohoReducer } from './zohoReducer';
import { marketoReducer } from './marketoReducer';
import { sixSenseReducer } from './sixsenseReducer';

const initialState = {
  loading: false,
  invalidated: true,
  items: [],
  deactivating: false,
  errors: null
};

function activatedIntegrationsReducer(state = initialState, action) {
  switch (action.type) {
    case FETCHING_INTEGRATIONS_REQUEST:
      return {
        ...state,
        loading: true,
        invalidated: true
      }

    case FETCHING_INTEGRATIONS_SUCCEDDED:
      return {
        ...state,
        loading: false,
        invalidated: false,
        items: action.payload
      }

    case FETCHING_INTEGRATIONS_FAILED:
      return {
        ...state,
        loading: false,
        invalidated: false
      }

    case INVALIDATE_INTEGRATIONS:
      return {
        ...state,
        loading: false,
        invalidated: true
      }

    case ACTIVATING_INTEGRATION_SUCCEDDED:
      var items = state.items || [];
      var integration = action.payload;

      var index = items.findIndex(function (element) {
        return integration.type == element.type
      })

      if (index == -1) {
        items = [...items, integration];
      } else {
        items[index] = integration;
      }

      return {
        ...state,
        items: items
      }

    case DEACTIVATING_INTEGRATION_REQUEST:
      return {
        ...state,
        deactivating: true,
        errors: null
      }

    case DEACTIVATING_INTEGRATION_SUCCEDDED:
      items = state.items.filter((integration, index) => {
        return integration.type !== action.payload.type;
      })

      return {
        ...state,
        items: items,
        deactivating: false
      }

    case DEACTIVATING_INTEGRATION_FAILED:
      return {
        ...state,
        deactivating: false,
        errors: action.payload
      }

    case INTEGRATION_AUTH_EXPIRED:
      var error = action.payload.state[0];

      if (!error || error.code !== 23002) {
        return state;
      }

      index = items.findIndex(function (element) {
        return action.payload.type === element.type
      });

      if (index !== -1) {
        let integration = items[index];
        integration = Object.assign({}, integration, {
          ...integration,
          state: error.state
        });

        items[index] = integration;
      }

      return {
        ...state,
        items: items
      }

    default:
      return state;
  }
}

const integrationsReducer = combineReducers({
  activated: activatedIntegrationsReducer,
  freshmarketer: freshmarketerReducer,
  pardot: pardotReducer,
  hubspot: hubspotReducer,
  zoho:zohoReducer,
  marketo: marketoReducer,
  sixsense: sixSenseReducer,
});

export { integrationsReducer };