import {
  FETCH_ASSET_VIEWERS_REQUEST, FETCHING_ASSET_VIEWERS_SUCCEEDED, FETCHING_ASSET_VIEWERS_FAILED,
  FETCH_ASSET_VIEWER_REQUEST, FETCHING_ASSET_VIEWER_SUCCEEDED, FETCHING_ASSET_VIEWER_FAILED,
  CREATE_ASSET_VIEWER_REQUEST, CREATING_ASSET_VIEWER_SUCCEEDED, CREATING_ASSET_VIEWER_FAILED,
  CLONE_ASSET_VIEWER_REQUEST, CLONING_ASSET_VIEWER_SUCCEEDED, CLONING_ASSET_VIEWER_FAILED,
  UPDATE_ASSET_VIEWER_REQUEST, UPDATING_ASSET_VIEWER_SUCCEEDED, UPDATING_ASSET_VIEWER_FAILED,
  PUBLISH_ASSET_VIEWER_REQUEST, PUBLISHING_ASSET_VIEWER_SUCCEEDED, PUBLISHING_ASSET_VIEWER_FAILED,
  RESTORE_ASSET_VIEWER_REQUEST, RESTORING_ASSET_VIEWER_SUCCEEDED, RESTORING_ASSET_VIEWER__FAILED,
  DELETE_ASSET_VIEWER_REQUEST, DELETING_ASSET_VIEWER_SUCCEEDED, DELETING_ASSET_VIEWER_FAILED
} from 'constants/actionTypes.jsx'; 

const initialState = {
  loading: false,
  items: [],
  pagination: {
    currentPage: -1,
    totalPages: 1,
    recordsPerPage: 20,
    totalRecordsCount: 0
  },
  viewer: {
    loading: false,
    updating: false,
    publishing: false,
    restoring: false,
    deleting: false,
    item: null
  },
  create: {
    creating: false,
    item: null,
    error: null
  },
  clone: {
    cloning: false,
    item: null,
    error: null
  }
}

function assetViewerReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_ASSET_VIEWERS_REQUEST:
      return {
        ...state,
        loading: true
      }

    case FETCHING_ASSET_VIEWERS_SUCCEEDED:
      var items = state.items;
      var { currentPage, totalPages, recordsPerPage, totalRecordsCount, records = [] } = action.payload;

      if (currentPage !== 0) {
        items = [...state.items, ...records];
      } else {
        items = records
      } 

      items = items.reduce((x, y) => {
        var index = x.findIndex((e) => {
          return e.id === y.id;
        });

        if (index < 0) {
          return [...x, y];
        } else {
          x[index] = y;
          return x;
        }
      }, []);

      return {
        ...state,
        loading: false,
        items: items,
        pagination: {
          ...state.pagination,
          currentPage,
          totalPages,
          recordsPerPage,
          totalRecordsCount
        }
      }

    case FETCHING_ASSET_VIEWERS_FAILED:
      const { error } = action.payload;

      return {
        ...state,
        loading: false,
        error: error
      }

    case UPDATE_ASSET_VIEWER_REQUEST:
      return {
        ...state,
        viewer: {
          ...state.viewer,
          updating: true,
          error: null
        }
      }

    case UPDATING_ASSET_VIEWER_SUCCEEDED:
      return {
        ...state,
        viewer: {
          ...state.viewer,
          updating: false,
          item: action.payload,
          error: null
        }
      }

    case UPDATING_ASSET_VIEWER_FAILED:
      return {
        ...state,
        viewer: {
          ...state.viewer,
          updating: false,
          error: action.payload
        }
      }

    case FETCH_ASSET_VIEWER_REQUEST: 
      return {
        ...state,
        viewer: {
          ...state.viewer,
          loading: true,
          error: null
        }
      }

    case FETCHING_ASSET_VIEWER_SUCCEEDED:
      return {
        ...state,
        viewer: {
          ...state.viewer,
          loading: false,
          item: action.payload,
          error: null
        },
        create: {
          creating: false,
          item: null,
          error: null
        },
        clone: {
          cloning: false,
          item: null,
          error: null
        }
      }

    case FETCHING_ASSET_VIEWERS_FAILED:
      return {
        ...state,
        viewer: {
          ...state.viewer,
          loading: false,
          error: action.payload
        }
      }

    case PUBLISH_ASSET_VIEWER_REQUEST:
      return {
        ...state,
        viewer: {
          ...state.viewer,
          publishing: true,
          error: null
        }
      }

    case PUBLISHING_ASSET_VIEWER_SUCCEEDED:
      return {
        ...state,
        viewer: {
          ...state.viewer,
          publishing: false,
          item: action.payload,
          error: null
        }
      }

    case PUBLISHING_ASSET_VIEWER_FAILED:
      return {
        ...state,
        viewer: {
          ...state.viewer,
          publishing: false,
          error: action.payload
        }
      }  
    
    case RESTORE_ASSET_VIEWER_REQUEST:
      return {
        ...state,
        viewer: {
          ...state.viewer,
          restoring: true,
          error: null
        }
      }

    case RESTORING_ASSET_VIEWER_SUCCEEDED:
      return {
        ...state,
        viewer: {
          ...state.viewer,
          restoring: false,
          item: action.payload,
          error: null
        }
      }

    case RESTORING_ASSET_VIEWER__FAILED:
      return {
        ...state,
        viewer: {
          ...state.viewer,
          restoring: false,
          error: action.payload
        }
      } 

    case DELETE_ASSET_VIEWER_REQUEST:
      return state;

    case DELETING_ASSET_VIEWER_SUCCEEDED:
      var { id } = action.payload;

      var items = (state.items || []).filter(item => {
        return item.id !== id;
      })

      return {
        ...state,
        items: items,
        viewer: {
          loading: false,
          item: null,
          error: null
        }
      }

    case DELETING_ASSET_VIEWER_FAILED:
      return state;

    default:
      return state;
  }
}

export { assetViewerReducer };