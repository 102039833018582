export const PALETTE_ITEM = 'PALETTE_ITEM';
export const ROW_ITEM = 'ROW_ITEM';
export const COLUMN_ITEM = 'COLUMN_ITEM';
export const WIDGET_ITEM = 'WIDGET_ITEM';

export const ROW = 'layout.row';
export const COLUMN = 'layout.column';
export const TEXT = 'widgets.text';
export const HTML = 'widgets.html';
export const BUTTON = 'widgets.button.cta';
export const HERO_BANNER = 'widgets.hero_banner';
export const ASSETS = 'assets.default';

export const MENU = 'texts.menu';

export const FORM = "widgets.form";
export const FORM_INPUT = 'widgets.form.input';
export const FORM_BUTTON = 'widgets.form.button';

export const PARDOT_FORM = 'widgets.form.pardot';
export const EXTERNAL_FORM = 'widgets.form.external';
export const HUBSPOT_FORM = 'widgets.form.hubspot';
export const MARKETO_FORM = 'widgets.form.marketo';

export const IMAGE = 'widgets.image';

export const CTA_CARD = 'widgets.cta.cards';

export const PASSWORD_PROTECTION = 'widgets.custom.password';
export const LIVE_ELEMENT = 'widgets.live_element';

export const MODAL = 'widgets.modal';

export const CAROUSEL = 'widgets.carousel';
export const CAROUSEL_ITEM = 'widgets.carousel.item';

export const ASSET_VIEWER = 'widgets.custom.asset.viewer';

export const CONTAINER = 'widgets.container';

export const SECTION_WIDGET = 'widgets.section';

export const MENU_WIDGET = 'texts.menu';
export const STICKY_MENU_WIDGET = 'texts.sticky.menu';

export const HTML_WIDGET = 'widgets.html';
export const CONTAINER_WIDGET = 'widgets.container';

export const SEEK = 'widgets.seek';

export const DEFAULT_ACCEPT_TARGETS = [ROW, TEXT, BUTTON, ASSETS, FORM_INPUT, FORM_BUTTON, IMAGE, CTA_CARD, MENU_WIDGET, LIVE_ELEMENT, CONTAINER_WIDGET, SECTION_WIDGET, CAROUSEL];

export const ALL_WIDGETS = [PALETTE_ITEM, ROW, COLUMN, TEXT, BUTTON, HERO_BANNER, ASSETS, FORM_INPUT, FORM_BUTTON, IMAGE, CTA_CARD, MENU_WIDGET, LIVE_ELEMENT];

export const HIDDEN_WIDGETS = [PASSWORD_PROTECTION];