import React, {useState, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import _ from 'lodash';

import cx from 'classnames';

import { 
  Tab,
  Accordion,
  Header,
  Segment,
  Icon
} from 'semantic-ui-react';

import Truncate from 'react-truncate';

import styles from './addAsset.module.scss';

import { fetchHubStreamsRequest, fetchStreamRequest, fetchGroupRequest,
  fetchCollectionsRequest, fetchCollectionRequest,
  fetchCollectionSectionRequest 
} from 'actions/paperflite.jsx';

import { addAssets } from 'actions/content.jsx';

function PaperfliteAssets(prop){
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchHubStreamsRequest()); 
    dispatch(fetchCollectionsRequest()); 

  }, []);

  const streams = useSelector(state => state.paperflite.streams.items);
  const collections = useSelector(state => state.paperflite.collections.items);
  
  const panes = [{
    menuItem: 'My Streams',
    render: () =>
      <Tab.Pane attached={true} className={styles.streamTab}>
        {streams.map(stream => <StreamNode key={stream.id} content={stream} />)}
      </Tab.Pane>
    },
    {
      menuItem: 'My Collection',
      render: () => 
        <Tab.Pane attached={true} className={styles.streamTab}>
          {collections.map(collection => <CollectionNode key={collection.id} content={collection} />)}
        </Tab.Pane>,
    }
  ]

  return (
    <Segment basic className={styles.paperflite}>
      <i aria-hidden="true" className="icon-paperflite-upload icon"></i>
      <Header as='h2'>
        Paperflite
        <Header.Subheader className={styles.subHeader}>
          Choose your assets from Paperflite streams &amp; collections
        </Header.Subheader>
      </Header>
      <Tab menu={{ secondary: true, pointing: true }} 
        grid={{ paneWidth: 12, tabWidth: 6 }} 
        panes={panes} />
    </Segment>
  );
}

function StreamNode({content}){
  const dispatch = useDispatch();

  const [open, setOpen] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const handleClick = () => {
    setOpen(!open);
    if(!loaded){
      dispatch(fetchStreamRequest(content.id));
      setLoaded(true);
    }
  }

  const loading = useSelector(state => _.get(state, `paperflite.streams.stream.${content.id}.loading`, false));
  const children = useSelector(state => _.get(state, `paperflite.streams.stream.${content.id}.item.children`, []));

  return (
    <Accordion className={cx(styles.node, styles.stream)}>
      <Accordion.Title active={open} onClick={handleClick}>
        {loading && 
          <Icon loading name='spinner' size='small' />
        }
        {!loading && 
          <Icon name={open ? 'minus' : 'add'} size="small"/>
        }
        {/* <Icon name='newspaper outline' /> */}
        <Truncate lines={1}>{content.name}</Truncate>
      </Accordion.Title>
      <Accordion.Content active={open}>
        {children.map(item => {
          if(item.entityType === 'group'){
            return <GroupNode key={item.id} content={item} />
          }else if(item.entityType === 'asset'){
            return <AssetNode key={item.id} content={item} />
          }

          return false;
        })}
      </Accordion.Content>
    </Accordion>
  );
}

function GroupNode({content}){
  const dispatch = useDispatch();

  const [open, setOpen] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const handleClick = () => {
    setOpen(!open);
    if(!loaded){
      dispatch(fetchGroupRequest(content.id));
      setLoaded(true);
    }
  }

  const loading = useSelector(state => _.get(state, `paperflite.streams.group.${content.id}.loading`, false));
  const children = useSelector(state => _.get(state, `paperflite.streams.group.${content.id}.item.children`, []));

  return (
    <Accordion className={cx(styles.node, styles.group)}>
      <Accordion.Title active={open} onClick={handleClick}>
        {loading && 
          <Icon loading name='spinner' size='small' />
        }
        {!loading && 
          <Icon name={open ? 'minus' : 'add'} size="small"/>
        }
        {/* <Icon name='folder open outline' /> */}
        <Truncate lines={1} className={styles.name}>{content.name}</Truncate>
      </Accordion.Title>
      <Accordion.Content active={open}>
        {children.map(item => {
          if(item.entityType === 'group'){
            return <GroupNode key={item.id} content={item} />
          }else if(item.entityType === 'asset'){
            return <AssetNode key={item.id} content={item} />
          }

          return false;
        })}
      </Accordion.Content>
    </Accordion>
  );
}

function AssetNode({content}){
  const dispatch = useDispatch();

  var added = useSelector(state => {
    var assets = _.get(state, 'selection.assets', []);
    return assets.some(item => item.type === 'asset' && item.asset.id === content.id);
  })

  const handleClick = () => {
    if(!added){
      dispatch(addAssets([content]));
    }
  }
  return (
    <Accordion className={cx(styles.node, styles.asset)}>
      <Accordion.Title onClick={handleClick}>
        {/* <Icon name='file alternate outline' /> */}
        <Truncate lines={1}>{content.name}</Truncate>
      </Accordion.Title>
    </Accordion>
  );
}

function CollectionNode({content}){
  const dispatch = useDispatch();

  const [open, setOpen] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const handleClick = () => {
    setOpen(!open);
    if(!loaded){
      dispatch(fetchCollectionRequest(content.id));
      setLoaded(true);
    }
  }

  const loading = useSelector(state => _.get(state, `paperflite.collections.collection.${content.id}.loading`, false));
  const collection = useSelector(state => _.get(state, `paperflite.collections.collection.${content.id}.item`, []));

  var children = _.get(collection, 'sections', []);
  if(_.size(children) === 0){
    children = _.get(collection, 'assets', []);
  }

  return (
    <Accordion className={cx(styles.node, styles.collection)}>
      <Accordion.Title active={open} onClick={handleClick}>
        {loading && 
          <Icon loading name='spinner' size='small' />
        }
        {!loading && 
          <Icon name={open ? 'minus' : 'add'} size="small"/>
        }
        {/* <Icon name='newspaper outline' /> */}
        <Truncate lines={1}>{content.name}</Truncate>
      </Accordion.Title>
      <Accordion.Content active={open}>
        {children.map(item => {
          if(item.entityType === 'collection_section'){
            return <CollectionSelectionNode key={item.id} parent={content.id} content={item} />
          }else if(item.entityType === 'asset'){
            return <AssetNode key={item.id} content={item} />
          }

          return false;
        })}
      </Accordion.Content>
    </Accordion>
  );
}

function CollectionSelectionNode({parent, content}){
  const dispatch = useDispatch();

  const [open, setOpen] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const handleClick = () => {
    setOpen(!open);
    if(!loaded){
      dispatch(fetchCollectionSectionRequest(parent, content.id));
      setLoaded(true);
    }
  }
  
  const loading = _.get(content, 'loading', false); 
  const children = _.get(content, 'assets', []);

  return (
    <Accordion className={cx(styles.node, styles.section)}>
      <Accordion.Title active={open} onClick={handleClick}>
        {loading && 
          <Icon loading name='spinner' size='small' />
        }
        {!loading && 
          <Icon name={open ? 'minus' : 'add'} size="small"/>
        }
        {/* <Icon name='newspaper outline' /> */}
        <Truncate lines={1}>{content.name}</Truncate>
      </Accordion.Title>
      <Accordion.Content active={open}>
        {children.map(item => {
          if(item.entityType === 'asset'){
            return <AssetNode key={item.id} content={item} />
          }

          return false;
        })}
      </Accordion.Content>
    </Accordion>
  );
}

export default PaperfliteAssets;