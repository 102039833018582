import {
  CREATING_CLUSTER, CREATING_CLUSTER_SUCCEEDED, CREATING_CLUSTER_FAILED,
  UPDATING_CLUSTER, UPDATING_CLUSTER_SUCCEEDED, UPDATING_CLUSTER_FAILED,
  UPDATE_CLUSTER,
  FETCHING_ALL_CLUSTERS, FETCHING_ALL_CLUSTERS_SUCCEEDED, FETCHING_ALL_CLUSTERS_FAILED,
  FETCH_CLUSTER_REQUEST, FETCHING_CLUSTER_SUCCEEDED, FETCHING_CLUSTER_FAILED,

  INVALDATE_CLUSTER_REQUEST,
  DELETE_CLUSTER_REQUEST, DELETE_CLUSTER_SUCCEEDED, DELETE_CLUSTER_FAILED
} from 'constants/actionTypes';

import AsyncLocalStorageService from 'services/localstorage.async.service';

import { guid } from 'services/util';

const initialState = {
  loading: false,
  invalidated: false,
  items: [],
  cluster: {
    item: null,
    loading: false,
    creating: false,
    updating: false,
    deleting: false
  },
  assets: {
    loading: false,
    invalidated: true,
    query: '',
    items: []
  }
}

export function clustersReducer(state = initialState, action) {
  switch (action.type) {
    case CREATING_CLUSTER:
      return Object.assign({}, state, {
        ...state,
        cluster: {
          ...state.cluster,
          creating: true
        }
      });

    case CREATING_CLUSTER_SUCCEEDED:
      var cluster = action.payload;
      return Object.assign({}, state, {
        ...state,
        creating: false,
        items: [...state.items, cluster],
        cluster: {
          ...state.cluster,
          creating: false,
          item: cluster
        }
      });

    case CREATING_CLUSTER_FAILED:
      return Object.assign({}, state, {
        ...state,
        cluster: {
          ...state.cluster,
          creating: false,
          error: action.payload
        }
      });
    
    case UPDATING_CLUSTER: 
      return Object.assign({}, state, {
        ...state,
        cluster: {
          ...state.cluster,
          updating: true
        }
      });

    case UPDATING_CLUSTER_SUCCEEDED:
      var cluster = action.payload.response;

      var clusters = state.items.map(item => {
        if(item.id === cluster.id) {
          return cluster;
        }

        return item;
      })

      return Object.assign({}, state, {
        ...state,
        updating: false,
        items: clusters,
        cluster: {
          ...state.cluster,
          updating: false,
          item: cluster
        }
      });

    case UPDATING_CLUSTER_FAILED:
      return Object.assign({}, state, {
        ...state,
        cluster: {
          ...state.cluster,
          updating: false,
          error: action.payload.error
        }
      });

    case UPDATE_CLUSTER: 
      return Object.assign({}, state, {
        ...state,
        cluster: {
          ...state.cluster,
          creating: false,
          updating: false,
          item: action.payload
        }
      });

    case FETCHING_ALL_CLUSTERS:
      return Object.assign({}, state, {
        ...state,
        loading: true
      });

    case FETCHING_ALL_CLUSTERS_SUCCEEDED: 
      var clusters = (action.payload || []);
      return Object.assign({}, state, {
        ...state,
        loading: false,
        items: clusters
      });

    case FETCHING_ALL_CLUSTERS_FAILED:
      return Object.assign({}, state, {
        ...state,
        loading: false,
        error: action.payload
      });

    case FETCH_CLUSTER_REQUEST: 
      var id = action.payload.id;

      return Object.assign({}, state, {
        ...state,
        cluster: {
          ...state.cluster,
          creating: false,
          updating: false,
          loading: true,
          item: null
        }
      });  

    case FETCHING_CLUSTER_SUCCEEDED: 
      var id = action.payload.id;
      var response = action.payload.response;

      return Object.assign({}, state, {
        ...state,
        cluster: {
          ...state.cluster,
          loading: false,
          item: response
        }
      });  

    case FETCHING_CLUSTER_FAILED: 
      var id = action.payload.id;
      var error = action.payload.error;

      return Object.assign({}, state, {
        ...state,
        cluster: {
          ...state.cluster,
          loading: false,
          error: error
        }
      });    

    case INVALDATE_CLUSTER_REQUEST: 
      return Object.assign({}, state, {
        ...state,
        cluster: {
          item: null,
          loading: false,
          creating: false,
          updating: false,
          deleting: false
        }
      });  
      
    case DELETE_CLUSTER_REQUEST:
      return {
        ...state,
        cluster: {
          ...state.cluster,
          deleting: true
        }
      }

    case DELETE_CLUSTER_SUCCEEDED:
      var { id } = action.payload;

      var items = state.items.filter(item => {
        return item.id !== id;
      })

      return {
        ...state,
        items: items,
        cluster: {
          item: null,
          loading: false,
          creating: false,
          updating: false,
          deleting: false
        }
      }

    case DELETE_CLUSTER_FAILED:
      var { error } = action.payload;

      return {
        ...state,
        cluster: {
          ...state.cluster,
          deleting: false,
          error: error
        }
      }

    default: 
      return state;
  }
}