import {
  TODAY, YESTERDAY, SEVEN_DAYS, FOURTEEN_DAYS, WEEK_TO_DATE, MONTH_TO_DATE, THIRTY_DAYS, NINETY_DAYS
} from 'constants/report';


import moment from 'moment';
import _ from 'lodash';
import numeral from 'numeral';

export const getReportDatesForOption = (key) => {
  switch (key) {
    case TODAY:
      return {
        startDate: moment(),
        endDate: moment()
      }

    case YESTERDAY:
      return {
        startDate: moment().subtract(1, 'days'),
        endDate: moment().subtract(1, 'days')
      }

    case SEVEN_DAYS:
      return {
        startDate: moment().subtract(7, 'days'),
        endDate: moment()
      }

    case FOURTEEN_DAYS:
      return {
        startDate: moment().subtract(14, 'days'),
        endDate: moment()
      }

    case WEEK_TO_DATE:
      return {
        startDate: moment().startOf('week'),
        endDate: moment()
      }

    case MONTH_TO_DATE:
      return {
        startDate: moment().startOf('month'),
        endDate: moment()
      }

    case THIRTY_DAYS:
      return {
        startDate: moment().subtract(30, 'days'),
        endDate: moment()
      }

    case NINETY_DAYS:
      return {
        startDate: moment().subtract(90, 'days'),
        endDate: moment()
      }

    default:
      return {
        startDate: moment(),
        endDate: moment()
      }
  }
}

export const generateQueryParams = (value, filterId) => {
  const type = _.get(value, 'type');
  const startDate = _.get(value, 'startDate');
  const endDate = _.get(value, 'endDate');

  return {
    dateRange: type,
    startDate: startDate.format('DD/MM/YYYY'),
    endDate: endDate.format('DD/MM/YYYY')
  }

}

function getParameterByName(name, queryString) {
  var match = RegExp('[?&]' + name + '=([^&]*)').exec(queryString);
  return match && decodeURIComponent(match[1].replace(/\+/g, ' '));
}

export const parseQueryParams = (value) => {
  var type = "";
  var startDate = "";
  var endDate = "";

  if (typeof value === 'object' && value !== null) {
    type = _.get(value, 'dateRange');
    startDate = _.get(value, 'startDate');
    endDate = _.get(value, 'endDate');
  }

  if (typeof value === 'string') {
    type = getParameterByName('dateRange', value)
    startDate = getParameterByName('startDate', value)
    endDate = getParameterByName('endDate', value);
  }

  if (_.isEmpty(type)) {
    return {
      type: FOURTEEN_DAYS,
      ...getReportDatesForOption(FOURTEEN_DAYS)
    }
  }

  return {
    type: type,
    startDate: moment(startDate, 'DD/MM/YYYY'),
    endDate: moment(endDate, 'DD/MM/YYYY')
  }
}

export const convertDatesToTimezone = (value) => {
  const startDate = _.get(value, 'startDate');
  const endDate = _.get(value, 'endDate');

  const startDateValue = startDate.startOf('day');
  const endDateValue = endDate.endOf('day');

  return {
    startDate: startDateValue.format('YYYY-MM-DDTHH:mm:ss+00:00'),
    endDate: endDateValue.format('YYYY-MM-DDTHH:mm:ss+00:00')
  }
}

export const formatTimespent = (seconds = 0) => {
  var timeSpent = seconds;
  var unit = 's';

  if (timeSpent >= 60) {
    timeSpent = timeSpent / 60;
    unit = 'm';
  }

  return `~${numeral(timeSpent).format('0')}${unit}`;
}

export const formatName = (entity) => {
  return `${_.get(entity, 'firstName') || ''} ${_.get(entity, 'lastName') || ''} `;
}

export const prepareRequest = (request, supportedEntities) => {
  const filterRequest = {
    ...convertDatesToTimezone(request.reportDate),
    filters: prepareFilters(request.filters, supportedEntities)
  }

  return filterRequest;
}

export const prepareFilters = (filters, supportedEntities) => {
  var updatedFilters = (filters || []).filter(filter => {
    if (!supportedEntities || supportedEntities.length == 0) {
      return true;
    }

    var property = filter.property;
    return _.includes(supportedEntities, property.type);
  }).filter(filter => {
    var property = filter.property;
    var value = filter.value;

    return value != null && value.length > 0;
  }).map(filter => {
    var property = filter.property;
    var operator = filter.operator;
    var value = filter.value;

    if (filter.property.type === 'stream' || filter.property.type === 'collection' ||
      filter.property.type === 'contact' || filter.property.type === 'user' ||
      filter.property.type === 'user_group' || filter.property.type === 'asset'
    ) {
      value = (filter.value || []).map(item => {
        if (typeof item === 'string' || item instanceof String) {
          return item;
        }

        return item.id;
      });
    }

    return {
      property: property,
      operator: operator,
      value: value
    }
  });

  return updatedFilters;
}