import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import _ from 'lodash';
import cx from 'classnames';

import {
  Image,
  Progress,
  Button,
  Label,
  Input,
  Form,
  TextArea,
  Dropdown,
  Checkbox,
  Menu,
  Icon,
  Segment
} from 'semantic-ui-react';

import { bulkUpdateAssets } from 'actions/content'
import { EditAsset } from './edit';

import styles from './asset.module.scss';

export default function BulkEditAssets(props) {
  const dispatch = useDispatch();
  const customFields = useSelector(state => { return state.customFields.items});
  const updating = useSelector(state => { return state.content.updating});

  const [tags, setTags] = useState([]);
  const [assetFields, setAssetFields] = useState([]);
  const [errors, setErrors] = useState({});
  
  const handleUpdate = () => {
    var request = {
      filters: props.filters(),
      tags: tags,
      customFields: assetFields
    }

    dispatch(bulkUpdateAssets(request, () => {
      if(props.onUpdate){
        props.onUpdate();
      }
    }));
  }

  const handleReset = () => {
    setTags([]);
    setAssetFields([]);
    setErrors({});
  }
  
  return (
    <Segment basic className={styles.bulkEdit}>
      <EditAsset.Tags tags={tags} onChange={setTags}/>
      <EditAsset.CustomFields 
        customFields={customFields} 
        assetFields={assetFields}
        onChange={setAssetFields} />

      <Menu borderless fixed={'bottom'}>
        <Button.Group fluid className={styles.buttonTray}>
          <Button onClick={handleReset}>
            <Icon name={'repeat'} /> 
            Reset
          </Button>
          <Button.Or />
          <Button 
            disabled={updating}
            positive 
            onClick={handleUpdate}>
              <Icon name={updating ? 'spinner' : 'save'} loading={updating}/> 
              {updating ? 'Updating' : 'Update'}
          </Button>
        </Button.Group>
      </Menu>
    </Segment>
  )
}