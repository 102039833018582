import {
  ACTIVATING_SIX_SENSE_REQUEST, ACTIVATING_SIX_SENSE_SUCCEDDED, ACTIVATING_SIX_SENSE_FAILED,
  UPDATING_SIX_SENSE_REQUEST, UPDATING_SIX_SENSE_SUCCEDDED, UPDATING_SIX_SENSE_FAILED
  } from 'constants/actionTypes';
  
  import axios from 'axios';
  import { createAction } from 'redux-actions';
  import { call, put, takeEvery } from 'redux-saga/effects';
  
  const apiUrl = `${process.env.REACT_APP_API_URL}/api/2.0`;
  const SIXSENSE_BASE_URL = `${apiUrl}/integrations/six-sense`;
  
  /*
  * Activate SixSense
  */
  export const activatingAccount = createAction(ACTIVATING_SIX_SENSE_REQUEST, (request, callback) => {
    return { request, callback };
  })
  
  const activatingAccountSuccedded = createAction(ACTIVATING_SIX_SENSE_SUCCEDDED, (response) => {
    return response;
  })
  
  const activatingAccountFailed = createAction(ACTIVATING_SIX_SENSE_FAILED, (error) => {
    return error;
  })
  
  function* activateAccountSaga(action) {
    const { request, callback } = action.payload;
  
    const activateSixSenseAPI = () => {
      return axios.post(SIXSENSE_BASE_URL, request).then(response => response.data);
    }
  
    try {
      const response = yield call(activateSixSenseAPI);
      if (response) {
        yield put(activatingAccountSuccedded(response));
        if(callback) {
          callback(response);
        }
      }
    } catch (error) {
      yield put(activatingAccountFailed(error));
    }
  }

  /*
  * Activate SixSense
  */
  export const updatingSixSenseConfiguration = createAction(UPDATING_SIX_SENSE_REQUEST, (request, callback) => {
    return { request, callback };
  })
  
  const updatingSixSenseConfigurationSuccedded = createAction(UPDATING_SIX_SENSE_SUCCEDDED, (response) => {
    return response;
  })
  
  const updatingSixSenseConfigurationFailed = createAction(UPDATING_SIX_SENSE_FAILED, (error) => {
    return error;
  })
  
  function* updateSixSenseConfigurationSaga(action) {
    const { request, callback } = action.payload;
  
    const updateSixSenseAPI = () => {
      return axios.put(SIXSENSE_BASE_URL, request).then(response => response.data);

    }
  
    try {
      const response = yield call(updateSixSenseAPI);
      if (response) {
        yield put(updatingSixSenseConfigurationSuccedded(response));
        yield put(activatingAccountSuccedded(response));
        if(callback) {
          callback(response);
        }
      }
    } catch (error) {
      yield put(updatingSixSenseConfigurationFailed(error));
    }
  }
   
  export default function* sixsenseSaga() {
    yield takeEvery(ACTIVATING_SIX_SENSE_REQUEST, activateAccountSaga);
    yield takeEvery(UPDATING_SIX_SENSE_REQUEST, updateSixSenseConfigurationSaga);

  }