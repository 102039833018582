import React, { useState, Fragment, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateProperties, updateWidget, showPlaceholderAtIndex, moveWidgetToIndex } from 'actions/widgetEditor';

import cx from 'classnames';
import _ from 'lodash';

import { useDrop } from 'react-dnd';
import { WIDGET_ITEM } from 'constants/ItemTypes.jsx';

import {
  Grid
} from 'semantic-ui-react';

import { WidgetRegistry } from '../registry.jsx';
import { Widget, getStyles } from '../widget.jsx';
import { Property } from 'components/Properties';
import { QuickSettings, QuickSettingsProperty } from 'components/QuickSettings';

import styles from './layout.module.scss';

import { useWhatChanged } from "@simbathesailor/use-what-changed";
import { useWidgetDrop } from 'services/dnd.service.jsx';
import { ROW } from 'constants/ItemTypes.jsx';
import { HERO_BANNER } from 'constants/ItemTypes.jsx';

const Row = React.memo((props) => {
  const ref = useRef(null);
  const dispatch = useDispatch();

  var widgets = props.widgets || [];
  widgets = useSelector(state => {
    var items = [];

    var editorContext = state.widgetsEditor.editors[props.editor];

    if (editorContext) {
      items = widgets.map(widget => {
        return editorContext.widgetsById[widget];
      });
    }

    return items;
  }).filter(widget => widget != null);

  const dropRef = useWidgetDrop(props, ref, {
    acceptTargets: (type) => {
      return true;
    }
  });

  const fuild = _.get(props, 'properties.fluid', false);
  const anchor = _.get(props, 'properties.anchor.name');

  var widgetStyles = getStyles(props.properties);

  return (
    <>
      <Grid id={`widget_${props.id}`} columns={12} container={!fuild} stackable className={cx(styles.grid, 'cs_row')} data-anchor={anchor}>
        <Grid.Row className={cx(props.className, styles.row, styles[props.properties.horizontalAlignment])}
          as={FowardableRow}
          forwardedRef={props.dragRef(dropRef(ref))}
          columns={widgets.length}
          stretched
          style={widgetStyles}
          onMouseOver={props.onMouseOver}
          onMouseOut={props.onMouseOut}
          onClick={props.onClick}
        >
          <Widget.Children widgets={props.widgets} parent={props.id} editor={props.editor} editable={props.editable} />

          {props.children}
        </Grid.Row>
      </Grid>
    </>
  );
});

function RowQuickSettings(props) {
  const dispatch = useDispatch();

  const [selected, setSelected] = useState('layout');

  const menuItems = [
    { id: 'layout', title: 'Layout', icon: 'icon-layout' },
    { id: 'spacing', title: 'Spacing', icon: 'icon-spacing' },
    { id: 'background', title: 'Background', icon: 'icon-background' },
    { id: 'border', title: 'Border', icon: 'icon-border' },
    { id: 'anchor', title: 'Anchor', icon: 'icon-custom' },
    { id: 'advanced', title: 'Advanced', icon: 'icon-custom' }
  ]

  const handleMenuClick = (item) => {
    setSelected(item);
  }

  var editorContext = useSelector(state => state.widgetsEditor.editors[props.editor]);

  var widget = editorContext.widgetsById[props.id];
  if (!widget) {
    return false;
  }

  var properties = widget.properties;
  const handleChange = (changeRequest) => {
    dispatch(updateProperties({
      id: widget.id,
      change: changeRequest,
      context: props.editor
    }));
  }

  const handleBackgroundImageChange = (media) => {
    var changeRequest = { ...widget };
    _.set(changeRequest, 'properties.background.upload', {
      type: 'image',
      data: media
    });
    changeRequest = WidgetRegistry.processRequest([changeRequest])[0];
    dispatch(updateWidget(props.id, changeRequest, props.editor));
  }

  const handleCssChange = (css) => {
    var changeRequest = { ...widget };
    _.set(changeRequest, 'properties.css', css);
    changeRequest = WidgetRegistry.processRequest([changeRequest])[0];
    dispatch(updateWidget(props.id, changeRequest, props.editor));
  }

  return (
    <QuickSettings menuItems={menuItems} selected={selected} onMenuClick={handleMenuClick} >
      {selected === 'spacing' &&
        <>
          <QuickSettingsProperty.Spacing
            title='Margin'
            value={properties.margin}
            onChange={(margin) => {
              handleChange({
                margin: margin
              });
            }}
          />
          <QuickSettingsProperty.Spacing
            title='Padding'
            value={properties.padding}
            onChange={(padding) => {
              handleChange({
                padding: padding
              });
            }}
          />

          <QuickSettingsProperty.Alignment
            title='Alignment'
            tooltip='Controls where the columns will be aligned'
            value={properties.horizontalAlignment}
            onChange={(alignment) => {
              handleChange({
                horizontalAlignment: alignment
              });
            }}
          />
        </>
      }

      {selected === 'background' &&
        <QuickSettingsProperty.Color
          title='Color'
          value={_.get(properties, 'background.color')}
          onChange={(value) => {
            handleChange({
              background: {
                color: value
              }
            })
          }}
        />
      }

      {selected === 'border' &&
        <QuickSettingsProperty.Border expanded value={properties.border} onChange={(border) => {
          handleChange({
            border: border
          });
        }}
        />
      }

      {selected === 'layout' &&
        <Property.Switch tooltip='Row extends full width of the page'
          title='Fluid'
          value={_.get(widget, 'properties.fluid', false)}
          onChange={() => {
            handleChange({
              fluid: !_.get(widget, 'properties.fluid', false)
            })
          }}
        />
      }

      {selected === 'advanced' &&
        <QuickSettingsProperty.CSS title='Stylesheets' value={properties.css} onChange={handleCssChange} />
      }

      {selected === 'anchor' &&
        <QuickSettingsProperty.Anchor title='Anchor' value={_.get(widget, 'properties.anchor.name')} onChange={(anchor) => {
          handleChange({
            anchor: {
              name: anchor 
            }
          })
        }} />
      }
    </QuickSettings>
  )
}

Row.QuickSettings = RowQuickSettings;

function RowProperty(props) {
  const dispatch = useDispatch();
  var editorContext = useSelector(state => state.widgetsEditor.editors[props.editor]);
  var widget = editorContext.widgetsById[props.id];
  if (!widget) {
    return false;
  }
  var properties = widget.properties;
  const handleChange = (changeRequest) => {
    dispatch(updateProperties({
      id: widget.id,
      change: changeRequest,
      context: props.editor
    }));
  }
  const handleBackgroundImageChange = (media) => {
    var changeRequest = { ...widget };
    _.set(changeRequest, 'properties.background.upload', {
      type: 'image',
      data: media
    });
    changeRequest = WidgetRegistry.processRequest([changeRequest])[0];
    dispatch(updateWidget(props.id, changeRequest, props.editor));
  }
  const handleCssChange = (css) => {
    var changeRequest = { ...widget };
    _.set(changeRequest, 'properties.css', css);
    changeRequest = WidgetRegistry.processRequest([changeRequest])[0];
    dispatch(updateWidget(props.id, changeRequest, props.editor));
  }
  return (
    <Fragment>
      <Property.Section title='Spacing' expanded>
        <Property.Spacing
          title='Margin'
          value={properties.margin}
          onChange={(margin) => {
            handleChange({
              margin: margin
            });
          }}
        />
        <Property.Spacing
          title='Padding'
          value={properties.padding}
          onChange={(padding) => {
            handleChange({
              padding: padding
            });
          }}
        />
      </Property.Section>
      <Property.Background expanded
        value={properties.background}
        onChange={(background) => {
          handleChange({
            background: background
          });
        }}
        onImageChange={(image) => {
          handleBackgroundImageChange(image);
        }}
      />

      <Property.Border expanded value={properties.border} onChange={(border) => {
        handleChange({
          border: border
        });
      }}
      />
      <Property.Section title='Layout' expanded>
        <Property.Switch tooltip='Row extends full width of the page'
          title='Fluid'
          value={_.get(widget, 'properties.fluid', false)}
          onChange={() => {
            handleChange({
              fluid: !_.get(widget, 'properties.fluid', false)
            })
          }}
        />
      </Property.Section>
      <Property.Section title='Advanced' expanded>
        <Property.CSS title='Stylesheets' value={properties.css} onChange={handleCssChange} />
      </Property.Section>
    </Fragment>
  );
}
function FowardableRow({ forwardedRef, ...props }) {
  return <div ref={forwardedRef} {...props} />
};

Row.Property = React.memo(RowProperty);

export { Row };