import React, { PropTypes, Fragment, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import styles from './hubspotConfig.module.scss';
import cx from 'classnames';
import {
  Grid,
  Form,
  Input,
  Header,
  Dropdown,
  Button,
  Icon,
  Checkbox,
  Label,
  Segment
} from 'semantic-ui-react';
import { updateHubspot } from 'actions/integrations/hubspot';
import { activatingIntegrationSuccedded } from 'actions/integrations/integrations';

export function HubspotCofiguration() {
  const dispatch = useDispatch();
  const [email, setEmail] = useState(null);
  const [regionOptions] = useState([
    { key: 'eu1', text: 'eu1', value: 'eu1' },
    { key: 'na1', text: 'na1', value: 'na1' }
  ]);
  const integrations = useSelector(state => _.get(state, 'integrations.activated.items', []));
  const saving = useSelector(state => _.get(state, 'integrations.hubspot.changes.saving', false));
  const integration = integrations.filter(item => {
    return item != null;
  }).find(item => {
    return item.type == 'hubspot';
  });
  const customBehavioralEvents = _.get(integration, 'configuration.customBehavioralEvents');
  const supported = _.get(customBehavioralEvents, 'supported', false);
  const [region, setRegion] = useState({ type: integration.configuration.region });
  const [pageViewedEventName, setPageViewedEventName] = useState(_.get(customBehavioralEvents, 'pageViewedEvent', null));
  const [assetViewedEventName, setAssetViewedEventName] = useState(_.get(customBehavioralEvents, 'assetViewedEvent', null));
  const [assetDownloadEventName, setAssetDownloadEventName] = useState(_.get(customBehavioralEvents, 'assetDownloadEvent', null));
  const [syncPageViewedEvent, setSyncPageViewedEvent] = useState(true);
  const [syncReadAssetEvent, setSyncReadAssetEvent] = useState(true);
  const [syncAssetDownloadEvent, setSyncAssetDownloadEvent] = useState(true);
  const [error, setError] = useState('');
  const [enabled, setEnabled] = useState(_.get(customBehavioralEvents, 'enabled', false));

  useEffect(() => {
    let { email } = integration && integration.configuration;
    setEmail(email);
    if (integration != null) {
      setSyncPageViewedEvent(_.get(integration, 'configuration.syncPageViewedEvent'));
      setSyncReadAssetEvent(_.get(integration, 'configuration.syncReadAssetEvent'));
      setSyncAssetDownloadEvent(_.get(integration, 'configuration.syncAssetDownloadEvent'));
    }
  }, [])
  const handleRegionChange = (e, data) => {
    setRegion({
      type: data.value
    });
  }
  const handleResetClick = () => {
    setRegion({ type: _.get(integration, 'configuration.region', null) });
    setSyncPageViewedEvent(_.get(integration, 'configuration.syncPageViewedEvent'));
    setSyncReadAssetEvent(_.get(integration, 'configuration.syncReadAssetEvent'));
    setSyncAssetDownloadEvent(_.get(integration, 'configuration.syncAssetDownloadEvent'));
    setPageViewedEventName(_.get(customBehavioralEvents, 'pageViewedEvent', null));
    setAssetViewedEventName(_.get(customBehavioralEvents, 'assetViewedEvent', null));
    setAssetDownloadEventName(_.get(customBehavioralEvents, 'assetDownloadEvent', null))
  }
  const handleSaveClick = () => {

    const errorMessage = validateForm();
    if (errorMessage) {
      setError(errorMessage);
      return;
    } else {
      setError('')
    }

    var request = {
      region: region.type,
      syncPageViewedEvent: syncPageViewedEvent,
      syncReadAssetEvent: syncReadAssetEvent,
      syncAssetDownloadEvent: syncAssetDownloadEvent,
      customBehavioralEvents: {
        enabled: enabled,
        pageViewedEvent: pageViewedEventName,
        assetViewedEvent: assetViewedEventName,
        assetDownloadEvent: assetDownloadEventName
      }
    };
    dispatch(updateHubspot(request, (response) => {
      dispatch(activatingIntegrationSuccedded(response))
    }));
  }

  const handlePageViewedEventNameChange = (e) => {
    setPageViewedEventName(e.target.value);
  }
  const handleAssetViewedEventNameChange = (e) => {
    setAssetViewedEventName(e.target.value);
  }
  const handleAssetDownloadEventNameChange = (e) => {
    setAssetDownloadEventName(e.target.value);
  }

  const validateForm = () => {
    if(!supported) {
      return;
    }
    
    if (!pageViewedEventName || pageViewedEventName.trim().length === 0) {
      return 'Please provide valid Page Viewed Event Name';
    }

    if (!assetViewedEventName || assetViewedEventName.trim().length === 0) {
      return 'Please provide valid Asset Viewed Event Name.'
    }

    if (!assetDownloadEventName || assetDownloadEventName.trim().length === 0) {
      return 'Please provide valid Asset Download Event Name.'
    }

    return null;
  }

  return (
    <Grid columns="equal" className={styles.hubspot}>
      <Grid.Row>
        <Grid.Column>
          <Form className={styles.hubspotForm}>
            <Form.Group inline>
              <Form.Field>
                <div className={styles.wrapper}>
                  <label>EMAIL</label>
                  <Input
                    value={email}
                  />
                </div>
                <div className={styles.helpText}>Hubspot integration is currently active for this email account.</div>
              </Form.Field>
            </Form.Group>
          </Form>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column>
          <Form className={styles.hubspotForm}>
            <Form.Group inline>
              <Form.Field>
                <div className={styles.wrapper}>
                  <label>HUBSPOT REGION</label>
                  <Dropdown
                    selection
                    className={styles.dropdown}
                    placeholder='Select a region'
                    options={regionOptions}
                    value={region.type}
                    onChange={handleRegionChange}
                  />
                </div>
              </Form.Field>
            </Form.Group>
          </Form>
          {supported &&
            <Form className={styles.hubspotEvents}>
              <Grid.Row className={styles.event}>
                <Header as='h4'> Custom Behavioral Events </Header>
                <Checkbox toggle checked={enabled} onChange={() => setEnabled(!enabled)} />
              </Grid.Row>
              <div className={styles.description}>To enable cleverstory to push events to Hubspot, do the following steps.
                <br />1. Go to your Hubspot account -> Reports -> Analytics tool.
                <br />2. You'll find Custom Behavioral Events listed as a card.
                <br />3. Under that, Select the respective events and copy the value of 'Internal Name' and paste it into the respective text box here.
                <br />To know more, <a target='_blank' href='https://support.cleverstory.io/en/articles/6771556-cleverstory-x-hubspot-integration'>click here.</a></div>
              {enabled &&
                <div>
                  <div className={cx(styles.errorMessage, { [styles.active]: error })}>
                    <p><Icon name="exclamation circle"></Icon> {error}</p>
                  </div>
                  <Grid.Row>
                    <Grid.Column>
                      <div className={styles.wrapper}>
                        <label>PAGE VIEWED EVENT</label>
                        <Input labelPosition='left'
                          type='text'
                          placeholder='Enter your page viewed event name'
                          value={pageViewedEventName}
                          onChange={handlePageViewedEventNameChange}
                        />
                      </div>
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Column>
                      <div className={styles.wrapper}>
                        <label>ASSET VIEWED EVENT</label>
                        <Input labelPosition='left'
                          type='text'
                          placeholder={'Enter your asset viewed event name'}
                          value={assetViewedEventName}
                          onChange={handleAssetViewedEventNameChange}
                        />
                      </div>
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Column>
                      <div className={styles.wrapper}>
                        <label>ASSET DOWNLOAD EVENT</label>
                        <Input labelPosition='left'
                          type='text'
                          placeholder={'Enter your asset download event name'}
                          value={assetDownloadEventName}
                          onChange={handleAssetDownloadEventNameChange}
                        />
                      </div>
                    </Grid.Column>
                  </Grid.Row>
                </div>}
            </Form>
          }

          {!supported &&
            <>
              <Header textAlign='left' as='h4'> Sync Events </Header>
              <Grid.Row className={styles.event}>
                <Header.Subheader className={styles.header}>
                  Contact/Lead views an asset
                </Header.Subheader>
                <Checkbox toggle checked={syncReadAssetEvent} onChange={() => setSyncReadAssetEvent(!syncReadAssetEvent)} />
              </Grid.Row>
              <Grid.Row className={styles.event}>
                <Header.Subheader className={styles.header}>
                  Contact/Lead downloads an asset
                </Header.Subheader>
                <Checkbox toggle checked={syncAssetDownloadEvent} onChange={() => setSyncAssetDownloadEvent(!syncAssetDownloadEvent)} />
              </Grid.Row>
              <Grid.Row className={styles.event}>
                <Header.Subheader className={styles.header}>
                  Contact/Lead visits a page
                </Header.Subheader>
                <Checkbox toggle checked={syncPageViewedEvent} onChange={() => setSyncPageViewedEvent(!syncPageViewedEvent)} />
              </Grid.Row>
            </>
          }
          <div className={styles.actions}>
            <Button onClick={handleSaveClick}>
              <Icon loading={saving} name={saving ? 'spinner' : 'save'} />
              {saving ? 'Saving...' : 'Save Changes'}
            </Button>
            <Button onClick={handleResetClick}>
              Reset
            </Button>
          </div>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  )
}