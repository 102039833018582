
export default class BrowserService {

  static getCurrentBrowser(){
    var browser = '';

    // Opera 8.0+
    browser = !!window.opera || navigator.userAgent.indexOf(' OPR/') >= 0 ? 'opera' : browser;

    // Firefox 1.0+
    browser = typeof InstallTrigger !== 'undefined' ? 'firefox' : browser;

    // Safari 3.0+ "[object HTMLElementConstructor]" 
    browser = /constructor/i.test(window.HTMLElement) || (function (p) { return p.toString() === "[object SafariRemoteNotification]"; })(!window['safari'] || (typeof safari !== 'undefined')) ? 'safari' : browser;

    // Internet Explorer 6-11
    browser = /*@cc_on!@*/false || !!document.documentMode ? 'ie' : browser;
    var isIE = /*@cc_on!@*/false || !!document.documentMode;

    // Edge 20+
    browser = !isIE && !!window.StyleMedia ? 'edge' : browser;

    // Chrome 1 - 71
    browser = !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime) ? 'chrome' : browser;

    return browser;
  }
}