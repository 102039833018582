import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import _ from 'lodash';
import cx from 'classnames';

import styles from './container.module.scss';

import { Widget, getStyles } from '../widget.jsx';
import { useWidgetDrop } from "services/dnd.service";

import { updateProperties, updateWidget } from 'actions/widgetEditor';

import Animation from 'components/Animation';
import { WidgetRegistry } from '../registry.jsx';
import { QuickSettings, QuickSettingsProperty } from 'components/QuickSettings';

export const Container = React.memo((props) => {
  const ref = React.useRef(null);

  const editorContext = useSelector(state => {
    return state.widgetsEditor.editors[props.editor];
  });

  const dropRef = useWidgetDrop(props, ref, {
    acceptTargets: (type) => {
      return true;
    },
    drop: (request) => {
      var dropTarget = props.id;
      var dropIndex = request.index;
      var anchor = request.anchor;

      var direction = "down";
      var identifedTarget = false;

      if (anchor) {
        if (!identifedTarget && (anchor.top || anchor.bottom)) {
          const parentWidget = editorContext.widgetsById[props.id];
          identifedTarget = _.get(parentWidget, 'capabilities.droppable', true);

          direction = anchor.bottom ? 'down' : 'up';
        }
      }

      if (!identifedTarget) {
        return null;
      }

      return {
        target: dropTarget,
        index: dropIndex,
        direction: direction
      }
    }
  });

  const properties = props.properties;
  var widgetStyles = getStyles(properties);

  if(properties.width){
    widgetStyles = {
      ...widgetStyles,
      width: properties.width
    }
  }

  const empty = !props.widgets || props.widgets.length === 0;

  return (
    <div className={cx(styles.container, props.className, 
      styles[properties.horizontalAlignment],
      styles[properties.verticalAlignment],
      {
       [styles.empty]: empty, 
       [styles.vertical]: properties.direction === 'vertical'
      })}
      id={`widget_${props.id}`}
      ref={props.dragRef(dropRef(ref))}
      onMouseOver={props.onMouseOver}
      onMouseOut={props.onMouseOut}
      onClick={props.onClick}
      style={widgetStyles}>

      <Widget.Children widgets={props.widgets} parent={props.id} editor={props.editor} editable={props.editable} />
      {props.children}
    </div>
  )
});

function ContainerQuickSettings(props) {
  const dispatch = useDispatch();

  const [selected, setSelected] = useState('basic');

  const menuItems = [
    { id: 'basic', title: 'Basic', icon: 'icon-custom' },
    { id: 'spacing', title: 'Spacing', icon: 'icon-spacing' },
    { id: 'background', title: 'Background', icon: 'icon-background' },
    { id: 'border', title: 'Border', icon: 'icon-border' },
    { id: 'animation', title: 'Animation', icon: 'icon-spacing' },
    { id: 'advanced', title: 'Advanced', icon: 'icon-custom' }
  ]

  const handleMenuClick = (item) => {
    setSelected(item);
  }

  var widget = useSelector(state => {
    const editorContext = state.widgetsEditor.editors[props.editor];

    if (editorContext) {
      return editorContext.widgetsById[props.id];
    }

    return null;
  });
  
  if (!widget) {
    return false;
  }

  var properties = widget.properties;

  const handleChange = (changeRequest) => {
    dispatch(updateProperties({
      id: widget.id,
      change: changeRequest,
      context: props.editor
    }));
  }

  const handleBackgroundImageChange = (media, type) => {
    var changeRequest = { ...widget };
    _.set(changeRequest, 'properties.background.upload', {
      type: type,
      data: media
    });

    changeRequest = WidgetRegistry.processRequest([changeRequest])[0];
    dispatch(updateWidget(props.id, changeRequest, props.editor));
  }

  const handleCssChange = (css) => {
    var changeRequest = { ...widget };
    _.set(changeRequest, 'properties.css', css);

    changeRequest = WidgetRegistry.processRequest([changeRequest])[0];
    dispatch(updateWidget(props.id, changeRequest, props.editor));
  }

  return (
    <QuickSettings menuItems={menuItems} selected={selected} onMenuClick={handleMenuClick} >
      {selected === 'basic' &&
        <QuickSettingsProperty.Input
          title={'Width'}
          value={properties.width}
          onChange={(w) => {
            handleChange({
              width: w
            })
          }}
        />
      }

      {selected === 'spacing' &&
        <>
          <QuickSettingsProperty.Spacing
            title='Margin'
            value={properties.margin}
            onChange={(margin) => {
              handleChange({
                margin: margin
              });
            }}
          />
          <QuickSettingsProperty.Spacing
            title='Padding'
            value={properties.padding}
            onChange={(padding) => {
              handleChange({
                padding: padding
              });
            }}
          />

          <div className={styles.alignments}>
            <label>Alignment</label>

            <QuickSettingsProperty.Alignment
              title='Horizontal'
              tooltip='Controls where the element will anchor to within the column'
              value={properties.horizontalAlignment}
              onChange={(alignment) => {
                handleChange({
                  horizontalAlignment: alignment
                });
              }}
            />

            <QuickSettingsProperty.Alignment
              title='Vertical'
              tooltip='Controls where the element will anchor to within the column'
              value={properties.verticalAlignment}
              onChange={(alignment) => {
                handleChange({
                  verticalAlignment: alignment
                });
              }}
              vertical
            />

            <QuickSettingsProperty.Direction
              value={properties.direction}
              tooltip='Controls how elements within the column are placed'
              onChange={(direction) => {
                handleChange({
                  direction: direction
                });
              }}
            />
          </div>
        </>
      }

      {selected === "background" &&
        <QuickSettingsProperty.Background expanded
          value={properties.background}
          onChange={(background) => {
            handleChange({
              background: background
            });
          }}
          onImageChange={(image, type) => {
            handleBackgroundImageChange(image, type);
          }}
        />
      }

      {selected === 'border' &&
        <QuickSettingsProperty.Border expanded value={properties.border} onChange={(border) => {
          handleChange({
            border: border
          });
        }}
        />
      }

      {selected === 'animation' &&
        <QuickSettingsProperty.Animation
          title='Animation'
          value={properties.animation}
          onChange={(animation) => {
            handleChange({
              animation: animation
            });
          }}
        />
      }


      {selected === 'advanced' &&
        <QuickSettingsProperty.CSS title='Stylesheets' value={properties.css} onChange={handleCssChange} />
      }
    </QuickSettings>
  )
}

Container.QuickSettings = ContainerQuickSettings;