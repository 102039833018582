import { 
  AUTHENTICATE_PARDOT_REQUEST, AUTHTENTICATING_PARDOT_SUCCEEDED, AUTHTENTICATING_PARDOT_FAILED,
  UPDATE_PARDOT_REQUEST, UPDATING_PARDOT_SUCCEEDED, UPDATING_PARDOT_FAILED,
  FETCH_PARDOT_FORMS, FETCHING_PARDOT_FORMS_SUCCEEDED, FETCHING_PARDOT_FORMS_FAILED,
  FETCH_PARDOT_FORM, FETCHING_PARDOT_FORM_SUCCEEDED, FETCHING_PARDOT_FORM_FAILED
} from 'constants/actionTypes';

import axios from 'axios';
import { createAction } from 'redux-actions';
import buildUrl from 'build-url';
import { call, put, takeEvery } from 'redux-saga/effects';

const apiUrl = `${process.env.REACT_APP_API_URL}/api/2.0`;
const PARDOT_BASE_URL = `${apiUrl}/integrations/pardot`;

/*
* Authenticate Pardot
*/
export const authenticatePardot = createAction(AUTHENTICATE_PARDOT_REQUEST, (code, callback) => {
  return {
    code: code,
    callback: callback
  }
})

const authenticatePardotSucceeded = createAction(AUTHTENTICATING_PARDOT_SUCCEEDED, (response) => {
  return response;
})

const authenticatePardotFailed = createAction(AUTHTENTICATING_PARDOT_FAILED, (error) => {
  return error;
})

function* authenticatePardotSaga(action) {
  const { code, callback } = action.payload;

  let params = {
    code: code
  }

  let url = buildUrl(PARDOT_BASE_URL, {
    queryParams: params
  });

  const authenticatePardotAPI = () => {
    return axios.post(url).then(response => response.data);
  }

  try {
    const response = yield call(authenticatePardotAPI);
    if(response) {
      yield put(authenticatePardotSucceeded(response));
    } 
    if (callback) {
      callback(response);
    }
  } catch (error) {
    yield put(authenticatePardotFailed(error));
  }
}

/*
* Update Pardot
*/
export const updatePardot = createAction(UPDATE_PARDOT_REQUEST, (request, callback) => {
  return {
    request: request,
    callback: callback
  };
})

export const updatingPardotSucceeded = createAction(UPDATING_PARDOT_SUCCEEDED, (response) => {
  return response;
})

export const updatingPardotFailed = createAction(UPDATING_PARDOT_FAILED, (error) => {
  return error;
})

function* updatePardotSaga(action) {
  const { request, callback } = action.payload;

  const updatePardotAPI = () => {
    return axios.put(PARDOT_BASE_URL, request).then(response => response.data);
  }

  try {
    const response = yield call(updatePardotAPI);
    if(response) {
      yield put(updatingPardotSucceeded(response));
    } 
    if (callback) {
      callback(response);
    }
  } catch (error) {
    yield put(updatingPardotFailed(error));
  }
}

/*
* Fetching pardot forms
*/
export const fetchPardotForms = createAction(FETCH_PARDOT_FORMS, (page, limit) => {
  return {
    page: page,
    limit: limit
  }
});

const fetchPardotFormsSucceeded = createAction(FETCHING_PARDOT_FORMS_SUCCEEDED, response => {
  return response;
});

const fetchPardotFormsFailed = createAction(FETCHING_PARDOT_FORMS_FAILED, error => {
  return error;
})

function* fetchPardotFormsSaga(action) {

  const {page, limit} = action.payload;

  let url = buildUrl(`${PARDOT_BASE_URL}/forms`, {
    queryParams: {
      page: page,
      limit: limit
    }
  });

  
  const fetchPardotFormsAPI = () => {
    return axios.get(url).then(response => response.data);
  }

  try {
    const response = yield call(fetchPardotFormsAPI);
    if (response) {
      yield put(fetchPardotFormsSucceeded(response));
    }
  } catch (error) {
    yield put(fetchPardotFormsFailed(error));
  }
}

/*
* Fetching pardot form
*/
export const fetchPardotForm = createAction(FETCH_PARDOT_FORM, (id, context) => {
  return {
    id: id,
    context: context
  };
})

const fetchPardotFormSucceeded = createAction(FETCHING_PARDOT_FORM_SUCCEEDED, (id, response, context) => {
  return {
    id: id,
    response: response,
    context: context
  }
})

const fetchPardotFormFailed = createAction(FETCHING_PARDOT_FORM_FAILED, (id, error, context) => {
  return {
    id: id,
    error: error,
    context: context
  }
})

function* fetchPardotFormSaga(action) {
  const { id, context } = action.payload;

  const fetchPardotFormAPI = () => {
    return axios.get(`${PARDOT_BASE_URL}/forms/${id}`).then(response => response.data);
  }

  try {
    const response = yield call(fetchPardotFormAPI);
    if (response) {
      yield put(fetchPardotFormSucceeded(id, response, context));
    }
  } catch (error) {
    yield put(fetchPardotFormFailed(id, error, context));
  }
}

export default function* pardotSaga() {
  yield takeEvery(AUTHENTICATE_PARDOT_REQUEST, authenticatePardotSaga);
  yield takeEvery(UPDATE_PARDOT_REQUEST, updatePardotSaga);
  yield takeEvery(FETCH_PARDOT_FORMS, fetchPardotFormsSaga);
  yield takeEvery(FETCH_PARDOT_FORM, fetchPardotFormSaga);
}