import {  AUTHENTICATE_ZOHO_REQUEST, AUTHENTICATE_ZOHO_SUCCEDDED, AUTHENTICATE_ZOHO_FAILED,
} from 'constants/actionTypes';

import axios from 'axios';
import { createAction } from 'redux-actions';
import buildUrl from 'build-url';
import { call, put, takeEvery } from 'redux-saga/effects';

const apiUrl = `${process.env.REACT_APP_API_URL}/api/2.0`;
const ZOHO_BASE_URL = `${apiUrl}/integrations/zoho`;

/*
* Authenticate Zoho
*/
export const authenticateAccount = createAction(AUTHENTICATE_ZOHO_REQUEST, (request, callback) => {
  return { request, callback };
})

const authenticateZohoSucceeded = createAction(AUTHENTICATE_ZOHO_SUCCEDDED, (response) => {
  return response;
})

const authenticateZohoFailed = createAction(AUTHENTICATE_ZOHO_FAILED, (error) => {
  return error;
})

function* authenticateZohoSaga(action) {
  const { request, callback } = action.payload;

  let url = buildUrl(ZOHO_BASE_URL, {
    queryParams: request });

  const authenticateZohoAPI = () => {
    return axios.post(url).then(response => response.data);
  }

  try {
    const response = yield call(authenticateZohoAPI);
    if (response) {
      yield put(authenticateZohoSucceeded(response));
      if(callback) {
        callback(response);
      }
    }
  } catch (error) {
    yield put(authenticateZohoFailed(error));
  }
}

export default function* zohoSaga() {
  yield takeEvery(AUTHENTICATE_ZOHO_REQUEST, authenticateZohoSaga);
}