import {
  CREATE_JOURNEY_REQUEST, CREATING_JOURNEY_SUCCEEDED, CREATING_JOURNEY_FAILED,
  FETCH_JOURNEYS_REQUEST, FETCHING_JOURNEYS_SUCCEEDED, FETCHING_JOURNEYS_FAILED,
  FETCH_JOURNEY_REQUEST, FETCHING_JOURNEY_SUCCEEDED, FETCHING_JOURNEY_FAILED,
  UPDATE_JOURNEY_REQUEST, UPDATING_JOURNEY_SUCCEEDED, UPDATING_JOURNEY_FAILED,

  CREATE_JOURNEY_STEP, CREATE_JOURNEY_STEP_SUCCEEDED, CREATE_JOURNEY_STEP_FAILED,
  UPDATE_JOURNEY_STEP, UPDATE_JOURNEY_STEP_SUCCEEDED, UPDATE_JOURNEY_STEP_FAILED,
  FETCH_JOURNEY_STEP, FETCH_JOURNEY_STEP_SUCCEEDED, FETCH_JOURNEY_STEP_FAILED,
  REARRANDE_STEP_IN_JOURNEY, DELETE_STEP_FROM_JOURNEY,
  DELETE_JOURNEY_REQUEST, DELETE_JOURNEY_SUCCEEDED, DELETE_JOURNEY_FAILED
} from 'constants/actionTypes.jsx';

import { createAction } from 'redux-actions';
import axios from 'axios';
import { call, put, takeEvery, takeLatest } from 'redux-saga/effects';
import buildUrl from 'build-url';

const apiUrl = process.env.REACT_APP_API_URL;
const JOURNEY_BASE_URL = `${apiUrl}/api/2.1/journey`;

/**
 * Create a new Journey
*/
export const createJourney = createAction(CREATE_JOURNEY_REQUEST, (request, callback) => {
  return {
    request: request,
    callback: callback
  }
});

const creatingJourneySucceeded = createAction(CREATING_JOURNEY_SUCCEEDED);

const creatingJourneyFailed = createAction(CREATING_JOURNEY_FAILED);

function* createJourneySaga(action) {

  var { request, callback } = action.payload;

  const createJourneyAPI = () => {
    return axios({
      method: 'POST',
      url: JOURNEY_BASE_URL,
      data: request
    }).then(response => response.data);
  }

  try {
    const response = yield call(createJourneyAPI);
    yield put(creatingJourneySucceeded(response));
    if(callback){
      callback(response);
    }
  } catch (error) {
    yield put(creatingJourneyFailed(error));
  }
}

/* Fetch All Journeys */

export const fetchJourneys = createAction(FETCH_JOURNEYS_REQUEST, (page, limit) => {
  return {
    page: page,
    limit: limit
  }
});

const fetchingJourneysSucceeded = createAction(FETCHING_JOURNEYS_SUCCEEDED);

const fetchingJourneysFailed = createAction(FETCHING_JOURNEYS_FAILED);

function* fetchJourneysSaga(action) {

  var params = {
    page: action.payload.page,
    limit: action.payload.limit
  }

  const fetchJourneysAPI = () => {
    return axios({
      method: 'GET',
      url: JOURNEY_BASE_URL,
      params: params
    }).then(response => response.data);
  }

  try {
    const response = yield call(fetchJourneysAPI);
    yield put(fetchingJourneysSucceeded(response));
  } catch (error) {
    yield put(fetchingJourneysFailed(error));
  }
}

/* Fetch Journey */

export const fetchJourney = createAction(FETCH_JOURNEY_REQUEST, (id) => {
  return {
    id: id
  }
});

const fetchingJourneySucceeded = createAction(FETCHING_JOURNEY_SUCCEEDED, (response) => {
  return response;
});

const fetchingJourneyFailed = createAction(FETCHING_JOURNEY_FAILED, (error) => {
  return error;
});

function* fetchJourneySaga(action) {

  var id = action.payload.id;

  let url = buildUrl(JOURNEY_BASE_URL, {
    path: `/${id}`
  });

  const fetchJourneyAPI = () => {
    return axios({
      method: 'GET',
      url: url,
    }).then(response => response.data);
  }

  try {
    const response = yield call(fetchJourneyAPI);
    yield put(fetchingJourneySucceeded(response));
  } catch (error) {
    yield put(fetchingJourneyFailed(error));
  }
}

/* Update Journey */

export const updateJourney = createAction(UPDATE_JOURNEY_REQUEST, (id, request, callback) => {
  return {
    id: id,
    request: request,
    callback: callback
  }
});

const updatingJourneySucceeded = createAction(UPDATING_JOURNEY_SUCCEEDED);

const updatingJourneyFailed = createAction(UPDATING_JOURNEY_FAILED);

function* updateJourneySaga(action) {

  var { id, request, callback } = action.payload;

  let url = buildUrl(JOURNEY_BASE_URL, {
    path: `/${id}`
  });

  const updateJourneyAPI = () => {
    return axios({
      method: 'PUT',
      url: url,
      data: request
    }).then(response => response.data);
  }

  try {
    const response = yield call(updateJourneyAPI);
    yield put(updatingJourneySucceeded(response));
    if(callback){
      callback(response);
    }
  } catch (error) {
    yield put(updatingJourneyFailed(error));
  }
}

/*
* Create journey step
*/
export const createJourneyStep = createAction(CREATE_JOURNEY_STEP, (id, request, callback) => {
  return {
    id: id,
    request: request,
    callback: callback
  }
}) 

const createJourneyStepSucceeded = createAction(CREATE_JOURNEY_STEP_SUCCEEDED, (id, response) => {
  return {
    id: id,
    response: response
  }
})

const createJourneyStepFailed = createAction(CREATE_JOURNEY_STEP_FAILED, (id, error) => {
  return {
    id: id,
    error: error
  }
})

function* createJourneyStepSaga(action) {
  var { id, request, callback } = action.payload;

  let url = buildUrl(JOURNEY_BASE_URL, {
    path: `/${id}/step`
  });

  const createJourneyStepAPI = () => {
    return axios({
      method: 'POST',
      url: url,
      data: request
    }).then(response => response.data);
  }

  try {
    const response = yield call(createJourneyStepAPI);
    yield put(createJourneyStepSucceeded(id, response));
    if (callback) {
      callback(response);
    }
  } catch (error) {
    yield put(createJourneyStepFailed(id, error));
  }
}

/*
* Update journey step
*/
export const updateJourneyStep = createAction(UPDATE_JOURNEY_STEP, (id, step, request, callback) => {
  return {
    id: id,
    step: step,
    request: request,
    callback: callback
  }
})

const updateJourneyStepSucceeded = createAction(UPDATE_JOURNEY_STEP_SUCCEEDED, (id, step, response) => {
  return {
    id: id,
    step: step,
    response: response
  }
});

const updateJourneyStepFailed = createAction(UPDATE_JOURNEY_STEP_FAILED, (id, step, error) => {
  return {
    id: id,
    step: step,
    error: error
  }
})

function* updateJourneyStepSaga(action) {
  var { id, step, request, callback } = action.payload;

  let url = buildUrl(JOURNEY_BASE_URL, {
    path: `/${id}/step/${step}`
  });

  const updateJourneyStepAPI = () => {
    return axios({
      method: 'PUT',
      url: url,
      data: request
    }).then(response => response.data);
  }

  try {
    const response = yield call(updateJourneyStepAPI);
    yield put(updateJourneyStepSucceeded(id, step, response));
    if (callback) {
      callback(response);
    }
  } catch (error) {
    yield put(updateJourneyStepFailed(id, step, error));
  }
}

/*
* Update journey step
*/
export const fetchJourneyStep = createAction(FETCH_JOURNEY_STEP, (id, step) => {
  return {
    id: id,
    step: step
  }
});

const fetchJourneyStepSucceeded = createAction(FETCH_JOURNEY_STEP_SUCCEEDED, (id, step, response) => {
  return {
    id: id,
    step: step,
    response: response
  }
})

const fetchJourneyStepFailed = createAction(FETCH_JOURNEY_STEP_FAILED, (id, step, error) => {
  return {
    id: id,
    step: step,
    error: error
  }
})

function* fetchJourneyStepSaga(action) {
  const { id, step } = action.payload;

  let url = buildUrl(JOURNEY_BASE_URL, {
    path: `/step/${step}`
  });

  const fetchJourneyStepAPI = () => {
    return axios({
      method: 'GET',
      url: url,
    }).then(response => response.data);
  }

  try {
    const response = yield call(fetchJourneyStepAPI);
    yield put(fetchJourneyStepSucceeded(id, step, response));
  } catch (error) {
    yield put(fetchJourneyStepFailed(id, step, error));
  }
}

/*
* Rearrange steps in journey
*/
export const rearrangeStepInJourney = createAction(REARRANDE_STEP_IN_JOURNEY, (step, from, to) => {
  return {
    step: step,
    from: from,
    to: to
  }
})

/*
* Delete step from journey
*/
export const deleteStepFromJourney = createAction(DELETE_STEP_FROM_JOURNEY, (step) => {
  return step;
})

/*
* Delete journey
*/
export const deleteJourney = createAction(DELETE_JOURNEY_REQUEST, (id, callback) => {
  return {
    id: id, 
    callback: callback
  }
});

const deleteJourneySucceeded = createAction(DELETE_JOURNEY_SUCCEEDED, (id, response) => {
  return {
    id: id,
    response: response
  }
});

const deleteJourneyFailed = createAction(DELETE_JOURNEY_FAILED, (id, error) => {
  return {
    id: id,
    error: error
  }
});

function* deleteJourneySaga(action) {
  const { id, callback } = action.payload;

  let url = buildUrl(JOURNEY_BASE_URL, {
    path: `/${id}`
  });

  const deleteJourneyAPI = () => {
    return axios({
      method: 'DELETE',
      url: url,
    }).then(response => response.data);
  }

  try {
    const response = yield call(deleteJourneyAPI);
    yield put(deleteJourneySucceeded(id, response));
    if(callback) {
      callback(response);
    }
  } catch (error) {
    yield put(deleteJourneyFailed(id, error));
  }
}

export default function* journeysSaga() {
  yield takeLatest(CREATE_JOURNEY_REQUEST, createJourneySaga);
  yield takeLatest(FETCH_JOURNEYS_REQUEST, fetchJourneysSaga);
  yield takeLatest(FETCH_JOURNEY_REQUEST, fetchJourneySaga);
  yield takeLatest(UPDATE_JOURNEY_REQUEST, updateJourneySaga);
  yield takeEvery(CREATE_JOURNEY_STEP, createJourneyStepSaga);
  yield takeEvery(UPDATE_JOURNEY_STEP, updateJourneyStepSaga);
  yield takeEvery(FETCH_JOURNEY_STEP, fetchJourneyStepSaga);
  yield takeEvery(DELETE_JOURNEY_REQUEST, deleteJourneySaga);
};
