import {
  FETCH_ALIASES_REQUEST, FETCHING_ALIASES_SUCCEEDED, FETCHING_ALIASES_FAILED,
  CREATE_ALIAS_REQUEST, CREATING_ALIAS_SUCCEEDED, CREATING_ALIAS_FAILED,
  UPDATE_ALIAS_REQUEST, UPDATING_ALIAS_SUCCEEDED, UPDATING_ALIAS_FAILED,
  DELETE_ALIAS_REQUEST, DELETING_ALIAS_SUCCEEDED, DELETING_ALIAS_FAILED,
  INVALIDATE_ALIASES
} from 'constants/actionTypes.jsx';

import _ from 'lodash';

const initialAliasState = {
  creating: false,
  created: false,
  updating: false,
  updated: false,
  deleting: false,
  deleted: false,
  item: null
}

const initialState = {
  alias: initialAliasState,
  loading: false,
  items: []
}

function aliasReducer(state = initialState, action) {
  switch (action.type) {
    case CREATE_ALIAS_REQUEST: {
      return {
        ...state,
        alias: {
          ...initialAliasState,
          creating: true
        }
      }
    }

    case CREATING_ALIAS_SUCCEEDED: {
      const { response } = action.payload;

      var items = state.items || [];
      const resetCanonical = response.canonical; var items = state.items || [];
      
      items = items.map(alias => {
        if(resetCanonical){
          alias = {
            ...alias,
            canonical: false,
            deletable: true
          }
        }

        return alias;
      })

      items = [response, ...items];
      items = _.uniqBy(items, 'id');

      return {
        ...state,
        items: items,
        alias: {
          ...initialAliasState,
          creating: false,
          created: true,
          item: response
        }
      }
    }

    case CREATING_ALIAS_FAILED: {
      return {
        ...state,
        alias: {
          ...initialAliasState,
          creating: false,
          error: action.payload.error
        }
      }
    }

    case UPDATE_ALIAS_REQUEST: {
      return {
        ...state,
        alias: {
          ...initialAliasState,
          updating: true
        }
      }
    }

    case UPDATING_ALIAS_SUCCEEDED: {
      const { id, response } = action.payload;

      const resetCanonical = response.canonical;

      var items = state.items || [];
      items = items.map(alias => {
        if (alias.id === id) {
          return response;
        }

        if(resetCanonical){
          alias = {
            ...alias,
            canonical: false,
            deletable: true
          }
        }

        return alias;
      })

      return {
        ...state,
        items: items,
        alias: {
          ...initialAliasState,
          updating: false,
          updated: true,
          item: response
        }
      }
    }

    case UPDATING_ALIAS_FAILED: {
      return {
        ...state,
        alias: {
          ...initialAliasState,
          updating: false,
          error: action.payload.error
        }
      }
    }


    case DELETE_ALIAS_REQUEST: {
      return {
        ...state,
        alias: {
          ...initialAliasState,
          deleting: true
        }
      }
    }

    case DELETING_ALIAS_SUCCEEDED: {
      const { id } = action.payload;

      var items = state.items || [];
      items = items.filter(alias => alias.id != id);

      return {
        ...state,
        items: items,
        alias: {
          ...initialAliasState,
          deleting: false,
          deleted: true
        }
      }
    }

    case DELETING_ALIAS_FAILED: {
      return {
        ...state,
        alias: {
          ...initialAliasState,
          deleting: false,
          error: action.payload.error
        }
      }
    }


    case FETCH_ALIASES_REQUEST:
      return {
        ...state,
        loading: true
      }

    case FETCHING_ALIASES_SUCCEEDED:
      var { request, response } = action.payload;


      var items = state.items || [];
      if (response.currentPage == 0) {
        items = [...(response.records || [])];
      } else {
        items = [...items, ...(response.records || [])];
      }

      items = _.uniqBy(items, 'id');

      return {
        ...state,
        loading: false,
        items: items,
        pagination: {
          currentPage: response.currentPage,
          totalPages: response.totalPages
        }
      }

    case FETCHING_ALIASES_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload.error
      }
    
    case INVALIDATE_ALIASES:
      return initialState;
      
    default:
      return state;
  }
}

export { aliasReducer };