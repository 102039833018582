import React, { PropTypes, Fragment, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { createLiveElement, fetchLiveElements, cloneLiveElement } from 'actions/live_elements';

import {
  Route,
  Switch,
  useRouteMatch,
  useHistory
} from "react-router-dom";

import cx from 'classnames';
import _ from 'lodash';
import dateformat from 'dateformat';

import {
  Button,
  Grid,
  Icon,
  Image,
  Visibility,
  Placeholder
} from 'semantic-ui-react';

import Truncate from 'react-truncate';
import Tooltip from 'components/Tooltip';

import styles from './liveElements.module.scss';

export default function LiveElementsPage(props){
  const dispatch = useDispatch();
  const history = useHistory();
  let { path, url } = useRouteMatch();

  const createdPage = useSelector(state => _.get(state, 'liveElements.create.item.id'));
  const clonedPage = useSelector(state => _.get(state, 'liveElements.clone.item.id'));

  useEffect(() => {
    if (createdPage) {
      history.push(`${url}/${createdPage}/edit`);
    }
  }, [createdPage])

  useEffect(() => {
    if (clonedPage) {
      history.push(`${url}/${clonedPage}/edit`);
    }
  }, [clonedPage])

  return (
    <Switch>
      <Route>
        <Grid columns="equal" stretched className={styles.content}>
          <Grid.Row>
            <Grid.Column>
              <Switch>
                <Route path={`${path}`} exact>
                  <div className={styles.details}>
                    <AllLiveElements />
                  </div>
                </Route>
              </Switch>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Route>
    </Switch>
  )
}

function AllLiveElements({ createPage }) {
  const dispatch = useDispatch();

  const elements = useSelector(state => _.get(state, "liveElements.items", []));
  const pagination = useSelector(state => _.get(state, "liveElements.pagination", {}));
  const currentPage = _.get(pagination, "currentPage", 0);
  const totalPages = _.get(pagination, "totalPages", 0);
  const loading = useSelector(state => _.get(state, "liveElements.loading", false));
  const placeholder = loading && currentPage < 0;

  
  useEffect(() => {
    dispatch(fetchLiveElements(0, 20, "name", "ASC", false));
  }, []);

  const loadMorePages = () => {
    if (loading || currentPage === totalPages - 1) {
      return null;
    }

    dispatch(fetchLiveElements(currentPage + 1, 20, "name", "ASC", false));
  }

  return (
    <div className={cx(styles.elements, styles.all)}>
      <h1 className={styles.header}>Live Elements</h1>

      <Visibility className={styles.inner} onBottomVisible={loadMorePages} continuous fireOnMount={false}>
        <BlankTemplate />
        
        {placeholder && _.times(5, (index) => {
          return <LiveElementPlaceholderCard key={index} className={styles.element} />
        })}

        {!placeholder && elements.map(element => {
          return <LiveElementCard key={element.id} className={styles.element} value={element} />
        })}
      </Visibility>
    </div>

  );
}

export function BlankTemplate(props) {
  const dispatch = useDispatch();

  const creating = useSelector(state => _.get(state, 'pages.create.creating'));
  const creatingCurrent = useSelector(state => _.get(state, 'pages.create.item.template')) == null && creating;

  const handleCreateClicked = () => {
    var request = {
      name: "Untitled Element"
    }

    dispatch(createLiveElement(request));
  }

  return (
    <>
      <div className={cx(styles.template, styles.blank, props.className, {
      [styles.creating]: creatingCurrent
    })}>
        <div className={styles.image} onClick={handleCreateClicked}>
          <div className="image" />
          <div className={styles.add}>{creatingCurrent ? 'Creating...' : '+'}</div>
        </div>
        <div className={styles.details}>
          <div className={styles.left}>
            <div className={styles.name}>Blank</div>
            <div className={styles.category}>Start from scratch</div>
          </div>  
        </div>
      </div>
    </>
  );
}

export function LiveElementCard(props){
  const dispatch = useDispatch();
  const history = useHistory();
  
  let { url } = useRouteMatch();
  const element = props.value || {};

  const cloning = useSelector(state => _.get(state, 'liveElements.clone.cloning'));
  const cloningCurrent = useSelector(state => _.get(state, 'liveElements.clone.element')) === element.id && cloning;

  const _handleEditPage = () => {
    history.push(`${url}/${element.id}/edit`);
  }

  const _handleClonePage = () => {
    if(cloning){
      return;
    }

    dispatch(cloneLiveElement(element.id, {
      name: `Clone of ${element.name}`
    }));
  }

  const author = () => {
    const checkDefault = (value, defaultValue) => value == null ? defaultValue : value;

    var firstName = checkDefault(_.get(element, 'createdBy.firstName'), '');
    var lastName = checkDefault(_.get(element, 'createdBy.lastName'), '');

    return `${firstName} ${lastName}`;
  }

  return (
    <div className={cx(styles.page, props.className, {
      [styles.cloning]: cloningCurrent
    })}>
      <div className={styles.image}>
        <div className={styles.analytics}>
          <div className={styles.date}>
            <strong>Published:</strong>
            <span>{dateformat(element.modifiedDate, 'dd mmm yyyy')}</span>
          </div>
          <div className={styles.date}>
            <strong>Last Update:</strong>
            <span>{dateformat(element.modifiedDate, 'dd mmm yyyy')}</span>
          </div>
        </div>

        <Button.Group className={styles.actions}>
          <Tooltip content='Edit Element' inverted size='mini' position='top center'>
            <Button icon onClick={_handleEditPage}>
              <Icon name='pencil' size='large'/>
            </Button>
          </Tooltip>
          <Tooltip content='Clone Element' inverted size='mini' position='top center'>
            <Button icon onClick={_handleClonePage}>
              <Icon name='clone outline' size='large' />
            </Button>
          </Tooltip>
        </Button.Group>
        <Image src='/images/pages/default_page.jpg' wrapped ui={false} fuild="true" />
        {cloningCurrent &&
          <div className={styles.progress}>Cloning...</div>
        }
      </div>
      <div className={styles.details}>
        <div className={styles.left}>
          <div className={styles.name}>{element.name}</div>
          <div className={styles.author}>By {author()}</div>
        </div>  
      </div>
    </div>
  );
}

export function LiveElementPlaceholderCard(props){

  return (
    <div className={cx(styles.page, styles.placeholder, props.className)}>
      <div className={styles.image}>
        <Placeholder fluid>
          <Placeholder.Image />
        </Placeholder>  
      </div>
      <div className={styles.details}>
        <div className={styles.left}>
          <Placeholder>
            <Placeholder.Paragraph>
              <Placeholder.Line length='medium' />
              <Placeholder.Line length='short' />
              <Placeholder.Line length='very long' />
            </Placeholder.Paragraph>
          </Placeholder>
        </div>  
      </div>
    </div>
  );
}