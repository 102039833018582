import React, { useState, Fragment } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { updateWidget, updateProperties } from 'actions/widgetEditor';

import _ from 'lodash';

import {
  Header,
  Grid,
  Segment,
  Icon,
  Accordion,
  Form,
  Input,
  Select,
  Dropdown,
  Button
} from 'semantic-ui-react';

import { Property } from 'components/Properties';
import { WidgetRegistry } from '../registry';
import ContentModal from './content';

export default function MenuProperties(props) {
  const dispatch = useDispatch();

  var widget = useSelector(state => {
    var widgetEditor = state.widgetsEditor.editors[props.editor];

    if(widgetEditor) {
      return widgetEditor.widgetsById[props.widget.id];
    }

    return null;
  });
  
  if(!widget){
    return false;
  }

  var properties = widget.properties;

  const handleChange = (changeRequest) => {
    dispatch(updateProperties({
      id: props.id,
      change: changeRequest,
      context: props.editor
    }));
  }

  const handleCssChange = (css) => {
    var changeRequest = { ...widget };
    _.set(changeRequest, 'properties.css', css);

    changeRequest = WidgetRegistry.processRequest([changeRequest])[0];
    dispatch(updateWidget(props.id, changeRequest));
  }

  return (
    <>
      <Property.Section title='Spacing' expanded>
        <Property.Spacing
          title='Margin'
          value={properties.margin}
          onChange={(margin) => {
            handleChange({
              margin: margin
            });
          }}
        />
        <Property.Spacing
          title='Padding'
          value={properties.padding}
          onChange={(padding) => {
            handleChange({
              padding: padding
            });
          }}
        />
      </Property.Section>

      <Property.Border expanded value={properties.border} onChange={(border) => {
          handleChange({
            border: border
          });
        }}
      />
      <Property.Section title='Layout' expanded>
        <Layout widget={widget} onChange={handleChange} />
      </Property.Section>
      <Property.Section title='Content' expanded>
        <Content widget={widget} onChange={handleChange} editor={props.editor}/>
      </Property.Section>
      <Property.Section title='Advanced' expanded>
        <Property.CSS title='Stylesheets' value={properties.css} onChange={handleCssChange} />
      </Property.Section>
    </>
  )
}

function Content(props) {

  return (
    <>
      <Property title='Menu'>
        <ContentModal trigger={
          <Button>Edit Menu</Button>
        } widget={props.widget} editor={props.editor}/>
      </Property>
    </>
  )
}

function Layout(props) {
  var widget = props.widget;
  var properties = widget.properties;

  const handleInvertedChange = (value) => {
    var change = {
      inverted: value
    };

    props.onChange(change);
  }

  const handleBorderlessChange = (value) => {
    var change = {
      borderless: value
    };

    props.onChange(change);
  }

  return (
    <Fragment>
      <Property.Switch tooltip='Inverted'
        title='Inverted'
        value={properties.inverted || false}
        onChange={handleInvertedChange}
      />

      <Property.Switch tooltip='Borderless'
        title='Borderless'
        value={properties.borderless || false}
        onChange={handleBorderlessChange}
      />
    </Fragment>
  )
}