import React, { PropTypes, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import cx from 'classnames';
import _ from 'lodash';

import { fetchStoryboards, fetchStoryboard } from 'actions/storyboard';
import { fetchCustomFields } from 'actions/customFields';

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
  useParams,
  useRouteMatch
} from "react-router-dom";

import {
  Container,
  Sidebar,
  Loader,
  Image,
  Header
} from 'semantic-ui-react';

import WebSocket from 'components/WebSocket';
import ApplicationMenu from 'components/ApplicationMenu';
import AddAssetContainer from 'components/Asset/AddAsset';

import SigninPage from 'pages/anonymous/signin';
import AcceptInvitation from 'pages/anonymous/acceptInvitation';

import ContentPage from 'pages/content';
import AssetView from 'pages/asset';
import PagesView from 'pages/pages/view';
import ReportsPage from 'pages/reports/';
import SettingsView from 'pages/settings';
import { PreviewPage, EmbeddedPageView } from 'pages/pages/preview';

import { PreviewLiveElementPage, EmbeddedLiveElementView } from 'pages/live_elements/preview';

import JourneyView from 'pages/journey';

import styles from './application.module.scss';
import AuthService from 'services/auth.service';
import AuthInterceptor from 'services/auth.interceptor.js';
import UserNotFound from 'images/userNotFound.svg';

export default function Application(props) {
  const dispatch = useDispatch();

  const [access, setAccess] = useState(true);

  const profile = useSelector(state => _.get(state, 'users.me', null));
  const cleverstory = useSelector(state => _.get(state, 'users.me.permissions.cleverstory'));

  useEffect(() => {
    if (cleverstory != null && !cleverstory) {
      // dispatch(logoutUser());
      setAccess(false);
    }
  }, [profile]);

  const storyboard = useSelector(state => {
    const storyboards = _.get(state, 'storyboards.items', []);
    if (storyboards && storyboards.length > 0) {
      return storyboards[0];
    }
    return null;
  });

  return (
    <Router>
      <Switch>
        <Route path="/login">
          <SigninPage />
        </Route>

        <Route path="/invitation/:id">
          <AcceptInvitation />
        </Route>

        <Route path="/logout">
          <SigninPage />
        </Route>

        <ProtectedRoute>
          <ApplicationLoader>
            <Route path="/" exact>
              {storyboard &&
                <Redirect to={`/app/${storyboard.id}/pages`} />
              }
            </Route>
            <Route path="/app/:id">
              <WebSocket>
                <Storyboard />
              </WebSocket>
            </Route>
          </ApplicationLoader>
        </ProtectedRoute>
      </Switch>
    </Router>
  );
}

function ProtectedRoute({ children, ...rest }) {
  var authenticated = AuthService.isAuthenticated();
  return (
    <Route {...rest}
      render={() =>
        authenticated ? (
          children
        ) : (
            <Redirect
              to={{
                pathname: "/login"
              }}
            />
          )
      }
    />
  );
}

function Home(props) {
  let { path, url } = useRouteMatch();
  return (
    <Redirect to={`${url}/content`} />
  )
}

function ApplicationLoader(props) {
  const dispatch = useDispatch();
  const storyboards = useSelector(state => _.get(state, 'storyboards.items', []));
  const storyboard = useSelector(state => _.get(state, 'storyboards.storyboard.item'));
  const loading = useSelector(state => state.storyboards.loading);

  useEffect(() => {
    dispatch(fetchStoryboards());
    dispatch(fetchCustomFields());
  }, []);

  if (loading || storyboards.length == 0) {
    return (
      <Loader active>Loading Storyboard...</Loader>
    );
  }

  return props.children;
}

function Storyboard(props) {
  const dispatch = useDispatch();

  const storyboard = useSelector(state => _.get(state, 'storyboards.storyboard.item'));
  const loading = useSelector(state => _.get(state, 'storyboards.storyboard.loading'));
  const fetchingProfile = useSelector(state => _.get(state, 'users.fetchingProfile', false));

  const cleverstory = useSelector(state => _.get(state, 'users.me.permissions.cleverstory'));

  const [expanded, setExpanded] = useState(false);
  const [showAddAsset, setShowAddAsset] = useState(false);

  let { path, url } = useRouteMatch();
  const { id } = useParams();

  //Side Effect
  useEffect(() => {
    if (id) {
      dispatch(fetchStoryboard(id));
      AuthInterceptor.updateAppId(id);
    }
  }, [id]);

  //Functions
  const _handleMenuStateChanged = () => {
    setExpanded(!expanded);
  }

  const _handleAddAssetStateChanged = () => {
    setShowAddAsset(!showAddAsset);
  }

  console.log("Render Storyboard");

  if (loading || !storyboard) {
    return (
      <Loader active>Loading Storyboard...</Loader>
    );
  }
  return (
    <Switch>
      <Route path={`${path}/pages/:id/preview`} exact>
        <PreviewPage />
      </Route>
      <Route path={`${path}/pages/:id/preview/embedded`} exact>
        <EmbeddedPageView />
      </Route>
      <Route path={`${path}/settings/live_elements/:id/preview`} exact>
        <PreviewLiveElementPage />
      </Route>
      <Route path={`${path}/settings/live_elements/:id/preview/embedded`} exact>
        <EmbeddedLiveElementView />
      </Route>
      <Route>
        <Container fluid className={cx(styles.application, { [styles.open]: expanded })}>
          <AddAssetContainer expanded={showAddAsset} onVisibilityChange={_handleAddAssetStateChanged} />
          <Sidebar.Pushable>
            <ApplicationMenu expanded={expanded}
              onVisibilityChange={_handleMenuStateChanged}
              onAddAssetClicked={_handleAddAssetStateChanged} />
            <Sidebar.Pusher>
              {!fetchingProfile && cleverstory &&
                <>
                  <Route path={`${path}/content`}>
                    <ContentPage />
                  </Route>
                  <Route path={`${path}/assets/:id`}>
                    <AssetView />
                  </Route>
                  <Route path={`${path}/pages`}>
                    <PagesView />
                  </Route>
                  <Route path={`${path}/reports`}>
                    <ReportsPage />
                  </Route>
                  <Route path={`${path}/journeys`}>
                    <JourneyView />
                  </Route>
                  <Route path={`${path}/settings`}>
                    <SettingsView />
                  </Route>
                </>
              }
              {!fetchingProfile && !cleverstory &&
                <span className={styles.errorMessageContainer}>
                  <Image src={UserNotFound} className={styles.errorMessage}/>
                  <Header as='h3'>Mmm... who moved my cheese?</Header>
                  <span>The Curiosity Rover has landed successfully. Unfortunately you don't have access to this. <br/>Kindly contact your administrator for access!  </span>
                </span>
              }
            </Sidebar.Pusher>
          </Sidebar.Pushable>
        </Container>
      </Route>
    </Switch>
  );
}