import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateProperties } from 'actions/widgetEditor';
import { fetchFreshmarketerLists } from 'actions/integrations/freshmarketer';

import _ from 'lodash';
import cx from 'classnames';

import {
  Form as SemanticForm,
  Dropdown,
  Icon,
  Input,
  Label,
  Popup,
  Table
} from 'semantic-ui-react';

import { Banner } from 'components/Banner';

import { guid, validateEmail } from 'services/util';
import Truncate from 'react-truncate';
import Tooltip from 'components/Tooltip';
import { Widget, getStyles } from '../widget.jsx';
import { Property } from 'components/Properties';
import { QuickSettings, QuickSettingsProperty } from 'components/QuickSettings';

import { WidgetContext } from 'pages/pages/editor/editor.jsx';

import styles from './form.module.scss';

export const Form = (props) => {
  const dispatch = useDispatch();
  const [step, setStep] = useState(null);

  var widgets = props.widgets || [];
  const properties = props.properties;

  const { entityReference } = React.useContext(WidgetContext);

  useEffect(() => {
    setStep(widgets[0]);
  }, [])

  return (
    <>
      {entityReference.type === 'page' &&
        <>
          <SemanticForm className={styles.form}>
            <StepWidget step={step} editor={props.editor} editable={props.editable} />
          </SemanticForm>
          {props.children}

          <div className={styles.actions}>
            {props.selected &&
              <Steps
                editor={props.editor}
                widgets={widgets}
                value={step}
                onChange={(value) => {
                  setStep(value);
                }}
              />
            }

            {props.selected &&
              <Fields widget={widgets[0]} editor={props.editor} />
            }
          </div>
        </>
      }

      {entityReference.type !== 'page' &&
        <div className={cx('ui', 'medium', 'modal', styles.modal, props.className)}
          id={`widget_${props.id}`}
          onMouseOver={props.onMouseOver}
          onMouseOut={props.onMouseOut}
          onClick={props.onClick}
        >
          <SemanticForm className={styles.form}>
            <StepWidget step={step} editor={props.editor} editable={props.editable} />
          </SemanticForm>
          {props.children}

          <div className={styles.actions}>
            {props.selected &&
              <Steps
                editor={props.editor}
                widgets={widgets}
                value={step}
                onChange={(value) => {
                  setStep(value);
                }}
              />
            }

            {props.selected &&
              <Fields widget={widgets[0]} editor={props.editor} />
            }
          </div>
        </div>
      }
    </>
  );
}

function Steps({ widgets, value, onChange, editor }) {
  const { scale } = React.useContext(WidgetContext);

  const [style, setStyle] = useState({});

  useEffect(() => {
    if (scale < 1) {
      var updatedScale = 1 / scale;
      setStyle(
        {
          transform: `scale(${updatedScale})`,
          transformOrigin: `bottom right`,
          marginLeft: '40px'
        }
      )
    } else {
      setStyle({})
    }
  }, [scale])

  widgets = widgets || [];
  widgets = useSelector(state => {
    var items = [];

    var editorContext = state.widgetsEditor.editors[editor];

    if (editorContext) {
      items = widgets.map(widget => {
        return editorContext.widgetsById[widget];
      }).filter(widget => {
        return widget.type === 'widgets.form.step';
      }).map(widget => {
        return { key: widget.id, value: widget.id, text: widget.name }
      });
    }

    return items;
  });

  return (
    <div className={styles.steps} style={style}>
      State {' '}
      <Dropdown
        inline
        options={widgets}
        value={value}
        onChange={(e, data) => {
          onChange(data.value);
        }}
      />
    </div>
  );
}

const defaultFields = [
  { id: 'email', label: 'Email' },
  { id: 'firstName', label: 'First Name' },
  { id: 'lastName', label: 'Last Name' },
  { id: 'company', label: 'Company' },
  { id: 'title', label: 'Title' },
  { id: 'workPhone', label: 'WorkPhone' }
]

function Fields({ widget, editor }) {
  const { scale } = React.useContext(WidgetContext);

  const [style, setStyle] = useState({});

  useEffect(() => {
    if (scale < 1) {
      var updatedScale = 1 / scale;
      setStyle(
        {
          transform: `scale(${updatedScale})`,
          transformOrigin: `bottom right`,
          marginLeft: '40px'
        }
      )
    } else {
      setStyle({})
    }
  }, [scale])

  widget = useSelector(state => {
    var item = null;

    var editorContext = state.widgetsEditor.editors[editor];

    if (editorContext) {
      item = editorContext.widgetsById[widget]
    }

    return item;
  });

  var widgetsById = useSelector(state => {
    var editorContext = state.widgetsEditor.editors[editor];

    if (editorContext) {
      return editorContext.widgetsById;
    }


    return null;
  })

  const getIconForField = (field) => {

    switch (field) {
      case 'firstName':
      case 'lastName':
        return 'user outline';
      case 'company':
        return 'building outline';
      case 'email':
        return 'envelope outline';
      case 'workPhone':
        return 'phone';
      case 'title':
        return 'address card outline';

      default:
        return 'clipboard outline';
    }
  }

  const isSelected = (id) => {
    var widgets = widget.widgets;

    var selected = widgets.find(widgetId => {
      var item = widgetsById[widgetId];
      var properties = item.properties;

      if (properties && properties.id && properties.id === id) {
        return item;
      }

      return null;
    })

    return selected != null;
  }

  const { onCreateWidget } = React.useContext(WidgetContext);

  const handleAddField = (field) => {
    if (isSelected(field.id)) {
      return false;
    }

    var widgetRequest = {
      type: 'widgets.form.input',
      name: field.label,
      properties: field,
      capabilities: {
        clonable: false
      },
    }

    onCreateWidget(editor, [widgetRequest], widget.id, -1, false);
  }

  if (!widget) {
    return false;
  }

  return (
    <Popup
      trigger={<div className={styles.steps} style={style}>Add Fields</div>}
      position='bottom right'
      pinned
      on='click'
      className={styles.fieldsPopup}
    >
      <Table basic='very' selectable>
        <Table.Body>
          {defaultFields.map(field => {
            var selected = isSelected(field.id);
            return (
              <Table.Row onClick={handleAddField.bind(this, field)}>
                <Table.Cell>
                  <Icon name={getIconForField(field.id)} />
                  <span>{field.label}</span>
                </Table.Cell>
                <Table.Cell collapsing>
                  <Icon className={cx(styles.fieldSelectionToggle, { [styles.toggleSelected]: selected })}
                    name={selected ? 'toggle on' : 'toggle off'} />
                </Table.Cell>
              </Table.Row>
            )
          })}
        </Table.Body>
      </Table>
    </Popup>
  );
}

function StepWidget({ step, ...rest }) {
  const widget = useSelector(state => {
    const editorContext = state.widgetsEditor.editors[rest.editor];

    if (editorContext) {
      return editorContext.widgetsById[step];
    }

    return null;
  });

  if (!widget) {
    return false;
  }

  return (
    <div>
      <Widget.Children widgets={widget.widgets} editor={rest.editor} editable={rest.editable} />
    </div>
  );
}

function FormQuickSettings(props) {
  const dispatch = useDispatch();

  const integrations = useSelector(state => _.get(state, 'integrations.activated.items', []));
  const freshmarketer = integrations.filter(item => {
    return item != null;
  }).find(item => {
    return item.type = 'freshmarketer';
  });

  const [selected, setSelected] = useState('action');

  const menuItems = [
    { id: 'action', title: 'Action', icon: 'icon-layout' }
  ]

  const handleMenuClick = (item) => {
    setSelected(item);
  }

  var widget = useSelector(state => {
    const editorContext = state.widgetsEditor.editors[props.editor];

    if (editorContext) {
      return editorContext.widgetsById[props.id];
    }

    return null;
  });
  if (!widget) {
    return false;
  }

  var properties = widget.properties;
  var integrationProperties = _.get(widget.properties, 'integrations') || [];
  var freshmarketerProperty = integrationProperties.find(item => {
    return item.type === 'properties.integrations.freshmarketer';
  });

  const handleChange = (changeRequest) => {
    dispatch(updateProperties({
      id: widget.id,
      change: changeRequest,
      context: props.editor
    }));
  }

  return (
    <QuickSettings menuItems={menuItems} selected={selected} onMenuClick={handleMenuClick} >
      <EmailProperty
        value={properties.emails}
        onChange={(value) => {
          handleChange({
            emails: value
          })
        }}
      />

      {freshmarketer &&
        <FreshMarketerProperty
          value={freshmarketerProperty}
          onChange={(value) => {
            var updated = integrationProperties.filter(item => {
              return item.type != "properties.integrations.freshmarketer"
            });

            const request = {
              type: "properties.integrations.freshmarketer",
              lists: value
            };

            updated = [...updated, request]

            handleChange({
              integrations: updated
            });
          }}
        />
      }
    </QuickSettings>
  )
}

Form.QuickSettings = FormQuickSettings;

function FormProperty(props) {
  const dispatch = useDispatch();

  const integrations = useSelector(state => _.get(state, 'integrations.activated.items', []));
  const freshmarketer = integrations.filter(item => {
    return item != null;
  }).find(item => {
    return item.type = 'freshmarketer';
  });

  var widget = useSelector(state => {
    const editorContext = state.widgetsEditor.editors[props.editor];

    if (editorContext) {
      return editorContext.widgetsById[props.id];
    }

    return null;
  });
  
  if (!widget) {
    return false;
  }

  var properties = widget.properties;
  var integrationProperties = _.get(widget.properties, 'integrations') || [];
  var freshmarketerProperty = integrationProperties.find(item => {
    return item.type === 'properties.integrations.freshmarketer';
  });

  console.log(freshmarketerProperty);

  const handleChange = (changeRequest) => {
    dispatch(updateProperties({
      id: widget.id,
      change: changeRequest,
      context: props.editor
    }));
  }

  return (
    <>
      <Property.Section title='Action' expanded>
        <EmailProperty
          value={properties.emails}
          onChange={(value) => {
            handleChange({
              emails: value
            })
          }}
        />

      </Property.Section>

      {freshmarketer &&
        <Property.Section title='Freshmarketer' expanded>
          <FreshMarketerProperty
            value={freshmarketerProperty}
            onChange={(value) => {
              var updated = integrationProperties.filter(item => {
                return item.type != "properties.integrations.freshmarketer"
              });

              const request = {
                type: "properties.integrations.freshmarketer",
                lists: value
              };

              updated = [...updated, request]

              handleChange({
                integrations: updated
              });
            }}
          />
        </Property.Section>
      }
    </>
  );
}
Form.Property = FormProperty;
function EmailProperty({ value, onChange }) {
  const [input, setInput] = useState('');
  const emails = value || [];
  const handleKeyPressed = (e) => {
    if (e.key === "Enter") {
      handleChanges();
    }
  }
  const handleChanges = () => {
    var updated = emails;
    if (!updated) {
      updated = [];
    }
    if (input && input.trim() && validateEmail(input.trim())) {
      updated = [...updated, input.trim()];
      updated = _.uniq(updated);
      onChange(updated);
    }
    setInput('');
  }
  const deleteValue = (index) => {
    var updated = [...emails];
    updated.splice(index, 1);
    onChange(updated);
  }
  return (
    <Property title={'Notify'} tooltip={'Notify users'}>
      {emails.map((item, index) => {
        return (
          <Tooltip content={item} inverted>
            <Label key={`${item}-${index}`} image className={cx(styles.email)}>
              <div className={styles.value}><Truncate lines={1}>{item}</Truncate></div>
              <Icon name='delete' onClick={deleteValue.bind(this, index)} />
            </Label>
          </Tooltip>
        );
      })}
      <Input className={styles.emailInput} size='mini'
        placeholder='Email address'
        value={input}
        onChange={(e) => {
          setInput(e.target.value);
        }}
        onBlur={handleChanges}
        onKeyPress={handleKeyPressed}
      />
    </Property>
  );
}
function FreshMarketerProperty({ value, onChange }) {
  const dispatch = useDispatch();
  const lists = useSelector(state => _.get(state, 'integrations.freshmarketer.lists.items', []));
  const pagination = useSelector(state => _.get(state, 'integrations.freshmarketer.lists.pagination', {}));
  const { current, totalPages } = pagination;
  useEffect(() => {
    dispatch(fetchFreshmarketerLists(1));
  }, []);
  const handleChange = (e, data) => {
    console.log(data);
    if (data.value[data.value.length - 1] === 'loadMore') {
      dispatch(fetchFreshmarketerLists(current + 1));
      return;
    }
    var values = (data.value || []).map(id => {
      var option = lists.find(list => {
        return list.id === id;
      })
      return option;
    });
    onChange(values);
  }
  var selectedOptions = (_.get(value, 'lists') || []).map(option => {
    return {
      key: option.id,
      value: option.id,
      text: option.name,
      content: (
        <p>{option.name}</p>
      )
    }
  })
  var options = lists.map(option => {
    return {
      key: option.id,
      value: option.id,
      text: option.name,
      content: (
        <p>{option.name}</p>
      )
    }
  });
  options = [...selectedOptions, ...options].reduce((x, y) => {
    var index = x.findIndex((e) => {
      return e.key === y.key;
    });
    if (index < 0) {
      return [...x, y];
    } else {
      x[index] = y;
      return x;
    }
  }, []);
  if (current < totalPages) {
    options = [...options, {
      key: 'loadMore',
      value: 'loadMore',
      text: 'Load More',
      content: (
        <p>Load More</p>
      )
    }]
  }
  return (
    <Property.Dropdown title='Lists'
      tooltip='Freshmarketer lists to push contacts'
      placeholder='Freshmarketer lists'
      selection
      multiple
      options={options}
      value={(_.get(value, 'lists') || []).map(item => {
        return item.id;
      })}
      onChange={handleChange}
    />
  )
}