import React, { useState, Fragment, useRef, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateProperties, updateWidgetContent } from 'actions/widgetEditor';

import _ from 'lodash';
import cx from 'classnames';

import { Widget, getStyles, getDimensionStyles } from '../widget.jsx';
import { Resizable } from '../resizable';
import { Property } from 'components/Properties';
import { QuickSettings, QuickSettingsProperty } from 'components/QuickSettings';

import styles from './afterEffects.module.scss';
import { useWidgetDrop } from "services/dnd.service";

import AfterEffect from 'components/AfterEffect';


const calculateImageDimension = (id, dimension, delta) => {
  return {
    width: dimension.width,
    height: dimension.height
  }
}

export const AfterEffects = React.memo((props) => {
  const ref = useRef(null);
  const dropRef = useWidgetDrop(props, ref, {

  });

  const properties = props.properties;
  const dimension = _.get(properties, 'dimension') || {};
  const play = _.get(properties, 'play') || "autoPlay";
  const loop = _.get(properties, 'loop');

  var widgetStyles = getStyles(props.properties);
  var dimensionStyles = getDimensionStyles(dimension);

  widgetStyles = {
    ...widgetStyles,
    ...dimensionStyles
  };

  const url = _.get(props, "content", null);

  return (
    <div className={cx(styles.animation, props.className)}
      id={`widget_${props.id}`}
      ref={props.dragRef(dropRef(ref))}
      onMouseOver={props.onMouseOver}
      onMouseOut={props.onMouseOut}
      onClick={props.onClick}
      style={widgetStyles}
    >
      {url &&
        <AfterEffect play={play} loop={loop} height={dimension.height} width={dimension.width} url={url} />
      }

      {!url &&
        <div>NO IMAGE</div>
      }

      <Widget.Children widgets={props.widgets} parent={props.id} editor={props.editor} editable={props.editable} />
      {props.children}
    </div>
  )
});

function AfterEffectsQuickSettings(props) {
  const dispatch = useDispatch();

  const [selected, setSelected] = useState('image');

  const menuItems = [
    { id: 'image', title: 'Image', icon: 'icon-background' },
    { id: 'dimension', title: 'Dimension', icon: 'icon-spacing' },
    { id: 'advanced', title: 'Advanced', icon: 'icon-custom' }
  ]

  const handleMenuClick = (item) => {
    setSelected(item);
  }

  var editorContext = useSelector(state => state.widgetsEditor.editors[props.editor]);
  var widget = editorContext.widgetsById[props.id];
  var updatingContent = _.get(widget, 'updatingContent', false);
  var content = _.get(props, "widget.content", null)

  useEffect(() => {
    var dimension = _.get(props, "widget.properties.dimension", null) || {};
    var changes = calculateImageDimension(props.id, dimension, {
      x: 0,
      y: 0
    });

    handleChange({
      dimension: {
        ...dimension,
        ...changes
      }
    });

  }, [content]);

  if (!widget) {
    return false;
  }

  var properties = widget.properties;

  const handleChange = (changeRequest) => {
    dispatch(updateProperties({
      id: widget.id,
      change: changeRequest,
      context: props.editor
    }));
  }

  const handleImageChange = (file) => {
    if (typeof file === 'string' || file.type.match('application/json')) {
      loadImageFile(file).then(url => {
        dispatch(updateWidgetContent(widget.id, {
          content: {
            file: url
          }
        }, props.editor
        ));
      });
    }
  }

  const loadImageFile = (imageFile) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.onload = e => resolve(e.target.result)
      reader.onerror = reject
      reader.readAsDataURL(imageFile)
    });
  }

  const unit = _.get(properties, 'dimension.unit') || 'percent';

  return (
    <QuickSettings menuItems={menuItems} selected={selected} onMenuClick={handleMenuClick} >
      {selected === 'image' &&
        <QuickSettingsProperty.Image
          accept='application/json'
          updating={updatingContent}
          value={_.get(props, "widget.content", null)}
          onChange={handleImageChange}
        />
      }

      {selected === 'dimension' &&
        <>
          <QuickSettingsProperty.Input
            title='Width'
            value={_.get(properties, 'dimension.width')}
            onChange={(value) => {
              const dimension = _.get(properties, 'dimension') || {};

              var changes = {
                width: value
              };

              handleChange({
                dimension: {
                  ...dimension,
                  ...changes
                }
              });
            }}
          />
          <QuickSettingsProperty.Input
            title='Height'
            value={_.get(properties, 'dimension.height')}
            onChange={(value) => {
              const dimension = _.get(properties, 'dimension') || {};

              var changes = {
                height: value
              };

              handleChange({
                dimension: {
                  ...dimension,
                  ...changes
                }
              });

            }}
          />
          <QuickSettingsProperty.Dropdown
            title='Play'
            tooltip='Play'
            selection
            options={[
              { key: 'autoPlay', value: 'autoPlay', text: 'Auto Play' },
              { key: 'onHover', value: 'onHover', text: 'On Hover' },
            ]}
          value={_.get(properties, 'play') || 'autoPlay'}
            onChange={(e, data) => {
              console.log(data.value);
              handleChange({
                play: data.value
              })
            }}
          />
          <QuickSettingsProperty.Dropdown
            title='Loop'
            tooltip='Loop'
            selection
            options={[
              { key: 'true', value: true, text: 'True' },
              { key: 'false', value: false, text: 'False' },
            ]}
            value={_.get(properties, 'loop') || false}
            onChange={(e, data) => {
              console.log(data.value);
              handleChange({
                loop: data.value
              })
            }}
          />
        </>
      }
{
  selected === 'advanced' &&
  <>

  </>
}
    </QuickSettings >
  )
}

AfterEffects.QuickSettings = AfterEffectsQuickSettings;

function AfterEffectsProperty(props) {
  const dispatch = useDispatch();

  var editorContext = useSelector(state => state.widgetsEditor.editors[props.editor]);
  var widget = editorContext.widgetsById[props.id];

  if (!widget) {
    return false;
  }

  const handleChange = (changeRequest) => {
    dispatch(updateProperties({
      id: widget.id,
      change: changeRequest,
      context: props.editor
    }));
  }

  return (
    <Fragment>
      <Property.ImageUpload
        value={props.content}
        onChange={(background) => {
          handleChange({
            background: background
          });
        }}
        onImageChange={(image, type) => {

        }}
      />
    </Fragment>
  )
}

AfterEffects.Property = AfterEffectsProperty;