import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation, useParams, useRouteMatch } from 'react-router-dom';

import {
  fetchVisitorSessions, fetchTopVisitors,
  fetchAggregatedBrowsersMetrics, fetchAggregatedDevicesMetrics,
  fetchAggregatedCountriesMetrics, fetchSessionsTimeSeries,
  fetchAggregatedRefererMetrics, fetchAggregatedUtmCampaignMetrics,
  fetchAggregatedUtmSourceMetrics, fetchAggregatedUtmMediumMetrics,
  fetchAggregatedPagesMetrics,
  fetchActiveSessions, selectVisitorSession,
  fetchVisitor, fetchSessionsForVisitor,
  fetchVisitorAnalytics
} from 'actions/reports/visitors';

import { fetchVisitorSession, updateFiltersForReports } from 'actions/reports/reports';

import cx from "classnames";
import moment from 'moment';
import _ from "lodash";
import { Map, TileLayer, Marker, Popup as ReactLeafletPopup } from 'react-leaflet';
import { normalise } from 'services/clusters.service';

import {
  Grid,
  Statistic,
  Segment,
  Label,
  Button,
  Popup
} from 'semantic-ui-react';

import {
  LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip,
} from 'recharts';

import SidePane from 'components/SidePane'
import VisitorDetailView from './components/visitorDetail/VisitorDetailView';
import SessionDetailView from './components/sessionDetail/SessionDetailView';
import { Filters } from './components/filters/filters';

import Measure from 'react-measure'
import { VisitorRow, TopVisitorRow, Pagination, CategoryReport, PageReport, DatePicker, EmptyMessage } from './components/row';
import { countries as countriesJSON } from 'constants/countries';
import { padNumber } from 'services/util';

import styles from './reports.module.scss';
import { generateQueryParams, parseQueryParams, convertDatesToTimezone } from 'services/report.service';
import BuildUrl from 'build-url';
import { downloadVisitorReports } from 'actions/reports/visitors'

export function VisitorReports(props) {
  const dispatch = useDispatch();
  const history = useHistory();
  let { path, url } = useRouteMatch();

  const filters = useSelector(state => _.get(state, 'reports.common.filters', []));

  const [reportDate, setReportDate] = useState(null);
  const [visitor, setVisitor] = useState(null);
  const [session, setSession] = useState(null);

  useEffect(() => {
    if (reportDate) {
      const updatedUrl = generateReportUrl(url, reportDate);
      history.replace(updatedUrl);
    }
  }, [reportDate])

  useEffect(() => {
    const parsedDate = parseQueryParams(props.location.search);
    setReportDate(parsedDate);
  }, []);

  const generateReportUrl = (url, reportDate) => {
    const queryParams = generateQueryParams(reportDate, null);

    const paramsString = props.location.search;
    const params = new URLSearchParams(paramsString);

    const query = {
      ...params,
      ...queryParams
    };

    const updated = BuildUrl(url, {
      queryParams: query
    });

    return updated;
  }

  const handleSessionClick = (session) => {
    if (visitor) {
      session.visitor = visitor;
    }

    dispatch(selectVisitorSession(session));
    setSession(session);
  }

  const handleDateChange = (value) => {
    setReportDate(value);
  }

  var sidePaneWidth = '30vw';
  if (session) {
    if (visitor) {
      sidePaneWidth = '70vw';
    } else {
      sidePaneWidth = '40vw';
    }
  }

  return (
    <Grid className={styles.visitors}>
      <ReportHeader
        value={reportDate}
        onChange={handleDateChange}
      />
      <Grid.Row columns='3'>
        <Grid.Column className={styles.grow} stretched>
          <Report className={styles.realTime} name='Real Time Analytics'>
            <ActiveSessionsMap
              reportDate={reportDate}
              filters={filters}
            />
          </Report>
        </Grid.Column>
        <Grid.Column stretched>
          <TopVisitorsReport
            filters={filters}
            reportDate={reportDate}
            onVisitorClick={setVisitor}
          />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column>
          <VisitorReport
            filters={filters}
            reportDate={reportDate}
            visitor={visitor}
            session={session}
            onVisitorClick={setVisitor}
            onSessionClick={handleSessionClick}
          />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row columns='equal'>
        <Grid.Column>
          <ReffererReport
            filters={filters}
            reportDate={reportDate}
          />
        </Grid.Column>
        <Grid.Column>
          <CountryReport
            filters={filters}
            reportDate={reportDate}
          />
        </Grid.Column>
        <Grid.Column>
          <SessionsTimeSeriesReport
            filters={filters}
            reportDate={reportDate}
          />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row columns='equal'>
        <Grid.Column>
          <UTMCampaignReport
            filters={filters}
            reportDate={reportDate}
          />
        </Grid.Column>
        <Grid.Column>
          <UTMMediumReport
            filters={filters}
            reportDate={reportDate}
          />
        </Grid.Column>
        <Grid.Column>
          <UTMSourceReport
            filters={filters}
            reportDate={reportDate}
          />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row columns='3'>
        <Grid.Column>
          <BrowserReport
            filters={filters}
            reportDate={reportDate}
          />
        </Grid.Column>
        <Grid.Column>
          <DeviceReport
            filters={filters}
            reportDate={reportDate}
          />
        </Grid.Column>
        <Grid.Column>
          <PagesReport
            filters={filters}
            reportDate={reportDate}
          />
        </Grid.Column>
      </Grid.Row>
      <SidePane right
        close
        open={visitor}
        width={sidePaneWidth}
        onClose={() => {
          setVisitor(null)
          setSession(null)
        }}
        closeOnDocumentClick={true}>
        <div className={styles.visitorDetail}>
          <div className={styles.visitor}>
            <VisitorDetail
              reportDate={reportDate}
              filters={filters}
              visitorId={visitor ? visitor.id : null}
              selectedSession={session}
              onSessionClicked={handleSessionClick}
            />
          </div>
          {session &&
            <div className={styles.session}>
              <SessionDetail session={session} />
            </div>}
        </div>
      </SidePane>

      <SidePane right
        close
        open={!visitor && session}
        width={'40vw'}
        onClose={() => {
          setVisitor(null)
          setSession(null)
        }}
        closeOnDocumentClick={true}>
        <div className={styles.session}>
          <SessionDetail session={session} />
        </div>
      </SidePane>
    </Grid>
  );
}

function ReportHeader(props) {
  const dispatch = useDispatch();

  const analytics = useSelector(state => _.get(state, 'reports.visitors.cumulativeAnalytics.item')) || {};
  const filters = useSelector(state => _.get(state, 'reports.common.filters', []));

  useEffect(() => {
    var requestFitlers = normalise(props.filters);
    if (props.value) {
      dispatch(fetchVisitorAnalytics({
        ...convertDatesToTimezone(props.value),
        filters: requestFitlers
      }));
    }
  }, [props.value, filters]);

  const _renderTimeSpent = (seconds) => {
    let minutes = padNumber(Math.floor(seconds / 60), 2);
    seconds = padNumber(Math.floor(seconds % 60), 2);
    var hours = padNumber(Math.floor(minutes / 60), 2);
    minutes = padNumber(minutes % 60, 2);

    hours = hours > 0 ? `${hours}:` : ''
    return `${hours}${minutes}:${seconds}`;
  }

  const handleFiltersChange = (filters) => {
    dispatch(updateFiltersForReports(filters))
  }

  return (
    <>
      <Grid.Row className={styles.header} columns='equal'>
        <Grid.Column width={3}>
          <DatePicker
            value={props.value}
            onChange={props.onChange}
          />
        </Grid.Column>
        <Grid.Column floated='right' textAlign='right' verticalAlign='middle'>
          <Statistic horizontal size='mini' className={styles.reportStatistics}>
            <Statistic.Value>{analytics.visitors || 0}</Statistic.Value>
            <Statistic.Label>VISITORS</Statistic.Label>
          </Statistic>
          <Statistic horizontal size='mini' className={styles.reportStatistics}>
            <Statistic.Value>{analytics.sessions || 0}</Statistic.Value>
            <Statistic.Label>SESSIONS</Statistic.Label>
          </Statistic>
          <Statistic horizontal size='mini' className={styles.reportStatistics}>
            <Statistic.Value>{analytics.assets || 0}</Statistic.Value>
            <Statistic.Label>ASSET VIEWS</Statistic.Label>
          </Statistic>
          <Statistic horizontal size='mini' className={styles.reportStatistics}>
            <Statistic.Value>{analytics.downloads || 0}</Statistic.Value>
            <Statistic.Label>DOWNLOADS</Statistic.Label>
          </Statistic>
          <Statistic horizontal size='mini' className={styles.reportStatistics}>
            <Statistic.Value>{_renderTimeSpent(analytics.duration || 0)}</Statistic.Value>
            <Statistic.Label>TIME SPENT</Statistic.Label>
          </Statistic>
        </Grid.Column>
      </Grid.Row>
      <Segment basic className={styles.reportFilters}>
        <Filters
          cluster={null}
          value={filters}
          editable={true}
          onChange={handleFiltersChange}
        />
      </Segment>
    </>
  );
}

function Report({ name, type, reportDate, visitorReducer, className, children, filters }) {
  const dispatch = useDispatch();

  const [downloadSuccess, setDownloadSuccess] = useState(false);

  const downloading = useSelector(state => _.get(state, `reports.visitors.${visitorReducer}.downloading`));

  const handleDownloadClick = () => {
    var requestFitlers = normalise(filters);

    dispatch(downloadVisitorReports(type, visitorReducer, {
      ...convertDatesToTimezone(reportDate),
      filters: requestFitlers,
    }, () => {
      setDownloadSuccess(true);
      setTimeout(() => {
        setDownloadSuccess(false);
      }, 3000);
    }));
  }

  return (
    <Segment className={cx(styles.report, className)}>
      <div className={cx("title", styles.reportHeader)}>
        <Label>{name}</Label>
        {type != null &&
          <SuccessPopupMessage
            trigger={
              <Button className={styles.downloadButton} onClick={handleDownloadClick}>
                {downloading ? 'Downloading...' : 'Download'}
              </Button>
            }
            open={downloadSuccess}
            onVisibilityChange={() => { setDownloadSuccess(false) }}
          />
        }
      </div>
      {children}
    </Segment>
  );
}

function VisitorReport(props) {
  const dispatch = useDispatch();

  const sessions = useSelector(state => _.get(state, 'reports.visitors.sessions.items')) || [];
  const pagination = useSelector(state => _.get(state, 'reports.visitors.sessions.pagination'));
  const loading = useSelector(state => _.get(state, 'reports.visitors.sessions.loading')) || false;

  useEffect(() => {
    if (props.reportDate) {
      handleFetchVisitorSessions(0);
    }
  }, [props.reportDate, props.filters]);

  const handlePageChange = (page) => {
    handleFetchVisitorSessions(page);
  }

  const handleFetchVisitorSessions = (page) => {
    var filters = normalise(props.filters);
    dispatch(fetchVisitorSessions(page, {
      ...convertDatesToTimezone(props.reportDate),
      filters: filters
    }));
  }

  return (
    <Report name='Sessions' className={styles.visitorReport} type="visitor_sessions" reportDate={props.reportDate} visitorReducer="sessions">
      {sessions.length > 0 &&
        <Grid>
          {!loading && sessions.map(session => {
            return <VisitorRow key={session.id}
              session={session}
              visitor={session.visitor}
              onVisitorClick={props.onVisitorClick}
              onSessionClick={props.onSessionClick} />
          })}

          {pagination &&
            <Grid.Row>
              <Grid.Column textAlign='center'><Pagination pagination={pagination} onClick={handlePageChange} /></Grid.Column>
            </Grid.Row>
          }
        </Grid>}

      {!loading && sessions.length == 0 &&
        <EmptyMessage title="Insufficient data to generate report." />}
    </Report>
  )
}

function TopVisitorsReport(props) {
  const dispatch = useDispatch();

  const visitors = useSelector(state => _.get(state, 'reports.visitors.top_visitors.items')) || [];
  const loading = useSelector(state => _.get(state, 'reports.visitors.top_visitors.loading')) || false;

  useEffect(() => {
    var filters = normalise(props.filters);
    if (props.reportDate) {
      dispatch(fetchTopVisitors(0, {
        ...convertDatesToTimezone(props.reportDate),
        filters: filters
      }));
    }
  }, [props.reportDate, props.filters]);

  return (
    <Report name='Top Visitors' type="top_visitors" className={styles.topVisitorsReport} reportDate={props.reportDate} visitorReducer="top_visitors">
      {!loading && visitors.length > 0 &&
        <Grid>
          {visitors.map(visitor => {
            return <TopVisitorRow key={visitor ? visitor.id : null} visitor={visitor} onVisitorClick={props.onVisitorClick} />
          })}

        </Grid>}

      {!loading && visitors.length == 0 &&
        <EmptyMessage title="Insufficient data to generate report." />}
    </Report>
  );
}

function BrowserReport(props) {
  const dispatch = useDispatch();

  const browsers = useSelector(state => _.get(state, 'reports.visitors.browsers')) || {};

  useEffect(() => {
    if (props.reportDate) {
      handleFetchBrowsersMetrics(0)
    }
  }, [props.reportDate, props.filters]);

  const handlePageChange = (page) => {
    handleFetchBrowsersMetrics(page)
  }

  const handleFetchBrowsersMetrics = (page) => {
    var filters = normalise(props.filters);
    dispatch(fetchAggregatedBrowsersMetrics(page, {
      ...convertDatesToTimezone(props.reportDate),
      filters: filters
    }));
  }

  return (
    <Report name='Browsers Breakdown' reportDate={props.reportDate} type="browsers_breakdown" visitorReducer="browsers" >
      {browsers &&
        <CategoryReport keyLabel='BROWSERS' valueLabel='Sessions' metrics={browsers} onClick={handlePageChange} />
      }
    </Report>
  );
}

function DeviceReport(props) {
  const dispatch = useDispatch();

  const devices = useSelector(state => _.get(state, 'reports.visitors.devices')) || {};

  useEffect(() => {
    if (props.reportDate) {
      handleFetchDeviceMetrics(0);
    }
  }, [props.reportDate, props.filters]);

  const handlePageChange = (page) => {
    handleFetchDeviceMetrics(page);
  }

  const handleFetchDeviceMetrics = (page) => {
    var filters = normalise(props.filters);
    dispatch(fetchAggregatedDevicesMetrics(page, {
      ...convertDatesToTimezone(props.reportDate),
      filters: filters
    }));
  }

  return (
    <Report name='Devices Breakdown' reportDate={props.reportDate} type="devices_breakdown" visitorReducer="devices">
      {devices &&
        <CategoryReport keyLabel='DEVICES' valueLabel='Sessions' metrics={devices} onClick={handlePageChange} />
      }
    </Report>
  );
}

function CountryReport(props) {
  const dispatch = useDispatch();

  const countries = useSelector(state => _.get(state, 'reports.visitors.countries')) || {};

  useEffect(() => {
    if (props.reportDate) {
      handleFetchCountriesMetrics(0);
    }
  }, [props.reportDate, props.filters]);

  const handlePageChange = (page) => {
    handleFetchCountriesMetrics(page);
  }

  const handleFetchCountriesMetrics = (page) => {
    var filters = normalise(props.filters);
    dispatch(fetchAggregatedCountriesMetrics(page, {
      ...convertDatesToTimezone(props.reportDate),
      filters: filters
    }));
  }

  return (
    <Report name='Geos Breakdown' reportDate={props.reportDate} type="countries" visitorReducer="countries">
      {countries &&
        <CategoryReport keyLabel='COUNTRIES' valueLabel='Sessions' metrics={countries} onClick={handlePageChange} />
      }
    </Report>
  );
}

function SessionsTimeSeriesReport(props) {
  const dispatch = useDispatch();

  const sessions = useSelector(state => _.get(state, 'reports.visitors.session_series.items')) || [];
  const data = sessions.map(item => {
    return {
      name: moment(item.name, 'DD MMM YYYY').format('DD/MM'),
      sessions: item.sessions
    }
  });

  const [width, setWidth] = useState(null)
  const [height, setHeight] = useState(null)

  useEffect(() => {
    var filters = normalise(props.filters);
    if (props.reportDate) {
      dispatch(fetchSessionsTimeSeries(0, {
        ...convertDatesToTimezone(props.reportDate),
        filters: filters
      }));
    }
  }, [props.reportDate, props.filters]);

  return (
    <Report name='Sessions' className={styles.sessionReport}>
      <Measure bounds
        onResize={(contentRect) => {
          setWidth(contentRect.bounds.width);
          setHeight(contentRect.bounds.height);
        }}>

        {({ measureRef }) =>
          <div ref={measureRef} className={styles.chart}>
            <LineChart
              width={width}
              height={350}
              data={data}
              margin={{
                top: 28, right: 0, left: 0, bottom: 0,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" axisLine={false} tickLine={false} minTickGap={75} />
              <YAxis hide />
              <Tooltip />
              <Line type="monotone" name='Sessions' dataKey="sessions" stroke="#4285f4" dot={false} strokeWidth={2} />
            </LineChart>
          </div>
        }
      </Measure>
    </Report>
  );
}

function ReffererReport(props) {
  const dispatch = useDispatch();

  const referers = useSelector(state => _.get(state, 'reports.visitors.referers')) || {};

  useEffect(() => {
    if (props.reportDate) {
      handleFetchRefererMetrics(0);
    }
  }, [props.reportDate, props.filters]);

  const handlePageChange = (page) => {
    handleFetchRefererMetrics(page);
  }

  const handleFetchRefererMetrics = (page) => {
    var filters = normalise(props.filters);
    dispatch(fetchAggregatedRefererMetrics(page, {
      ...convertDatesToTimezone(props.reportDate),
      filters: filters
    }));
  }

  return (
    <Report name='Top Referrer' reportDate={props.reportDate} type="top_refferer" visitorReducer="referers">
      {referers &&
        <CategoryReport keyLabel='TOP REFFERER' valueLabel='Sessions' metrics={referers} onClick={handlePageChange} />
      }
    </Report>
  );
}

function UTMCampaignReport(props) {
  const dispatch = useDispatch();

  const utmCampaigns = useSelector(state => _.get(state, 'reports.visitors.utmCampaigns')) || {};

  useEffect(() => {
    if (props.reportDate) {
      fetchUtmCampaignMetrics(0);
    }
  }, [props.reportDate, props.filters]);

  const handlePageChange = (page) => {
    fetchUtmCampaignMetrics(page);
  }

  const fetchUtmCampaignMetrics = (page) => {
    var filters = normalise(props.filters);
    dispatch(fetchAggregatedUtmCampaignMetrics(page, {
      ...convertDatesToTimezone(props.reportDate),
      filters: filters
    }));
  }

  return (
    <Report name='UTM Campaigns' reportDate={props.reportDate} type="utm_campaigns" visitorReducer="utmCampaigns">
      {utmCampaigns &&
        <CategoryReport keyLabel='UTM CAMPAIGNS' valueLabel='Sessions' metrics={utmCampaigns} onClick={handlePageChange} />
      }
    </Report>
  );
}

function UTMSourceReport(props) {
  const dispatch = useDispatch();

  const utmSources = useSelector(state => _.get(state, 'reports.visitors.utmSources')) || {};

  useEffect(() => {
    if (props.reportDate) {
      handleFetchUtmSourceMetrics(0);
    }
  }, [props.reportDate]);

  const handlePageChange = (page) => {
    handleFetchUtmSourceMetrics(page);
  }

  const handleFetchUtmSourceMetrics = (page) => {
    var filters = normalise(props.filters);
    dispatch(fetchAggregatedUtmSourceMetrics(page, {
      ...convertDatesToTimezone(props.reportDate),
      filters: filters
    }));
  }

  return (
    <Report name='UTM Sources' reportDate={props.reportDate} type="utm_sources" visitorReducer="utmSources">
      {utmSources &&
        <CategoryReport keyLabel='UTM SOURCES' valueLabel='Sessions' metrics={utmSources} onClick={handlePageChange} />
      }
    </Report>
  );
}

function UTMMediumReport(props) {
  const dispatch = useDispatch();

  const utmMedium = useSelector(state => _.get(state, 'reports.visitors.utmMedium')) || {};

  useEffect(() => {
    if (props.reportDate) {
      handleFetchUtmMediumMetrics(0);
    }
  }, [props.reportDate]);

  const handlePageChange = (page) => {
    handleFetchUtmMediumMetrics(page);
  }

  const handleFetchUtmMediumMetrics = (page) => {
    var filters = normalise(props.filters);
    dispatch(fetchAggregatedUtmMediumMetrics(page, {
      ...convertDatesToTimezone(props.reportDate),
      filters: filters
    }));
  }

  return (
    <Report name='UTM Medium' reportDate={props.reportDate} type="utm_mediums" visitorReducer="utmMedium">
      {utmMedium &&
        <CategoryReport keyLabel='UTM MEDIUM' valueLabel='Sessions' metrics={utmMedium} onClick={handlePageChange} />
      }
    </Report>
  );
}

function PagesReport(props) {
  const dispatch = useDispatch();

  const pages = useSelector(state => _.get(state, 'reports.visitors.pages')) || {};

  useEffect(() => {
    if (props.reportDate) {
      handlePageChange(0);
    }
  }, [props.reportDate, props.filters]);

  const handlePageChange = (page) => {
    var filters = normalise(props.filters);
    dispatch(fetchAggregatedPagesMetrics(page, {
      ...convertDatesToTimezone(props.reportDate),
      filters: filters
    }));
  }

  return (
    <Report name='Pages' reportDate={props.reportDate} filters={props.filters} type="pages" visitorReducer="pages">
      {pages &&
        <PageReport keyLabel='PAGE' valueLabel='Sessions' metrics={pages} onClick={handlePageChange} />
      }
    </Report>
  );
}

function ActiveSessionsMap(props) {
  const dispatch = useDispatch();

  const activeSessions = useSelector(state => _.get(state, 'reports.visitors.active_sessions')) || {};

  useEffect(() => {
    handleFetchActiveSessions();
  }, []);

  const handleFetchActiveSessions = () => {
    var filters = normalise(props.filters);
    dispatch(fetchActiveSessions({

      filters: filters
    }));
  }

  var sessions = (activeSessions.items || []).map(item => {
    var countrycode = item.name;

    var country = countriesJSON.find(item => {
      return item.country_code === countrycode;
    })

    if (!country) {
      return null;
    }

    var latlng = country.latlng;

    return {
      sessions: item.sessions,
      latlng: latlng
    }
  }).filter(item => item !== null);

  var bounds = [
    [51.505, -0.09]
  ];

  if (sessions.length > 0) {
    bounds = sessions.map(item => {
      return item.latlng;
    })
  }

  return (
    <Map className={styles.realtimeAnalysis} bounds={bounds} boundsOptions={{ padding: [500, 500] }} maxZoom={2}
      style={{
        height: "400px"
      }}>
      <TileLayer
        attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
        url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
      />

      {sessions.map((session, index) => {
        return (
          <Marker position={session.latlng} key={index}>
            <ReactLeafletPopup>
              <span>{`${session.sessions} Sessions`}</span>
            </ReactLeafletPopup>
          </Marker>
        )
      })}
    </Map>
  )
}

function VisitorDetail({ visitorId, reportDate, filters, onSessionClicked, selectedSession }) {

  const dispatch = useDispatch();

  const visitor = useSelector(state => _.get(state, 'reports.visitors.visitor.item', null))
  const sessions = useSelector(state => _.get(state, 'reports.visitors.visitor.sessions.items', []))

  const loadingVisitor = useSelector(state => _.get(state, 'reports.visitors.visitor.loading', null))
  const loadingSessions = useSelector(state => _.get(state, 'reports.visitors.visitor.sessions.loading', false))

  useEffect(() => {
    if (visitorId && reportDate) {
      dispatch(fetchVisitor(visitorId))

      var requestFitlers = normalise(filters);
      dispatch(fetchSessionsForVisitor(visitorId, {
        ...convertDatesToTimezone(reportDate),
        filters: requestFitlers
      }, 0))
    }
  }, [visitorId, reportDate, filters])

  return (
    <VisitorDetailView
      visitor={visitor}
      sessions={sessions}
      loadingVisitor={loadingVisitor}
      loadingSessions={loadingSessions}
      selectedSession={selectedSession}
      onSessionClicked={onSessionClicked}
    />
  )
}

function SessionDetail(props) {
  const dispatch = useDispatch();

  const session = useSelector(state => _.get(state, 'reports.visitors.session.item', null));
  const assetSessions = useSelector(state => _.get(state, 'reports.visitors.session.assetSessions.items', []))
  const loading = useSelector(state => _.get(state, 'reports.visitors.session.assetSessions.loading', false))

  useEffect(() => {
    if (session) {
      dispatch(fetchVisitorSession(session.id))
    }
  }, [session])

  if (!session) {
    return null;
  }

  return (
    <SessionDetailView
      session={session}
      visitor={session.visitor}
      assetSessions={assetSessions}
      loading={loading}
    />
  )
}

function SuccessPopupMessage({ className, open, trigger, onVisibilityChange }) {
  return (
    <Popup
      className={cx(styles.popup, className)}
      popperModifiers={{
        preventOverflow: {
          boundariesElement: "window"
        }
      }}
      open={open}
      on='click'
      onClose={() => onVisibilityChange(false)}
      onOpen={() => onVisibilityChange(true)}
      flowing
      pinned
      hoverable
      position='bottom right'
      trigger={trigger}
      size='tiny'
    >
      <div className={styles.closeButton} onClick={() => onVisibilityChange(false)}>
        <i aria-hidden="true" className="icon-close_1 icon">
          <span className="path1"></span><span className="path2"></span><span className="path3"></span>
        </i>
      </div>
      <div className={styles.content}>
        <div className={styles.details}>
          <h3 className={styles.title}>Success</h3>
          <p className={styles.subtext}>Your download has been initiated and a copy of the report will be delivered to your mailbox in less than 2 minutes</p>
        </div>
      </div>
    </Popup>
  )
}