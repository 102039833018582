import React from 'react';

import TimeAgo from 'react-timeago';
import dateformat from 'dateformat';
import Truncate from 'react-truncate';
import _ from 'lodash';
import { Map, TileLayer } from 'react-leaflet';

import { 
  Header, 
  Segment, 
  Table,
  Icon,
  Placeholder
} from 'semantic-ui-react';

import styles from './visitorDetail.module.scss';
import { padNumber } from 'services/util';


export default function VisitorDetailView({ visitor, sessions, loadingVisitor, loadingSessions, selectedSession, onSessionClicked }) {
  const renderName = () => {
    return `${visitor.firstName ? visitor.firstName : ''} ${visitor.lastName ? visitor.lastName : ''}`
  }

  return (
    <div className={styles.content}>  
      <div className={styles.visitorDetail}>
        <VisitorMap 
          lat={visitor && visitor.lastSession.country ? visitor.lastSession.country.latitude : null} 
          lng={visitor && visitor.lastSession.country ? visitor.lastSession.country.longitude : null} 
        />
        
        <Segment className={styles.railSegment}>
          {loadingVisitor && 
            <Placeholder.Header>
              <Placeholder.Line />
              <Placeholder.Line />
            </Placeholder.Header>
          } 

          {!loadingVisitor && visitor && 
          <Header as='h4' inverted>
            <Header.Content>
              <Truncate lines={1}>{renderName()}</Truncate>
              <Header.Subheader>
                {`${sessions ? sessions.length : 0} Sessions | ${visitor ? visitor.assets : 0} Assets`}
              </Header.Subheader>
            </Header.Content>
          </Header>}
        </Segment>
      </div>

      <Table basic selectable={!loadingSessions} className={styles.sessionsTable}>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell collapsing></Table.HeaderCell>
            <Table.HeaderCell>SESSIONS</Table.HeaderCell>
            <Table.HeaderCell textAlign='center'>ASSETS</Table.HeaderCell>
            <Table.HeaderCell textAlign='center'>LENGTH</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {loadingSessions && _.times(5).map(index => {
            return <PlaceholderSessionRow key={index} />
          }) }

          {!loadingSessions && (sessions || []).map(item => {
            return <SessionRow session={item} active={item.id === (selectedSession ? selectedSession.id : null)} onSessionClicked={onSessionClicked}/>
          }) }
        </Table.Body>
      </Table>
    </div>
  )
}

function SessionRow(props) {

  let timeSpent = props.session.duration;
  let minutes = padNumber(Math.floor(timeSpent / 60), 2);
  let seconds = padNumber(Math.floor(timeSpent % 60), 2);

  const handleSessionClick = () => {
    props.onSessionClicked(props.session);
  }

  return (
    <Table.Row onClick={handleSessionClick} active={props.active}>
      <Table.Cell className={styles.visitorPlayIcon} collapsing><Icon size='big' name='play circle outline' /></Table.Cell>
      <Table.Cell>
        <Header as='h4'>
          <Header.Content>
            <TimeAgo date={props.session.lastEventDate} live={false} />
            <Header.Subheader>
              {dateformat(props.session.lastEventDate, 'hh:mm TT')}
            </Header.Subheader>
          </Header.Content>
        </Header>
      </Table.Cell>
      <Table.Cell textAlign='center'>{props.session.assetsCount}</Table.Cell>
      <Table.Cell textAlign='center'>{`${minutes} ${seconds ? `- ${seconds}` : ''}`}</Table.Cell>
    </Table.Row>
  )
}

function PlaceholderSessionRow() {
  return (
    <Table.Row>
      <Table.Cell collapsing>
        <Placeholder>
          <Placeholder.Header image></Placeholder.Header>
        </Placeholder>
      </Table.Cell>
      <Table.Cell>
        <Placeholder>
          <Placeholder.Header>
            <Placeholder.Line />
            <Placeholder.Line />
          </Placeholder.Header>
        </Placeholder>
      </Table.Cell>
      <Table.Cell textAlign='center'>
        <Placeholder>
          <Placeholder.Header>
            <Placeholder.Line />
          </Placeholder.Header>
        </Placeholder>
      </Table.Cell>
      <Table.Cell textAlign='center'>
        <Placeholder>
          <Placeholder.Header>
            <Placeholder.Line />
          </Placeholder.Header>
        </Placeholder>
      </Table.Cell>
    </Table.Row>
  )
}

function VisitorMap(props) {
  return (
    <Map
      center={[props.lat || 20, props.lng || 77]}
      zoom={4}
      maxZoom={4}
      attributionControl={true}
      zoomControl={true}
      doubleClickZoom={false}
      scrollWheelZoom={true}
      dragging={false}
      animate={false}
      easeLinearity={0.35}
    >

      <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
    </Map>
  )
}