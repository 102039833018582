import React, { useState, Fragment, createRef, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { createWidget, moveWidgetToIndex, updateWidgetName, deleteWidget, updateProperties } from 'actions/widgetEditor';

import _ from 'lodash';

import { Modal, Segment, Checkbox, Label, Menu, Input, Button, Icon, Accordion, Radio } from 'semantic-ui-react';
import Tooltip from 'components/Tooltip';
import { WidgetContext } from 'pages/pages/editor/editor.jsx';

import { Sortable } from '@shopify/draggable';

import styles from './filters.module.scss';
import cx from 'classnames';
import { filter } from 'minimatch';

export function FiltersModal({ trigger, editor, widget, ...rest }) {
  const dispatch = useDispatch();
  const listReference = React.createRef(null);

  const [open, setOpen] = useState(false);
  const [filtersView, setFiltersView] = useState(false);
  const [selectedFields, setSelectedFields] = useState([]);

  const [dragging, setDragging] = useState(false);

  const filters = useSelector(state => {
    const editorState = _.get(state, `widgetsEditor.editors.${editor}`);
    if (!editorState) {
      return [];
    }

    return _.get(editorState.widgetsById[widget.id], 'properties.filters');
  });

  const handleCreate = () => {
    setFiltersView(true);
  };

  const handleDelete = (filter) => {
    const index = filters.indexOf(filter.id);

    filters.splice(index, 1)

    dispatch(updateProperties({
      id: widget.id,
      change: {
        filters: filters
      },
      context: editor
    }));

  };

  const handleAddFields = (fieldValues) => {
    var request = filters || {
      fields: []
    };

    const fields = fieldValues.map(item => {
      var fieldObj = {
        id: item.id,
        name: item.name,
        options: item.options
      }
      return fieldObj;
    });
    request.fields = [...request.fields, ...fields];
    request.fields = _.uniqBy(request.fields, "id");

    dispatch(updateProperties({
      id: widget.id,
      change: {
        filters: request
      },
      context: editor
    }));

    setFiltersView(false);
  }

  const handleChange = (request) => {
    dispatch(updateProperties({
      id: widget.id,
      change: {
        filters: request
      },
      context: editor
    }));
  }

  return (
    <Modal trigger={trigger} size='mini'
      className={styles.manageFilters}
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
    >
      <Segment attached='top' basic className={styles.header}>
        <Menu secondary>
          <Menu.Item header as="h2">Filters <span className={styles.label}>({_.get(filters, 'fields.length', 0)})</span></Menu.Item>
          <Menu.Menu position='right'>
            {!filtersView &&
              <Menu.Item className={styles.newFilter}
                onClick={handleCreate}
              >
                + Add new filter
              </Menu.Item>
            }
          </Menu.Menu>
        </Menu>
      </Segment>
      {!filtersView &&
        <ManageFilters
          editor={editor}
          widget={widget}
          filters={filters}
          onChange={handleChange}
          onClose={() => {
            setOpen(false);
          }}
        />
      }
      {filtersView &&
        <AddFields onAdd={handleAddFields}
          onClose={() => {
            setFiltersView(false);
          }}
        />
      }
    </Modal>
  );
}

function AddFields({ onAdd, onClose }) {
  const [selectedFields, setSelectedFields] = useState([]);
  const fields = useSelector(state => state.customFields.items);

  return (
    <>
    <Segment attached basic className={styles.list}>
      <div className={styles.fields}>
        {fields.map(field => {
          const selected = selectedFields.find(item => {
            return item.id === field.id;
          }) != null;

          return (
            <FieldItem
              key={field.id}
              field={field}
              onChange={(field) => {
                var items = selectedFields;
                if (selected) {
                  items = selectedFields.filter(item => {
                    return item.id != field.id;
                  });

                } else {
                  items = [...selectedFields, field];
                }

                items = _.uniqBy(items, 'id');
                setSelectedFields(items);
              }}
              selected={selected}
            />
          )
        })}
      </div>
    </Segment>
    <Segment attached='bottom' className={styles.buttonContainer} textAlign='right'>
      {selectedFields.length > 0 &&
        <Button onClick={() => {
          onAdd(selectedFields);
        }}>
          ADD {selectedFields.length} FIELDS
          </Button>
      }
      <Button onClick={() => {
        onClose();
      }}>
        BACK
        </Button>
    </Segment>
    </>
  );
}

function FieldItem({ field, selected, onChange }) {
  return (
    <div className={styles.field}>
      <div className={styles.name}>
        {field.name}
        <div className={styles.description}>{field.options.length} Values</div>
      </div>
      <Checkbox className={styles.checkBox} checked={selected} onClick={() => {
        onChange(field);
      }} />
    </div>
  );
}

function ManageFilters({ widget, editor, filters, onChange, onClose }) {
  const listReference = React.createRef(null);
  const sortable = React.useRef(null);

  const fields = useSelector(state => {
    const editorState = _.get(state, `widgetsEditor.editors.${editor}`);
    if (!editorState) {
      return [];
    }

    var fields = _.get(filters, 'fields') || [];
    const customFields = state.customFields.items || [];

    return fields.map(item => {
      return customFields.find(field => field.id === item.id);
    });
  });

  useEffect(() => {
    if (!listReference.current) {
      return;
    }

    sortable.current = new Sortable(listReference.current, {
      draggable: `.${styles.field}`,
      handle: `.${styles.handle}`,
      mirror: {
        appendTo: 'body',
        constrainDimensions: true,
      },
    });
  }, []);

  useEffect(() => {
    if (!filters && !sortable.current) {
      return;
    }

    sortable.current.on('sortable:stop', handleMove);

    return () => {
      sortable.current.off('sortable:stop', handleMove);
    }
  }, [filters]);

  const handleMove = (e) => {
    const oldIndex = e.data.oldIndex;
    const newIndex = e.data.newIndex;

    if (oldIndex === newIndex) {
      return;
    }

    var items = filters.fields || [];
    items = [...items];

    var element = items[oldIndex];
    items[oldIndex] = items[newIndex];
    items[newIndex] = element;

    onChange({
      ...filters,
      fields: items
    });
  }

  const handleDelete = (field) => {
    var request = filters || {
      fields: []
    };

    request.fields = (request.fields || []).filter(item => item.id != field.id);
    request.fields = _.uniqBy(request.fields);
    onChange(request);
  }

  return (
    <>
    <Segment attached basic className={styles.list}>
      <div className={styles.fields} ref={listReference}>
        {fields.map(field => {
          return (
            <FiltersItem
              key={field.id}
              field={field}
              onDelete={handleDelete}
              widget={widget}
              editor={editor}
              filters={filters}
            />
          )
        })}
      </div>
    </Segment>
    <Segment attached='bottom' className={styles.buttonContainer} textAlign='right'>
      <Button onClick={() => {
        onClose();
      }}>
        DONE
        </Button>
    </Segment>
    </>
  );
}

function FiltersItem({ widget, filters, editor, field, onDelete }) {
  const [filterExpand, setFilterExpand] = useState(false);
  const dispatch = useDispatch();

  const onFilterExpand = () => {
    setFilterExpand(!filterExpand)
  }

  var filtersField = filters.fields.find(filterField => {
    return filterField.id === field.id;
  })

  return (
    <div className={styles.field}>
      <Handle />
      <Accordion vertical key={field.id}>
        <Accordion.Title
          onClick={onFilterExpand}>
          <Icon name={filterExpand ? 'minus' : 'plus'} size="small" />
          <div className={styles.name}>
            <Input size='mini'
              value={filtersField.name}
              onChange={(e) => {
                _.set(filtersField,"name",e.target.value);
                var index = _.findIndex(filters.fields, {id: filtersField.id});
                filters.fields.splice(index, 1, filtersField);
                dispatch(updateProperties({
                  id: widget.id,
                  change: {
                    filters: filters
                  },
                  context: editor
                }));
              }}
            />
          </div>

        </Accordion.Title>
        <Accordion.Content key={field.id} active={filterExpand}>
          {field.options.map((option) => {
            const selected = filtersField.options.find(item => {
              return item.id === option.id;
            }) != null;

            return (
              <FieldOptionRow 
                  option={option}
                  selected={selected}
                  filtersField={filtersField}
                  filters={filters}
                  field={field}
                  widget={widget}
                  editor={widget}
              />
            )
          })}
        </Accordion.Content>
      </Accordion>
      <Button icon onClick={() => onDelete(field)}>
        <Icon name='trash alternate outline' />
      </Button>
    </div>
  );
}

function FieldOptionRow({ option, selected, filtersField, filters, field, widget, editor}) {
    const dispatch = useDispatch();

    const toggleOption = () => {
      var items = filtersField.options;
      if (selected) {
        items = filtersField.options.filter(item => {
          return item.id != option.id;
        });

      } else {
        items = [...filtersField.options, option];
      }

      var sortedItems = items.sort((a, b) => {
        const indexA = field.options.findIndex(item => item.id === a.id);
        const indexB = field.options.findIndex(item => item.id === b.id);
        return indexA - indexB;
      });

      sortedItems = _.uniqBy(sortedItems, 'id');
      filters.fields = filters.fields.map(item => {
        if (item.id == field.id) {
          item.options = sortedItems;
          return item;
        }
        return item;
      })

      dispatch(updateProperties({
        id: widget.id,
        change: {
          filters: filters
        },
        context: editor
      }));
    }

    return (
      <div className={styles.checkBoxContainer}>
        <Checkbox label={option.value} checked={selected} className={styles.filterCheckBox}
          onClick={toggleOption}
        />
      </div>
    )
}

const Handle = React.forwardRef((props, ref) => {
  return (
    <div className={styles.handle} ref={ref}>
      <div className={styles.line}>
        {_.times(2).map((index) => {
          return <span key={index} />
        })}
      </div>
      <div className={styles.line}>
        {_.times(2).map((index) => {
          return <span key={index} />
        })}
      </div>
      <div className={styles.line}>
        {_.times(2).map((index) => {
          return <span key={index} />
        })}
      </div>
    </div>
  );
});