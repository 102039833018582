import {
  CREATING_CUSTOM_FIELD_REQUEST, CREATING_CUSTOM_FIELD_SUCCEEDED, CREATING_CUSTOM_FIELD_FAILED,
  UPDATING_CUSTOM_FIELD_REQUEST, UPDATING_CUSTOM_FIELD_SUCCEEDED, UPDATING_CUSTOM_FIELD_FAILED,
  FETCHING_CUSTOM_FIELD_REQUEST, FETCHING_CUSTOM_FIELD_SUCCEEDED, FETCHING_CUSTOM_FIELD_FAILED,
  FETCH_CUSTOM_FIELDS, FETCH_CUSTOM_FIELDS_SUCCEDDED, FETCH_CUSTOM_FIELDS_FAILED,
  INVALIDATE_CUSTOM_FIELDS,
  DELETING_CUSTOM_FIELD_REQUEST, DELETING_CUSTOM_FIELD_SUCCEEDED, DELETING_CUSTOM_FIELD_FAILED
} from 'constants/actionTypes';

import { call, put, takeLatest } from 'redux-saga/effects';
import buildUrl from 'build-url';
import axios from 'axios';
import { createAction } from 'redux-actions';

const apiUrl = `${process.env.REACT_APP_API_URL}/api/2.0`;
const CUSTOM_FIELD_URL = `${apiUrl}/custom_fields`;

/**
* Create customFields
*/
export const createCustomField = createAction(CREATING_CUSTOM_FIELD_REQUEST, (request, callback) => {
  return {
    request: request,
    callback: callback
  };
})

const createCustomFieldSucceeded = createAction(CREATING_CUSTOM_FIELD_SUCCEEDED, (response) => {
  return response;
})

const createCustomFieldFailed = createAction(CREATING_CUSTOM_FIELD_FAILED, (error) => {
  return error;
})

function* createCustomFieldSaga(action) {
  var { request, callback } = action.payload;

  const createCustomFieldAPI = () => {
    return axios.post(CUSTOM_FIELD_URL, request).then(response => response.data);
  }

  try {
    const response = yield call(createCustomFieldAPI);
    yield put(createCustomFieldSucceeded(response));
    callback(response);
  } catch (error) {
    yield put(createCustomFieldFailed(error));
  }
}

/**
* Create customFields
*/
export const updateCustomField = createAction(UPDATING_CUSTOM_FIELD_REQUEST, (id, request) => {
  return {
    id: id, 
    request: request
  };
})

const updateCustomFieldSucceeded = createAction(UPDATING_CUSTOM_FIELD_SUCCEEDED, (id, response) => {
  return {
    id: id,
    response: response
  };
})

const updateCustomFieldFailed = createAction(UPDATING_CUSTOM_FIELD_FAILED, (id, error) => {
  return {
    id: id,
    error: error
  };
})

function* updateCustomFieldSaga(action) {
  var { id, request } = action.payload;

  let url = buildUrl(CUSTOM_FIELD_URL, {
    path: `${id}`
  });

  const updateCustomFieldAPI = () => {
    return axios.put(url, request).then(response => response.data);
  }

  try {
    const response = yield call(updateCustomFieldAPI);
    yield put(updateCustomFieldSucceeded(id, response));
  } catch (error) {
    yield put(updateCustomFieldFailed(id, error));
  }
}

/**
* Fetch customField by id
*/
export const fetchCustomField = createAction(FETCHING_CUSTOM_FIELD_REQUEST, (id) => {
  return id;
});

const fetchCustomFieldSucceeded = createAction(FETCHING_CUSTOM_FIELD_SUCCEEDED, (id, response) => {
  return {
    id: id,
    response: response
  };
});

const fetchCustomFieldFailed = createAction(FETCHING_CUSTOM_FIELD_FAILED, (id, error) => {
  return {
    id: id,
    error: error
  };
})

function* fetchCustomFieldSaga(action) {
  var id = action.payload;

  let url = buildUrl(CUSTOM_FIELD_URL, {
    path: `${id}`
  });

  const fetchCustomFieldAPI = () => {
    return axios.get(url).then(response => response.data);
  }

  try {
    const response = yield call(fetchCustomFieldAPI);
    yield put(fetchCustomFieldSucceeded(id, response));
  } catch (error) {
    yield put(fetchCustomFieldFailed(id, error));
  }
}

/**
* Fetch all customFields
*/
export const fetchCustomFields = createAction(FETCH_CUSTOM_FIELDS);

const fetchCustomFieldsSucceeded = createAction(FETCH_CUSTOM_FIELDS_SUCCEDDED, (response) => {
  return {
    response: response
  }
});

const fetchCustomFieldsFailed = createAction(FETCH_CUSTOM_FIELDS_FAILED, (error) => {
  return {
    error: error
  }
})

const invalidateCustomFields = createAction(INVALIDATE_CUSTOM_FIELDS);

function* fetchCustomFieldsSaga(action) {

  const fetchCustomFieldsAPI = () => {
    return axios.get(CUSTOM_FIELD_URL).then(response => response.data);
  }

  try {
    const response = yield call(fetchCustomFieldsAPI);
    yield put(fetchCustomFieldsSucceeded(response));
  } catch (error) {
    yield put(fetchCustomFieldsFailed(error));
  }
}

/**
* Delete customField by id
*/
export const deleteCustomField = createAction(DELETING_CUSTOM_FIELD_REQUEST, (id, callback) => {
  return {
    id: id,
    callback: callback
  };
})

const deleteCustomFieldSucceeded = createAction(DELETING_CUSTOM_FIELD_SUCCEEDED, (id, response) => {
  return {
    id: id,
    response: response
  };
});

const deleteCustomFieldFailed = createAction(DELETING_CUSTOM_FIELD_FAILED, (id, error) => {
  return {
    id: id,
    error: error
  };
})

function* deleteCustomFieldSaga(action) {
  var { id, callback } = action.payload;

  let url = buildUrl(CUSTOM_FIELD_URL, {
    path: `${id}`
  });

  const deleteCustomFieldAPI = () => {
    return axios.delete(url).then(response => response.data);
  }

  try {
    const response = yield call(deleteCustomFieldAPI);
    yield put(deleteCustomFieldSucceeded(id, response));
    callback(response);
  } catch (error) {
    yield put(deleteCustomFieldFailed(id, error));
  }
}

export default function* customFieldsSaga() {
  yield takeLatest(CREATING_CUSTOM_FIELD_REQUEST, createCustomFieldSaga);
  yield takeLatest(UPDATING_CUSTOM_FIELD_REQUEST, updateCustomFieldSaga);
  yield takeLatest(FETCHING_CUSTOM_FIELD_REQUEST, fetchCustomFieldSaga);
  yield takeLatest(FETCH_CUSTOM_FIELDS, fetchCustomFieldsSaga);
  yield takeLatest(DELETING_CUSTOM_FIELD_REQUEST, deleteCustomFieldSaga);
};