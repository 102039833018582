import {
  FETCHING_LEAD_SESSIONS, FETCHING_LEAD_SESSIONS_SUCCEEDED, FETCHING_LEAD_SESSIONS_FAILED,
  FETCHING_LEAD, FETCHING_LEAD_SUCCEEDED, FETCHING_LEAD_FAILED,
  FETCHING_SESSIONS_FOR_LEAD, FETCHING_SESSIONS_FOR_LEAD_SUCCEEDED, FETCHING_SESSIONS_FOR_LEAD_FAILED,
  DOWNLOAD_LEAD_REPORTS, DOWNLOADING_LEAD_REPORTS_SUCCEEDED, DOWNLOADING_LEAD_REPORTS_FAILED,
  SELECT_LEAD_SESSION,
  FETCHING_LEAD_ANALYTICS, FETCHING_LEAD_ANALYTICS_SUCCEEDED, FETCHING_LEAD_ANALYTICS_FAILED
} from 'constants/actionTypes';

import { createAction } from 'redux-actions';
import axios from 'axios';
import { call, put, takeLatest } from 'redux-saga/effects';
import buildUrl from 'build-url';

const apiUrl = process.env.REACT_APP_API_URL;
const API_BASE_URL = `${apiUrl}/api/2.1`;
const LEAD_REPORTS_URL = `${API_BASE_URL}/resource_center/reports/leads`;

/**
* Fetch lead sessions
*/
export const fetchLeadSessions = createAction(FETCHING_LEAD_SESSIONS, (page, request) => {
  return {
    page: page,
    request: request
  }
})

export const fetchLeadSessionsSucceeded = createAction(FETCHING_LEAD_SESSIONS_SUCCEEDED, (response) => {
  return response;
})

export const fetchLeadSessionsFailed = createAction(FETCHING_LEAD_SESSIONS_FAILED, (error) => {
  return error;
})

function* fetchLeadSessionsSaga(action) {
  const params = {
    page: action.payload.page,
    limit: 5
  }

  let url = buildUrl(`${LEAD_REPORTS_URL}`, {
    queryParams: params
  });

  const fetchLeadSessionsAPI = () => {
    return axios.post(url,
      action.payload.request
    ).then(response => response.data);
  }

  try {
    const response = yield call(fetchLeadSessionsAPI);
    yield put(fetchLeadSessionsSucceeded(response));
  } catch (error) {
    yield put(fetchLeadSessionsFailed(error));
  }
}


/**
* Fetch sesssions for lead
*/
export const fetchSessionsForLead = createAction(FETCHING_SESSIONS_FOR_LEAD, (id, request, page) => {
  return { id, request, page }
});

export const fetchSessionsForLeadSucceeded = createAction(FETCHING_SESSIONS_FOR_LEAD_SUCCEEDED, (response) => {
  return response;
})

export const fetchSessionsForLeadFailed = createAction(FETCHING_SESSIONS_FOR_LEAD_FAILED, (error) => {
  return error;
})

function* fetchSessionsForLeadSaga(action) {
  const { id, page, request } = action.payload;

  const params = {
    page: action.payload.page,
    limit: 5
  }

  let url = buildUrl(`${LEAD_REPORTS_URL}/${id}/sessions`, {
    queryParams: params
  });

  const fetchSessionsForLeadAPI = () => {
    return axios.post(url, request).then(response => response.data);
  }

  try {
    const response = yield call(fetchSessionsForLeadAPI);
    yield put(fetchSessionsForLeadSucceeded(response));
  } catch (error) {
    yield put(fetchSessionsForLeadFailed(error));
  }
}

/**
* Fetch lead for id
*/
export const fetchLead = createAction(FETCHING_LEAD, (id) => {
  return { id }
})

export const fetchLeadSucceeded = createAction(FETCHING_LEAD_SUCCEEDED, (response) => {
  return response;
})

export const fetchLeadFailed = createAction(FETCHING_LEAD_FAILED, (error) => {
  return error;
})

function* fetchLeadSaga(action) {
  const id = action.payload.id;

  const fetchLeadAPI = () => {
    return axios.get(`${LEAD_REPORTS_URL}/${id}`,
    ).then(response => response.data);
  }

  try {
    const response = yield call(fetchLeadAPI);
    yield put(fetchLeadSucceeded(response));
  } catch (error) {
    yield put(fetchLeadFailed(error));
  }
}

export const selectLeadSession = createAction(SELECT_LEAD_SESSION);

/**
* Fetch cumulative lead analytics
*/
export const fetchLeadAnalytics = createAction(FETCHING_LEAD_ANALYTICS, (request) => {
  return { request };
});

export const fetchLeadAnalyticsSucceeded = createAction(FETCHING_LEAD_ANALYTICS_SUCCEEDED, response => {
  return response;
});

export const fetchLeadAnalyticsFailed = createAction(FETCHING_LEAD_ANALYTICS_FAILED, error => {
  return error;
});

function* fetchLeadAnalyticsSaga(action) {
  const { request } = action.payload;

  const fetchLeadAnalyticsAPI = () => {
    return axios.post(`${LEAD_REPORTS_URL}/analytics`, request)
      .then(response => response.data);
  }

  try {
    const response = yield call(fetchLeadAnalyticsAPI);
    yield put(fetchLeadAnalyticsSucceeded(response));
  } catch (error) {
    yield put(fetchLeadAnalyticsFailed(error));
  }
}

/**
* Download Lead reports
*/
export const downloadLeadReports = createAction(DOWNLOAD_LEAD_REPORTS, (request, callback) => {
  return {
    request: request,
    callback: callback
  }
});

export const downloadLeadReportsSucceeded = createAction(DOWNLOADING_LEAD_REPORTS_SUCCEEDED, (response) => {
  return response;
})

export const downloadLeadReportsFailed = createAction(DOWNLOADING_LEAD_REPORTS_FAILED, (error) => {
  return error;
})

function* downloadLeadReportsSaga(action) {
  const { request, callback } = action.payload;

  const downloadLeadReportsAPI = () => {
    return axios.post(`${LEAD_REPORTS_URL}/download/lead_sessions`, request).then(response => response.data);
  }

  try {
    const response = yield call(downloadLeadReportsAPI);
    yield put(downloadLeadReportsSucceeded(response));
    if(callback) {
      callback();
    }
  } catch (error) {
    yield put(downloadLeadReportsFailed(error));
  }
}


export default function* leadsSaga() {
  yield takeLatest(FETCHING_LEAD_SESSIONS, fetchLeadSessionsSaga);
  yield takeLatest(FETCHING_SESSIONS_FOR_LEAD, fetchSessionsForLeadSaga);
  yield takeLatest(DOWNLOAD_LEAD_REPORTS, downloadLeadReportsSaga);
  yield takeLatest(FETCHING_LEAD, fetchLeadSaga);
  yield takeLatest(FETCHING_LEAD_ANALYTICS, fetchLeadAnalyticsSaga)
}