import React, { Fragment, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';

import { fetchAsset } from 'actions/content';

import _ from 'lodash';
import cx from 'classnames';
import dateformat from 'dateformat';

import FileService from 'services/file.service.jsx';
import { guid } from 'services/util';
import AuthService from 'services/auth.service';

import {
  Menu,
  Button,
  Icon,
  Sidebar,
  Segment,
  Header
} from 'semantic-ui-react';

import AssetViewer from 'components/AssetViewer/AssetViewer';
import { EditAsset } from './edit';
import AnalyticsView from './analytics';
import ManageAlias from 'components/Alias';

import styles from './asset.module.scss';

export default function AssetView(props) {
  const { id } = useParams();
  const history = useHistory();

  const dispatch = useDispatch();

  const [sideBar, setSideBar] = useState(null);

  const [contextId, setContextId] = useState(null);

  const fetchAssets =  useSelector(state => _.get(state, `asset[${contextId}].fetch`, false));

  useEffect(() => {
    var contextId = guid();
    setContextId(contextId);
    dispatch(fetchAsset(id, contextId))
  }, []);

  useEffect(() => {
    if(fetchAssets) {
      dispatch(fetchAsset(id, contextId))
    }
  }, [fetchAssets]);

  var asset = useSelector(state => {
    if(contextId) {
      const assetByContextId = state.asset[contextId] || {};
      return assetByContextId.item;
    }
    
    return null;
  })

  const toggleSideBarState = (state) => {
    setSideBar(state)
  }

  const handleBackClick = () => {
    history.goBack();
  }

  if (!contextId || !asset) {
    return null;
  }

  return (
    <div className={styles.content}>
      <Sidebar.Pushable as={Segment} basic className={styles.body}>
        <Sidebar
          animation='push'
          icon='labeled'
          direction={'right'}
          visible={sideBar != null}
          className={cx(styles.sidebar, { [styles.expanded]: false })}
        >
          <Menu size='large' borderless fixed={'top'} className={styles.header}>
            <Menu.Item header className={styles.headerName}>{sideBar === 'edit' ? 'Edit Asset' : 'Asset Analytics'}</Menu.Item>
            <Menu.Menu position='right'>
              <Menu.Item 
                icon={{name: 'close', size: 'small'}} 
                className={styles.close} 
                onClick={toggleSideBarState.bind(this, null)}>
              </Menu.Item>
            </Menu.Menu>
          </Menu>

          <div className={styles.editView}>
            {sideBar === 'edit' &&
              <EditAsset onFinish={toggleSideBarState.bind(this, null)} contextId={contextId}/>}

            {sideBar === 'analytics' && 
              <AnalyticsView asset={asset} />}
          </div>
        </Sidebar>
        <Sidebar.Pusher className={cx(styles.viewer, {
          [styles.expanded]: sideBar
        })}>
          <MainView
            asset={asset}
            contextId={contextId}
            onBackClick={handleBackClick}
            onToggleSideBar={toggleSideBarState} />
        </Sidebar.Pusher>
      </Sidebar.Pushable>
    </div>
  )
}

function MainView(props) {

  const [state, setState] = useState({
    alias: false
  });

  const storyboard = useSelector(state => _.get(state, 'storyboards.storyboard.item'));

  return (
    <Fragment>
      <Menu
        size='large'
        borderless
        fixed={'top'}
        className={styles.header}
      >
        <Menu.Item icon={{name: "angle left", size: 'large'}} 
          onClick={props.onBackClick} 
        />
        <Menu.Item header>
          <AssetTitle asset={props.asset}/>
        </Menu.Item>

        <Menu.Menu position='right'>
          {props.asset.metadata.content_type !== "application/url" && props.asset.settings.download &&
            <Menu.Item className={styles.action} as='a' href={AuthService.getURL(`api/2.0/content/${props.asset.id}/download`,
               { modifiedDate: props.asset.metadata.modified_date, 'PF-APPLICATION-ID': storyboard.id })} target="_blank">
              <Button size='mini'>
                <Icon name='download' />DOWNLOAD
              </Button>
            </Menu.Item>
          }
          <Menu.Item className={styles.action}>
            <Button size='mini' onClick={props.onToggleSideBar.bind(this, 'analytics')}>
              <Icon name='chart bar' />DETAILS
            </Button>
          </Menu.Item>
          <Menu.Item className={styles.action}>
            <Button size='mini' onClick={(state) => {
              setState({
                ...state,
                alias: true
              })
            }}>
              <Icon name='linkify' />MANAGE ALIAS
            </Button>
          </Menu.Item>
          <Menu.Item className={styles.action}>
            <Button size='mini' onClick={props.onToggleSideBar.bind(this, 'edit')}>
              <Icon name='edit' />EDIT</Button>
          </Menu.Item>
        </Menu.Menu>
      </Menu>

      <div className={styles.assetViewer}>
        <AssetViewer asset={props.asset} contextId={props.contextId}/>
      </div>

      <ManageAlias contextId={props.contextId} entity={props.asset} open={state.alias} onClose={() => {
        setState((state) => {
          return {
            ...state,
            alias: false
          }
        })
      }}/>
    </Fragment>
  )
}

function AssetTitle({asset, ...rest}){

  const renderAdditionalInfo = (asset) => {
    var detail = FileService.fileType(asset.metadata);

    if(asset.metadata.pages){
      detail = `${detail} | ${asset.metadata.pages} Pages`;
    }else if(asset.metadata.video_duration){
      detail = `${detail} | ${FileService.formatDuration(asset.metadata.video_duration)}`;
    }

    detail = `${detail} | Updated on ${dateformat(asset.modifiedDate, 'dd mmmm yyyy')}`;

    return detail;
  }

  return (
    <Header as='h3' className={styles.title}>
      {asset.name}
      <Header.Subheader>
        {renderAdditionalInfo(asset)}
      </Header.Subheader>
    </Header>
  );
}