import React, {useState, useEffect, useRef} from 'react';
import { useSelector, useDispatch } from 'react-redux';

import cx from 'classnames';
import _ from 'lodash';

import { Widget, getStyles } from '../widget.jsx';
import { useWidgetDrop } from 'services/dnd.service.jsx';

import {
  Menu, Dropdown, Image
} from 'semantic-ui-react';

import styles from './menu.module.scss';
import { Link } from 'react-router-dom';

export default function MenuView(props) {
  const ref = useRef(null);
  const dropRef = useWidgetDrop(props, ref, {
    
  });

  const properties = props.properties || {};
  
  var menuContent = props.content;
  
  const [leftMenus, setLeftmenus] = useState([])
  const [rightMenus, setRightMenus] = useState([])

  useEffect(() => {
    setLeftmenus(menuContent && menuContent.leftMenus ? menuContent.leftMenus : []);
    setRightMenus(menuContent && menuContent.rightMenus ? menuContent.rightMenus : []);
  }, [menuContent]);

  const widgetStyles = getStyles(properties);

  return (
    <div 
      ref={props.dragRef(dropRef(ref))}
      className={cx(styles.menuWidget, props.className)}
      onMouseOver={props.onMouseOver}
      onMouseOut={props.onMouseOut}
      onClick={props.onClick}
    >
      <div className={cx(styles.menuContainer, 'cs-menu')} id={`widget_${props.id}`} style={widgetStyles}>
        <Menu className={styles.menu} 
          inverted={properties.inverted} 
          borderless={properties.borderless} 
          size='large'
          secondary
        >
          <MenuSection menus={leftMenus} />

          <Menu.Menu position="right">
            <MenuSection menus={rightMenus} />
          </Menu.Menu>
        </Menu>
      </div>

      {props.children}
    </div>
  )
}

function MenuSection(props) {
  return (
    <>
      {(props.menus || []).map(item => {
        if(item.children && item.children.length > 0) {
          return <DropdownMenu menu={item} />
        } else {
          return <MenuItem menu={item} />
        }
      })}
    </>
  )
}

function DropdownMenu(props) {
  const trigger = (
    <>
      {props.menu.icon && <img src={props.menu.icon.thumbnail} />}
      {props.menu.label}
    </>
  )

  return (
    <Dropdown
      trigger={trigger}
      pointing
      className='link item'
    >
      <Dropdown.Menu>
        {props.menu.children.map(child => {
          var type = child.type;
          var url = _.get(child, 'value', '#');
          var target = _.get(child, 'target', '_self');

          return (
            <>
              {
                type === 'PAGE' &&
                <Dropdown.Item as={Link} to={`${url}`} target={target} className={cx(styles.menuItem)}>
                  <>
                    {child.icon && <img src={child.icon.thumbnail} />}
                    {child.label || ''}
                  </>
                </Dropdown.Item>
              }

              {
                type !== 'PAGE' &&
                <Dropdown.Item as='a' href={`${url}`} target={target} className={cx(styles.menuItem)}>
                  <>
                    {child.icon && <img src={child.icon.thumbnail} />}
                    {child.label || ''}
                  </>
                </Dropdown.Item>
              }
            </>
          )
        })}
      </Dropdown.Menu>
    </Dropdown>
  )
}

function MenuItem({ menu }) {
  var type = menu.type;
  var url = _.get(menu, 'value', '#');
  var target = _.get(menu, 'target', '_self');

  if (type === 'PAGE') {
    url = _.get(menu, 'value.alias', '#');
  }

  return (
    <>
      {type === 'PAGE' &&
        <Menu.Item as={Link} to={`${url}`} target={target}>
          <>
            {menu.icon && <img src={menu.icon.thumbnail} />}
            {menu.label || ''}
          </>
        </Menu.Item>
      }

      {type !== 'PAGE' &&
        <Menu.Item as='a' href={`${url}`} target={target}>
          <>
            {menu.icon && <img src={menu.icon.thumbnail} />}
            {menu.label || ''}
          </>
        </Menu.Item>
      }
    </>
  )
}