import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fetchWidgetContent, updateWidgetContent } from 'actions/widgetEditor.jsx';

import _ from 'lodash';
import { DndProvider } from 'react-dnd';
import { DragDropContext } from "react-beautiful-dnd";
import { HTML5Backend } from 'react-dnd-html5-backend';
import { guid } from 'services/util';

import {
  Icon,
  Button,
  Modal,
  Grid,
  Label,
  Segment,
  Image,
  Form,
  Input,
  Menu,
  Dropdown
} from 'semantic-ui-react';


import ImageTools from 'services/imagetools.service';
import Tooltip from 'components/Tooltip';

import cx from "classnames";
import styles from './menu.module.scss';


export default function ContentModal({ trigger, widget, editor, ...rest }) {
  const dispatch = useDispatch();

  var widgetContent = useSelector(state => {
    var widgetContent = null;
    const editorContext = state.widgetsEditor.editors[editor];
    if (editorContext) {
      widgetContent = _.get(editorContext, `widgetsById[${widget.id}].content`, null);
    }

    return widgetContent;
  });

  var updatingContent = useSelector(state => {
    var updating = false;
    const editorContext = state.widgetsEditor.editors[editor];
    if (editorContext) {
      updating = _.get(editorContext, `widgetsById[${widget.id}].updatingContent`, false);
    }

    return updating;
  })

  const [open, setOpen] = useState(false);
  const [menus, setMenus] = useState([]);
  const [selected, setSelected] = useState(menus ? menus[0] : null)
  
  useEffect(() => {
    if (open) {
      dispatch(fetchWidgetContent(widget.id, editor));
    }
  }, [open]);

  useEffect(() => {
    if (widgetContent) {
      setMenus(widgetContent.menus);
    }
  }, [widgetContent]);

  const processMenuRequest = (menus) => {
    var menusRequest = menus.map(menu => {
      var children = (menu.children || []).map(child => {
        if (child.type === "PAGE") {
          return {
            ...child,
            icon: child.icon && !child.icon.type ? child.icon : null,
            value: _.get(child, 'value.id', null)
          }
        }

        return {
          ...child,
          icon: child.icon && !child.icon.type ? child.icon : null,
        };
      })

      if (menu.type === 'PAGE') {
        return {
          ...menu,
          value: _.get(menu, 'value.id', null),
          icon: menu.icon && !menu.icon.type ? menu.icon : null,
          children: children
        }
      }

      if (menu.type === 'ANCHOR') {
        return {
          ...menu,
          target:'_self',
        }
      }

      return {
        ...menu,
        icon: menu.icon && !menu.icon.type ? menu.icon : null,
        children: children
      }
    })

    return menusRequest;
  }

  const handleSave = () => {
    var menusRequest = processMenuRequest(menus);

    var request = {
      content: {
        menus: menusRequest,
      }
    }

    dispatch(updateWidgetContent(widget.id, request, editor, () => {
      handleCancel()
    }));
  }

  const handleCancel = () => {
    setMenus([])
    setSelected(null);
    setOpen(false)
  }

  const handleUpdateMenu = (menu) => {
    
    const findAndModify = (children, menu) => {
      var menus = (children || []).map(item => {
        if(item.menuId === menu.menuId){
          return menu;
        }
        
        return {
          ...item,
          children: findAndModify(item.children, menu)
        };
      })

      return menus;
    }

    setMenus(findAndModify(menus, menu));
  }

  const handleMenuClicked = (menu) => {
    setSelected(menu);
  }

  const handleRearrangeMenu = (from, to) => {
    const menusArr = [...menus];
    menusArr.splice(to, 0, menusArr.splice(from, 1)[0]);
    setMenus(menusArr)
  }

  const handleChildRearrangeMenu = (item, from, to) => {
    const menusArr = [...menus];
    const menuIndex = menusArr.findIndex(menu =>  menu.children.includes(item))
    menusArr[menuIndex].children.splice(to, 0, menusArr[menuIndex].children.splice(from, 1)[0]);
    setMenus(menusArr);
  }

  return (
    <Modal trigger={trigger} size='large'
      className={styles.content}
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
    >
      <Modal.Content>
        <Grid>
          <Grid.Row>
            <Grid.Column width='five' className={styles.left} stretched>
              <DndProvider backend={HTML5Backend}>
                <Menu secondary>
                  <Menu.Item header as="h2">Menus <Label>{1}</Label></Menu.Item>  
                </Menu>
                <DragDropContext>
                  <div className={styles.scroll}>
                    <MenuSection name='STICKY MENU' menus={menus} 
                      onChange={(menus) => {
                        setMenus(menus);
                      }}
                      onClick={handleMenuClicked}
                      selected={selected}
                      onRearrange={handleRearrangeMenu}
                      onChildRearrange={handleChildRearrangeMenu}
                    />
                  </div>
                </DragDropContext>
              </DndProvider>
            </Grid.Column>
            <Grid.Column width='eleven'  className={styles.right} >
              {selected && 
                <MenuDetailForm editor={editor} menu={selected} onChange={handleUpdateMenu}/>
              }
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={handleCancel}>
          <Icon name='remove' /> Cancel
        </Button>
        <Button primary onClick={handleSave} disabled={updatingContent}>
          <Icon loading={updatingContent}
            name={updatingContent ? 'spinner' : 'checkmark'} />
            Save Changes
        </Button>
      </Modal.Actions>
    </Modal>
  )
}

function MenuSection({name, menus, onChange, onClick, selected, onRearrange, onChildRearrange}){

  const handleNewMenuItem = () => {
    var menu = {
      label: 'Untitled',
      type: 'WEB_LINK',
      target: '_self',
      children: [],
      menuId: guid(),
    }

    var updated = menus || [];
    onChange([...updated, menu]);
  }

  const handleDelete = (item) => {
    if(!item){
      return false;
    }

    const updated = menus.filter(menu => {
      return menu.menuId != item.menuId
    });

    onChange(updated);
  }

  const handleChange = (item) => {
    if(!item){
      return false;
    }

    const updated = menus.map(menu => {
      if(menu.menuId === item.menuId){
        return item;
      }

      return menu;
    });

    onChange(updated);
  }
  
  return (
    <Segment vertical className={styles.menuSection}>
      <Label attached='top'>{name}</Label>
      <Label as='a' attached='top right' onClick={handleNewMenuItem}>+ Add</Label>
      {(menus || []).map((menu, index) => {
        return <MenuItem key={menu.menuId} 
          item={menu}  
          index={index}
          canAdd={true}
          onDelete={handleDelete}
          onChange={handleChange}
          onClick={onClick}
          selected={selected}
          menus={menus}
          onRearrange={onRearrange}
          onChildRearrange={onChildRearrange}
        />
      })}
    </Segment>
  );
}

function MenuItem({item, index, canAdd, onDelete, onChange, onClick, selected, menus, onRearrange, onChildRearrange, isChildren, menusLength}){
  const handleAddClicked = () => {
    const children = item.children || [];
    
    var menu = {
      label: 'Untitled',
      type: 'WEB_LINK',
      target: '_self',
      children: [],
      menuId: guid(),
    }

    const updated = [...children, menu];
    onChange({
      ...item,
      children: updated
    })
  }

  const renderIcon = (menu) => {
    if(typeof menu.icon === 'string') {
      return menu.icon;
    }

    return menu.icon && menu.icon.thumbnail;
  }

  const handleDeleteClicked = () => {
    onDelete(item);
  }

  const handleChildDeleteClicked = (child) => {
    if(!child){
      return false;
    }

    const children = item.children || [];
    const updated = children.filter(menu => {
      return menu.menuId != child.menuId
    });

    onChange({
      ...item,
      children: updated
    });
  }

  const handleMenuClick= () => {
    onClick(item);    
  }


  return (
    <>
      <Segment basic className={cx(styles.menu, {[styles.active]: selected && selected.menuId === item.menuId})} clearing onClick={handleMenuClick}>
        <ReorderMenuActions menus={menus} selected={selected} item={item} index={index} onRearrange={onRearrange} onChildRearrange={onChildRearrange} isChildren={isChildren} menusLength={menusLength} />
        {item.icon && 
          <Image src={renderIcon(item)} />
        }
        {item.label}
        <Tooltip inverted content={'Delete Menu'} position='top right'>
          <Button 
            icon="trash" 
            floated='right' 
            size='mini' 
            compact
            onClick={handleDeleteClicked}
          />
        </Tooltip>
        
        {canAdd &&
          <Tooltip inverted content={'Add sub menu'} position='top right'>
            <Button 
              icon="add" 
              floated='right' 
              size='mini' 
              compact 
              onClick={handleAddClicked}
            />
          </Tooltip>
        }
      </Segment>
      {item.children && item.children.length > 0 && 
        <div className={styles.children}>
          {item.children.map((child, index) => {
            return <MenuItem key={child.menuId} 
              item={child} 
              index={index}
              canAdd={false} 
              onDelete={handleChildDeleteClicked}
              onClick={onClick}
              selected={selected}
              onRearrange={onRearrange}
              onChildRearrange={onChildRearrange}
              isChildren={true}
              menusLength={item.children.length}
            />
          })}
        </div>
      }
    </>
  );
}

function ReorderMenuActions({menus, selected, item, index, onRearrange, onChildRearrange, isChildren, menusLength }) {

  const handleMoveDown = () => {
    onRearrange(index, index + 1);
  }

  const handleMoveUp = () => {
    onRearrange(index, index - 1);
  }

  const handleChildMoveDown = (item) => {
    onChildRearrange(item, index, index + 1)
  }

  const handleChildMoveUp = (item) => {
    onChildRearrange(item, index, index - 1)
  }

  if(isChildren) {
    return (
      <div className={styles.reorderMenuActions}>
      {selected && selected.menuId === item.menuId && index !== 0 &&
        <div className={styles.moveUp}>
          <Tooltip content={'Move up'}
            position='top left'
            inverted
            size='mini'
          >
            <Label size='mini' onClick={() => handleChildMoveUp(item)}>
                <Icon name={"angle double up"} />
            </Label>
          </Tooltip>
        </div>
      }
      {selected && selected.menuId === item.menuId && index != menusLength - 1 &&
        <div className={styles.moveDown}>
          <Tooltip content={'Move bottom'}
            inverted
            size='mini'
            position='top left'
          >
            <Label size='mini' onClick={() => handleChildMoveDown(item)}>
                <Icon name={"angle double down"} />
            </Label>
          </Tooltip>
        </div>
      }
    </div>
    )
  }

  if(!isChildren) {
    return (
      <div className={styles.reorderMenuActions}>
      {selected && selected.menuId === item.menuId && index !== 0 &&
        <div className={styles.moveUp}>
          <Tooltip content={'Move up'}
            position='top left'
            inverted
            size='mini'
          >
            <Label size='mini' onClick={() => handleMoveUp(item)}>
                <Icon name={"angle double up"} />
            </Label>
          </Tooltip>
        </div>
      }
      {selected && selected.menuId === item.menuId && index != menus.length - 1 &&
        <div className={styles.moveDown}>
          <Tooltip content={'Move bottom'}
            inverted
            size='mini'
            position='top left'
          >
            <Label size='mini' onClick={() => handleMoveDown(item)}>
                <Icon name={"angle double down"} />
            </Label>
          </Tooltip>
        </div>
      }
    </div>
    )
  }
}

function MenuDetailForm({ menu, editor, onChange}) {
  const parent = null;
  const [label, setLabel] = useState("");
  const [icon, setIcon] = useState(null);
  const [type, setType] = useState('WEB_LINK');
  const [target, setTarget] = useState('_self');
  const [value, setValue] = useState(null);

  useEffect(() => {
    onChange({
      ...menu,
      label: label,
      icon: icon,
      type: type,
      target: target,
      value: value
    })
  }, [label, icon, type, target, value]);

  useEffect(() => {
    setLabel(menu.label);
    setIcon(menu.icon);
    setType(menu.type || 'WEB_LINK');
    setTarget(menu.target || '_self');
    setValue(menu.value || null);
  }, [menu])

  const renderIcon = () => {
    if(typeof icon === 'string') {
      return icon;
    }

    return icon && icon.thumbnail;
  }

  const handleLabelChange = (e, { value }) => {
    setLabel(value || null);
  }

  const handleIconChange = (value) => {
    setIcon(value)
  }

  const handleUrlChange = (value) => {
    setType(value.type);
    setValue(value.value);
  }

  const handleTargetChange = (e, { value }) => {
    setTarget(value)
  }

  return (
    <Segment className={styles.menuForm}>
      <Form className={styles.form}>
        <Form.Group inline className={styles.input}>
          <label>Menu label</label>
          <Form.Field
            id='form-input-control-first-name'
            control={Input}
            placeholder='Provide a valid label'
            value={label}
            onChange={handleLabelChange}
          />
        </Form.Group>  

        <ImageInput value={renderIcon()} onChange={handleIconChange}/>

        <UrlTypeInput editor={editor} url={{type: type, value: value}} onChange={handleUrlChange}/>

        {type != 'ANCHOR' &&
              <Form.Group inline>
              <label>Open url in</label>
              <Form.Radio
                label='Current Tab'
                value={'_self'}
                checked={target === '_self'}
                onChange={handleTargetChange}
              />
              <Form.Radio
                label='New Tab'
                value={'_blank'}
                checked={target === '_blank'}
                onChange={handleTargetChange}
              />
            </Form.Group>
        }
      </Form>
    </Segment>
  )
}

function ImageInput({value, onChange}){
  const uploadIconRef = React.createRef();
  const handleUploadIcon = (e) => {
    ImageTools.resize(e.target.files[0], {
      width: 1024, // maximum width
      height: 1024 // maximum height
    }, (blob, didItResize) => {
      var reader = new FileReader();
      reader.onload = (e) => {
        onChange(e.target.result)
      };

      reader.readAsDataURL(blob);
    });
  }
  

  return (
    <Form.Group inline className={styles.icon}>
      <label>Menu Image</label>
      <div className={styles.image}>
        {value ? 
          <Image src={value} /> 
          :
          <div className={styles.empty}>NO ICON</div>
        }
      </div>
      
      <div className={styles.upload}>
        <Button size='mini' onClick={() => uploadIconRef.current.click()}>Upload Icon</Button>
        <input
          ref={uploadIconRef}
          type="file"
          hidden
          onChange={handleUploadIcon}
        />
      </div>
    </Form.Group>
  );
}

function UrlTypeInput({ url, editor, onChange}){
  const type = url.type;
  const value = url.value;

  const pages = useSelector(state => _.get(state, 'pages.items', []));
  const widgets = useSelector(state => _.get(state, `widgetsEditor.editors.[${editor}].widgetsById`, []));

  var anchorNames = [];

  Object.keys(widgets).forEach(item => {
    if((widgets[item].type === "layout.row" || widgets[item].type === "widgets.text") && widgets[item].properties.anchor){
      anchorNames.push({
        text: widgets[item].properties.anchor.name,
        value: widgets[item].properties.anchor.name
      });
    }
  });

  const pageOptions = pages.map(item => {
    return {
      key: item.id,
      text: item.name,
      value: item.id
    }
  })

  const handleTypeChange = (e, data) => {
    onChange({
      type: data.value,
      value: null
    })
  }

  const handleInputChange = (e) => {
    onChange({
      type: type,
      value: e.target.value
    })
  }

  const handleAnchorChange = (e) => {
    onChange({
      type: type,
      value: e.target.innerText
    })
  }

  const handlePageChange = (e, { value }) => {
    if (type === 'PAGE') {
      value = pages.find(item => {
        return item.id === value;
      })
    }

    onChange({
      type: type,
      value: value
    })
  }

  return (
    <Form.Group inline className={styles.url}>
      <label>Url type</label>
      <Dropdown button basic floating options={[
          { key: 'WEB_LINK', text: 'External Link', value: 'WEB_LINK' },
          { key: 'PAGE', text: 'Page', value: 'PAGE' },
          { key: 'ANCHOR', text: 'Anchor', value: 'ANCHOR' },
        ]} 
        value={type}
        onChange={handleTypeChange}
      />

      {type === 'WEB_LINK' &&
        <Input placeholder='Enter an external link' value={value} onChange={handleInputChange}/>
      }
      
      {type === 'PAGE' &&
        <Dropdown button basic floating className={styles.pages} 
          options={pageOptions} 
          placeholder='Select page...'
          onChange={handlePageChange}
          value={value ? value.id : null}
        />
      }

      {type === 'ANCHOR' &&
        <Dropdown button basic floating className={styles.pages}
          options={anchorNames}
          placeholder='Select Anchor...'
          onChange={handleAnchorChange}
          value={value ? value : null}
        />
      }

    </Form.Group>
  );
}