import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';

import cx from 'classnames';

import Truncate from 'react-truncate';
import dateformat from 'dateformat';
import S3Upload from 'react-s3-uploader/s3upload';

import SidePane from 'components/SidePane';

import ImageTools from 'services/imagetools.service';
import { guid } from 'services/util';
import FileUploadService from 'services/file.upload.service';
import AuthService from 'services/auth.service';

import {
  Image,
  Progress,
  Button,
  Label,
  Input,
  Form,
  TextArea,
  Dropdown,
  Checkbox,
  Menu,
  Radio
} from 'semantic-ui-react';

import styles from './asset.module.scss';

import {
  updateAsset, uploadNewVersion, uploadingNewVersionProgress, cancelNewVersionUpload,
  uploadingNewVersionStarted, processAsset, 
} from 'actions/content';

import { fetchAllGating } from 'actions/gatings';

const API_BASE_URL = `${process.env.REACT_APP_API_URL}/api/2.0`;

const isValidUrl = (s) => {
  var re_weburl = new RegExp(
    "^" +
    // protocol identifier
    "(?:(?:https?|ftp)://)" +
    // user:pass authentication
    "(?:\\S+(?::\\S*)?@)?" +
    "(?:" +
    // IP address exclusion
    // private & local networks
    "(?!(?:10|127)(?:\\.\\d{1,3}){3})" +
    "(?!(?:169\\.254|192\\.168)(?:\\.\\d{1,3}){2})" +
    "(?!172\\.(?:1[6-9]|2\\d|3[0-1])(?:\\.\\d{1,3}){2})" +
    // IP address dotted notation octets
    // excludes loopback network 0.0.0.0
    // excludes reserved space >= 224.0.0.0
    // excludes network & broacast addresses
    // (first & last IP address of each class)
    "(?:[1-9]\\d?|1\\d\\d|2[01]\\d|22[0-3])" +
    "(?:\\.(?:1?\\d{1,2}|2[0-4]\\d|25[0-5])){2}" +
    "(?:\\.(?:[1-9]\\d?|1\\d\\d|2[0-4]\\d|25[0-4]))" +
    "|" +
    // host name
    "(?:(?:[a-z\\u00a1-\\uffff0-9]-*)*[a-z\\u00a1-\\uffff0-9]+)" +
    // domain name
    "(?:\\.(?:[a-z\\u00a1-\\uffff0-9]-*)*[a-z\\u00a1-\\uffff0-9]+)*" +
    // TLD identifier
    "(?:\\.(?:[a-z\\u00a1-\\uffff]{2,}))" +
    // TLD may end with dot
    "\\.?" +
    ")" +
    // port number
    "(?::\\d{2,5})?" +
    // resource path
    "(?:[/?#]\\S*)?" +
    "$", "i"
  );
  return re_weburl.test(s);
}

const fixHTTPUrl = (url) => {
  if (!/^(?:f|ht)tps?\:\/\//.test(url)) {
    url = "https://" + url;
  }
  return url;
}

function EditAsset(props) {
  var assetState = useSelector(state => state.asset[props.contextId])
  const asset = assetState.item;
  const updating = assetState.updating;
  const customFields = useSelector(state => { return state.customFields.items });

  const dispatch = useDispatch();

  const [name, setName] = useState(asset.name || '');
  const [url, setUrl] = useState(asset.metadata.url || '');
  const [summary, setSummary] = useState(asset.summary || '',);
  const [icon, setIcon] = useState(null);
  const [poster, setPoster] = useState(null);
  const [tags, setTags] = useState(asset.tags || []);
  const [assetFields, setAssetFields] = useState(asset.customFields || []);
  const [errors, setErrors] = useState({});
  const [viewerMode, setViewerMode] = useState(asset.viewerMode || 'render_in_viewer');
  const [excludeFromSearch, setExcludeFromSearch] = useState(asset.excludeFromSearch);
  const [download, setDownload] = useState(asset.settings.download);

  const isUrl = asset.metadata.content_type === "application/url";

  const aliases = useSelector(state => _.get(state, 'aliases.items', []));

  const validateAsset = () => {
    var errors = {};
    if (!name || name.trim() === '') {
      errors['name'] = 'Please provide a valid name';
    }

    if (isUrl && asset.metadata.url !== url) {
      var fixedUrl = fixHTTPUrl(url);
      setUrl(fixedUrl);
      if (!isValidUrl(fixedUrl)) {
        errors['url'] = 'Please provide a valid url'
      }
    }

    return errors;
  }

  const handleUpdate = () => {

    let errors = validateAsset();

    if (Object.keys(errors).length > 0) {
      setErrors(errors);
      return;
    }

    var customFields = assetFields || [];
    customFields = customFields.filter(field => {
      return field.id != null;
    });

    var settings = {
      ...asset.settings,
      download: download
    }

    let request = {
      id: asset.id,
      name: name,
      summary: summary,
      icon: icon,
      poster: poster,
      tags: tags,
      customFields: customFields,
      excludeFromSearch: excludeFromSearch,
      settings: settings
    }

    if (isUrl) {

      request = {
        ...request,
        viewerMode: viewerMode
      }

      if (asset.metadata.url !== url) {
        var fixedUrl = fixHTTPUrl(url);
        request = {
          ...request,
          url: fixedUrl
        }
      }
    }
    dispatch(updateAsset(props.contextId, request, (res) => {
      if (props.onFinish) {
        props.onFinish();
      }
    }))
  }

  const handleReset = () => {
    setName(asset.name);
    setUrl(asset.metadata.url || '');
    setSummary(asset.summary || '');
    setIcon(null);
    setPoster(null)
    setTags(asset.tags || []);
    setAssetFields(asset.customFields || []);
    setExcludeFromSearch(asset.excludeFromSearch);
    setDownload(asset.settings.download);
    setErrors({});
  }

  return (
    <>
      <div className={styles.basicInfo}>
        <UploadIcon icon={icon} asset={asset} onChange={setIcon} />
        <div className={styles.metadata}>
          {!isUrl &&
            <div className={styles.source}>
              <label>Source:</label>
              <a href="#">{asset.source || 'Paperflite Cloud'} <i className="fa fa-external-link" aria-hidden="true"></i></a>
            </div>
          }
          <div>
            <label>Publisher:</label>
            <span>{asset.author.firstName} {asset.author.lastName}</span>
          </div>
          <div>
            <label>Last update:</label>
            <span>{dateformat(asset.modifiedDate, 'dd mmm yyyy')}</span>
          </div>

          <UploadNewVersion asset={asset} contextId={props.contextId} />
        </div>
      </div>

      {isUrl &&
        <InputField title='URL'
          value={url}
          placeholder='eg: https://www.cleverstory.com'
          error={errors.url}
          onChange={setUrl}
        />
      }

      <InputField title='NAME'
        value={name}
        placeholder='Provide asset name'
        error={errors.name}
        onChange={setName}
        disabled={asset.source != null}
      />

      <TextAreaField title='DESCRIPTION'
        value={summary}
        placeholder='Please describe the asset'
        onChange={setSummary}
      />

      {asset.viewer == "video_viewer" &&
        <UploadPoster poster={poster} asset={asset} onChange={setPoster} />
      }

      {isUrl &&
        <ViewerMode value={viewerMode} onChange={setViewerMode} />
      }

      <TagsField tags={tags} onChange={setTags} />

      <div className={styles.excludeSearch}>
        <div className={styles.inner}>
          <h5>EXCLUDE FROM SEARCH</h5>
          <p>Prevent search engines from indexing this page</p>
        </div>
        <Radio toggle checked={excludeFromSearch} onChange={() => {
          setExcludeFromSearch(!excludeFromSearch);
        }} />
      </div>
      <div className={styles.download}>
        <div className={styles.inner}>
          <h5>DOWNLOAD</h5>
          <p>Allow users to download the asset</p>
        </div>
        <Radio className={styles.downloadRadio} toggle checked={download} onChange={() => {
          setDownload(!download);
        }} />
      </div>

      <CustomFields
        customFields={customFields}
        assetFields={assetFields}
        onChange={setAssetFields}
      />

      <Menu borderless fixed={'bottom'}>
        <Button.Group fluid className={styles.buttonTray}>
          <Button onClick={handleReset} disabled={updating}>Reset</Button>
          <Button.Or />
          <Button positive onClick={handleUpdate} disabled={updating}>{updating ? 'Updating...' : 'Update'}</Button>
        </Button.Group>
      </Menu>
    </>
  )
}

function UploadIcon({ icon, asset, onChange, ...rest }) {

  const uploadIconRef = React.createRef();

  const assetIcon = () => {
    if (icon) {
      return icon;
    }

    return _.get(asset, 'icon.thumbnail', '/images/default_asset.jpg');
  }

  const handleUploadIcon = (e) => {
    ImageTools.resize(e.target.files[0], {
      width: 1024, // maximum width
      height: 1024 // maximum height
    }, (blob, didItResize) => {
      var reader = new FileReader();
      reader.onload = (e) => {
        onChange(e.target.result)
      };

      reader.readAsDataURL(blob);
    });
  }

  return (
    <div className={styles.uploadIcon}>
      <Image
        label={{ as: 'a', corner: 'right', icon: 'upload' }}
        className={styles.icon}
        src={assetIcon()}
        onClick={() => uploadIconRef.current.click()} />

      <input
        ref={uploadIconRef}
        type="file"
        hidden
        onChange={handleUploadIcon}
      />
    </div>
  )
}

function UploadPoster({ poster, asset, onChange, ...rest }) {
  const uploadIconRef = React.createRef();
  const externalVideoType = _.get(asset, 'metadata.external_video_provider')

  const assetPoster = () => {
    if (poster) {
      return poster;
    }

    return _.get(asset, 'poster.full');
  }

  const handleUploadPoster = (e) => {
    ImageTools.resize(e.target.files[0], {
      width: 1600, // maximum width
      height: 900 // maximum height
    }, (blob, didItResize) => {
      var reader = new FileReader();
      reader.onload = (e) => {
        onChange(e.target.result)
      };

      reader.readAsDataURL(blob);
    });
  }

  if (_.includes(['vimeo', 'wistia', 'vidyard'], externalVideoType)) {
    return false;
  }

  return (
    <div className={styles.poster}>
      <Label size='small' active>
        POSTER
      </Label>
      <div className={styles.upload}>
        <div className={styles.progress}>Updating...</div>

        {!assetPoster() &&
          <Image className={styles.empty} onClick={() => uploadIconRef.current.click()}>
            <div>NO IMAGE</div>
          </Image>
        }

        {assetPoster() &&
          <Image
            label={{ as: 'a', corner: 'right', icon: 'upload' }}
            className={styles.icon}
            src={assetPoster()}
            onClick={() => uploadIconRef.current.click()}
          />
        }

        <input
          ref={uploadIconRef}
          type="file"
          hidden
          onChange={handleUploadPoster}
        />
      </div>
    </div>
  )
}


function UploadNewVersion({ asset, contextId, ...rest }) {
  
  const isUrl = asset.metadata.content_type === "application/url";

  var assetState = useSelector(state => state.asset[contextId]);
  const uploadProgress = assetState.upload.progress;
  const uploading = assetState.upload.uploading;
  const file = assetState.upload.file;
  const processing = assetState.processing;

  const dispatch = useDispatch();

  const uploadNewVersionRef = React.createRef();

  const handleUploadNewVersion = (e) => {
    var file = e.target.files[0];
    file.id = guid();

    dispatch(uploadingNewVersionStarted(contextId, file));

    var s3Uploader = new S3Upload({
      signingUrl: '/file_upload/initiate',
      signingUrlMethod: "POST",
      signingUrlHeaders: { 'Authorization': `Bearer ${AuthService.getAccessToken()}` },
      server: API_BASE_URL,
      contentDisposition: 'auto',
      uploadRequestHeaders: {},
      onFinishS3Put: handleFileUploadFinish,
      onProgress: handleFileUploadProgress,
      onError: handleFileUploadError,
      files: [file]
    });

    FileUploadService.trackUpload(s3Uploader);
  }

  const handleProcessAsset = () => {
    dispatch(processAsset(contextId, asset.id));
  }

  const handleFileUploadProgress = (progress, textState, file) => {
    dispatch(uploadingNewVersionProgress(contextId, progress));
  }

  const handleFileUploadError = err => {
    console.log(err);
  }

  const handleFileUploadFinish = (info, file) => {
    if (info) {
      FileUploadService.markFinish(file.id);
      dispatch(uploadNewVersion(contextId, asset.id, info.signedUrl));
    }
  }

  const handleCancelUpload = () => {
    if (!file) {
      return;
    }

    var uploader = FileUploadService.getUploader(file.id);

    if (uploader) {
      uploader.abortUpload();
    }

    dispatch(cancelNewVersionUpload(contextId, file));
  }

  if (asset.source) {
    return false;
  }

  return (
    <>
      {!isUrl && 
        <div className={styles.uploadIcon}>
          {!uploading &&
            <>
              <Button
                size='mini'
                fluid
                className={styles.upload}
                content={uploading ? 'UPLOADING...' : 'UPLOAD NEW VERSION'}
                labelPosition="left"
                icon="upload"
                onClick={() => uploadNewVersionRef.current.click()}
              />

              <input
                ref={uploadNewVersionRef}
                type="file"
                hidden
                disabled={uploading}
                onChange={handleUploadNewVersion}
              />
            </>
          }

          {uploading &&
            <>
              <div className={styles.progress}>
                <div className={styles.fileName}>
                  <Truncate lines={1}>{file.name}</Truncate>
                </div>

                {uploadProgress !== 100 &&
                  <Button size='small' icon='close' onClick={handleCancelUpload}></Button>
                }
              </div>
              <div className={styles.bar}>
                <Progress percent={uploadProgress} size='tiny' />
              </div>
            </>
          }
        </div>
      }
      
      {isUrl && 
        <div className={styles.uploadIcon}>
          {!uploading &&
            <>
              <Button
                size='mini'
                fluid
                className={styles.pload}
                content= {processing ? "Processing..." : "Refresh Asset" }
                labelPosition="left"
                icon="refresh"
                onClick={handleProcessAsset}
              />
            </>
          }
        </div>
      }
    </>
  )
}

function InputField({ title, placeholder, value, onChange, error, ...rest }) {

  const handleChange = (e) => {
    onChange(e.target.value);
  }

  return (
    <div className={styles.field}>
      <Label size='small' active>{title}</Label>
      <Input error={error} placeholder={placeholder}
        value={value}
        onChange={handleChange}
        disabled={rest.disabled} />
    </div>
  )
}

function TextAreaField({ title, placeholder, value, onChange, ...rest }) {

  const handleChange = (e) => {
    onChange(e.target.value);
  }

  return (
    <div className={styles.field}>
      <Label size='small' active>{title}</Label>
      <Form>
        <TextArea placeholder={placeholder}
          rows={4}
          onChange={handleChange}
          value={value} />
      </Form>
    </div>
  )
}

function TagsField({ tags, onChange, ...rest }) {
  const [options, setOptions] = useState((tags || []).map(item => {
    return {
      key: item,
      text: item,
      value: item
    }
  }) || '');

  const handleAddition = (e, { value }) => {
    setOptions([{ text: value, value }, ...options])
  }

  const handleChange = (e, { value }) => {
    onChange(value);
  }

  return (
    <div className={styles.field}>
      <Label size='small' active>TAGS</Label>
      <Dropdown
        className={styles.tags}
        search
        selection
        fluid
        multiple
        allowAdditions
        placeholder='Add Tags'
        options={options}
        value={tags}
        onAddItem={handleAddition}
        onChange={handleChange}
        noResultsMessage={null}
      />
    </div>
  )
}

function UrlAlias({ value, onChange, autoCreateAlias, setAutoCreateAlias, error, ...rest }) {

  const handleAliasChange = (e) => {
    onChange(e.target.value);
  }

  const [aliasEdit, setAliasEdit] = useState(false);
  const [createAlias, setCreateAlias] = useState(false);
  const [alias, setAlias] = useState(null);

  return (
    <div className={cx(styles.field, styles.urlAlias)}>
      <Label size='small' active>URL ALIAS</Label>
      <Checkbox label='AUTO GENERATE URL ALIAS'
        checked={autoCreateAlias}
        onChange={() => {}} />
      <Input placeholder={'Provide asset url alias'}
        value={value}
        error={error}
        disabled={autoCreateAlias}
        onChange={handleAliasChange} />
    </div>
  )
}

function CustomFields({ customFields, assetFields, onChange, ...rest }) {

  const handleFieldChange = (customField, options) => {
    var values = [];
    if (!customField.multiselect && options) {
      values.push(options);
    } else {
      values = options;
    }

    values = values && values.map(item => {
      return customField.options.find(option => {
        return option.id === item;
      })
    }).filter(item => item != null);

    let selectedFieldIndex = (assetFields || []).findIndex(assetField => {
      return assetField.id === customField.id;
    });

    if (selectedFieldIndex === -1) {
      let selectedField = {
        id: customField.id,
        name: customField.name,
        value: values
      }

      assetFields.push(selectedField);
    } else {
      assetFields = assetFields.map(assetField => {
        if (assetField.id === customField.id) {
          return {
            ...assetField,
            value: values
          }
        }

        return assetField;
      })
    }

    assetFields = assetFields.filter(item => {
      return !_.isEmpty(item.value);
    });

    onChange(assetFields);
  }

  return (customFields || []).filter(item => {
    return item.options && item.options.length > 0;
  }).map(item => {
    var assetField = (assetFields || []).find(field => {
      return field.id === item.id;
    });

    var multiple = item.multiselect;

    var values = (assetField && assetField.value || []).map(value => {
      return value.id;
    });

    if (!multiple) {
      values = values.length > 0 ? values[0] : null;
    }

    return (
      <CustomField
        key={item.id}
        field={item}
        values={values}
        multiple={multiple}
        onChange={handleFieldChange} />
    )
  })
}

function CustomField({ field, values, multiple, onChange, ...rest }) {
  const options = () => {
    return (field.options || []).map(item => {
      return {
        key: item.id,
        value: item.id,
        text: item.value
      }
    })
  }

  const handleChange = (e, { value }) => {
    onChange(field, value);
  }

  return (
    <div className={styles.field}>
      <Label className={styles.fieldLabel} size='small' active>{field.name}</Label>
      <Dropdown
        className={styles.customField}
        options={options()}
        placeholder={`Select Value${multiple ? 's' : ''}`}
        search
        selection
        fluid
        multiple={multiple}
        value={values}
        onChange={handleChange}
        upward={false}
      />
    </div>
  )
}

function ViewerMode({ value, onChange, ...rest }) {
  const options = [
    {
      key: 'render_in_viewer',
      value: 'render_in_viewer',
      text: 'Render in viewer'
    },
    {
      key: 'render_optimized_view',
      value: 'render_optimized_view',
      text: 'Render optimized view'
    },
    {
      key: 'open_in_new_tab',
      value: 'open_in_new_tab',
      text: 'Open in new tab'
    }
  ]

  const handleChange = (e, { value }) => {
    onChange(value);
  }

  return (
    <div className={styles.field}>
      <Label className={styles.fieldLabel} size='small' active>Viewer Mode</Label>
      <Dropdown
        className={styles.customField}
        options={options}
        placeholder={`Select Value`}
        search
        selection
        fluid
        multiple={false}
        value={value}
        onChange={handleChange}
        upward={false}
      />
    </div>
  )
}


EditAsset.Tags = TagsField;
EditAsset.CustomFields = CustomFields;

export { EditAsset };