import React, {useState, Fragment, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import { updateWidget, updatingWidgetContentSucceeded } from 'actions/widgetEditor';

import cx from 'classnames';
import _ from 'lodash';

import {
  Button,
  Icon,
  Modal
} from 'semantic-ui-react';


import AceEditor from "react-ace";
import "ace-builds/src-noconflict/mode-css";
import "ace-builds/src-noconflict/theme-tomorrow";
import "ace-builds/src-min-noconflict/ext-searchbox";
import "ace-builds/src-min-noconflict/ext-language_tools";

import { WidgetRegistry } from '../registry.jsx';
import { Widget } from '../widget.jsx';
import { Property } from 'components/Properties';
import { QuickSettings } from 'components/QuickSettings';

import styles from "./texts.module.scss";

export function Html(props){
  return (
    <div className={cx(styles.html, props.className)}
      id={`widget_${props.id}`}
      onMouseOver={props.onMouseOver}
      onMouseOut={props.onMouseOut}
      onClick={props.onClick}
    >
      {props.editable && <div className={styles.htmlOverlay}></div>} 
      <div className={styles.inner} dangerouslySetInnerHTML={{__html: props.content}}/>
      <Widget.Children widgets={props.widgets} editor={props.editor} editable={props.editable} />
      {props.children}
    </div>
  );
}

function HtmlQuickSettings(props) {
  const dispatch = useDispatch();

  const [selected, setSelected] = useState('advanced');

  const menuItems = [
    { id: 'advanced', title: 'Advanced', icon: 'icon-custom' }
  ]

  const handleMenuClick = (item) => {
    setSelected(item);
  }

  var editorContext = useSelector(state => state.widgetsEditor.editors[props.editor]);
  var widget = editorContext.widgetsById[props.id];
  if (!widget) {
    return false;
  }

  var properties = widget.properties;

  const handleHtmlChange = (html) => {
    dispatch(updatingWidgetContentSucceeded(props.id, html, props.editor));
  }

  const handleCssChange = (css) => {
    var changeRequest = { ...widget };
    _.set(changeRequest, 'properties.css', css);

    changeRequest = WidgetRegistry.processRequest([changeRequest])[0];
    dispatch(updateWidget(props.id, changeRequest, props.editor));
  }

  return (
    <QuickSettings menuItems={menuItems} selected={selected} onMenuClick={handleMenuClick} >
      <HtmlEditor title='Html' value={widget.content} onChange={handleHtmlChange} />
      <Property.CSS title='Stylesheets' value={properties.css} onChange={handleCssChange} />
    </QuickSettings>
  )
}

Html.QuickSettings = HtmlQuickSettings;

function HtmlProperty(props){
  const dispatch = useDispatch();

  var editorContext = useSelector(state => state.widgetsEditor.editors[props.editor]);
  var widget = editorContext.widgetsById[props.id];
  if(!widget){
    return false;
  }

  var properties = widget.properties;

  const handleHtmlChange = (html) => {
    dispatch(updatingWidgetContentSucceeded(props.id, html, props.editor));
  }

  const handleCssChange = (css) => {
    var changeRequest = {...widget};
    _.set(changeRequest, 'properties.css', css);

    changeRequest  = WidgetRegistry.processRequest([changeRequest])[0];
    dispatch(updateWidget(props.id, changeRequest, props.editor));
  }
  

  return (
    <Fragment>
      <Property.Section title='Advanced' expanded>
        <HtmlEditor title='Html' value={widget.content} onChange={handleHtmlChange} />
        <Property.CSS title='Stylesheets' value={properties.css} onChange={handleCssChange} />
      </Property.Section>
    </Fragment>
  );
}

function HtmlEditor({title, tooltip, value, onChange, ...rest}){
  const [open, setOpen] = useState(false);
  const [html, setHtml] = useState(value);

  const handleSave = () => {
    onChange(html);
    setOpen(false);
  }

  return (
    <Property title={title} tooltip={tooltip}>
      <Modal size='large' 
        trigger={
          <Button size='mini'>Edit</Button>
        } 
        className={styles.cssEditorModal}
        open={open}
        onOpen={() => {
          setOpen(true);
        }}
      >
        <Modal.Header>Custom  Html</Modal.Header>
        <Modal.Content scrolling>
          <AceEditor
            placeholder={'Edit html'}
            mode={'html'}
            theme="tomorrow"
            className={styles.aceEditor}
            fontSize={14}
            width='100%'
            showPrintMargin={true}
            showGutter={true}
            highlightActiveLine={true}
            value={html || ''}
            onChange={(value) => {setHtml(value)}}
            setOptions={{
              useWorker: false,
              enableBasicAutocompletion: true,
              enableLiveAutocompletion: true,
              enableSnippets: false,
              showLineNumbers: true,
              tabSize: 2
            }}
        />  
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={() => setOpen(false)}>
            <Icon name='remove' /> Cancel
          </Button>
          <Button primary onClick={handleSave}>
            <Icon name='checkmark' /> Save Changes
          </Button>
        </Modal.Actions>
      </Modal>
    </Property>
  );
}

Html.Property = HtmlProperty;

export default Html;