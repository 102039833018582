import { ItemMeta } from "semantic-ui-react";

export function normalise (filters){
  var updatedFilters = (filters || []).map(filter => {
    var property = filter.property;
    var operator = filter.operator;
    var value = filter.value;

    if(filter.property.type === 'asset' || filter.property.type === 'page'){
      value = (filter.value || []).map(item => {
        if(typeof item === 'string' || item instanceof String){
          return item;
        }

        return item.id;
      });
    }

    return {
      property: property,
      operator: operator,
      value: value  
    }
  });

  return updatedFilters;
}

export function createFilterForAssets(assets){
var filters = [{
    property: {
      'type': 'asset'
    },
    operator: 'is',
    value: assets
  }];

  return filters;
}