import React, { useState, Fragment, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import _ from 'lodash';
import cx from 'classnames';
import { useWidgetDrop } from "services/dnd.service";

import styles from './assetViewer.module.scss';
import { ROW, HERO_BANNER, PASSWORD_PROTECTION } from 'constants/ItemTypes';

import { 
  Image 
} from 'semantic-ui-react';

import { QuickSettings, QuickSettingsProperty } from 'components/QuickSettings';
import { Widget } from '../widget.jsx';

import { updateProperties } from 'actions/widgetEditor';

export const AssetViewer = React.memo((props) => {
  const ref = useRef(null);
  const dropRef = useWidgetDrop(props, ref, {
    acceptTargets: (type) => {
      return _.includes([ROW, HERO_BANNER, PASSWORD_PROTECTION], type);
    }
  });

  return (
    <div className={cx(styles.assetViewer, props.className)}
      id={`widget_${props.id}`}
      ref={props.dragRef(dropRef(ref))}
      onMouseOver={props.onMouseOver}
      onMouseOut={props.onMouseOut}
      onClick={props.onClick}>
        <Image src="/images/assetViewer/asset_viewer_entity_placeholder.png" />

        <Widget.Children widgets={props.widgets} parent={props.id} editor={props.editor} editable={props.editable} />
        {props.children}
    </div>
  )
})

function AssetViewerQuickSettings(props) {
  const dispatch = useDispatch();

  const [selected, setSelected] = useState('type');

  const menuItems = [
    { id: 'type', title: 'Type', icon: 'icon-custom' }
  ]

  const handleMenuClick = (item) => {
    setSelected(item);
  }

  var editorContext = useSelector(state => state.widgetsEditor.editors[props.editor]);
  var widget = editorContext.widgetsById[props.id];

  if (!widget) {
    return false;
  }

  var properties = widget.properties;

  const handleChange = (changeRequest) => {
    dispatch(updateProperties({
      id: widget.id,
      change: changeRequest,
      context: props.editor
    }));
  }

  return (
    <QuickSettings menuItems={menuItems} selected={selected} onMenuClick={handleMenuClick} >
      {selected === 'type' &&
        <QuickSettingsProperty.Dropdown
          title='Type'
          tooltip='Viewer type'
          selection
          options={[
            { key: 'full', value: 'full', text: 'Full' },
            { key: 'minimal', value: 'minimal', text: 'Minimal' },
            { key: 'none', value: 'none', text: 'None'}
          ]}
          value={_.get(properties, 'type') || 'full'}
          onChange={(e, data) => {
            handleChange({
              type: data.value
            })
          }}
        />
      }
    </QuickSettings>
  )
}

AssetViewer.QuickSettings = AssetViewerQuickSettings;