import {
  FETCHING_HUB_STREAMS_REQUEST, FETCHING_HUB_STREAMS_SUCCEEDED, FETCHING_HUB_STREAMS_FAILED,
  FETCHING_STREAM_REQUEST, FETCHING_STREAM_SUCCEEDED, FETCHING_STREAM_FAILED,
  FETCHING_GROUP_REQUEST, FETCHING_GROUP_SUCCEEDED, FETCHING_GROUP_FAILED,
  FETCHING_COLLECTIONS_REQUEST, FETCHING_COLLECTIONS_SUCCEEDED, FETCHING_COLLECTIONS_FAILED,
  FETCHING_COLLECTION_REQUEST, FETCHING_COLLECTION_SUCCEEDED, FETCHING_COLLECTION_FAILED,
  FETCHING_COLLECTION_SECTION_REQUEST, FETCHING_COLLECTION_SECTION_SUCCEEDED, FETCHING_COLLECTION_SECTION_FAILED
} from 'constants/actionTypes.jsx';


import axios from 'axios';
import buildUrl from 'build-url';
import { call, put, takeEvery, takeLatest, actionChannel } from 'redux-saga/effects';

const apiUrl = process.env.REACT_APP_API_URL;
const STREAMS_BASE_URL = `${apiUrl}/api/2.0/streams`;
const GROUP_BASE_URL = `${apiUrl}/api/2.0/groups`;
const COLLECTION_BASE_URL = `${apiUrl}/api/2.0/collections`;
const COLLECTION_SECTION_BASE_URL = `${apiUrl}/api/2.0/collections/sections`;
const HUB_STREAMS_URL = `${STREAMS_BASE_URL}/hub`;

export default function* watchFetchHubStreams() {
  yield takeLatest(FETCHING_HUB_STREAMS_REQUEST, fetchHubStreams);
  yield takeEvery(FETCHING_STREAM_REQUEST, fetchStream);
  yield takeEvery(FETCHING_GROUP_REQUEST, fetchGroup);
  yield takeLatest(FETCHING_COLLECTIONS_REQUEST, fetchCollections);
  yield takeEvery(FETCHING_COLLECTION_REQUEST, fetchCollection);
  yield takeEvery(FETCHING_COLLECTION_SECTION_REQUEST, fetchCollectionSection)
};

/*
* Fetch hub streams
*/
export function fetchHubStreamsRequest() {
  return {
    type: FETCHING_HUB_STREAMS_REQUEST,
    payload: {}
  }
}

export function fetchingHubStreamsSucceeded(response) {
  return {
    type: FETCHING_HUB_STREAMS_SUCCEEDED,
    payload: response
  };
}

function* fetchHubStreams() {
  console.log("Watcher method called");

  const fetchingHubStreams = () => {
    return axios.get(
      HUB_STREAMS_URL
    ).then(response => response.data)
      .catch(err => {
        throw err;
      });
  }

  try {
    const response = yield call(fetchingHubStreams);
    if (response) {
      yield put(fetchingHubStreamsSucceeded(response));
      console.log("success");
    }
  } catch (error) {
    yield put({ type: FETCHING_HUB_STREAMS_FAILED, payload: error });
    console.log("failed");
  }
}

/*
* Fetch stream
*/
export function fetchStreamRequest(id) {
  return {
    type: FETCHING_STREAM_REQUEST,
    payload: {
      id: id
    }
  }
}

export function fetchingStreamSucceeded(response, id) {
  return {
    type: FETCHING_STREAM_SUCCEEDED,
    payload: {
      stream: response,
      id: id
    }
  };
}

export function fetchingStreamFailed(error, id) {
  return {
    type: FETCHING_STREAM_FAILED,
    payload: {
      error: error,
      id: id
    }
  };
}


function* fetchStream(action) {
  console.log("Watcher method called");

  const { id, callback } = action.payload;

  const fetchingStream = () => {
    return axios.get(
      STREAMS_BASE_URL + '/' + id
    ).then((response) => {
      return response.data;
    })
      .catch((err) => {
        throw err;
      });

  }

  try {
    const response = yield call(fetchingStream);

    if (response) {
      yield put(fetchingStreamSucceeded(response, id));
    }
  } catch (error) {
    yield put(fetchingStreamFailed(error, id));
  }
}

/*
* Fetch group
*/
export function fetchGroupRequest(id) {
  return {
    type: FETCHING_GROUP_REQUEST,
    payload: {
      id: id
    }
  }
}

export function fetchingGroupSucceeded(response, id) {
  return {
    type: FETCHING_GROUP_SUCCEEDED,
    payload: {
      group: response,
      id: id
    }
  };
}

export function fetchingGroupFailed(error, id) {
  return {
    type: FETCHING_GROUP_FAILED,
    payload: {
      error: error,
      id: id
    }
  };
}

function* fetchGroup(action) {
  const {id, callback} = action.payload;

  const fetchingGroup = () => {
    return axios.get(
      `${GROUP_BASE_URL}/${id}`
    ).then((response) => {
      return response.data;
    })
    .catch((err) => {
      throw err;
    })
  }

  try {
    const response = yield call(fetchingGroup);

    if(response) {
      yield put(fetchingGroupSucceeded(response, id));
    }
  } catch (error) {
    yield put(fetchingGroupFailed(error, id));
  }
}

/*
* Fetch collections
*/
export function fetchCollectionsRequest() {
  return {
    type: FETCHING_COLLECTIONS_REQUEST,
    payload: {}
  }
}

function fetchingCollectionsSucceeded(response) {
  return {
    type: FETCHING_COLLECTIONS_SUCCEEDED,
    payload: response
  }
}

function fetchingCollectionsFailed(error) {
  return {
    type: FETCHING_COLLECTIONS_FAILED,
    payload: error
  }
}


function* fetchCollections(action) {

  const fetchingCollections = () => {
    return axios.get(
      COLLECTION_BASE_URL
    ).then((response) => {
      return response.data;
    })
      .catch((err) => {
        throw err;
      })
  }

  try {
    const response = yield call(fetchingCollections);

    if (response) {
      yield put(fetchingCollectionsSucceeded(response));
    }
  } catch (error) {
    yield put(fetchingCollectionsFailed(error));
  }
}

/*
* Fetch collection
*/
export function fetchCollectionRequest(id) {
  return {
    type: FETCHING_COLLECTION_REQUEST,
    payload: {
      id: id
    }
  }
}

function fetchingCollectionSucceeded(id, response) {
  return {
    type: FETCHING_COLLECTION_SUCCEEDED,
    payload: {
      id: id,
      collection: response
    }
  }
}

function fetchingCollectionFailed(id, error) {
  return {
    type: FETCHING_COLLECTION_FAILED,
    payload: {
      id: id,
      error: error
    }
  }
}

function* fetchCollection(action) {
  const { id } = action.payload;

  const fetchingCollection = () => {
    return axios.get(
      `${COLLECTION_BASE_URL}/${id}`
    ).then((response) => {
      return response.data;
    })
      .catch((err) => {
        throw err;
      })
  }

  try {
    const response = yield call(fetchingCollection);

    if (response) {
      yield put(fetchingCollectionSucceeded(id, response));
    }
  } catch (error) {
    yield put(fetchingCollectionFailed(id, error));
  }
}

/*
* Fetch collection section
*/
export function fetchCollectionSectionRequest(collectionId, sectionId) {
  return {
    type: FETCHING_COLLECTION_SECTION_REQUEST,
    payload: {
      collectionId: collectionId,
      sectionId: sectionId
    }
  }
}

function fetchingCollectionSectionSucceeded(collectionId, sectionId, response) {
  return {
    type: FETCHING_COLLECTION_SECTION_SUCCEEDED,
    payload: {
      collectionId: collectionId,
      sectionId: sectionId,
      section: response
    }
  }
}

function fetchingCollectionSectionFailed(collectionId, sectionId, error) {
  return {
    type: FETCHING_COLLECTION_SECTION_FAILED,
    payload: {
      collectionId: collectionId,
      sectionId: sectionId,
      error: error
    }
  }
}

function* fetchCollectionSection(action) {
  const { collectionId, sectionId } = action.payload;

  const fetchingCollectionSection = () => {
    return axios.get(
      `${COLLECTION_SECTION_BASE_URL}/${sectionId}`
    ).then((response) => {
      return response.data;
    })
      .catch((err) => {
        throw err;
      })
  }

  try {
    const response = yield call(fetchingCollectionSection);

    if (response) {
      yield put(fetchingCollectionSectionSucceeded(collectionId, sectionId, response));
    }
  } catch (error) {
    yield put(fetchingCollectionSectionFailed(collectionId, sectionId, error));
  }
}