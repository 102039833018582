import { useWhatChanged } from "@simbathesailor/use-what-changed";

import React, { Fragment, useState, useEffect, useRef, forwardRef, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  selectWidget, deleteWidget, invalidateWidgetPlaceholder,
  resizeWidget, invalidateWidgetResize, updateProperties,
  processWidgetDropRequest, rearrangeWidgets, cloneWidget
} from 'actions/widgetEditor';

import { HIDDEN_WIDGETS, ASSET_VIEWER } from 'constants/ItemTypes';

import _ from 'lodash';
import cx from 'classnames';

import { WidgetRegistry } from './registry.jsx';

import {
  Icon,
  Label,
  Portal
} from 'semantic-ui-react';

import { Helmet } from "react-helmet";
import { useDrag, DragPreviewImage } from 'react-dnd';
import { WIDGET_ITEM } from 'constants/ItemTypes.jsx';
import Draggable from 'react-draggable';

import styles from './widget.module.scss';
import { WidgetContext } from 'pages/pages/editor/editor.jsx';
import { getResizableWidget, isResizable, getClosestColumnForWidget, getClosestEditableParentForWidget } from "services/widget.helper.jsx";

import FloatingPane from 'components/FloatingPane';
import Tooltip from "components/Tooltip/index.jsx";

export function WidgetPlaceholder(props) {
  const type = _.get(props, 'value.data.type');
  const direction = _.get(props, 'value.data.direction', 'down');

  return <div className={cx(styles.placeholder, styles[type], styles[direction])}></div>
}

function WidgetAction({ icon, tooltip, children, onClick, ...rest }) {
  return (
    <Tooltip content={tooltip}
      position='top center'
      hoverable
      inverted
      size='mini'
      disabled={tooltip == null}
      popperModifiers={{
        preventOverflow: {
          boundariesElement: "offsetParent"
        }
      }}>
      <Label size='mini' className={rest.className} onClick={(e) => {
        if (onClick) {
          onClick(e);
        }
      }}>
        {icon &&
          <Icon name={icon} />
        }
        {children}
      </Label>
    </Tooltip>
  );
}

export function WidgetHeader(props) {
  const dispatch = useDispatch();
  const [showQuickSettings, setShowQuickSettings] = useState(false);
  const [position, setPosition] = useState({ top: 0, left: 0 });

  const item = props.item;
  const editor = props.editor;
  const { entityReference, modal } = React.useContext(WidgetContext);

  const headerRef = useRef(null);
  const settingsRef = useRef();
  const viewerElement = document.querySelector(`#${modal && 'modal_'}editor_${props.editor}`);

  var ticking = false;
  var frameId = false;

  const deletable = _.get(item, 'capabilities.deletable', true);
  const draggable = _.get(item, 'capabilities.draggable', true);
  const clonable = _.get(item, 'capabilities.clonable', true);

  const canMoveDown = useSelector(state => {
    var editorContext = state.widgetsEditor.editors[editor];

    if (!editorContext) {
      return false;
    }

    var widgets = editorContext.widgets;
    if (item.parent != null) {
      const widget = editorContext.widgetsById[item.parent];
      if (widget != null) {
        widgets = widget.widgets;
      }
    }

    widgets = widgets || [];

    return props.index < widgets.length - 1;
  });

  const editableParent = useSelector(state => {
    var editorContext = state.widgetsEditor.editors[editor];

    if (!editorContext) {
      return null;
    }

    return getClosestEditableParentForWidget(editorContext, item.id);
  })

  useEffect(() => {
    if (props.selected) {
      handleUpdate();

    } else {
      setShowQuickSettings(false);
    }
  }, [props.selected]);


  const handleQuickSettingsClick = () => {
    if (!showQuickSettings) {
      var clientRect = settingsRef && settingsRef.current.getBoundingClientRect && settingsRef.current.getBoundingClientRect()
      setPosition({
        top: clientRect.top,
        left: clientRect.left
      })
    };

    setShowQuickSettings(!showQuickSettings);
  }

  const handleMoveDown = () => {
    dispatch(rearrangeWidgets(editor, entityReference, item, item.parent, props.index + 1, false));
  }

  const handleMoveUp = () => {
    dispatch(rearrangeWidgets(editor, entityReference, item, item.parent, props.index - 1, true));
  }

  const handleClone = () => {
    dispatch(cloneWidget(editor, item, entityReference, props.index));
  }

  const handleRemove = () => {
    dispatch(deleteWidget(item.id, editor));
  }

  const handleSelectParent = () => {
    if (editableParent) {
      dispatch(selectWidget(editor, editableParent.id));
    }
  }

  const handleUpdate = () => {
    if (ticking) {
      return;
    }

    ticking = true;
    frameId = requestAnimationFrame(update)
  }

  const update = () => {
    ticking = false;

    if (!headerRef.current) {
      return;
    }

    const element = props.parentRef();
    if (!element) {
      return;
    }

    if (!viewerElement) {
      return null;
    }

    const rect = element.getBoundingClientRect();
    const viewerRect = viewerElement && viewerElement.getBoundingClientRect();

    const top = rect.top - viewerRect.top;
    const left = Math.max(0, rect.left - viewerRect.left);

    headerRef.current.style.cssText = `top: ${top}px; left: ${left}px`;
    handleUpdate();
  }

  if (!viewerElement) {
    return null;
  }

  return (
    <Portal
      closeOnTriggerClick
      openOnTriggerClick
      open={props.selected}
      mountNode={viewerElement}
    >
      <div ref={props.dragRef(headerRef)} className={cx(styles.widgetHeader, 'widget__header', {
        [styles.hover]: props.hover,
        [styles.selected]: props.selected,
        [styles.dragging]: props.dragging
      })}>
        <div className={styles.header}>
          <Label className={styles.title} size='mini'>
            {props.item.name}
          </Label>
          {props.quickAction &&
            <Label size='mini' onClick={() => {
              if (props.onQuickActionClick) {
                props.onQuickActionClick();
              }
            }}>
              {props.quickAction}
            </Label>
          }
          {props.selected &&
            <>
              {WidgetRegistry.quickActions(item.type, {
                id: item.id,
                widget: item,
                editor: editor
              })}

              <WidgetAction icon="paint brush" tooltip='Design' onClick={handleQuickSettingsClick}>
                <div className={styles.quickSettingsRef} ref={settingsRef}></div>
              </WidgetAction>

              {clonable &&
              <WidgetAction icon="clone outline" tooltip='Clone' onClick={handleClone} />}

              {draggable && props.index > 0 &&
                <WidgetAction icon="arrow up" tooltip='Move up' onClick={handleMoveUp} />
              }

              {draggable && canMoveDown &&
                <WidgetAction icon="arrow down" tooltip='Move down' onClick={handleMoveDown} />
              }

              {editableParent &&
                <WidgetAction icon="angle double up" tooltip='Select parent' onClick={handleSelectParent} />
              }

              {deletable &&
                <WidgetAction icon="trash" tooltip='Delete' onClick={handleRemove} />
              }
            </>
          }
        </div>

        {props.selected && showQuickSettings &&
          <FloatingPane
            width={260}
            height='35vh'
            position={position}
            onClose={handleQuickSettingsClick}
          >
            <QuickSettingsPane editor={props.editor} />
          </FloatingPane>}
      </div>
    </Portal>
  );
}

export function QuickSettingsPane(props) {
  const selectedWidget = useWidgetSelecion(props.editor);

  if (!selectedWidget) {
    return false;
  }

  return (
    WidgetRegistry.quickSettings(selectedWidget.type,
      {
        id: selectedWidget.id,
        widget: selectedWidget,
        editor: props.editor
      }
    )
  );
}

const WidgetSelection = React.memo(function WidgetSelection({
  editor, dragRef, item, hover, selected, resizable, dragging, onRemove, onResizing, onResize, ...rest
}) {
  const selectionRef = React.useRef(null);

  return (
    <>
      <WidgetHeader
        parentRef={() => selectionRef.current}
        editor={editor}
        dragRef={dragRef}
        item={item}
        hover={hover}
        dragging={dragging}
        selected={selected}
        onRemove={onRemove}
        {...rest}
      />

      <div ref={selectionRef} className={cx(styles.widgetSelection, {
        [styles.active]: hover || selected,
        [styles.drop]: hover || selected
      })}>
      </div>
      {selected && resizable && false &&
        <Resizable onResizing={onResizing} onResize={onResize} />
      }
    </>
  );
});

export function Resizable(props) {

  const onResizing = _.throttle((e, ui) => {
    if (props.onResizing) {
      props.onResizing({
        x: ui.deltaX,
        y: 0
      });
    }
  }, 100);

  const onStop = (e, ui) => {
    if (props.onResize) {
      props.onResize();
    }
  }

  return (
    <>
      <div className={cx(styles.anchor, styles.right)}></div>
      <Draggable handle={`.${styles.handle}`} axis='x' onDrag={onResizing} onStop={onStop}>
        <div className={cx(styles.handle, styles.right)}></div>
      </Draggable>
    </>
  );
}

const Widget = React.memo(function Widget({ editor, item, editable, ...rest }) {
  const { type } = item;
  const dispatch = useDispatch();
  const [hover, setHover] = useState(false);

  const selectedWidget = useWidgetSelecion(editor);
  const selected = selectedWidget ? selectedWidget.id === item.id : false;

  const { entityReference } = React.useContext(WidgetContext);

  const editorContext = useSelector(state => {
    return state.widgetsEditor.editors[editor];
  });

  const resizable = isResizable(editorContext, item);

  var widgets = item.widgets || [];
  widgets = useSelector(state => {
    var items = [];

    var editorContext = state.widgetsEditor.editors[editor];

    if (editorContext) {
      items = widgets.map(widget => {
        return editorContext.widgetsById[widget];
      });
    }

    return items;
  });

  const [{ dragging }, dragRef, previewRef] = useDrag(() => ({
    type: WIDGET_ITEM,
    item: {
      type: WIDGET_ITEM,
      content: item,
      request: {
        index: rest.index
      },
      originalIndex: rest.index
    },
    canDrag(monitor) {
      return _.get(item, 'capabilities.draggable', true);
    },
    end: (item, monitor) => {
      //dispatch(rearrangeWidgets(props.editor, entityReference, item.content, target, index, insertBefore));
      dispatch(processWidgetDropRequest(editor, entityReference, item.content));
      dispatch(invalidateWidgetPlaceholder(editor));
    },
    collect: monitor => ({
      dragging: monitor.isDragging()
    })
  }), [item, rest.index]);

  const key = item.id;

  const handleMouseEnter = useCallback((e) => {
    e.stopPropagation();
    setHover(true);
  }, []);

  const handleMouseLeave = useCallback((e) => {
    e.stopPropagation();
    setHover(false);
  }, []);

  const handleClick = useCallback((e) => {
    e.stopPropagation();
    if (!selected) {
      dispatch(selectWidget(editor, item.id));
    }
  }, [selected]);

  //Handle Resize Events
  const handleResizing = useCallback((delta) => {
    const widget = getResizableWidget(editorContext, item);

    if (!widget) {
      return;
    }

    const resizable = _.get(item, 'capabilities.resizable', true);
    if (!resizable) {
      return;
    }

    const selector = `#widget_${widget.id}`;
    const element = document.querySelector(selector);
    if (!element) {
      return;
    }

    const rowWidth = element.parentElement.offsetWidth;
    const minWidth = rowWidth / 16;
    var width = element.offsetWidth + delta.x;

    width = Math.max(width, minWidth);
    width = Math.min(width, rowWidth);

    dispatch(resizeWidget(editor, widget.id, {
      width: width
    }));

  }, []);

  const handleResizeFinished = useCallback(() => {
    const widget = getClosestColumnForWidget(editorContext, item.id);

    if (!widget) {
      return;
    }

    const resizable = _.get(item, 'capabilities.resizable', true);
    if (!resizable) {
      return;
    }

    const selector = `#widget_${widget.id}`;
    const element = document.querySelector(selector);
    if (!element) {
      return;
    }

    const width = element.offsetWidth
    const rowWidth = element.parentElement.offsetWidth;
    const widthPerColumn = rowWidth / 16;

    var columns = Math.round(width / widthPerColumn);
    columns = Math.min(columns, 16);
    columns = Math.max(columns, 1);

    dispatch(updateProperties({
      id: widget.id,
      change: {
        width: columns
      },
      context: editor
    }));

    dispatch(invalidateWidgetResize(editor));
  });

  const componentConfig = WidgetRegistry.widgets[type];
  if (!componentConfig) {
    console.error(`Widget config for type ${type} not found. Check if the widget type is registered`);
    return false;
  }

  const viewType = editable ? 'editor' : 'view';
  var Component = componentConfig[viewType];
  if (!Component) {
    return false;
  }

  const stylesheets = _.get(item, 'properties.css');
  const showPlaceholder = rest.placeholder != null;

  var hasEditCapability = _.get(item, 'capabilities.editable', true);
  if (type === ASSET_VIEWER) {
    hasEditCapability = true;
  }

  return (
    <>
      {stylesheets &&
        <Helmet>
          <style type="text/css">{stylesheets}</style>
        </Helmet>
      }

      <DragPreviewImage src="/images/assets/add_url.png" connect={previewRef} />
      
      <Component
        key={key}
        dragRef={dragRef}
        className={cx(styles.widget, {
          [styles.hasPlaceholder]: showPlaceholder,
          [styles.dragging]: dragging
        })}
        {...item}
        {...rest}
        editor={editor}
        editable={editable}
        selected={selected}
        onMouseOver={hasEditCapability && editable ? handleMouseEnter : null}
        onMouseOut={hasEditCapability && editable ? handleMouseLeave : null}
        onClick={hasEditCapability && editable ? handleClick : null}
      >
        <WidgetSelection
          editor={editor}
          dragRef={dragRef}
          item={item}
          index={rest.index}
          hover={editable && hover}
          selected={editable && selected}
          dragging={dragging}
          resizable={resizable}
          onResizing={handleResizing}
          onResize={handleResizeFinished}
        />

        {showPlaceholder &&
          <WidgetPlaceholder value={rest.placeholder} />
        }
      </Component>
    </>
  );
});

const WidgetChildren = React.memo(function WidgetChildren({ editor, parent, widgets, editable, ...rest }) {
  var widgets = widgets || [];
  widgets = useSelector(state => {
    var items = [];

    var editorContext = state.widgetsEditor.editors[editor];

    if (editorContext) {
      items = widgets.map(widget => {
        return editorContext.widgetsById[widget];
      });
    }

    return items;
  }).filter(widget => {
    return widget != null && !_.includes(HIDDEN_WIDGETS, widget);
  });

  const placeholder = useSelector(state => {
    var editorContext = state.widgetsEditor.editors[editor];
    //return _.get(editorContext, 'placeholder');
    return null;
  });

  if (widgets.length == 0) {
    if (placeholder && placeholder.parent == parent) {
      const updated = {
        ...placeholder,
        data: {
          ...placeholder.data,
          direction: 'up'
        }
      };

      return <WidgetPlaceholder value={updated} />;
    }
  }

  return widgets.map((widget, index) => {
    var showPlaceholder = false;
    if (placeholder) {
      showPlaceholder = (placeholder.parent == parent) && placeholder.index === index;
    }

    return (
      <Widget
        key={widget.id}
        editor={editor}
        editable={editable}
        item={widget}
        index={index}
        placeholder={showPlaceholder ? placeholder : null}
      />
    )
  });
});

export function useWidgetSelecion(context) {
  const widget = useSelector(state => {
    var editorContext = _.get(state, `widgetsEditor.editors.${context}`, {});
    const id = editorContext.selection;
    if (editorContext && id) {
      return editorContext.widgetsById[id];
    }

    return null;
  });

  return widget;
}

export function useWidgetTypeSelection(context, widgetId) {
  const widgetType = useSelector(state => {
    var editorContext = _.get(state, `widgetsEditor.editors.${context}`, {});
    const id = editorContext.selection;
    if (editorContext && id) {
      var widget = editorContext.widgetsById[id];
      return widget.type;
    }

    return null;
  });

  return widgetType;
}

Widget.Children = WidgetChildren;
Widget.Header = WidgetHeader;
Widget.Placeholder = WidgetPlaceholder;
Widget.Action = WidgetAction;

export { Widget };

export function getStyles(properties) {
  var styles = {};

  if (!properties) {
    return styles;
  }

  var border = getBorderStyles(properties.border);
  if (border) {
    styles = {
      ...styles,
      ...border
    };
  }

  const margin = getSpacingStyles('margin', properties.margin);
  if (margin) {
    styles = {
      ...styles,
      ...margin
    };
  }

  const padding = getSpacingStyles('padding', properties.padding);
  if (padding) {
    styles = {
      ...styles,
      ...padding
    };
  }

  const background = getBackgroundStyles(properties.background);
  if (background) {
    styles = {
      ...styles,
      ...background
    };
  }

  const color = getColorStyles(properties.color);
  if (color) {
    styles = {
      ...styles,
      ...color
    }
  }

  return styles;
}

export function getBorderStyles(value) {
  if (!value) {
    return null;
  }

  const top = _.get(value, 'sides.top', false);
  const left = _.get(value, 'sides.left', false);
  const right = _.get(value, 'sides.right', false);
  const bottom = _.get(value, 'sides.bottom', false);

  const colorRgb = _.get(value, 'color');
  const width = _.get(value, 'width', 'inherit');
  const radius = _.get(value, 'radius', 0);

  var color = 'inherit';
  if (colorRgb) {
    color = `rgba(${colorRgb.r}, ${colorRgb.g}, ${colorRgb.b}, ${colorRgb.a})`;
  }

  var styles = {
    'borderTop': `${top ? width : 0} solid ${color}`,
    'borderBottom': `${bottom ? width : 0} solid ${color}`,
    'borderLeft': `${left ? width : 0} solid ${color}`,
    'borderRight': `${right ? width : 0} solid ${color}`,
  };

  if (radius) {
    styles['borderRadius'] = radius;
  }

  return styles;
}

export function getSpacingStyles(prefix, value) {
  if (!value) {
    return null;
  }

  var styles = {};

  Object.keys(value).forEach(side => {
    var x = _.get(value, side);
    if (x) {
      styles[`${prefix}${_.capitalize(side)}`] = x;
    }
  })

  return styles;
}

export function getBackgroundStyles(value) {
  if (!value) {
    return null;
  }

  const colorRgb = _.get(value, 'color');

  var color = 'inherit';
  if (colorRgb) {
    color = `rgba(${colorRgb.r}, ${colorRgb.g}, ${colorRgb.b}, ${colorRgb.a})`;
  }

  var styles = {
    backgroundColor: color
  };

  return styles;
}

export function getColorStyles(value) {
  if (!value) {
    return null;
  }

  const colorRgb = value;

  var color = 'inherit';
  if (colorRgb) {
    color = `rgba(${colorRgb.r}, ${colorRgb.g}, ${colorRgb.b}, ${colorRgb.a})`;
  }

  var styles = {
    color: color
  };

  return styles;
}

export function getDimensionStyles(value) {
  if (!value) {
    return null;
  }

  var styles = {};

  const getUnit = (value) => {
    if (!value) {
      return '%';
    }

    switch (value.unit) {
      case 'percent':
        return '%';
      case 'px':
      default:
        return 'px';
    }
  }

  if (value.width) {
    styles = {
      ...styles,
      width: `${value.width}${getUnit(value)}`
    }
  }

  if (value.height) {
    styles = {
      ...styles,
      height: `${value.height}${getUnit(value)}`
    }
  }

  return styles;
}