import { 
  CREATE_ASSET_TEMPLATE_REQUEST, CREATING_ASSET_TEMPLATE_SUCCEEDED, CREATING_ASSET_TEMPLATE_FAILED,
  UPDATE_ASSET_TEMPLATE_REQUEST, UPDATING_ASSET_TEMPLATE_SUCCEEDED, UPDATING_ASSET_TEMPLATE_FAILED, 
  FETCH_ASSET_TEMPLATES_REQUEST, FETCHING_ASSET_TEMPLATES_SUCCEEDED, FETCHING_ASSET_TEMPLATES_FAILED,
  FETCH_ASSET_TEMPLATE_REQUEST, FETCHING_ASSET_TEMPLATE_SUCCEEDED,FETCHING_ASSET_TEMPLATE_FAILED,
  DELETE_ASSET_TEMPLATE_REQUEST, DELETING_ASSET_TEMPLATE_SUCCEEDED, DELETING_ASSET_TEMPLATE_FAILED,

  FETCH_PAGE_TEMPLATES_REQUEST, FETCHING_PAGE_TEMPLATES_SUCCEEDED, FETCHING_PAGE_TEMPLATES_FAILED,
  FETCH_PAGE_TEMPLATE_REQUEST, FETCHING_PAGE_TEMPLATE_SUCCEEDED, FETCHING_PAGE_TEMPLATE_FAILED,
  UPDATE_PAGE_TEMPLATE_REQUEST, UPDATING_PAGE_TEMPLATE_SUCCEEDED, UPDATING_PAGE_TEMPLATE_FAILED,
  PUBLISH_PAGE_TEMPLATE_REQUEST, PUBLISHING_PAGE_TEMPLATE_SUCCEEDED, PUBLISHING_PAGE_TEMPLATE_FAILED,

  UPDATING_PAGE_TEMPLATE_PROPERTIES
} from 'constants/actionTypes.jsx';

const initialState = {
  loading: false,
  items: [],
  create: {
    creating: false,
    item: null,
    error: null
  },
  template: {
    loading: false,
    updating: false,
    deleting: false,
    item: null,
    error: null
  },
  pages: {
    loading: false,
    items: [],
    template: {
      loading: false,
      updating: false,
      deleting: false,
      publishing: false,
      item: null,
      error: null
    }
  }
}

function templatesReducer(state = initialState, action) {
  switch (action.type) {
    case CREATE_ASSET_TEMPLATE_REQUEST:
      return {...state,
        create: {
          creating: true
        }
      }  

    case CREATING_ASSET_TEMPLATE_SUCCEEDED:
      var items = [action.payload, ...state.items];

      return {...state,
        items: items,
        create: {
          creating: false,
          item: action.payload,
          error: null
        },
        template:{
          ...state.template,
          loading: false,
          item: action.payload
        }
      }

    case CREATING_ASSET_TEMPLATE_FAILED:
      return {...state,
        create: {
          creating: false,
          item: null,
          error: action.payload
        }
      }    

    case UPDATE_ASSET_TEMPLATE_REQUEST:
      return {...state,
        template:{
          ...state.template,
          updating: true
        }
      }  

    case UPDATING_ASSET_TEMPLATE_SUCCEEDED:
      var template = action.payload;

      var items = state.items.map(item => {
        if(item.id === template.id){
          return {...item, 
            name: template.name,
            modifiedDate: template.modifiedDate
          }
        }

        return item;
      });

      return {...state,
        items: items,
        template:{
          ...state.template,
          updating: false,
          item: action.payload
        }
      }    

    case UPDATING_ASSET_TEMPLATE_FAILED:
      return {...state,
        template:{
          ...state.template,
          updating: false,
          error: action.payload
        }
      }      
  
    case FETCH_ASSET_TEMPLATES_REQUEST:
      return {...state,
        loading: true,
        error: null
      }  

    case FETCHING_ASSET_TEMPLATES_SUCCEEDED:
      return {...state,
        loading: false,
        items: action.payload,
        error: null
      }    

    case FETCHING_ASSET_TEMPLATES_FAILED:
      return {...state,
        loading: false,
        items: [],
        error: action.payload
      }    

    case FETCH_ASSET_TEMPLATE_REQUEST:
      return {...state,
        template:{
          ...state.template,
          loading: true
        }
      }  

    case FETCHING_ASSET_TEMPLATE_SUCCEEDED:
      return {...state,
        create: {
          creating: false,
          item: null,
          error: null
        },
        template:{
          ...state.template,
          loading: false,
          item: action.payload
        }
      }    

    case FETCHING_ASSET_TEMPLATE_FAILED:
      return {...state,
        template:{
          ...state.template,
          loading: false,
          error: action.payload
        }
      }      

    case DELETE_ASSET_TEMPLATE_REQUEST:
      return {...state,
        template:{
          ...state.template,
          deleting: true
        }
      }  

    case DELETING_ASSET_TEMPLATE_SUCCEEDED:
      var template = action.payload;

      var items = state.items.filter(item => {
        return item.id != template.id;
      });
      
      return {...state,
        items: items,
        template:{
          ...state.template,
          deleting: false,
          loading: false,
          updating: false,
          item: null,
          error: null
        }
      }    

    case DELETING_ASSET_TEMPLATE_FAILED:
      return {...state,
        template:{
          ...state.template,
          deleting: false,
          error: action.payload
        }
      }

    case FETCH_PAGE_TEMPLATES_REQUEST:
      return {
        ...state,
        pages: {
          ...state.pages,
          loading: true
        }
      }

    case FETCHING_PAGE_TEMPLATES_SUCCEEDED:
      return {
        ...state,
        pages: {
          ...state.pages,
          loading: false,
          items: action.payload
        }
      }

    case FETCHING_PAGE_TEMPLATE_FAILED:
      return {
        ...state,
        pages: {
          ...state.pages,
          loading: false,
          error: action.payload
        }
      }
    
    case FETCH_PAGE_TEMPLATE_REQUEST:
      return {
        ...state,
        pages: {
          ...state.pages,
          template:{
            ...state.pages.template,
            loading: true
          }
        }
      }  

    case FETCHING_PAGE_TEMPLATE_SUCCEEDED:
      return {
        ...state,
        pages: {
          ...state.pages,
          template:{
            ...state.pages.template,
            loading: false,
            item: action.payload
          }
        }
      }

    case FETCHING_PAGE_TEMPLATES_FAILED:
      return {
        ...state,
        pages: {
          ...state.pages,
          template:{
            ...state.pages.template,
            loading: false,
            error: action.payload
          }
        }
      }  

    case UPDATE_PAGE_TEMPLATE_REQUEST:
      return {
        ...state,
        pages: {
          ...state.pages,
          template:{
            ...state.pages.template,
            updating: true
          }
        }
      }  

    case UPDATING_PAGE_TEMPLATE_SUCCEEDED:
      var template = action.payload;

      return {
        ...state,
        pages: {
          ...state.pages,
          template:{
            ...state.pages.template,
            updating: false,
            item: action.payload
          }
        }
      }    
  
      case UPDATING_PAGE_TEMPLATE_FAILED:
        return {
          ...state,
          pages: {
            ...state.pages,
            template:{
              ...state.pages.template,
              updating: false,
              error: action.payload
            }
          }
        }      

      case PUBLISH_PAGE_TEMPLATE_REQUEST:
        return {
          ...state,
          pages: {
            ...state.pages,
            template:{
              ...state.pages.template,
              publishing: true
            }
          }
        }  
  
      case PUBLISHING_PAGE_TEMPLATE_SUCCEEDED:
        var template = action.payload;
  
        return {
          ...state,
          pages: {
            ...state.pages,
            template:{
              ...state.pages.template,
              publishing: false,
              item: action.payload
            }
          }
        }    
    
        case PUBLISHING_PAGE_TEMPLATE_FAILED:
          return {
            ...state,
            pages: {
              ...state.pages,
              template:{
                ...state.pages.template,
                publishing: false,
                error: action.payload
              }
            }
          }          
        
        case UPDATING_PAGE_TEMPLATE_PROPERTIES:
          var { id, change } = action.payload;      
          return {
            ...state,
            pages: {
              ...state.pages,
              template:{
                ...state.pages.template,
                item: {
                  ...state.pages.template.item,
                  draft: {
                    ...state.pages.template.item.draft,
                    properties: {
                      ...state.pages.template.item.draft.properties,
                      ...change
                    }
                  }
                }
              }
            }
          }

    default:
      return state
  }
}

export { templatesReducer };