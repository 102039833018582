import React, { PropTypes, Fragment, useState, useEffect } from 'react';
import { connect, useSelector, useDispatch } from 'react-redux';

import {
  createAssetTemplate, updateAssetTemplate, 
  fetchAssetTemplates, fetchAssetTemplate, 
  deleteAssetTemplate, invalidateAssetTemplates
} from 'actions/templates';

import dateformat from 'dateformat';
import cx from 'classnames';
import {
    Button,
    Dimmer,
    Grid,
    Header,
    Menu,
    Label,
    List,
    Segment,
    Icon,
    Input,
    Form,
    Loader
} from 'semantic-ui-react';

import {
  NavLink,
  Route,
  Switch,
  useParams,
  useHistory, useRouteMatch
} from "react-router-dom";

import AceEditor from "react-ace";
import "ace-builds/src-noconflict/mode-html";
import "ace-builds/src-noconflict/mode-css";
import "ace-builds/src-noconflict/theme-tomorrow";
import "ace-builds/src-min-noconflict/ext-searchbox";
import "ace-builds/src-min-noconflict/ext-language_tools";

import Tooltip from 'components/Tooltip';
import Device from 'components/Device';
import { AssetsGrid } from 'components/Widgets/assets';
import AssetTemplateView from 'components/Templates/assets';
import InlineConfirmationDialog from 'components/InlineConfirmationDialog';
import styles from './templates.module.scss';

const defaultAsset = {
  name: "Welcome to cleverstory",
  summary: "A render of how the asset card will be displayed to the end user with A render of how the asset card will be displayed to the end user with A render of how the asset card will be displayed to the end user with",
  author: {
    firstName: 'John',
    lastName: 'Smith',
    name: 'John Smith'
  },
  metadata: {
    url: '#',
    pages: 12
  }  
}

class TemplatesView extends React.Component {
  constructor(props) {
    super(props);

    this.state = {

    }
  }

  componentDidMount(){
    this.props.invalidateAssetTemplates();
    this.props.fetchAssetTemplates();
  }

  render() {
    const { path } = this.props.match;
    return (
      <Grid columns="equal" stretched divided='vertically' className={cx(styles.templates)}>
        <Grid.Row>
          <Grid.Column width={4}>
            <TemplateList/>
          </Grid.Column>
          <Grid.Column>
            <Switch>
              <Route path={`${path}/:id`} exact>
                <TemplateView />
              </Route>
            </Switch>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    )
  }
}

function TemplateList({onCreateClicked}){
  const history = useHistory();
  const dispatch = useDispatch();

  const {id} = useParams();
  const { path, url } = useRouteMatch();
  const createTemplateState = useSelector(state => state.templates.create);
  const templates = useSelector(state => state.templates.items);
  const template = useSelector(state => state.templates.template);

  const handleCreate = () => {
    dispatch(createAssetTemplate({
      name: 'Untitled Template',
      html: '<div></div>',
      styles: '.asset {}'
    }));
  }

  //On create
  useEffect(() => {
    if(createTemplateState.item){
      history.push(`${url}/${createTemplateState.item.id}`);
    }
  }, [createTemplateState.item]);

  //On load or delete
  useEffect(() => {
    if(!template.item){
      if(templates.length > 0){
        var next = templates[0].id;
        history.replace(`${url}/${next}`);
      }
      
    }
  }, [templates.length, template.item, id]);

  return (
    <Segment basic className={styles.list}>
      <Menu secondary>
        <Menu.Item header as="h2">Templates <Label>{templates.length}</Label></Menu.Item>  
        <Menu.Menu position='right'>
          <Tooltip inverted content={'Create new template'} position="top right">
            <Menu.Item icon='add' onClick={handleCreate} disabled={createTemplateState.creating}/>
          </Tooltip>  
        </Menu.Menu>
      </Menu>
      
      <List selection celled verticalAlign='middle'>
        {templates.map(template => {
          return <TemplateItem key={template.id} template={template} />
        })}
      </List>
    </Segment>
  );
}

function TemplateItem({template}){
  const { path, url } = useRouteMatch();
  return (
    <List.Item as={NavLink} to={`${url}/${template.id}`}>
      <List.Icon name='id card' size='big' verticalAlign='middle' />
      <List.Content>
        <List.Header>{template.name}</List.Header>
        <List.Description>
          Last updated on {dateformat(template.modifiedDate, 'dd mmmm')}
        </List.Description>
      </List.Content>
    </List.Item>
  );
}

function TemplateView(props){
  const {id} = useParams();
  const dispatch = useDispatch();

  const template = useSelector(state => state.templates.template);
  
  const [name, setName] = useState(template.item ? template.item.name : '');
  const [html, setHtml] = useState(template.item ? template.item.html : '');
  const [customStyles, setCustomStyles] = useState(template.item ? template.item.styles : '');

  useEffect(() => {
    var shouldLoad = !(template.item && template.item.id === id);
    if(shouldLoad){
      dispatch(fetchAssetTemplate(id));
    }
  }, [id]);

  useEffect(() => {
    if(template.item){
      setName(template.item.name);
      setHtml(template.item.html);
      setCustomStyles(template.item.styles);
    }
  }, [template.item]);

  const handleSave = () => {
    var request = {
      ...template.item,
      name: name,
      html: html,
      styles: customStyles
    }

    dispatch(updateAssetTemplate(request));
  }

  const handleDelete = () =>{
    dispatch(deleteAssetTemplate(template.item.id));
  }

  if(!template.item){
    return false;
  }

  return (
    <Dimmer.Dimmable as={Segment} basic className={styles.template} dimmed={template.deleting}>
      <Dimmer className={styles.dimmer} active={template.deleting} inverted verticalAlign='top'>
        <Loader inverted inline size='large'>Deleting templates...</Loader>
      </Dimmer>

      
      <Menu secondary>
        <Menu.Item>
          <Input size='massive' className={styles.name} 
            disabled={!template.item.userDefined}
            value={name} 
            onChange={(e) => {
              setName(e.target.value);
            }}
          />
        </Menu.Item>
        
        {template.item.userDefined && 
          <Menu.Menu position='right'>
            <Menu.Item fitted='horizontally'>
              <InlineConfirmationDialog 
                message='Are you sure you would like to delete template?'
                onConfirm={handleDelete}
              >
                <Button negative 
                  loading={template.deleting} 
                  disabled={template.deleting} 
                >Delete Template</Button>
              </InlineConfirmationDialog>
            </Menu.Item>
            <Menu.Item fitted='horizontally'>
              <Button primary 
                loading={template.updating} 
                disabled={template.updating} 
                onClick={handleSave}>Save changes</Button>
            </Menu.Item>
          </Menu.Menu>
        }
      </Menu>
      

      <Segment basic className={cx(styles.section, styles.preview)}>
        <Menu secondary>
          <Menu.Item header as="h3">Preview Template</Menu.Item>
          <Menu.Menu position='right'>
            <Menu.Item as={Button} basic icon='mobile' />
            <Menu.Item as={Button} basic icon='tablet' />
            <Menu.Item as={Button} basic icon='desktop' />
          </Menu.Menu>
        </Menu>
        
        <div style={{padding: '5rem 2rem 2rem'}}>
          <Device mode='desktop'>
              <Grid container>
                <Grid.Column>
                  <AssetsGrid.Layout properties={{
                      layout: {
                        type:'grid', columns: 2
                      },
                      pagination: false
                    }} editor={true}>
                    <AssetTemplateView template={{
                      ...template.item,
                      html: html,
                      styles: customStyles
                    }} asset={defaultAsset}/>
                  </AssetsGrid.Layout>
                </Grid.Column>
              </Grid>
            
          </Device>
        </div>
      </Segment>
      <Grid columns='equal' className={styles.editors}>
        <Grid.Row>
          <Grid.Column>
            <Editor 
              name='HTML Editor' 
              type='html' 
              placeholder={'Edit html'}
              readOnly={!template.item.userDefined}
              value={html}
              onChange={(value) =>{
                setHtml(value);
              }}
            />              
          </Grid.Column>
          <Grid.Column>
            <Editor 
              name='Style Editor' 
              type='css' 
              placeholder={'Edit styles'}
              readOnly={!template.item.userDefined}
              value={customStyles}
              onChange={(value) =>{
                setCustomStyles(value);
              }}
            />
          </Grid.Column>
        </Grid.Row>
        
        {template.item.userDefined && 
          <Grid.Row>
            <Grid.Column textAlign='right'>
              <Button primary 
                loading={template.updating} 
                disabled={template.updating} 
                onClick={handleSave}>Save changes</Button>
              <Button disabled={template.updating}>Cancel</Button>
            </Grid.Column>
        </Grid.Row>
        }
      </Grid>
    </Dimmer.Dimmable>
  );
}

function Editor({name, type, className, placeholder, value, onChange, readOnly}){
  return (
    <Segment basic className={cx(styles.section, styles.editor,className)}>
      <Header as='h3'>{name}</Header>
      <AceEditor
        placeholder={placeholder}
        mode={type}
        theme="tomorrow"
        className={styles.aceEditor}
        fontSize={14}
        width='100%'
        showPrintMargin={true}
        showGutter={true}
        highlightActiveLine={true}
        value={value}
        onChange={onChange}
        readOnly={readOnly}
        setOptions={{
          useWorker: false,
          enableBasicAutocompletion: true,
          enableLiveAutocompletion: true,
          enableSnippets: false,
          showLineNumbers: true,
          tabSize: 2
        }}
      />
    </Segment> 
  );
}

const mapStateToProps = (state, ownProps) => {
  return {
    creating: state.templates.create.creating,
    loading: state.templates.loading,
    templates: state.templates.items
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    createAssetTemplate: (template) => {
      dispatch(createAssetTemplate(template));
    },

    updateAssetTemplate: (template) => {
      dispatch(updateAssetTemplate(template));
    },

    fetchAssetTemplates: () => {
      dispatch(fetchAssetTemplates());
    },

    invalidateAssetTemplates: () => {
      dispatch(invalidateAssetTemplates());
    }
  }
}

const TemplatesPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(TemplatesView)

export default TemplatesPage;