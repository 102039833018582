import {
  FETCHING_VISITOR_SESSIONS, FETCHING_VISITOR_SESSIONS_SUCCEEDED, FETCHING_VISITOR_SESSIONS_FAILED,
  FETCH_VISITORS_BY_BROWSERS, FETCHING_VISITORS_BY_BROWSERS_SUCCEEDED, FETCHING_VISITORS_BY_BROWSERS_FAILED,
  FETCH_VISITORS_BY_DEVICES, FETCHING_VISITORS_BY_DEVICES_SUCCEEDED, FETCHING_VISITORS_BY_DEVICES_FAILED,
  FETCH_TOP_VISITORS, FETCHING_TOP_VISITORS_SUCCEEDED, FETCHING_TOP_VISITORS_FAILED,
  FETCH_VISITORS_BY_COUNTRIES, FETCHING_VISITORS_BY_COUNTRIES_SUCCEEDED, FETCHING_VISITORS_BY_COUNTRIES_FAILED,

  FETCH_VISITORS_BY_REFERERS, FETCHING_VISITORS_BY_REFERERS_SUCCEEDED, FETCHING_VISITORS_BY_REFERERS_FAILED,
  FETCH_VISITORS_BY_UTM_CAMPAIGN, FETCHING_VISITORS_BY_UTM_CAMPAIGN_SUCCEEDED, FETCHING_VISITORS_BY_UTM_CAMPAIGN_FAILED,
  FETCH_VISITORS_BY_UTM_SOURCE, FETCHING_VISITORS_BY_UTM_SOURCE_SUCCEEDED, FETCHING_VISITORS_BY_UTM_SOURCE_FAILED,
  FETCH_VISITORS_BY_UTM_MEDIUM, FETCHING_VISITORS_BY_UTM_MEDIUM_SUCCEEDED, FETCHING_VISITORS_BY_UTM_MEDIUM_FAILED,
  FETCH_VISITORS_BY_PAGES, FETCHING_VISITORS_BY_PAGES_SUCCEEDED, FETCHING_VISITORS_BY_PAGES_FAILED,

  FETCH_SESSIONS_TIME_SERIES, FETCHING_SESSIONS_TIME_SERIES_SUCCEEDED, FETCHING_SESSIONS_TIME_SERIES_FAILED,
  FETCH_ACTIVE_SESSIONS, FETCHING_ACTIVE_SESSIONS_SUCCEEDED, FETCHING_ACTIVE_SESSIONS_FAILED,

  FETCHING_VISITOR_ANALYTICS_DAILY, FETCHING_VISITOR_ANALYTICS_DAILY_SUCCEEDED, FETCHING_VISITOR_ANALYTICS_DAILY_FAILED,
  FETCHING_VISITOR_SESSIONS_DAILY, FETCHING_VISITOR_SESSIONS_DAILY_SUCCEEDED, FETCHING_VISITOR_SESSIONS_DAILY_FAILED,

  FETCHING_VISITOR_ASSETS, FETCHING_VISITOR_ASSETS_SUCCEEDED, FETCHING_VISITOR_ASSETS_FAILED,
  FETCHING_VISITOR, FETCHING_VISITOR_SUCCEEDED, FETCHING_VISITOR_FAILED,
  FETCHING_SESSIONS_FOR_VISITOR, FETCHING_SESSIONS_FOR_VISITOR_SUCCEEDED, FETCHING_SESSIONS_FOR_VISITOR_FAILED,
  SELECT_VISITOR_SESSION,
  FETCHING_ASSETS_FOR_SESSION, FETCHING_ASSETS_FOR_SESSION_SUCCEEDED, FETCHING_ASSETS_FOR_SESSION_FAILED,
  FETCHING_SESSION_ASSET_ANALYITCS, FETCHING_SESSION_ASSET_ANALYITCS_SUCCEEDED, FETCHING_SESSION_ASSET_ANALYITCS_FAILED,
  FETCHING_ASSET_PREVIEW, FETCHING_ASSET_PREVIEW_SUCCEEDED, FETCHING_ASSET_PREVIEW_FAILED,
  FETCHING_VISITOR_ANALYTICS, FETCHING_VISITOR_ANALYTICS_SUCCEEDED, FETCHING_VISITOR_ANALYTICS_FAILED,
  DOWNLOAD_VISITOR_REPORTS, DOWNLOAD_VISITOR_REPORTS_SUCCEEDED, DOWNLOAD_VISITOR_REPORTS_FAILED

} from 'constants/actionTypes';



const metricsReportState = {
  loading: false,
  invalidated: true,
  items: [],
  error: null,
  pagination: {
    current: -1,
    totalPages: 1
  }
}

const initialState = {
  dailyReports: {
    loading: false,
    invalidated: true,
    items: [],
    error: null
  },
  sessions: {
    loading: false,
    invalidated: true,
    items: [],
    error: null,
    pagination: {
      current: -1,
      totalPages: 1,
      totalRecords: 0
    }
  },
  top_visitors: metricsReportState,
  browsers: metricsReportState,
  devices: metricsReportState,
  countries: metricsReportState,
  referers: metricsReportState,
  utmCampaigns: metricsReportState,
  utmSources: metricsReportState,
  utmMedium: metricsReportState,
  pages: metricsReportState,
  session_series: {
    loading: false,
    items: [],
    error: null
  },
  active_sessions: {
    loading: false,
    items: [],
    error: null
  },
  visitor: {
    loading: false,
    item: null,
    sessions: {
      loading: false,
      invalidated: true,
      items: [],
      error: null,
      pagination: {
        current: -1,
        totalPages: 1
      }
    }
  },
  session: {
    item: null,
    assetSessions: {
      loading: false,
      items: [],
      error: null
    }
  },
  viewedAssets: {
    loading: false,
    items: {},
    pagination: {
      current: -1,
      totalPages: 1,
      totalRecords: 0
    }
  },
  cumulativeAnalytics: {
    loading: false,
    item: {}
  }
}

export function visitorReportReducer(state = initialState, action) {
  switch (action.type) {
    case FETCHING_VISITOR_ANALYTICS_DAILY:
      return {
        ...state,
        dailyReports: {
          ...state.dailyReports,
          loading: true
        }
      }

    case FETCHING_VISITOR_ANALYTICS_DAILY_SUCCEEDED:
      return {
        ...state,
        dailyReports: {
          ...state.dailyReports,
          loading: false,
          items: action.payload
        }
      }

    case FETCHING_VISITOR_ANALYTICS_DAILY_FAILED:
      return {
        ...state,
        dailyReports: {
          ...state.dailyReports,
          loading: false,
          error: action.payload
        }
      }

    case FETCHING_VISITOR_SESSIONS_DAILY:
      return {
        ...state,
        sessionsReports: {
          ...state.sessionsReports,
          loading: true
        }
      }

    case FETCHING_VISITOR_SESSIONS_DAILY_SUCCEEDED:
      return {
        ...state,
        sessionsReports: {
          ...state.sessionsReports,
          loading: false,
          items: action.payload
        }
      }

    case FETCHING_VISITOR_SESSIONS_DAILY_FAILED:
      return {
        ...state,
        sessionsReports: {
          ...state.sessionsReports,
          loading: false,
          error: action.payload
        }
      }

    case FETCHING_VISITOR_SESSIONS:
      return {
        ...state,
        sessions: {
          ...state.sessions,
          loading: true
        }
      }

    case FETCHING_VISITOR_SESSIONS_SUCCEEDED:
      var records = action.payload.records;

      return {
        ...state,
        sessions: {
          ...state.sessions,
          loading: false,
          invalidated: false,
          items: records,
          pagination: {
            ...state.sessions.pagination,
            current: action.payload.currentPage,
            totalPages: action.payload.totalPages,
            totalRecords: action.payload.totalRecordsCount
          }
        }
      }

    case FETCHING_VISITOR_SESSIONS_FAILED:
      return {
        ...state,
        sessions: {
          ...state.sessions,
          loading: false,
          error: action.payload.error
        }
      }

    case FETCH_TOP_VISITORS:
      var metricsState = state.top_visitors || metricsReportState;

      return {
        ...state,
        top_visitors: {
          ...metricsState,
          loading: true
        }
      }

    case FETCHING_TOP_VISITORS_SUCCEEDED:
      var records = action.payload;
      var metricsState = state.top_visitors || metricsReportState;

      return {
        ...state,
        top_visitors: {
          ...metricsState,
          loading: false,
          invalidated: false,
          items: records,
        }
      }

    case FETCHING_TOP_VISITORS_FAILED:
      var metricsState = state.top_visitors || metricsReportState;

      return {
        ...state,
        top_visitors: {
          ...state.metricsState,
          loading: false,
          error: action.payload.error
        }
      }

    case FETCH_VISITORS_BY_BROWSERS:
      var metricsState = state.browsers || metricsReportState;

      return {
        ...state,
        browsers: {
          ...metricsState,
          loading: true
        }
      }

    case FETCHING_VISITORS_BY_BROWSERS_SUCCEEDED:
      var records = action.payload.records;
      var metricsState = state.browsers || metricsReportState;

      return {
        ...state,
        browsers: {
          ...metricsState,
          loading: false,
          invalidated: false,
          items: records,
          pagination: {
            ...metricsState.pagination,
            current: action.payload.currentPage,
            totalPages: action.payload.totalPages,
            totalRecords: action.payload.totalRecordsCount
          }
        }
      }

    case FETCHING_VISITORS_BY_BROWSERS_FAILED:
      var metricsState = state.browsers || metricsReportState;

      return {
        ...state,
        browsers: {
          ...state.metricsState,
          loading: false,
          error: action.payload.error
        }
      }

    case FETCH_VISITORS_BY_DEVICES:
      var metricsState = state.devices || metricsReportState;

      return {
        ...state,
        devices: {
          ...metricsState,
          loading: true
        }
      }

    case FETCHING_VISITORS_BY_DEVICES_SUCCEEDED:
      var records = action.payload.records;
      var metricsState = state.devices || metricsReportState;

      return {
        ...state,
        devices: {
          ...metricsState,
          loading: false,
          invalidated: false,
          items: records,
          pagination: {
            ...metricsState.pagination,
            current: action.payload.currentPage,
            totalPages: action.payload.totalPages,
            totalRecords: action.payload.totalRecordsCount
          }
        }
      }

    case FETCHING_VISITORS_BY_DEVICES_FAILED:
      var metricsState = state.devices || metricsReportState;

      return {
        ...state,
        devices: {
          ...state.metricsState,
          loading: false,
          error: action.payload.error
        }
      }

    case FETCH_VISITORS_BY_COUNTRIES:
      var metricsState = state.countries || metricsReportState;

      return {
        ...state,
        countries: {
          ...metricsState,
          loading: true
        }
      }

    case FETCHING_VISITORS_BY_COUNTRIES_SUCCEEDED:
      var records = action.payload.records;
      var metricsState = state.countries || metricsReportState;

      return {
        ...state,
        countries: {
          ...metricsState,
          loading: false,
          invalidated: false,
          items: records,
          pagination: {
            ...metricsState.pagination,
            current: action.payload.currentPage,
            totalPages: action.payload.totalPages,
            totalRecords: action.payload.totalRecordsCount
          }
        }
      }

    case FETCHING_VISITORS_BY_COUNTRIES_FAILED:
      var metricsState = state.countries || metricsReportState;

      return {
        ...state,
        countries: {
          ...state.metricsState,
          loading: false,
          error: action.payload.error
        }
      }

    case FETCH_VISITORS_BY_REFERERS:
      var metricsState = state.referers || metricsReportState;

      return {
        ...state,
        referers: {
          ...metricsState,
          loading: true
        }
      }

    case FETCHING_VISITORS_BY_REFERERS_SUCCEEDED:
      var records = action.payload.records;
      var metricsState = state.referers || metricsReportState;

      return {
        ...state,
        referers: {
          ...metricsState,
          loading: false,
          invalidated: false,
          items: records,
          pagination: {
            ...metricsState.pagination,
            current: action.payload.currentPage,
            totalPages: action.payload.totalPages,
            totalRecords: action.payload.totalRecordsCount
          }
        }
      }

    case FETCHING_VISITORS_BY_REFERERS_FAILED:
      var metricsState = state.referers || metricsReportState;

      return {
        ...state,
        referers: {
          ...state.metricsState,
          loading: false,
          error: action.payload.error
        }
      }

    case FETCH_VISITORS_BY_UTM_CAMPAIGN:
      var metricsState = state.utmCampaigns || metricsReportState;

      return {
        ...state,
        utmCampaigns: {
          ...metricsState,
          loading: true
        }
      }

    case FETCHING_VISITORS_BY_UTM_CAMPAIGN_SUCCEEDED:
      var records = action.payload.records;
      var metricsState = state.utmCampaigns || metricsReportState;

      return {
        ...state,
        utmCampaigns: {
          ...metricsState,
          loading: false,
          invalidated: false,
          items: records,
          pagination: {
            ...metricsState.pagination,
            current: action.payload.currentPage,
            totalPages: action.payload.totalPages,
            totalRecords: action.payload.totalRecordsCount
          }
        }
      }

    case FETCHING_VISITORS_BY_UTM_CAMPAIGN_FAILED:
      var metricsState = state.utmCampaigns || metricsReportState;

      return {
        ...state,
        utmCampaigns: {
          ...state.metricsState,
          loading: false,
          error: action.payload.error
        }
      }

    case FETCH_VISITORS_BY_UTM_SOURCE:
      var metricsState = state.utmSources || metricsReportState;

      return {
        ...state,
        utmSources: {
          ...metricsState,
          loading: true
        }
      }

    case FETCHING_VISITORS_BY_UTM_SOURCE_SUCCEEDED:
      var records = action.payload.records;
      var metricsState = state.utmSources || metricsReportState;

      return {
        ...state,
        utmSources: {
          ...metricsState,
          loading: false,
          invalidated: false,
          items: records,
          pagination: {
            ...metricsState.pagination,
            current: action.payload.currentPage,
            totalPages: action.payload.totalPages,
            totalRecords: action.payload.totalRecordsCount
          }
        }
      }

    case FETCHING_VISITORS_BY_UTM_SOURCE_FAILED:
      var metricsState = state.utmSources || metricsReportState;

      return {
        ...state,
        utmSources: {
          ...state.metricsState,
          loading: false,
          error: action.payload.error
        }
      }

    case FETCH_VISITORS_BY_UTM_MEDIUM:
      var metricsState = state.utmMedium || metricsReportState;

      return {
        ...state,
        utmMedium: {
          ...metricsState,
          loading: true
        }
      }

    case FETCHING_VISITORS_BY_UTM_MEDIUM_SUCCEEDED:
      var records = action.payload.records;
      var metricsState = state.utmMedium || metricsReportState;

      return {
        ...state,
        utmMedium: {
          ...metricsState,
          loading: false,
          invalidated: false,
          items: records,
          pagination: {
            ...metricsState.pagination,
            current: action.payload.currentPage,
            totalPages: action.payload.totalPages,
            totalRecords: action.payload.totalRecordsCount
          }
        }
      }

    case FETCHING_VISITORS_BY_UTM_MEDIUM_FAILED:
      var metricsState = state.utmMedium || metricsReportState;

      return {
        ...state,
        utmMedium: {
          ...state.metricsState,
          loading: false,
          error: action.payload.error
        }
      }

    case FETCH_VISITORS_BY_PAGES:
      var metricsState = state.pages || metricsReportState;

      return {
        ...state,
        pages: {
          ...metricsState,
          loading: true
        }
      }

    case FETCHING_VISITORS_BY_PAGES_SUCCEEDED:
      var records = action.payload.records;
      var metricsState = state.pages || metricsReportState;

      return {
        ...state,
        pages: {
          ...metricsState,
          loading: false,
          invalidated: false,
          items: records,
          pagination: {
            ...metricsState.pagination,
            current: action.payload.currentPage,
            totalPages: action.payload.totalPages,
            totalRecords: action.payload.totalRecordsCount
          }
        }
      }

    case FETCHING_VISITORS_BY_PAGES_FAILED:
      var metricsState = state.pages || metricsReportState;

      return {
        ...state,
        pages: {
          ...state.metricsState,
          loading: false,
          error: action.payload.error
        }
      }


    case FETCH_SESSIONS_TIME_SERIES:
      return {
        ...state,
        session_series: {
          ...state.session_series,
          loading: true
        }
      }

    case FETCHING_SESSIONS_TIME_SERIES_SUCCEEDED:
      var records = action.payload;

      return {
        ...state,
        session_series: {
          ...state.session_series,
          loading: false,
          items: records,
          error: null
        }
      }

    case FETCHING_SESSIONS_TIME_SERIES_FAILED:
      return {
        ...state,
        session_series: {
          ...state.session_series,
          loading: false,
          error: action.payload.error
        }
      }

    case FETCH_ACTIVE_SESSIONS:
      return {
        ...state,
        active_sessions: {
          ...state.active_sessions,
          loading: true
        }
      }

    case FETCHING_ACTIVE_SESSIONS_SUCCEEDED:
      var records = action.payload;

      return {
        ...state,
        active_sessions: {
          ...state.active_sessions,
          loading: false,
          items: records,
          error: null
        }
      }

    case FETCHING_ACTIVE_SESSIONS_FAILED:
      return {
        ...state,
        active_sessions: {
          ...state.active_sessions,
          loading: false,
          error: action.payload.error
        }
      }

    case FETCHING_VISITOR_ASSETS:
      return {
        ...state,
        viewedAssets: {
          ...state.viewedAssets,
          loading: true
        }
      }

    case FETCHING_VISITOR_ASSETS_SUCCEEDED:
      records = action.payload.records;
      var index = action.payload.currentPage + 1;

      return {
        ...state,
        viewedAssets: {
          ...state.visitedAssets,
          loading: false,
          items: {
            ...state.viewedAssets.items,
            [index]: records
          },
          pagination: {
            ...state.sessions.pagination,
            current: action.payload.currentPage,
            totalPages: action.payload.totalPages,
            totalRecords: action.payload.totalRecordsCount
          }
        }
      }

    case FETCHING_VISITOR_ASSETS_FAILED:
      return {
        ...state,
        visitedAssets: {
          ...state.visitedAssets,
          loading: false,
          error: action.payload.error
        }
      }

    case FETCHING_VISITOR:
      return {
        ...state,
        visitor: {
          ...state.visitor,
          loading: true,
          sessions: {
            loading: false,
            invalidated: true,
            items: [],
            error: null,
            pagination: {
              current: -1,
              totalPages: 1
            }
          }
        }
      }

    case FETCHING_VISITOR_SUCCEEDED:
      var visitor = action.payload;
      return {
        ...state,
        visitor: {
          ...state.visitor,
          loading: false,
          item: visitor
        }
      }

    case FETCHING_VISITOR_FAILED:
      return {
        ...state,
        visitor: {
          ...state.visitor,
          loading: false,
          item: null,
          error: action.payload.error
        }
      }

    case FETCHING_SESSIONS_FOR_VISITOR:
      return {
        ...state,
        visitor: {
          ...state.visitor,
          sessions: {
            ...state.visitor.sessions,
            loading: true
          }
        }
      }

    case FETCHING_SESSIONS_FOR_VISITOR_SUCCEEDED:
      records = action.payload.records;

      var items = [];

      if (action.payload.currentPage !== 0) {
        items = [...state.visitor.sessions.items, ...records];
      } else {
        items = records;
      }

      items = items.reduce((x, y) => {
        return x.findIndex((e) => {
          // eslint-disable-next-line no-unused-expressions
          e.id === y.id
        }) < 0 ? [...x, y] : x
      }, []);

      return {
        ...state,
        visitor: {
          ...state.visitor,
          sessions: {
            ...state.visitor.sessions,
            loading: false,
            invalidated: false,
            items: items,
            pagination: {
              ...state.visitor.sessions.pagination,
              current: action.payload.currentPage,
              totalPages: action.payload.totalPages
            }
          }
        }
      }

    case FETCHING_SESSIONS_FOR_VISITOR_FAILED:
      return {
        ...state,
        visitor: {
          ...state.visitor,
          sessions: {
            ...state.visitor.sessions,
            loading: false,
            item: null,
            error: action.payload.error
          }
        }
      }

    case SELECT_VISITOR_SESSION:
      return {
        ...state,
        session: {
          ...state.session,
          item: action.payload
        }
      }

    case FETCHING_ASSETS_FOR_SESSION:
      var id = action.payload.id;

      if (!state.session.item || id !== state.session.item.id) {
        return state;
      }

      return {
        ...state,
        session: {
          ...state.session,
          assetSessions: {
            ...state.session.assetSessions,
            loading: true
          }
        }
      }

    case FETCHING_ASSETS_FOR_SESSION_SUCCEEDED:
      var id = action.payload.id;
      var assetSessions = action.payload.response || [];

      if (!state.session.item || id !== state.session.item.id) {
        return state;
      }

      assetSessions = assetSessions.map(item => {
        return {
          ...item,
          analytics: {
            fetch: true,
            loading: false,
            item: null
          },
          preview: {
            loading: false,
            item: null,
            fetch: true
          }
        }
      })

      return {
        ...state,
        session: {
          ...state.session,
          assetSessions: {
            ...state.session.assetSessions,
            loading: false,
            items: assetSessions
          }
        }
      }

    case FETCHING_ASSETS_FOR_SESSION_FAILED:
      var id = action.payload.id;

      if (id !== state.session.item.id) {
        return state;
      }
      return {
        ...state,
        session: {
          ...state.session,
          assetSessions: {
            ...state.session.assetSessions,
            loading: false,
            items: [],
            error: action.payload.error
          }
        }
      }

    case FETCHING_SESSION_ASSET_ANALYITCS:
      var assetSessionId = action.payload.assetSessionId;

      assetSessions = state.session.assetSessions.items.map(item => {
        if (item.id === assetSessionId) {
          return {
            ...item,
            analytics: {
              ...item.analyticss,
              fetch: false,
              loading: true
            }
          }
        }

        return item;
      })

      return {
        ...state,
        session: {
          ...state.session,
          assetSessions: {
            ...state.session.assetSessions,
            loading: false,
            items: assetSessions
          }
        }
      }

    case FETCHING_SESSION_ASSET_ANALYITCS_SUCCEEDED:
      assetSessionId = action.payload.assetSessionId;

      assetSessions = state.session.assetSessions.items.map(item => {
        if (item.id === assetSessionId) {
          return {
            ...item,
            analytics: {
              fetch: false,
              loading: false,
              item: action.payload.json
            }
          }
        }

        return item;
      })

      return {
        ...state,
        session: {
          ...state.session,
          assetSessions: {
            ...state.session.assetSessions,
            loading: false,
            items: assetSessions
          }
        }
      }

    case FETCHING_SESSION_ASSET_ANALYITCS_FAILED:
      assetSessionId = action.payload.assetSessionId;

      assetSessions = state.session.assetSessions.items.map(item => {
        if (item.id === assetSessionId) {
          return {
            ...item,
            analytics: {
              fetch: true,
              loading: false
            }
          }
        }

        return item;
      })

      return {
        ...state,
        session: {
          ...state.session,
          assetSessions: {
            ...state.session.assetSessions,
            loading: false,
            items: assetSessions
          }
        }
      }

    case FETCHING_ASSET_PREVIEW:
      var assetId = action.payload.id;

      assetSessions = state.session.assetSessions.items.map(item => {
        if (item.asset.id === assetId) {
          return {
            ...item,
            preview: {
              ...item.preview,
              loading: true,
              fetch: false
            }
          }
        }

        return item;
      })

      return {
        ...state,
        session: {
          ...state.session,
          assetSessions: {
            ...state.session.assetSessions,
            loading: false,
            items: assetSessions
          }
        }
      }

    case FETCHING_ASSET_PREVIEW_SUCCEEDED:
      assetId = action.payload.id;

      assetSessions = state.session.assetSessions.items.map(item => {
        if (item.asset.id === assetId) {
          return {
            ...item,
            preview: {
              ...item.preview,
              loading: false,
              item: action.payload.result
            }
          }
        }

        return item;
      })

      return {
        ...state,
        session: {
          ...state.session,
          assetSessions: {
            ...state.session.assetSessions,
            loading: false,
            items: assetSessions
          }
        }
      }

    case FETCHING_ASSET_PREVIEW_FAILED:
      assetId = action.payload.id;

      assetSessions = state.session.assetSessions.items.map(item => {
        if (item.asset.id === assetId) {
          return {
            ...item,
            preview: {
              ...item.preview,
              loading: false,
              item: null,
              error: action.payload.error
            }
          }
        }

        return item;
      })

      return {
        ...state,
        session: {
          ...state.session,
          assetSessions: {
            ...state.session.assetSessions,
            loading: false,
            items: assetSessions
          }
        }
      }

    case FETCHING_VISITOR_ANALYTICS:
      return {
        ...state,
        cumulativeAnalytics: {
          ...state.cumulativeAnalytics,
          loading: true
        }
      }

    case FETCHING_VISITOR_ANALYTICS_SUCCEEDED:
      return {
        ...state,
        cumulativeAnalytics: {
          ...state.cumulativeAnalytics,
          loading: false,
          item: action.payload
        }
      }

    case FETCHING_VISITOR_ANALYTICS_FAILED:
      return {
        ...state,
        cumulativeAnalytics: {
          ...state.cumulativeAnalytics,
          loading: false,
          item: {},
          error: action.payload.error
        }
      }

    case DOWNLOAD_VISITOR_REPORTS:
      var visitorReducer = action.payload.visitorReducer;
      return {
        ...state,
        [visitorReducer]: {
          ...state[visitorReducer],
          downloading: true,
          downloaded: false
        }
      }

    case DOWNLOAD_VISITOR_REPORTS_SUCCEEDED:
      var visitorReducer = action.payload.visitorReducer;
      return {
        ...state,
        [visitorReducer]: {
          ...state[visitorReducer],
          downloading: false,
          downloaded: true
        }
      }

    case DOWNLOAD_VISITOR_REPORTS_FAILED:
      var visitorReducer = action.payload.visitorReducer;
      return {
        ...state,
        [visitorReducer]: {
          ...state[visitorReducer],
          downloading: false,
          downloaded: false,
          error: action.payload
        }
      }

    default:
      return state;
  }
}