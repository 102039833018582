import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { updateProperties, updateWidget } from 'actions/widgetEditor'; 

import _ from 'lodash';
import cx from 'classnames';

import Flicking from "@egjs/react-flicking";
import { AutoPlay } from "@egjs/flicking-plugins";

import { WidgetRegistry } from '../registry.jsx';
import { QuickSettings, QuickSettingsProperty } from 'components/QuickSettings';
import { Widget, getStyles, getSpacingStyles, getBorderStyles, getBackgroundStyles, getDimensionStyles } from '../widget.jsx';

import { useWidgetDrop } from "services/dnd.service";

import styles from './carousel.module.scss';
import { Button, Icon } from 'semantic-ui-react';

export const Carousel = React.memo((props) => {
  const ref = React.useRef(null);
  const carouselRef = React.useRef(null);
  const dropRef = useWidgetDrop(props, ref, {});

  const [currentItem, setCurrentItem] = useState(0);

  const properties = props.properties;
  var widgetStyles = getStyles(properties);

  const plugins = [
    new AutoPlay({
      duration: properties.autoPlayDuration || 3000,
      direction: "NEXT",
      stopOnHover: true
    })
  ]

  return (
    <div className={cx(styles.carousel, props.className)}
      id={`widget_${props.id}`}
      ref={props.dragRef(dropRef(ref))}
      onMouseOver={props.onMouseOver}
      onMouseOut={props.onMouseOut}
      onClick={props.onClick}
      style={widgetStyles}
    >
      {properties.arrows &&
        <Navigation 
          color={properties.arrowsColor}
          current={currentItem} 
          total={(props.widgets || {}).length} 
          onChange={(index) => {
            carouselRef.current.moveTo(index);
          }}
        />
      }

      <Flicking
        ref={carouselRef}
        gap={0}
        hanger="0px"
        anchor="0px"
        autoResize={true}
        plugins={properties.autoPlay ? plugins : []}
        bound={true}
        adaptive={true}
        isEqualSize={false}
        isConstantSize={false}
        collectStatistics={false}
        onChange={(e) => {
          setCurrentItem(e.index);
          carouselRef.current.resize();
        }}
      >
        <Widget.Children widgets={props.widgets} parent={props.id} editor={props.editor} editable={props.editable} />
      </Flicking>

      {properties.dots &&
        <Pagination 
          color={properties.dotsColor}
          current={currentItem} 
          total={(props.widgets || {}).length} 
          onChange={(index) => {
            carouselRef.current.moveTo(index);
          }}
        />
      }
      {props.children}
    </div>
  );
});

export const CarouselItem = React.memo((props) => {
  const ref = React.useRef(null);

  const editorContext = useSelector(state => {
    return state.widgetsEditor.editors[props.editor];
  });

  const dropRef = useWidgetDrop(props, ref, {
    acceptTargets: (type) => {
      return true;
    },
    drop: (request) => {
      var dropTarget = props.id;
      var dropIndex = request.index;
      var anchor = request.anchor;

      var direction = "down";
      var identifedTarget = false;

      if (anchor) {
        if (!identifedTarget && (anchor.top || anchor.bottom)) {
          const parentWidget = editorContext.widgetsById[props.id];
          identifedTarget = _.get(parentWidget, 'capabilities.droppable', true);

          direction = anchor.bottom ? 'down' : 'up';
        }
      }

      if (!identifedTarget) {
        return null;
      }

      return {
        target: dropTarget,
        index: dropIndex,
        direction: direction
      }
    }
  });

  const dispatch = useDispatch();
  const properties = props.properties;
  var widgetStyles = getStyles(properties);

  if(properties.width){
    widgetStyles = {
      ...widgetStyles,
      width: properties.width
    }
  }

  return (
    <div className={cx(styles.item, props.className, {
        [styles.empty]: (props.widgets || []).length == 0
      })}
      id={`widget_${props.id}`}
      ref={props.dragRef(dropRef(ref))}
      onMouseOver={props.onMouseOver}
      onMouseOut={props.onMouseOut}
      onClick={props.onClick}
      style={widgetStyles}
    >
      <Widget.Children widgets={props.widgets} parent={props.id} editor={props.editor} editable={props.editable} />
      {props.children}
    </div>
  );
});

function Pagination({color, current, total, onChange}){
  if(total < 1){
    return false;
  }

  var dotColor = `rgba(${_.get(color, 'r') || 0}, ${_.get(color, 'g') || 0}, ${_.get(color, 'b') || 0}, ${_.get(color, 'a') || 1})`;

  return (
      <div className={styles.pagination}>
      {_.times(total).map(index => {
        return <div className={cx(styles.dot, {
          [styles.active]: current === index
        })} style={{
          backgroundColor: dotColor
        }} onClick={() => {
          onChange(index)
        }}>&nbsp;</div>
      })}
    </div>
  );
}

function Navigation({color, current, total, onChange}){
  if(total < 1){
    return false;
  }

  var arrowColor = `rgba(${_.get(color, 'r') || 0}, ${_.get(color, 'g') || 0}, ${_.get(color, 'b') || 0}, ${_.get(color, 'a') || 1})`;

  const buttonStyle = {
    backgroundColor: `${arrowColor}`,
    padding: 20
  }

  const handlePrevious = () => {
    if(current == 0){
      return;
    }

    onChange(current - 1);
  }

  const handleNext = () => {
    if(current >= total - 1){
      return;
    }

    onChange(current + 1);
  }

  return (
    <div className={styles.navigation}>
      <button className={cx(styles.arrow, styles.previous)} 
        style={buttonStyle} 
        disabled={current == 0}
        onClick={handlePrevious}
      />
      <button className={cx(styles.arrow, styles.next)} 
        style={buttonStyle} 
        disabled={current >= total - 1}
        onClick={handleNext}
      />
    </div>
  );
}


function CarouselQuickSettings(props) {
  const dispatch = useDispatch();

  const [selected, setSelected] = useState('basic');

  const menuItems = [
    { id: 'basic', title: 'Basic', icon: 'icon-custom' },
    { id: 'spacing', title: 'Spacing', icon: 'icon-spacing' },
    { id: 'background', title: 'Background', icon: 'icon-background' },
    { id: 'border', title: 'Border', icon: 'icon-border' },
    { id: 'advanced', title: 'Advanced', icon: 'icon-custom' }
  ]

  const handleMenuClick = (item) => {
    setSelected(item);
  }

  var widget = useSelector(state => {
    const editorContext = state.widgetsEditor.editors[props.editor];

    if (editorContext) {
      return editorContext.widgetsById[props.id];
    }

    return null;
  });
  if (!widget) {
    return false;
  }

  var properties = widget.properties;

  const handleChange = (changeRequest) => {
    dispatch(updateProperties({
      id: widget.id,
      change: changeRequest,
      context: props.editor
    }));
  }

  const handleBackgroundImageChange = (media, type) => {
    var changeRequest = { ...widget };
    _.set(changeRequest, 'properties.background.upload', {
      type: type,
      data: media
    });

    changeRequest = WidgetRegistry.processRequest([changeRequest])[0];
    dispatch(updateWidget(props.id, changeRequest, props.editor));
  }

  const handleCssChange = (css) => {
    var changeRequest = { ...widget };
    _.set(changeRequest, 'properties.css', css);

    changeRequest = WidgetRegistry.processRequest([changeRequest])[0];
    dispatch(updateWidget(props.id, changeRequest, props.editor));
  }

  return (
    <QuickSettings menuItems={menuItems} selected={selected} onMenuClick={handleMenuClick} >
      {selected === 'basic' && 
        <>
          <QuickSettingsProperty.Switch
            tooltip='Show dots pagination'
            title='Dots'
            value={properties.dots}
            onChange={() => {
              handleChange({
                dots: !properties.dots
              })
            }}
          />

          {properties.dots &&
            <QuickSettingsProperty.Color 
              title='Dots Color' 
              value={properties.dotsColor} 
              onChange={(value) => {
                handleChange({
                  dotsColor: value
                })
              }} 
            />
          }

          <QuickSettingsProperty.Switch
            tooltip='Show arrows for navigation'
            title='Arrows'
            value={properties.arrows}
            onChange={() => {
              handleChange({
                arrows: !properties.arrows
              })
            }}
          />

          {properties.arrows &&
            <QuickSettingsProperty.Color 
              title='Arrow Color' 
              value={properties.arrowsColor} 
              onChange={(value) => {
                handleChange({
                  arrowsColor: value
                })
              }} 
            />
          }
          
        <QuickSettingsProperty.Switch
            tooltip='Enable Autoplay'
            title='Autoplay'
            value={properties.autoPlay}
            onChange={() => {
              handleChange({
                autoPlay: !properties.autoPlay
              })
            }}
          />
          {properties.autoPlay &&
            <QuickSettingsProperty.NumericInput 
              title='Duration' 
              value={_.get(properties, 'autoPlayDuration') || '3000'} 
              onChange={(value) => {
                handleChange({
                  autoPlayDuration: value
                })
              }} 
            />
          }
        </>
      }

      {selected === 'spacing' &&
        <>
          <QuickSettingsProperty.Spacing
            title='Margin'
            value={properties.margin}
            onChange={(margin) => {
              handleChange({
                margin: margin
              });
            }}
          />
          <QuickSettingsProperty.Spacing
            title='Padding'
            value={properties.padding}
            onChange={(padding) => {
              handleChange({
                padding: padding
              });
            }}
          />
        </>
      }

      {selected === "background" &&
        <QuickSettingsProperty.Background expanded
          value={properties.background}
          onChange={(background) => {
            handleChange({
              background: background
            });
          }}
          onImageChange={(image, type) => {
            handleBackgroundImageChange(image, type);
          }}
        />
      }

      {selected === 'border' &&
        <QuickSettingsProperty.Border expanded value={properties.border} onChange={(border) => {
          handleChange({
            border: border
          });
        }}
        />
      }

      {selected === 'advanced' &&
        <QuickSettingsProperty.CSS title='Stylesheets' value={properties.css} onChange={handleCssChange} />
      }
    </QuickSettings>
  )
}

Carousel.QuickSettings = CarouselQuickSettings;

function CarouselItemQuickSettings(props) {
  const dispatch = useDispatch();

  const [selected, setSelected] = useState('basic');

  const menuItems = [
    { id: 'basic', title: 'Basic', icon: 'icon-custom' },
    { id: 'spacing', title: 'Spacing', icon: 'icon-spacing' },
    { id: 'background', title: 'Background', icon: 'icon-background' },
    { id: 'border', title: 'Border', icon: 'icon-border' },
    { id: 'advanced', title: 'Advanced', icon: 'icon-custom' }
  ]

  const handleMenuClick = (item) => {
    setSelected(item);
  }

  var widget = useSelector(state => {
    const editorContext = state.widgetsEditor.editors[props.editor];

    if (editorContext) {
      return editorContext.widgetsById[props.id];
    }

    return null;
  });
  if (!widget) {
    return false;
  }

  var properties = widget.properties;

  const handleChange = (changeRequest) => {
    dispatch(updateProperties({
      id: widget.id,
      change: changeRequest,
      context: props.editor
    }));
  }

  const handleBackgroundImageChange = (media, type) => {
    var changeRequest = { ...widget };
    _.set(changeRequest, 'properties.background.upload', {
      type: type,
      data: media
    });

    changeRequest = WidgetRegistry.processRequest([changeRequest])[0];
    dispatch(updateWidget(props.id, changeRequest, props.editor));
  }

  const handleCssChange = (css) => {
    var changeRequest = { ...widget };
    _.set(changeRequest, 'properties.css', css);

    changeRequest = WidgetRegistry.processRequest([changeRequest])[0];
    dispatch(updateWidget(props.id, changeRequest, props.editor));
  }

  return (
    <QuickSettings menuItems={menuItems} selected={selected} onMenuClick={handleMenuClick} >
      {selected === 'basic' && 
        <QuickSettingsProperty.Input
          title={'Width'}
          value={properties.width}
          onChange={(w) => { 
            handleChange({
              width: w
            }) 
          }}
        />
      }

      {selected === 'spacing' &&
        <>
          <QuickSettingsProperty.Spacing
            title='Margin'
            value={properties.margin}
            onChange={(margin) => {
              handleChange({
                margin: margin
              });
            }}
          />
          <QuickSettingsProperty.Spacing
            title='Padding'
            value={properties.padding}
            onChange={(padding) => {
              handleChange({
                padding: padding
              });
            }}
          />
        </>
      }

      {selected === "background" &&
        <QuickSettingsProperty.Background expanded
          value={properties.background}
          onChange={(background) => {
            handleChange({
              background: background
            });
          }}
          onImageChange={(image, type) => {
            handleBackgroundImageChange(image, type);
          }}
        />
      }

      {selected === 'border' &&
        <QuickSettingsProperty.Border expanded value={properties.border} onChange={(border) => {
          handleChange({
            border: border
          });
        }}
        />
      }

      {selected === 'advanced' &&
        <QuickSettingsProperty.CSS title='Stylesheets' value={properties.css} onChange={handleCssChange} />
      }
    </QuickSettings>
  )
}

CarouselItem.QuickSettings = CarouselItemQuickSettings;