import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, NavLink, useRouteMatch, useHistory } from "react-router-dom";

import { WidgetRegistry } from 'components/Widgets/registry.jsx';
import { WidgetsEditor } from "pages/pages/editor/editor";
import SidePane from 'components/SidePane';
import { PasswordModal } from 'components/Widgets/custom/password/passwordModal';
import { useWidgetSelecion } from 'components/Widgets/widget';

import { Property } from 'components/Properties';
import FloatingPane from 'components/FloatingPane';

import cx from 'classnames';
import _ from 'lodash';
import { Helmet } from "react-helmet";
import dateformat from 'dateformat';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import { PASSWORD_PROTECTION } from 'constants/ItemTypes';

import {
  Header,
  Menu,
  Segment,
  Image,
  Icon,
  Button,
  Popup,
  Label
} from 'semantic-ui-react';

import styles from './assetViewer.module.scss';

// import { PropertyPane, PropertiesPane } from './properties';

import { fetchAssetViewer, updateAssetViewer, publishAssetViewer, restoreAssetViewer, deleteAssetViewer } from 'actions/asset_viewer';
import { fetchAssetTemplates } from 'actions/templates';
import { fetchIntegrations } from 'actions/integrations/integrations';
import {
  createEditorContext, updateEditorContext, createWidgetFromPalette
} from 'actions/widgetEditor';

import { denormalise } from 'services/widget.helper';
import { getBaseUrl, guid } from 'services/util';
import assetViewersSaga from 'actions/asset_viewer';

export default function AssetViewerEditor() {
  const dispatch = useDispatch();
  const history = useHistory();
  let { url } = useRouteMatch();

  const { id } = useParams();

  const [editor, setEditor] = useState(null);
  const [editable, setEditable] = useState(true);

  const selectedWidget = useWidgetSelecion(editor);

  const viewer = useSelector(state => _.get(state, 'assetViewers.viewer.item', {}));
  const loading = useSelector(state => _.get(state, 'assetViewers.viewer.loading', false));
  const updating = useSelector(state => _.get(state, 'assetViewers.viewer.updating', false));

  var widgetsById = useSelector(state => {
    var widgetsById = {};

    if (!editor) {
      return widgetsById;
    }

    var editorContext = state.widgetsEditor.editors[editor];

    if (editorContext) {
      widgetsById = editorContext.widgetsById;
    }

    return widgetsById;
  })

  var widgets = useSelector(state => {
    var widgets = [];

    if (!editor) {
      return widgets;
    }

    var editorContext = state.widgetsEditor.editors[editor];

    if (editorContext) {
      widgets = editorContext.widgets;
    }

    return widgets;
  })

  useEffect(() => {
    dispatch(fetchAssetViewer(id));
    dispatch(fetchAssetTemplates());
    dispatch(fetchIntegrations());

    var editor = guid();
    setEditor(editor);
  }, []);

  useEffect(() => {
    if (viewer && viewer.draft && viewer.draft.widgets && editor) {
      dispatch(createEditorContext(editor, viewer.draft.widgets));
    }
  }, [viewer && viewer.id, editor]);

  var pageWidgets = widgets.filter(item => {
    var widget = widgetsById[item];
    return widget.type !== PASSWORD_PROTECTION;
  })

  const _onItemDrop = (editor, request, parent, index, insertBefore) => {
    const entityReference = {
      id: id,
      type: 'asset_viewer'
    };

    dispatch(createWidgetFromPalette(editor, entityReference, request[0], parent, index, insertBefore));
  }

  const _handleUpdateViewer = () => {
    var widgetsRequest = denormalise(widgets, widgetsById);
    widgetsRequest = WidgetRegistry.processRequest(widgetsRequest);

    var properties = _.get(viewer, 'draft.properties', {});

    var request = {
      widgets: widgetsRequest,
      properties: properties
    }

    var name = viewer.name;
    if (name && name.trim() != '') {
      request = {
        ...request,
        name: name
      }
    }

    dispatch(updateAssetViewer(viewer.id, request));
  }

  if (loading || !viewer) {
    return false;
  }

  const stylesheets = _.get(viewer, 'draft.properties.css', '');
  const script = _.get(viewer, 'draft.properties.script', '');

  return (
    <div className={styles.content}>
      {stylesheets &&
        <Helmet>
          <style type="text/css">{stylesheets}</style>
          <script type="text/javascript">{script}</script>
        </Helmet>
      }

      <ViewerHeader
        name={viewer.name}
        widgets={widgets}
        editor={editor}
        widgetsById={widgetsById}
        // preview={_handlePreviewClicked}
        // delete={_handleDeleteClicked}
        onCreateWidget={_onItemDrop}
      />

      <Segment basic className={styles.widgetsEditor}>
        <div className={styles.editor}>
          <WidgetsEditor
            id='widget_viewer'
            editor={editor}
            entityReference={{
              id: viewer.id,
              type: 'asset_viewer'
            }}
            widgets={pageWidgets}
            editable={editable}
            onCreateWidget={_onItemDrop}
          />

          {/* <FloatingPane
            width={260}
            height='80vh'
            position={{ top: 85, right: 20 }}
            className={cx(styles.pageProperties, { [styles.hide]: (!editable || selectedWidget != null) })}
          >
            <PropertiesPane editor={editor} />
          </FloatingPane> */}
        </div>
      </Segment>
    </div>
  )
}

function ViewerHeader(props) {
  const dispatch = useDispatch();

  const baseUrl = useSelector(state => _.get(state, 'storyboards.storyboard.item.baseUrl'));
  const viewer = useSelector(state => _.get(state, 'assetViewers.viewer.item', {}));
  const updating = useSelector(state => _.get(state, 'assetViewers.viewer.updating', false));
  const publishing = useSelector(state => _.get(state, 'assetViewers.viewer.publishing', false));
  const deleting = useSelector(state => _.get(state, 'assetViewers.viewer.deleting', false));

  const [saveSuccess, setSaveSuccess] = useState(false);
  const [publishSuccess, setPublishSuccess] = useState(false);
  const [passwordWidget, setPasswordWidget] = useState(false);

  const disableActions = updating || publishing || deleting;

  let { path, url } = useRouteMatch();
  let appUrl = getBaseUrl(url);

  const [showEdit, setShowEdit] = useState(false);

  const toggleShowEdit = () => {
    setShowEdit(!showEdit);
  }

  const _handleUpdatePage = () => {
    var widgetsRequest = denormalise(props.widgets, props.widgetsById);
    widgetsRequest = WidgetRegistry.processRequest(widgetsRequest);

    var properties = _.get(viewer, 'draft.properties', {});

    dispatch(updateAssetViewer(viewer.id, {
      widgets: widgetsRequest,
      properties: properties
    }, (res) => {
      setTimeout(() => {
        setSaveSuccess(true);
      }, 1000);
    }));
  }

  const _handlePublishPage = (saveSuccess) => {
    dispatch(publishAssetViewer(viewer.id, (res) => {
      setSaveSuccess(false);
      setPublishSuccess(true);
    }));
  }

  const _handleClosePasswordWidget = () => {
    setPasswordWidget(null);
  }

  return (
    <>
      <Menu fluid className={styles.pageHeader}>
        <Menu.Item as={NavLink} exact name='Pages' to={`${appUrl}/pages`} icon onClick={props.preview}><Icon name='arrow left' /></Menu.Item>

        <PageTitleWidget editor={props.editor} onWidgetClick={setPasswordWidget} />

        <Menu.Item icon onClick={toggleShowEdit} className={styles.pageSettings}><i aria-hidden="true" className="icon-page-settings icon"></i></Menu.Item>

        <Menu.Menu position='right'>
          <DeleteConfirmPopup
            trigger={
              <Menu.Item disabled={disableActions}>
                <Icon name='trash' /> Delete
              </Menu.Item>
            }
            onDelete={props.delete}
          />

          <Menu.Item as='a' href={`${appUrl}/pages/${viewer.id}/preview`} target='_blank' disabled={disableActions}>
            <Icon name='eye' />
            Preview
          </Menu.Item>

          <SaveSuccessPopup
            trigger={
              <Menu.Item onClick={_handleUpdatePage} disabled={disableActions}>
                <Icon name={updating ? 'spinner' : 'save'} loading={updating} />
                {updating ? 'Saving...' : 'Save'}
              </Menu.Item>
            }
            open={saveSuccess}
            onVisibilityChange={() => { setSaveSuccess(false) }}
            onPublish={() => {
              _handlePublishPage();
              setSaveSuccess(false);
            }}
          />

          <PublishSuccessPopup
            open={publishSuccess}
            onVisibilityChange={() => setPublishSuccess(false)}
            viewUrl={null}
            trigger={
              <Menu.Item className={styles.publish}>
                <Button basic primary onClick={_handlePublishPage.bind(this, false)} disabled={disableActions}>
                  {publishing && <Icon name='spinner' loading={publishing} />}
                  {publishing ? 'Publishing...' : 'Publish'}
                </Button>
              </Menu.Item>
            }
          />

          {viewer.published &&
            <Menu.Item className={styles.restoreMenuItem}>
              <Header as='h4' className={styles.lastPublised}>
                <Header.Content>
                  Last Published
                  <Header.Subheader>
                    on {dateformat(viewer.lastPublised, 'dd mmm yyyy')}
                  </Header.Subheader>
                </Header.Content>
              </Header>
              <Menu secondary className={styles.restoreMenu}>
                {viewer.changes &&
                  <RestoreConfirmPopup
                    trigger={
                      <Menu.Item onClick={() => { }}>
                        RESTORE
                    </Menu.Item>
                    }
                    viewer={viewer}
                    editor={props.editor}
                  />
                }
              </Menu>
            </Menu.Item>
          }
        </Menu.Menu>
      </Menu>
      <SidePane right
        header='Update Page'
        close
        background='#ffffff'
        open={showEdit}
        className={styles.pageEditSlider}
        onClose={() => {
          setShowEdit(false);
        }}>
        {/* <EditPageView page={page} open={showEdit} onCancel={() => { setShowEdit(false) }} /> */}
      </SidePane>

      <PasswordModal id={passwordWidget}
        editor={props.editor}
        open={passwordWidget !== null}
        onClose={_handleClosePasswordWidget}
        onCreateWidget={props.onCreateWidget} />
    </>
  );
}

function PageTitleWidget(props) {
  const [open, setOpen] = useState(false);

  const viewer = useSelector(state => _.get(state, 'assetViewers.viewer.item', {}));

  const hiddenWidgets = useSelector(state => {
    if (!props.editor) {
      return [];
    }

    var widgets = _.get(state, `widgetsEditor.editors.${props.editor}.widgets`) || [];

    return widgets.map(widget => {
      return _.get(state, `widgetsEditor.editors.${props.editor}.widgetsById.${widget}`);
    }).filter(widget => widget != null && widget.type === PASSWORD_PROTECTION);
  });

  console.log(hiddenWidgets);

  const statusLabel = () => {
    if (viewer && !viewer.changes && viewer.published) {
      return 'Published';
    }

    return 'Draft';
  }

  const handlePopupClick = (id) => {
    props.onWidgetClick(id);
    setOpen(false);
  }

  return (
    <Popup
      basic
      className={styles.hiddenWidgetsPopup}
      disabled={hiddenWidgets.length === 0}
      on='click'
      onOpen={() => { setOpen(true) }}
      onClose={() => setOpen(false)}
      open={open}
      position='bottom left'
      trigger={
        <Menu.Item className={styles.pageName}>
          <Header as='h3'>
            <Header.Content>
              {viewer.name}
              <Header.Subheader>
                <Label>{statusLabel()}</Label>
                Last Saved on {dateformat(viewer.modifiedDate, 'dd mmm yyyy')}
              </Header.Subheader>
            </Header.Content>
            {hiddenWidgets.length > 0 && <Icon name="caret down" />}
          </Header>
        </Menu.Item>
      }
    >
      <Segment basic className={styles.hiddenWidgetsContent}>
        <Header as="h2" className={styles.popupLabels} onClick={() => setOpen(false)}>
          {viewer.name}
        </Header>

        <Header as="h4" className={styles.popupBoxHeader}>
          Pop Up
        </Header>

        {hiddenWidgets.map(widget => {
          return (
            <Header as="h2" className={styles.popupLabels} onClick={handlePopupClick.bind(this, widget.id)}>
              {widget.name}
            </Header>
          )
        })}
      </Segment>
    </Popup>
  );
}

function SaveSuccessPopup({ trigger, open, onVisibilityChange, onPublish, publishing }) {
  return (
    <PopupMessage
      open={open}
      onVisibilityChange={onVisibilityChange}
      className={styles.save}
      trigger={trigger}
    >
      <div className={styles.content}>
        <div className={styles.details}>
          <h3 className={styles.title}>All your changes are now saved</h3>
          <p className={styles.subtext}>You can now preview your site or publish to see the changes on your live site</p>

          <Image src="/images/pages/save-dog.svg" />
          <Menu secondary>
            <Menu.Item onClick={onPublish.bind(this)}>
              Publish
            </Menu.Item>
            <Menu.Item onClick={() => onVisibilityChange(false)}>
              Done
            </Menu.Item>
          </Menu>
        </div>
      </div>
    </PopupMessage>
  )
}

function PublishSuccessPopup({ viewUrl, trigger, open, onVisibilityChange }) {
  const [copied, setCopied] = useState(false);

  return (
    <PopupMessage
      open={open}
      onVisibilityChange={onVisibilityChange}
      className={styles.publish}
      trigger={trigger}
    >
      <div className={styles.content}>
        <div className={styles.details}>
          <h3 className={styles.title}>Yayyy! Your page is live and kicking</h3>
          <p className={styles.subtext}>We have successfully published your site.</p>
          
          <Menu secondary>
            <Menu.Item as='a' icon href={viewUrl} target='_blank'>
              View site
            </Menu.Item>
            <Menu.Item onClick={() => onVisibilityChange(false)}>
              Continue Editing
            </Menu.Item>
          </Menu>
        </div>
        <Image className={styles.icon} src="/images/pages/publish-dog.svg" />
      </div>
    </PopupMessage>
  )
}

function RestoreConfirmPopup({ trigger, page, editor }) {
  const dispatch = useDispatch();

  const [open, setOpen] = useState(false);

  const restoring = useSelector(state => _.get(state, 'assetViewer.restoring', false));

  const handleRestore = () => {
    dispatch(restoreAssetViewer(page.id, (res) => {
      dispatch(updateEditorContext(editor, res.draft.widgets))
      handleVisibilityChange(false);
    }));
  }

  const handleVisibilityChange = (visibile) => {
    setOpen(visibile);
  }

  return (
    <PopupMessage
      open={open || restoring}
      onVisibilityChange={handleVisibilityChange}
      className={styles.restore}
      trigger={trigger}>
      <div className={styles.content}>
        <div className={styles.details}>
          <h3 className={styles.title}>Restore last published version</h3>
          <p className={styles.subtext}>If you choose to continue, All edits to this draft page will be discarded and cannot be undone</p>
          <Menu secondary>
            <Menu.Item onClick={() => { handleVisibilityChange(false) }} disabled={restoring}>
              Cancel
              </Menu.Item>
            <Menu.Item onClick={handleRestore} disabled={restoring}>
              {restoring ? 'Restoring...' : 'Discard changes and restore'}
            </Menu.Item>
          </Menu>
        </div>
        <Image className={styles.icon} src="/images/pages/restore-dog.svg" />
      </div>
    </PopupMessage>
  )
}

function DeleteConfirmPopup({ trigger, onDelete }) {
  const [open, setOpen] = useState(false);
  const deleting = useSelector(state => _.get(state, 'assetViewer.deleting', false));

  const handleDelete = () => {
    onDelete();
  }

  const handleVisibilityChange = (visibile) => {
    setOpen(visibile);
  }

  return (
    <PopupMessage
      open={open || deleting}
      onVisibilityChange={handleVisibilityChange}
      className={styles.restore}
      trigger={trigger}>
      <div className={styles.content}>
        <div className={styles.details}>
          <h3 className={styles.title}>Delete the page</h3>
          <p className={styles.subtext}>If you choose to continue, the page will be deleted and cannot be undone</p>
          <Menu secondary>
            <Menu.Item onClick={() => { handleVisibilityChange(false) }} disabled={deleting}>
              Cancel
              </Menu.Item>
            <Menu.Item onClick={handleDelete} disabled={deleting}>
              {deleting ? 'Deleting...' : 'Confirm'}
            </Menu.Item>
          </Menu>
        </div>
        <Image className={styles.icon} src="/images/pages/restore-dog.svg" />
      </div>
    </PopupMessage>
  )
}

function PopupMessage({ className, open, trigger, children, onVisibilityChange }) {
  return (
    <Popup
      className={cx(styles.popup, className)}
      popperModifiers={{
        preventOverflow: {
          boundariesElement: "window"
        }
      }}
      open={open}
      on='click'
      onClose={() => onVisibilityChange(false)}
      onOpen={() => onVisibilityChange(true)}
      flowing
      pinned
      hoverable
      position='bottom right'
      trigger={trigger}
    >
      <div className={styles.closeButton} onClick={() => onVisibilityChange(false)}>
        <i aria-hidden="true" className="icon-close_1 icon">
          <span className="path1"></span><span className="path2"></span><span className="path3"></span>
        </i>
      </div>
      {children}
    </Popup>
  )
}