import React, { useState } from 'react';

import cx from 'classnames';
import _ from 'lodash';

import {
  Menu,
  Segment,
  Button,
  Accordion,
  Icon,
  Dropdown,
  Radio,
  Input,
  Popup,
  Image,
  Modal
} from 'semantic-ui-react';

import styles from './quickSettings.module.scss';

import ImageTools from 'services/imagetools.service';
import BrowserService from 'services/browser.service';
import { SketchPicker } from 'react-color';
import Tooltip from 'components/Tooltip';

import AceEditor from "react-ace";
import "ace-builds/src-noconflict/mode-css";
import "ace-builds/src-noconflict/theme-tomorrow";
import "ace-builds/src-min-noconflict/ext-searchbox";
import "ace-builds/src-min-noconflict/ext-language_tools";


export function QuickSettings({ menuItems, selected, onMenuClick, children, ...rest }) {

  return (
    <Segment basic className={styles.quickSettings}>
      <QuickSettingsMenu>
        {menuItems.map(item => {
          return <QuickSettingsMenuItem key={item.id}  {...item} onClick={onMenuClick} selected={selected} />
        })}
      </QuickSettingsMenu>
      <Segment basic className={cx(styles.quickSettingsProperties, rest.segmentClassName)}>
        {children}
      </Segment>
    </Segment>
  )
}

function QuickSettingsMenu({ children, ...rest }) {

  return (
    <Menu secondary vertical className={styles.quickSettingsMenu}>
      {children}
    </Menu>
  )
}


export function QuickSettingsMenuItem({ id, icon, title, onClick, selected, ...rest }) {

  return (
    <Tooltip content={title}
      className={styles.tooltip}
      position='right center'
      hoverable
      inverted
      size='tiny'
      popperModifiers={{
        preventOverflow: {
          boundariesElement: "offsetParent"
        }
      }}>
      <Menu.Item
        className={cx(styles.menu, { [styles.selected]: selected === id })}
        onClick={() => {
          onClick(id);
        }} >
        <Button icon fluid>
          <i aria-hidden="true" className={`${icon} icon`}></i>
        </Button>
      </Menu.Item>
    </Tooltip>
  )
}

QuickSettings.MenuItem = QuickSettingsMenuItem;


/**
 * Property 
 */
export function QuickSettingsProperty({ title, tooltip, className, children }) {
  const label = tooltip ? (
    <Tooltip
      content={tooltip}
      className={styles.tooltip}
      inverted
      popperModifiers={{
        preventOverflow: {
          boundariesElement: "offsetParent"
        }
      }}
    >
      <span>{title}</span>
    </Tooltip>
  ) : title;
  return (
    <table>
      <tbody>
        <tr className={cx(styles.property, className)}>
          {label &&
            <td className={styles.label}><label>{label}</label></td>
          }
          <td align='right' className={styles.content}>{children}</td>
        </tr>
      </tbody>
    </table>
  );
}

/**
 * Property Section
 */
function PropertySection({ title, expanded, className, children }) {
  const [expandedState, setExpanded] = useState(expanded);

  const handleClick = () => {
    setExpanded(!expandedState);
  }

  return (
    <Accordion fluid styled className={cx(styles.section, className)}>
      <Accordion.Title active={expandedState} className={styles.sectionTitle} onClick={handleClick}>
        <Icon name='dropdown' />
        {title}
      </Accordion.Title>
      <Accordion.Content active={expandedState}>
        <table>
          <tbody>
            {children}
          </tbody>
        </table>
      </Accordion.Content>
    </Accordion>
  );
}

QuickSettingsProperty.Section = PropertySection;

/**
 * Property Dropdown
 */
function PropertyDropdown({ title, tooltip, options, children, ...rest }) {
  return (
    <QuickSettingsProperty title={title} tooltip={tooltip}>
      <Dropdown className={styles.dropdown} options={options} {...rest}>
        {children}
      </Dropdown>
    </QuickSettingsProperty>
  );
}

QuickSettingsProperty.Dropdown = PropertyDropdown;

/**
 * Property Switch
 */
function PropertySwitch({ title, tooltip, value, onChange, ...rest }) {
  const handleChange = (e) => {
    if (onChange) {
      onChange(!value);
    }
  }

  return (
    <QuickSettingsProperty title={title} tooltip={tooltip}>
      <Radio className={styles.switch} toggle checked={value} onChange={handleChange} />
    </QuickSettingsProperty>
  );
}

QuickSettingsProperty.Switch = PropertySwitch;


/**
 * Property Input
 */
function PropertyInput({ title, tooltip, value, onChange, ...rest }) {
  return (
    <QuickSettingsProperty title={title} tooltip={tooltip}>
      <Input className={styles.input} value={value}
        onChange={(e) => {
          if (onChange) {
            onChange(e.target.value);
          }
        }} />
    </QuickSettingsProperty>
  );
}

QuickSettingsProperty.Input = PropertyInput;
const preventMinus = (e) => {
    if (e.key == "-") {
        e.preventDefault();
    }
};
/**
 * Property Number Input
 */
function PropertyNumberInput({ title, tooltip, value, onChange, min, max, ...rest }) {
  const regexp = new RegExp(`^-?[0-9]*$`);
  const [internalValue, setInternalValue] = useState(value);

  const handleChange = (e) => {
    const newValue = e.target.value;
    if (regexp.test(newValue)) {
      setInternalValue(newValue);

      if(newValue < min || newValue > max) {
        return;
      }
      if (onChange) {
        onChange(newValue);
        return;
      }
    }
  }

  const handleBlur = (e) => {
    if (internalValue < min) {
      setInternalValue(min);
    } else if (internalValue > max) {
      setInternalValue(max);
    } else {
      setInternalValue(value);
    }
  }

  return (
    <QuickSettingsProperty title={title} tooltip={tooltip}>
      <Input className={styles.input}
        value={internalValue}
        onChange={handleChange}
        onBlur={handleBlur}
        onKeyPress={preventMinus}
      />
    </QuickSettingsProperty>
  );
}

QuickSettingsProperty.NumericInput = PropertyNumberInput;

/**
 * Background Color
 */
function PropertyColor({ title, value, presetColors, onChange }) {
  value = value || 'transparent';
  const pickerColor = value === 'transparent' ? { r: 255, g: 255, b: 255, a: 1 } : value;

  var colorConfig = {};
  if (presetColors) {
    colorConfig['presetColors'] = presetColors;
  }

  const handleColorChange = (color) => {
    onChange(color.rgb);
  }

  return (
    <QuickSettingsProperty title={title} className={styles.color}>
      <Button.Group>
        <Popup
          className={styles.colorPopup}
          on='click'
          hoverable
          position='bottom right'
          trigger={
            <Button className={cx(styles.picker, {
              [styles.none]: value === 'transparent'
            })}
              style={{
                background: `rgba(${pickerColor.r}, ${pickerColor.g}, ${pickerColor.b}, ${pickerColor.a})`,
              }}
            >
              {value === 'transparent' &&
                <svg width='100%' height='100%'>
                  <rect width="100%" height="100%" style={{
                    fill: 'transparent',
                    stroke: 'red',
                    strokeWidth: 2
                  }} />
                  <line x1={0} y1={0} x2='100%' y2='100%' style={{
                    stroke: 'red',
                    strokeWidth: 1
                  }} />
                </svg>
              }
            </Button>
          }
          content={
            <SketchPicker
              {...colorConfig}
              color={pickerColor}
              onChangeComplete={handleColorChange} />
          }
        />
        {value !== 'transparent' &&
          <Button icon className={styles.reset} onClick={() => {
            onChange(null);
          }}>
            <Icon name='close' size='small' />
          </Button>
        }
      </Button.Group>
    </QuickSettingsProperty>
  );
}

QuickSettingsProperty.Color = PropertyColor;


/**
 * Background Property
 */
function PropertyImage({ title, className, value, onChange, onRemove, filter, accept, ...rest }) {
  const uploadIconRef = React.createRef();
  const imageData = _.get(value, 'data');
  const imageUrl = _.get(value, 'full');
  const image = imageData ? imageData : imageUrl;

  const type = _.get(value, 'type');
  const isSafari = BrowserService.getCurrentBrowser() === 'safari';
  const updating = rest.updating;

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (onChange) {
      onChange(file)
    }
  }

  const handleRemove = () => {
    if (onRemove) {
      onRemove();
    }
  }

  return (
    <div className={styles.property}>
      <div className={cx(styles.image, className)}>
        <div className={styles.label}><label>{title}</label></div>
        <div className={cx(styles.media, {
          [styles.updating]: updating
        })}>
          <div className={cx(styles.overlay, styles[filter])}></div>
          <div className={styles.progress}>Updating...</div>

          {image && type === 'image' &&
            <Image src={image} />
          }

          {image && type === 'video' &&
            <video height="auto" width="100%" autoPlay muted loop playsInline={isSafari} src={!isSafari && image}>
              {isSafari &&
                <source src={image} type="video/mp4" />
              }
            </video>
          }

          <Button className={styles.remove} onClick={handleRemove}>Remove Background</Button>
        </div>

        {!image &&
          <Image>
            <div>NO IMAGE</div>
          </Image>
        }

        {!updating &&
          <Button className={styles.uploadButton} basic onClick={() => { uploadIconRef.current.click() }}>
            Upload new image
            <input
              ref={uploadIconRef}
              type="file"
              accept={accept || "image/* video/*"}
              onChange={handleFileChange}
            />
          </Button>
        }
      </div>
    </div>
  );
}

QuickSettingsProperty.Image = PropertyImage;

/**
 * Background Property
 */
function PropertyBackground({ expanded, updating, value, onImageChange, onChange, ...rest }) {
  const media = _.get(value, 'media');
  const upload = _.get(value, 'upload');

  const type = _.get(value, 'type');
  const color = _.get(value, 'color') || 'transparent';
  const overlay = _.get(value, 'overlay') || 'transparent';
  const position = _.get(value, 'position' || 'center center');

  const handleBackgroundChange = (file) => {

    if (typeof file === 'string' || file.type.match('image.*')) {
      ImageTools.resize(file, {
        width: 1600, // maximum width
        height: 1024 // maximum height
      }, (blob, didItResize) => {
        loadImageFile(blob).then(url => {
          onImageChange(url, 'image');
        })
      });
    } else if (file.type.match('video.*')) {
      loadImageFile(file).then(url => {
        onImageChange(url, 'video');
      })
    }
  }

  const loadImageFile = (imageFile) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.onload = e => resolve(e.target.result)
      reader.onerror = reject
      reader.readAsDataURL(imageFile)
    });
  }

  const handleRemoveImage = () => {
    var background = value || {};
    background = {
      ...background,
      media: null
    }

    onChange(background);
  }

  const handleColorChange = (color) => {
    var updated = { ...(value || {}) };
    _.set(updated, `color`, color);
    if (onChange) {
      onChange(updated);
    }
  }

  const handleOverlayChange = (color) => {
    var updated = { ...(value || {}) };
    _.set(updated, `overlay`, color);
    if (onChange) {
      onChange(updated);
    }
  }

  const handlePositionChange = (position) => {
    var updated = { ...(value || {}) };
    _.set(updated, `position`, position);
    if (onChange) {
      onChange(updated);
    }
  }

  return (
    <>
      <QuickSettingsProperty.Image title='Image'
        value={upload ? upload : media}
        type={type}
        updating={updating}
        accept='image/* video/*'
        onChange={handleBackgroundChange}
        onRemove={handleRemoveImage}
      />
      <QuickSettingsProperty.Color title='Color' value={color} onChange={handleColorChange} />
      {rest.overlayEnabled &&
        <QuickSettingsProperty.Color
          title='Overlay'
          value={overlay}
          onChange={handleOverlayChange}
          presetColors={[
            {
              title: "Dark",
              color: { r: 0, g: 0, b: 0, a: 0.15 }
            },
            {
              title: "Light",
              color: { r: 255, g: 255, b: 255, a: 0.15 }
            }
          ]}
        />
      }
      <QuickSettingsProperty.Position value={position} onChange={handlePositionChange} />
    </>
  );
}

QuickSettingsProperty.Background = PropertyBackground;

/**
 * Background Filter
 */
function PropertyFilter({ value, onChange }) {
  const filterOptions = [
    { key: 'noFilter', value: 'noFilter', text: 'No Filter' },
    { key: 'dark', value: 'dark', text: 'Dark' },
    { key: 'light', value: 'light', text: 'Light' },
  ]

  const handleFilterChange = (e, data) => {
    let filter = data.value;
    onChange(filter);
  }

  return (
    <QuickSettingsProperty.Dropdown title='Filter'
      tooltip='Select filter'
      placeholder='Select filter...'
      selection
      options={filterOptions}
      value={value}
      onChange={handleFilterChange}
    />
  );
}

QuickSettingsProperty.Filter = PropertyFilter;

/**
 * Background position
 */
function PropertyPosition({ value, onChange }) {
  var horizontalOptions = ['left', 'center', 'right'];
  var verticalOptions = ['top', 'center', 'bottom'];

  const handlePositionClick = (horizontal, vertical) => {
    var position = `${vertical} ${horizontal}`;
    if (onChange) {
      onChange(position)
    }
  }

  return (
    <QuickSettingsProperty title='Position'>
      <div className={styles.positions}>
        {verticalOptions.map(vertical => {
          return horizontalOptions.map(horizontal => {
            var position = `${vertical} ${horizontal}`;
            var active = position === value;

            return (
              <div key={position} className={cx(
                styles.position,
                styles[horizontal],
                styles[vertical],
                { [styles.active]: active }
              )}
                eventKey={`${horizontal}_${vertical}`}
                onClick={handlePositionClick.bind(this, horizontal, vertical)}
              >
                <a href="#">
                  {!(horizontal === 'center' && vertical === 'center') &&
                    <Icon name='long arrow alternate up' />
                  }
                  {(horizontal === 'center' && vertical === 'center') &&
                    <span>&nbsp;</span>
                  }
                </a>
              </div>
            );
          })
        })}
      </div>
    </QuickSettingsProperty>
  )
}

QuickSettingsProperty.Position = PropertyPosition;

/**
 * Border Property
 */
function PropertyBorder({ expanded, value, onChange, ...rest }) {
  const defaultBorder = {
    sides: {
      top: false,
      bottom: false,
      left: false,
      right: false
    },
    color: { r: 0, g: 0, b: 0, a: 1 },
    width: '1px',
    radius: '0px'
  }

  const top = _.get(value, 'sides.top', false);
  const left = _.get(value, 'sides.left', false);
  const right = _.get(value, 'sides.right', false);
  const bottom = _.get(value, 'sides.bottom', false);

  const color = _.get(value, 'color') || defaultBorder.color;
  const width = _.get(value, 'width', defaultBorder.width);
  const radius = _.get(value, 'radius', defaultBorder.radius);

  const handleSideToggle = (side, state) => {
    var updated = { ...(value || defaultBorder) };
    _.set(updated, `sides.${side}`, state);
    if (onChange) {
      onChange(updated);
    }
  }

  const handleWidthChange = (width) => {
    var updated = { ...(value || defaultBorder) };
    _.set(updated, `width`, width);
    if (onChange) {
      onChange(updated);
    }
  }

  const handleRadiusChange = (radius) => {
    var updated = { ...(value || defaultBorder) };
    _.set(updated, `radius`, radius);
    if (onChange) {
      onChange(updated);
    }
  }

  const handleColorChange = (color) => {
    var updated = { ...(value || defaultBorder) };
    _.set(updated, `color`, color);
    if (onChange) {
      onChange(updated);
    }
  }

  return (
    <>
      <QuickSettingsProperty title='Border' className={styles.borders}>
        <Button.Group floated='right'>
          <Button className={cx(styles.top, { [styles.active]: top })} onClick={() => {
            handleSideToggle('top', !top);
          }}>
            <Image size='mini' src='/images/pages/properties/border_top.svg' />
          </Button>
          <Button className={cx(styles.left, { [styles.active]: left })} onClick={() => {
            handleSideToggle('left', !left);
          }}>
            <Image size='mini' src='/images/pages/properties/border_left.svg' />
          </Button>
          <Button className={cx(styles.right, { [styles.active]: right })} onClick={() => {
            handleSideToggle('right', !right);
          }}>
            <Image size='mini' src='/images/pages/properties/border_right.svg' />
          </Button>
          <Button className={cx(styles.bottom, { [styles.active]: bottom })} onClick={() => {
            handleSideToggle('bottom', !bottom);
          }}>
            <Image size='mini' src='/images/pages/properties/border_bottom.svg' />
          </Button>
        </Button.Group>
      </QuickSettingsProperty>

      <QuickSettingsProperty.Color
        title="Border Color"
        value={color}
        onChange={handleColorChange}
      />

      <QuickSettingsProperty.Input
        title="Border Width"
        value={width}
        onChange={handleWidthChange}
      />
      <QuickSettingsProperty.Input
        title="Border Radius"
        value={radius}
        onChange={handleRadiusChange}
      />
    </>
  );
}

QuickSettingsProperty.Border = PropertyBorder;


/**
 * Margin Property
 */
function PropertySpacing({ title, expanded, value, onChange, ...rest }) {
  const [expandedState, setExpanded] = useState(expanded || false);

  const handleClick = () => {
    setExpanded(!expandedState);
  }

  const top = _.get(value, 'top', '');
  const left = _.get(value, 'left', '');
  const right = _.get(value, 'right', '');
  const bottom = _.get(value, 'bottom', '');

  var values = [top, left, right, bottom];
  values = _.uniq(values);

  const equalSpacing = values.length === 1 ? values[0] : null;

  const handleAllChange = (e) => {
    var spacing = e.target.value;

    if (onChange) {
      onChange({
        top: spacing,
        left: spacing,
        right: spacing,
        bottom: spacing
      });
    }
  }

  const handleChange = (side, v) => {
    if (onChange) {
      onChange({
        ...(value || {}),
        [side]: v
      });
    }
  }

  return (
    <table>
      <tbody>
        <tr className={styles.property}>
          <td colSpan={2}>
            <Accordion className={styles.spacing}>
              <Accordion.Title active={expandedState} className={styles.title} onClick={handleClick}>
                {title}
                <Icon name='dropdown' />
                <Input
                  className={styles.input}
                  value={equalSpacing}
                  onClick={(e) => { e.stopPropagation() }}
                  onChange={handleAllChange}
                />
              </Accordion.Title>
              <Accordion.Content active={expandedState}>
                <QuickSettingsProperty.Input
                  title={`${title} Top`}
                  value={top}
                  onChange={(e) => { handleChange('top', e) }}
                />
                <QuickSettingsProperty.Input
                  title={`${title} Right`}
                  value={right}
                  onChange={(e) => { handleChange('right', e) }}
                />
                <QuickSettingsProperty.Input
                  title={`${title} Bottom`}
                  value={bottom}
                  onChange={(e) => { handleChange('bottom', e) }}
                />
                <QuickSettingsProperty.Input
                  title={`${title} Left`}
                  value={left}
                  onChange={(e) => { handleChange('left', e) }}
                />
              </Accordion.Content>
            </Accordion>
          </td>
        </tr>
      </tbody>
    </table>
  );
}

QuickSettingsProperty.Spacing = PropertySpacing;

/**
 * Property CSS
 */
function PropertyCss({ title, tooltip, value, onChange, ...rest }) {
  const [open, setOpen] = useState(false);
  const [css, setCss] = useState(value);

  const handleSave = () => {
    onChange(css);
    setOpen(false);
  }

  return (
    <QuickSettingsProperty title={title} tooltip={tooltip}>
      <Modal size='large'
        trigger={
          <Button size='mini'>Edit</Button>
        }
        className={styles.cssEditorModal}
        open={open}
        onOpen={() => {
          setOpen(true);
        }}
      >
        <Modal.Header>Custom  Stylesheet</Modal.Header>
        <Modal.Content scrolling>
          <AceEditor
            placeholder={'Edit styles'}
            mode={'css'}
            theme="tomorrow"
            className={styles.aceEditor}
            fontSize={14}
            width='100%'
            showPrintMargin={true}
            showGutter={true}
            highlightActiveLine={true}
            value={css || ''}
            onChange={(value) => { setCss(value) }}
            setOptions={{
              useWorker: false,
              enableBasicAutocompletion: true,
              enableLiveAutocompletion: true,
              enableSnippets: false,
              showLineNumbers: true,
              tabSize: 2
            }}
          />
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={() => setOpen(false)}>
            <Icon name='remove' /> Cancel
          </Button>
          <Button primary onClick={handleSave}>
            <Icon name='checkmark' /> Save Changes
          </Button>
        </Modal.Actions>
      </Modal>
    </QuickSettingsProperty>
  );
}

QuickSettingsProperty.CSS = PropertyCss;

/**
 * Property Script
 */
function PropertyScript({ title, tooltip, value, onChange, ...rest }) {
  const [open, setOpen] = useState(false);
  const [script, setScript] = useState(value);

  const handleSave = () => {
    onChange(script);
    setOpen(false);
  }

  return (
    <QuickSettingsProperty title={title} tooltip={tooltip}>
      <Modal size='large'
        trigger={
          <Button size='mini'>Edit</Button>
        }
        className={styles.cssEditorModal}
        open={open}
        onOpen={() => {
          setOpen(true);
        }}
      >
        <Modal.Header>Custom Script</Modal.Header>
        <Modal.Content scrolling>
          <AceEditor
            placeholder={'Edit script'}
            mode={'javascript'}
            theme="tomorrow"
            className={styles.aceEditor}
            fontSize={14}
            width='100%'
            showPrintMargin={true}
            showGutter={true}
            highlightActiveLine={true}
            value={script || ''}
            onChange={(value) => { setScript(value) }}
            setOptions={{
              useWorker: false,
              enableBasicAutocompletion: true,
              enableLiveAutocompletion: true,
              enableSnippets: false,
              showLineNumbers: true,
              tabSize: 2
            }}
          />
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={() => setOpen(false)}>
            <Icon name='remove' /> Cancel
          </Button>
          <Button primary onClick={handleSave}>
            <Icon name='checkmark' /> Save Changes
          </Button>
        </Modal.Actions>
      </Modal>
    </QuickSettingsProperty>
  );
}

QuickSettingsProperty.Script = PropertyScript;


/**
 * Property Animation
 */

function AnimationProperty({ value, onChange, ...rest }) {
  const animationOptions = [
    { key: 'none', value: 'none', text: 'None' },
    { key: 'fade', value: 'fade', text: 'Fade' },
    { key: 'flip', value: 'flip', text: 'Flip' },
    { key: 'slide', value: 'slide', text: 'Slide' },
    { key: 'roll', value: 'roll', text: 'Roll' },
  ]

  const directionOptions = (type) => {
    if (type === 'roll') {
      return [];
    }

    if (type === 'flip') {
      return [
        { key: 'horizontal', value: 'horizontal', text: 'Horizontal' },
        { key: 'vertical', value: 'vertical', text: 'Vertical' }
      ];
    }

    return [
      { key: 'left', value: 'left', text: 'Left' },
      { key: 'right', value: 'right', text: 'Right' },
      { key: 'up', value: 'up', text: 'Up' },
      { key: 'down', value: 'down', text: 'Down' }
    ];
  }

  const handleAnimationChange = (e, data) => {
    let type = data.value;
    if (onChange) {
      onChange({
        ...(value || {}),
        type: type
      });
    }
  }

  const handleDirectionChange = (e, data) => {
    let direction = data.value;
    if (onChange) {
      onChange({
        ...(value || {}),
        direction: direction
      });
    }
  }

  const handleDurationChange = (data) => {
    if (onChange) {
      onChange({
        ...(value || {}),
        duration: data
      });
    }
  }

  const handleDelayChange = (data) => {
    if (onChange) {
      onChange({
        ...(value || {}),
        delay: data
      });
    }
  }

  const type = _.get(value, 'type', 'none');
  const direction = _.get(value, 'direction');
  const duration = _.get(value, 'duration', '');
  const delay = _.get(value, 'delay', '');

  const directions = directionOptions(type);

  return (
    <>
      <QuickSettingsProperty.Dropdown title='Animation'
        tooltip='Select animation'
        placeholder='Select animation...'
        selection
        options={animationOptions}
        value={type}
        onChange={handleAnimationChange}
      />

      {type != 'none' &&
        <>
          {directions.length > 0 &&
            <QuickSettingsProperty.Dropdown title='Direction'
              tooltip='Select direction'
              placeholder='Select direction...'
              selection
              options={directions}
              value={direction}
              onChange={handleDirectionChange}
            />
          }
          <QuickSettingsProperty.Input
            title="Duration"
            value={duration}
            onChange={(handleDurationChange)}
          />
          <QuickSettingsProperty.Input
            title="Delay"
            value={delay}
            onChange={handleDelayChange}
          />
        </>
      }
    </>
  );
}

QuickSettingsProperty.Animation = AnimationProperty;

/**
 * Property Direction
 */
function PropertyDirection({ onChange, value, tooltip, ...rest }) {

  const handleDirectionChange = (direction) => {
    onChange(direction)
  }

  return (
    <QuickSettingsProperty title='Direction' tooltip={tooltip} className={styles.direction}>
      <Button.Group floated='right'>
        <Button className={cx(styles.column, { [styles.active]: value === 'vertical' })} onClick={() => {
          handleDirectionChange('vertical');
        }}>
          <Image size='mini' src='/images/container/column.svg' />
        </Button>
        <Button className={cx(styles.row, { [styles.active]: value === 'horizontal' })} onClick={() => {
          handleDirectionChange('horizontal');
        }}>
          <Image size='mini' src='/images/container/row.svg' />
        </Button>
      </Button.Group>
    </QuickSettingsProperty>
  );
}

QuickSettingsProperty.Direction = PropertyDirection;

/**
 * Property Alignment 
 */
function PropertyAlignment({ title, onChange, value, vertical, tooltip, ...rest }) {
  var alignments = [{
    key: 'left',
    icon: 'left'
  }, {
    key: 'center',
    icon: 'vertical'
  }, {
    key: 'right',
    icon: 'right'
  }];

  if (vertical) {
    alignments = [{
      key: 'top',
      icon: 'up'
    }, {
      key: 'middle',
      icon: 'horizontal'
    }, {
      key: 'bottom',
      icon: 'down'
    }];
  }

  const handleAlignmentChange = (alignment) => {
    onChange(alignment);
  }

  return (
    <QuickSettingsProperty title={title} tooltip={tooltip} className={styles.direction}>
      <Button.Group floated='right'>
        {alignments.map(alignment => {
          return (
            <Button className={cx(styles.row, { [styles.active]: value === alignment.key })} onClick={() => {
              handleAlignmentChange(alignment.key);
            }}>
              <Image size='mini' src={`/images/container/${alignment.icon}.svg`} />
            </Button>
          );
        })}
      </Button.Group>
    </QuickSettingsProperty>
  )
}

QuickSettingsProperty.Alignment = PropertyAlignment;


/**
 * Property Anchor
 */
function PropertyAnchor({ title, onChange, value, tooltip, ...rest }) {
  const [enabled, setEnabled] = useState(!_.isEmpty(value));
  const regex = /[a-zA-Z0-9\\_\\"]+$/;

  return (
    <>
      <QuickSettingsProperty.Switch tooltip='Adds Anchor to the Row'
        title='Anchor'
        value={enabled}
        onChange={() => {
          setEnabled(!enabled);
          onChange(null);
        }}
      />

      {enabled &&
        <QuickSettingsProperty.Input
          title="Name"
          value={value}
          onChange={(e) => {
            if(_.isEmpty(e) || regex.test(e)){
              onChange(e);
            }
          }}
        />
      }
    </>
  )
}

QuickSettingsProperty.Anchor = PropertyAnchor;