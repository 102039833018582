import {
  AUTHENTICATE_FRESHMARKETER_REQUEST, AUTHENTICATE_FRESHMARKETER_SUCCEEDED, AUTHENTICATE_FRESHMARKETER_FAILED,
  FETCHING_FRESHMARKETER_LISTS, FETCHING_FRESHMARKETER_LISTS_SUCCEEDED, FETCHING_FRESHMARKETER_LISTS_FAILED
} from 'constants/actionTypes';

import axios from 'axios';
import { createAction } from 'redux-actions';
import buildUrl from 'build-url';
import { call, put, takeEvery } from 'redux-saga/effects';

const apiUrl = `${process.env.REACT_APP_API_URL}/api/2.0`;
const FRESHMARKETER_BASE_URL = `${apiUrl}/integrations/freshmarketer`;

/*
* Authenticate Freshmarketer
*/
export const authenticateFreshMarketer = createAction(AUTHENTICATE_FRESHMARKETER_REQUEST, (request, callback) => {
  return { request, callback };
})

const authenticateFreshMarketerSucceeded = createAction(AUTHENTICATE_FRESHMARKETER_SUCCEEDED, (response) => {
  return response;
})

const authenticateFreshMarketerFailed = createAction(AUTHENTICATE_FRESHMARKETER_FAILED, (error) => {
  return error;
})

function* authenticateFreshMarketerSaga(action) {
  const { request, callback } = action.payload;

  const authenticateFreshMarketerAPI = () => {
    return axios.post(FRESHMARKETER_BASE_URL, request).then(response => response.data);
  }

  try {
    const response = yield call(authenticateFreshMarketerAPI);
    if (response) {
      yield put(authenticateFreshMarketerSucceeded(response));
      if(callback) {
        callback(response);
      }
    }
  } catch (error) {
    yield put(authenticateFreshMarketerFailed(error));
  }
}

/*
* Fetching Freshmarketer lists
*/
export const fetchFreshmarketerLists = createAction(FETCHING_FRESHMARKETER_LISTS, (page) => {
  return page;
});

const fetchFreshmarketerListsSucceeded = createAction(FETCHING_FRESHMARKETER_LISTS_SUCCEEDED, (response) => {
  return response;
})

const fetchFreshmarketerListsFailed = createAction(FETCHING_FRESHMARKETER_LISTS_FAILED, (error) => {
  return error;
})

function* fetchFreshmarketerListsSaga(action) {
  const page = action.payload;

  var params = {
    page: page
  }

  let url = buildUrl(FRESHMARKETER_BASE_URL, {
    path: 'lists',
    queryParams: params
  });

  const fetchFreshmarketerListsAPI = () => {
    return axios.get(url).then(response => response.data);
  }

  try {
    const response = yield call(fetchFreshmarketerListsAPI);
    if (response) {
      yield put(fetchFreshmarketerListsSucceeded(response));
    }
  } catch (error) {
    yield put(fetchFreshmarketerListsFailed(error));
  }
}

export default function* freshmarketerSaga() {
  yield takeEvery(AUTHENTICATE_FRESHMARKETER_REQUEST, authenticateFreshMarketerSaga);
  yield takeEvery(FETCHING_FRESHMARKETER_LISTS, fetchFreshmarketerListsSaga);
}