import React, { PropTypes, Fragment, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router-dom';

import {
  Segment,
  Header,
  Card,
  Image,
  Button
} from 'semantic-ui-react';

import cx from 'classnames';
import _ from 'lodash';

import styles from './integrations.module.scss';

import { integrations } from 'constants/integrations';
import { fetchIntegrations } from 'actions/integrations/integrations';

export default function IntegrationsView() {
  const dispatch = useDispatch();

  useEffect(()=> {
    dispatch(fetchIntegrations());
  },[])
  const activatedIntegratons = useSelector(state => _.get(state, 'integrations.activated.items', []));

  return (
    <Segment basic className={styles.integrationsView}>
      <Header as={'h2'} className={styles.header}>Integrations</Header>

      <Card.Group itemsPerRow={4} className={styles.integrationCards}>
        {integrations.map(item => {
          return <IntegrationCard key={item.type} integration={item} integrations={activatedIntegratons}/>
        })}
      </Card.Group>
    </Segment>
  )
}

function IntegrationCard({ integration, integrations }) {
  const history = useHistory();
  let { url } = useRouteMatch();

  const handleCardClick = () => {
    history.push(`${url}${integration.route}`);
  }

  const activatedIntegration = integrations.filter(item => {
    return item != null;
  }).find(item => {
    return item.type === integration.type;
  });

  const activated = activatedIntegration != null;

  return (
    <Card className={cx(styles.integrationCard, { [styles.activated]: activated })} onClick={handleCardClick}>
      <Card.Content>
        <Image src={integration.icon} />
        <Header as='h2'>{integration.name}</Header>
        <p className={styles.description}>{integration.description}</p>
        <Button>{activated ? 'ACTIVATED' : 'ACTIVATE'}</Button>
      </Card.Content>
    </Card>
  )
}