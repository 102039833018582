import { 
  AUTHENTICATING_USER_REQUEST, AUTHENTICATING_USER_SUCCEDDED, AUTHENTICATING_USER_FAILED, 
  FORGOT_PASSWORD_REQUEST, FORGOT_PASSWORD_SUCCEEDED, FORGOT_PASSWORD_FAILED,   
  FETCHING_SSO_URL_REQUEST, FETCHING_SSO_URL_SUCCEEDED, FETCHING_SSO_URL_FAILED, SSO_AUTHENTICATION_FAILED, 
  LOGOUT_USER,
  FETCHING_USER_PROFILE, FETCHING_USER_PROFILE_SUCCEEDED, FETCHING_USER_PROFILE_FAILED,
  FETCHING_USER_INVITATION, FETCHING_USER_INVITATION_SUCCEDDED, FETCHING_USER_INVITATION_FAILED,
  USER_REGISTRATION_REQUEST, USER_REGISTRATION_SUCCEDDED, USER_REGISTRATION_FAILED,

  UPDATING_USER_PROFILE, UPDATING_USER_PROFILE_SUCCEEDED, UPDATING_USER_PROFILE_FAILED,
  CHANGING_PASSWORD, CHANGING_PASSWORD_SUCCEEDED, CHANGING_PASSWORD_FAILED
} from 'constants/actionTypes.jsx';

import _ from 'lodash';
import AuthService from '../services/auth.service.js';

const initialState = {
  authentication: {
    authenticating: false,
    authenticated: false,
    error: null,
  },
  changePassword: {
    error: [],
    recoveryError: null,
    loading: false,
    success: false,
    changing: false
  },
  me: null,
  forgotPassword:{
    loading: false,
    error: null
  },
  sso: {
    loading: false,
    signinWithPassword: false,
    item: null
  },
  updating: false
}

function usersReducer(state = initialState, action) {
  switch (action.type) {
    case AUTHENTICATING_USER_REQUEST:
      return {...state, 
        authentication: {
          authenticating: true,
          authenticated: false,
          error: null
        }
      };
      
    case AUTHENTICATING_USER_SUCCEDDED:
      AuthService.saveToken(action.payload.access_token, action.payload.refresh_token);
      
      return {...state, 
        authentication: {
          authenticating: false,
          authenticated: true,
          error: null
        }
      };
      
    case AUTHENTICATING_USER_FAILED:
      var error = 'Unable to authenticate. Please try again later';
      if(action.payload){
        error = action.payload.error_description;
      }
      return {...state, 
        authentication: {
          authenticating: false,
          authenticated: false,
          error: error
        }
      };

    case FETCHING_USER_PROFILE:
      return {
        ...state,
        me: null,
        fetchingProfile: true
      }

    case FETCHING_USER_PROFILE_SUCCEEDED:
      return {
        ...state,
        me: action.payload,
        fetchingProfile: false
      }

    case FETCHING_USER_PROFILE_FAILED:
      return {
        ...state,
        me: null,
        fetchingProfile: false
      }

    case FORGOT_PASSWORD_REQUEST:
      return {
        ...state,
        forgotPassword: {
          loading: true,
          error: null
        }
      }

    case FORGOT_PASSWORD_SUCCEEDED:
      return {
        ...state,
        forgotPassword: {
          loading: false,
          error: null
        }
      }
    
    case FORGOT_PASSWORD_FAILED:
      var error = 'Unable to process request. Please try again later';
      if(action.payload.errors){
        error = _.get(action.payload, 'errors[0].message');
      }

      return Object.assign({}, state, {
        forgotPassword: {
          loading: false,
          error: error
        }
      })

    case FETCHING_SSO_URL_REQUEST: 
      return {
        ...state,
        sso: {
          ...state.sso,
          loading: true,
          signinWithPassword: false,
          item: null
        }   
      }

    case FETCHING_SSO_URL_SUCCEEDED:
      return {
        ...state,
        sso: {
          ...state.sso,
          loading: false,
          signinWithPassword: false,
          item: action.payload
        }
      }

    case FETCHING_SSO_URL_FAILED:
      console.log(action);
      return {
        ...state,
        sso: {
          ...state.sso,
          loading: false,
          signinWithPassword: true,
          item: null,
          error: action.payload.error
        }
      }

    case SSO_AUTHENTICATION_FAILED:
      return {
        ...state,
          sso: {
            ...state.sso,
            loading: false,
            item: null,
            signinWithPassword: true,
            error: {
              errors: [{
                code: 99999,
                message: action.payload.reason
              }]
            }
          }
        }
    
    case FETCHING_USER_INVITATION:
      return {
        ...state,
        invitation: {
          loading: true,
          item: null,
          error: null
        }
      }

    case FETCHING_USER_INVITATION_SUCCEDDED:
      return {
        ...state,
        invitation: {
          loading: false,
          item: action.payload,
          error: null
        }
      }

    case FETCHING_USER_INVITATION_FAILED:
      return {
        ...state,
        invitation: {
          loading: false,
          item: null,
          error: action.payload
        }
      }
    
    case USER_REGISTRATION_REQUEST:
      return {
        ...state,
        registration: {
          ...state.registration,
          loading: true,
          item: null,
          error: null
        }
      }
      
    case USER_REGISTRATION_SUCCEDDED:
      return {
        ...state,
        registration: {
          ...state.registration,
          loading: false,
          item: action.payload
        }
      }

    case USER_REGISTRATION_FAILED:
      return {
        ...state,
        registration: {
          ...state.registration,
          loading: false,
          item: null,
          error: action.payload
        }
      }

    case UPDATING_USER_PROFILE:
      return {
        ...state,
        updating: true
      }

    case UPDATING_USER_PROFILE_SUCCEEDED:
      return {
        ...state,
        updating: false,
        me: action.payload
      }

    case UPDATING_USER_PROFILE_FAILED:
      return {
        ...state,
        updating: false,
        error: action.payload
      }
    
    case CHANGING_PASSWORD:
      return {
        ...state,
        changePassword: {
          ...state.changePassword,
          error: [],
          changing: true
        }
      }
    case CHANGING_PASSWORD_SUCCEEDED:
      return {
        ...state,
        changePassword: {
          ...state.changePassword,
          error: [],
          changing: false,
        }
      }
    case CHANGING_PASSWORD_FAILED:
      return {
        ...state,
        changePassword: {
          ...state.changePassword,
          error: action.payload,
          changing: false,
        }
      }

    case LOGOUT_USER:
      AuthService.clearAccessToken();
      return state

    default:
      return state
  }
}

export { usersReducer };