import React, { useCallback, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { deselectWidget, showPlaceholderAtIndex } from 'actions/widgetEditor';

import { DndProvider, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

import cx from 'classnames';
import _ from 'lodash';

import {
  Portal,
  Ref,
  Segment
} from 'semantic-ui-react';

import styles from './editor.module.scss';
import WidgetsPaneView from './widgets';
import { Widget, getStyles } from 'components/Widgets/widget.jsx';

import { WIDGET_ITEM, PALETTE_ITEM } from 'constants/ItemTypes.jsx';
import { rearrangeWidgets } from 'actions/widgetEditor';
import { useWidgetSelecion } from 'components/Widgets/widget';

export const WidgetContext = React.createContext({
  onCreateWidget: () => { },
  onPaletteItemDrop: () => { },
  scale: 1
});

export function WidgetsEditor(props) {
  const dispatch = useDispatch();

  const { editor, editable, onCreateWidget, modal } = props;
  const selectedWidget = useWidgetSelecion(editor);

  const editorContext = useSelector(state => {
    return state.widgetsEditor.editors[editor];
  });

  var widgets = props.widgets || [];
  widgets = useSelector(state => {
    let items = [];

    if (!props.editor) {
      return items;
    }

    var editorContext = state.widgetsEditor.editors[editor];

    items = widgets.map(widget => {
      return editorContext.widgetsById[widget];
    });

    return items;
  }).filter(item => item != null);

  var widgetIds = (widgets || []).map(item => {
    return item.id;
  })

  const handleViewerClicked = useCallback(() => {
    dispatch(deselectWidget(editor));
  }, [editor]);

  const handlePaletteItemDrop = useCallback((editor, data, parent, index, insertBefore) => {
    handleCreateWidget(editor, data, parent, index, insertBefore);
  }, [editorContext]);

  const handleCreateWidget = useCallback((editor, request, parent, index, insertBefore) => {
    onCreateWidget(editor, request, parent, index, insertBefore);
  }, []);

  if (!editor) {
    return false;
  }

  return (
    <DndProvider backend={HTML5Backend}>
      {editable &&
        <WidgetsPaneView
          className={props.paletteClassName}
          entityReference={props.entityReference}
          editor={editor}
          onItemClick={(data) => {
            handlePaletteItemDrop(editor, data, null, widgets.length);
          }} 
        />
      }

      <WidgetViewer
        className={props.className}
        entityReference={props.entityReference}
        editor={editor}
        editable={editable}
        modal={modal}
        widgets={widgetIds}
        handlePaletteItemDrop={handlePaletteItemDrop}
        handleCreateWidget={handleCreateWidget}
        onClick={handleViewerClicked}
        scale={1}
        modal={modal}
      />
    </DndProvider>
  );
}

const WidgetViewer = React.memo(function WidgetViewer(props) {
  const dispatch = useDispatch();

  const ref = React.useRef(null);
  
  const { widgets, handlePaletteItemDrop, handleCreateWidget, scale, modal } = props;
  const page = useSelector(state => _.get(state, 'pageEditor.item', {}));

  const entityReference = props.entityReference;
  
  const id = `${modal && 'modal_'}editor_${props.editor}`;
  const [pageStyles, setPageStyles] = useState(entityReference && entityReference.type === 'page' ? getStyles(_.get(page, 'draft.properties', {})) : {});

  useEffect(() => {
    if (page && entityReference && entityReference.type === 'page') {
      setPageStyles(getStyles(_.get(page, 'draft.properties', {})));
    }
  }, [page]);

  const [{ isOver }, drop] = useDrop(() => ({
    accept: [PALETTE_ITEM, WIDGET_ITEM],
    canDrop(props, monitor) {
      var itemType = monitor.getItemType();
      if (itemType === PALETTE_ITEM) {
        return true;
      }

      return true;
    },
    hover: _.throttle((item, monitor) => {
      const isOver = monitor.isOver({ shallow: true });
      if (!isOver) {
        return;
      }else{
        console.log(`Currently drag hover for editor`);
      }

      var request = {
        parent: null,
        index: widgets.length - 1,
        direction: 'down'
      }

      var rect = null;

      if(request.index >= 0){
        rect = ref.current?.children[request.index].getBoundingClientRect();
      }else{
        rect = ref.current?.getBoundingClientRect();
        rect = {
          top: rect.top,
          bottom: rect.top,
          left: rect.left,
          right: rect.right,
          width: rect.width,
          height: rect.height
        }
      }

      if (_.isEqual(request, item.request)) {
        return;
      }

      dispatch(showPlaceholderAtIndex(props.editor, null, request.index, {
        direction: 'down',
        rect: rect
      }));

      item.request = request;
    }, 100),
    collect: monitor => ({
      isOver: monitor.isOver({ shallow: true })
    }),
  }), [props.editor, props.entityReference]);

  if (!widgets) {
    return false;
  }

  return (
    <>
      <div ref={drop}>
        <Ref innerRef={ref}>
          <Segment basic
            id={id}
            className={cx(styles.widgetEditor, 'widget_viewer', props.className, {
              [styles.editable]: props.editable
            })}
            style={pageStyles}
            onClick={props.onClick}
          >
            <WidgetContext.Provider value={{
              entityReference: props.entityReference,
              onPaletteItemDrop: handlePaletteItemDrop,
              onCreateWidget: handleCreateWidget,
              scale: scale,
              modal: modal
            }}>
              <Widget.Children
                widgets={widgets}
                editor={props.editor}
                editable={props.editable}
              />
            </WidgetContext.Provider>
          </Segment>
        </Ref>
      </div>
      <DragIndicator editor={props.editor} editorId={id} />
    </>
  );
});

const DragIndicator = React.memo(({ editor, editorId }) => {
  const ref = React.useRef(null);

  const [animate, setAnimate] = useState(false);

  const placeholder = useSelector(state => {
    var editorContext = state.widgetsEditor.editors[editor];
    return _.get(editorContext, 'placeholder');
  });

  useEffect(() => {
    setAnimate(placeholder != null);
  }, [placeholder]);


  if (!placeholder) {
    return false;
  }

  const direction = _.get(placeholder, 'data.direction');
  var top = 0;
  var left = 0;

  var indicatorStyles = {
    top: top,
    left: left,
    width: _.get(placeholder, 'data.rect.width'),
    height: _.get(placeholder, 'data.rect.height')
  }

  switch (direction) {
    case 'up':

      indicatorStyles = {
        top: _.get(placeholder, 'data.rect.top', 0),
        left: _.get(placeholder, 'data.rect.left', 0),
        right: _.get(placeholder, 'data.rect.right', 0),
        width: _.get(placeholder, 'data.rect.width', 0),
        height: '3px',
      }

      break;

    case 'left':
      indicatorStyles = {
        top: _.get(placeholder, 'data.rect.top', 0),
        bottom: _.get(placeholder, 'data.rect.bottom', 0),
        left: _.get(placeholder, 'data.rect.left', 0),
        width: '3px',
        height: _.get(placeholder, 'data.rect.height', 0)
      }

      break;

    case 'down':
      indicatorStyles = {
        top: _.get(placeholder, 'data.rect.bottom', 0),
        left: _.get(placeholder, 'data.rect.left', 0),
        right: _.get(placeholder, 'data.rect.right', 0),
        width: _.get(placeholder, 'data.rect.width', 0),
        height: '3px'
      }

      break;

    case 'right':
      indicatorStyles = {
        top: _.get(placeholder, 'data.rect.top', 0),
        bottom: _.get(placeholder, 'data.rect.bottom', 0),
        left: _.get(placeholder, 'data.rect.right', 0),
        width: '3px',
        height: _.get(placeholder, 'data.rect.height', 0)
      }

      break;
    default:
  }

  return (
    <Portal open={placeholder != null}>
      <div ref={ref} className={cx(styles.dragIndicator, {
        [styles.animate]: animate
      })} style={indicatorStyles}>
      </div>
    </Portal>
  );
});