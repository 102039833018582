/* eslint-disable jsx-a11y/anchor-has-content */
import React, { PropTypes, Fragment } from 'react';
import { connect } from 'react-redux';
import cx from 'classnames';

import {
  Image,
  Menu,
  Rail,
  Header,
  Sticky,
  Ref
} from 'semantic-ui-react';

import styles from './EnhancedDocumentViewer.module.scss'

import TextLayer from './TextLayer';

import Measure from 'react-measure';
import { Waypoint } from 'react-waypoint';

import { fetchPreviewAsset, fetchPreviewText, recordAnalyticsEvent } from 'actions/content';

const ZoomOutIcon = '/images/pdfViewer/zoom_out_icon.png';
const ZoomInIcon = '/images/pdfViewer/zoom_in_icon.png';
const NextPageIcon = '/images/pdfViewer/up_arrow_icon.png';
const PreviousPageIcon = '/images/pdfViewer/down_arrow_icon.png';
const FullScreenIcon = '/images/pdfViewer/full_screen_icon.png';

class EnhancedDocumentView extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      pageDimensions: {},
      currentPage: 0,
      currentScale: 0,
      initialPage: 1,
      preferredWidth: this.props.inline ? 760 : 1024
    }

    this.onPageChange = this.onPageChange.bind(this);
    this._handleZoomInClick = this._handleZoomInClick.bind(this);
    this._handleZoomOutClick = this._handleZoomOutClick.bind(this);

    this._handleFullScreenClick = this._handleFullScreenClick.bind(this);

    this._handleNextPageClick = this._handleNextPageClick.bind(this);
    this._handlePreviousPageClick = this._handlePreviousPageClick.bind(this);

    this.rendered = false;
    this.initialized = false;
  }

  componentDidMount() {
    this.loadPreviewData(this.props.asset);
  }

  loadPreviewData(asset) {
    this.props.fetchPreviewAsset(asset.id, this.props.contextId, (res) => {
      this.props.fetchPreviewText(asset.id, this.props.contextId);
    });
  }

  onViewerRendered() {
    this.initialized = true;

    if (!this.props.inline) {
      scrollToPage(this.state.initialPage - 1);
    }
  }

  onPageChange(page, props) {
    var { previousPosition, currentPosition } = props;

    var previousPage = this.state.currentPage;
    var currentPage = this.state.currentPage;

    if (currentPosition == 'inside') {
      currentPage = page;
    } else if (currentPosition == 'below') {
      currentPage = page - 1;

    } else if (currentPosition == 'above') {
      currentPage = page + 1;
    }

    if (previousPage != currentPage) {
      this.setState({
        currentPage: currentPage
      });
    }
  }

  _handleZoomInClick() {
    var newScale = this.state.currentScale;
    newScale = (newScale * 1.1).toFixed(2);
    newScale = Math.ceil(newScale * 10) / 10;
    newScale = Math.min(10, newScale);

    this.setState({
      currentScale: newScale
    });
  }

  _handleZoomOutClick() {
    var newScale = this.state.currentScale;

    newScale = (newScale / 1.1).toFixed(2);
    newScale = Math.floor(newScale * 10) / 10;
    newScale = Math.max(0.25, newScale);

    this.setState({
      currentScale: newScale
    });
  }

  _handleNextPageClick() {
    let total = this.props.preview.pages.length;
    let current = this.state.currentPage;

    current = current + 1;
    if (current <= total) {
      scrollToPage(current);
    }
  }

  _handlePreviousPageClick() {
    let current = this.state.currentPage;

    current = current - 1;
    if (current >= 0) {
      scrollToPage(current);
    }
  }

  _handleFullScreenClick() {
    var viewer = document.getElementById(`enhanced_${this.props.asset.id}`);
    this.launchFullScreen(viewer);
  }

  launchFullScreen(element) {
    if (element.requestFullScreen) {
      element.requestFullScreen();
    } else if (element.mozRequestFullScreen) {
      element.mozRequestFullScreen();
    } else if (element.webkitRequestFullScreen) {
      element.webkitRequestFullScreen();
    }
  }

  render() {
    if (this.props.loading || !this.props.preview) {
      return null;
    }

    this.viewer = React.createRef();

    return (
      <div id={`enhanced_${this.props.asset.id}`} className={styles.viewer} ref={this.viewer}>

        {!this.props.inline && this.renderAssetView()}

        {this.props.inline && this.renderInlineView()}

        {!this.props.inline && this.renderMenu()}

        {this.props.inline &&
          <Sticky active={true} context={this.contextRef}>
            {this.renderMenu()}
          </Sticky>
        }
      </div>
    )
  }

  renderAssetView() {
    return (
      <Measure bounds
        onResize={(contentRect) => {
          var currentWidth = this.state.pageDimensions.width;

          var width = contentRect.bounds.width;
          var currentScale = this.state.currentScale;

          if (this.state.currentScale == 0) {
            currentScale = this._calculateScale(width, currentScale);
          }

          if (this.state.pageDimensions.width == width) {
            return;
          }

          this.setState({
            pageDimensions: {
              width: width
            },
            currentScale: currentScale
          });

          setTimeout(() => {
            if (!this.rendered) {
              this.rendered = true;
              this.onViewerRendered();
            }
          }, 0)
        }}>
        {({ measureRef }) =>
          <div ref={measureRef} className={styles.inner}>
            {this.renderPages()}
          </div>
        }
      </Measure>
    )
  }

  renderInlineView() {

    return (
      <Measure bounds
        onResize={(contentRect) => {
          var currentWidth = this.state.pageDimensions.width;

          var width = contentRect.bounds.width;
          var currentScale = this.state.currentScale;

          if (this.state.currentScale == 0) {
            currentScale = 1;
          }

          if (this.state.pageDimensions.width == width) {
            return;
          }

          this.setState({
            pageDimensions: {
              width: width
            },
            currentScale: currentScale,
          });

          if (this.props.inline) {
            this.setState({
              preferredWidth: width
            })
          }

          setTimeout(() => {
            if (!this.rendered) {
              this.rendered = true;
              this.onViewerRendered();
            }
          }, 0)
        }}>
        {({ measureRef }) =>
          <div ref={measureRef} className={styles.innerInline}>
            {this.renderPages()}
          </div>
        }
      </Measure>
    )
  }

  renderMenu() {
    return (
      <Menu
        compact
        icon='labeled'
        fixed='bottom'
        size='small'
        className={styles.controlMenu}>
        <Menu.Item
          name={`Page ${this.state.currentPage + 1} of ${this.props.preview.pages.length}`}
        >
        </Menu.Item>
        <Menu.Item
          name='angle up'
          onClick={this._handleZoomOutClick}
        >
          <Image src={ZoomOutIcon} size='mini' spaced />
        </Menu.Item>

        <Menu.Item
          name='angle up'
          onClick={this._handleZoomInClick}
        >
          <Image src={ZoomInIcon} size='mini' spaced />
        </Menu.Item>

        <Menu.Item
          name='angle up'
          onClick={this._handlePreviousPageClick}
        >
          <Image src={NextPageIcon} size='mini' spaced />
        </Menu.Item>

        <Menu.Item
          name='angle up'
          onClick={this._handleNextPageClick}
        >
          <Image src={PreviousPageIcon} size='mini' spaced />
        </Menu.Item>
        <Menu.Item
          name='angle up'
          onClick={this._handleFullScreenClick}
        >
          <Image src={FullScreenIcon} size='mini' spaced />
        </Menu.Item>
      </Menu>
    )
  }

  renderPages() {
    if (!this.props.preview) {
      return false;
    }

    var pages = this.props.preview.pages.map((page) => {
      var dimensions = this.calculateDimensionForPage(page);
      return (
        <Page 
          key={page.index}
          page={page}
          currentPage={this.state.currentPage}
          dimensions={dimensions}
          onPageChange={this.onPageChange}
          renderLinks={this.renderLinks}
          preview={this.props.preview}
          inline={this.props.inline}
        />
      )
    });

    return pages;
  }

  renderLinks(page, dimensions) {
    if (!page || !page.links) {
      return false;
    }

    var scale = dimensions.width / page.width;

    var pages = page.links.map((link, index) => {
      var linkStyle = {
        left: link.x,
        top: link.y,
        width: link.width,
        height: link.height,
        transformOrigin: `-${link.x}px -${link.y}px 0px`,
        transform: `matrix(${scale}, 0, 0, ${scale}, 0, 0)`
      };

      if (link.internal) {
        return (
          <section className={styles.link} key={index}>
            <div href={link.url}
              className={styles.link}
              style={linkStyle}
              onClick={() => { scrollToPage(link.page) }}
            >&nbsp;</div>
          </section>
        );
      } else {
        return (
          <section className={styles.link} key={index}>
            <a href={link.url}
              className={styles.link}
              style={linkStyle}
              target="_blank"
              rel="noopener noreferrer nofollow"></a>
          </section>
        )
      }
    });

    return pages;
  }

  calculateDimensionForPage(page) {
    var { currentScale } = this.state;
    var parentWidth = this.state.pageDimensions.width || page.width;

    var aspectRatio = page.width / page.height;

    var pageWidth = (parentWidth);
    var pageHeight = Math.ceil(pageWidth / aspectRatio);

    if (aspectRatio < 1) {
      //var pageHeight = parentWidth - 5;
      //var pageWidth = Math.ceil(pageHeight * aspectRatio);
    }

    if (currentScale == 0 && !this.props.inline) {
      currentScale = this.state.preferredWidth / pageWidth;
    }

    return {
      width: pageWidth * currentScale,
      height: pageHeight * currentScale
    }
  }

  _calculateScale(width, currentScale) {
    let pageWidthScale = this.state.preferredWidth / width;
    return pageWidthScale;
  }
}

const scrollToPage = (page) => {
  var pageContainer = document.getElementById(`pageContainer${page + 1}`);
  if (pageContainer) {
    pageContainer.scrollIntoView();
    pageContainer.offsetParent.scrollTop -= 30;
  }
}


function Page({ page, dimensions, onPageChange, renderLinks, currentPage, preview, ...rest }) {
  const shouldRenderTextLayer = (page) => {
    return page === currentPage || page === currentPage - 1 || page === currentPage + 1;
  }

  return (
    <div
      id={`pageContainer${page.index + 1}`}
      key={`pageContainer${page.index + 1}`}
      className={cx(styles.page, { [styles.pageInline]: rest.inline })}
      style={{ width: dimensions.width, height: dimensions.height }}>

      <div style={{ height: dimensions.height }} key={`lazyload_${page.index}`}>
        <Waypoint
          fireOnRapidScroll={false}
          bottomOffset="50%"
          topOffset="49.5%"
          onEnter={onPageChange.bind(this, page.index)}
          onLeave={onPageChange.bind(this, page.index)}
          key={`page_${page.index}`} >
          <div className={styles.pageInner}>
            <div className={styles.backgroundWrapper}>
              <Image src={page.background.full} />
            </div>

            {shouldRenderTextLayer(page.index) &&
              <div className={styles.textsWrapper} style={{
                width: page.width,
                height: page.height,
                transform: `scale(${dimensions.width / page.width})`
              }}>
                <TextLayer page={page} dimensions={dimensions} fonts={preview.fonts} />
              </div>
            }
            <div className={styles.linksWrapper}>
              {renderLinks(page, dimensions)}
            </div>
          </div>
        </Waypoint>
      </div>
    </div>
  );
}

const mapStateToProps = (state, ownProps) => {
  var assetState = state.asset[ownProps.contextId];
  return {
    loading: assetState ? assetState.preview.loading : false,
    preview: assetState ? assetState.preview.item : null,
    session: assetState ? assetState.session.id : null
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    fetchPreviewAsset: (id, contextId, callback) => {
      return dispatch(fetchPreviewAsset(id, contextId, callback));
    },

    fetchPreviewText: (id) => {
      return dispatch(fetchPreviewText(id));
    }
  }
}

const EnhancedDocumentViewer = connect(
  mapStateToProps,
  mapDispatchToProps
)(EnhancedDocumentView)

export default EnhancedDocumentViewer;