import React, { PropTypes, Fragment, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { fetchPageTemplate, fetchAssetTemplates, updatePageTemplate, publishPageTemplate } from 'actions/templates';
import { fetchIntegrations } from 'actions/integrations/integrations';
import {
  undo, redo, createEditorContext, createWidgetFromPalette
} from 'actions/widgetEditor';

import SidePane from 'components/SidePane';
import cx from 'classnames';
import FloatingPane from 'components/FloatingPane';
import { getStyles } from 'components/Widgets/widget.jsx';

import {
  Route,
  NavLink,
  useParams,
  useRouteMatch,
} from "react-router-dom";

import { Menu, Segment, Icon, Form, Label, Input, Header, Button, Image } from 'semantic-ui-react';

import _ from 'lodash';
import { guid } from 'services/util';
import { denormalise } from 'services/widget.helper';

import { Helmet } from "react-helmet";
import { getBaseUrl } from 'services/util';

import { WidgetRegistry } from 'components/Widgets/registry.jsx';
import { WidgetsEditor } from "pages/pages/editor/editor";
import ImageTools from 'services/imagetools.service';
import { useWidgetSelecion } from 'components/Widgets/widget';
import { PropertyPane, PropertiesPane } from './properties';

import styles from './templates.module.scss';

export default function PageTemplatesPage() {
  let { path, url } = useRouteMatch();

  return (
    <Route path={`${path}/:id/edit`}>
      <PageTemplateEditor />
    </Route>
  );
}

export function PageTemplateEditor() {
  const dispatch = useDispatch();
  const { id } = useParams();
  const [editor, setEditor] = useState(null);

  const template = useSelector(state => _.get(state, 'templates.pages.template.item', {}));
  const loading = useSelector(state => _.get(state, 'pageEditor.loading', false));
  const updating = useSelector(state => _.get(state, 'pageEditor.updating', false));

  const [editable, setEditable] = useState(true);
  const selectedWidget = useWidgetSelecion(editor);

  console.log(template);

  var widgetsById = useSelector(state => {
    var widgetsById = {};

    if (!editor) {
      return widgetsById;
    }

    var editorContext = state.widgetsEditor.editors[editor];

    if (editorContext) {
      widgetsById = editorContext.widgetsById;
    }

    return widgetsById;
  })

  var widgets = useSelector(state => {
    var widgets = [];

    if (!editor) {
      return widgets;
    }

    var editorContext = state.widgetsEditor.editors[editor];

    if (editorContext) {
      widgets = editorContext.widgets;
    }

    return widgets;
  })

  useEffect(() => {
    dispatch(fetchPageTemplate(id));
    dispatch(fetchAssetTemplates());
    dispatch(fetchIntegrations());

    var editor = guid();
    setEditor(editor);
  }, []);

  useEffect(() => {
    console.log(template);
    if (template && template.draft && editor) {
      dispatch(createEditorContext(editor, template.draft.widgets || []));
    }
  }, [template && template.id, editor]);

  const _onItemDrop = (editor, request, parent, index, insertBefore) => {
    const entityReference = {
      id: id,
      type: 'page_template'
    };

    dispatch(createWidgetFromPalette(editor, entityReference, request[0], parent, index, insertBefore));
  }

  const _handleUpdatePage = () => {
    var widgetsRequest = denormalise(widgets, widgetsById);
    widgetsRequest = WidgetRegistry.processRequest(widgetsRequest);

    var properties = _.get(template, 'draft.properties', {});

    dispatch(updatePageTemplate(template.id, {
      widgets: widgetsRequest,
      properties: properties
    }));
  }

  const _handlePublishPage = () => {
    var widgetsRequest = denormalise(widgets, widgetsById);
    widgetsRequest = WidgetRegistry.processRequest(widgetsRequest);

    var properties = _.get(template, 'draft.properties', {});

    dispatch(publishPageTemplate(template.id, {
      widgets: widgetsRequest,
      properties: properties
    }));
  }

  const _handlePreviewClicked = () => {
    setEditable(!editable);
  }

  if (loading || !template) {
    return false;
  }

  const stylesheets = _.get(template, 'draft.properties.css', '');
  var templateProperties = _.get(template, 'draft.properties', {});
  var templateStyles = getStyles(templateProperties);
  const script = _.get(template, 'draft.properties.script', '');

  return (
    <div className={styles.content}>
      {stylesheets &&
        <Helmet>
          <style type="text/css">{stylesheets}</style>
          <script type="text/javascript">{script}</script>
        </Helmet>
      }

      <PageHeader
        name={template.name}
        undo={undo}
        redo={redo}
        save={_handleUpdatePage}
        publish={_handlePublishPage}
        preview={_handlePreviewClicked}
      />
      <Segment basic className={styles.widgetsEditor} style={templateStyles} >
        <div className={styles.editor}>
          <WidgetsEditor
            id='widget_viewer'
            editor={editor}
            entityReference={{
              id: template.id,
              type: 'page.template'
            }}
            widgets={widgets}
            editable={editable}
            onCreateWidget={_onItemDrop}
          />
          <FloatingPane
            width={260}
            height='80vh'
            position={{ top: 85, right: 20 }}
            className={cx(styles.pageProperties, { [styles.hide]: (!editable || selectedWidget != null) })}
          >
            <PropertiesPane editor={editor} />
          </FloatingPane>
        </div>
      </Segment>
    </div>
  );
}

function PageHeader(props) {
  const baseUrl = useSelector(state => _.get(state, 'storyboards.storyboard.item.baseUrl'));
  const template = useSelector(state => _.get(state, 'templates.pages.template.item', {}));
  const updating = useSelector(state => _.get(state, 'templates.pages.template.updating', false));
  const publishing = useSelector(state => _.get(state, 'templates.pages.template.publishing', false));

  let { path, url } = useRouteMatch();
  let appUrl = getBaseUrl(url);

  const [showEdit, setShowEdit] = useState(false);

  const toggleShowEdit = () => {
    setShowEdit(!showEdit);
  }

  return (
    <>
      <Menu fluid className={styles.pageHeader}>
        <Menu.Item as={NavLink} exact name='Pages' to={`${appUrl}/pages`} icon onClick={props.preview}><Icon name='arrow left' /></Menu.Item>
        <Menu.Item header>{props.name}</Menu.Item>
        <Menu.Item icon onClick={toggleShowEdit}><Icon name='setting' /></Menu.Item>
        <Menu.Item icon onClick={props.preview}><Icon name='eye' /></Menu.Item>
        <Menu.Menu position='right'>
          <Menu.Item onClick={props.save} disabled={updating}>
            <Icon name={updating ? 'spinner' : 'save'} loading={updating} />
            {updating ? 'Saving...' : 'Save'}
          </Menu.Item>
          <Menu.Item onClick={props.publish} disabled={publishing}>
            {publishing && <Icon name='spinner' loading={publishing} />}
            {publishing ? 'Publishing...' : 'Publish'}
          </Menu.Item>
        </Menu.Menu>
      </Menu>
      <SidePane right
        header='Update Template'
        close
        background='#ffffff'
        open={showEdit}
        className={styles.pageEditSlider}
        onClose={() => {
          setShowEdit(false);
        }}
        closeOnDocumentClick={true}>
        <EditTemplateView template={template} open={showEdit} onCancel={() => { setShowEdit(false) }} />
      </SidePane>
    </>
  );
}

function EditTemplateView(props) {
  const dispatch = useDispatch();

  const template = useSelector(state => _.get(state, 'templates.pages.template.item', {}));
  const updating = useSelector(state => _.get(state, 'templates.pages.template.updating', false));
  const error = useSelector(state => _.get(state, 'templates.pages.template.error', null));


  const [name, setName] = useState(template.name);
  const [errors, setErrors] = useState({});
  const [icon, setIcon] = useState(null);

  useEffect(() => {
    if (props.open) {
      setName(template.name);
      setErrors({});
      setIcon(null);
    }
  }, [props.open])

  useEffect(() => {
    if (error && error.errors) {
      var errors = {};
      error.errors.forEach(item => {
        if (item.code === 34001) {
          errors['alias'] = item.message;
          setErrors(errors);
        }
      })
    }
  }, [error])

  const handleNameChange = (value) => {
    setName(value);
    setErrors({
      ...errors,
      name: null
    })
  }

  const validateForm = () => {
    var errors = {};

    if (!name || name.trim() === '') {
      errors['name'] = 'Please provide a valid name';
    }

    return errors;
  }

  const onFormSubmit = () => {
    let errors = validateForm();

    if (Object.keys(errors).length > 0) {
      setErrors(errors);
      return;
    }

    var request = {
      name: name,
      icon: icon
    }

    dispatch(updatePageTemplate(template.id, request, (res) => {
      props.onCancel();
    }));
  }

  const onCancel = () => {
    props.onCancel();
  }

  const renderIcon = () => {
    if (icon) {
      return icon;
    }

    return _.get(template, 'icon.thumbnail');
  }

  return (
    <Segment basic className={styles.templateEditorForm}>
      <Segment basic className={styles.templateEditView}>
        <Form>
          <InputField
            title='NAME'
            value={name}
            placeholder='Provide template name'
            error={errors.name}
            onChange={handleNameChange}
          />

          <Segment className={styles.seoMetadataSegment}>

            <Header as='h5'>THUMBNAIL</Header>

            <ImageInput value={renderIcon()} onChange={setIcon} />

          </Segment>

        </Form>
      </Segment>

      <Menu borderless fixed={'bottom'}>
        <Button.Group fluid className={styles.buttonTray}>
          <Button onClick={onCancel} disabled={updating}>
            <Icon name={'repeat'} />
            Cancel
          </Button>
          <Button.Or />
          <Button
            disabled={updating}
            positive
            onClick={onFormSubmit}>
            <Icon name={updating ? 'spinner' : 'save'} loading={updating} disabled={updating} />
            {updating ? 'Updating' : 'Update'}
          </Button>
        </Button.Group>
      </Menu>
    </Segment>
  )
}

function InputField({ title, placeholder, value, onChange, error, ...rest }) {

  const handleChange = (e) => {
    onChange(e.target.value);
  }

  return (
    <Form.Field className={styles.field}>
      <Label size='small' active>{title}</Label>
      <Input error={error} placeholder={placeholder}
        value={value}
        onChange={handleChange}
        disabled={rest.disabled} />
    </Form.Field>
  )
}

function ImageInput({ value, onChange }) {
  const uploadIconRef = React.createRef();
  const handleUploadIcon = (e) => {
    ImageTools.resize(e.target.files[0], {
      width: 1024, // maximum width
      height: 1024 // maximum height
    }, (blob, didItResize) => {
      var reader = new FileReader();
      reader.onload = (e) => {
        onChange(e.target.result)
      };

      reader.readAsDataURL(blob);
    });
  }

  return (
    <Form.Group inline className={styles.icon}>
      <div className={styles.image}>
        {value ?
          <Image src={value} />
          :
          <div className={styles.empty}>NO ICON</div>
        }
      </div>

      <div className={styles.upload}>
        <Button size='mini' onClick={() => uploadIconRef.current.click()}>Upload Icon</Button>
        <input
          ref={uploadIconRef}
          type="file"
          hidden
          onChange={handleUploadIcon}
        />
      </div>
    </Form.Group>
  );
}