import React, { useState, Fragment, useRef, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateProperties, updateWidget, updateWidgetContent } from 'actions/widgetEditor';

import _ from 'lodash';
import cx from 'classnames';

import {
  Image as SemanticImage
} from 'semantic-ui-react';

import { Widget, getStyles, getDimensionStyles } from '../widget.jsx';
import { Resizable } from '../resizable';
import { WidgetRegistry } from '../registry.jsx';
import { Property } from 'components/Properties';
import { QuickSettings, QuickSettingsProperty } from 'components/QuickSettings';
import Animation from 'components/Animation';

import styles from './image.module.scss';
import { useWidgetDrop } from "services/dnd.service";
import { getInnerWidthOfElement, getInnerHeightOfElement } from 'services/widget.helper.jsx';
import ImageTools from 'services/imagetools.service.jsx';


const calculateImageDimension = (id, dimension, delta) => {
  const unit = _.get(dimension, 'unit');
  const aspectRatio = _.get(dimension, 'aspectRatio');

  var element = document.querySelector(`#widget_${id} img`);
  if (!element) {
    return {};
  }

  var elementWidth = element.offsetWidth;
  var elementHeight = element.offsetHeight;

  var newWidth = elementWidth + delta.x;
  var newHeight = elementHeight + delta.y;

  var currentWidth = dimension.width;

  var ratio = element.naturalWidth / element.naturalHeight;

  if (unit === 'percent') {
    newWidth = (currentWidth / elementWidth) * newWidth;
    newHeight = null;
  } else if (aspectRatio) {
    if (delta.y == 0) {
      newWidth = newHeight * ratio;
    } else {
      newHeight = newWidth / ratio;
    }
  }

  return {
    width: newWidth,
    height: newHeight
  }
}

export const Image = React.memo((props) => {
  const ref = useRef(null);
  const dropRef = useWidgetDrop(props, ref, {

  });

  const dispatch = useDispatch();
  const properties = props.properties;
  const dimension = _.get(properties, 'dimension') || {};

  var widgetStyles = getStyles(props.properties);
  var dimensionStyles = getDimensionStyles(dimension);

  widgetStyles = {
    ...widgetStyles,
    ...dimensionStyles
  };

  const image = _.get(props, "content.full", null);

  const handleResize = useCallback((delta) => {
    const dimension = _.get(properties, 'dimension') || {};
    const changes = calculateImageDimension(props.id, dimension, delta);

    dispatch(updateProperties({
      id: props.id,
      change: {
        dimension: {
          ...dimension,
          ...changes
        }
      },
      context: props.editor
    }));
  });

  const aspectRatio = _.get(properties, 'dimension.aspectRatio');
  var resizeHandles = ['se'];
  if (!aspectRatio) {
    resizeHandles = [...resizeHandles, 'e', 's'];
  }

  const wrapWithAnimation = (component, editable) => {
    if(editable){
      return component;
    }

    return (
      <Animation {...(properties.animation || {})}>
        {component}
      </Animation>
    );
  }

  return (
    <div className={cx(styles.image, props.className, {
      [styles.responsive]: aspectRatio
    })}
      id={`widget_${props.id}`}
      ref={props.dragRef(dropRef(ref))}
      onMouseOver={props.onMouseOver}
      onMouseOut={props.onMouseOut}
      onClick={props.onClick}
      style={widgetStyles}
    >
      {image &&
        wrapWithAnimation(<img src={image} />, props.editable)
      }
      
      {!image &&
        <SemanticImage>
          <div>NO IMAGE</div>
        </SemanticImage>
      }

      <Widget.Children widgets={props.widgets} parent={props.id} editor={props.editor} editable={props.editable} />
      {props.children}

      {props.selected &&
        <Resizable handles={resizeHandles} onResizing={handleResize} />
      }
    </div>
  )
});

function ImageQuickSettings(props) {
  const dispatch = useDispatch();

  const [selected, setSelected] = useState('image');
  const [upload, setUpload] = useState({});
  const menuItems = [
    { id: 'image', title: 'Image', icon: 'icon-background' },
    { id: 'action', title: 'Action', icon: 'icon-layout' },
    { id: 'dimension', title: 'Dimension', icon: 'icon-spacing' },
    { id: 'spacing', title: 'Spacing', icon: 'icon-spacing' },
    { id: 'border', title: 'Border', icon: 'icon-border' },
    { id: 'animation', title: 'Animation', icon: 'icon-spacing'},
    { id: 'advanced', title: 'Advanced', icon: 'icon-custom' }
  ]

  const handleMenuClick = (item) => {
    setSelected(item);
  }

  var editorContext = useSelector(state => state.widgetsEditor.editors[props.editor]);
  var widget = editorContext.widgetsById[props.id];
  var updatingContent = _.get(widget, 'updatingContent', false);
  var content = _.get(props, "widget.content", null)

  useEffect(() => {
    var dimension = _.get(props, "widget.properties.dimension", null) || {};
    var changes = calculateImageDimension(props.id, dimension, {
      x: 0,
      y: 0
    });

    handleChange({
      dimension: {
        ...dimension,
        ...changes
      }
    });

  }, [content]);

  if (!widget) {
    return false;
  }

  var properties = widget.properties;

    const handleChange = (changeRequest) => {
      dispatch(updateProperties({
        id: widget.id,
        change: changeRequest,
        context: props.editor
      }));
    }

  const handleCssChange = (css) => {
    var changeRequest = { ...widget };
    _.set(changeRequest, 'properties.css', css);

    changeRequest = WidgetRegistry.processRequest([changeRequest])[0];
    dispatch(updateWidget(props.id, changeRequest, props.editor));
  }

  const handleImageChange = (file) => {
    if (typeof file === 'string' || file.type.match('image.*')) {
      ImageTools.resize(file, {
        width: 1600, // maximum width
        height: Number.MAX_SAFE_INTEGER // maximum height
      }, (blob, didItResize) => {
        loadImageFile(blob).then(url => {
          var uploadObject = {
            "data": url,
            "type": "image"
          }
          setUpload(uploadObject)
          dispatch(updateWidgetContent(widget.id, {
            content: {
              upload: url
            }
          }, props.editor
          ));
        })
      });
    }
  }

  const loadImageFile = (imageFile) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.onload = e => resolve(e.target.result)
      reader.onerror = reject
      reader.readAsDataURL(imageFile)
    });
  }

  const handleRemoveImage = () => {
    var changeRequest = { ...widget };
    _.set(changeRequest, 'content', null);
    setUpload({});
    dispatch(updateWidgetContent(props.id, 
      {content: null}, props.editor));
  }

  const unit = _.get(properties, 'dimension.unit') || 'percent';
  const uploadedImage = _.get(props, "widget.content", null);

  var link = _.get(properties, 'action.link', null);
  var newTab = _.get(properties, 'action.newTab', false);

  return (
    <QuickSettings menuItems={menuItems} selected={selected} onMenuClick={handleMenuClick} >
      {selected === 'image' &&
        <QuickSettingsProperty.Image
          title='Image'
          accept='image/*'
          updating={updatingContent}
          value={uploadedImage ? uploadedImage : upload}
          onChange={handleImageChange}
          onRemove={handleRemoveImage}
        />
      }

      {selected === 'action' &&
        <>
          <QuickSettingsProperty.Input
            title={'Url'}
            value={link}
            onChange={(value) => {
              handleChange({
                action: {
                  ...properties.action,
                  link: value,
                  type: "link"
                }
              });
            }}
          />
          <QuickSettingsProperty.Switch
            title={'Open in new Tab'}
            value={newTab}
            onChange={() => {
              handleChange({
                action: {
                  ...properties.action,
                  newTab: !newTab,
                  type: "link"
                }
              })
            }}
          />
        </>
      }

      {selected === 'dimension' &&
        <>
          <QuickSettingsProperty.Dropdown
            title='Unit'
            tooltip='Unit of measure'
            selection
            options={[
              { key: 'percent', value: 'percent', text: 'Percentage' },
              { key: 'px', value: 'px', text: 'Pixels' },
            ]}
            value={_.get(properties, 'dimension.unit') || 'percent'}
            onChange={(e, data) => {
              var dimension = _.get(properties, 'dimension') || {};

              var element = document.querySelector(`#widget_${props.id}`);
              var parent = document.querySelector(`#widget_${props.widget.parent}`);

              var parentWidth = getInnerWidthOfElement(parent);

              var elementWidth = getInnerWidthOfElement(element);
              var elementHeight = getInnerHeightOfElement(element);

              var width = "";
              var height = "";

              if (data.value === 'percent') {
                width = (elementWidth / parentWidth) * 100;
                height = null;
              } else if (data.value === 'px') {
                width = elementWidth;
                height = elementHeight;
              }

              handleChange({
                dimension: {
                  ...dimension,
                  unit: data.value,
                  width: width,
                  height: height
                }
              })
            }}
          />

          <QuickSettingsProperty.Input
            title='Width'
            value={_.get(properties, 'dimension.width')}
            onChange={(value) => {
              const dimension = _.get(properties, 'dimension') || {};
              const unit = _.get(dimension, 'unit');
              const aspectRatio = _.get(dimension, 'aspectRatio');

              var changes = {
                width: value
              };

              if (unit === 'px' && aspectRatio) {
                var element = document.querySelector(`#widget_${props.id} img`);
                var ratio = element.naturalWidth / element.naturalHeight;

                changes = {
                  ...changes,
                  height: value / ratio
                }
              }

              handleChange({
                dimension: {
                  ...dimension,
                  ...changes
                }
              });
            }}
          />

          {unit != 'percent' &&
            <>
              <QuickSettingsProperty.Input
                title='Height'
                value={_.get(properties, 'dimension.height')}
                onChange={(value) => {
                  const dimension = _.get(properties, 'dimension') || {};
                  const unit = _.get(dimension, 'unit');
                  const aspectRatio = _.get(dimension, 'aspectRatio');

                  var changes = {
                    height: value
                  };

                  if (unit === 'px' && aspectRatio) {
                    var element = document.querySelector(`#widget_${props.id} img`);
                    var ratio = element.naturalWidth / element.naturalHeight;

                    changes = {
                      ...changes,
                      width: value * ratio
                    }
                  }

                  handleChange({
                    dimension: {
                      ...dimension,
                      ...changes
                    }
                  });

                }}
              />

              <QuickSettingsProperty.Switch
                title='Maintain Aspect Ratio'
                value={_.get(properties, 'dimension.aspectRatio')}
                onChange={(value) => {
                  const dimension = _.get(properties, 'dimension') || {};
                  const unit = _.get(dimension, 'unit');

                  var width = _.get(dimension, 'width');
                  var height = _.get(dimension, 'height');

                  if (value && unit === 'px') {
                    var element = document.querySelector(`#widget_${props.id} img`);
                    var ratio = element.naturalWidth / element.naturalHeight;

                    height = width / ratio;
                  }

                  handleChange({
                    dimension: {
                      ...dimension,
                      aspectRatio: value,
                      width: width,
                      height: height
                    }
                  });
                }}
              />
            </>
          }
        </>
      }

      {selected === 'spacing' &&
        <>
          <QuickSettingsProperty.Spacing
            title='Margin'
            value={properties.margin}
            onChange={(margin) => {
              handleChange({
                margin: margin
              });
            }}
          />
          <QuickSettingsProperty.Spacing
            title='Padding'
            value={properties.padding}
            onChange={(padding) => {
              handleChange({
                padding: padding
              });
            }}
          />
        </>
      }

      {selected === 'border' &&
        <QuickSettingsProperty.Border expanded value={properties.border} onChange={(border) => {
          handleChange({
            border: border
          });
        }}
        />
      }

      {selected === 'animation' &&
        <QuickSettingsProperty.Animation 
          title='Animation' 
          value={properties.animation} 
          onChange={(animation) => {
            handleChange({
              animation: animation
            });
          }}
        />
      }

      {selected === 'advanced' &&
        <QuickSettingsProperty.CSS title='Stylesheets' value={properties.css} onChange={handleCssChange} />
      }
    </QuickSettings>
  )
}

Image.QuickSettings = ImageQuickSettings;

function ImageProperty(props) {
  const dispatch = useDispatch();

  var editorContext = useSelector(state => state.widgetsEditor.editors[props.editor]);
  var widget = editorContext.widgetsById[props.id];

  if (!widget) {
    return false;
  }

  var properties = widget.properties;

  const handleChange = (changeRequest) => {
    dispatch(updateProperties({
      id: widget.id,
      change: changeRequest,
      context: props.editor
    }));
  }

  const handleBackgroundImageChange = (media, type) => {
    var changeRequest = {...widget};
    _.set(changeRequest, 'properties.background.upload', {
      type: type,
      data: media
    });

    changeRequest  = WidgetRegistry.processRequest([changeRequest])[0];
    dispatch(updateWidget(props.id, changeRequest, props.editor));
  }
  
  const handleCssChange = (css) => {
    var changeRequest = { ...widget };
    _.set(changeRequest, 'properties.css', css);

    changeRequest = WidgetRegistry.processRequest([changeRequest])[0];
    dispatch(updateWidget(props.id, changeRequest, props.editor));
  }

  return (
    <Fragment>
      <Property.ImageUpload
        value={props.content}
        onChange={(background) => {
          handleChange({
            background: background
          });
        }}
        onImageChange={(image, type) => {
          handleBackgroundImageChange(image, type);
        }} 
      />
      <Property.Section title='Spacing' expanded>
        <Property.Spacing
          title='Margin'
          value={properties.margin}
          onChange={(margin) => {
            handleChange({
              margin: margin
            });
          }}
        />
        <Property.Spacing
          title='Padding'
          value={properties.padding}
          onChange={(padding) => {
            handleChange({
              padding: padding
            });
          }}
        />
      </Property.Section>

      <Property.Border expanded value={properties.border} onChange={(border) => {
        handleChange({
          border: border
        });
      }}
      />

      <Property.Section title='Advanced' expanded>
        <Property.CSS title='Stylesheets' value={properties.css} onChange={handleCssChange} />
      </Property.Section>
    </Fragment>
  )
}

Image.Property = ImageProperty;